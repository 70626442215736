import { isEmpty } from 'lodash-es'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColorGray } from '../../../../design-tokens/build/shared/token-colors.json'
import dbconnect from '../../assets/images/dnb-amp-logo.svg'
import { useApi } from '../../hooks'
import { useFeatures, useModules } from '../../hooks/useEntitlements'
import { Button, Divider, Modal } from '../../local-core-ui/'
import { CreateQueryStatus } from '../../queries/api/getC4SActiveConnection'
import { System } from '../../queries/api/getConnections'
import { useCreateConnection } from '../../queries/useCreateConnection'
import { authenticateAgainst3rdParty, RootState, TDispatch, useAppDispatch, useAppSelector } from '../../store'
import {
	clearAuthOrigin,
	setAuthOrigin,
	setCreateQueryStatus,
	setTypeConnection
} from '../../store/connection/connectionSlice'
import { AuthOrigins } from '../../store/session/types/AuthOrigins'
import { C4SFailConnectionModal } from '../c4s-fail-connection-modal/c4s-fail-connection-modal'
import { connectData, ConnectManageModal } from '../connect-manage-modal/connect-manage-modal'
import { IntegrationConfig, IntegrationTile } from '../integration-tile/integration-tile'
import salesforce from '../integration-tile/png/salesforce.png'
import snowflakes from '../integration-tile/png/snowflakes.png'
import { NewC4SConnectionModal, SalesforceEnvironment } from '../salesforce-modal/salesforce-modal'
import { SnowflakeData } from '../snowflake-form/snowflake-form'
import { SnowflakeModal } from '../snowflake-modal/snowflake-modal'
import styles from './new-connection-modal.module.scss'

export enum IntegrationType {
	Salesforce = 'salesforce',
	Snowflake = 'snowflakes',
	ConnectManage = 'connectManage'
}

interface NewConnectionModalProps {
	open?: boolean
	onCloseModal(): void
	onReload(): void
	testId: string
}

export const storageEventListener = (dispatch: TDispatch, action: (display: boolean) => void) => {
	const c4sError = localStorage.getItem('C4SError')

	if (!isEmpty(c4sError)) {
		// access_denied: returned when user choose deny access permissions
		if (c4sError !== 'access_denied') {
			action(true)
		}
		dispatch(clearAuthOrigin())
		localStorage.setItem('C4SError', '')
	}
}

export const NewConnectionModal = ({ open, onCloseModal, onReload, testId }: NewConnectionModalProps): ReactElement => {
	const { t } = useTranslation()
	const apiClient = useApi()
	const enableConnectionManager = useFeatures(['EnableConnectionManagement'])
	const [selectedTile, setSelectedTile] = useState('')
	const [snowflakeModal, setSnowflakeModal] = useState(false)
	const [salesforceModal, setSalesforceModal] = useState(false)
	const [connectManageModal, setConnectManageModal] = useState(false)
	const [failC4SModal, setFailC4SModal] = useState(false)
	const [error, setError] = useState('')
	const [errors, setErrors] = useState<string[]>()
	const [dataVerified, setDataVerified] = useState<boolean | undefined>(undefined)
	const [isDataValid, setIsDataValid] = useState<boolean>(false)

	const createConnectionMutation = useCreateConnection()
	const selectConnections = (state: RootState) => state.connection
	const connection = useAppSelector(selectConnections)
	const enableManagedConnectedApp = useFeatures(['C4SEnableManagedConnectedApp'])
	const dispatch = useAppDispatch()
	const disableC4SOAuth = useFeatures(['DisableC4SOAuth'])
	const enableC4SAuthMgmt = useFeatures(['EnableC4SAuthMgmt'])
	const enableSnowflakeConnection = useFeatures(['EnableSnowflakeConnection'])
	const connectManage = useModules(['ConnectManage'])

	const integrations: Array<IntegrationConfig> = [
		{
			id: IntegrationType.ConnectManage,
			title: 'Connect Manage',
			description: '',
			isDisable: !connectManage,
			isDisplayed: connectManage,
			imgSource: dbconnect
		},
		{
			id: IntegrationType.Salesforce,
			title: 'Salesforce',
			description: '',
			isDisable: !enableC4SAuthMgmt,
			isDisplayed: true,
			imgSource: salesforce
		},
		{
			id: IntegrationType.Snowflake,
			title: 'Snowflake',
			description: '',
			isDisable: !enableConnectionManager,
			isDisplayed: enableSnowflakeConnection,
			imgSource: snowflakes
		}
	]

	const getSortedIntegrations = (): Array<IntegrationConfig> => {
		const disableIntegrations: Array<IntegrationConfig> = []
		const enabledIntegrations: Array<IntegrationConfig> = []
		integrations.forEach((integration) => {
			if (integration.isDisplayed) {
				if (integration.isDisable) {
					disableIntegrations.push(integration)
				} else {
					enabledIntegrations.push(integration)
				}
			}
		})
		return [...enabledIntegrations, ...disableIntegrations]
	}

	const onSelectTile = (id: string) => setSelectedTile(id)

	const onClose = () => {
		setSelectedTile('')
		onCloseModal()
	}

	const onContinue = () => {
		if (selectedTile === IntegrationType.Snowflake) {
			setSnowflakeModal(true)
		} else if (selectedTile === IntegrationType.Salesforce) {
			setSalesforceModal(true)
		} else if (selectedTile === IntegrationType.ConnectManage) {
			setConnectManageModal(true)
		}
		onClose()
	}

	const onCloseSnow = () => {
		if (createConnectionMutation.status !== 'loading') {
			setSnowflakeModal(false)
			setError('')
		}
	}

	const createSnowConnection = (snowData: SnowflakeData) => {
		if (snowData) {
			createConnectionMutation.mutateAsync(
				{
					systemType: System.Snowflake,
					connectionData: snowData
				},
				{
					onSuccess: (response) => {
						if (response.Success) {
							onReload()
							onCloseSnow()
						} else if (response.Errors) {
							setError(response.Errors[0])
						}
					}
				}
			)
		}
	}

	const openSalesforceAuthenticator = (environment?: SalesforceEnvironment) => {
		dispatch(setAuthOrigin(AuthOrigins.Connections))
		dispatch(
			authenticateAgainst3rdParty(
				'',
				disableC4SOAuth,
				enableManagedConnectedApp,
				enableC4SAuthMgmt,
				'',
				environment
			)
		)
		setSalesforceModal(false)
	}

	const onRetry = () => {
		setFailC4SModal(false)
		setSalesforceModal(true)
	}

	const verifiedData = async (systemType: string, data: connectData) => {
		const { apiUrl, apiKey } = { ...data }
		const checkDataAPI = `/pls/integration/${systemType}/organization/verifyConnection?apiKey=${apiKey}&apiUrl=${apiUrl}`
		await apiClient
			.get(checkDataAPI)
			.then((response) => {
				setIsDataValid(response.data.Success)
			})
			.catch(() => {
				setIsDataValid(false)
			})
		setDataVerified(true)
	}

	const createConnectConnection = (connectData: connectData) => {
		if (connectData) {
			createConnectionMutation.mutateAsync(
				{
					systemType: System.ConnectManage,
					connectionData: connectData
				},
				{
					onSuccess: (response) => {
						if (response.Success) {
							dispatch(setCreateQueryStatus(CreateQueryStatus.Success))
							dispatch(setTypeConnection(System.ConnectManage))
							onReload()
							setConnectManageModal(false)
							setDataVerified(undefined)
						} else if (response.Errors) {
							setError(response.Errors[0])
						}
					}
				}
			)
		}
	}

	useEffect(() => {
		const addStorageEventListener = () => storageEventListener(dispatch, (display) => setFailC4SModal(display))

		window.addEventListener('storage', addStorageEventListener, false)

		return () => {
			window.removeEventListener('storage', addStorageEventListener)
		}
		/**
		 * We want to run this effect when the salesforce oAuth returns an error
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (connection.createQueryStatus === CreateQueryStatus.Error) {
			setFailC4SModal(true)
			setErrors(connection.connectionErrors)
		}
		/**
		 * We want run this effect when create C4S connection query response with errors
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connection.createQueryStatus])

	return (
		<>
			<C4SFailConnectionModal
				open={failC4SModal}
				onClose={() => setFailC4SModal(false)}
				onRetry={() => onRetry()}
				errors={errors}
				testId={'new-salesforce-modal'}
			/>
			<NewC4SConnectionModal
				open={salesforceModal}
				onCloseModal={() => setSalesforceModal(false)}
				onContinue={(environment) => openSalesforceAuthenticator(environment)}
				testId={'new-salesforce-modal'}
			/>
			<SnowflakeModal
				isEditable={createConnectionMutation.status !== 'loading'}
				open={snowflakeModal}
				onCloseModal={onCloseSnow}
				title={t('create.snowflake')}
				error={error}
				onCloseError={() => setError('')}
				closeText={t('confirmation.modal.cancel') || ''}
				continueText={t('Admin.addBtn')}
				onContinue={createSnowConnection}
				isCloseDisable={createConnectionMutation.status === 'loading'}
				isContinueDisable={createConnectionMutation.status === 'loading'}
				showPassword={true}
				testId={'newSnowConnectionModal'}
			/>
			<ConnectManageModal
				open={connectManageModal}
				onClose={() => setConnectManageModal(false)}
				verifyData={verifiedData}
				dataVerified={dataVerified}
				testId={'newConnectManageModal'}
				changeData={() => setDataVerified(undefined)}
				isDataValid={isDataValid}
				onSave={createConnectConnection}
			/>
			<Modal open={open} onClose={() => onClose()} testId="newConnectionModal" fixWidth={false}>
				<div className={styles.mainContainer}>
					<h2 className={styles.title}>{t('dashboard.connections.create.title')}</h2>
					<div className={styles.description}>{t('dashboard.connections.create.description')}</div>
					<div className={styles.tilesContainer}>
						{getSortedIntegrations().map((integration) => (
							<IntegrationTile
								key={integration.id}
								config={integration}
								isSelected={selectedTile === integration.id}
								onClickTile={() => onSelectTile(integration.id)}
								testId={`${testId}-${integration.id}-integration-tile`}
							/>
						))}
					</div>
				</div>
				<div className={styles.buttonContainer}>
					<Divider color={ColorGray} />
					<div className={styles.buttons}>
						<Button
							size={'small'}
							type={'secondary'}
							text={t('confirmation.modal.cancel')}
							onClick={() => onClose()}
							isDisabled={false}
							testId={testId + '-cancel'}
						/>
						<div className={styles.continueButton}>
							<Button
								size={'small'}
								text={t('continue')}
								onClick={() => onContinue()}
								isDisabled={isEmpty(selectedTile)}
								testId={testId + '-continue'}
							/>
						</div>
					</div>
				</div>
			</Modal>
		</>
	)
}
