import { FC } from 'react'
import { abbreviateNumber } from '../../helpers'
import { BaseDonutChart, Grid } from '../../local-core-ui'
import styles from './donut-chart.module.scss'

interface DonutChartData {
	label: string
	value: number
	rate: number
}

interface DonutChartProps {
	data: DonutChartData[]
	heavy?: boolean
	showDividers?: boolean
	testId: string
	title: string
	matchedCount: number
	colors: Array<string>
}

export const DonutChart: FC<DonutChartProps> = ({
	data,
	heavy = false,
	showDividers = false,
	testId,
	title,
	matchedCount,
	colors
}: DonutChartProps) => {
	const donutData = data.map((driver: DonutChartData) => ({
		label: driver.label,
		value: Math.round(driver.rate),
		netTotal: new Intl.NumberFormat().format(Math.round(driver.value))
	}))

	const precision = matchedCount > 1000 ? 1 : 0
	const abbreviateMatchedCount = abbreviateNumber(matchedCount, precision)

	return (
		<div className={`${styles.mainDonutChartContainer}`}>
			<Grid testId="drivers-donut-chart" md={6}>
				<div className={`${styles.donutChartContainer}`} style={{ color: colors[0] }}>
					<div className={`${styles.titleContainer}`}>
						<div className={`${styles.titleNumber}`}>{abbreviateMatchedCount}</div>
						<div className={`${styles.titleString}`}>{title}</div>
					</div>
					<BaseDonutChart
						data={donutData}
						heavy={heavy}
						showDividers={showDividers}
						testId={testId}
						size="medium"
						colors={colors}
						showTooltip={true}
						showKeys={true}
					/>
				</div>
			</Grid>
		</div>
	)
}
