import { AxiosResponse } from 'axios'
import { RecordClassification } from '../../../components/steward-filter-tabs/steward-filter-tabs'
import { PublishedList } from '../../../types'
import { AppThunk, getApiClient4Thunks, TDispatch } from '../../index'
import { setRecords, setRetrieving, setSelectedRecords } from '../stewardSlice'
import { PageSize, TileTypes } from '../types'

export const getRecords =
	(
		forTile: TileTypes | undefined,
		pageIndex: number,
		pageSize: PageSize,
		requestedClassification?: RecordClassification,
		forAssignee?: string,
		forAssignment?: string,
		selectRecordIndex?: number
	): AppThunk =>
	async (dispatch: TDispatch): Promise<void> => {
		dispatch(setRetrieving(true))
		const apiClient = getApiClient4Thunks(dispatch)
		let url
		switch (forTile) {
			case TileTypes.Published:
				url = `/pls/steward/records/list/published?pageIndex=${pageIndex}&pageSize=${pageSize}&classification=${requestedClassification}&sortField=COMPANY_NAME&sortOrder=ASC`
				break
			case TileTypes.Unassigned:
				url = `/pls/steward/records/list/unassigned?pageIndex=${pageIndex}&pageSize=${pageSize}`
				break
			case TileTypes.Assigned:
				break
			case TileTypes.AssignedToMe:
			default:
				if (forAssignment) {
					url = `/pls/steward/assignments/${forAssignment}/records/processing?pageIndex=${pageIndex}&pageSize=${pageSize}&classification=${requestedClassification}&sortField=COMPANY_NAME&sortOrder=ASC`
				} else if (forAssignee) {
					url = `/pls/steward/records/list?assignee=${forAssignee}&pageIndex=${pageIndex}&pageSize=${pageSize}&classification=${requestedClassification}`
				}
				break
		}
		if (url && forTile !== undefined) {
			apiClient.get(url).then((response: AxiosResponse<PublishedList>) => {
				const tileData = response.data
				dispatch(
					setRecords({
						records: tileData.records,
						forTile,
						pageIndex,
						pageSize,
						total: tileData.total,
						requestedClassification
					})
				)
				if (selectRecordIndex !== undefined) {
					const recordToSelect = tileData.records[selectRecordIndex]
					dispatch(setSelectedRecords([recordToSelect]))
				}
				dispatch(setRetrieving(false))
			})
		} else {
			dispatch(setRetrieving(false))
		}
	}
