import { SalesforceEnvironment } from '../components/salesforce-modal/salesforce-modal'

export const authenticateWithSalesforce = (
	integrationDomain: string | undefined,
	enableManagedConnectedApp = false,
	isSandbox = false,
	enableC4SAuthMgmt = false,
	forcedInstanceURL?: string,
	environment?: SalesforceEnvironment,
	isC4SPlatform?: boolean
): void => {
	const prompt = enableC4SAuthMgmt ? '&prompt=login' : ''
	const isSandboxEnv = enableC4SAuthMgmt && !isC4SPlatform ? environment === SalesforceEnvironment.Sandbox : isSandbox
	const environmentDomain = isSandboxEnv
		? import.meta.env.VITE_C4S_SANDBOX_LOGIN_URL
		: import.meta.env.VITE_C4S_LOGIN_URL
	const domain = integrationDomain ? integrationDomain : environmentDomain

	const authURL = `${forcedInstanceURL ? forcedInstanceURL : domain}/services/oauth2/authorize?client_id=${
		enableManagedConnectedApp ? import.meta.env.VITE_C4S_MANAGED_CLIENT_ID : import.meta.env.VITE_C4S_CLIENT_ID
	}&redirect_uri=${window.location.origin}&response_type=code&scope=refresh_token%20api${prompt}`
	const newWindowSize = 600
	const left = (window.innerWidth - newWindowSize) / 2
	const top = (window.innerHeight - newWindowSize) / 2

	window.open(authURL, 'c4sAuth', `height=${newWindowSize},width=${newWindowSize},left=${left},top=${top}`)
}
