import { ColorMulberry, ColorPinkLighter } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, HalfDonutChart, ProgressBar } from '../../local-core-ui'
import styles from './duns-match-overview.module.scss'

export interface ConfidenceData {
	rate: number
	value: number
}

interface DunsMatchOverviewProps {
	matchedCount: number
	unmatchedCount: number
	confidenceData: Array<ConfidenceData>
	mediumConfidenceMinValue: number
	highConfidenceMinValue: number
	primaryColor?: string
	secondaryColor?: string
	primaryScssColor?: string
	tertiaryScssColor?: string
	testId: string
}

export const DunsMatchOverview: FC<DunsMatchOverviewProps> = ({
	matchedCount,
	unmatchedCount,
	confidenceData,
	mediumConfidenceMinValue,
	highConfidenceMinValue,
	primaryColor = ColorMulberry,
	secondaryColor = ColorPinkLighter,
	primaryScssColor = '$color-mulberry',
	tertiaryScssColor = '$color-mulberry-lightest',
	testId
}: DunsMatchOverviewProps) => {
	const { t } = useTranslation()

	const chartData = Object()
	chartData[t('dashboard.dunsMatchOverview.matched')] = matchedCount
	chartData[t('dashboard.dunsMatchOverview.unmatched')] = unmatchedCount

	const chartColors = Object()
	chartColors[t('dashboard.dunsMatchOverview.matched')] = primaryColor
	chartColors[t('dashboard.dunsMatchOverview.unmatched')] = secondaryColor

	return (
		<div className={styles.dunsMatchOverview}>
			<Grid testId="container-duns-match-overview" container>
				<Grid testId="title-subtitle-duns-first" md={5} sm={5}>
					<div className={styles.header}>
						<div data-testid="title-matched-half-donut" className={styles.title}>
							{t('dashboard.dunsMatchOverview.title.1')}
						</div>
						<div className={styles.subtitle}>{t('dashboard.dunsMatchOverview.subtitle.1')}</div>
					</div>
				</Grid>
				<Grid testId="title-subtitle-duns-second" md={7} sm={7}>
					<div className={`${styles.header} ${styles.withMargin}`}>
						<div data-testid="title-by-confidence-code-half-donut" className={styles.title}>
							{t('dashboard.dunsMatchOverview.title.2')}
						</div>
						<div className={`${styles.subtitle} ${styles.italicized}`}>
							{t('dashboard.dunsMatchOverview.subtitle.2')}
						</div>
					</div>
				</Grid>
				<Grid testId="half-donut-duns-match" md={5} sm={5}>
					<HalfDonutChart
						colors={chartColors}
						data={chartData}
						defaultKey={t('dashboard.dunsMatchOverview.matched')}
						testId="dunsMatchDashboard"
					/>
				</Grid>
				<Grid testId="progress-bar-duns-match" md={7} sm={7}>
					<div className={styles.progressBarsConfidence}>
						<div className={`${styles.confidenceSpan} ${styles.low}`}>
							<div className={styles.progressBarGroup}>
								{confidenceData.map((data, confidence) => {
									if (confidence < mediumConfidenceMinValue) {
										return (
											<div
												className={styles.progressBarContainer}
												key={`pb-confidence-${confidence}`}
											>
												<ProgressBar
													id={`cc-${confidence}`}
													value={(data.rate / 100) * data.rate}
													total={data.rate}
													rounded="oneSide"
													position="vertical"
													precision={0}
													svgHeight={96}
													svgWidth={24}
													colors={{
														barColor: tertiaryScssColor,
														containerColor: primaryScssColor
													}}
													tooltipInfo={{
														effect: 'solid',
														position: 'top',
														values: [
															{
																label: t('dashboard.confidence.level'),
																value: t('dashboard.dunsMatchOverview.low')
															},
															{
																label: t('dashboard.total.records'),
																value: new Intl.NumberFormat().format(data.value)
															}
														],
														matchScore: confidence,
														testId: testId + '-dmo-' + confidence + '-tooltip'
													}}
												/>
											</div>
										)
									} else {
										return null
									}
								})}
							</div>
							<div className={styles.confidenceBar} style={{ backgroundColor: primaryColor }}>
								{confidenceData.map((data, confidence) => {
									if (confidence < mediumConfidenceMinValue) {
										return <span key={`confidence-${confidence}`}>{confidence}</span>
									} else {
										return null
									}
								})}
							</div>
							<div className={styles.confidenceLabel}>{t('dashboard.dunsMatchOverview.low')}</div>
						</div>
						<div className={`${styles.confidenceSpan} ${styles.medium}`}>
							<div className={styles.progressBarGroup}>
								{confidenceData.map((data, confidence) => {
									if (confidence >= mediumConfidenceMinValue && confidence < highConfidenceMinValue) {
										return (
											<div
												className={styles.progressBarContainer}
												key={`pb-confidence-${confidence}`}
											>
												<ProgressBar
													id={`cc-${confidence}`}
													value={(data.rate / 100) * data.rate}
													total={data.rate}
													rounded="oneSide"
													position="vertical"
													precision={0}
													svgHeight={96}
													svgWidth={24}
													colors={{
														barColor: tertiaryScssColor,
														containerColor: primaryScssColor
													}}
													tooltipInfo={{
														effect: 'solid',
														position: 'top',
														values: [
															{
																label: t('dashboard.confidence.level'),
																value: t('dashboard.dunsMatchOverview.medium')
															},
															{
																label: t('dashboard.total.records'),
																value: new Intl.NumberFormat().format(data.value)
															}
														],
														matchScore: confidence,
														testId: testId + '-dmo-' + confidence + '-tooltip'
													}}
												/>
											</div>
										)
									} else {
										return null
									}
								})}
							</div>
							<div className={styles.confidenceBar} style={{ backgroundColor: primaryColor }}>
								{confidenceData.map((data, confidence) => {
									if (confidence >= mediumConfidenceMinValue && confidence < highConfidenceMinValue) {
										return <span key={`confidence-${confidence}`}>{confidence}</span>
									} else {
										return null
									}
								})}
							</div>
							<div className={styles.confidenceLabel}>{t('dashboard.dunsMatchOverview.medium')}</div>
						</div>
						<div className={`${styles.confidenceSpan} ${styles.high}`}>
							<div className={styles.progressBarGroup}>
								{confidenceData.map((data, confidence) => {
									if (confidence >= highConfidenceMinValue) {
										return (
											<div
												className={styles.progressBarContainer}
												key={`pb-confidence-${confidence}`}
											>
												<ProgressBar
													id={`cc-${confidence}`}
													value={(data.rate / 100) * data.rate}
													total={data.rate}
													rounded="oneSide"
													position="vertical"
													precision={0}
													svgHeight={96}
													svgWidth={24}
													colors={{
														barColor: tertiaryScssColor,
														containerColor: primaryScssColor
													}}
													tooltipInfo={{
														effect: 'solid',
														position: 'top',
														values: [
															{
																label: t('dashboard.confidence.level'),
																value: t('dashboard.dunsMatchOverview.high')
															},
															{
																label: t('dashboard.total.records'),
																value: new Intl.NumberFormat().format(data.value)
															}
														],
														matchScore: confidence,
														testId: testId + '-dmo-' + confidence + '-tooltip'
													}}
												/>
											</div>
										)
									} else {
										return null
									}
								})}
							</div>
							<div className={styles.confidenceBar} style={{ backgroundColor: primaryColor }}>
								{confidenceData.map((data, confidence) => {
									if (confidence >= highConfidenceMinValue) {
										return <span key={`confidence-${confidence}`}>{confidence}</span>
									} else {
										return null
									}
								})}
							</div>
							<div className={styles.confidenceLabel}>{t('dashboard.dunsMatchOverview.high')}</div>
						</div>
						<div style={{ fontWeight: 'bold', color: primaryColor }}>%</div>
					</div>
				</Grid>
			</Grid>
		</div>
	)
}
