import { AxiosResponse } from 'axios'
import { mapBE2FEDelimiter } from '../../../helpers'
import { GetProjectResponse, GetSourceResponse, GetTeamResponse } from '../../../types'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import {
	newProjectAction,
	setFileAlreadyProcessed,
	setOverviewViewedAction,
	updateCurrentProjectAction
} from '../actions'
import { UpdateCurrentProjectRequest } from '../types'
import { readEnrichingLayoutIntoStore } from './readEnrichingLayoutIntoStore'
import { readMappingInfoIntoStore } from './readMappingInfoIntoStore'
import { readMatchingInfoIntoStore } from './readMatchingInfoIntoStore'

export const readProjectIntoStore =
	(
		id: string,
		isAPIEnabled: boolean,
		isC4S: boolean,
		includeSource?: boolean,
		sourceId?: string
	): AppThunk<Promise<void>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<void> => {
		const apiClient = getApiClient4Thunks(dispatch)
		let url = `/pls/projects/projectId/${id}?includeSources=true`
		dispatch(newProjectAction(isAPIEnabled, isC4S))
		return apiClient
			.get(url)
			.then(async (response: AxiosResponse<GetProjectResponse>) => {
				const projectData = response.data
				let readProject: UpdateCurrentProjectRequest = {
					id: projectData.projectId,
					projectDescription: projectData?.projectDescription || '',
					name: projectData.projectDisplayName,
					shouldSync: false,
					purposeOfUse: projectData.purposeOfUse
				}

				// Retrieve Source Info including c4s info
				if (sourceId || (includeSource && projectData.sources.length > 0)) {
					const selectedSourceId = sourceId ? sourceId : projectData.sources[0].sourceId
					url = `/pls/sources/sourceId/${selectedSourceId}?inflateMetadata=true`
					await apiClient.get(url).then(async (sourceResponse: AxiosResponse<GetSourceResponse>) => {
						const sourceData = sourceResponse.data
						dispatch(setOverviewViewedAction())
						dispatch(setFileAlreadyProcessed())

						let selectedFileInfo = undefined
						if (sourceData.delimiterInfo) {
							selectedFileInfo = {
								delimiter: mapBE2FEDelimiter(sourceData.delimiterInfo?.chosen_delimiter),
								autoDetectedDelimiter: mapBE2FEDelimiter(sourceData.delimiterInfo.detected_delimiter),
								escapeCharacter: sourceData.delimiterInfo.escape,
								textIdentifier: sourceData.delimiterInfo.quote
							}
						}
						readProject = {
							...readProject,
							source: {
								id: sourceData.sourceId,
								name: sourceData.sourceDisplayName,
								isApi: sourceData.isApi,
								isFile: sourceData.isFile,
								enableAme: sourceData.enableAme,
								stewardable: sourceData.stewardable,
								entityType: sourceData.entityType,
								enable_partial_match: sourceData?.enable_partial_match,
								enable_email_verification: sourceData?.enable_email_verification,
								directEnrich: sourceData?.direct_enrich
							},
							fileInfo: selectedFileInfo
						}
						dispatch(updateCurrentProjectAction(readProject))

						await readMappingInfoIntoStore(sourceData, apiClient, dispatch)
						await readMatchingInfoIntoStore(sourceData, apiClient, dispatch)
						await readEnrichingLayoutIntoStore(sourceData, apiClient, dispatch, getState, isC4S)

						// C4S info mapping
						if (sourceData.integrationInfo && sourceData.integrationInfo.integrationType !== 'DCP') {
							const crmSource =
								sourceData.integrationInfo.documentType.toLowerCase() === 'account'
									? 'account'
									: sourceData.integrationInfo.documentType.toLowerCase() === 'lead'
									? 'lead'
									: sourceData.integrationInfo.documentType.toLowerCase() === 'contact'
									? 'contact'
									: undefined // TODO: had to do this mapping due to the way this is interwined,
							// define what to do if we support additional CRM objects

							if (crmSource)
								dispatch(
									updateCurrentProjectAction({
										thirdPartyIntegration: {
											crmSource: crmSource
										}
									})
								)
						}
					})
				} else dispatch(updateCurrentProjectAction(readProject))

				// End Retrieve

				url = `/pls/teams/teamId/${projectData.teamId}`
				await apiClient.get(url).then((teamResponse: AxiosResponse<GetTeamResponse>) => {
					const teamData = teamResponse.data
					dispatch(updateCurrentProjectAction({ team: teamData }))
				})
			})
			.catch((err) => console.log(err))
	}
