import { MouseEvent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { getLocalizedDateString, numberDelimeters } from '../../helpers'
import { Avatar, Button, ContextMenu, ContextMenuOption } from '../../local-core-ui'
import { RootState, useAppSelector } from '../../store'
import { UserDetail } from '../../types'
import { Spinner } from '../spinner/spinner'
import styles from './project-source-file-bar.module.scss'

interface ProjectSourceFileBarContextMenu {
	options: ContextMenuOption[]

	onClick(option: string, idx: number): void
}

interface ActionButton {
	text: string

	onClick(event: MouseEvent<HTMLButtonElement>): void
}

interface BasicStats {
	successCnt?: number
	matchedCnt?: number
	unmatchedCnt?: number
}

interface ProjectSourceFileBarProps {
	name: string
	createDate: Date | number
	createdBy: string
	isArchived: boolean
	color: string

	onClick(): void

	testId: string
	contextMenu?: ProjectSourceFileBarContextMenu
	actionButton?: ActionButton
	basicStats?: BasicStats
	teamMembers?: UserDetail[]
	isLoading?: boolean
}

export const ProjectSourceFileBar = ({
	name,
	createDate,
	createdBy,
	isArchived,
	color,
	onClick,
	testId,
	contextMenu,
	actionButton,
	basicStats,
	teamMembers,
	isLoading
}: ProjectSourceFileBarProps): ReactElement => {
	const { t } = useTranslation()
	const selectSession = (state: RootState) => state.session
	const sessionState = useAppSelector(selectSession)
	const currentUser = sessionState.user?.DisplayName
	const wasCreatedByMe = createdBy === currentUser
	const createdByText = wasCreatedByMe ? t('project.source.file.bar.me') : createdBy

	return (
		<div
			tabIndex={isArchived ? -1 : 0}
			role="button"
			className={`${styles.projectSourceFileBarContainer} ${isArchived ? styles.disabled : ''}`}
			data-testid="project-bar-dashboard"
			style={{ backgroundColor: color }}
			onClick={() => {
				if (!isArchived) {
					onClick()
				}
			}}
			onKeyPress={(event) => {
				if (event.key === 'Enter') {
					onClick()
				}
			}}
		>
			<div
				data-testid="project-source-file-bar-info-container"
				className={styles.projectSourceFileBarInfoContainer}
			>
				<p data-testid="project-name" className={styles.projectSourceFileBarName} title={name}>
					{name}
				</p>
				<p data-testid="created-by-on" className={styles.projectSourceFileBarDateBy}>
					{t('project.source.file.bar.date')}
					<span data-testid="created-on" className={styles.projectSourceFileBarDate}>
						{getLocalizedDateString(createDate)}
					</span>
					{t('project.source.file.bar.by')}
					<span
						data-testid="created-by"
						className={`${styles.projectSourceFileBarBy} ${wasCreatedByMe ? styles.bgLilac : ''}`}
					>
						{createdByText}
					</span>
				</p>
			</div>
			<div className={styles.projectSourceFileBarRightInfoContainer}>
				{teamMembers && (
					<div className={styles.projectSourceFileBarTeamMembers}>
						{teamMembers.slice(0, 3).map((userName, index) => (
							<div key={index} className={styles.projectSourceFileBarTeamMember}>
								<Avatar
									userData={[{ label: 'Name', value: userName.FirstName + ' ' + userName.LastName }]}
									testId={testId + '-avatar-' + userName}
								/>
							</div>
						))}
						{teamMembers.length > 3 && (
							<div className={styles.projectSourceFileBarTeamPlusMember}>{`+${
								teamMembers.length - 3
							}`}</div>
						)}
					</div>
				)}
				{basicStats && (
					<div className={styles.projectSourceFileBarStatsContainer}>
						<p data-testid="submitted-title-project-stats" className={styles.projectSourceFileBarStats}>
							{t('project.status.submitted')}
							<span
								data-testid="total-submitted-records"
								className={styles.projectSourceFileBarStatsData}
							>
								{numberDelimeters(basicStats.successCnt)}
							</span>
						</p>
						<p data-testid="matched-title-project-stats" className={styles.projectSourceFileBarStats}>
							{t('project.status.matched')}
							<span data-testid="total-matched-records" className={styles.projectSourceFileBarStatsData}>
								{numberDelimeters(basicStats.matchedCnt)}
							</span>
						</p>
						<p data-testid="unmatched-title-project-stats" className={styles.projectSourceFileBarStats}>
							{t('project.status.unmatched')}
							<span
								data-testid="total-unmatched-records"
								className={styles.projectSourceFileBarStatsData}
							>
								{numberDelimeters(basicStats.unmatchedCnt)}
							</span>
						</p>
					</div>
				)}
				{isLoading && (
					<div className={styles.projectSourceFileBarSpinnerContainer}>
						<Spinner />
					</div>
				)}
				<div className={styles.projectSourceFileBarActionButton} onClick={(event) => event.stopPropagation()}>
					{actionButton ? (
						<Button
							text={actionButton.text}
							onClick={actionButton.onClick}
							testId={testId + '-action-button'}
						/>
					) : undefined}
					{contextMenu ? (
						<div className={styles.contextMenuWrapper}>
							<ContextMenu
								options={contextMenu.options}
								onMenuClicked={contextMenu.onClick}
								alignment="left"
								testId={testId + '-menu'}
							/>
						</div>
					) : undefined}
				</div>
			</div>
		</div>
	)
}
