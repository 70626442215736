import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { useApi } from '../hooks'
import { Record, RecordClassification } from '../types'
import { changeRecordsToStewardClassification } from './api/changeRecordsToStewardClassification'

export interface ChangeRecordsToStewardClassificationParams {
	assignmentId: string
	newClassification: RecordClassification
	records: Array<Record>
}
export const useChangeRecordsToStewardClassification = (): UseMutationResult<
	void,
	unknown,
	ChangeRecordsToStewardClassificationParams,
	unknown
> => {
	const apiClient = useApi()
	const queryClient = useQueryClient()

	const mutationFn = ({ assignmentId, newClassification, records }: ChangeRecordsToStewardClassificationParams) =>
		changeRecordsToStewardClassification(apiClient, assignmentId, newClassification, records)

	return useMutation(mutationFn, {
		onSuccess: () => {
			const queryKey = ['getRecordsToSteward']
			queryClient.invalidateQueries(queryKey)
			// Invalidate all the queries related to RecordsToSteward
		}
	})
}
