export enum STATES {
	ACTIVE = 'active',
	IDLE = 'idle',
	INACTIVE = 'inactive'
}

type callback = () => void
type eventListener = (eventName: STATES, listener: callback) => callback
export interface ActivityMonitorReturn {
	on: eventListener
	stop: callback
	reset: callback
}

export interface ActivityMonitorParameters {
	timeToIdle?: number
	timeToInactive?: number
}

export const activityMonitor = ({
	timeToIdle = 30000,
	timeToInactive = 120000
}: ActivityMonitorParameters): ActivityMonitorReturn => {
	const INITIAL_STATE: STATES = STATES.ACTIVE

	const ACTIVITY_EVENTS = [
		'click',
		'mousemove',
		'keydown',
		'DOMMouseScroll',
		'mousewheel',
		'mousedown',
		'touchstart',
		'touchmove',
		'focus'
	]

	const listeners: Record<STATES, Array<callback>> = { [STATES.ACTIVE]: [], [STATES.IDLE]: [], [STATES.INACTIVE]: [] }
	let state: STATES
	let timer: NodeJS.Timeout

	const registerHandlers = () => {
		ACTIVITY_EVENTS.forEach((eventName) => window.addEventListener(eventName, handleUserActivityEvent))
	}

	const unregisterHandlers = () => {
		ACTIVITY_EVENTS.forEach((eventName) => window.removeEventListener(eventName, handleUserActivityEvent))
	}

	const setState = (newState: STATES) => {
		clearTimeout(timer)
		if (newState === STATES.ACTIVE) {
			timer = setTimeout(() => setState(STATES.IDLE), timeToIdle)
		} else if (newState === STATES.IDLE) {
			timer = setTimeout(() => setState(STATES.INACTIVE), timeToInactive - timeToIdle)
			unregisterHandlers()
		}
		if (state !== newState) {
			state = newState
			listeners[state].forEach((l) => l())
		}
	}

	const handleUserActivityEvent = () => {
		if (state !== STATES.INACTIVE) {
			setState(STATES.ACTIVE)
		}
	}

	/**
	 * Starts the activity detector with the given state.
	 * @param {string} firstState 'idle' or 'active'
	 */
	const init = (firstState = INITIAL_STATE) => {
		setState(firstState)
		registerHandlers()
	}

	/**
	 * Register an event listener for the required event
	 * @param {string} eventName 'active' or 'idle'
	 * @param {Function} listener
	 */
	const on = (eventName: STATES, listener: callback) => {
		listeners[eventName].push(listener)
		const off = () => {
			const index = listeners[eventName].indexOf(listener)
			if (index >= 0) {
				listeners[eventName].splice(index, 1)
			}
		}
		return off
	}

	/**
	 * Stops the activity detector and clean the listeners
	 */
	const stop = () => {
		listeners[STATES.ACTIVE] = []
		listeners[STATES.IDLE] = []

		clearTimeout(timer)
		unregisterHandlers()
	}

	init(INITIAL_STATE)

	return { on, stop, reset: init }
}
