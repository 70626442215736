import { useHistory, useRouteMatch } from 'react-router-dom'
import { useRouteQuery } from '../../../hooks/useRouteQuery'
import { updateLoadingNextStep } from '../../../store/projectWizard/actions'
import { WizardDefinition } from '../definitions/wizardDefinition'
import { composeRoute } from '../helpers/composeRoute'
import {
	WizardComposerStepDefinition,
	WizardComposerSubStepDefinition,
	WizardComposerSubStepStates
} from '../types/WizardComposerStepDefinition'
import { WizardRoute } from '../types/WizardRoute'
import { extractWizardKey, WizardKey } from '../wizardComposer'
import { useWizardContext } from './useWizardContext'

export interface WizardRouteMatchResult {
	currentStep?: WizardComposerStepDefinition
	currentSubStep?: WizardComposerSubStepDefinition
	next: () => Promise<void>
	previous: () => Promise<void>
	isNextDisabled: () => boolean
	isPreviousDisabled: () => boolean
	areWeDoneYet: () => boolean
	currentStepRoute?: WizardRoute
	currentStepIndex: number
	lastEnabledStepIndex: number
	currentStepRouteExtraInfo?: unknown
	ready: boolean
}

export const useWizardRouteMatch = (): WizardRouteMatchResult => {
	const query = useRouteQuery()
	const projectId = query.get('projectId')
	const sourceId = query.get('sourceId') || undefined

	const history = useHistory()
	const wizardStepParams = useRouteMatch<WizardKey>('/:prefix/:wizKey/:step')
	const wizardSubStepParams = useRouteMatch<WizardKey>('/:prefix/:wizKey/:steps/:substep')

	const { wizardContext, dynamicWizardContext } = useWizardContext()
	const activeWizardDefinitionRoutes = WizardDefinition.filter(
		(route) => !(route.disabled && route.disabled(wizardContext))
	)

	let goToLastStep = false
	if (activeWizardDefinitionRoutes && wizardStepParams && wizardStepParams.params.step === 'lastStep') {
		goToLastStep = true
		const lastStep = activeWizardDefinitionRoutes
			.reverse()
			.find((route) => route.stepDefinition(wizardContext, dynamicWizardContext).progress() !== 0)
		if (lastStep) {
			const lastStepKeys = extractWizardKey(lastStep.path)
			if (lastStepKeys?.step) wizardStepParams.params.step = lastStepKeys.step
		}
	}

	const addParamsToWizardRoute = (path: string) => {
		const projectParam = projectId ? `projectId=${projectId}` : undefined
		const sourceParam = sourceId ? `sourceId=${sourceId}` : undefined

		if (projectParam && sourceParam) {
			return `${path}?${projectParam}&${sourceParam}`
		} else {
			if (projectParam) {
				return `${path}?${projectParam}`
			} else {
				if (sourceParam) return `${path}?${sourceParam}`
				else return path
			}
		}
	}

	const currentStepRoute = activeWizardDefinitionRoutes.find((wizardRoute) => {
		const routeWizKey = extractWizardKey(wizardRoute.path)
		return (
			wizardStepParams &&
			routeWizKey?.wizKey === wizardStepParams.params.wizKey &&
			routeWizKey.step === wizardStepParams.params.step
		)
	})

	const stepIdx = activeWizardDefinitionRoutes.findIndex((stepRoute) => stepRoute === currentStepRoute)

	const whoCalledMeRoute = stepIdx > 0 ? activeWizardDefinitionRoutes[stepIdx - 1] : undefined

	const nextPath =
		stepIdx < activeWizardDefinitionRoutes.length - 1 ? activeWizardDefinitionRoutes[stepIdx + 1].path : undefined
	const currentStep = currentStepRoute?.stepDefinition(wizardContext, dynamicWizardContext)

	const subSteps = typeof currentStep?.subSteps === 'function' ? currentStep?.subSteps() : currentStep?.subSteps

	let currentSubStep = subSteps?.find((subStep) => {
		const subStepRoute = subStep.route.path.replace('/', '')
		const subStepFullRoute = composeRoute(
			subStep.route,
			`/${wizardStepParams?.params.prefix}/${wizardStepParams?.params.wizKey}/${wizardStepParams?.params.step}`
		)
		return (
			(wizardSubStepParams && subStepRoute === wizardSubStepParams.params.substep) ||
			subStepFullRoute.path === wizardStepParams?.url
		)
	})

	if (!currentSubStep && (dynamicWizardContext.ready || currentStep?.subSteps instanceof Array)) {
		if (
			wizardSubStepParams?.params.substep === undefined ||
			wizardSubStepParams?.params.substep === '' ||
			goToLastStep
		) {
			const currentStepCompletedSubSteps = subSteps?.filter(
				(subStep) => subStep.state() === WizardComposerSubStepStates.complete
			)

			if (currentStepCompletedSubSteps && currentStepCompletedSubSteps.length > 0)
				currentSubStep = currentStepCompletedSubSteps.reverse()[0]
		}

		// I know I've asked for !currentSubStep above, but, if the filter reverse doesn't provide a currentSubStep,
		// I need to return something
		if (!currentSubStep && subSteps) {
			currentSubStep = subSteps[0]
		}
	}

	const subStepIdx = subSteps?.findIndex((subStep) => subStep === currentSubStep)

	const moveFwd = async () => {
		if (currentStep) {
			if (subSteps && subStepIdx !== undefined && subSteps.length > subStepIdx + 1) {
				const nextSubStep = subSteps[subStepIdx + 1]
				const nextSubStepRoute = composeRoute(
					nextSubStep.route,
					`/${wizardStepParams?.params.prefix}/${wizardStepParams?.params.wizKey}/${wizardStepParams?.params.step}`
				)
				history.push(addParamsToWizardRoute(nextSubStepRoute.path))
			} else {
				const stepAction = currentStep.onNext
				if (stepAction) {
					wizardContext.dispatch(updateLoadingNextStep(true))
					await stepAction()
						.then(() => {
							if (nextPath) history.push(addParamsToWizardRoute(nextPath))

							//else history.push('/dashboard')
							// TODO: What should we do if no nextPath?
						})
						.catch((err) => console.error(err))
						.finally(() => wizardContext.dispatch(updateLoadingNextStep(false)))
				} else {
					if (nextPath) history.push(addParamsToWizardRoute(nextPath))

					//else history.push('/dashboard')
					// TODO: What should we do if no nextPath?
				}
			}
		}
	}

	const nextStepClick = async () => {
		if (currentSubStep) {
			const subStepAction = currentSubStep.onNext
			if (subStepAction) {
				wizardContext.dispatch(updateLoadingNextStep(true))
				await subStepAction()
					.then(() => moveFwd())
					.catch((err: unknown) => {
						if (err) console.error(err)
					})
					.finally(() => wizardContext.dispatch(updateLoadingNextStep(false)))
			} else {
				await moveFwd()
			}
		}
	}

	const moveBck = async () => {
		if (currentStep) {
			if (subSteps && subStepIdx && subStepIdx > 0) {
				const previousSubStep = subSteps[subStepIdx - 1]
				const previousSubStepRoute = composeRoute(
					previousSubStep.route,
					`/${wizardStepParams?.params.prefix}/${wizardStepParams?.params.wizKey}/${wizardStepParams?.params.step}`
				)
				history.push(addParamsToWizardRoute(previousSubStepRoute.path))
			} else {
				const stepAction = currentSubStep?.onPrevious
				if (stepAction) {
					wizardContext.dispatch(updateLoadingNextStep(true))
					await stepAction()
						.then(() => {
							if (whoCalledMeRoute?.path) history.push(addParamsToWizardRoute(whoCalledMeRoute.path))
						})
						.finally(() => wizardContext.dispatch(updateLoadingNextStep(false)))
				} else {
					if (whoCalledMeRoute?.path) history.push(addParamsToWizardRoute(whoCalledMeRoute.path))
				}
			}
		}
	}

	const previousStepClick = async () => {
		const subStepAction = currentSubStep?.onPrevious

		if (subStepAction) {
			wizardContext.dispatch(updateLoadingNextStep(true))

			await subStepAction()
				.then(() => moveBck())
				.catch((err) => {
					if (err) console.error(err)
				})
				.finally(() => wizardContext.dispatch(updateLoadingNextStep(false)))
		} else {
			await moveBck()
		}
	}

	const isPreviousDisabled = () =>
		wizardStepParams?.params.prefix === 'step' ? subStepIdx === 0 : stepIdx === 0 && subStepIdx === 0

	const isNextDisabled = () => !currentSubStep?.isNextEnabled()

	const areWeDoneYet = () =>
		wizardStepParams?.params.prefix === 'step'
			? (subStepIdx || 0) === (subSteps?.length || 0) - 1
			: stepIdx === activeWizardDefinitionRoutes.length - 1 && subStepIdx === (subSteps?.length || 0) - 1

	const stepsEnabledState = activeWizardDefinitionRoutes.map((wizRoute) => {
		const stepDefintion = wizRoute.stepDefinition(wizardContext, dynamicWizardContext)
		const subStepOrFunction = stepDefintion?.subSteps
		const subSteps = typeof subStepOrFunction === 'function' ? subStepOrFunction() : subStepOrFunction

		return stepDefintion.progress() === 100 || subSteps?.every((subStep) => subStep.isNextEnabled())
	})

	const lastEnabledStepIndex = stepsEnabledState.lastIndexOf(true)
	const firstDisabledStepIndex = stepsEnabledState.findIndex((enabled) => !enabled)

	const currentStepRouteExtraInfo =
		typeof currentStepRoute?.extraInfo === 'function'
			? currentStepRoute.extraInfo(wizardContext, dynamicWizardContext)
			: currentStepRoute?.extraInfo

	return {
		currentStep,
		currentSubStep,
		next: nextStepClick,
		previous: previousStepClick,
		isNextDisabled,
		isPreviousDisabled,
		areWeDoneYet,
		currentStepRoute,
		currentStepIndex: stepIdx,
		lastEnabledStepIndex:
			firstDisabledStepIndex > -1 ? firstDisabledStepIndex : lastEnabledStepIndex > -1 ? lastEnabledStepIndex : 0,
		currentStepRouteExtraInfo,
		ready: dynamicWizardContext.ready && currentSubStep !== undefined
	}
}
