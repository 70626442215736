import { Children, cloneElement, FC, PropsWithChildren, ReactElement, useEffect, useState } from 'react'

interface AccordionProps {
	testId: string
	allowMultipleOpen?: boolean
	id: string
	expanded?: boolean
}

const getInitialItemOpenState = (childs, allowMultipleOpen) => {
	const getHeaders = childs.map((child, index) => {
		if (index % 2 === 0) {
			return (allowMultipleOpen && child['props'].isOpen) || false
		}
	})
	const initialOpenState = getHeaders.filter((position) => position !== undefined)
	if (!allowMultipleOpen) {
		// We can't have multiple open Items so go grab the first one that is open and flag it as such
		for (let j = 0, i = 0; i < childs.length; i = i + 2, j++) {
			const child = childs[i]
			if (child['props'].isOpen) {
				initialOpenState[j] = true
				break
			}
		}
	}
	return initialOpenState
}

export const Accordion: FC<PropsWithChildren<AccordionProps>> = ({
	children,
	allowMultipleOpen = false,
	testId,
	id,
	expanded
}: PropsWithChildren<AccordionProps>) => {
	const childrenArray = Children.toArray(children)
	const [openSections, setOpenSections] = useState(getInitialItemOpenState(childrenArray, allowMultipleOpen))

	const onChange = (indexItem: number) => {
		const isOpen = !!openSections[indexItem]
		if (allowMultipleOpen) {
			setOpenSections(
				openSections.map((section, index) => {
					if (index === indexItem) {
						return !isOpen
					} else {
						return section
					}
				})
			)
		} else {
			setOpenSections(
				openSections.map((section, index) => {
					if (index === indexItem) {
						return !isOpen
					} else {
						if (!isOpen) {
							return false
						} else {
							return section
						}
					}
				})
			)
		}
	}

	const generateAccordionHeader = (child: ReactElement, index: number) => {
		return cloneElement(child, {
			onChange,
			isOpen: openSections[index],
			index: index,
			testId
		})
	}

	const generateAccordionPanel = (child: ReactElement, withControlElement: boolean, isOpen: boolean) => {
		return cloneElement(child, {
			isOpen: isOpen,
			existControlElement: withControlElement
		})
	}

	const generateAccordionItem = (child: ReactElement, index: number) => {
		if (child.props['controls']) {
			const panelIndex = childrenArray.findIndex((childArray: ReactElement) => {
				return childArray.props.id === child.props['controls']
			})
			const panel = childrenArray[panelIndex] as ReactElement
			const withControlElement = !!child.props.children
			const indexHeader = Math.trunc(index / 2)
			const isOpen = openSections[indexHeader]
			return (
				<div key={id + '-accordion-item-' + indexHeader}>
					{generateAccordionHeader(child, indexHeader)}
					{panel !== undefined && generateAccordionPanel(panel, withControlElement, isOpen)}
				</div>
			)
		}
		return null
	}

	useEffect(() => {
		if (expanded != undefined) {
			setOpenSections(
				openSections.map(() => {
					return expanded
				})
			)
		}
	}, [expanded])

	return (
		<div data-testid={`accordion-${testId}`} id={id + '-accordion'}>
			{childrenArray.map((child: ReactElement, index: number) => generateAccordionItem(child, index))}
		</div>
	)
}
export default Accordion
