import designTokens from '@dnb-uux-design-system/design-tokens/build/index'
import { useTranslation } from 'react-i18next'
import { ReportSnackbar } from '../../../../../components/report-snackbar/report-snackbar'
import { ReportTile } from '../../../../../components/report-tile/report-tile'
import { HalfDonutChart } from '../../../../../local-core-ui'
import { DataReport } from '../../../../../types'
import styles from './match-results.module.scss'

interface MatchResultsProps {
	contactMatchReport: DataReport['matchToContactReport'] | null
}

const MatchResults = ({ contactMatchReport }: MatchResultsProps) => {
	const { t } = useTranslation()
	const { TokenChartColors, TokenColors } = designTokens

	const displayMatchResults = contactMatchReport && contactMatchReport.matchedRate > 0
	const centerTextColor = TokenColors.ColorGrayPrimary
	const zeroStateColor = TokenChartColors.ColorChartUncategory3

	const chartData = Object()
	const chartColors = {
		[t('dashboard.contactMatchOverview.matched')]: TokenChartColors.ColorChartCategory1,
		[t('dashboard.contactMatchOverview.unmatched')]: TokenChartColors.ColorChartCategory1Alpha2
	}
	const chartKey = t('dashboard.contactMatchOverview.matched') as string

	if (contactMatchReport) {
		chartData[t('dashboard.contactMatchOverview.matched')] = contactMatchReport.matched
		chartData[t('dashboard.contactMatchOverview.unmatched')] = contactMatchReport.unmatched
	}

	return (
		<ReportTile className={styles.contactMatchChartContainer}>
			<span className={styles.subtitle}>
				{t('report.page.contactMatchSummary.tile.matchResults.title') as string}
			</span>
			<span className={styles.compact}>
				{t('report.page.contactMatchSummary.tile.matchResults.subtitle') as string}
			</span>
			{displayMatchResults ? (
				<>
					<div className={styles.chartWrapper}>
						<HalfDonutChart
							centerTextColor={centerTextColor}
							colors={chartColors}
							data={chartData}
							defaultKey={chartKey}
							disableHiddenBar
							legendOrientation="horizontal"
							testId="match-results-half-donut-chart"
							zeroStateColor={zeroStateColor}
						/>
					</div>
				</>
			) : (
				<div className={styles.matchResultsSnackbarContainer}>
					<ReportSnackbar />
				</div>
			)}
		</ReportTile>
	)
}

export { MatchResults }
