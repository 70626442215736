import { t } from 'i18next'
import { EnrichingBlock } from '../../../../store/projectWizard/types'
import { ElementDetail } from '../../../../types'
import { BlockCollection } from '../enriching-crm-source/enrich-crm-data-source'

export const sortDataBlockList = (
	a: ElementDetail | EnrichingBlock | BlockCollection,
	b: ElementDetail | EnrichingBlock | BlockCollection
) => {
	const aName = t(a.blockId)
	const bName = t(b.blockId)
	// The ticket (DCP-2117) ask to order the list by adding at the top the Company Entity Resolution and then sort it by alphabetical order
	if (aName === t('baseinfo')) {
		return -1
	} else if (bName === t('baseinfo')) {
		return 1
	} else {
		// DCP-5594,5595,5598 contact block should be visible at top after base info
		if (aName === t('contacts')) {
			return -1
		} else if (bName === t('contacts')) {
			return 1
		} else if (aName === t('entityresolution')) {
			return -1
		} else if (bName === t('entityresolution')) {
			return 1
		} else if (aName < bName) {
			return -1
		} else if (aName > bName) {
			return 1
		} else {
			return 0
		}
	}
}
