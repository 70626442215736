import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next'

export interface Locale {
	key: string
	short: string
	long: string
	dictionary?: unknown
}

export const supportedLocales: Locale[] = [
	{ key: 'cs-CZ', short: 'CZE', long: 'Czech' },
	{ key: 'da-DK', short: 'DAN', long: 'Dansk' },
	{ key: 'de-DE', short: 'DEU', long: 'Deutsch' },
	{ key: 'en-US', short: 'ENG', long: 'English' },
	{ key: 'es-ES', short: 'SPA', long: 'Español' },
	{ key: 'es-MX', short: 'SPA', long: 'Español (México)' },
	{ key: 'fi-FI', short: 'FIN', long: 'Suomi' },
	{ key: 'fr-FR', short: 'FRA', long: 'Français' },
	{ key: 'hu-HU', short: 'HUN', long: 'Magyar' },
	{ key: 'ja-JP', short: 'JPN', long: '日本' },
	{ key: 'nl-NL', short: 'NLD', long: 'Nederlands' },
	{ key: 'pt-PT', short: 'POR', long: 'Português' },
	{ key: 'sl-SI', short: 'SVN', long: 'Slovenščina' },
	{ key: 'sv-SE', short: 'SWE', long: 'Svenska' },
	{ key: 'zh-CN', short: 'CHI', long: '简体中文' },
	{ key: 'zh-TW', short: 'CHI', long: '繁體中文' }
]

i18n.use(
	resourcesToBackend((language, _namespace, callback) => {
		import(`./dcp-ui-languages/src/locales/${language}.json`)
			.then(({ default: resources }) => {
				callback(null, resources)
			})
			.catch((error) => {
				console.log(error)
				callback(error, null)
			})
		//}
	})
)
	.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		fallbackLng: 'en-US',
		keySeparator: false, // we do not use keys in form messages.welcome
		interpolation: {
			escapeValue: false // react already safes from xss
		},
		debug: false,
		supportedLngs: supportedLocales.map((language) => language.key),
		load: 'currentOnly'
	})

export default i18n
