import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { GetEnrichmentRulesResponse } from '../types'
import { getTemplateEnrichmentRules } from './api/getTemplateEnrichmentRules'

export const useEnrichmentRulesTemplate = (sourceId: string): UseQueryResult<GetEnrichmentRulesResponse, unknown> => {
	const apiClient = useApi()

	const queryKey = ['getTemplateEnrichmentRules', sourceId]
	const queryFunction = (): Promise<GetEnrichmentRulesResponse> => getTemplateEnrichmentRules(apiClient, sourceId)
	return useQuery(queryKey, queryFunction)
}
