import { MutableRefObject } from 'react'
import { TFunction } from 'react-i18next'
import assessmentPaydexScoreHistoryPrefixes from '../assessment-paydex-score-history-prefixes.json'
import { ElementUIFacade } from '../ElementUIFacade'

const buildChildrenTree = (
	listFlattenElementsAssessmentPaydexScoreHistory: Array<ElementUIFacade>,
	root: ElementUIFacade,
	t: TFunction<'translation'>,
	assessmentPaydexScoreSelectorValueRef: MutableRefObject<number>
) => {
	const groupElements: Array<ElementUIFacade> = []
	assessmentPaydexScoreHistoryPrefixes.forEach((prefix) => {
		const elementList = listFlattenElementsAssessmentPaydexScoreHistory.filter((paydexScoreElement) =>
			paydexScoreElement.elementId.startsWith(prefix)
		)
		if (elementList && elementList.length > 0) {
			const groupParent: ElementUIFacade = {
				parent: root,
				elementId: `${prefix}Id`,
				displayName: t(`${prefix}.element`),
				description: elementList[0].description,
				level: elementList[0].level,
				dataType: elementList[0].dataType,
				example: elementList[0].example,
				showChildrenBlocks: false,
				considerChildrenForCount: false,
				maximumElementsInOutput: assessmentPaydexScoreSelectorValueRef,
				isRequiredByDefault: prefix === 'trade_smry_paydex_hist_value_'
			}
			elementList.sort((a: ElementUIFacade, b: ElementUIFacade) => {
				if (a.elementId > b.elementId) {
					return 1
				} else if (a.elementId < b.elementId) {
					return -1
				} else {
					return 0
				}
			})
			groupParent.childs = elementList
			groupElements.push(groupParent)
		}
	})
	return groupElements
}

export const buildAssessmentPaydexScoreHistoryTree = (
	listFlattenElementsAssessmentPaydexScoreHistory: Array<ElementUIFacade>,
	t: TFunction<'translation'>,
	assessmentPaydexScoreSelectorValueRef: MutableRefObject<number>
): Array<ElementUIFacade> => {
	const tree: Array<ElementUIFacade> = []
	if (listFlattenElementsAssessmentPaydexScoreHistory.length) {
		const root: ElementUIFacade = {
			blockId: t(listFlattenElementsAssessmentPaydexScoreHistory[0].blockId || ''),
			elementId: 'assessmentPaydexScoreHistoryId',
			displayName: t('assessmentPaydexScoreHistory.element'),
			description: t('assessmentPaydexScoreHistory.description'),
			level: '4',
			dataType: t('ARRAY'),
			showChildrenBlocks: true,
			containsChildrenSelector: true,
			considerChildrenForCount: true,
			maximumElementsInOutput: assessmentPaydexScoreSelectorValueRef,
			outputDropdownOptions: [...Array(24).keys()].map((i) => ({
				value: (i + 1).toString(),
				label: (i + 1).toString()
			}))
		}
		tree.push(root)

		root.childs = buildChildrenTree(
			listFlattenElementsAssessmentPaydexScoreHistory,
			root,
			t,
			assessmentPaydexScoreSelectorValueRef
		)
	}
	return tree
}
