import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from '../../../local-core-ui'
import styles from '../appID-s3pathway-copy-modal.module.scss'

interface AppIdCopyToClipboardModalProps {
	open: boolean
	onClose(): void
	testId: string
	showButtonClose?: boolean
	appId: boolean
	textToClipboard: string
}

export const AppIdCopyToClipboardModal = ({
	open,
	onClose,
	testId,
	showButtonClose = true,
	appId,
	textToClipboard
}: AppIdCopyToClipboardModalProps): ReactElement => {
	const { t } = useTranslation()
	const modalContent = appId
		? {
				title: t('upload.tile.context.copyAppId.modal.title'),
				description: t('upload.tile.context.copyAppId.modal.description'),
				idType: 'appId'
		  }
		: {
				title: t('upload.tile.context.copyS3.modal.title'),
				description: t('upload.tile.context.copyS3.modal.description'),
				idType: 'S3'
		  }

	return (
		<div className={styles.confirmationModalSourceContainer} data-testid="confirmation-modal-source-container">
			<Modal open={open} onClose={onClose} showButtonClose={showButtonClose} testId="confirmation-modal-source">
				<div className={styles.appIdModal} data-testid="confirmation-modal-content-container">
					<p
						className={styles.appIdModalTitle}
						data-testid={`confirmation-modal-title-${modalContent.idType}`}
					>
						{modalContent.title}
					</p>
					<p
						className={styles.appIdModalDescription}
						data-testid={`confirmation-modal-description-${modalContent.idType}`}
					>
						{modalContent.description}
					</p>
					<p
						className={styles.appIdModalDescription}
						data-testid={`confirmation-modal-id-text-to-clipboard-${modalContent.idType}`}
					>
						{textToClipboard}
					</p>
				</div>

				<div
					className={styles.confirmationModalSourceButtonsContainer}
					data-testid="confirmation-modal-button-container"
				>
					<Button
						text={t('source.refreshNow.disable.btn.disable')}
						onClick={onClose}
						testId={`${testId}-cancel`}
					/>
				</div>
			</Modal>
		</div>
	)
}
