import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { CheckBox } from '../../local-core-ui'
import { useRecordsToSteward } from '../../queries/useRecordsToSteward'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import { setSelectedRecords } from '../../store/steward/stewardSlice'
import { Record } from '../../types'
import { CompanyDetailTile } from '../company-detail-tile/company-detail-tile'
import styles from './company-list-manager.module.scss'
import { CompanyListToolBar } from './company-list-tool-bar'

type recordSortBy = 'nameAsc' | 'nameDesc' | 'confidenceCodeHigh' | 'confidenceCodeLow'

interface CompanyListManagerProps {
	isMultiSelectActive: boolean
	onToggleMultiSelect(): void
	currentSorting?: recordSortBy
	onSortChange(sortBy: recordSortBy): void
}

const CompanyListManager = ({
	isMultiSelectActive,
	onToggleMultiSelect,
	currentSorting = 'nameAsc',
	onSortChange
}: CompanyListManagerProps): ReactElement => {
	const [selectAll, setSelectAll] = useState<boolean>(false)
	const [showDetails, setShowDetails] = useState<boolean>(false)
	const selectSteward = (state: RootState) => state.steward
	const steward = useAppSelector(selectSteward)
	const { forTile, forAssignee, forAssignment, selectedRecords, pageSize, pageIndex, requestedClassification } =
		steward

	const recordsToStewardQuery = useRecordsToSteward(
		forTile,
		pageIndex,
		pageSize,
		requestedClassification,
		forAssignee,
		forAssignment
	)

	const dispatch = useAppDispatch()

	const handleRecordRemovedInMultiSelectView = () => {
		if (recordsToStewardQuery.data) {
			const missingRecords = recordsToStewardQuery.data.records.filter(isNotSelected)
			if (selectAll && missingRecords.length !== 0) {
				setSelectAll(false)
			}
		}
	}

	useEffect(() => {
		if (isMultiSelectActive) {
			handleRecordRemovedInMultiSelectView()
		}
		/**
		 * handleRecordRemovedInMultiSelectView is not added as a dependency because it would cause the other
		 * dependencies to change with each render.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedRecords.length])

	const handleOnSelect = (selectedRecord: Record) => {
		const addRecord = !selectedRecords.some((record) => record.recordId === selectedRecord.recordId)
		if (isMultiSelectActive) {
			updateSelectedCompanies(addRecord, selectedRecord)
		} else {
			if (addRecord) {
				dispatch(setSelectedRecords([selectedRecord]))
			} else {
				dispatch(setSelectedRecords([]))
			}
		}
	}

	useEffect(() => {
		if (!isMultiSelectActive) {
			if (selectedRecords.length > 1) {
				dispatch(setSelectedRecords([]))
			}
			if (selectAll) {
				setSelectAll(false)
			}
		}
		/**
		 * selectAll is not added as a dependency because it would create an infinite loop.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMultiSelectActive])

	const isNotInPage = (selectedRecord: Record): boolean => {
		return (
			!recordsToStewardQuery.data?.records?.some((record) => record.recordId === selectedRecord.recordId) || false
		)
	}

	const handleOnChangeSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
		const isChecked = event.target.checked
		setSelectAll(isChecked)
		if (recordsToStewardQuery.data) {
			if (isChecked) {
				const missingRecords = recordsToStewardQuery.data.records.filter(isNotSelected)
				dispatch(setSelectedRecords([...selectedRecords, ...missingRecords]))
			} else {
				const recordsOutOfPage = selectedRecords.filter(isNotInPage)
				dispatch(setSelectedRecords([...recordsOutOfPage]))
			}
		}
	}

	const handleOnChangeSelectedCompany = (event: ChangeEvent<HTMLInputElement>, selectedRecord: Record) => {
		const addRecord = event.target.checked
		updateSelectedCompanies(addRecord, selectedRecord)
	}

	const updateSelectedCompanies = (addRecord: boolean, selectedRecord: Record) => {
		const newList = [...selectedRecords]
		if (addRecord) {
			newList.push(selectedRecord)
		} else {
			const recordIdx = newList.findIndex((record) => record.recordId === selectedRecord.recordId)
			newList.splice(recordIdx, 1)
		}
		const selectedRecordsInPage = newList.filter((record) => {
			return !isNotInPage(record)
		})
		const selectedPageCount = selectedRecordsInPage.length
		const isAllPageSelected = selectedPageCount === recordsToStewardQuery.data?.records.length
		if (isAllPageSelected) {
			setSelectAll(true)
		} else if (selectAll) {
			setSelectAll(false)
		}
		dispatch(setSelectedRecords(newList))
	}

	const isNotSelected = (record: Record) => {
		return !selectedRecords.some((selectedRecord) => selectedRecord.recordId === record.recordId)
	}

	const handlePageIndexChange = () => {
		if (recordsToStewardQuery.data) {
			const missingRecords = recordsToStewardQuery.data.records.filter(isNotSelected)
			const missingRecordsCount = missingRecords.length
			const hasPreviousSelection = missingRecordsCount !== recordsToStewardQuery.data.records.length
			const hasAllPageSelected = missingRecordsCount === 0
			const selectAllPage = selectAll && !hasPreviousSelection
			const unSelectAllPage = !selectAll && hasAllPageSelected
			if (selectAllPage) {
				dispatch(setSelectedRecords([...selectedRecords, ...missingRecords]))
			} else if (unSelectAllPage) {
				const recordsOutOfPage = selectedRecords.filter(isNotInPage)
				dispatch(setSelectedRecords([...recordsOutOfPage]))
			} else if (selectAll && !hasAllPageSelected) {
				setSelectAll(false)
			}
		}
	}

	useEffect(() => {
		if (isMultiSelectActive) {
			handlePageIndexChange()
		}
		/**
		 * handlePageIndexChange is not added as a dependency because it would cause the other dependencies to change with
		 * each render.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageIndex])

	const handlePageSizeChange = () => {
		if (recordsToStewardQuery.data && selectAll) {
			const missingRecords = recordsToStewardQuery.data.records.filter(isNotSelected)
			dispatch(setSelectedRecords([...selectedRecords, ...missingRecords]))
		}
	}

	useEffect(() => {
		if (isMultiSelectActive) {
			handlePageSizeChange()
		}
		/**
		 * handlePageSizeChange is not added as a dependency because it would cause the other dependencies to change
		 * with each render.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageSize])

	return (
		<div className={styles.companyListManagerContainer}>
			<CompanyListToolBar
				isMultiSelectActive={isMultiSelectActive}
				onToggleMultiSelect={onToggleMultiSelect}
				currentSorting={currentSorting}
				onSortChange={onSortChange}
				isInsightsOn={showDetails}
				onInsightsChange={(event) => setShowDetails(event.target.checked)}
				isSelectAllChecked={selectAll}
				onSelectAllChange={handleOnChangeSelectAll}
			/>
			<div className={styles.companyListManagerList} role="list">
				{recordsToStewardQuery.data?.records.map((record, idx) => {
					const isSelected = selectedRecords.some(
						(selectedRecord) => selectedRecord.recordId === record.recordId
					)
					return (
						<div key={`${record.recordId}-${idx}`} className={styles.companyListManagerCompanyContainer}>
							{isMultiSelectActive && (
								<div className={styles.companyListManagerCheckbox}>
									<CheckBox
										id={`company-list-manager-${record.recordId}`}
										checked={isSelected}
										testId={`company-list-manager-${record.recordId}`}
										onChange={(event) => handleOnChangeSelectedCompany(event, record)}
									/>
								</div>
							)}
							<div
								className={`${styles.companyListManagerTileContainer} ${
									idx === 0 ? styles.firstTile : ''
								}`}
							>
								<CompanyDetailTile
									recordInformation={record}
									onSelect={() => handleOnSelect(record)}
									showDetail={showDetails}
									isSelected={isSelected}
								/>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export type { recordSortBy }
export { CompanyListManager }
