import { isEqual } from 'lodash-es'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MatchCountrySelector } from '../../../components/match-country-selector/match-country-selector'
import { MatchInclusion } from '../../../components/match-inclusion/match-inclusion'
import { MatchQualitySection } from '../../../components/match-quality/match-quality-section'
import { convertTo2CountryCode, convertTo3CountryCode } from '../../../helpers'
import { Accordion, AccordionHeader, AccordionPanel, Slider } from '../../../local-core-ui'
import world from '../../../local-core-ui/assets/data/world.json'
import { BASE_RULE, MatchRuleInfo, SPECIAL_RULE } from '../../../store/projectWizard/types'
import styles from './rule-item-v2.module.scss'
import { getMatchGradePatterns } from './utils'

interface IRuleItemProps {
	value: MatchRuleInfo
	index: number
	updateMatchGradePatterns: (updatedMatchGradePatterns: Array<string>, idx: number) => void
	updateMatchType: (selectedMatchType: string, idx: number) => void
	updateExclusionCriterion: (updatedExclusionCriterion: Array<string>, idx: number) => void
	updateAllowedCountries: (updatedAllowedCountries: Array<string>, idx: number) => void
	disabledCountries: Array<string>
	setSelectedTab: () => void
	color: string
	testId: string
	readonly?: boolean
	initialMatchRule?: MatchRuleInfo
	showGeographicalDistributionDialogBox?: boolean
	onGeographicalDistributionDialogBoxClose?: () => void
	setConfidenceSliderValue(confidenceSliderValue: number, idx: number): void
	useEasyMatchString: boolean
	onChangeEasyMatchString: (newValue: boolean, idx: number) => void
}

export const RuleItem = ({
	value,
	index,
	setConfidenceSliderValue,
	updateMatchGradePatterns,
	updateMatchType,
	updateExclusionCriterion,
	updateAllowedCountries,
	disabledCountries,
	setSelectedTab,
	color,
	testId,
	readonly = false,
	initialMatchRule,
	showGeographicalDistributionDialogBox,
	onGeographicalDistributionDialogBoxClose,
	useEasyMatchString,
	onChangeEasyMatchString
}: IRuleItemProps): ReactElement => {
	const { t } = useTranslation()
	const [selectedCountries, setSelectedCountries] = useState<Array<string>>(
		value.matchRule.allowedValues ? value.matchRule.allowedValues : []
	)
	const sliderMinValue = 1
	const sliderMaxValue = 10
	const selectedCountriesString = useMemo(() => {
		const countries = convertTo3CountryCode(selectedCountries)
		let countriesList = world.features
			.filter((c) => countries?.includes(c.id))
			.sort((a, b) => {
				return t('country.' + a.id2.toLowerCase()) > t('country.' + b.id2.toLowerCase()) ? 1 : -1
			})
			.map((c) => {
				return t('country.' + c.id2.toLowerCase())
			})
			.join(', ')
		if (countriesList.length > 45) {
			countriesList = countriesList.substring(0, 42).trim() + '...'
		}
		const showing = countriesList.split(', ').length
		const total = selectedCountries.length
		const remaining = total - showing
		return `${countriesList} ${remaining > 0 ? '+' + remaining : ''}`
		/**
		 * We only want to run this effect when selectedCountries changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCountries])

	const matchQualityString = useMemo(() => {
		const matchGradePatterns = getMatchGradePatterns(value)
		let matchGradePatternsString = matchGradePatterns.join(', ')

		if (matchGradePatternsString.length > 45) {
			matchGradePatternsString = matchGradePatternsString.substring(0, 42).trim() + '...'
		}
		const showing = matchGradePatternsString.split(', ').length
		const total = matchGradePatterns.length
		const remaining = total - showing
		return `${matchGradePatternsString} ${remaining > 0 ? '+' + remaining : ''}`
		/**
		 * We only want to run this effect when the MatchGradePatterns from the acceptCriterion in the match rule changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value.matchRule.acceptCriterion.MatchGradePatterns])

	const arrayDifference = (array1: string[], array2?: string[]) => {
		let difference: string[] = array1
		if (array2) {
			difference = array1.filter((key: string) => !array2.includes(key))
		}
		return difference
	}

	const getInclusionCriteria = (rule: MatchRuleInfo) => {
		const inclusionCriteriaKeys = [
			'NonHeadQuarters',
			'OutOfBusiness',
			'Unreachable',
			'NonMarketable',
			'Undeliverable'
		]
		return arrayDifference(inclusionCriteriaKeys, rule.matchRule.exclusionCriterion)?.map((includeKey) => {
			return t(`match.rule.summary.tile.${includeKey}`)
		})
	}

	const inclusionString = useMemo(() => {
		const inclusionCriteria = getInclusionCriteria(value)
		let inclusionCriteriaString = inclusionCriteria.join(', ')
		if (inclusionCriteriaString.length > 45) {
			inclusionCriteriaString = inclusionCriteriaString.substring(0, 42).trim() + '...'
		}
		const showing = inclusionCriteriaString.split(', ').length
		const total = inclusionCriteria.length
		const remaining = total - showing
		return `${inclusionCriteriaString} ${remaining > 0 ? '+' + remaining : ''}`
		/**
		 * We only want to run this effect when the exclusionCriterion from the match rule changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value.matchRule.exclusionCriterion])

	const getInitialButton = (field: string) => {
		switch (field) {
			case 'acceptCriterion':
				return (
					value.matchRule.acceptCriterion.LowestConfidenceCode ===
						initialMatchRule?.matchRule.acceptCriterion.LowestConfidenceCode ||
					(initialMatchRule === undefined && value.matchRule.acceptCriterion.LowestConfidenceCode === 6)
				)
			case 'MatchGradePatterns':
				return isEqual(
					value.matchRule.acceptCriterion.MatchGradePatterns,
					initialMatchRule?.matchRule.acceptCriterion.MatchGradePatterns
				)
			case 'exclusionCriterion':
				const initialExclusionCriterion =
					initialMatchRule?.matchRule.exclusionCriterion !== undefined
						? initialMatchRule?.matchRule.exclusionCriterion
						: []
				const newValuesExclusionCriterion =
					value.matchRule.exclusionCriterion !== undefined ? value.matchRule.exclusionCriterion : []
				return isEqual(newValuesExclusionCriterion, initialExclusionCriterion)
		}
	}

	useEffect(() => {
		setSelectedTab()
		// We need this code to get executed only once to tell the parent at what tab is position to prevent unexpected behaviors
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (value.matchRule.allowedValues && !isEqual(selectedCountries, value.matchRule.allowedValues)) {
			setSelectedCountries(value.matchRule.allowedValues)
		}
	}, [value.matchRule.allowedValues, selectedCountries])

	return (
		<div className={styles.ruleItem} data-testid="rule-items-container">
			<div className={styles.accordionContainer} data-testid="accordion-container-matching">
				<Accordion testId={testId + '-accordion'} id={'rule-item-accordion-' + testId} allowMultipleOpen={true}>
					{value.matchRule.ruleType === SPECIAL_RULE && (
						<AccordionHeader
							controls={'CountryPanel'}
							title={t('matching.step.accordion.title.geographical.distribution')}
							isOpen={true}
							additionalText={{
								textExpanded: selectedCountriesString,
								textCollapsed: selectedCountriesString
							}}
							key={'geographical-distribution'}
							testId="country-panel-header"
						/>
					)}
					{value.matchRule.ruleType === SPECIAL_RULE && (
						<AccordionPanel id="CountryPanel" testId="country-accordion">
							<MatchCountrySelector
								selectedCountries={convertTo3CountryCode(value.matchRule.allowedValues)}
								setSelectedCountriesValue={(newValue) => {
									updateAllowedCountries(convertTo2CountryCode(newValue), index)
									setSelectedCountries(convertTo2CountryCode(newValue))
								}}
								disabledCountries={convertTo3CountryCode(disabledCountries)}
								color={color}
								testId={testId + '-mcs'}
								readonly={readonly}
								showDialogBox={showGeographicalDistributionDialogBox}
								onDialogBoxClose={onGeographicalDistributionDialogBoxClose}
							/>
						</AccordionPanel>
					)}
					<AccordionHeader
						title={t('matching.step.slider.title')}
						testId="slider-accordion"
						key="confidence-slider"
						controls={'panelMatchRule'}
						type={getInitialButton('acceptCriterion') ? 'button' : 'normal'}
						isCollapsable={false}
						isOpen={value.matchRule.ruleType === BASE_RULE ? true : !getInitialButton('acceptCriterion')}
					/>
					<AccordionPanel id="panelMatchRule" testId="match-rule-panel">
						<div className={styles.confidenceSliderContainer}>
							<div className={styles.spanContainer}>
								<span className="confidenceBlock">{t('file.matching.low.confidence')}</span>
								<span className="confidenceBlock middle">{t('file.matching.medium.confidence')}</span>
								<span className="confidenceBlock">{t('file.matching.high.confidence')}</span>
							</div>
							<div data-testid="slider-wrapper" className={styles.sliderWrapper}>
								<Slider
									minValue={sliderMinValue}
									maxValue={sliderMaxValue}
									valueFillOrientation="right"
									onChange={(valueSlider) => {
										setConfidenceSliderValue(valueSlider, index)
									}}
									sliderValue={
										value.matchRule.acceptCriterion.LowestConfidenceCode
											? value.matchRule.acceptCriterion.LowestConfidenceCode
											: sliderMinValue
									}
									width={616}
									testId={testId + '-confidence-slider'}
									disabled={readonly}
								/>
							</div>
						</div>
					</AccordionPanel>
					<AccordionHeader
						title={t('matching.step.accordion.title.match.quality')}
						key={'match-quality'}
						controls={'match-quality-panel'}
						type={getInitialButton('MatchGradePatterns') ? 'button' : 'normal'}
						isOpen={!getInitialButton('MatchGradePatterns')}
						// DCP-518-Update the display of the 'Easy' match MGS in the UI and make it 11 byte MGS
						// additionalText={{
						// 	textExpanded: matchQualityString,
						// 	textCollapsed: matchQualityString
						// }}
						testId="match-quality-header"
					/>
					<AccordionPanel id="match-quality-panel" testId="match-quality-panel">
						<MatchQualitySection
							matchType={value.matchQualityType}
							matchGradePatterns={value.matchRule.acceptCriterion.MatchGradePatterns || []}
							updateMatchGradePatterns={(updatedMatchGradePatterns) =>
								updateMatchGradePatterns(updatedMatchGradePatterns, index)
							}
							updateMatchType={(selectedMatchType) => updateMatchType(selectedMatchType, index)}
							testId={testId + '-mqs'}
							readonly={readonly}
							useEasyMatchString={useEasyMatchString}
							onChangeEasyMatchString={(newValue) => onChangeEasyMatchString(newValue, index)}
						/>
					</AccordionPanel>
					<AccordionHeader
						title={t('matching.step.accordion.title.inclusions')}
						key={'inclusions'}
						controls={'inclusions-panel'}
						type={getInitialButton('exclusionCriterion') ? 'button' : 'normal'}
						isOpen={!getInitialButton('exclusionCriterion')}
						additionalText={{
							textExpanded: inclusionString,
							textCollapsed: inclusionString
						}}
						testId="inclusions-header"
					/>
					<AccordionPanel id="inclusions-panel" testId="inclusions-panel">
						<MatchInclusion
							selectedOptions={value.matchRule.exclusionCriterion || []}
							updateInclusion={(updatedOptions) => updateExclusionCriterion(updatedOptions, index)}
							testId={testId + '-match-inclusion'}
							readonly={readonly}
						/>
					</AccordionPanel>
				</Accordion>
			</div>
		</div>
	)
}
