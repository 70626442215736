import { useTranslation } from 'react-i18next'
import { ReportDonutChart } from '../../../../../components/report-half-donut-chart/report-donut-chart'
import { ReportSnackbar } from '../../../../../components/report-snackbar/report-snackbar'
import {
	ChartKey,
	ColDef,
	ReportTable,
	ReportTableWrapper,
	RowDef
} from '../../../../../components/report-table/report-table'
import { ReportTile } from '../../../../../components/report-tile/report-tile'
import { abbreviateNumber } from '../../../../../helpers'
import { DataReport } from '../../../../../types'
import { EnrichedDataList } from '../../../types/EnrichedDataList'
import { chartColorsMap } from '../../chartColorsMap'
import styles from './match-summary-code-count.module.scss'

type MatchSummaryCodeCountProps = {
	matchSummaryCodeReport: DataReport['matchSummaryCodeReport'] | null
	profile: string
}

type SummaryCodeList = DataReport['matchSummaryCodeReport']['summaryCodeList']

const NO_MATCH_CODE = 'No Match'
const rowHeight = '2rem'

const getSummaryRows = (matchSummaryData: EnrichedDataList[]): RowDef[] => {
	let rows: RowDef[] = []

	if (matchSummaryData) {
		rows = matchSummaryData.map(({ field, count, rate, color }, idx) => [
			<ChartKey key={`key-${idx}`} label={field} color={color} />,
			new Intl.NumberFormat().format(count),
			<span key={`key-${idx}`} className={styles.boldText}>
				{rate + '%'}
			</span>
		])
	}

	return rows
}

const MatchSummaryCodeCount = ({ matchSummaryCodeReport, profile }: MatchSummaryCodeCountProps) => {
	const { t } = useTranslation()
	const matchedCount = matchSummaryCodeReport ? matchSummaryCodeReport.total : 0
	const precision = matchedCount > 1000 ? 1 : 0
	const abbreviateMatchedCount = abbreviateNumber(matchedCount, precision)
	const summaryCodeList: SummaryCodeList = matchSummaryCodeReport ? matchSummaryCodeReport.summaryCodeList : []

	const displaySecondTable = summaryCodeList.length > 10

	const summaryColumns: ColDef[] = [
		{
			label: t('report.page.parallelPassMatching.tile.stewardshipCount.table.column1') as string,
			field: 'code'
		},
		{
			label: t('report.page.parallelPassMatching.tile.stewardshipCount.table.column2') as string,
			field: 'count',
			align: displaySecondTable ? 'right' : 'left'
		},
		{
			label: t('report.page.parallelPassMatching.tile.stewardshipCount.table.column3') as string,
			field: 'rate',
			width: displaySecondTable ? 75 : 160,
			align: displaySecondTable ? 'right' : 'left'
		}
	]

	const sortedSummaryCodeList: EnrichedDataList[] = [...summaryCodeList]
		.sort((a, b) => b.rate - a.rate)
		.map(({ code, rate, count }, idx) => {
			let codeColor = chartColorsMap.get(`category-${idx + 1}`) ?? ''

			if (code === 'DUN') {
				codeColor = chartColorsMap.get('success') ?? ''
			}

			if (code === 'UNC') {
				codeColor = chartColorsMap.get('uncategory-1') ?? ''
			}

			if (code === NO_MATCH_CODE) {
				codeColor = chartColorsMap.get('uncategory-3') ?? ''
			}

			return {
				field: code,
				color: codeColor,
				count,
				rate: Math.round(rate)
			}
		})

	const summaryCodeListDataset1 = sortedSummaryCodeList.slice(0, 10)
	const summaryCodeListDataset2 = sortedSummaryCodeList.slice(10, 20)

	return (
		<ReportTile
			title={t('report.page.parallelPassMatching.tile.matchSummaryCodeCount.title') as string}
			subtitle={
				t('report.page.parallelPassMatching.tile.matchSummaryCodeCount.subtitle', {
					precedenceProfile: profile
				}) as string
			}
		>
			{matchSummaryCodeReport && summaryCodeList.length > 0 ? (
				<div className={styles.summaryContainer}>
					<ReportDonutChart
						data={sortedSummaryCodeList}
						size={240}
						statLabel={
							t('report.page.parallelPassMatching.tile.matchSummaryCodeCount.chart.stat') as string
						}
						statValue={abbreviateMatchedCount}
					/>
					<ReportTableWrapper>
						<div className={styles.tablesContainer}>
							<ReportTable
								rowHeight={rowHeight}
								columns={summaryColumns}
								rows={getSummaryRows(summaryCodeListDataset1)}
								hideBorders
								hideHeader={displaySecondTable}
								zebraStripes="even"
							/>
							{displaySecondTable && (
								<ReportTable
									rowHeight={rowHeight}
									columns={summaryColumns}
									rows={getSummaryRows(summaryCodeListDataset2)}
									hideBorders
									hideHeader
									zebraStripes="even"
								/>
							)}
						</div>
					</ReportTableWrapper>
				</div>
			) : (
				<ReportSnackbar />
			)}
		</ReportTile>
	)
}

export { MatchSummaryCodeCount }
