import { AxiosInstance, AxiosResponse } from 'axios'
import { SnowflakeData } from '../../components/snowflake-form/snowflake-form'
import { System } from './getConnections'

export interface SuccessResponse {
	Success: boolean
	Errors: string[]
}

export const updateConnection = async (
	apiClient: AxiosInstance,
	data: SnowflakeData,
	systemType: System
): Promise<SuccessResponse> => {
	const url = `/pls/integration/${systemType}/organization/connection`
	return apiClient.post(url, data).then((response: AxiosResponse<SuccessResponse>) => response.data)
}
