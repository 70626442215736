import { useTranslation } from 'react-i18next'
import { ReportSnackbar } from '../../../../../components/report-snackbar/report-snackbar'
import {
	ChartKey,
	ColDef,
	ReportTable,
	ReportTableWrapper,
	RowDef
} from '../../../../../components/report-table/report-table'
import { ReportTile } from '../../../../../components/report-tile/report-tile'
import { PieChart } from '../../../../../local-core-ui'
import { DataReport } from '../../../../../types'
import { chartColorsMap } from '../../chartColorsMap'
import styles from './stewardship-count.module.scss'

type StewardshipValueReport = DataReport['stewardshipValueReport']

type StewardshipValueList = StewardshipValueReport['stewardshipValueList']

type StewardshipCountProps = {
	stewardshipValueReport: StewardshipValueReport | null
	profile: string
}

const rowHeight = '2rem'
const stewardshipColorMap = new Map([
	['Accept', chartColorsMap.get('category-1')],
	['Review', chartColorsMap.get('category-2')],
	['Reject', chartColorsMap.get('category-3')],
	['No Match', chartColorsMap.get('uncategory-1')]
])

const StewardshipCount = ({ stewardshipValueReport, profile }: StewardshipCountProps) => {
	console.log(stewardshipValueReport)
	const { t } = useTranslation()
	const stewardshipValueList: StewardshipValueList = stewardshipValueReport
		? stewardshipValueReport.stewardshipValueList
		: []

	const stewardshipCountColumns: ColDef[] = [
		{
			label: t('report.page.parallelPassMatching.tile.stewardshipCount.table.column1') as string,
			field: 'stewardship'
		},
		{
			label: t('report.page.parallelPassMatching.tile.stewardshipCount.table.column2') as string,
			field: 'count'
		},
		{
			label: t('report.page.parallelPassMatching.tile.stewardshipCount.table.column3') as string,
			field: 'percentage',
			width: 160
		}
	]

	const stewardshipValueChartData = stewardshipValueList
		.sort((a, b) => b.rate - a.rate)
		.map((item) => ({
			label: item.value,
			value: item.count,
			rate: item.rate
		}))
		.reduce(
			(data, { label, value }) => ({
				...data,
				[label]: value
			}),
			{}
		)

	const stewardshipValueChartColors = stewardshipValueList.reduce((data, { value }) => {
		const label = value ? t('label.graph.' + value) : ''
		return {
			...data,
			[label]: stewardshipColorMap.get(value)
		}
	}, {})

	const getStewardshipCountColumns = (): RowDef[] => {
		let rows: RowDef[] = []

		if (stewardshipValueList) {
			rows = stewardshipValueList.map(({ value, count, rate }, idx) => [
				<ChartKey key={`key-${idx}`} label={value} color={stewardshipColorMap.get(value) as string} />,
				new Intl.NumberFormat().format(count),
				<span key={`key-${idx}`} className={styles.boldText}>
					{rate + '%'}
				</span>
			])
		}

		return rows
	}

	return (
		<ReportTile
			title={t('report.page.parallelPassMatching.tile.stewardshipCount.title') as string}
			subtitle={
				t('report.page.parallelPassMatching.tile.stewardshipCount.subtitle', {
					stewardshipProfile: profile
				}) as string
			}
		>
			{stewardshipValueReport && stewardshipValueList.length > 0 ? (
				<div className={styles.stewardshipCountContainer}>
					<div className={styles.pieChartContainer}>
						<PieChart
							diameter={225}
							colors={stewardshipValueChartColors}
							data={stewardshipValueChartData}
							describeWith={'none'}
							showKeys={false}
							showStrokes
						/>
					</div>

					<ReportTableWrapper>
						<ReportTable
							rowHeight={rowHeight}
							columns={stewardshipCountColumns}
							rows={getStewardshipCountColumns()}
							hideBorders
						/>
					</ReportTableWrapper>
				</div>
			) : (
				<ReportSnackbar />
			)}
		</ReportTile>
	)
}

export { StewardshipCount }
