import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { numberDelimeters } from '../../helpers'
import { Icon, IconType } from '../../local-core-ui'
import { useUsers } from '../../queries/useUsers'
import { RootState, useAppSelector } from '../../store'
import { AssignmentDetails } from '../../types'
import styles from './assignment-tile.module.scss'

interface AssignmentDetailProps {
	isSelected: boolean
	assignmentDetail: AssignmentDetails
}

export const AssignmentDetail = ({ isSelected, assignmentDetail }: AssignmentDetailProps): ReactElement => {
	const { t } = useTranslation()
	const { assignee, createdBy, priority, totalRecords } = assignmentDetail

	const selectSession = (state: RootState) => state.session
	const sessionState = useAppSelector(selectSession)
	const currentUser = sessionState.user?.EmailAddress

	const isCurrentUser = currentUser === assignee
	const wasCreatedByMe = currentUser === createdBy

	const usersQuery = useUsers()

	const assigneeData = usersQuery.data?.find((user) => {
		return user.Email === assignee
	})
	const assigneeName = assigneeData ? assigneeData.FirstName + ' ' + assigneeData.LastName : assignee
	const assignedByData = usersQuery.data?.find((user) => {
		return user.Email === createdBy
	})
	const assignedByName = assignedByData ? assignedByData.FirstName + ' ' + assignedByData.LastName : createdBy

	const me = t('assignments.to.tile.me')
	const assigneeUser = isCurrentUser ? me : assigneeName
	const assignedByUser = wasCreatedByMe ? me : assignedByName
	const priorityLowerCase = priority.toLowerCase()
	const priorityIcon = isSelected
		? (`${priorityLowerCase}-priority-circle` as IconType)
		: (`${priorityLowerCase}-priority-bg` as IconType)

	const getPriorityLabel = () => {
		return t(`assignment.tile.${priorityLowerCase}`)
	}

	return (
		<div className={styles.assignmentTileBody}>
			<div className={styles.assignmentTileRecordsContainer}>
				<span className={styles.assignmentTileRecordsLabel}>{t('assignment.tile.records')}</span>
				<span className={`${styles.assignmentTileData} ${isSelected ? styles.assignmentTileSelectedTxt : ''}`}>
					{numberDelimeters(totalRecords)}
				</span>
			</div>
			<div className={styles.assignmentTileRow}>
				<span className={`${styles.assignmentTileLabel} ${isSelected ? styles.assignmentTileSelectedTxt : ''}`}>
					{t('assignment.tile.priority')}
				</span>
				<span
					className={`${styles.assignmentTilePriorityLvl} ${styles.assignmentTileData} ${
						isSelected ? styles.assignmentTileSelectedTxt : ''
					}`}
				>
					{getPriorityLabel()}
					<span className={styles.assignmentTileIconContainer}>
						<Icon testId="priority-assignment-detail" type={priorityIcon} size="mini" />
					</span>
				</span>
			</div>
			<div className={styles.assignmentTileRow}>
				<span className={`${styles.assignmentTileLabel} ${isSelected ? styles.assignmentTileSelectedTxt : ''}`}>
					{t('assignment.tile.assignee')}
				</span>
				<span className={`${styles.assignmentTileData} ${isSelected ? styles.assignmentTileSelectedTxt : ''}`}>
					{assigneeUser}
				</span>
			</div>
			<div className={styles.assignmentTileRow}>
				<span className={`${styles.assignmentTileLabel} ${isSelected ? styles.assignmentTileSelectedTxt : ''}`}>
					{t('assignment.tile.assigned.by')}
				</span>
				<span className={`${styles.assignmentTileData} ${isSelected ? styles.assignmentTileSelectedTxt : ''}`}>
					{assignedByUser}
				</span>
			</div>
		</div>
	)
}
