import { ReactElement } from 'react'
import styles from './upload-tile.module.scss'

export type Stat = {
	label: string
	data: string
	emphasizeData?: boolean
}

interface UploadTileStatsProps {
	stats: Array<Stat>
	testId?: string
}

export const UploadTileStats = ({ stats, testId }: UploadTileStatsProps): ReactElement => {
	return (
		<>
			{stats.map((stat) => {
				return (
					<div
						data-testid={`upload-tile-container-${testId}-${stat.label}`}
						className={styles.uploadTileStatContainer}
						key={`upload-tile-stat-${stat.label}`}
					>
						<p
							data-testid={`upload-tile-label-${testId}-${stat.label}`}
							className={styles.uploadTileStatLabel}
						>
							{stat.label}
						</p>
						<p
							className={`${styles.uploadTileStatData} ${stat.emphasizeData ? styles.emphasizeData : ''}`}
							data-testid={`${testId}-upload-tile-stat-data-${stat.label
								.toLowerCase()
								.replaceAll(' ', '_')}`}
						>
							{stat.data}
						</p>
					</div>
				)
			})}
		</>
	)
}
