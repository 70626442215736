import { DNBTypography } from '@dnb-uux-design-system/react'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './manual-match-key.module.scss'

export const ManualMatchKey = (): ReactElement => {
	const { t } = useTranslation()
	const keysMatch = ['A', 'B', 'F', 'Z', '.']

	return (
		<div className={styles.manualMatchKey}>
			{keysMatch.map((keyMatch) => {
				return (
					<div className={styles.mmkRow} key={`section-desc-${keyMatch}`}>
						<div className={styles.mmkLetter}>{keyMatch}</div>
						<div className={styles.mmkBody}>
							<DNBTypography variant="compact-bold">
								{t(`manual.match.key.${keyMatch}.desc`)}
							</DNBTypography>
							<DNBTypography variant="compact-body">
								{t(`manual.match.key.${keyMatch}.example`)}
							</DNBTypography>
						</div>
					</div>
				)
			})}
		</div>
	)
}
