import { AxiosResponse } from 'axios'
import { UnassignedList } from '../../../types'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { setSelectedRecords } from '../stewardSlice'
import { TileTypes } from '../types'
import { getRecords } from './getRecords'

//TODO add the correct endpoint to assign all unassigned records
export const assignAllUnassignedRecords =
	(assignmentId: string): AppThunk<Promise<void>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<void> => {
		const apiClient = getApiClient4Thunks(dispatch)
		const steward = getState().steward

		const unassignedRecordsUrl = '/pls/steward/records/list/unassigned?pageSize=500'
		return apiClient.get(unassignedRecordsUrl).then((unassignedResponse: AxiosResponse<UnassignedList>) => {
			const unassignedData = unassignedResponse.data
			const records = unassignedData.records
			const recordIds = records.map((record) => record.recordId)
			const url = `/pls/steward/assignments/${assignmentId}/records/assign`
			return apiClient.put(url, recordIds).then(() => {
				if (steward.forTile === TileTypes.Unassigned) {
					dispatch(
						getRecords(
							steward.forTile,
							steward.pageIndex,
							steward.pageSize,
							steward.requestedClassification
						)
					)
					dispatch(setSelectedRecords([]))
				}
			})
		})
	}
