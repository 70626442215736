import {
	ColorBlueBrand,
	ColorBluePrimary,
	ColorBlueSky,
	ColorBlueSkyMiddle,
	ColorCarmine,
	ColorCarmineMiddle,
	ColorGrass,
	ColorGrassMiddle,
	ColorHan,
	ColorHanMiddle,
	ColorMulberry,
	ColorMulberryLighter,
	ColorOlive,
	ColorOliveMiddle,
	ColorOrange,
	ColorOrangeLighter,
	ColorPurple,
	ColorPurpleMiddle,
	ColorSeaGreenDark,
	ColorSeaGreenMiddle
} from '@dnb-dcp/design-tokens/build/shared/token-colors.json'

export const ruleColorList: Array<string> = [
	ColorBluePrimary,
	ColorSeaGreenDark,
	ColorPurple,
	ColorOrange,
	ColorCarmine,
	ColorHan,
	ColorBlueSky,
	ColorMulberry,
	ColorGrass,
	ColorOlive
]
export const hoverRuleColorList: Array<string> = [
	ColorBlueBrand,
	ColorSeaGreenMiddle,
	ColorPurpleMiddle,
	ColorOrangeLighter,
	ColorCarmineMiddle,
	ColorHanMiddle,
	ColorBlueSkyMiddle,
	ColorMulberryLighter,
	ColorGrassMiddle,
	ColorOliveMiddle
]
