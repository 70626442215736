import cx from 'classnames'
import { FC } from 'react'
import { createTokenList, getValueToken } from '../design-tokens/utils'
import * as tokens from '../shared/token-colors.json'
import { GDMKey } from './gdm-key'
import { GDMMap } from './gdm-map'
import styles from './geographical-distribution-map.module.scss'

interface ICountry {
	properties: {
		name: string
	}
	id2: string
	id: string
}

export interface IDistributionData {
	countryCode: string
	matched: number
	unmatched?: number
	duplicates?: number
	rejected?: number
}

export interface IGeographicalDistributionMapProps {
	data?: Array<IDistributionData>
	/** UpperCamelCase token of the base color to use - looked up through shared/token-colors.json  */
	color?: string
	width?: number
	/** Provide your translation function (usually "t" or whatever you assigned i18next's useTranslation call to)<br/>
        Ensure the proper keys are defined in your project:<br/>
        <ul>
			<li><b>country.[CC]</b> - where [CC] is the lowercase 2-letter country code for every supported country</li>
			<li><b>geographical.distribution.map.tooltip.total.records</b></li>
			<li><b>geographical.distribution.map.tooltip.matched</b></li>
			<li><b>geographical.distribution.map.tooltip.unmatched</b></li>
			<li><b>geographical.distribution.map.tooltip.duplicates</b></li>
			<li><b>geographical.distribution.map.tooltip.rejected</b></li>
        </ul>
    */
	translationFunction?(i18nKey: string): string
	testId: string
	zeroColor?: string
	orientation?: 'horizontal' | 'vertical'
}

export const GeographicalDistributionMap: FC<IGeographicalDistributionMapProps> = ({
	data = [],
	color = 'ColorChart10',
	width = 400,
	translationFunction,
	testId,
	zeroColor,
	orientation = 'horizontal'
}: IGeographicalDistributionMapProps) => {
	const colorsTokenList = createTokenList(tokens)
	const colorValue = getValueToken(colorsTokenList, color)
	if (colorValue === undefined) {
		throw new Error('Invalid color token provided')
	}

	const dataWithTotals = data.map((d: IDistributionData) => ({
		...d,
		total:
			d.matched +
			(d.unmatched ? d.unmatched : 0) +
			(d.duplicates ? d.duplicates : 0) +
			(d.rejected ? d.rejected : 0)
	}))
	const grandTotal = dataWithTotals.length > 0 ? dataWithTotals.map((c) => c.total).reduce((a, b) => (a += b)) : 0
	const dataWithPercents = dataWithTotals.map((d) => ({ ...d, percent: d.total / grandTotal }))

	/*const getCountryTranslation = (country: ICountry): string => {
		let name = country.properties.name
		if (translationFunction && translationFunction('country.' + country.id2.toLowerCase())) {
			name = translationFunction('country.' + country.id2.toLowerCase())
		}
		return name
	}*/

	return (
		<div
			className={cx(styles.geographicalDistributionMap, {
				[styles.positionBottom]: orientation === 'vertical'
			})}
			data-testid={`${testId}` + '-container'}
			style={{
				width: width + 'px',
				height: orientation === 'vertical' ? '100%' : (width - 78) * 0.65 + 'px'
			}}
		>
			<GDMMap
				data={dataWithPercents}
				color={colorValue}
				width={width - 78}
				translationFunction={translationFunction}
				testId={testId + '-gdm-map'}
				zeroColor={zeroColor}
				orientation={orientation}
			/>
			<GDMKey
				data={dataWithPercents}
				color={color}
				translationFunction={translationFunction}
				testId={testId + '-gdm-key'}
				orientation={orientation}
				width={width - 78}
			/>
		</div>
	)
}
