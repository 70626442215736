import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { useAccessLevel, useEntitlement, useFeatures, useModules } from '../../hooks/useEntitlements'
import { IRouteProps } from '../switch-routes/switch-routes'
import styles from './tab-links.module.scss'

export interface TabLinksProps {
	routes: IRouteProps[]
}

const TabLink: FC<IRouteProps> = ({
	path,
	label = '',
	entitlement = '',
	accessLevel,
	actions = [''],
	modules,
	features
}: IRouteProps) => {
	const { t } = useTranslation()
	const entitled = useEntitlement(entitlement, actions)
	const hasModules = useModules(modules)
	const hasFeatures = useFeatures(features)
	const levelAccess = useAccessLevel(accessLevel)

	return entitled && hasModules && hasFeatures && levelAccess ? (
		<NavLink data-testid={`tabs${path}`} to={path}>
			<span>{t(label)}</span>
		</NavLink>
	) : null
}

export const TabLinks: FC<TabLinksProps> = ({ routes }: TabLinksProps) => {
	return (
		<div className={styles.tabsControlContainer}>
			{routes.map((tab) => tab.label !== '' && <TabLink key={tab.path} {...tab} />)}
		</div>
	)
}
