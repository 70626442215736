import { ElementDetail } from '../../../../types'
import tradeUpElements from '../select-your-data/tradeup-elements.json'

export const deleteTradeUpElements = (dataBlockList: ElementDetail[]) => {
	const blockTradeUp = tradeUpElements[0]
	const block = dataBlockList.find((flat) => {
		return flat.blockId === blockTradeUp.blockId
	})
	if (block) {
		const level = block.levels.find((lev) => lev.level.levelId === 'L' + blockTradeUp.elements[0].level)
		if (level) {
			const newElements = level.elements.filter((ele) => ele.elementId !== blockTradeUp.elements[0].elementId)
			level.elements = newElements
		}
	}
}
