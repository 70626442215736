import cx from 'classnames'
import styles from './skeleton-project-bar.module.scss'

export const SkeletonProjectBar = () => {
	return (
		<div className={styles.skeletonProjectBar}>
			<div className={styles.container1}>
				<div className={cx(styles.title, styles.pulse)}></div>
				<div className={styles.subContainer}>
					<div className={cx(styles.line1, styles.pulse)}></div>
					<div className={cx(styles.line2, styles.pulse)}></div>
				</div>
			</div>
			<div className={styles.container2}>
				<div className={cx(styles.box, styles.pulse)}></div>
			</div>
		</div>
	)
}
