import { DNBButton } from '@dnb-uux-design-system/react'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import BlockImage from '../../assets/images/choose-enrichment-from-blocks.png'
import TemplateImage from '../../assets/images/choose-match-step-tile-image.png'
import approachStyles from '../approach-tile/approach-tile.module.scss'
import styles from './enrichment-approach-tile.module.scss'

export interface EnrichmentApproachTileInterface {
	type: 'saved-template' | 'data-blocks'
	onClick: () => void
	differentPurpose?: boolean
	templateCount?: number
	purposeOfUse?: string
}

export const EnrichmentApproachTile = ({
	type,
	onClick,
	differentPurpose,
	templateCount,
	purposeOfUse
}: EnrichmentApproachTileInterface): ReactElement => {
	const { t } = useTranslation()

	const approachTileData: {
		imageSource: string
		imageClassName: string
		alt: string
		buttonLabel: string
		buttonType: 'primary' | 'secondary'
		labelSection: string
		recommended: boolean
		templateExists: boolean
	} =
		type === 'saved-template'
			? {
					imageSource: TemplateImage,
					imageClassName: 'img',
					alt: t('enrichment.approach.saved.template'),
					buttonLabel: t('enrichment.approach.saved.template'),
					recommended: true,
					labelSection: t('enrichment.approach.saved.template.description'),
					buttonType: 'primary',
					templateExists: templateCount && templateCount > 0 ? true : false
			  }
			: {
					imageSource: BlockImage,
					imageClassName: 'img-secondary',
					alt: t('enrichment.approach.data.blocks'),
					buttonLabel: t('enrichment.approach.data.blocks'),
					recommended: false,
					labelSection: t('enrichment.approach.data.blocks.description'),
					buttonType: 'secondary',
					templateExists: true
			  }

	return (
		<div className={approachStyles.approachTileContainer}>
			<div className={approachStyles.leftColumn}>
				<div className={approachStyles.imageWrapper}>
					<img
						className={approachTileData.imageClassName}
						src={approachTileData.imageSource}
						alt={approachTileData.alt}
						data-testid={`choose-enrich-step-${type}-image`}
					/>
				</div>
				<div className={`${approachStyles.textContainer} ${differentPurpose ? approachStyles.opacity50 : ''}`}>
					{differentPurpose && approachTileData.templateExists
						? t('enrichment.approach.saved.template.diff.purpose.of.use', {
								templatesLength: templateCount
						  })
						: approachTileData.labelSection}
					{differentPurpose && approachTileData.templateExists && (
						<span className={styles.purposeOfUse}>{purposeOfUse}</span>
					)}
				</div>
			</div>
			<div className={approachStyles.verticalDivider} />
			<div className={approachStyles.rightColumn}>
				<div className={approachStyles.buttonTxtWrapper}>
					<div className={approachStyles.buttonWrapper}>
						<DNBButton
							size="default"
							variant="primary"
							onClick={onClick}
							data-testid={`enrichment-approach-${type}`}
							disabled={differentPurpose}
						>
							{approachTileData.buttonLabel}
						</DNBButton>
					</div>
					{approachTileData.recommended && (
						<div className={approachStyles.textContainer}>
							{t('enrichment.approach.saved.template.recommended')}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
