import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { UserDetail } from '../types'
import { getUsers } from './api/getUsers'

export const useUsers = (): UseQueryResult<Array<UserDetail>> => {
	const apiClient = useApi()

	const queryKey = ['getUsers']
	const queryFunction = () => getUsers(apiClient)

	return useQuery(queryKey, queryFunction)
}
