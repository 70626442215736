import cx from 'classnames'
import { FC, PropsWithChildren } from 'react'
import styles from './grid.module.scss'

/**
 - Grid System
 **/

export type GridSystemColumnRange = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
type GutterRange = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
export interface IProps {
	/** if exists, treats the container as the parent container */
	container?: boolean
	/** Range: 0-10
	 * Defines the gap between grid items as a multiple of the base-unit. */
	gutter?: GutterRange
	/** Range: 1-12
	 * Defines the number of columns to span across for small screens and up, unless overridden by a larger screen. */
	sm?: GridSystemColumnRange
	/** Range: 1-12
	 * Defines the number of columns to span across for medium screens and up, unless overridden by a larger screen. */
	md?: GridSystemColumnRange
	/** Range: 1-12
	 * Defines the number of columns to span across for large screens and up, unless overridden by a larger screen. */
	lg?: GridSystemColumnRange
	/** Range: 1-12
	 * Defines the number of columns to span across for extra-large screens */
	xl?: GridSystemColumnRange
	testId?: string
}

export const Grid: FC<PropsWithChildren<IProps>> = ({
	children,
	container = false,
	gutter = 0,
	sm = 12,
	md,
	lg,
	xl,
	testId
}: PropsWithChildren<IProps>) => {
	return (
		<div
			className={
				container
					? cx(styles.gridContainer, styles[`gutter${gutter ? gutter : '0'}`])
					: cx(styles.gridItem, styles[`colSm${sm}`], {
							[styles[`colMd${md}`]]: md,
							[styles[`colLg${lg}`]]: lg,
							[styles[`colXl${xl}`]]: xl
					  })
			}
			data-testid={`grid-container-${testId}`}
		>
			{children}
		</div>
	)
}

export default Grid
