import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ProgressBar } from '../../../local-core-ui'
import { EmailVerification } from '../../dashboard-component/dashboard-component'
import { emailCategoryEV } from '../email-verification-overview'

interface ProgressBarCharts {
	containerBarColor: string
	barColor: string
	textTotal: string
	data: emailCategoryEV
	testId: string
}
export const ProgressChartsEV: FC<ProgressBarCharts> = ({
	containerBarColor,
	barColor,
	textTotal,
	data,
	testId
}: ProgressBarCharts) => {
	const { t } = useTranslation()

	return (
		<>
			<div data-testid={testId}>
				{data.emailCategoryList.map((category: EmailVerification) => (
					<ProgressBar
						id={`progress-meter-category-${category.description}`}
						label={t(`label.graph.${category.description.replaceAll(' ', '')}`) as string}
						type={'meter'}
						value={category.count}
						total={data.total || 0}
						textTotal={textTotal}
						colors={{
							barColor: barColor,
							containerColor: containerBarColor
						}}
						svgWidth={150}
						svgHeight={20}
						key={testId + '-' + category.description}
						testId={testId + '-' + category.description}
					/>
				))}
			</div>
		</>
	)
}
