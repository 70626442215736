/**
 * @class ProgressBar
 */

import cx from 'classnames'
import * as d3 from 'd3'
import { FC, useEffect, useRef, useState } from 'react'
import { ToolTip, ToolTipInfo } from '..'
import { createTokenList, getValueToken } from '../design-tokens/utils'
import { abbreviateNumber } from '../helpers/numberFormatters'
import * as tokens from '../shared/token-colors.json'
import { barBuilder, percentageTextBuilder, progressBarSVGBuilder } from './progress-bar-svg-constructors'
import styles from './progress-bar.module.scss'

export type position = 'horizontal' | 'vertical'

type progressBarColor = {
	barColor: string
	containerColor: string
}

interface ProgressBarProps {
	id: string
	label?: string
	value: number
	total: number
	type?: 'primary' | 'secondary' | 'meter'
	rounded?: 'oneSide' | 'all' | 'none'
	svgWidth?: number
	svgHeight?: number
	numberToShow?: 'none' | 'value' | 'total'
	position?: position
	displayPercentage?: boolean
	precision?: number
	tooltipInfo?: ToolTipInfo
	colors?: progressBarColor
	testId?: string
	textTotal?: string
	textToZero?: string
	showZero?: boolean
}

export const ProgressBar: FC<ProgressBarProps> = ({
	id,
	label,
	value,
	total,
	type = 'primary',
	rounded = 'none',
	svgWidth = 41,
	svgHeight = 41,
	numberToShow = 'total',
	position = 'horizontal',
	displayPercentage = false,
	precision = 2,
	colors,
	tooltipInfo,
	testId,
	textTotal = '',
	textToZero = '',
	showZero = true
}: ProgressBarProps) => {
	const d3Container = useRef(null)
	const basePercentage: number = (value / total) * 100
	const roundedPercentage: number = basePercentage > 0 && basePercentage < 1 ? 1 : Math.round(basePercentage)
	const percentage: number = Math.max(Math.min(roundedPercentage, 100), 0) || 0
	const borderRadius: number = rounded === 'oneSide' ? 4 : 0
	const progressBarColorValue = useRef('')
	const progressColorValue = useRef('')
	const [numberToShowColor, setNumberToShownColor] = useState('')

	useEffect(() => {
		colorTranslationFunction()
	}, [colors?.barColor, colors?.containerColor])

	const colorTranslationFunction = () => {
		const colorsTokenList = createTokenList(tokens)
		const newProgressColorValue =
			colors !== undefined ? getValueToken(colorsTokenList, colors.containerColor) : undefined
		const newProgressBarColorValue =
			colors !== undefined ? getValueToken(colorsTokenList, colors.barColor) : undefined
		progressBarColorValue.current = newProgressBarColorValue
		progressColorValue.current = newProgressColorValue
		setNumberToShownColor(newProgressColorValue)
	}

	const getPercentageToDisplay = (): string => {
		if (basePercentage > 1 || percentage === 0) {
			return percentage + '%'
		} else if (basePercentage < 1) {
			return '<' + percentage + '%'
		}
	}

	useEffect(() => {
		createProgressBar()
	}, [])

	const createProgressBar = () => {
		const progressBarSVG = d3.select(d3Container.current)
		if (rounded === 'all') {
			const containerBorderRadius = '4px'
			progressBarSVG.style('border-radius', containerBorderRadius)
		}
		progressBarSVGBuilder(
			progressBarSVG,
			id,
			total,
			value,
			percentage,
			borderRadius,
			svgWidth,
			svgHeight,
			position,
			displayPercentage,
			progressBarColorValue.current,
			progressColorValue.current,
			type === 'meter'
		)
	}

	useEffect(() => {
		updateProgressBar()
	}, [
		displayPercentage,
		progressBarColorValue.current,
		progressColorValue.current,
		svgHeight,
		svgWidth,
		total,
		type,
		value,
		textToZero
	])

	const updateProgressBar = () => {
		const progressBarSVG = d3.select(d3Container.current)

		progressBarSVG
			.select(`#progress-bar-${id}`)
			.attr('d', barBuilder(svgWidth, svgHeight, borderRadius, position))
			.style('fill', progressBarColorValue.current)

		const widthBar: number = position === 'horizontal' ? svgWidth * (percentage / 100) : svgWidth
		const heightBar: number = position === 'vertical' ? svgHeight * (percentage / 100) : svgHeight
		progressBarSVG
			.select(`#progress-${id}`)
			.transition()
			.duration(350)
			.attr('d', barBuilder(widthBar, heightBar, borderRadius, position))
			.style('fill', progressColorValue.current)
		progressBarSVG.select(`#progress-bar-text-${id}`).style('display', displayPercentage ? '' : 'none')
		if (type === 'meter') {
			progressBarSVG
				.select('line')
				.style(
					'stroke',
					progressColorValue.current !== undefined
						? progressColorValue.current
						: getValueToken(createTokenList(tokens), 'ColorGreen')
				)
				.style('stroke-width', 1)
				.attr('x1', svgWidth * (percentage / 100) - 0.5)
				.attr('y1', 0)
				.attr('x2', svgWidth * (percentage / 100) - 0.5)
				.attr('y2', 5)
				.attr(
					'transform',
					position === 'vertical' ? `translate(0, ${svgHeight})` : `translate(0, ${svgHeight})`
				)
		}

		if (displayPercentage) {
			repositionTextPercentage()
		}
		repositionTextValueBottom()
	}

	const repositionTextPercentage = () => {
		const progressBarSVG = d3.select(d3Container.current)
		const { translateText, percentageColor } = percentageTextBuilder(
			percentage,
			position === 'horizontal' ? svgWidth : svgHeight,
			position,
			progressColorValue.current
		)
		const textTransform =
			position === 'horizontal' ? `translate(${translateText}, 0)` : `translate(0, ${translateText})`
		if (!showZero && percentage === 0 && textToZero !== '') {
			progressBarSVG
				.select(`#progress-bar-text-${id}`)
				.text(textToZero)
				.attr('x', '50%')
				.attr('text-anchor', 'middle')
				.style('fill', percentageColor)
		} else {
			progressBarSVG
				.select(`#progress-bar-text-${id}`)
				.text(getPercentageToDisplay())
				.attr('transform', textTransform)
				.style('fill', percentageColor)
		}
	}

	const repositionTextValueBottom = () => {
		const progressBarSVG = d3.select(d3Container.current)
		const { translateText, percentageColor } = percentageTextBuilder(
			percentage,
			position === 'horizontal' ? svgWidth : svgHeight,
			position,
			progressColorValue.current
		)
		const textTransform =
			position === 'horizontal' ? `translate(${translateText}, 0)` : `translate(0, ${translateText})`
		progressBarSVG
			.select(`#progress-bar-text-bottom-${id}`)
			.text(getPercentageToDisplay())
			.attr('transform', textTransform)
			.style('fill', percentageColor)
	}

	const formatNumber = (number: number): string => {
		return number === 0 ? '0' : new Intl.NumberFormat().format(number)
	}

	return (
		<ToolTip testId={`info-tooltip-bar-${testId}`} {...tooltipInfo}>
			<div data-testid={`general-progress-bar-container-${testId}`} className={styles.progressBarContainer}>
				{label && (
					<label data-testid={`label-bar-${testId}`} className={styles.progressBarLabel}>
						{label}
					</label>
				)}
				<div
					data-testid={`value-container-${testId}`}
					className={cx(styles.progressBarValueContainer, styles[type], styles[position])}
				>
					{type === 'meter' ? (
						<div className={styles.sideTexts} style={{ height: svgHeight }}>
							{getPercentageToDisplay()}
						</div>
					) : (
						''
					)}
					{/**TODO: Condition show bottom legend**/}
					<svg
						data-testid={`svg-bar-${testId}`}
						width={svgWidth}
						height={type === 'meter' ? svgHeight * 2 : svgHeight}
						ref={d3Container}
					/>
					{type === 'meter' ? (
						<div className={styles.sideTexts} style={{ height: svgHeight, marginTop: -(svgHeight - 10) }}>
							<div>{textTotal}</div>
							<div className={styles.number}>{formatNumber(total)}</div>
						</div>
					) : numberToShow === 'total' ? (
						<p
							data-testid={`total-bar-${testId}`}
							className={styles.value}
							style={{ color: numberToShowColor }}
						>
							{abbreviateNumber(total, precision)}
						</p>
					) : numberToShow === 'value' && (showZero || (!showZero && value !== 0)) ? (
						<p
							data-testid={`value-bar-${testId}`}
							className={styles.value}
							style={{ color: numberToShowColor }}
						>
							{abbreviateNumber(value, precision)}
						</p>
					) : (
						''
					)}
				</div>
			</div>
		</ToolTip>
	)
}

export default ProgressBar
