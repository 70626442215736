import { TFunction } from 'react-i18next'
import { CellData } from '../../data-block-element-selector/data-table'
import { ElementUIFacade } from '../ElementUIFacade'
import industriesCodeList from '../industries-codes.json'

const buildChildrenTree = (
	listIndustriesCode: Array<ElementUIFacade>,
	root: ElementUIFacade,
	t: TFunction<'translation'>
) => {
	const listIndustries: Array<ElementUIFacade> = []
	industriesCodeList.forEach((industryCode) => {
		const subItem: ElementUIFacade = {
			blockId: industryCode,
			elementId: industryCode,
			displayName: t(industryCode),
			description: t('text.description.nested.array', { principalIndustry: t(industryCode) }),
			dataType: root.dataType,
			level: root.level,
			showChildrenBlocks: false
		}
		const dataTable: Array<CellData> = []
		const elementChilds: Array<ElementUIFacade> = []
		listIndustriesCode.forEach((industryCodeItem) => {
			if (industryCodeItem.elementId.includes(industryCode)) {
				elementChilds.push(industryCodeItem)
				dataTable.push({
					columnName: industryCodeItem.displayName,
					description: industryCodeItem.description || ''
				})
			}
		})
		if (dataTable.length && elementChilds.length) {
			subItem.dataTable = dataTable
			subItem.childs = elementChilds
			listIndustries.push(subItem)
		}
	})

	return listIndustries
}

export const buildIndustryCodesTree = (
	flattenedIndustryCodesDataBlockList: Array<ElementUIFacade>,
	t: TFunction<'translation'>
) => {
	const tree: Array<ElementUIFacade> = []
	if (flattenedIndustryCodesDataBlockList.length) {
		const root: ElementUIFacade = {
			elementId: 'industryCodes',
			displayName: t('text.industryCodes'),
			level: '2',
			dataType: t('text.dataType'),
			showChildrenBlocks: true,
			hideElementDetail: true
		}
		root.childs = buildChildrenTree(flattenedIndustryCodesDataBlockList, root, t)
		tree.push(root)
	}
	return tree
}
