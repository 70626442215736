import { AxiosResponse } from 'axios'
import { FileUploadResponse } from '../../../types'
import { ApiErrorState, setApiErrorAction } from '../../apiError'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import {
	setPercentageUploadedAction,
	updateCurrentProjectAction,
	updateLoadingNextStep,
	uploadingFileAction
} from '../actions'

export const uploadFile =
	(): AppThunk<Promise<void>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<void> => {
		const fileInfo = getState().projectWizard.currentProject.fileInfo
		if (fileInfo && !fileInfo.uploadInfo) {
			dispatch(updateLoadingNextStep(true))
			dispatch(uploadingFileAction(true))

			const fileUploadURL = `/pls/fileuploads/${fileInfo.file?.name}`

			const formData = new FormData()
			formData.append('file', getState().projectWizard.currentProject.fileInfo?.file || '')

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
			const apiClient = getApiClient4Thunks(dispatch)

			return apiClient
				.post(fileUploadURL, formData, config)
				.then(async (uploadResponse: AxiosResponse<FileUploadResponse>) => {
					dispatch(updateCurrentProjectAction({ fileInfo: { uploadInfo: uploadResponse.data } }))
					dispatch(setPercentageUploadedAction(100))
					dispatch(updateLoadingNextStep(false))
				})
				.catch((e) => {
					console.error(e)
					const errorMessage = JSON.parse(e.request.response).UIAction
					dispatch(updateLoadingNextStep(false))
					dispatch(uploadingFileAction(false))
					const errorData: ApiErrorState = {
						errorCode: errorMessage?.code,
						message: errorMessage?.message
					}
					dispatch(setApiErrorAction(errorData))
					throw e
				})
		} else {
			return Promise.reject()
		}
	}
