import { ChangeEvent, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import information from '../../../../../assets/images/info.svg'
import lock from '../../../../../assets/images/lock.svg'
import mandatoryElements from '../../../../../components/data-block-searcher/arrayMandatoryElesAndAlerts.json'
import schemaClassification from '../../../../../components/data-block-searcher/child-nodes-schema/schemaType-classification.json'
import { ElementUIFacade } from '../../../../../components/data-block-searcher/ElementUIFacade'
import industriesCodeList from '../../../../../components/data-block-searcher/industries-codes.json'
import { Accordion, AccordionHeader, AccordionPanel, CheckBox } from '../../../../../local-core-ui'
import { RootState, useAppDispatch, useAppSelector } from '../../../../../store'
import { addElementInPreview, removeElementInPreview } from '../../../../../store/projectWizard/actions'
import { Element } from '../../../../../types'
import tradeUpList from '../../select-your-data/tradeup-elements.json'
import styles from './data-block-previewer-enrichment.module.scss'

interface DataBlockPreviewerEnrichmentProps {
	title?: string
	selectedElements: Array<Element>
	defaultElements?: Array<Element>
	readOnly?: boolean
	blockId: string
}

export interface GroupedElementsByLevel {
	[key: string]: Array<Element>
}
export const DataBlockPreviewerEnrichment = ({
	title,
	selectedElements,
	defaultElements,
	readOnly,
	blockId
}: DataBlockPreviewerEnrichmentProps): ReactElement => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const [expanded, setExpanded] = useState<boolean>(true)
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const testId = 'enrichmentPreviewer'
	const sortByDisplayName = (a: Element, b: Element) => {
		return new Intl.Collator(navigator.language, { numeric: true }).compare(a.displayName, b.displayName)
	}

	const groupElementsByLevel = () => {
		const groupedElementsByLevel: GroupedElementsByLevel = {}
		selectedElements.forEach((eleObj: Element) => {
			if (groupedElementsByLevel[eleObj.level]) {
				groupedElementsByLevel[eleObj.level].push(eleObj)
			} else {
				groupedElementsByLevel[eleObj.level] = []
				groupedElementsByLevel[eleObj.level].push(eleObj)
			}
		})
		return groupedElementsByLevel
	}
	const groupedElementsByLevel = groupElementsByLevel()

	const dataBlockElements = (key: string) => {
		const elementsToRender: Array<Element> = groupedElementsByLevel[key as keyof typeof groupedElementsByLevel]
		elementsToRender.sort(sortByDisplayName)
		const temporalSelectedElements: Array<Element> = mappingChilds(elementsToRender)
		return temporalSelectedElements.map((element: ElementUIFacade) => {
			return (
				<li key={element.elementId}>
					<div>
						<div data-testid="data-block-container" className={styles.dataBlockPreviewerEnrichmentElement}>
							<CheckBox
								id={element.elementId}
								label={element.displayName}
								onChange={(e) => handleOnchange(e, element)}
								checked={isElementChecked(element.elementId)}
								disabled={
									readOnly ||
									isElementCheckedByDefault(element.elementId) ||
									isElementCheckedMandatory(element.elementId) ||
									isElementTradeUp(element.elementId) ||
									isRemovedFromDeprecated(element.elementId) ||
									isElementCheckedMandatoryforAging(element.elementId) ||
									isElementCheckedMandatoryforClassif(element.elementId)
								}
								testId={`checkbox-${element.elementId}-test`}
							/>
						</div>
						{element.childs?.map((elementNested) => (
							<div
								key={element.elementId + '-container-' + elementNested.elementId}
								className={styles.dataBlockPreviewerEnrichmentElement}
								data-testid="element-container"
							>
								<li
									data-testid="element-child"
									key={element.elementId + '-child-' + elementNested.elementId}
								>
									<label data-testid="element-label">{elementNested.displayName}</label>
								</li>
							</div>
						))}
					</div>
				</li>
			)
		})
	}

	const getIndustriesCodeListChilds = (elementsToRender: Array<Element>, temporalArraySelected: Array<Element>) => {
		industriesCodeList.forEach((industryCode) => {
			const elementChild: Array<Element> = []
			elementsToRender.map((selectedElement) => {
				const isNestedElement = selectedElement.elementId.includes(industryCode)
				const idx = temporalArraySelected.findIndex(
					(temporalSelected) => selectedElement.elementId === temporalSelected.elementId
				)
				if (isNestedElement && idx > -1) {
					elementChild.push(selectedElement)
					temporalArraySelected.splice(idx, 1)
				}
			})

			if (elementChild.length) {
				const nestedArray: ElementUIFacade = {
					elementId: industryCode,
					displayName: t(industryCode),
					childs: elementChild
				}
				temporalArraySelected.push(nestedArray)
			}
		})
	}

	const getSECDetailsListChilds = (elementsToRender: Array<Element>, temporalArraySelected: Array<Element>) => {
		const classification = schemaClassification.SECDetailsId.classifications
		const subElms = schemaClassification.SECDetailsId.arrayChild
		classification.forEach((classif) => {
			subElms.forEach((subElem) => {
				const elementChild: Array<Element> = []
				const nestedElements = elementsToRender.filter((selectedElement) => {
					if (
						selectedElement.elementId.startsWith(classif) &&
						selectedElement.elementId.includes('_' + subElem)
					) {
						return selectedElement
					}
				})
				if (nestedElements.length) {
					nestedElements.forEach((nestedElement) => {
						const idx = temporalArraySelected.findIndex(
							(temporalSelected) =>
								temporalSelected.elementId.startsWith(classif) &&
								temporalSelected.elementId.includes('_' + subElem)
						)
						if (idx > -1) {
							elementChild.push(nestedElement)
							temporalArraySelected.splice(idx, 1)
						}
					})

					if (elementChild.length) {
						const nestedArray: ElementUIFacade = {
							elementId: classif + subElem,
							displayName: elementChild[0].displayName.replace(/[0-9]/g, ''),
							childs: elementChild
						}
						temporalArraySelected.push(nestedArray)
					}
				}
			})
		})
	}

	const mappingChilds = (elementsToRender: Array<Element>) => {
		const temporalArraySelected: Array<Element> = [...elementsToRender]
		getIndustriesCodeListChilds(elementsToRender, temporalArraySelected)
		getSECDetailsListChilds(elementsToRender, temporalArraySelected)
		return temporalArraySelected
	}

	const level = () => {
		if (selectedElements === undefined || selectedElements.length == 0) return 0
		return (
			selectedElements.reduce((prevElement: ElementUIFacade, currentElement: ElementUIFacade) =>
				Number(prevElement.level) > Number(currentElement.level) ? prevElement : currentElement
			) as ElementUIFacade
		).level
	}

	const handleOnchange = (e: ChangeEvent<HTMLInputElement>, element: Element) => {
		if (e.target.checked) {
			if (blockId === 'companyinfo') {
				const isIndustry = industriesCodeList.find((industry) => industry === element.elementId)
				if (isIndustry) {
					selectedElements.map((selectedElement) => {
						if (selectedElement.elementId.includes(element.elementId)) {
							dispatch(addElementInPreview(selectedElement))
						}
					})
					return
				}
			} else if (blockId === 'diversityinsight') {
				const isSECDetailsClassif = schemaClassification.SECDetailsId.classifications.find((classif) =>
					element.elementId.startsWith(classif)
				)
				if (isSECDetailsClassif) {
					selectedElements.map((selectedElement) => {
						if (selectedElement.elementId.startsWith(element.elementId)) {
							dispatch(addElementInPreview(selectedElement))
						}
					})
					return
				}
			}
			dispatch(addElementInPreview(element))
		} else {
			if (blockId === 'companyinfo') {
				const isIndustry = industriesCodeList.find((industry) => industry === element.elementId)
				if (isIndustry) {
					selectedElements.map((selectedElement) => {
						if (selectedElement.elementId.includes(element.elementId)) {
							dispatch(removeElementInPreview(selectedElement))
						}
					})
					return
				}
			} else if (blockId === 'diversityinsight') {
				const isSECDetailsClassif = schemaClassification.SECDetailsId.classifications.find((classif) =>
					element.elementId.startsWith(classif)
				)
				if (isSECDetailsClassif) {
					selectedElements.map((selectedElement) => {
						if (selectedElement.elementId.startsWith(element.elementId)) {
							dispatch(removeElementInPreview(selectedElement))
						}
					})
					return
				}
			}
			dispatch(removeElementInPreview(element))
		}
	}

	const isElementTradeUp = (elementId: string) => {
		return !!tradeUpList?.find((elem) => elem.elements.find((el) => el.elementId === elementId))
	}
	const isElementCheckedByDefault = (elementId: string) => {
		return !!defaultElements?.find((element) => element.elementId === elementId)
	}

	const isRemovedFromDeprecated = (elementId: string) => {
		return !!projectWizardState.enrichmentPreviewSubStep.removedElements.find(
			(removedElement) => removedElement.elementId === elementId
		)
	}

	const isElementCheckedMandatory = (elementId: string) => {
		const mandatoryElementsToPreview = mandatoryElements['mandatoryToPreview']
		const elementSplit = elementId.split('_')
		const suffix = elementSplit[elementSplit.length - 1]
		return mandatoryElementsToPreview.indexOf(elementId.replace(new RegExp(suffix + '$'), '')) > -1
	}

	const isElementCheckedMandatoryforAging = (elementId: string) => {
		const mandatoryElementsToPreview = mandatoryElements['mandatoryToPreview']
		const elementSplit = elementId.split('_')
		if (elementSplit[0] && elementSplit[1]) {
			const elementToSearch = elementSplit[0] + '_' + elementSplit[1] + '_'
			return mandatoryElementsToPreview?.indexOf(elementToSearch) > -1
		}
	}

	const isElementCheckedMandatoryforClassif = (elementId: string) => {
		const mandatoryElementsToPreview = mandatoryElements['mandatoryToPreview']
		return mandatoryElementsToPreview.some((mandatoryEle) => (elementId + '_').includes('_' + mandatoryEle))
	}

	const isElementChecked = (elementId: string) => {
		if (defaultElements && isElementCheckedByDefault(elementId)) {
			return true
		}
		const isIndustry = industriesCodeList.find((industry) => industry === elementId)
		const isSECDetails = schemaClassification.SECDetailsId.classifications.find((classif) =>
			elementId.startsWith(classif)
		)
		if (isIndustry) {
			return !isRemoved(elementId)
		} else if (isSECDetails) {
			return !isRemoved(elementId)
		} else {
			return !projectWizardState.enrichmentPreviewSubStep.removedElements.find(
				(removedElement) => removedElement.elementId === elementId
			)
		}
	}

	const isRemoved = (elementId: string) => {
		return projectWizardState.enrichmentPreviewSubStep.removedElements.find((removedElement) => {
			return removedElement.elementId.includes(elementId)
		})
	}

	const reducer = (accumulator: number, element: Element) => {
		if (
			projectWizardState.enrichmentPreviewSubStep.removedElements.find(
				(removedElement) => removedElement.elementId == element.elementId
			)
		)
			return accumulator
		else return accumulator + 1
	}

	const countSelectedElements = (levelElments: Array<Element>) => {
		return levelElments.reduce(reducer, 0)
	}

	return (
		<div data-testid="data-block-previewer-container" className={styles.dataBlockPreviewerEnrichmentContainer}>
			<div data-testid="data-block-previewer-header" className={styles.dataBlockPreviewerEnrichmentHeader}>
				<div className={styles.accordionHeaderContainer}>
					<div className={styles.containerImgAndTitle}>
						{title && defaultElements ? <img width="12px" src={lock} alt="view elements lock" /> : null}
						<h4
							data-testid="title-enrichment-data-blocks"
							className={styles.dataBlockPreviewerEnrichmentTitle}
						>
							{title}
						</h4>
					</div>
					<div
						data-testid="collapsed-expanded-container"
						className={styles.collapsedExpandedContainer}
						onClick={() => setExpanded(!expanded)}
					>
						<div data-testid="text-collapsed-expanded" className={styles.textCollapsedExpanded}>
							{expanded ? t('accordion.collapse.all') : t('accordion.expand.all')}
						</div>
						<div className={expanded ? styles.bottomArrow : styles.rightArrow} />
					</div>
				</div>
				{blockId === 'entityresolution' && (
					<div
						data-testid="data-block-previewer-box-info-entityresolution"
						className={styles.dataBlockPreviewerBoxInformation}
					>
						<div className={styles.informationContainer}>
							<img className={styles.informationIcon} src={information} alt={'information'} />
							<div>{t('dataBlock.previewer.information.entityResolution')}</div>
						</div>
					</div>
				)}
				{Object.keys(groupedElementsByLevel).map((key, index) => {
					const levelElments = groupedElementsByLevel[key as keyof typeof groupedElementsByLevel]
					const levelLabelKey =
						['0', '1', '2', '3', '4', '5'].findIndex((level) => level === key) > -1
							? 'data.block.tile.level'
							: `data.block.tile.level.sideBlock.${key}`
					const levelValue = t(levelLabelKey, { level: key })
					const levelValueWithCount =
						levelValue +
						' - ' +
						t('data.block.tile.selected', { selected: countSelectedElements(levelElments) })
					return (
						<div id="accordionContainer" key={index}>
							<Accordion
								testId={'accordionItem'}
								allowMultipleOpen={true}
								id={'enrichment' + '-accordionItem-' + index}
								expanded={expanded}
							>
								<AccordionHeader
									title={levelValueWithCount}
									isOpen={true}
									controls={testId + '-accordion-panel'}
									stickyAdditionalText={true}
								></AccordionHeader>
								<AccordionPanel
									id={testId + '-accordion-panel'}
									isOpen={true}
									existControlElement={true}
								>
									<div className={styles.dataBlockPreviewerEnrichmentContainerList} key={index}>
										<ul
											data-testid="data-block-previewer-enrichment-elements-list"
											className={styles.dataBlockPreviewerEnrichmentElementsList}
										>
											{dataBlockElements(key)}
										</ul>
									</div>
								</AccordionPanel>
							</Accordion>
						</div>
					)
				})}
			</div>
		</div>
	)
}
