import { FC, useEffect } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { useEntitlement, useFeatures, useModules, usePlatform } from '../../hooks/useEntitlements'
import { RootState, useAppSelector } from '../../store'
import { AuthOrigins } from '../../store/session/types/AuthOrigins'

export interface IRouteProps {
	path: string
	features?: string[]
	modules?: string[]
	entitlement?: string
	accessLevel?: string[]
	actions?: string[]
	component: React.ComponentType<unknown>
	exact?: boolean
	label?: string
	showNav?: boolean
}

export interface SwitchRouteProps {
	routes: IRouteProps[]
}

export const SwitchRoute: FC<IRouteProps> = ({
	features,
	modules,
	entitlement = '',
	actions = [''],
	path,
	component,
	exact
}: IRouteProps) => {
	//note because useFeatures is a hook we need to break it out into it's own component since we cannot embed it in a filter
	const hasEntitlements = useEntitlement(entitlement, actions)
	const hasFeatures = useFeatures(features)
	const hasModules = useModules(modules)
	if (hasEntitlements && hasFeatures && hasModules) {
		return <Route path={path} component={component} exact={exact} />
	} else {
		return <Redirect from={path} to="/dashboard/overview" />
	}
}

export const SwitchRoutes: FC<SwitchRouteProps> = ({ routes }: SwitchRouteProps) => {
	const selectSession = (state: RootState) => state.session
	const history = useHistory()
	const session = useAppSelector(selectSession)
	const selectConnections = (state: RootState) => state.connection
	const connection = useAppSelector(selectConnections)
	const isSteward = useModules(['Stewardship']) && session.user?.AccessLevel === 'DATA_STEWARD'
	const isSalesforce = usePlatform('salesforce')
	const isC4S = useModules(['C4S'])
	const hasC4SAccessRevoked = !isC4S && isSalesforce
	const enableC4SAuthMgmt = useFeatures(['EnableC4SAuthMgmt'])
	const location = useLocation()

	//this checks to see if the user is in salesforce iFrame and redirects them if they don't have module flag
	const setRoute = () => {
		if (isSalesforce) {
			if (hasC4SAccessRevoked) {
				history.push('/c4sAccess')
			}
		}
		if (enableC4SAuthMgmt) {
			if (connection.authOrigin === AuthOrigins.Connections) {
				return <Redirect from="/" to={'/connections'} />
			} else if (connection.authOrigin === AuthOrigins.Wizard) {
				return <Redirect from="/" to={'/steps/project-wizard/select-data/select-input'} />
			}
		}
		if (isSteward) {
			return <Redirect from="/" to={'/steward/review'} />
		} else if (isSalesforce) {
			return <Redirect from="/" to={'/dashboard/projects'} />
		} else {
			return <Redirect from="/" to={'/dashboard/overview'} />
		}
	}

	useEffect(() => {
		const dcpContainer = document.querySelector("[class*='app-container']") as HTMLDivElement
		if (location.pathname === '/report') {
			dcpContainer.style.padding = '0'
		} else if (dcpContainer.style.padding !== '') {
			dcpContainer.style.padding = ''
		}
	}, [location])

	return (
		<Switch>
			{routes.map((route) => (
				<SwitchRoute {...route} key={route.path} />
			))}
			{setRoute()}
		</Switch>
	)
}
