import { FC, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/DnB Connect.svg'
import styles from './dcp-logo.module.scss'

interface DCPLogoProps {
	onClick?: (event: MouseEvent<HTMLImageElement>) => void
}

export const DCPLogo: FC<DCPLogoProps> = ({ onClick }: DCPLogoProps) => {
	const { t } = useTranslation()
	if (onClick) {
		return (
			<div className={styles.dcpLogoLink}>
				<img alt={t('dcp.logo.dnb-connect')} src={logo} onClick={onClick} data-testid="dcp-logo" />
			</div>
		)
	} else {
		return (
			<Link className={styles.dcpLogoLink} to="/dashboard/overview" replace={true}>
				<img alt={t('dcp.logo.dnb-connect')} src={logo} data-testid="dcp-logo" />
			</Link>
		)
	}
}
