import { ReactElement } from 'react'
import { Tile } from '../../components/tile/tile'
import styles from './dashboard-allUsers.module.scss'
import { DashboardTabUsers } from './dashboard-tab-users/dashboard-tab-users'

export function DashboardAllUsers(): ReactElement {
	return (
		<div className={styles.dashboardTabAllUsers}>
			<Tile testId="dashboard-all-users-tile">
				<DashboardTabUsers />
			</Tile>
		</div>
	)
}
