import { ColorOrangeBurnt, ColorSlateLight } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { DNBButton } from '@dnb-uux-design-system/react'
import { Fragment, ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useFeatures } from '../../hooks/useEntitlements'
import { Divider, Icon } from '../../local-core-ui'
import { ApiErrorState } from '../../store/apiError'
import { authenticateWithSalesforce } from '../../utilities'
import styles from './error-modal.module.scss'

interface OAuthSectionProps {
	apiError: ApiErrorState
	onClose: () => void
}

export const OAuthSection = ({ apiError, onClose }: OAuthSectionProps): ReactElement => {
	const { t } = useTranslation()
	const enableManagedConnectedApp = useFeatures(['C4SEnableManagedConnectedApp'])
	const enableC4SAuthMgmt = useFeatures(['EnableC4SAuthMgmt'])

	return (
		<Fragment>
			<div className={styles.errorMessageOAuthTitle}>
				<Icon testId="alert-big-error-modal" type="notification" size="small" color={ColorOrangeBurnt} />
				<span className={styles.errorMessageOAuthSpan}>{t('oauth.modal.title')}</span>
			</div>
			<div className={styles.errorMessageOAuthDesc}>
				<Trans i18nKey="oauth.modal.message" />
			</div>
			<div className={styles.errorMessageOAuthDivider}>
				<Divider color={ColorSlateLight} size="small" />
			</div>
			<div className={styles.errorMessageOAuthButtonsContainer}>
				<div className={styles.errorMessageOAuthButton}>
					<DNBButton
						size="compact"
						variant="secondary"
						onClick={onClose}
						data-testid="OAuth-Modal-Close-Button"
					>
						{t('Connect.closeButton')}
					</DNBButton>
				</div>
				{apiError.oAuthC4SError?.isAdmin && (
					<div className={styles.errorMessageOAuthButton}>
						<DNBButton
							size="compact"
							variant="primary"
							onClick={() => {
								authenticateWithSalesforce(
									apiError.oAuthC4SError?.domain,
									enableManagedConnectedApp,
									apiError.oAuthC4SError?.isSandbox,
									enableC4SAuthMgmt
								)
								onClose()
							}}
							data-testid="OAuth-Modal-Establish-Connection-Button"
						>
							{t('oauth.modal.button')}
						</DNBButton>
					</div>
				)}
			</div>
		</Fragment>
	)
}
