import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DynamicWizardContext } from '../helpers/DynamicWizardContext'

export interface WizardState {
	dynamicWizardContext: DynamicWizardContext
}

const initialState: WizardState = {
	dynamicWizardContext: new DynamicWizardContext()
}

const wizardSlice = createSlice({
	name: 'wizard',
	initialState,
	reducers: {
		setDynamicWizardContext: (state, action: PayloadAction<DynamicWizardContext>) => {
			state.dynamicWizardContext = action.payload
		}
	}
})

// Extract the action creators object and the reducer
const { actions, reducer } = wizardSlice
// Extract and export each action creator by name
export const { setDynamicWizardContext } = actions

export default reducer
