import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { Block } from '../types'
import { getDataBlocksMetadata } from './api/getDataBlocksMetadata'

export const useDataBlocksMetadata = (): UseQueryResult<Array<Block>, unknown> => {
	const apiClient = useApi()

	const queryKey = ['getDataBlocksMetadata']
	const queryFunction = () => getDataBlocksMetadata(apiClient)
	return useQuery(queryKey, queryFunction)
}
