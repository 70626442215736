import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar } from '../../local-core-ui'
import styles from './user-tile.module.scss'

export interface UserTileProps {
	userID: string
	name: string
	role: string
	jobTitle: string
	emailAddress: string
	isBusy?: boolean
	deleteUserButtonClick?(userId: string): void
	editUserButtonClick?(userId: string): void
	actionToTeams?: 'add' | 'remove'
	actionAddToTeam?(userEmail: string): void
	actionRemoveOfTeam?(userEmail: string): void
	buttonPosition?: 'Left' | 'Center' | 'Right'
	hideButtons?: boolean
	testId: string
}

export const UserTile = ({
	userID,
	name,
	role,
	jobTitle,
	emailAddress,
	isBusy = false,
	deleteUserButtonClick,
	editUserButtonClick,
	actionToTeams,
	actionAddToTeam,
	actionRemoveOfTeam,
	buttonPosition = 'Center',
	hideButtons = false,
	testId
}: UserTileProps): ReactElement => {
	const { t } = useTranslation()
	const [showUserInformation, setShowUserInformation] = useState(true)
	const [showConfirmDeleteUser, setShowConfirmDeleteUser] = useState(false)
	const buttonPositionClassName = styles[`buttonAlign${buttonPosition}`]
	const confirmDeleteUser = () => {
		setShowConfirmDeleteUser(true)
	}
	const onBlurUserTile = () => {
		if (!showConfirmDeleteUser) {
			setShowUserInformation(true)
			setShowConfirmDeleteUser(false)
		}
	}

	const deleteAction = (userId: string) => {
		setShowUserInformation(true)
		setShowConfirmDeleteUser(false)
		if (deleteUserButtonClick) {
			deleteUserButtonClick(userId)
		} else if (actionRemoveOfTeam) {
			actionRemoveOfTeam(emailAddress)
		}
	}

	const onMouseOverTile = () => {
		if (!hideButtons) {
			setShowUserInformation(isBusy)
		}
	}
	return (
		<div
			className={`${styles.userTileContainer} ${
				showUserInformation && !showConfirmDeleteUser ? styles.backgroundWhite : styles.backgroundBlue
			}
				${isBusy ? styles.busy : ''}`}
			tabIndex={0}
			onClick={() => onMouseOverTile()}
			onBlur={() => onBlurUserTile()}
			onMouseOver={() => onMouseOverTile()}
			onMouseLeave={() => onBlurUserTile()}
			data-testid={testId + '-container'}
		>
			<div
				className={`${styles.lateralBar} ${
					showUserInformation && !showConfirmDeleteUser ? styles.backgroundWhite : styles.backgroundPink
				}`}
			/>
			<div className={styles.containerImgUser}>
				<Avatar userData={[{ label: 'Name', value: name || '' }]} testId={testId + '-ut-avatar'} />
			</div>
			<div data-testid="data-user" className={styles.dataUser}>
				<div className={!showConfirmDeleteUser ? styles.displayBlock : styles.displayNone}>
					<div className={styles.firstLine}>{name}</div>
					<div
						className={`${styles.userInformation} ${
							showUserInformation ? styles.displayBlock : styles.displayNone
						}`}
					>
						<div>{role}</div>
						<div>{jobTitle}</div>
						<div>{emailAddress}</div>
					</div>
					<div
						className={`${styles.containerButtons} ${buttonPositionClassName} ${
							showUserInformation ? styles.displayNone : styles.displayBlock
						}`}
					>
						{actionToTeams !== undefined ? (
							actionToTeams === 'add' ? (
								<button
									className={`${styles.buttonTile} ${styles.btnPrimaryShort}`}
									onClick={() => {
										if (actionAddToTeam) {
											actionAddToTeam(emailAddress)
										}
									}}
									data-testid={testId + '-add-btn'}
								>
									{t('user.tile.button.add')}
								</button>
							) : (
								<button
									className={`${styles.buttonTile} ${styles.btnPrimaryShort}`}
									onClick={() => {
										confirmDeleteUser()
									}}
									data-testid={testId + '-confirm-delete-btn'}
								>
									{t('user.tile.button.remove')}
								</button>
							)
						) : (
							<div>
								<button
									className={`${styles.buttonTile} ${styles.btnPrimaryShort}`}
									onClick={() => {
										if (editUserButtonClick) {
											editUserButtonClick(userID)
										}
									}}
									data-testid={testId + '-edit-btn'}
								>
									{t('user.tile.button.edit')}
								</button>
								<button
									className={`${styles.buttonTile} ${styles.btnSecondaryShort}`}
									onClick={() => confirmDeleteUser()}
									data-testid={testId + '-remove-btn'}
								>
									{t('user.tile.button.remove')}
								</button>
							</div>
						)}
					</div>
				</div>
				<div className={showConfirmDeleteUser ? styles.displayBlock : styles.displayNone}>
					<div className={styles.firstLine}> {t('user.tile.question')}</div>
					<div
						className={`${styles.containerButtons} ${
							showUserInformation ? styles.displayNone : styles.displayBlock
						}`}
					>
						<button
							className={`${styles.buttonTile} ${styles.btnPrimaryShort}`}
							onClick={() => deleteAction(userID)}
							data-testid={testId + '-delete-confirm'}
						>
							{t('user.tile.button.yes')}
						</button>
						<button
							className={`${styles.buttonTile} ${styles.btnSecondaryShort}`}
							onClick={() => setShowConfirmDeleteUser(false)}
							data-testid={testId + '-delete-cancel'}
						>
							{t('user.tile.button.no')}
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
