import { AxiosInstance, AxiosResponse } from 'axios'
import { ConnectionStatus } from './getC4SActiveConnection'

export enum System {
	Salesforce = 'Salesforce',
	Snowflake = 'Snowflake',
	ConnectManage = 'ConnectManage'
}

export interface Connection {
	name: string
	system: System
	created: number
	updated: number
	user: string
	status: ConnectionStatus
	displayName: string
}

export const getConnections = async (apiClient: AxiosInstance): Promise<Connection[]> => {
	const url = `/pls/integration/connection`
	return apiClient.get(url).then((response: AxiosResponse<Connection[]>) => response.data)
}
