import { AxiosResponse } from 'axios'
import { IdaasLoginRequest, IdaasLoginResponse } from '../../../types'
import { AppThunk, getApiClient4Thunks, SignOutReason, TDispatch } from '../../index'
import { authenticateSession, terminateSession } from '../actions'
import { SessionAuthenticator } from '../types'

export const idaasLogin =
	(username: string, password: string): AppThunk<Promise<void>> =>
	(dispatch: TDispatch): Promise<void> => {
		const apiClient = getApiClient4Thunks(dispatch)
		const idaasLoginReq: IdaasLoginRequest = {
			Username: username,
			Password: password
		}
		return apiClient
			.post('/pls/idaas-login', idaasLoginReq)
			.then((response: AxiosResponse<IdaasLoginResponse>) => {
				const availableConnectTenants = response.data.Result.Tenants.filter((tenant) =>
					tenant.EntitledApps.includes('DnB')
				)
				if (availableConnectTenants.length === 0) dispatch(terminateSession(SignOutReason.unauthorized))
				else {
					// SET AUTHORIZATION COOKIE, this ensures the BE accepts our calls
					document.cookie =
						'Authorization=' +
						(response.data.Uniqueness + '.' + response.data.Randomness) +
						'; SameSite=None; Secure; path=/; Priority=High'

					dispatch(
						authenticateSession({
							token: response.data.Uniqueness + '.' + response.data.Randomness,
							authenticator: SessionAuthenticator.idaas,
							tenants: availableConnectTenants,
							username: response.data.UserName
						})
					)
				}
			})
			.catch(() => {
				dispatch(terminateSession(SignOutReason.wronguserorpassword))
			})
	}
