import { DNBCheckbox, DNBRadio } from '@dnb-uux-design-system/react'
import { ChangeEvent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { sideBlocksStringReplace } from '../../components/data-block-searcher/helpers/side-blocks-string-replace'
import { Accordion, AccordionHeader, AccordionPanel } from '../../local-core-ui'
import { ElementDetail } from '../../types'
import { dataCategory } from './dashboard-myDataBlocks'
import styles from './dashboard-myDataBlocks.module.scss'

interface DataBlocksFilterPanelProps {
	blockList: Array<ElementDetail>
	dataBlocksFilterArray: dataCategory
	updateFilter(blockId: string, levelId: string, isChecked?: boolean, selectAll?: boolean): void
	testId: string
	selectedDomain: string
	onChangeDataType(domain: number): void
	filterApplied?: number
	domains: Array<string>
}

export const DataBlocksFilterPanel = ({
	blockList,
	dataBlocksFilterArray,
	updateFilter,
	testId,
	selectedDomain,
	onChangeDataType,
	filterApplied,
	domains
}: DataBlocksFilterPanelProps): ReactElement => {
	const { t } = useTranslation()
	const renderSelectAll = () => {
		let overAllLevelsCount = 0
		let selectedLevelsCount = 0
		Object.keys(dataBlocksFilterArray).forEach((key) => {
			const selectedLevelObject = dataBlocksFilterArray[key as keyof typeof dataBlocksFilterArray]

			Object.keys(selectedLevelObject).forEach((key) => {
				overAllLevelsCount++
				selectedLevelObject[key as keyof typeof selectedLevelObject] ? selectedLevelsCount++ : ''
			})
		})
		return selectedLevelsCount === overAllLevelsCount ? (
			<div
				data-testid={testId + '-SelectNone'}
				className={styles.selectAllText}
				onClick={() => updateFilter('', '', false, false)}
			>
				<span data-testid={testId + '-selectnone'}>{t('mydata.block.filter.selectNone')}</span>
			</div>
		) : selectedLevelsCount === 0 ? (
			<div
				data-testid={testId + '-SelectAll'}
				className={styles.selectAllText}
				onClick={() => updateFilter('', '', false, true)}
			>
				<span data-testid={testId + '-selectAll'}>{t('mydata.block.filter.selectAll')}</span>
			</div>
		) : (
			<div className={styles.selectAllText}>
				<span data-testid={testId + '-selectAll'} onClick={() => updateFilter('', '', false, true)}>
					{t('mydata.block.filter.selectAll')}
				</span>{' '}
				|{' '}
				<span data-testid={testId + '-selectnone'} onClick={() => updateFilter('', '', false, false)}>
					{t('mydata.block.filter.none')}
				</span>
			</div>
		)
	}

	const isAllLevelSelected = (blockId: string, isIndeterminate: boolean): boolean => {
		if (dataBlocksFilterArray && dataBlocksFilterArray[blockId as keyof typeof dataBlocksFilterArray]) {
			const selectedLevelObject = dataBlocksFilterArray[blockId as keyof typeof dataBlocksFilterArray]
			let levelsSelectedCount = 0
			Object.keys(selectedLevelObject).forEach((key) => {
				selectedLevelObject[key as keyof typeof selectedLevelObject] ? levelsSelectedCount++ : ''
			})
			if (isIndeterminate) {
				return levelsSelectedCount > 0 && levelsSelectedCount < Object.keys(selectedLevelObject).length
					? true
					: false
			} else
				return levelsSelectedCount > 0 && levelsSelectedCount === Object.keys(selectedLevelObject).length
					? true
					: false
		} else return false
	}
	return (
		<div data-testid={testId + '-data-category-cont'} className={styles.dataCategoryContainer}>
			<>
				<div data-testid={testId + '-data-category'} className={styles.filterPaneltitleText}>
					{t('mydata.category')}
				</div>
				{domains.length > 0 &&
					domains.map((domain, index) => (
						<DNBRadio
							label={domain}
							key={index}
							size="small"
							value={domain}
							checked={selectedDomain === domain}
							data-testid={testId + '-select-domain-salesmarketing'}
							onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeDataType(index)}
						/>
					))}
			</>
			<>
				<div data-testid={testId + '-selectAllcont'} className={styles.selectAllCont}>
					<div data-testid={testId + '-datablocktxt'} className={styles.filterPaneltitleText}>
						{t('mydata.block')}
					</div>
					{renderSelectAll()}
				</div>
				{blockList &&
					blockList?.length &&
					blockList.map((block, index) => {
						const blockLevelObj = dataBlocksFilterArray[block.blockId as keyof typeof dataBlocksFilterArray]
						return (
							dataBlocksFilterArray && (
								<Accordion
									testId={'accordionItem-' + index}
									allowMultipleOpen={true}
									id={'-accordionItem-' + index}
									key={`${index}_ + '-accordionItem-' + ${block.blockId}`}
								>
									<AccordionHeader
										title={sideBlocksStringReplace(block.blockId)}
										isOpen={true}
										controls={testId + '-accordion-panel' + index}
										stickyAdditionalText={true}
										key={`${index}_header_${block.blockId}`}
									>
										<div key={`${index}_${block.blockId}`} className={styles.checkboxContainer}>
											<DNBCheckbox
												id={block.blockId}
												checked={isAllLevelSelected(block.blockId, false)}
												indeterminate={isAllLevelSelected(block.blockId, true)}
												data-testid={testId + '-checkbox-' + block.blockId}
												onChange={(e: ChangeEvent<HTMLInputElement>) =>
													updateFilter(block.blockId, '', e.currentTarget.checked)
												}
												key={`${index}_${block.blockId}`}
												size="small"
											/>
										</div>
									</AccordionHeader>
									<AccordionPanel
										id={testId + '-accordion-panel' + index}
										isOpen={true}
										existControlElement={true}
										key={`${index}_panel_${block.blockId}`}
										testId={testId}
									>
										{blockLevelObj &&
											block.levels.map((level) => {
												const levelId = level?.level?.levelId
												const isLevelSelected =
													blockLevelObj[levelId as keyof typeof blockLevelObj]
												return (
													blockLevelObj && (
														<DNBCheckbox
															id={level.level.displayName}
															label={level.level.displayName}
															checked={isLevelSelected}
															data-testid={testId + '-checkbox-' + level.level.levelId}
															onChange={(e: ChangeEvent<HTMLInputElement>) =>
																updateFilter(block.blockId, level.level.levelId)
															}
															key={`${index}_${block.blockId}_${level.level.displayName}`}
															size="small"
														/>
													)
												)
											})}
									</AccordionPanel>
								</Accordion>
							)
						)
					})}
			</>
		</div>
	)
}
