import { AxiosResponse } from 'axios'
import { CreateSourceResponse, GetMatchRulesResponse } from '../../../types'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { addMatchRule } from '../actions'

export const getBaseMatchRule =
	(createdSource: CreateSourceResponse): AppThunk<Promise<void>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<void> => {
		if (createdSource && getState().projectWizard.currentProject.matchRules.length <= 0) {
			const url = `/pls/matchrules/sourceId/${createdSource.sourceId}?includeArchived=false&includeInactive=false`
			const apiClient = getApiClient4Thunks(dispatch)
			return apiClient
				.get(url)
				.then((response: AxiosResponse<Array<GetMatchRulesResponse>>) => {
					if (response.data.length > 0) {
						dispatch(addMatchRule(response.data[0]))
					}
				})
				.catch((e) => {
					console.error(e)
					throw e
				})
		} else {
			return new Promise<void>((resolve) => resolve())
		}
	}
