import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { ColorGrayDark } from '../../../../design-tokens/build/shared/token-colors.json'
import { Icon } from '../../local-core-ui/icon/icon'
import styles from './new-connection-tile.module.scss'

interface NewConnectionTileProps {
	onClickTile(): void
}

export const NewConnectionTile = ({ onClickTile }: NewConnectionTileProps): ReactElement => {
	const { t } = useTranslation()

	return (
		<button className={styles.newConnection} onClick={onClickTile}>
			<div className={styles.plusCircle}>
				<Icon testId="arrows-project-filter-bar" type={'plus'} size={'large'} color={ColorGrayDark} />
			</div>
			<div className={styles.label}>{t('dashboard.connections.add.tile')}</div>
		</button>
	)
}
