import { ColorSlateLight } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import {
	Divider,
	Dropdown,
	DropdownOptions,
	Grid,
	Input,
	Modal,
	RadioButton,
	TileCollection,
	ToolTip
} from '../../../local-core-ui'

import { DNBButton } from '@dnb-uux-design-system/react'
import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { DescriptionTextArea } from '../../../components/description-text-area/description-text-area'
import { UserTile } from '../../../components/user-tile/user-tile'
import { useDataBlocksEntitlements } from '../../../queries/useDataBlocksEntitlements'
import { useUsers } from '../../../queries/useUsers'
import { RootState, useAppDispatch, useAppSelector } from '../../../store'
import { updateCurrentProjectAction } from '../../../store/projectWizard/actions'
import {
	addTeamMember,
	getTeamInformation,
	removeTeamMember,
	updateTeamMembers
} from '../../../store/projectWizard/thunks'
import { PurposeOfUse, UserDetail } from '../../../types'
import styles from './setup-project.module.scss'
import { UserSelection } from './user-selection/user-selection'

export function SetupProject(): ReactElement {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()

	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const selectSession = (state: RootState) => state.session
	const sessionState = useAppSelector(selectSession)

	const [categoryOptions, setCategoryOptions] = useState<DropdownOptions[]>([])
	const [dataTypeOptions, setDataTypeOptions] = useState<string[]>([])
	const [usersInProject, setUsersInProject] = useState<UserDetail[]>([])
	const [availableUsers, setAvailableUsers] = useState<UserDetail[]>([])
	const [unmovableUsers, setUnmovableUsers] = useState<UserDetail[]>([])
	const [isModalOpen, setModalOpen] = useState(false)
	const purposeOfUse: PurposeOfUse = { domain: '', recordType: '' }
	const [domain, setDomain] = useState(projectWizardState.currentProject.purposeOfUse.domain)
	const [isNoEntitlementModalOpen, setNoEntitlementModalOpen] = useState<boolean>(false)

	const usersQuery = useUsers()
	const entitlementQuery = useDataBlocksEntitlements()
	const history = useHistory()

	useEffect(() => {
		if (usersQuery.data) setAvailableUsers(usersQuery.data)
		/**
		 * We only want to run this effect when isFetching flag from usersQuery changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [usersQuery.isFetching])

	useEffect(() => {
		setUsersInProject(projectWizardState.currentProject.team.TeamMembers)
	}, [usersInProject, projectWizardState.currentProject.team.TeamMembers])

	useEffect(() => {
		dispatch(getTeamInformation())
	}, [dispatch])

	useEffect(() => {
		if (entitlementQuery.isSuccess && entitlementQuery.data) {
			populateCategoryDropdown()
			if (entitlementQuery.data.length === 1) {
				onChangeCategory(entitlementQuery.data[0].domain)
			}
		} else if (!entitlementQuery.isFetching && !entitlementQuery.data) {
			setNoEntitlementModalOpen(true)
		}
		/**
		 * We only want to run this effect when isFetching flag from entitlementQuery changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entitlementQuery.isFetching])

	useEffect(() => {
		const projectCreatorEmail = projectWizardState.currentProject.team.CreatedBy
			? projectWizardState.currentProject.team.CreatedBy.Email
			: sessionState.user?.EmailAddress
		const currentUserEmail = sessionState.user?.EmailAddress
		const newUnmovableUsers: Array<UserDetail> = []

		if (projectCreatorEmail) {
			const projectCreator = projectWizardState.currentProject.team.TeamMembers.find((user) => {
				return projectCreatorEmail === user.Email
			})
			if (projectCreator) {
				newUnmovableUsers.push(projectCreator)
			} else {
				const projectCreatorToAdd = availableUsers?.find((user) => {
					return user.Email === projectCreatorEmail
				})
				if (projectCreatorToAdd) {
					newUnmovableUsers.push(projectCreatorToAdd)
					dispatch(addTeamMember(projectCreatorToAdd))
				}
			}
		}
		if (currentUserEmail && projectCreatorEmail !== currentUserEmail) {
			const currentUser = projectWizardState.currentProject.team.TeamMembers.find((user) => {
				return currentUserEmail === user.Email
			})
			if (currentUser) {
				newUnmovableUsers.push(currentUser)
			}
		}

		setUnmovableUsers(newUnmovableUsers)
		// Should not depend on unmovable users.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, availableUsers, sessionState.user, projectWizardState.currentProject.team])

	const isUserAlreadyInList = (list: UserDetail[], userDetail: UserDetail): boolean => {
		const userExist = list.find((user: UserDetail) => {
			return user.Email === userDetail.Email
		})
		return !!userExist
	}

	const populateCategoryDropdown = () => {
		const categories: DropdownOptions[] = []
		entitlementQuery.data?.forEach((entitlement) => {
			categories.push({
				label: t(entitlement.domain),
				value: entitlement.domain
			})
		})
		setCategoryOptions(categories)
		if (t(domain)) {
			populateDataTypeInfo(t(domain))
		}
	}

	const populateDataTypeInfo = (currentEntitlement: string) => {
		let dataTypes: string[] = []
		if (entitlementQuery.data) {
			for (const entitlement of entitlementQuery.data) {
				if (currentEntitlement === entitlement.domain) {
					dataTypes = [...Object.keys(entitlement.recordTypes)]
				}
			}
		}

		const indexDomainUse = dataTypes.indexOf('Domain Use')
		if (indexDomainUse > -1 && dataTypes.indexOf('Domain Use 1') > -1) {
			dataTypes.splice(indexDomainUse, 1)
		}
		const indexDomainMasterData = dataTypes.indexOf('Domain Master Data Use')
		if (indexDomainMasterData > -1 && dataTypes.indexOf('Domain Use 2') > -1) {
			dataTypes.splice(indexDomainMasterData, 1)
		}

		if (dataTypes.length === 1) {
			purposeOfUse.recordType = dataTypes[0]
			dispatch(updateCurrentProjectAction({ purposeOfUse: purposeOfUse }))
		} else if (!projectWizardState.currentProject.purposeOfUse.recordType) {
			purposeOfUse.recordType = ''
			dispatch(updateCurrentProjectAction({ purposeOfUse: purposeOfUse }))
		}
		setDataTypeOptions(dataTypes)
	}

	const onNameChange = (newName: string) => {
		dispatch(updateCurrentProjectAction({ name: newName }))
	}

	const onDescriptionChange = (newDescription: string) => {
		dispatch(updateCurrentProjectAction({ projectDescription: newDescription }))
	}

	const onChangeDataType = (event: ChangeEvent<HTMLInputElement>) => {
		purposeOfUse.recordType = event.target.value
		dispatch(updateCurrentProjectAction({ purposeOfUse: purposeOfUse }))
	}

	const onChangeCategory = (selected: string) => {
		if (domain !== selected) {
			purposeOfUse.domain = selected
			setDomain(selected)
			dispatch(updateCurrentProjectAction({ purposeOfUse: purposeOfUse }))
			populateDataTypeInfo(selected)
			if (projectWizardState.currentProject.purposeOfUse.domain === selected && dataTypeOptions.length) {
				setDataTypeOptions([])
				setDomain('')
			}
		}
	}

	const submitChanges = (list: UserDetail[]) => {
		dispatch(updateTeamMembers(list))
	}

	const removeFromTeam = (userEmail: string) => {
		dispatch(removeTeamMember(userEmail))
	}

	const getBulletList = (recordType: string) => {
		let i = 1
		const generatedComponent: Array<JSX.Element> = []
		while (t(recordType + '.option' + i) !== recordType + '.option' + i) {
			generatedComponent.push(<li key={recordType + 'bullet-' + i}>{t(recordType + '.option' + i)}</li>)
			i++
		}
		return generatedComponent
	}

	const goToDashboard = () => {
		history.push(`/dashboard/overview`)
	}

	return (
		<div className={styles.createProject} data-testid="create-project">
			<div data-testid="no-entitlement-modal-container">
				<Modal
					open={isNoEntitlementModalOpen}
					disableBackdropFilter={true}
					isContainer={true}
					showButtonClose={false}
					testId="NoEntitlementModal"
				>
					<div className={styles.noDataBlocksModalText}>
						<div className={styles.noDataBlocksModalTitle}>{t('no.data.blocks')}</div>
						<div className={styles.noDataBlocksModalMessage}>{t('subscriber.no.data.blocks')}</div>
					</div>
					<div className={styles.noDataBlocksModalDivider}>
						<Divider color={ColorSlateLight} size="small" />
					</div>
					<div className={styles.noDataBlocksModalButton}>
						<DNBButton
							size="compact"
							variant="primary"
							onClick={() => goToDashboard()}
							data-testid="Return-To-Dashboard-Button"
						>
							{t('return.to.dashboard')}
						</DNBButton>
					</div>
				</Modal>
			</div>
			<div data-testid="select-users-modal-container" className={styles.selectUsersModalContainer}>
				<Modal
					open={isModalOpen}
					onClose={() => setModalOpen(false)}
					isContainer={true}
					testId="SelectUsersModal"
				>
					<h1 data-testid="company-directory-title">{t('create.project.modal.header')}</h1>
					<UserSelection
						userDirectory={availableUsers}
						selectedUsers={usersInProject}
						unmovableUsers={unmovableUsers}
						onDone={(userList) => {
							setModalOpen(false)
							submitChanges(userList)
						}}
					/>
				</Modal>
			</div>
			<h1 data-testid="setup-project-header">{t('create.project.heading')}</h1>
			<Grid testId="container-setup-project" container gutter={2}>
				<Grid testId="input-setup-project" sm={6}>
					<Input
						id="displayName"
						hint={t('create.project.form.help.project.name')}
						onChangeFunction={(value: string) => onNameChange(value)}
						value={projectWizardState.currentProject.name}
						label={t('create.project.form.label.project.name')}
						size="fluid"
						required
						maxLength={30}
						disabled={!!projectWizardState.currentProject.id || projectWizardState.loadingNextStep}
						displayRemainingCharacters
						remainingCharactersText={t('create.project.form.characters.left') as string}
						testId="proj-setup-name"
					/>
				</Grid>
				<Grid testId="input-span-setup-project" sm={6}>
					<div className={styles.inputSpan}>{t('create.project.form.span.input')}</div>
				</Grid>
				<Grid testId="dropdown-wrapper-setup-project" sm={6}>
					<div className={styles.dropdownWrapper}>
						{entitlementQuery.data?.length === 1 && Object.keys(dataTypeOptions).length > 1 && (
							<div>
								<span className={styles.titleDataTypes}>{t('create.project.form.label.category')}</span>
								<br />
								<span className={styles.inputSpan}>{t(domain)}</span>
							</div>
						)}
						{entitlementQuery.data && entitlementQuery.data?.length > 1 && categoryOptions.length > 1 && (
							<Dropdown
								id={'domains'}
								hint={''}
								onChangeFunction={(selected: string) => onChangeCategory(selected)}
								label={t('create.project.form.label.categories')}
								options={categoryOptions}
								size="fluid"
								isRequired
								selected={domain}
								disabled={!!projectWizardState.currentProject.id || projectWizardState.loadingNextStep}
								testId="proj-setup-categories"
							/>
						)}
					</div>
				</Grid>
				<Grid testId="radio-button-wrapper-setup-project" sm={12}>
					<div className={styles.radioButtonWrapper}>
						{dataTypeOptions.length > 1 && (
							<div>
								<span className={styles.titleDataTypes}>
									{t('create.project.form.label.datatype')}
									<span className={styles.requiredSymbol}> *</span>
								</span>
								<div data-testid="radio-buttons-group" className={styles.radioButtonsGroup}>
									<div data-testid="radio-buttons-container" className={styles.radioButtons}>
										{dataTypeOptions.map((item: string) => {
											return (
												<div
													className={styles.radioContainer}
													key={t(domain) + item + 'container'}
													data-testid="each-radio-container"
												>
													<div className={styles.containerTooltip}>
														<ToolTip
															customContent={
																<div className={styles.containerDataTooltip}>
																	<span className={styles.title}>
																		{t(item + '.tooltip.title')}
																	</span>
																	<span className={styles.text}>
																		{t(item + '.tooltip.text')}
																	</span>
																</div>
															}
															position={'rightTop'}
															testId={item}
														>
															<div
																data-testid="text-hidden"
																className={styles.textHidden}
															>
																{t(item)}
															</div>
														</ToolTip>
													</div>
													<RadioButton
														key={t(domain) + item}
														value={item}
														group={'dataType'}
														id={item}
														label={t(item)}
														onChange={onChangeDataType}
														checked={
															item ===
															projectWizardState.currentProject.purposeOfUse.recordType
														}
														disabled={
															!!projectWizardState.currentProject.id ||
															projectWizardState.loadingNextStep
														}
														testId="proj-setup-data-types"
													/>
													<div className={styles.helpText}>{t(item + '.helpText')}</div>
												</div>
											)
										})}
									</div>
								</div>
							</div>
						)}
					</div>
					{projectWizardState.currentProject.purposeOfUse.recordType !== '' &&
						projectWizardState.currentProject.purposeOfUse.recordType !== undefined &&
						dataTypeOptions.length > 1 && (
							<div className={styles.descriptionWrapper}>
								<div className={styles.wrapperContent}>
									<span>{t(projectWizardState.currentProject.purposeOfUse.recordType)}</span>
									<ul>{getBulletList(projectWizardState.currentProject.purposeOfUse.recordType)}</ul>
								</div>
							</div>
						)}
				</Grid>
			</Grid>
			<Grid testId="container-text-area-setup-project" container gutter={2}>
				<Grid testId="text-area-description-setup-project" sm={12}>
					<DescriptionTextArea
						value={projectWizardState.currentProject.projectDescription}
						onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onDescriptionChange(e.target.value)}
						testId="proj-setup-desc"
					/>
					<DNBButton
						size="default"
						variant="primary"
						onClick={() => setModalOpen(true)}
						data-testid="button-test"
					>
						{t('create.project.form.button.add.member')}
					</DNBButton>
					<div className={styles.tileCollectionWrapper}>
						<TileCollection testId="setup-project" dimensions={{ sm: 6, md: 6, lg: 4, xl: 3 }}>
							{projectWizardState.currentProject.team.TeamMembers.map((user: UserDetail) => (
								<UserTile
									key={'user-tile-' + user.Username}
									userID={user.id}
									name={
										user.FirstName === null || user.LastName === null
											? t('user.name.noneProvided')
											: user.FirstName + ' ' + user.LastName
									}
									role={''}
									jobTitle={''}
									emailAddress={user.Email}
									actionToTeams={'remove'}
									actionRemoveOfTeam={(userEmail) => removeFromTeam(userEmail)}
									buttonPosition={'Left'}
									hideButtons={isUserAlreadyInList(unmovableUsers, user)}
									testId="user-tile-setup-project"
								/>
							))}
						</TileCollection>
					</div>
				</Grid>
			</Grid>
		</div>
	)
}
