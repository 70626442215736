import { FC } from 'react'
import { Icon } from '..'
import { Button } from '../button/button'
import styles from './error-state.module.scss'

export interface ErrorStateProps {
	errorMessage: string | JSX.Element
	retryText?: string
	onRetry?(): void
	onClose?(): void
	testId: string
}

export const ErrorState: FC<ErrorStateProps> = ({ errorMessage, retryText, onRetry, onClose, testId }) => {
	return (
		<div data-testid={`error-container-${testId}`} className={styles.errorContainer}>
			<div className={styles.closeBtn} tabIndex={0} onClick={() => onClose()} data-testid={testId + '-close'}>
				&times;
			</div>
			<div data-testid={`error-message-${testId}`} className={styles.errorMessage}>
				<Icon testId={`dropdown-alert-big-${testId}`} type="alert-big" size="mini" color={'ColorOrangeBurnt'} />
				<div className={styles.errorText} data-testid={`${testId}-message`}>
					{typeof errorMessage === 'string' ? `${errorMessage}` : errorMessage}
				</div>
			</div>
			{retryText && (
				<Button size="medium" type="secondary" text={retryText} onClick={onRetry} testId={testId + '-retry'} />
			)}
		</div>
	)
}
