import cx from 'classnames'
import { camelCase } from 'lodash-es'
import { CSSProperties, FC, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Icon } from '..'
import { ColorBorderFocus, ColorWhite } from '../shared/token-colors.json'
import { TabColorState, TabsColor } from './tabs'
import styles from './tabs.module.scss'

export interface TabProps {
	label?: string
	disabled?: boolean
	tabColor?: TabsColor
	onHover?(isHover: boolean): void
	/**
	 * Refers to the tabpanel element associated with the tab (tab-panel id).
	 */
	'aria-controls': string
	/**
	 * Elements can be given the attribute aria-label to provide an accessible name for situations when there is no visible label due to a chosen design approach
	 */
	'aria-label'?: string
	id: string
	selectable?: boolean
}

export interface withTabsParentProps extends TabProps {
	/**
	 * @ignore
	 * The parent tabs component is going to send all this props
	 */
	testId?: string
	isSelected?: boolean
	changeTab?(index): void
	index?: number
	scroll?: number
	spacingClass?: number
	type?: string
	onEditTab?(idx: number): void
	onDeleteTab?(idx: number): void
	tabsColor?: TabColorState
}

export const Tab: FC<PropsWithChildren<withTabsParentProps>> = (props: PropsWithChildren<withTabsParentProps>) => {
	const {
		label,
		disabled,
		tabColor,
		onHover,
		'aria-controls': ariaControls,
		'aria-label': ariaLabel,
		id,
		children,
		testId,
		isSelected,
		changeTab,
		index,
		scroll,
		spacingClass,
		type,
		onEditTab,
		onDeleteTab,
		tabsColor,
		selectable = true
	} = props as PropsWithChildren<withTabsParentProps>
	const tabEl = useRef<HTMLSpanElement>(null)
	const contentEl = useRef<HTMLDivElement>(null)
	const [focusedTab, setFocusedTab] = useState<boolean>(false)
	const [hoveredTab, setHoveredTab] = useState<boolean>(false)
	const activeDefaultTab = isSelected ? 'active' : ''
	const tabPropsColors: TabColorState = {
		defaultState: tabColor?.defaultColor,
		hoverState: tabColor?.hoverColor
	}
	const tabColors = tabColor ? tabPropsColors : tabsColor ? tabsColor : undefined
	const isTypePill: boolean = props.type === 'pill'
	const isTypeLink: boolean = props.type === 'link'

	useEffect(() => {
		if (isTypePill && isSelected && tabColors && contentEl && contentEl.current) {
			contentEl.current.style.backgroundColor = tabColors.defaultState
		}
	}, [isSelected])

	const setPersonalizedColor = (state: 'defaultState' | 'hoverState') => {
		if (tabColors && contentEl && contentEl.current) {
			if (isTypePill) {
				tabEl.current.style.backgroundColor = tabColors[state]
				tabEl.current.style.borderColor = tabColors[state]
				contentEl.current.style.backgroundColor = tabColors[state]
			} else {
				tabEl.current.style.backgroundColor = tabColors[state]
				if (!focusedTab) {
					tabEl.current.style.borderColor = tabColors[state]
				}
			}
		}
	}

	const activeTabStyle = (): CSSProperties | undefined => {
		if (tabColors) {
			if (isTypePill) {
				let backgroundColor = tabColors.defaultState
				let borderColor = tabColors.defaultState
				if (hoveredTab) {
					backgroundColor = tabColors.hoverState
					borderColor = tabColors.hoverState
				}
				if (focusedTab) {
					backgroundColor = tabColors.defaultState
					borderColor = tabColors.defaultState
				}
				return {
					backgroundColor,
					borderColor
				}
			} else {
				if (isSelected) {
					let backgroundColor = tabColors.defaultState
					let borderColor = tabColors.defaultState
					if (hoveredTab) {
						backgroundColor = tabColors.hoverState
						borderColor = tabColors.hoverState
					}
					if (focusedTab) {
						borderColor = ColorBorderFocus
					}
					return {
						backgroundColor,
						borderColor
					}
				}
			}
		} else {
			return undefined
		}
	}

	const handleOnClick = () => {
		if (!disabled && selectable) {
			changeTab(index)
		}
	}

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			changeTab(index)
		}
	}

	const handleOnMouseEnter = () => {
		setPersonalizedColor('hoverState')
		setHoveredTab(true)
		if (onHover) onHover(true)
	}

	const handleOnMouseLeave = () => {
		if (isSelected) {
			setPersonalizedColor('defaultState')
		} else {
			if (isTypePill && tabColors) {
				tabEl.current.style.borderColor = tabColors.defaultState
				if (contentEl.current) contentEl.current.style.backgroundColor = tabColors.defaultState
			} else {
				tabEl.current.removeAttribute('style')
			}
		}
		setHoveredTab(false)
		if (onHover) onHover(false)
	}

	const handleOnFocus = () => {
		if (isTypePill) {
			tabEl.current.style.borderColor = tabColors.defaultState
			if (contentEl.current) contentEl.current.style.backgroundColor = tabColors.defaultState
		} else if (tabColors) {
			tabEl.current.style.borderColor = ColorBorderFocus
		}
		setFocusedTab(true)
	}

	const handleOnBlur = () => {
		if (isSelected && tabColors) {
			tabEl.current.style.borderColor = tabColors.defaultState
		} else if (isTypePill && tabColors) {
			tabEl.current.style.borderColor = tabColors.defaultState
		} else {
			tabEl.current.style.borderColor = ''
		}
		setFocusedTab(false)
	}

	return (
		<span
			className={cx(
				styles.tabButton,
				styles[scroll],
				styles[spacingClass],
				styles[activeDefaultTab],
				styles[camelCase(`type-${type}`)],
				{ [styles.withChildren]: !!children },
				disabled ? styles.disabled : ''
			)}
			style={activeTabStyle()}
			tabIndex={disabled ? -1 : 0}
			ref={tabEl}
			onClick={handleOnClick}
			onKeyPress={handleKeyPress}
			onMouseEnter={handleOnMouseEnter}
			onMouseLeave={handleOnMouseLeave}
			data-testid={testId + '-tab-' + index}
			onFocus={handleOnFocus}
			onBlur={handleOnBlur}
			role="tab"
			aria-selected={isSelected}
			aria-controls={ariaControls}
			aria-label={ariaLabel}
			id={id}
		>
			{label && (
				<div
					className={cx(
						styles.tabContentContainer,
						{ [styles.pillTab]: isTypePill },
						{
							[styles.pillFocused]: isTypePill && (focusedTab || isSelected)
						},
						{ [styles.linkTabFocused]: isTypeLink && (focusedTab || isSelected) }
					)}
					ref={contentEl}
					data-testid={`tab-content-container-${testId}`}
				>
					<label
						className={isTypePill ? styles.tabTextPill : isTypeLink ? styles.tabTextLink : styles.tabText}
						aria-selected={isSelected}
						title={label}
						data-testid={`label-${testId}`}
					>
						{label}
					</label>
					{isSelected && onEditTab && (
						<div
							tabIndex={0}
							className={styles.pencilContainer}
							onClick={() => onEditTab(index)}
							onKeyPress={(event) => {
								if (event.key === 'Enter') onEditTab(index)
							}}
							data-testid={testId + '-tab-' + index + '-edit'}
						>
							<Icon testId={`tab-pencil-${testId}`} color={ColorWhite} type="pencil" size="mini" />
						</div>
					)}
					{isSelected && onDeleteTab && (
						<div
							tabIndex={0}
							className={styles.pencilContainer}
							onClick={() => onDeleteTab(index)}
							onKeyPress={(event) => {
								if (event.key === 'Enter') onDeleteTab(index)
							}}
							data-testid={testId + '-tab-' + index + '-delete'}
						>
							<Icon color={ColorWhite} type="trash" size="mini" />
						</div>
					)}
				</div>
			)}
			{children}
		</span>
	)
}

export default Tab
