import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { useApi } from '../hooks'
import { finalizeRecordsForUpload } from './api/finalizeRecordsForUpload'

export const useFinalizeRecordsForUpload = (): UseMutationResult<void, unknown, string, unknown> => {
	const apiClient = useApi()
	const queryClient = useQueryClient()

	const mutationFn = (uploadId: string) => finalizeRecordsForUpload(apiClient, uploadId)

	return useMutation(mutationFn, {
		onSuccess: (_data, variables) => {
			queryClient.invalidateQueries(['getUploads', variables])
		}
	})
}
