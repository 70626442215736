interface DynamicWizardContextState {
	contactMatchEntitled: boolean
	ready: boolean
}

export class DynamicWizardContext {
	public ready = false
	public contactMatchEntitled = false

	public constructor(contactMatchEntitled?: boolean, ready?: boolean) {
		if (contactMatchEntitled !== undefined) {
			this.contactMatchEntitled = contactMatchEntitled
			this.ready = ready !== undefined ? ready : true
		}
	}

	public set(newState: DynamicWizardContextState): void {
		this.contactMatchEntitled = newState.contactMatchEntitled
		this.ready = true
	}
}
