import { AxiosInstance } from 'axios'
import { ElementUIFacade } from '../../../../components/data-block-searcher/ElementUIFacade'
import { EnrichingBlock } from '../../../../store/projectWizard/types'
import { Element, ElementDetail } from '../../../../types'
import { getDefaultElementList, getEmailDefaultElements } from '../../../../utils'
import tradeUpElements from '../select-your-data/tradeup-elements.json'

const getDefaultElementsToAdd = (
	selectedElements: Array<string>,
	hasCRMSource: boolean,
	entityType: string | undefined,
	enableEmailVerification: boolean | undefined,
	apiClient: AxiosInstance,
	isRecommendationMatch: boolean
) => {
	//DCP-5594,5595,5598 concating default elements with contact default elements
	const defaultElementList = getDefaultElementList(
		entityType,
		enableEmailVerification,
		apiClient,
		isRecommendationMatch
	)
	const defaultElements: Array<EnrichingBlock> = hasCRMSource
		? [...defaultElementList.filter((block) => block.blockId !== 'entityresolution')]
		: [...defaultElementList]
	const elementsDefaultSelected: Array<string> = []
	defaultElements?.forEach((block) => {
		block?.elements?.forEach((element) => {
			if (!selectedElements.includes(element.elementId)) {
				elementsDefaultSelected.push(element.elementId)
			}
		})
	})
	return elementsDefaultSelected
}

export const getSelectedElements = (
	enrichingLayout: Array<EnrichingBlock>,
	hasCRMSource: boolean,
	entityType: string | undefined,
	enableEmailVerification: boolean | undefined,
	apiClient: AxiosInstance,
	isRecommendationMatch: boolean
): Array<string> => {
	let selectedElements: Array<string> = []

	enrichingLayout.forEach((enrichingBlock) => {
		enrichingBlock.elements?.forEach((element) => {
			selectedElements.push(element.elementId)
		})
	})
	if (enableEmailVerification === false) {
		const EVBlocksParsed = getEmailDefaultElements(apiClient)
		const EVIDS = EVBlocksParsed?.elements?.map(function (element: ElementUIFacade) {
			return element.elementId
		})
		selectedElements = selectedElements?.filter((el) => !EVIDS?.includes(el))
	}

	return selectedElements.concat(
		getDefaultElementsToAdd(
			selectedElements,
			hasCRMSource,
			entityType,
			enableEmailVerification,
			apiClient,
			isRecommendationMatch
		)
	)
}

export const getTradeUpElements = (dataBlockList: ElementDetail[]): ElementUIFacade[] => {
	const flattenTradeUp: ElementUIFacade[] = (tradeUpElements as EnrichingBlock[]).reduce(
		(elementsList: ElementUIFacade[], blockTradeUp: EnrichingBlock) => {
			let elementFull: Element
			const blockElements = blockTradeUp.elements.map((elementTradeUp: ElementUIFacade) => {
				dataBlockList.find((blockList: ElementDetail) => {
					if (blockList.blockId === blockTradeUp.blockId) {
						const levelList = blockList.levels.find(
							(levelDetail) => levelDetail.level.levelId === 'L' + elementTradeUp.level
						)
						elementFull = levelList?.elements.find(
							(elementL) => elementL.elementId === elementTradeUp.elementId
						) as Element
					}
				})
				return {
					...elementFull,
					...{
						blockId: blockTradeUp.blockId,
						replacements: elementTradeUp.replacements
					}
				}
			})
			return [...elementsList, ...blockElements]
		},
		[] as Array<ElementUIFacade>
	)
	return flattenTradeUp
}
