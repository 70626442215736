import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from '../../../../local-core-ui'
import styles from '../../refresh-rematch-now-confirmation-modal.module.scss'

interface RefreshMatchNowConfirmationModalProps {
	open: boolean
	onClose(): void
	testId: string
	showButtonClose?: boolean
	refresh?: boolean
}

const RefreshMatchNowConfirmationModal = ({
	open,
	onClose,
	testId,
	showButtonClose = true,
	refresh
}: RefreshMatchNowConfirmationModalProps): ReactElement => {
	const { t } = useTranslation()
	return (
		<div className={styles.confirmationModalSourceContainer} data-testid="confirmation-modal-source-container">
			<Modal
				open={open}
				isContainer={false}
				onClose={onClose}
				showButtonClose={showButtonClose}
				testId="confirmation-modal-source"
			>
				<div>
					<p className={styles.confirmationModalSourceDescription}>
						<strong>{t('source.refreshNow.disable.description')}</strong>
						{refresh
							? t('source.refreshNow.disable.description.complement')
							: t('source.rematchNow.disable.description.complement')}
					</p>
					<p className={styles.confirmationModalSourceDescription}>
						{refresh
							? t('source.refreshNow.disable.description.line2')
							: t('source.rematchNow.disable.description.line2')}
					</p>
				</div>

				<div className={styles.confirmationModalSourceButtonsContainer}>
					<Button
						type="secondary"
						text={t('source.refreshNow.disable.btn.disable')}
						onClick={onClose}
						testId={testId + '-cancel'}
					/>
				</div>
			</Modal>
		</div>
	)
}

export type { RefreshMatchNowConfirmationModalProps }
export { RefreshMatchNowConfirmationModal }
