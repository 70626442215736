import { useMutation, UseMutationResult } from 'react-query'
import { connectData } from '../components/connect-manage-modal/connect-manage-modal'
import { SnowflakeData } from '../components/snowflake-form/snowflake-form'
import { useApi } from '../hooks'
import { createConnection, SalesforceData } from './api/createConnection'

interface CreateConnectionParams {
	systemType: string
	connectionData: SnowflakeData | SalesforceData | connectData
}

interface CreateConnectionResponse {
	Success: boolean
	Errors?: string[]
}

export const useCreateConnection = (): UseMutationResult<
	CreateConnectionResponse,
	unknown,
	CreateConnectionParams,
	unknown
> => {
	const apiClient = useApi()

	const mutationFn = ({ systemType, connectionData }: CreateConnectionParams) =>
		createConnection(apiClient, systemType, connectionData)

	return useMutation(mutationFn)
}
