import { FC } from 'react'
import styles from './tooltip.module.scss'

export interface IContentRowProps {
	label: string
	value: string
	title?: string
	testId?: string
}

export const RowContent: FC<IContentRowProps> = ({ label, value, testId }: IContentRowProps) => {
	return (
		<tr data-testid={`tooltip-row-content-tr-${testId}`}>
			<td data-testid={`tooltip-td-left-${testId}`} className={styles.tdLeft}>
				{label}
			</td>
			<td data-testid={`tooltip-td-right-${testId}`} className={styles.tdRight}>
				{value}
			</td>
		</tr>
	)
}
