export function getDisplayNameForCrmSource(crmSource: string) {
	switch (crmSource) {
		case 'account':
			return 'Account'
		case 'contact':
			return 'Contact'
		case 'lead':
			return 'Lead'
		default:
			return ''
	}
}
