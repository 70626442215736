import { remove } from 'lodash-es'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { removeBaseInfoEnrichingBlock } from '../../helpers'
import { isTradeUpEnabled } from '../../helpers/validateTradeUp'
import { useApi } from '../../hooks'
import { useFeatures } from '../../hooks/useEntitlements'
import { Button, Modal } from '../../local-core-ui/'
import { EnrichmentPreviewer } from '../../project/steps/enriching/enrichmentPreviewer/enrichmentPreviewer'
import { useEnrichmentTemplateFacadeList } from '../../queries/useEnrichmentTemplateFacadeList'
import { useSource } from '../../queries/useSource'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import { updateCurrentProjectAction } from '../../store/projectWizard/actions'
import { EnrichingBlock } from '../../store/projectWizard/types'
import { Element, PurposeOfUse, UserDetail } from '../../types'
import { ElementUIFacade } from '../data-block-searcher/ElementUIFacade'
import { EnrichmentTemplateTile } from '../enrichment-template-tile/enrichment-template-tile'
import { ActiveFilters, TemplateTilePicker } from '../template-tile-picker/template-tile-picker'
import styles from './enrichment-template-picker.module.scss'

export interface EnrichmentTemplateFacade {
	name: string
	purposeOfUse: PurposeOfUse
	dataBlocks: Array<EnrichingBlock>
	notEntitledDataBlocks: Array<Element>
	createTime: number
	updateTime?: number
	createdBy: UserDetail
	templateId: string
	generateJson: boolean
	tradeUp?: 'domhq' | 'hq'
}

interface EnrichmentTemplatePickerProps {
	open: boolean
	onClose: () => void
	onSelect?: () => void
	onTemplatesClear: () => void
	testId: string
	purposeOfUse: PurposeOfUse
}

type ModalContentType = 'template_preview' | 'remove_template_confirmation' | 'template_removed' | 'template_library'

export const EnrichmentTemplatePicker = ({
	open,
	onClose,
	onSelect,
	onTemplatesClear,
	testId,
	purposeOfUse
}: EnrichmentTemplatePickerProps): ReactElement => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const [filteredTemplates, setFilteredTemplates] = useState<Array<EnrichmentTemplateFacade>>([])
	const [removeTemplateName, setRemoveTemplateName] = useState<string>()
	const [removeTemplateId, setRemoveTemplateId] = useState<string>()
	const [currentTemplateName, setCurrentTemplateName] = useState<string>()
	const [activeFilters, setActiveFilters] = useState<ActiveFilters>()
	const [previewTemplateId, setPreviewTemplateId] = useState<string>()
	const [isLoading, setIsLoading] = useState<boolean>()
	const [modalContent, setModalContent] = useState<ModalContentType>('template_library')
	const selectSession = (state: RootState) => state.session
	const sessionState = useAppSelector(selectSession)
	const currentUser = sessionState.user?.EmailAddress
	const apiClient = useApi()
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const sourceId = projectWizardState?.currentProject?.source?.id || ''
	const sourceQuery = useSource(sourceId, false)
	//TODO this has to be removed once the BE send user info in the enrichment library list api
	const enrichmentTemplateList = useEnrichmentTemplateFacadeList(
		projectWizardState.currentProject.purposeOfUse,
		projectWizardState.currentProject.source.entityType
	)
	const dnbOwnedId = 'Dun & Bradstreet'
	const showTradeUp = isTradeUpEnabled(
		useFeatures(['EnableTradeUp']),
		useFeatures(['EnableTradeUpC4S']),
		sourceQuery.data?.integrationInfo?.integrationType,
		projectWizardState?.currentProject?.source?.entityType,
		projectWizardState?.currentProject?.versionSF
	)

	const onRemoveTemplate = (templateId: string, templateName: string): void => {
		setModalContent('remove_template_confirmation')
		setRemoveTemplateId(templateId)
		setRemoveTemplateName(templateName)
	}

	const onRemoveTemplateConfirmation = () => {
		setIsLoading(true)
		const url = `/pls/enrichment-template/templateId/${removeTemplateId}`
		apiClient.delete(url).then(() => {
			if (enrichmentTemplateList.data) {
				const idx = enrichmentTemplateList.data.findIndex(
					(enrichmentTemplate) => enrichmentTemplate.templateId === removeTemplateId
				)
				if (idx > -1) {
					enrichmentTemplateList.data.splice(idx, 1)
					setModalContent('template_removed')
				}
			}
			setIsLoading(false)
		})
	}

	const onTemplateRemoved = () => {
		const selectedDomain = projectWizardState.currentProject.purposeOfUse.domain
		const selectedRecordType = projectWizardState.currentProject.purposeOfUse.recordType
		const templatesExist =
			enrichmentTemplateList && enrichmentTemplateList.data && enrichmentTemplateList.data.length > 0
		const differentPurpose = !enrichmentTemplateList.data?.find(
			(template) =>
				template.purposeOfUse.domain === selectedDomain &&
				template.purposeOfUse.recordType === selectedRecordType
		)
		if (!templatesExist || (templatesExist && differentPurpose)) {
			onTemplatesClear && onTemplatesClear()
		}
	}

	const getElementsCount = (template: EnrichmentTemplateFacade) => {
		let elementCount = 3
		let counterEntityResolution = false
		const elementsOfTradeUp = 1
		const elementsDefaultEntityResolution = 9
		template.dataBlocks.forEach((dataBlock) => {
			if (dataBlock.blockId !== 'baseinfo') {
				elementCount += dataBlock.elements?.length
			} else if (template.tradeUp) {
				elementCount += elementsOfTradeUp
			}
			if (dataBlock.blockId === 'entityresolution') {
				counterEntityResolution = true
			}
		})
		if (!counterEntityResolution) {
			elementCount = elementCount + elementsDefaultEntityResolution
		}
		return elementCount
	}

	const getBlocksCount = (template: EnrichmentTemplateFacade) => {
		return template.dataBlocks.length
	}

	const getSelectedTemplate = (templateId: string) => {
		return filteredTemplates.find((filteredTemplate) => {
			return filteredTemplate.templateId === templateId
		})
	}

	const onChangeFilteredTemplates = (templateList: Array<EnrichmentTemplateFacade>, filters: ActiveFilters) => {
		//(aramirez) For now, D&B Owned templates need to be always on top (this may change once we have more than 1 D&B Owned enrichment template)
		const dnbOwnedTemplates: Array<EnrichmentTemplateFacade> = remove(
			templateList,
			(template) => template.createdBy.id != dnbOwnedId
		)
		templateList.push(...dnbOwnedTemplates)
		setFilteredTemplates(templateList)
		setActiveFilters(filters)
	}

	const getDataBlockSummary = (blocks: Array<EnrichingBlock>) => {
		const dataBlockSummary: string[] = []
		blocks.forEach((block) => {
			const maxLevel = block.elements.reduce((p: number, v: ElementUIFacade): number => {
				const level = parseInt(v?.level ? v.level : '1')
				return p > level ? p : level
			}, 0)
			dataBlockSummary.push(`${t(block.blockId)} ${t('data.block.element.selector.level')} ${maxLevel}`)
		})
		return dataBlockSummary
	}

	const onApplyTemplate = (templateId: string) => {
		const selectedElement = getSelectedTemplate(templateId)
		if (selectedElement) {
			dispatch(
				updateCurrentProjectAction({
					currentTemplate: {
						templateId: selectedElement.templateId,
						templateName: selectedElement.name,
						createdBy: selectedElement.createdBy,
						generateJson: selectedElement.generateJson,
						tradeUp: selectedElement.tradeUp
					},
					enrichingLayout: removeBaseInfoEnrichingBlock(
						selectedElement.dataBlocks,
						showTradeUp && selectedElement.tradeUp !== undefined
					),
					tradeUp: !!selectedElement.tradeUp,
					entityTradeUp: selectedElement.tradeUp ? selectedElement.tradeUp : 'hq',
					generateJson: selectedElement.generateJson
				})
			)
			if (onSelect) onSelect()
		}
	}

	const onPreviewTemplate = (templateId: string) => {
		setPreviewTemplateId(templateId)
		const selectedElement = getSelectedTemplate(templateId)
		if (selectedElement) {
			dispatch(
				updateCurrentProjectAction({
					enrichingLayout: removeBaseInfoEnrichingBlock(
						selectedElement.dataBlocks,
						showTradeUp && selectedElement.tradeUp !== undefined
					),
					generateJson: selectedElement.generateJson,
					tradeUp: selectedElement.tradeUp !== undefined,
					entityTradeUp: selectedElement.tradeUp
				})
			)
		}
		setCurrentTemplateName(selectedElement?.name)
		setModalContent('template_preview')
	}

	const getModalContent = () => {
		switch (modalContent) {
			case 'template_preview':
				return (
					<EnrichmentPreviewer
						readonly
						enrichmentTitle={currentTemplateName}
						onClickBackToLibrary={() => {
							dispatch(
								updateCurrentProjectAction({
									enrichingLayout: removeBaseInfoEnrichingBlock([])
								})
							)
							setModalContent('template_library')
						}}
					/>
				)
			case 'remove_template_confirmation':
				return (
					<div className={styles.removeTemplateModal}>
						<div className={styles.text}>{t('enrichment.remove.template')}</div>
						<div className={styles.buttonContainer}>
							<Button
								onClick={onRemoveTemplateConfirmation}
								text={t('enrichment.remove.template.button.remove')}
								testId={'confirm-remove-button'}
							/>
							<Button
								onClick={() => {
									setModalContent('template_library')
								}}
								text={t('confirmation.modal.cancel')}
								testId={'cancel-button'}
								type={'secondary'}
							/>
						</div>
					</div>
				)
			case 'template_removed':
				return (
					<div className={styles.successRemovedTemplateModal}>
						<div className={styles.text}>
							{t('enrichment.removed.template.back', { templateName: removeTemplateName })}
						</div>
						<div className={styles.buttonContainer}>
							<Button
								onClick={() => {
									setModalContent('template_library')
									onTemplateRemoved()
								}}
								text={t('enrichment.removed.template.button.back')}
								testId={'deleted'}
							/>
						</div>
					</div>
				)
			case 'template_library':
				return (
					<TemplateTilePicker
						listTemplates={enrichmentTemplateList.data}
						testId={testId + '-enrichment-template-tile-picker'}
						title={t('enriching.template.picker.title')}
						purposeOfUse={purposeOfUse}
						onChangeFilteredTemplates={onChangeFilteredTemplates}
						filters={activeFilters}
						isLoading={isLoading}
						sortByPurposeOfUse={true}
					>
						{filteredTemplates.map((template, idx) => {
							const isDifferentPurpose =
								template.purposeOfUse.domain !== purposeOfUse.domain ||
								template.purposeOfUse.recordType !== purposeOfUse.recordType
							return (
								<div key={'enrichment-template-tile-' + idx}>
									<EnrichmentTemplateTile
										name={template.name}
										purposeOfUse={{
											domain: template.purposeOfUse.domain,
											recordType: template.purposeOfUse.recordType
										}}
										createdBy={template.createdBy}
										date={template.updateTime || template.createTime}
										numberOfElements={getElementsCount(template)}
										numberOfDataBlocks={getBlocksCount(template)}
										disabled={isDifferentPurpose && template.purposeOfUse.domain !== null}
										functionApplyTemplate={(templateId) => onApplyTemplate(templateId)}
										functionPreviewTemplate={(templateId) => onPreviewTemplate(templateId)}
										functionRemoveTemplate={onRemoveTemplate}
										isOwnerTemplate={
											template.createdBy.Email === currentUser ||
											template.createdBy.id === currentUser
										}
										testId={`enrichment-template-tile-${idx}`}
										templateId={template.templateId}
										flipped={template.templateId === previewTemplateId}
										dataBlockDetails={getDataBlockSummary(template.dataBlocks)}
									/>
								</div>
							)
						})}
					</TemplateTilePicker>
				)
			default:
				break
		}
	}

	return (
		<div
			className={styles.enrichmentTemplatePickerModalContainer}
			data-testid="enrichment-template-picker-modal-container"
		>
			<Modal
				open={open}
				isContainer={true}
				onClose={onClose}
				showButtonClose={modalContent === 'template_preview' || modalContent === 'template_library'}
				testId="EnrichmentTemplatePickerModal"
			>
				{getModalContent()}
			</Modal>
		</div>
	)
}
