import { useTranslation } from 'react-i18next'
import { ColDef, ReportTable, RowDef } from '../../../../../components/report-table/report-table'
import { ReportTile } from '../../../../../components/report-tile/report-tile'
import { DataReport } from '../../../../../types'
import { ReportPage } from '../../../report'
import { getGeoTableData } from '../../utils/getGeoTableData'
import styles from '../appendix.module.scss'

interface FullGeographicalDistributionProps {
	geoDistributionReport: DataReport['geoDistributionReport'] | null
	firstAppendix?: boolean
}

const rowHeight = '3.6rem'
const FullGeographicalDistribution = ({
	geoDistributionReport,
	firstAppendix = false
}: FullGeographicalDistributionProps) => {
	const { t } = useTranslation()
	const geoDistributionList = geoDistributionReport ? [...geoDistributionReport.geoDistributionList] : []
	const MAX_ELEMENTS_PER_PAGE = 38
	const ELEMENTS_OFFSET = 2

	const tableColumns: ColDef[] = [
		{
			field: 'country',
			label: t('report.page.appendix.tile.fullGeographicalDistribution.table.column1') as string
		},
		{
			field: 'records',
			label: t('report.page.appendix.tile.fullGeographicalDistribution.table.column2') as string
		},
		{
			field: 'percentage',
			label: t('report.page.appendix.tile.fullGeographicalDistribution.table.column3') as string
		}
	]

	const geoData = getGeoTableData(geoDistributionList)
	const segmentedGeoData: RowDef[][] = []

	let segmentSize = firstAppendix ? MAX_ELEMENTS_PER_PAGE - ELEMENTS_OFFSET : MAX_ELEMENTS_PER_PAGE

	for (let i = 0; i < geoData.length; i += segmentSize) {
		if (segmentedGeoData.length > 0) {
			segmentSize = MAX_ELEMENTS_PER_PAGE
		}

		if (firstAppendix && i === MAX_ELEMENTS_PER_PAGE) {
			i = MAX_ELEMENTS_PER_PAGE - ELEMENTS_OFFSET
		}

		segmentedGeoData.push(geoData.slice(i, i + segmentSize))
	}

	return segmentedGeoData.map((segment, idx) => {
		return (
			<ReportPage key={`full-geographical-distribution-table-${idx + 1}`}>
				<div className={styles.appendixPage}>
					{firstAppendix && idx === 0 ? (
						<span className={styles.header}>{t('report.page.appendix.header')}</span>
					) : null}
					<ReportTile
						title={t('report.page.appendix.tile.fullGeographicalDistribution.title') as string}
						subtitle={t('report.page.appendix.tile.fullGeographicalDistribution.subitle') as string}
						pageIndicator={`${idx + 1}/${segmentedGeoData.length}`}
					>
						<ReportTable columns={tableColumns} rows={segment} rowHeight={rowHeight} zebraStripes="even" />
					</ReportTile>
				</div>
			</ReportPage>
		)
	})
}

export { FullGeographicalDistribution }
