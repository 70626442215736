import { ReactElement } from 'react'
import styles from './company-detail-tile.module.scss'

export interface CommittedStatus {
	committed?: boolean
}

export const Accepted = ({ committed = false }: CommittedStatus): ReactElement => {
	return <span className={`${styles.recordAccepted} ${committed ? styles.recordCommitted : ''}`}>A</span>
}

export const Rejected = ({ committed = false }: CommittedStatus): ReactElement => {
	return <span className={`${styles.recordRejected} ${committed ? styles.recordCommitted : ''}`}>R</span>
}

export const NeedsReview = (): ReactElement => {
	return <span className={styles.recordNeedsReview} />
}
