import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from '../../local-core-ui'
import { RefreshMatchNowConfirmationModalProps } from '../refresh-rematch-now-confirmation-modal/components/RefreshMatchNowConfirmationModal/refreshMatchNowConfirmationModal'
import styles from '../refresh-rematch-now-confirmation-modal/refresh-rematch-now-confirmation-modal.module.scss'

const RefreshErrorModal = ({ open, onClose, testId }: RefreshMatchNowConfirmationModalProps): ReactElement => {
	const { t } = useTranslation()
	return (
		<div className={styles.confirmationModalSourceContainer} data-testid="confirmation-modal-source-container">
			<Modal
				open={open}
				isContainer={false}
				onClose={onClose}
				showButtonClose={true}
				testId="confirmation-modal-source"
			>
				<p className={styles.confirmationModalSourceDescription}>{t('source.refreshRematchNow.error')}</p>

				<div className={styles.confirmationModalSourceButtonsContainer}>
					<Button
						type="secondary"
						text={t('source.refreshNow.disable.btn.disable')}
						onClick={onClose}
						testId={testId + '-cancel'}
					/>
				</div>
			</Modal>
		</div>
	)
}

export { RefreshErrorModal }
