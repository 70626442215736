import { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import iconCheck from '../../assets/images/icon-green-check.svg'
import { useFeatures } from '../../hooks/useEntitlements'
import { Icon } from '../../local-core-ui'
import ToggleSwitchAssignment from '../../local-core-ui/toggle-switch-assignment/toggle-switch-assignment'
import { useSetSourceRefreshRematch } from '../../queries/useSetSourceRefreshRematch'
import { useSource } from '../../queries/useSource'
import { useUploads } from '../../queries/useUploads'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import { updateCurrentProjectAction } from '../../store/projectWizard/actions'
import { RefreshRematchConfig } from '../../types'
import { EntityType } from '../../types/sources/EntityType'
import { ConfirmationModalSourceToogleOff } from '../confirmation-modal-source-toggle-off/confirmation-modal-source-toggle-off'
import styles from './source-refresh-rematch.module.scss'
import { timestampFormatter } from './timestampFormatter'

interface SourceRefreshRematchProps {
	entityType: string
	sourceId: string
	readOnly: boolean
	wizardMode: boolean
}

const refreshRematchDefault = {
	refresh: false,
	rematch: false,
	enabled: true,
	nextUpdate: '',
	refreshStatus: '',
	matchStatus: ''
}

export const SourceRefreshRematch: FC<SourceRefreshRematchProps> = ({
	entityType,
	sourceId,
	readOnly,
	wizardMode
}: SourceRefreshRematchProps) => {
	const setRefreshRematchMutation = useSetSourceRefreshRematch(sourceId)
	const sourceQuery = useSource(sourceId, true)
	const { t } = useTranslation()
	const [refreshRematchConfig, setRefreshRematchConfig] = useState<RefreshRematchConfig>(refreshRematchDefault)
	const [refreshOrRematchActive, setRefreshOrRematchActive] = useState<boolean>(false)
	const [nextUpdate, setNextUpdate] = useState<string>('')
	const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
	const [refreshRunning, setRefreshRunning] = useState<boolean>(false)
	const [rematchRunning, setRematchRunning] = useState<boolean>(false)
	const uploadsQuery = useUploads(sourceId)
	const projectWizardSelector = (state: RootState) => state.projectWizard
	const projectWizard = useAppSelector(projectWizardSelector)
	const dispatch = useAppDispatch()
	const refreshRematchDefaultFlag = useFeatures(['RefreshRematchDefaultValue'])

	const changeRefreshRematchConfig = (config: RefreshRematchConfig) => {
		setRefreshRematchConfig(config)
		if (wizardMode) {
			const currentIntegrationInfo = projectWizard.currentProject.source.integrationInfo
			dispatch(
				updateCurrentProjectAction({
					source: {
						integrationInfo: {
							...currentIntegrationInfo,
							refreshRematchConfig: config
						}
					}
				})
			)
		} else setRefreshRematchMutation.mutate(config)
	}

	const handleChangeRefresh = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			changeRefreshRematchConfig({ ...refreshRematchConfig, refresh: event.target.checked })
		} else {
			checkRefreshRunning()
		}
	}

	const handleChangeRematch = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			changeRefreshRematchConfig({ ...refreshRematchConfig, rematch: event.target.checked })
		} else {
			checkRematchRunning()
		}
	}

	function checkRefreshRunning(): void {
		if (sourceQuery.data?.integrationInfo?.refreshInfo) {
			const refreshStatusResponse: string[] = ['Completed', 'Failed']
			const refreshStatus: string = sourceQuery.data.integrationInfo?.refreshInfo?.refreshStatus
			if (refreshStatusResponse.includes(refreshStatus)) {
				disableToggleRefresh()
			} else {
				setRefreshRunning(true)
				setShowConfirmationModal(true)
			}
		} else {
			disableToggleRefresh()
		}
	}

	function checkRematchRunning(): void {
		const problematicFilesStatuses: string[] = [
			'NEW',
			'IMPORT_STARTED',
			'IMPORT_FINISHED',
			'MATCH_STARTED',
			'MATCH_FINISHED'
		]
		if (uploadsQuery.data) {
			const response: string[] = uploadsQuery.data.map((element) => element.status)
			const found: boolean = problematicFilesStatuses.some((r) => response.includes(r))
			if (found) {
				setRematchRunning(true)
				setShowConfirmationModal(true)
			} else {
				disableToggleRematch()
			}
		} else {
			disableToggleRematch()
		}
	}

	const disableToggleRefresh = () => {
		changeRefreshRematchConfig({ ...refreshRematchConfig, refresh: false })
	}
	const disableToggleRematch = () => {
		changeRefreshRematchConfig({ ...refreshRematchConfig, rematch: false })
	}
	const disableRefreshRematchFromModal = () => {
		let config = { ...refreshRematchConfig }
		if (refreshRunning) config = { ...refreshRematchConfig, refresh: false }
		if (rematchRunning) config = { ...refreshRematchConfig, rematch: false }
		changeRefreshRematchConfig(config)
		setShowConfirmationModal(false)
	}

	useEffect(() => {
		if (!wizardMode && sourceQuery.isSuccess && !sourceQuery.isFetching) {
			const refreshRematch: RefreshRematchConfig =
				sourceQuery.data.integrationInfo?.refreshRematchConfig || refreshRematchDefault
			setRefreshRematchConfig({ ...refreshRematch, enabled: true })
			setRefreshOrRematchActive(refreshRematch.refresh || refreshRematch.rematch || false)
			if (sourceQuery.data.integrationInfo?.refreshRematchConfig.nextUpdate) {
				setNextUpdate(timestampFormatter(sourceQuery.data.integrationInfo?.refreshRematchConfig.nextUpdate))
			}
		}
		/**
		 * We only want to run this effect when the flag isFetching from sourceQuery or wizardMode prop changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceQuery.isFetching, wizardMode])

	useEffect(() => {
		if (wizardMode) {
			if (refreshRematchDefaultFlag !== undefined) {
				setRefreshRematchConfig({
					...{
						refresh: refreshRematchDefaultFlag,
						rematch: refreshRematchDefaultFlag
					},
					enabled: true
				})
				setRefreshOrRematchActive(refreshRematchDefaultFlag || false)
			} else if (projectWizard.currentProject.source.integrationInfo?.refreshRematchConfig) {
				setRefreshRematchConfig({
					...projectWizard.currentProject.source.integrationInfo?.refreshRematchConfig,
					enabled: true
				})
				setRefreshOrRematchActive(
					projectWizard.currentProject.source.integrationInfo?.refreshRematchConfig.refresh ||
						projectWizard.currentProject.source.integrationInfo?.refreshRematchConfig.rematch ||
						false
				)
			}
		}
		/**
		 * We only want to run this effect when wizardMode prop changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [wizardMode])

	return (
		<div>
			{readOnly ? (
				<div className={styles.rowTransactional}>
					<div className={styles.refreshSettingsProjectDetails}>
						<div className={styles.sourceSettingsProjectDetails}>
							<div className={styles.refreshLabelProjectDetails}>{t('source.update14switch')}</div>
							<div className={styles.checkContainerProjectDetails}>
								{refreshRematchConfig.refresh ? (
									<>
										<img
											className={styles.checkIconProjectDetails}
											src={iconCheck}
											alt="refresh-true"
										/>
										<div className={styles.rematchLabelProjectDetails}>
											{t('source.refresh.match.records')}
										</div>
									</>
								) : (
									<>
										<Icon
											testId="denied-source-refresh"
											type={'denied'}
											color={'ColorJetBlack'}
											size={'mini'}
										/>
										<span className={styles.disableLabelProjectDetails}>
											{t('source.refresh.match.records')}
										</span>
									</>
								)}
							</div>
							<div className={styles.checkContainerProjectDetails}>
								{refreshRematchConfig.rematch ? (
									<>
										{entityType === EntityType.CONTACTS && (
											<>
												<Icon
													testId="denied-source-rematch"
													type={'denied'}
													color={'ColorJetBlack'}
													size={'mini'}
												/>
												<span className={styles.disableLabelProjectDetails}>
													{t('source.rematch.unmatch.records.disabled2')}
												</span>
											</>
										)}
										{entityType !== EntityType.CONTACTS && (
											<>
												<img
													className={styles.checkIconProjectDetails}
													src={iconCheck}
													alt="rematch-true"
												/>
												<div className={styles.rematchLabelProjectDetails}>
													{t('source.rematch.unmatch.records')}
												</div>
											</>
										)}
									</>
								) : (
									<>
										{entityType === EntityType.CONTACTS && (
											<>
												<Icon
													testId="denied-source-rematch"
													type={'denied'}
													color={'ColorJetBlack'}
													size={'mini'}
												/>
												<span className={styles.disableLabelProjectDetails}>
													{t('source.rematch.unmatch.records.disabled2')}
												</span>
											</>
										)}
										{entityType !== EntityType.CONTACTS && (
											<>
												<Icon
													testId="denied-source-rematch"
													type={'denied'}
													color={'ColorJetBlack'}
													size={'mini'}
												/>
												<span className={styles.disableLabelProjectDetails}>
													{t('source.rematch.unmatch.records')}
												</span>
											</>
										)}
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			) : (
				<>
					<div className={styles.RefreshRetryTitle}>{t('source.update14switch')}</div>
					<div className={styles.refreshSettings}>
						<div className={styles.refreshToggle}>
							<ToggleSwitchAssignment
								id="refresh"
								label={t('source.refresh.match.records')}
								checked={refreshRematchConfig.refresh}
								onChange={handleChangeRefresh}
								testId="source-refresh-match"
							/>
						</div>
						{projectWizard.currentProject.source.entityType === EntityType.CONTACTS && (
							<div className={styles.refreshToggle}>
								<Icon
									testId="denied-source-refresh"
									type={'denied'}
									color={'ColorJetBlack'}
									size={'mini'}
								/>
								<span className={styles.refreshDisabled}>
									{t('source.rematch.unmatch.records.disabled2')}
								</span>
							</div>
						)}
						{projectWizard.currentProject.source.entityType !== EntityType.CONTACTS && (
							<ToggleSwitchAssignment
								id="rematch"
								label={t('source.rematch.unmatch.records')}
								checked={refreshRematchConfig.rematch}
								onChange={handleChangeRematch}
								testId="source-rematch-unmatch"
							/>
						)}
					</div>
					{refreshOrRematchActive && (
						<div>
							<div>
								<div className={styles.nextUpdateText}>
									{t('source.update.schedule', { nextUpdate: nextUpdate })}{' '}
								</div>
								<ConfirmationModalSourceToogleOff
									open={showConfirmationModal}
									onClose={() => setShowConfirmationModal(false)}
									onConfirmClick={() => {
										disableRefreshRematchFromModal()
									}}
									showButtonClose={false}
									testId={'confirmation-modal-source-toggle-off'}
								/>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	)
}
