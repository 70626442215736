import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from '../../local-core-ui'
import styles from './confirmation-modal.module.scss'

interface ConfirmationModalProps {
	open: boolean
	onClose(): void
	description: string
	confirmText: string
	onConfirmClick(): void
	testId: string
	showButtonClose?: boolean
}

export const ConfirmationModal = ({
	open,
	onClose,
	description,
	confirmText,
	onConfirmClick,
	testId,
	showButtonClose = true
}: ConfirmationModalProps): ReactElement => {
	const { t } = useTranslation()

	return (
		<div className={styles.confirmationModalContainer} data-testid="confirmation-modal-container">
			<Modal
				open={open}
				isContainer={false}
				onClose={onClose}
				showButtonClose={showButtonClose}
				testId="confirmation-modal"
			>
				<p className={styles.confirmationModalDescription}>{description}</p>
				<div className={styles.confirmationModalButtonsContainer}>
					<Button text={confirmText} onClick={onConfirmClick} testId={testId + '-confirm'} />
					<Button
						type="secondary"
						text={t('confirmation.modal.cancel')}
						onClick={onClose}
						testId={testId + '-cancel'}
					/>
				</div>
			</Modal>
		</div>
	)
}
