import { FC } from 'react'
import { Grid, PieChart as BasePieChart } from '../../local-core-ui'
import styles from './pie-chart.module.scss'

interface PieChartData {
	label: string
	value: number
	rate: number
}
interface PieChartProps {
	data: PieChartData[]
	testId: string
	chartColors: Record<string, string>
}
export const PieChart: FC<PieChartProps> = ({ data, chartColors, testId }: PieChartProps) => {
	const newPieData = [...data]
		.sort((a, b) => b.value - a.value)
		.reduce(
			(data, driver) => ({
				...data,
				[driver.label]: driver.value
			}),
			{}
		)

	const tooltipData = data.map((driver: PieChartData) => {
		return {
			label: driver.label,
			value: new Intl.NumberFormat().format(Math.round(driver.value))
		}
	})

	return (
		<div className={styles.mainPieChartContainer} data-testid={testId + 'pie-chart-custom'}>
			<Grid container>
				<Grid testId="drivers-pie-chart" md={7}>
					<div className={styles.pieChartContainer}>
						<BasePieChart
							data={newPieData}
							diameter={180}
							colors={chartColors}
							describeWith={'none'}
							showKeys={true}
							showStrokes={true}
							tooltipInfo={{
								type: 'bySlice',
								testId: testId + '-tooltipPie',
								values: tooltipData
							}}
							positionKeys={'right'}
						/>
					</div>
				</Grid>
			</Grid>
		</div>
	)
}
