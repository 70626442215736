import { AxiosResponse } from 'axios'
import { QueryClient } from 'react-query'
import { GetEnrichmentRulesResponseBySource } from '../../../types'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { updateCurrentProjectAction, updateLoadingNextStep } from '../actions'

export const createOrUpdateEnrichmentRules =
	(queryClient: QueryClient): AppThunk<Promise<void>> =>
	async (dispatch: TDispatch, getState: TGetState): Promise<void> => {
		const currentProject = getState().projectWizard.currentProject
		const apiClient = getApiClient4Thunks(dispatch)
		const sourceId = getState().projectWizard.currentProject.source?.id
		const url = `/pls/enrichment-rules`
		const enrichmentRules = currentProject.enrichmentRules
		const selectSession = getState().session.user?.EmailAddress

		const data = { sourceId: sourceId, createdBy: selectSession, rules: enrichmentRules }

		dispatch(updateLoadingNextStep(true))
		let apiCall
		const exists = await apiClient
			.get(`/pls/enrichment-rules/sourceId/${sourceId}`)
			.then(
				(response: AxiosResponse<GetEnrichmentRulesResponseBySource>) =>
					response.data?.rules && response.data.rules.length > 0
			)
		if (exists) {
			apiCall = apiClient.put(url, data)
		} else {
			apiCall = apiClient.post(url, data)
		}
		return apiCall
			.then(async () => {
				//TODO: Should we update Redux?
				queryClient.invalidateQueries(['getTemplateEnrichmentRules', sourceId])
				dispatch(updateCurrentProjectAction({ enrichmentRules: enrichmentRules }))
			})
			.catch((e) => {
				console.error(e)
				throw e
			})
			.finally(() => dispatch(updateLoadingNextStep(false)))
	}
