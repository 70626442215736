import cx from 'classnames'
import { FC } from 'react'
import styles from './flag.module.scss'
import * as flags from './flags'

interface IFlagProps {
	countryCode: string
	size?: 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge' | 'xxxLarge' | 'xxxxLarge'
	alt?: string
	testId?: string
}

export const Flag: FC<IFlagProps> = ({ countryCode, size = 'medium', alt = countryCode, testId }: IFlagProps) => {
	countryCode = countryCode?.toUpperCase()
	if (countryCode?.indexOf('-') >= 0) {
		const cc = countryCode.split('-')
		countryCode = cc[0] + cc[1].toLowerCase()
	}
	if (!flags[countryCode]) {
		countryCode = 'UNKNOWN'
	}

	return (
		<img
			data-testid={`flag-img-${testId}`}
			src={flags[countryCode]}
			alt={alt}
			className={cx(styles.flag, styles[size])}
		></img>
	)
}

export default Flag
