import { Team } from '../../../types'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { updateCurrentProjectAction } from '../actions'

export const getTeamInformation =
	(): AppThunk<Promise<void>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<void> => {
		const projectWizard = getState().projectWizard
		const projectDisplayName = projectWizard.currentProject?.name
		if (projectWizard.currentProject.id) {
			const apiClient = getApiClient4Thunks(dispatch)
			const url = `/pls/teams`
			return apiClient
				.get(url)
				.then(async (response) => {
					if (response.data) {
						const currentTeam = response.data.filter((team: Team) => {
							return team.TeamName === projectDisplayName
						})
						if (currentTeam.length) {
							dispatch(updateCurrentProjectAction({ team: currentTeam[0] }))
						}
					}
				})
				.catch((error) => {
					console.error(error)
				})
		} else {
			return new Promise<void>((resolve) => resolve())
		}
	}
