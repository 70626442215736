import {
	ColorBlueBrand,
	ColorBluePrimary,
	ColorSlateLight
} from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'
import { CheckBox, Divider, Dropdown, Icon, Tab, Tabs } from '../../local-core-ui'
import { useProjects } from '../../queries/useProjects'
import { RootState, useAppSelector } from '../../store'
import { Tenant } from '../../types'
import { ElementsPerPage } from '../elements-per-page/elements-per-page'
import { ExpandedSearchButtonNInput } from '../expanded-search-button-n-input/expanded-search-button-n-input'
import { findLocale } from '../language-selector/findLocale'
import styles from './project-filter-bar.module.scss'

export interface ProjectFilter {
	quickFilter: string
	status: 'active' | 'archived'
	createdByMe: boolean
	sortBy: 'name' | 'submitted' | 'matched' | 'unmatched' | 'createdon'
	sortByLabel: string
	sortAscending: boolean
}

interface ProjectSourceFilterBarProps {
	testId: string
	setRecordsPerPage(newNumberOfRecords: recordPerPage): void
	onChangeFilter?(filters: ProjectFilter): void
	initialFilters: ProjectFilter
	activeProjectsCount: number
	archivedProjectsCount: number
	presentedProjectsCount: number
}

export type recordPerPage = 20 | 50 | 100

export const ProjectFilterBar = ({
	testId,
	setRecordsPerPage,
	onChangeFilter,
	initialFilters,
	activeProjectsCount,
	archivedProjectsCount,
	presentedProjectsCount
}: ProjectSourceFilterBarProps): ReactElement => {
	const { t } = useTranslation()
	const [projectFilter, setProjectFilter] = useState<ProjectFilter>(initialFilters)
	const [sortState, setSortState] = useState<ProjectFilter['sortBy']>('createdon')
	const [quickFilterValue, setQuickFilterValue] = useState('')
	const [filterCreatedByMe, setFilterCreatedByMe] = useState(initialFilters.createdByMe)
	const [projectsPerPage, setProjectsPerPage] = useState<recordPerPage>(20)
	const [isQuickFilterOpen, setQuickFilterOpen] = useState<boolean>(false)
	const firstRender = useRef(true)
	const selectSession = (state: RootState) => state.session
	const sessionState = useAppSelector(selectSession)
	const tenant = sessionState.tenants.find((aTenant: Tenant) => aTenant.Identifier === sessionState.tenant)

	const query = useProjects()
	const projectCount = query.data?.length || t('dashboard.projects.title.projects.loading')
	const paginationOptions: recordPerPage[] = [20, 50, 100]
	const selectedLocale = findLocale(i18n.language)

	useEffect(() => {
		let updateProjectSort = projectFilter
		updateProjectSort = {
			...updateProjectSort,
			sortBy: sortState,
			sortByLabel: dropdownOptions.find(({ value }) => value === sortState)?.label
		}
		setProjectFilter(updateProjectSort)
	}, [selectedLocale])

	useEffect(() => {
		if (initialFilters) {
			setProjectFilter(initialFilters)
			setFilterCreatedByMe(initialFilters.createdByMe)
		}
	}, [initialFilters])

	const handleQuickFilter = (value: ProjectFilter['quickFilter']) => {
		setProjectFilter({ ...projectFilter, quickFilter: value })
		setQuickFilterValue(value)
	}

	const filterByStatus = (tabIndex: number) => {
		if (tabIndex === 0) setProjectFilter({ ...projectFilter, status: 'active' })
		if (tabIndex === 1) setProjectFilter({ ...projectFilter, status: 'archived' })
	}

	const handleProjectSort = (sort: ProjectFilter['sortBy']) => {
		setSortState(sort)
		let updateProjectSort = projectFilter
		updateProjectSort = {
			...updateProjectSort,
			sortByLabel: dropdownOptions.find(({ value }) => value === sort)?.label,
			sortBy: sort
		}
		setProjectFilter(updateProjectSort)
	}

	useEffect(() => {
		setProjectFilter({ ...projectFilter, createdByMe: filterCreatedByMe })
		// Execute only when the 'created by me' & 'created by others' checkboxes change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterCreatedByMe])

	useEffect(() => {
		setRecordsPerPage(projectsPerPage)
		// Set the record per page only when user changes the records to be shown
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectsPerPage])

	useEffect(() => {
		if (onChangeFilter && !firstRender.current) {
			onChangeFilter(projectFilter)
		} else {
			firstRender.current = false
		}
		// Execute only when projectFilter changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectFilter])

	const dropdownOptions = [
		{
			label: t('search.label.sortByName'),
			value: 'name'
		},
		{
			label: t('search.label.sortBySubmitted'),
			value: 'submitted'
		},
		{
			label: t('search.label.sortByMatched'),
			value: 'matched'
		},
		{
			label: t('search.label.sortByUnMatched'),
			value: 'unmatched'
		},
		{
			label: t('search.label.sortByCreatedOn'),
			value: 'createdon'
		}
	]
	return (
		<div className={styles.filtersContainer}>
			<div className={styles.expandedSearchNButtonInputWrapper}>
				<ExpandedSearchButtonNInput
					value={quickFilterValue}
					onChangeFilter={handleQuickFilter}
					onClick={() => setQuickFilterOpen(!isQuickFilterOpen)}
					collapsed={!isQuickFilterOpen}
					buttonPosition={'Right'}
					hint={t('dashboard.projects.input.hint')}
					showButtonAsIcon={false}
					testId={testId + '-extended-search-button-input'}
				/>
			</div>
			<div data-testid="tenant-name-total-projects" className={styles.companyCountWrapper}>{`${
				tenant?.CompanyName
			} ${t('dashboard.projects.title.projects', {
				numOfProjects: projectCount
			})}`}</div>
			<div className={styles.tabControlWrapper}>
				<Tabs
					colors={{
						defaultColor: ColorBluePrimary,
						hoverColor: ColorBlueBrand
					}}
					testId={testId + '-tab-control'}
					onChange={filterByStatus}
					value={projectFilter.status === 'active' ? 0 : 1}
				>
					<Tab
						label={t('dashboard.projects.tab.active', { numOfProjects: activeProjectsCount })}
						id="tabActive"
						aria-controls="projectListContainer"
					/>
					<Tab
						label={t('dashboard.projects.tab.archived', { numOfProjects: archivedProjectsCount })}
						id="tabArchived"
						aria-controls="projectListContainer"
					/>
				</Tabs>
				<Divider color={ColorSlateLight} size={'medium'} />
			</div>
			<div className={styles.paginationNCheckboxContainer}>
				<div className={styles.elementsPerPageWrapper}>
					<ElementsPerPage
						paginationOptions={paginationOptions}
						selectedElement={projectsPerPage}
						onElementsPerPageChanged={(selectedElement: recordPerPage) =>
							setProjectsPerPage(selectedElement)
						}
						totalElementsCount={presentedProjectsCount}
						textLeft={t('dashboard.projects.label.show')}
					/>
				</div>
				<div className={styles.checkboxWrapper}>
					<CheckBox
						id="checkbox-filter-created-by-me"
						label={t('dashboard.projects.checkbox.created-by-me')}
						onChange={() => setFilterCreatedByMe(!filterCreatedByMe)}
						checked={filterCreatedByMe}
						testId={testId + '-checkbox-1'}
					/>
				</div>
			</div>
			<div className={styles.sortDropdownNButtonContainer}>
				<div className="button-search">
					<div
						className={styles.iconSearch}
						onClick={() =>
							setProjectFilter({ ...projectFilter, sortAscending: !projectFilter.sortAscending })
						}
						data-testid={testId + 'sort-icon'}
					>
						<Icon
							testId="arrows-project-filter-bar"
							type={projectFilter.sortAscending ? 'arrow-sort-down' : 'arrow-sort-up'}
							size={'small'}
						/>
					</div>
				</div>
				<Dropdown
					id={'sort-projects'}
					label={''}
					hint={t('search.label.sortBy')}
					onChangeFunction={handleProjectSort}
					options={dropdownOptions}
					selected={dropdownOptions.find(({ label }) => label === projectFilter.sortByLabel)?.value}
					testId={testId + '-dropdown-sort'}
				/>
			</div>
		</div>
	)
}
