import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'

import { Block, PurposeOfUse } from '../types'
import { EntityType } from '../types/sources/EntityType'
import { getDataBlocksEntitlements } from './api/getDataBlocksEntitlements'

export const useDataBlocksEntitlementsByPurposeOfUse = (
	purposeOfUse: PurposeOfUse,
	entityType?: EntityType,
	includeElements = false
): UseQueryResult<Array<Block>, unknown> => {
	const apiClient = useApi()

	const queryKey: Array<unknown> = purposeOfUse
		? ['getDataBlocksEntitlements', purposeOfUse]
		: ['getDataBlocksEntitlements']
	if (entityType) queryKey.push(entityType)
	queryKey.push(includeElements)
	// TODO: add key to query since the cache will take care of everything, no need to bring everything and then filter,
	// or better yet, actually use the cache and send everything there and then filter it
	const queryFunction = () =>
		getDataBlocksEntitlements(apiClient, purposeOfUse, entityType, includeElements)
			.then((domains) => domains.find((domain) => domain.domain === purposeOfUse.domain))
			.then((domain) => domain?.recordTypes[purposeOfUse.recordType])
	return useQuery(queryKey, queryFunction)
}
