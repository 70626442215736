import cx from 'classnames'
import { useEffect, useRef, useState } from 'react'
import styles from './box-name.module.scss'

export enum typeBox {
	normal,
	deprecated
}
interface BoxNameProps {
	text: string
	type?: typeBox
	testId: string
}
export const BoxName = ({ text, type = typeBox.normal, testId }: BoxNameProps) => {
	const containerRef = useRef<HTMLDivElement>(null)
	const textRef = useRef<HTMLSpanElement>(null)
	const [isSlideable, setIsSlideable] = useState(false)
	const checkTextWidth = () => {
		if (containerRef.current && textRef.current) {
			const paddingSidesButton = 68
			const buttonWidth = containerRef.current.offsetWidth - paddingSidesButton
			const textWidth = textRef.current.scrollWidth
			setIsSlideable(textWidth > buttonWidth)
		}
	}

	useEffect(() => {
		checkTextWidth()
		window.addEventListener('resize', checkTextWidth)
		return () => window.removeEventListener('resize', checkTextWidth)
	}, [])

	return (
		<>
			<div ref={containerRef} id={testId}>
				<span
					className={cx(styles.contName, styles.typeButton, {
						[styles.deletedItem]: type === typeBox.deprecated
					})}
				>
					<span className={cx(styles.text, { [styles.slide]: isSlideable })} ref={textRef}>
						{text}
					</span>
				</span>
			</div>
		</>
	)
}
