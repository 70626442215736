import { Fragment, ReactElement } from 'react'
import { ApiErrorState } from '../../store/apiError'
import styles from './error-modal.module.scss'

interface DevSiteDataProps {
	apiError: ApiErrorState
}

export const DevSiteData = ({ apiError }: DevSiteDataProps): ReactElement => {
	return (
		<Fragment>
			{apiError?.previousURL && <p className={styles.errorMessageModalErrorMessage}>{apiError?.previousURL}</p>}
			{apiError?.requestURL && <p className={styles.errorMessageModalErrorMessage}>{apiError?.requestURL}</p>}
			{(apiError?.requestPayload || apiError?.response) && (
				<div className={styles.errorMessageModalPayloadResponseContainer}>
					{apiError?.requestPayload && (
						<p className={styles.errorMessageModalErrorMessage}>{apiError?.requestPayload}</p>
					)}
					{apiError?.response && (
						<p className={styles.errorMessageModalErrorMessage}>
							{typeof apiError?.response === 'object'
								? JSON.stringify(apiError?.response)
								: apiError?.response}
						</p>
					)}
				</div>
			)}
		</Fragment>
	)
}
