import { AxiosInstance, AxiosResponse } from 'axios'
import { GetEnrichmentRulesResponse } from '../../types'

export const getTemplateEnrichmentRules = async (
	apiClient: AxiosInstance,
	sourceId: string
): Promise<GetEnrichmentRulesResponse> => {
	const url = `/pls/enrichment-rules/sourceId/${sourceId}/template`

	return apiClient.get(url).then((response: AxiosResponse<GetEnrichmentRulesResponse>) => response.data)
}
