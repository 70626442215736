import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AlphabeticalFilter } from '../../../../components/alphabetical-filter/alphabetical-filter'
import { sbniType, SearchButtonNInput } from '../../../../components/search-button-n-input/search-button-n-input'
import { UserTile } from '../../../../components/user-tile/user-tile'
import { Button, Icon, TileCollection } from '../../../../local-core-ui'
import { UserDetail } from '../../../../types'
import styles from './user-selection.module.scss'

export interface IUserSelectionProps {
	userDirectory: Array<UserDetail>
	selectedUsers?: Array<UserDetail>
	unmovableUsers?: Array<UserDetail>

	onDone(userList: Array<UserDetail>): void
}

export const UserSelection = ({
	userDirectory,
	selectedUsers,
	unmovableUsers,
	onDone
}: IUserSelectionProps): ReactElement => {
	const { t } = useTranslation()

	const [inputValue, setInputValue] = useState('')
	const [usersToShow, setUsersToShow] = useState<Array<UserDetail>>([])
	const [selectedUserList, setSelectedUserList] = useState<Array<string>>([])
	const [unmovableUserEmailList, setUnmovableUserEmailList] = useState<Array<string>>([])
	const [filter, setFilter] = useState<{ type: string; value: string }>({ type: '', value: '' })
	const [currentLetter, setCurrentLetter] = useState('')

	const availableUsers = userDirectory

	useEffect(() => {
		const userList: string[] = []
		if (selectedUsers && selectedUserList.length === 0) {
			selectedUsers.forEach((userDetail) => {
				userList.push(userDetail.Email)
			})
			setSelectedUserList(userList)
		}
		// We only need to make this api call when selectedUsers is updated
		// If we add selectedUserList react will loop infinite times
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUsers])

	useEffect(() => {
		const userList: string[] = []
		if (unmovableUsers) {
			unmovableUsers.forEach((userDetail) => {
				userList.push(userDetail.Email)
			})
		}
		setUnmovableUserEmailList(userList)
	}, [unmovableUsers])

	useEffect(() => {
		let filteredUsers: UserDetail[] = []
		if (!filter.value) {
			availableUsers.sort((a, b) =>
				a.FirstName > b.FirstName
					? 1
					: a.FirstName === null || a.LastName === null || b.FirstName > a.FirstName
					? -1
					: 0
			)
			setUsersToShow(availableUsers)
		} else if (filter.type && filter.value) {
			if (filter.type === 'alphabetical-filter') {
				setInputValue('')
				filteredUsers = availableUsers.filter((user) => {
					const noName = user.FirstName === null || user.LastName === null
					return noName ? user : user.FirstName.toLowerCase().startsWith(filter.value.toLowerCase())
				})
				setUsersToShow(filteredUsers)
			} else if (filter.type === 'search-input') {
				filteredUsers = availableUsers.filter((user) => {
					const userName =
						user.FirstName === null || user.LastName === null
							? t('user.name.noneProvided').toLowerCase().trim()
							: `${user.FirstName} ${user.LastName}`.toLowerCase().trim()
					return (
						userName.includes(filter.value.toLowerCase().trim()) ||
						user.Email.includes(filter.value.toLowerCase().trim())
					)
				})
				setUsersToShow(filteredUsers)
			}
		}
	}, [availableUsers, filter])

	const isUserAlreadyInList = (list: string[], userEmail: string): boolean => {
		const userExist = list.find((user: string) => {
			return user === userEmail
		})
		return !!userExist
	}

	const addToTeam = (userEmail: string) => {
		if (!isUserAlreadyInList(selectedUserList, userEmail)) {
			setSelectedUserList([...selectedUserList, userEmail])
		}
	}

	const removeFromTeam = (userEmail: string) => {
		const removedUserIndex = selectedUserList.findIndex((user) => {
			return user === userEmail
		})
		const list = [...selectedUserList]
		list.splice(removedUserIndex, 1)
		setSelectedUserList(list)
	}

	const availableAlphabetLetters = availableUsers.map((user: UserDetail) => {
		return user.FirstName || t('user.name.noneProvided')
	})

	const handleOnDoneClick = () => {
		const selectedUserDetailList: UserDetail[] = []
		selectedUserList.forEach((userEmail) => {
			const user = userDirectory.find((userObject) => {
				return userObject.Email === userEmail
			})
			if (user) selectedUserDetailList.push(user)
		})
		onDone(selectedUserDetailList)
	}

	return (
		<div data-testid="add-member-container" className={styles.addMemberContainer}>
			<div className={styles.filterSectionContainer}>
				<div className={styles.searchInputWrapper}>
					<SearchButtonNInput
						value={inputValue}
						onChangeFilter={(value) => {
							setInputValue(value)
							setFilter({ type: 'search-input', value: value })
							setCurrentLetter('')
						}}
						hint={t('search.hint.users')}
						textButton={t('add.member.button.text') as string}
						type={sbniType.input}
						testId="team-search-input"
						showButtonAsIcon={true}
					/>
				</div>
				<div className={styles.alphabeticalFilterWrapper}>
					<AlphabeticalFilter
						onSelection={(letter) => {
							setCurrentLetter(letter)
							setFilter({ type: 'alphabetical-filter', value: letter })
						}}
						testId={'alphabetical-filter-search'}
						itemsToFilter={availableAlphabetLetters}
						selectedLetter={currentLetter}
					/>
				</div>
			</div>
			<div className={styles.tileCollectionWrapper}>
				<TileCollection testId="user-selection" dimensions={{ sm: 6, md: 6, lg: 4, xl: 4 }}>
					{usersToShow.map((user: UserDetail, index: number) => {
						const userAddedInProject = selectedUserList
							? selectedUserList.findIndex((teamUser) => {
									return teamUser === user.Email
							  })
							: ''
						return (
							<div key={'user-tile-n-icon-container' + index} className={styles.userTileNIconContainer}>
								{userAddedInProject >= 0 ? (
									<div className={styles.checkIconContainer}>
										<Icon
											testId="check-circle-user-selection"
											type={'check-circle'}
											size={'mini'}
										/>
									</div>
								) : (
									''
								)}
								<div key={'container-user-tile-' + index}>
									<UserTile
										key={'user-tile-' + user.Username}
										userID={user.id}
										name={
											user.FirstName === null || user.LastName === null
												? t('user.name.noneProvided')
												: user.FirstName + ' ' + user.LastName
										}
										role={''}
										jobTitle={''}
										emailAddress={user.Email}
										actionToTeams={userAddedInProject >= 0 ? 'remove' : 'add'}
										actionAddToTeam={addToTeam}
										actionRemoveOfTeam={removeFromTeam}
										buttonPosition={'Left'}
										hideButtons={isUserAlreadyInList(unmovableUserEmailList, user.Email)}
										testId="team-user-tile"
									/>
								</div>
							</div>
						)
					})}
				</TileCollection>
			</div>
			<div className={styles.doneButtonWrapper}>
				<Button
					text={t('user.button.done')}
					onClick={() => {
						handleOnDoneClick()
					}}
					testId="done-button"
				/>
			</div>
		</div>
	)
}
