import { ColorLink } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { AxiosResponse } from 'axios'
import { FocusEvent, ReactElement, useState } from 'react'
import { useApi } from '../../hooks'
import { Icon } from '../../local-core-ui'
import { AssignmentDetails } from '../../types'
import { AssignmentTile } from '../assignment-tile/assignment-tile'
import { PriorityLevel } from '../assignment-tile/types'
import { AssignmentsToTile } from '../assignments-to-tile/assignments-to-tile'
import { AssignedToWithName } from './assignments-manager-list'
import styles from './assignments-manager.module.scss'

interface AssignmentsGroupProps {
	idx: number
	assignedTo: AssignedToWithName
	filterCriteria: string
	showDashboards: boolean
	onHideDashboard(): void
	isSelected: boolean
	selectedAssignmentId?: string
	onFocus(event?: FocusEvent<HTMLDivElement>, assignment?: AssignmentDetails): void
	onAssignmentsGroupChange(): void
}

export const AssignmentsGroup = ({
	idx,
	assignedTo,
	filterCriteria,
	showDashboards,
	onHideDashboard,
	isSelected,
	selectedAssignmentId,
	onFocus,
	onAssignmentsGroupChange
}: AssignmentsGroupProps): ReactElement => {
	const apiClient = useApi()

	const [isGroupOpen, setIsGroupOpen] = useState<boolean>(false)
	const [showAssignments, setShowAssignments] = useState<boolean>(false)
	const [assignments, setAssignments] = useState<AssignmentDetails[]>([])
	const [isGettingAssignments, setIsGettingAssignments] = useState<boolean>(false)

	const filterByName = (name: string): boolean => {
		return name.toLowerCase().includes(filterCriteria.toLowerCase())
	}

	const isValidAssignee = filterByName(assignedTo?.assigneeName || '')

	const sortAssignments = (a: AssignmentDetails, b: AssignmentDetails) => {
		const aPriority = PriorityLevel[a.priority]
		const bPriority = PriorityLevel[b.priority]
		if (aPriority < bPriority) {
			return 1
		} else if (aPriority > bPriority) {
			return -1
		} else {
			return 0
		}
	}

	const filteredAndSortedAssignments = assignments
		.filter((assignment) => filterByName(assignment.displayName))
		.sort(sortAssignments)
	const assignmentsCount = filteredAndSortedAssignments.length
	const assignmentsCountPlusAssignee = assignmentsCount + 1
	const totalTiles = isValidAssignee ? assignmentsCountPlusAssignee : assignmentsCount
	const tileWidth = 377
	const margin = 4
	const closeTileWidth = 80
	const groupBgWidth = tileWidth * totalTiles - margin + closeTileWidth
	const groupMinWidth = isGroupOpen && totalTiles > 0 ? groupBgWidth : 0

	const handleReassign = () => {
		console.log('reassign')
	}

	const handleDownloadRecords = () => {
		console.log('download')
	}

	const getAssigneeAssignments = async () => {
		if (!isGroupOpen) {
			setIsGettingAssignments(true)
			const pageSize = 100
			let pageIndex = 1
			const assigneeAssignments: AssignmentDetails[] = []
			let hasFetchAllActive = false
			let hasFetchAllArchived = false
			do {
				const url = `/pls/steward/assignments?state=${hasFetchAllActive ? 'ARCHIVED' : 'ACTIVE'}&assignee=${
					assignedTo?.assignee
				}&pageIndex=${pageIndex}&pageSize=${pageSize}`
				await apiClient.get(url).then((response: AxiosResponse<AssignmentDetails[]>) => {
					const assignmentsDetailsList = response.data
					const numberOfAssignments = assignmentsDetailsList.length
					const isPageFull = numberOfAssignments === pageSize
					assigneeAssignments.push(...assignmentsDetailsList)
					if (!isPageFull && !hasFetchAllActive) {
						hasFetchAllActive = true
						pageIndex = 0
					} else if (!isPageFull && !hasFetchAllArchived) {
						hasFetchAllArchived = true
					}
					pageIndex++
				})
			} while (!hasFetchAllArchived)
			setAssignments(assigneeAssignments.filter((assignment) => assignment.totalRecords > 0))
			setIsGroupOpen(true)
			setIsGettingAssignments(false)
		}
	}

	const handleLeftArrow = () => {
		setShowAssignments(false)
		if (filteredAndSortedAssignments.length === 0) {
			setIsGroupOpen(false)
			onAssignmentsGroupChange()
		}
		if (isSelected && selectedAssignmentId !== undefined) {
			onFocus()
		}
	}

	return (
		<>
			<div
				key={`assignments-bg-${assignedTo?.assignee}`}
				className={`${styles.assignmentsManagerAssignmentSelectedBg} ${
					!showDashboards && styles.assignmentsManagerSummaryBg
				}`}
				style={{ minWidth: groupMinWidth, margin: 0 }}
				onTransitionEnd={() => {
					if (isGroupOpen) {
						setShowAssignments(true)
						onAssignmentsGroupChange()
					}
				}}
			/>
			<div
				key={`assignments-to-${assignedTo?.assignee}`}
				className={`${styles.assignmentsManagerTileContainer} ${
					!showDashboards && styles.assignmentsManagerSummaryTileContainer
				} ${isValidAssignee ? '' : styles.assignmentsManagerHiddenTile}`}
			>
				<AssignmentsToTile
					idx={idx}
					assigneeSummary={assignedTo}
					onHideDashboardClick={onHideDashboard}
					onReassignClick={handleReassign}
					onDownloadRecordsClick={handleDownloadRecords}
					onThreeCardsClick={getAssigneeAssignments}
					showDetail={showDashboards}
					isSelected={isSelected && selectedAssignmentId === undefined}
					onFocus={onFocus}
					isGettingAssignments={isGettingAssignments}
				/>
			</div>
			{isGroupOpen && (
				<>
					{filteredAndSortedAssignments.map((assignment, assignmentIdx) => {
						const tileIdx = assignmentIdx + 1
						const hiddenXPosition = tileWidth * tileIdx
						const xPosition = showAssignments ? 0 : -hiddenXPosition
						const tilesAreHidden = tileIdx === filteredAndSortedAssignments.length && !showAssignments
						return (
							<div
								key={`assignment-${assignedTo?.assignee}-${assignmentIdx}`}
								className={`${styles.assignmentsManagerTileContainer} ${
									!showDashboards && styles.assignmentsManagerSummaryTileContainer
								} ${styles.assignmentsManagerAssignmentContainer}`}
								style={{ transform: `translateX(${xPosition}px)` }}
								onTransitionEnd={() => {
									if (tilesAreHidden) {
										setIsGroupOpen(false)
										onAssignmentsGroupChange()
									}
								}}
							>
								<AssignmentTile
									idx={idx}
									assignmentDetail={assignment}
									onLeftArrowClicked={handleLeftArrow}
									onHideDashboardClick={onHideDashboard}
									onReassignClick={handleReassign}
									onDownloadRecordsClick={handleDownloadRecords}
									showDetail={showDashboards}
									isSelected={isSelected && assignment.assignmentId === selectedAssignmentId}
									onFocus={(event) => onFocus(event, assignment)}
								/>
							</div>
						)
					})}
					<div
						className={`${styles.assignmentsManagerTileContainer} ${
							!showDashboards && styles.assignmentsManagerSummaryTileContainer
						} ${styles.assignmentsManagerCloseTileContainer}`}
						style={{
							transform: `translateX(${
								showAssignments ? 0 : -tileWidth * filteredAndSortedAssignments.length - closeTileWidth
							}px)`
						}}
						tabIndex={0}
						onClick={handleLeftArrow}
					>
						<div
							className={`${styles.assignmentsManagerCloseTile} ${
								showDashboards ? styles.containerDetail : styles.assignmentsManagerCloseSummaryTile
							}`}
						>
							<div className={styles.assignmentsManagerCloseContent}>
								<Icon testId="back-assignments-group" type="arrow-back" color={ColorLink} />
							</div>
						</div>
					</div>
				</>
			)}
		</>
	)
}
