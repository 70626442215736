import { AxiosInstance, AxiosResponse } from 'axios'
import { EnterpriseSSOLoginResponse } from '../../../types'
import { getApiClient } from '../../../utilities'
import { AppThunk } from '../../index'

export const authenticateWithEnterpriseSSO =
	(origin: string): AppThunk =>
	(): void => {
		const httpClient: AxiosInstance = getApiClient()
		httpClient
			.get('/pls/enterprisesso/login?redirectTo=' + origin)
			.then((result: AxiosResponse<EnterpriseSSOLoginResponse>) => {
				if (result.data) window.location.replace(result.data.Result)
			})
			.catch((err) => console.log(err))
	}
