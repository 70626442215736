import { DNBButton, DNBToggle } from '@dnb-uux-design-system/react'
import { ChangeEvent, MouseEvent, ReactElement } from 'react'
import styles from './action-configuration.module.scss'

export interface ActionConfigurationProps {
	isDisabled?: boolean
	active?: boolean
	isLoading?: boolean
	toggleLabel?: string
	toggleDescription?: string
	buttonLabel?: string
	buttonDescription?: string
	contactMatchMode?: boolean
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void
	onClick?: (event: MouseEvent<HTMLButtonElement>) => void
}

export const ActionConfiguration = ({
	isDisabled,
	active,
	isLoading,
	toggleLabel,
	toggleDescription,
	buttonLabel,
	buttonDescription,
	onChange,
	onClick,
	contactMatchMode
}: ActionConfigurationProps): ReactElement => {
	return (
		<div className={isDisabled ? styles.disabled : styles.container}>
			{!contactMatchMode && (
				<div className={styles.toggleContainer}>
					<div className={styles.Body}>{toggleLabel}</div>
					<div className={styles.toggle}>
						<DNBToggle onChange={onChange} checked={active} isLoading={isLoading} isDisabled={isDisabled} />
					</div>
				</div>
			)}
			{!isLoading && <div className={styles.compactBody}>{toggleDescription}</div>}
			{onClick && !isLoading && (
				<div>
					<div className={styles.body}>{buttonDescription}</div>
					<div className={styles.buttonContainer}>
						<DNBButton size="small" variant="primary" onClick={onClick} disabled={isDisabled}>
							{buttonLabel}
						</DNBButton>
					</div>
				</div>
			)}
		</div>
	)
}
