import { AxiosResponse } from 'axios'
import { isEmpty } from 'lodash-es'
import { ValidateMappingResponse } from '../../../types'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { updateCurrentProjectAction, updateLoadingNextStep } from '../actions'

export const validateMapping =
	(): AppThunk<Promise<unknown>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<unknown> => {
		const isFirstTime = !getState().projectWizard.currentProject.mappingInfo.isDataMappingValidated
		// Warning messages should be seen and if the user clicks again, we should let him move fwd
		const definitionMap =
			getState().projectWizard.currentProject.mappingInfo.mapping.currentFieldDefinitionsRecord
				.fieldDefinitionsRecordsMap
		const entityType = getState().projectWizard.currentProject.source.entityType
		let url: string
		if (!isEmpty(definitionMap)) {
			const fileImportId = getState().projectWizard.currentProject.fileInfo?.uploadInfo?.file_import_id
			const sourceId = getState().projectWizard.currentProject.source.id
			if (fileImportId && !sourceId) {
				url = `/pls/sources/validate?entityType=${entityType}&fileImportId=${fileImportId}`
			} else {
				url = `/pls/sources/validate?entityType=${entityType}&sourceId=${sourceId}`
			}
			dispatch(updateLoadingNextStep(true))
			const apiClient = getApiClient4Thunks(dispatch)
			const mappingInfo = getState().projectWizard.currentProject.mappingInfo.mapping

			if (getState().projectWizard.alternateCompanyMatch.alternateAdrsExpanded) {
				const alternateCountry =
					mappingInfo.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap.companyInformation.filter(
						(row) => {
							return row.fieldName?.toLowerCase().includes('alternate_country')
						}
					)
				alternateCountry.length && alternateCountry[0].columnName === undefined
					? mappingInfo.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap.companyInformation
							.filter((row) => {
								return (
									row.fieldName?.toLowerCase().includes('alternate') &&
									!row.fieldName?.toLowerCase().includes('alternate_companyname')
								)
							})
							.forEach((row) => {
								delete row.columnName
								row.inCurrentImport = false
							})
					: ''
			}
			const mapping = mappingInfo
			mapping.existingFieldDefinitionsMap = {}

			return new Promise<void>((resolve, reject) => {
				apiClient
					.post(url, mapping)
					.then((response: AxiosResponse<ValidateMappingResponse>) => {
						const mappingResponse = response.data
						dispatch(
							updateCurrentProjectAction({
								mappingInfo: {
									...getState().projectWizard.currentProject.mappingInfo,
									validationResponse: mappingResponse,
									isDataMappingValidated: true,
									isDataTypesValidated: true
								}
							})
						)
						dispatch(updateLoadingNextStep(false))
						if (
							(response.data.validationResult === 'WARNING' && isFirstTime) ||
							response.data.validationResult === 'ERROR'
						) {
							reject(response.data)
						} else {
							resolve()
						}
					})
					.catch((e) => {
						console.error(e)
						dispatch(updateLoadingNextStep(false))
						reject(e)
					})
			})
		} else {
			return new Promise<void>((resolve, reject) => reject())
		}
	}
