import { startCase } from 'lodash-es'
import { MouseEvent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { BigButton } from '../../components/big-button/big-button'
import { DashboardComponent } from '../../components/dashboard-component/dashboard-component'
import { useFeatures, usePlatform } from '../../hooks/useEntitlements'
import { useAppDispatch } from '../../store'
import { newProjectAction } from '../../store/projectWizard/actions'
import dashboardStyles from '../dashboard.module.scss'
import styles from './dashboard-overview.module.scss'

export function DashboardOverview(): ReactElement {
	const { t } = useTranslation()

	const dispatch = useAppDispatch()
	const history = useHistory()
	const isAPIEnabled = useFeatures(['EnableAPI'])
	const isSalesforce = usePlatform('salesforce')

	const onCreateProjectHandler = (e: MouseEvent) => {
		e.preventDefault()
		dispatch(newProjectAction(isAPIEnabled, isSalesforce))
		history.push('/steps/project-wizard/project', { source: 'overview' })
	}

	return (
		<div className={styles.viewContainer}>
			<div className={`${dashboardStyles.dashboardContainer}`}>
				<div>
					<h1 data-testid="dashboard-title-data-health" className={styles.dashboardOverviewHeader}>
						{t('dashboard.overview.my-data-health')}
					</h1>
					<DashboardComponent hasUploads={true} hasErrors={false} testId="dashboard-component-tenant" />
				</div>
				<div className={dashboardStyles.bigButtonContainer}>
					<BigButton
						text={startCase(t('createNewProject') as string)}
						onClick={onCreateProjectHandler}
						testId="big-button-create-project"
					/>
				</div>
			</div>
		</div>
	)
}
