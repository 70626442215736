import { ApiErrorActionTypes, ApiErrorState, CLEAR_API_ERROR, SET_API_ERROR } from './types'

const initialState: ApiErrorState = {
	errorCode: undefined,
	message: undefined,
	additionalInfo: undefined,
	previousURL: undefined,
	requestURL: undefined,
	requestPayload: undefined,
	response: undefined,
	oAuthC4SError: undefined
}

export function apiErrorReducer(state = initialState, action: ApiErrorActionTypes): ApiErrorState {
	switch (action.type) {
		case SET_API_ERROR:
			return {
				...action.payload
			}
		case CLEAR_API_ERROR:
			return {
				...initialState
			}
		default:
			return state
	}
}
