/**
 * @class TileCollection
 */

import { Children, FC, ReactNode, useLayoutEffect, useRef, useState } from 'react'
import Grid, { GridSystemColumnRange } from '../grid/grid'
import { getCoords } from '../helpers/getCoords'
import styles from './tile-collection.module.scss'

declare type ReactNodes = ReactNode | ReactNode[]
declare type WithChildren<T> = {
	children: T
	dimensions?: { sm: number; md: number; lg: number; xl: number }
	testId?: string
}

export const TileCollection: FC<WithChildren<ReactNodes>> = ({
	children,
	dimensions: dimensionsProp,
	testId
}: WithChildren<ReactNodes>) => {
	const childrenArray = Children.toArray(children)
	const containerRef = useRef()
	const [dimensions, setDimensions] = useState(dimensionsProp || { sm: 6, md: 6, lg: 4, xl: 3 })
	useLayoutEffect(() => {
		if (!dimensionsProp && containerRef.current) {
			const currentCoords = getCoords(containerRef.current)
			if (currentCoords.width <= 600) {
				setDimensions({ ...dimensions, lg: 6, xl: 6 })
			} else if (currentCoords.width <= 1200) {
				setDimensions({ ...dimensions, xl: 4 })
			}
		}
	}, [])

	return (
		<div data-testid={`tile-container-${testId}`} className={styles.tileCollection} ref={containerRef}>
			<Grid testId={`${testId}` + '-container'} container gutter={3}>
				{childrenArray.map((child, index) => (
					<Grid
						sm={dimensions.sm as GridSystemColumnRange}
						md={dimensions.md as GridSystemColumnRange}
						lg={dimensions.lg as GridSystemColumnRange}
						xl={dimensions.xl as GridSystemColumnRange}
						key={'item-tile-' + index}
						testId={`${testId}` + '-item'}
					>
						{child}
					</Grid>
				))}
			</Grid>
		</div>
	)
}

export default TileCollection
