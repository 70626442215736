/**
 * @class Input
 **/

import cx from 'classnames'
import { camelCase } from 'lodash-es'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { Icon } from '../icon/icon'
import checkCircleImage from './assets/check-circle.svg'
import errorCircleImage from './assets/error-circle.svg'
import styles from './input.module.scss'

interface IInputProps {
	id: string
	size?: 'mini' | 'small' | 'medium' | 'large' | 'fluid'
	hint: string
	helperText?: string
	onChangeFunction(value: string): void
	error?: boolean
	errorMessage?: string
	value: string
	label: string
	required?: boolean
	disabled?: boolean
	type?: string
	positionIconError?: 'right' | 'bottom'
	positionIconValid?: 'right' | 'bottom'
	leadingIcon?: 'person' | 'search'
	name?: string
	refForm?: unknown
	onBlurFunction?(value: string): void
	maxLength?: number
	displayRemainingCharacters?: boolean
	remainingCharactersText?: string
	showCheckIcon?: boolean
	showErrorIcon?: boolean
	allowWhitespaceOnly?: boolean
	whitespaceErrorMessage?: string
	testId: string
}

export const Input = forwardRef<HTMLInputElement, IInputProps>(
	(
		{
			id,
			hint,
			helperText = '',
			size = 'small',
			onChangeFunction,
			error = undefined,
			errorMessage = '',
			value,
			label,
			required = false,
			disabled = false,
			type,
			positionIconError = 'right',
			positionIconValid = 'right',
			leadingIcon,
			name,
			refForm,
			onBlurFunction,
			maxLength,
			displayRemainingCharacters = false,
			remainingCharactersText,
			showCheckIcon = true,
			showErrorIcon = true,
			allowWhitespaceOnly = false,
			testId,
			whitespaceErrorMessage = 'invalid value'
		},
		ref?
	) => {
		const [statusInput, setStatusInput] = useState(disabled ? 'disabled' : 'default')
		const [showHelperText, setShowHelperText] = useState(false)
		const [showIconClear, setShowIconClear] = useState(false)
		const [validationActive, setValidationActive] = useState(false)
		const [errorStatus, setErrorStatus] = useState(false)
		const [valueWithMask, setValueWithMask] = useState('')
		const remainingCharacters = maxLength - value.length
		const elementId = id + '-input'
		let inputRef
		// eslint-disable-next-line react-hooks/rules-of-hooks
		if (refForm) inputRef = useRef(refForm)
		// eslint-disable-next-line react-hooks/rules-of-hooks
		else if (ref) inputRef = ref
		// eslint-disable-next-line react-hooks/rules-of-hooks
		else inputRef = useRef(null)

		useEffect(() => {
			if (disabled && statusInput !== 'disabled') {
				setShowIconClear(false)
				setValidationActive(false)
			}
			setStatusInput(disabled ? 'disabled' : 'default')
		}, [disabled])

		const onFocusInput = (event) => {
			if (event.target.value.length > 0) {
				setShowIconClear(true)
				setValidationActive(true)
				if (!errorStatus) {
					setShowHelperText(true)
					setStatusInput('focus')
				}
			} else {
				setShowIconClear(false)
				setValueWithMask('(')
				if (error) {
					setShowHelperText(false)
					setStatusInput('error')
					setValidationActive(true)
				} else {
					setShowHelperText(true)
					setStatusInput('focus')
					setValidationActive(true)
				}
			}
		}

		const onBlurInput = (event) => {
			setShowHelperText(false)
			if (!errorStatus) {
				setStatusInput('default')
			}
			if (value.length === 0) {
				setValueWithMask('')
			}
			if (onBlurFunction) {
				const newValue = cleanValue(event.target.value.trim())
				onBlurFunction(newValue)
			}
		}

		const onChangeInput = (event) => {
			if (!error) {
				setShowHelperText(true)
			}
			setValidationActive(true)
			const newValue = cleanValue(event.target.value)
			onChangeFunction(newValue)
		}

		const onKeyDownInput = (event) => {
			if (type === 'phone' && event.key === 'Backspace' && value.length === 3) {
				setValueWithMask(value.slice(0, 3))
			}
		}

		const clearInput = () => {
			setValidationActive(false)
			setShowIconClear(false)
			setStatusInput('default')
			setShowHelperText(false)
			if (required) {
				setErrorStatus(true)
			}
			if (error) {
				setShowHelperText(false)
				setStatusInput('error')
				setValidationActive(true)
			}
			onChangeFunction('')
			if (onBlurFunction) {
				const newValue = cleanValue(value)
				onBlurFunction(newValue)
			}
		}

		const applyMaskPhone = () => {
			const newValue = value
			let valueFormat = ''
			for (let i = 0; i < newValue.length; i++) {
				if (newValue[i] >= '0' && newValue[i] <= '9') {
					if (i == 0) {
						valueFormat = '(' + newValue[i]
					} else if (i == 2) {
						valueFormat = valueFormat + newValue[i] + ') '
					} else if (i == 6) {
						valueFormat = valueFormat + '-' + newValue[i]
					} else {
						valueFormat = valueFormat + newValue[i]
					}
				}
			}
			return valueFormat
		}

		const cleanValue = (valueToClean) => {
			let newValue = valueToClean
			if (type === 'phone') {
				const cleanP = valueToClean.replace(/[()]/g, '')
				const cleanHyphen = cleanP.replace(/[-]/g, '')
				const cleanSpaces = cleanHyphen.replace(/ /g, '')
				const onlyNumbers = cleanSpaces.replace(/[^0-9]+/g, '')
				newValue = onlyNumbers
			} else if (!allowWhitespaceOnly) {
				newValue = newValue.trim() === '' ? '' : newValue
			}
			return newValue
		}

		useEffect(() => {
			if (type === 'phone') {
				const valueMask = applyMaskPhone()
				setValueWithMask(valueMask)
			}
			if (statusInput !== 'disabled') {
				if (value.length === 0) {
					if (error) {
						setValidationActive(true)
						setErrorStatus(error)
						setShowHelperText(false)
						setShowIconClear(false)
					} else {
						setShowIconClear(false)
						setErrorStatus(false)
						if (validationActive) {
							setShowHelperText(true)
							setStatusInput('focus')
							setValidationActive(false)
						}
					}
				} else if (value.length > 0) {
					setShowIconClear(true)
					setErrorStatus(error)
					setValidationActive(true)
					if (error) {
						setStatusInput('error')
						setShowHelperText(false)
					} else if (statusInput !== 'default') {
						setStatusInput('focus')
						setShowHelperText(true)
					}
				}
			}
		}, [value, error, statusInput])

		useEffect(() => {
			setErrorStatus(error)
			if (error) {
				setStatusInput('error')
			}
		}, [error])

		useEffect(() => {
			const { current } = inputRef
			current?.setAttribute('value', value)
		}, [value])

		return (
			<div data-testid={`input-general-container-${testId}`} className={styles.input} id={id}>
				<div data-testid={`input-label-container-${testId}`} className={styles.containerLabelInput}>
					<label data-testid={`input-label-${testId}`} className={styles.labelInput} htmlFor={elementId}>
						{label}
					</label>
					{required && (
						<div data-testid={`required-symbol-${testId}`} className={styles.requiredSymbol}>
							&nbsp;*
						</div>
					)}
				</div>
				<div data-testid={`input-component-${testId}`} className={styles.containerInput}>
					<div
						className={cx(
							styles.containerInput,
							styles.generalInputCustom,
							styles[camelCase(`input-custom-${size}`)],
							styles[camelCase(`color-input-custom-${statusInput}`)]
						)}
						data-testid={`input-color-sizes-${testId}`}
					>
						{leadingIcon !== undefined && (
							<div
								data-testid={`input-icon-container-${testId}`}
								className={styles[camelCase(`position-icon-${statusInput}`)]}
							>
								<Icon
									testId={`input-leading-icon-${testId}`}
									type={leadingIcon}
									size="mini"
									title={`${leadingIcon}-icon`}
								/>
							</div>
						)}
						<div
							className={cx(
								styles.containerInput,
								leadingIcon !== undefined ? styles.inputListIcon : styles.inputListNoIcon
							)}
							data-testid={`input-no-borders-${testId}`}
						>
							<input
								id={elementId}
								name={name}
								aria-hidden={'true'}
								ref={inputRef}
								tabIndex={-1}
								data-testid={testId + '-hidden'}
							/>
							<input
								className={cx(
									styles.inputDefault,
									styles.inputPlaceholder,
									styles[camelCase(`input-placeholder-color-status-${statusInput}`)]
								)}
								placeholder={hint}
								onFocus={(e) => onFocusInput(e)}
								onBlur={(e) => onBlurInput(e)}
								value={type === 'phone' ? valueWithMask : value}
								type={type === 'password' ? type : 'text'}
								onChange={(e) => onChangeInput(e)}
								disabled={disabled}
								maxLength={type === 'phone' ? 14 : maxLength}
								onKeyDown={(e) => onKeyDownInput(e)}
								data-testid={testId + '-input'}
							/>
						</div>
						{showIconClear && (
							<div data-testid={`input-clear-${testId}`} className={styles.containerButtonInput}>
								<button
									className={styles.buttonClearInput}
									onClick={() => clearInput()}
									data-testid={testId + '-clear'}
								>
									<div data-testid={`input-close-${testId}`} className={styles.closeIcon}>
										&times;
									</div>
								</button>
							</div>
						)}
					</div>
					{validationActive && error !== undefined && (showErrorIcon || showCheckIcon) ? (
						<div data-testid={`input-validations-${testId}`} className={styles.positionResultValidation}>
							{errorStatus ? (
								positionIconError === 'right' && showErrorIcon ? (
									<img data-testid={`input-error-${testId}`} src={errorCircleImage} alt="error" />
								) : null
							) : positionIconValid === 'right' && showCheckIcon ? (
								<img data-testid={`input-okay-${testId}`} src={checkCircleImage} alt="valid" />
							) : null}
						</div>
					) : null}
				</div>
				{displayRemainingCharacters && maxLength && (
					<div
						data-testid={`input-remaining-container-${testId}`}
						className={styles[camelCase(`input-custom-${size}`)]}
					>
						<div
							data-testid={`input-remaining-${testId}`}
							className={styles.remainingCharacters}
						>{`${remainingCharacters} ${remainingCharactersText ? remainingCharactersText : ''}`}</div>
					</div>
				)}
				<div data-testid={`input-container-text-img-${testId}`} className={styles.spaceToTextBottom}>
					{showHelperText && (
						<div data-testid={`input-img-helper-${testId}`} className={styles.containerFlexInput}>
							{validationActive && positionIconValid === 'bottom' && showCheckIcon && (
								<img
									data-testid={`input-position-bottom-${testId}`}
									className={styles.positionIconBottom}
									src={checkCircleImage}
									alt="check"
								/>
							)}
							<p
								data-testid={`input-helper-text-${testId}`}
								className={cx(styles.styleTextInstructions, styles.colorHelper)}
							>
								{helperText}
							</p>
						</div>
					)}

					{validationActive && (
						<div data-testid={`input-container-validation-${testId}`} className={styles.containerFlexInput}>
							{errorStatus && showErrorIcon && positionIconError === 'bottom' && (
								<img
									data-testid={`input-icon-validation-${testId}`}
									className={styles.positionIconBottom}
									src={errorCircleImage}
									alt="error"
								/>
							)}
							<p
								data-testid={`input-error-${testId}`}
								className={cx(styles.styleTextInstructions, styles.colorError)}
							>
								{errorMessage}
							</p>
						</div>
					)}
				</div>
			</div>
		)
	}
)

Input.displayName = 'Input'

export default Input
