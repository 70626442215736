import { MutableRefObject } from 'react'
import { TFunction } from 'react-i18next'
import assessmentDelinquencyScoreOverrideReasonsPrefixes from '../assessment-delinquency-score-override-reasons-element-prefixes.json'
import { ElementUIFacade } from '../ElementUIFacade'

const buildChildrenTree = (
	listFlattenElementsAssessmentDelinquencyScoreOverrideReasons: Array<ElementUIFacade>,
	root: ElementUIFacade,
	t: TFunction<'translation'>,
	assessmentDelinquencyScoreOverrideReasonsSelectorValueRef: MutableRefObject<number>
) => {
	const groupElements: Array<ElementUIFacade> = []
	assessmentDelinquencyScoreOverrideReasonsPrefixes.forEach((prefix) => {
		const elementList = listFlattenElementsAssessmentDelinquencyScoreOverrideReasons.filter((ele) =>
			ele.elementId.startsWith(prefix)
		)
		if (elementList && elementList.length > 0) {
			const groupParent: ElementUIFacade = {
				parent: root,
				elementId: `${prefix}Id`,
				displayName: t(`${prefix}.element`),
				description: elementList[0].description,
				level: elementList[0].level,
				dataType: elementList[0].dataType,
				example: elementList[0].example,
				showChildrenBlocks: false,
				considerChildrenForCount: false,
				maximumElementsInOutput: assessmentDelinquencyScoreOverrideReasonsSelectorValueRef
			}
			elementList.sort((a: ElementUIFacade, b: ElementUIFacade) => {
				if (a.elementId > b.elementId) {
					return 1
				} else if (a.elementId < b.elementId) {
					return -1
				} else {
					return 0
				}
			})
			groupParent.childs = elementList
			groupElements.push(groupParent)
		}
	})
	return groupElements
}

export const buildAssessmentDelinquencyScoreOverrideReasonsTree = (
	listFlattenElementsAssessmentDelinquencyScoreOverrideReasons: Array<ElementUIFacade>,
	t: TFunction<'translation'>,
	assessmentDelinquencyScoreOverrideReasonsSelectorValueRef: MutableRefObject<number>
): Array<ElementUIFacade> => {
	const tree: Array<ElementUIFacade> = []
	if (listFlattenElementsAssessmentDelinquencyScoreOverrideReasons.length) {
		const root: ElementUIFacade = {
			blockId: t(listFlattenElementsAssessmentDelinquencyScoreOverrideReasons[0].blockId || ''),
			elementId: 'assessmentDelinquencyScoreOverrideReasonsId',
			displayName: t('assessmentDelinquencyScoreOverrideReasons.element'),
			description: t('assessmentDelinquencyScoreOverrideReasons.description'),
			level: '2',
			dataType: t('ARRAY'),
			showChildrenBlocks: true,
			containsChildrenSelector: true,
			considerChildrenForCount: true,
			maximumElementsInOutput: assessmentDelinquencyScoreOverrideReasonsSelectorValueRef,
			outputDropdownOptions: [
				{ label: '1', value: '1' },
				{ label: '2', value: '2' },
				{ label: '3', value: '3' }
			]
		}
		tree.push(root)

		root.childs = buildChildrenTree(
			listFlattenElementsAssessmentDelinquencyScoreOverrideReasons,
			root,
			t,
			assessmentDelinquencyScoreOverrideReasonsSelectorValueRef
		)
	}
	return tree
}
