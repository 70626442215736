import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ThirdPartyIntegrationsState {
	finishedProjects: Array<string>
	finishedSources: Array<string>
}

const initialState: ThirdPartyIntegrationsState = {
	finishedProjects: [],
	finishedSources: []
}

const thirdPartyIntegrationsSlice = createSlice({
	name: 'thirdPartyIntegrations',
	initialState,
	reducers: {
		markProjectAsFinished: (state, action: PayloadAction<string>) => {
			const currentFinishedProjects = state.finishedProjects
			if (!currentFinishedProjects.includes(action.payload)) currentFinishedProjects.push(action.payload)
			state.finishedProjects = currentFinishedProjects
		},
		markSourceAsFinished: (state, action: PayloadAction<string>) => {
			const currentFinishedSources = state.finishedSources
			if (!currentFinishedSources.includes(action.payload)) currentFinishedSources.push(action.payload)
			state.finishedSources = currentFinishedSources
		}
	}
})

// Extract the action creators object and the reducer
const { actions, reducer } = thirdPartyIntegrationsSlice
// Extract and export each action creator by name
export const { markProjectAsFinished, markSourceAsFinished } = actions

export default reducer
