import { ColorScaleRed, ColorWhite } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ChangeEvent, MouseEventHandler, ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import stagingWordmarkLogo from '../assets/images/dnb-staging-wordmark-logo.svg'
import wordmarkLogo from '../assets/images/dnb-wordmark-logo.svg'
import { CheckBox, Icon } from '../local-core-ui'
import { idaasLogin, initializeSessionAction, RootState, SignOutReason, useAppDispatch, useAppSelector } from '../store'
import styles from './LoginTemplate.module.scss'

export function LoginTemplate(): ReactElement {
	const { t } = useTranslation()
	const [username, setUsername] = useState(
		localStorage.getItem('rememberUsername') === 'true' ? localStorage.getItem('username') || '' : ''
	)
	const [password, setPassword] = useState('')
	const [fetching, setFetching] = useState(false)
	const [showPrivacyNotice, setShowPrivacyNotice] = useState(false)
	const [errorUsername, setErrorUsername] = useState<boolean>(false)
	const [errorPassword, setErrorPassword] = useState<boolean>(false)
	const [rememberUsername, setRememberUsername] = useState<boolean>(
		localStorage.getItem('rememberUsername') === 'true'
	)
	const privacyModalRef = useRef<HTMLDivElement>(null)
	const authContainerRef = useRef<HTMLDivElement>(null)
	const dnbLinksRef = useRef<HTMLDivElement>(null)
	const selectSession = (state: RootState) => state.session
	const session = useAppSelector(selectSession)

	const dispatch = useAppDispatch()

	const login = () => {
		if (!(errorUsername || errorPassword)) {
			setFetching(true)
			dispatch(idaasLogin(username, password)).then(() => setFetching(false))
		}
	}

	const onUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
		const newUsername = event.target.value
		setUsername(newUsername)
		setErrorUsername(newUsername === '')
		if (rememberUsername) {
			localStorage.setItem('username', newUsername)
		}
	}

	const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		const newPassword = event.target.value
		setPassword(newPassword)
		setErrorPassword(newPassword === '')
	}

	const onRememberUsernameChange = () => {
		const newValue = !rememberUsername
		setRememberUsername(newValue)
		localStorage.setItem('rememberUsername', `${newValue}`)
		if (!newValue) {
			localStorage.removeItem('username')
		}
	}

	useEffect(() => {
		dispatch(initializeSessionAction())
		/**
		 * We only want to run this effect once, so we use the empty array as the dependency
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleCloseClick: MouseEventHandler<HTMLDivElement> = (event) => {
		const eventTargetNode = event.target as Node
		const clickedOnAuthContainer = authContainerRef.current && authContainerRef.current.contains(eventTargetNode)
		const clickedOnDnbLinks = dnbLinksRef.current && dnbLinksRef.current.contains(eventTargetNode)
		const clickedOnPrivacyModal = privacyModalRef.current && privacyModalRef.current.contains(eventTargetNode)
		if (!(clickedOnAuthContainer || clickedOnDnbLinks || clickedOnPrivacyModal)) {
			event.preventDefault()
			setShowPrivacyNotice(false)
		}
	}

	return (
		<div className={styles.loginBackground} onClick={handleCloseClick}>
			<div className={styles.authContainer} ref={authContainerRef}>
				<div className={styles.authHeader}>
					<img
						className={styles.authLogo}
						alt="dnb-stg logo logo"
						aria-label="dnb-stg logo logo"
						src={import.meta.env.VITE_LOGIN_IMAGE === 'staging' ? stagingWordmarkLogo : wordmarkLogo}
					/>
					{fetching && (
						<div className={styles.beaconContainer}>
							<>
								<div className={styles.beacon} />
								<div className={styles.circle} />
							</>
						</div>
					)}
				</div>
				<div className={styles.authContent}>
					{session.signoutreason === SignOutReason.wronguserorpassword || errorUsername || errorPassword ? (
						<div className={styles.errorCard}>
							<div className={styles.errorIcon}>
								<Icon testId="warning-template" type="warning" size="small" color={ColorWhite} />
							</div>
							<div className={styles.errorText}>
								<p>
									{t('login.forgotPassword.errorCard')}
									<br />
									{t('login.forgotPassword.errorCard.complement')}
								</p>
							</div>
						</div>
					) : undefined}
					<div className={styles.formContent}>
						<div className={styles.fieldSetContainer}>
							<div className={styles.formLabel}>
								<label htmlFor="username" className={styles.label}>
									Username&nbsp;
								</label>
							</div>
							<span className={styles.formInputField}>
								<input
									type="text"
									placeholder=""
									name="username"
									id="username"
									value={username}
									aria-label=""
									autoComplete="username"
									required={true}
									className={styles.inputField}
									onChange={(event) => onUsernameChange(event)}
								/>
							</span>
						</div>
						{errorUsername && (
							<div className={styles.errorContainer}>
								<Icon
									testId="warning-red-user-name"
									type="warning"
									size="small"
									color={ColorScaleRed}
								/>
								<p className={styles.errorValidation}>{t('login.error.username')}</p>
							</div>
						)}
						<div className={styles.fieldSetContainer}>
							<div className={styles.formLabel}>
								<label htmlFor="password" className={styles.label}>
									Password&nbsp;
								</label>
							</div>
							<span className={styles.formInputField}>
								<input
									type="password"
									placeholder=""
									name="password"
									id="password"
									value={password}
									aria-label=""
									autoComplete="password"
									required={true}
									className={styles.inputField}
									onChange={(event) => onPasswordChange(event)}
								/>
							</span>
						</div>
						{errorPassword && (
							<div className={styles.errorContainer}>
								<Icon testId="warning-password" type="warning" size="small" color={ColorScaleRed} />
								<p className={styles.errorValidation}>{t('login.error.password')}</p>
							</div>
						)}
						<div className={styles.fieldSetContainer}>
							<div className={styles.formCheckbox}>
								<CheckBox
									id="cb-rememberUsername"
									label="Remember my username"
									checked={rememberUsername}
									testId="cb-rememberUsername"
									onChange={onRememberUsernameChange}
								/>
							</div>
						</div>
					</div>
					<button
						className={styles.button}
						onClick={login}
						disabled={fetching || errorUsername || errorPassword}
					>
						Continue
					</button>
				</div>
			</div>
			<div className={styles.dnbLinks} ref={dnbLinksRef}>
				<a onClick={() => setShowPrivacyNotice(true)}>Privacy</a>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<Link to="/signin/forgot-password">{t('login.forgotPassword.link')}</Link>
			</div>
			{/*-- The Privacy Modal*/}
			{showPrivacyNotice && (
				<div role="dialog" className={styles.privacyNotice}>
					<div className={styles.privacyContent} ref={privacyModalRef}>
						<div className={styles.authHeader}>
							<img
								className={styles.authLogo}
								alt="dnb-stg logo logo"
								aria-label="dnb-stg logo logo"
								src={
									import.meta.env.VITE_LOGIN_IMAGE === 'staging' ? stagingWordmarkLogo : wordmarkLogo
								}
							/>
						</div>
						<div
							id="priv-body"
							style={{
								fontSize: '14px',
								background: '#fff',
								color: '#000',
								padding: '20px 25px 25px 20px',
								height: '100%',
								fontFamily: "'Roboto', 'Helvetica Neue', 'Helvetica', 'Segoe UI', 'Arial', 'sans-serif'"
							}}
						>
							<span className="priv-title" style={{ fontSize: '20px' }}>
								Privacy
								<br />
								<br />
							</span>
							Access to this site is governed by the following D&B Policies
							<br />
							<br />
							<a
								href="https://www.dnb.co.uk/utility-pages/privacy-policy.html"
								target="_blank"
								rel="noreferrer"
							>
								European Union Privacy Policy
							</a>
							<br />
							<br />
							<a
								href="http://www.dnb.com/utility-pages/privacy-policy.html"
								target="_blank"
								rel="noreferrer"
							>
								Cookie and Privacy Policy for USA
							</a>
							<br />
							<br />
							<br />
							<button className={styles.closeButton} onClick={() => setShowPrivacyNotice(false)}>
								Close
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
