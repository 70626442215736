import cx from 'classnames'
import { ReactElement, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GetMatchSummaryCodes, Results } from '../../../../../types/match-recommendation/GetMatchRecommendationProfile'
import styles from './match-recommendation-summary.module.scss'

interface OutcomeOrder {
	outcome: string
	code: string
}
interface MatchRecommendationSummaryProps {
	summaryData: Results
	summaryCodes: GetMatchSummaryCodes
	ruleOrder: Array<string>
}
export function MatchRecommendationSummary({
	summaryData,
	summaryCodes,
	ruleOrder
}: MatchRecommendationSummaryProps): ReactElement {
	const { t } = useTranslation()
	const rulesContainer = useRef<HTMLDivElement>(null)
	const [sortRuleOrder, setSortRuleOrder] = useState<Array<OutcomeOrder>>([])
	const [oldBox, setOldBox] = useState<Array<Record<string, DOMRect>>>([])

	useEffect(() => {
		const positionArray: Array<Record<string, DOMRect>> = Array.from(rulesContainer.current?.children || []).map(
			(child) => {
				const newBox = child.getBoundingClientRect()
				return { [child.id]: newBox }
			}
		)
		setOldBox(positionArray)

		if (ruleOrder.length > 0) {
			const sortRule: Array<OutcomeOrder> = ruleOrder.reduce<Array<OutcomeOrder>>(
				(acc: Array<OutcomeOrder>, codeCurrent: string) => {
					const dataRule = Object.entries(summaryData).filter((dataInSummary) => {
						if (dataInSummary[0] === 'rule') {
							return null
						}
						return dataInSummary[1].find((codeOfProfile) => codeOfProfile === codeCurrent)
					})
					return acc.concat({ outcome: dataRule[0][0], code: codeCurrent })
				},
				[] as Array<OutcomeOrder>
			)
			setSortRuleOrder(sortRule)
		}
	}, [ruleOrder, summaryData])

	useLayoutEffect(() => {
		Array.from((rulesContainer.current?.children as HTMLCollectionOf<HTMLElement>) || []).forEach((child) => {
			const newBox = child.getBoundingClientRect()
			const oldBIdx = Object.values(oldBox).findIndex((box) => Object.keys(box)[0] === child.id)
			const oldB = oldBox[oldBIdx]
			if (oldB) {
				const deltaY = oldB[`${child.id}`].y - newBox.y
				const ruleBox = document.querySelector(`#${child.id}`) as HTMLElement
				requestAnimationFrame(() => {
					if (rulesContainer.current && ruleBox) {
						ruleBox.style.transform = `translate(0px, ${deltaY}px)`
						ruleBox.style.transition = 'transform 0ms'
						requestAnimationFrame(() => {
							if (rulesContainer.current) {
								ruleBox.style.transform = ''
								ruleBox.style.transition = 'transform 500ms'
							}
						})
					}
				})
			}
		})
	}, [sortRuleOrder])

	const searchDescription = (label: string) => {
		const listCodes = Object.keys(summaryCodes)
		const listDesc = Object.values(summaryCodes) as unknown as Array<string>
		const objDescriptionIdx = listCodes.findIndex((code) => code === label)
		return objDescriptionIdx > -1 ? (listDesc[objDescriptionIdx] as string) : ''
	}

	return (
		<div>
			<div className={styles.containerLabels}>
				{Object.keys(summaryData).map((labelUsed, idx) => (
					<div
						className={cx(styles.containerLabelLegend, styles[`color-${labelUsed}`])}
						key={`${labelUsed}-${idx}`}
					>
						<div className={styles[`text-${labelUsed}`]}>
							{t(`label.outcome.match.recommendation.${labelUsed}`)}
						</div>
					</div>
				))}
			</div>
			<div ref={rulesContainer}>
				{sortRuleOrder.map((order: OutcomeOrder) => (
					<div
						className={styles.lineSummary}
						key={`${order.code}-${order.outcome}`}
						id={`${order.code}-${order.outcome}`}
					>
						<div className={cx(styles.labelLine, styles[`color-${order.outcome}`])}>
							<div className={styles[`text-${order.outcome}`]}>{order.code}</div>
						</div>
						<div>{searchDescription(order.code)}</div>
					</div>
				))}
			</div>
		</div>
	)
}
