import { AxiosInstance, AxiosResponse } from 'axios'

export const getCrmAvailableSourceTypesDetails = async (
	apiClient: AxiosInstance,
	platformName: string,
	orgId: string,
	matchType: string
): Promise<Array<string>> => {
	const url = `/pls/integration/${platformName}/organization/${orgId}/sources/${matchType}/details`
	return orgId ? apiClient.get(url).then((response: AxiosResponse<Array<string>>) => response.data) : []
}
