export const ADD_MATCH_RULE = 'ADD_MATCH_RULE'
export const UPDATE_MATCH_RULE = 'UPDATE_MATCH_RULE'
export const DELETE_MATCH_RULE = 'DELETE_MATCH_RULE'
export const SET_MATCH_DEFINITION_VALIDATED = 'SET_MATCH_DEFINITION_VALIDATED'
export const UPLOADING_FILE = 'UPLOADING_FILE'
export const SET_PERCENTAGE_UPLOADED = 'SET_PERCENTAGE_UPLOADED'
export const REPLACE_FILE = 'REPLACE_FILE'
export const UPDATE_CURRENT_PROJECT = 'UPDATE_CURRENT_PROJECT'
export const CREATE_PROJECT = 'CREATE_PROJECT'
export const UPDATE_STATUS_START_IMPORT = 'UPDATE_STATUS_START_IMPORT '
export const UPDATE_LOADING_NEXT_STEP = 'UPDATE_LOADING_NEXT_STEP'
export const DELETE_ADVANCED_MATCH_RULES = 'DELETE_ADVANCED_MATCH_RULES'
export const ADD_TEAM_MEMBER = 'ADD_TEAM_MEMBER'
export const REMOVE_TEAM_MEMBER = 'REMOVE_TEAM_MEMBER'
export const SET_OVERVIEW_VIEWED = 'SET_OVERVIEW_VIEWED'
export const SET_ALTERNATE_COMPANY_INFO = 'SET_ALTERNATE_COMPANY_INFO'
export const SET_ENRICHMAPPING_VIEWED = 'SET_ENRICHMAPPING_VIEWED'
export const SELECT_PROJECT = 'SELECT_PROJECT'
export const NEW_PROJECT = 'NEW_PROJECT'
export const REMOVE_PROJECT_FROM_WIZARD = 'REMOVE_PROJECT_FROM_WIZARD'
export const SET_FILE_ALREADY_PROCESSED = 'SET_FILE_ALREADY_PROCESSED'
export const BASE_RULE = 'BASE_RULE'
export const SPECIAL_RULE = 'SPECIAL_RULE'
export const SET_ENRICHING_APPROACH = 'SET_ENRICHING_APPROACH'
export const REMOVE_ELEMENT_IN_PREVIEW = 'REMOVE_ELEMENT_IN_PREVIEW'
export const ADD_ELEMENT_IN_PREVIEW = 'ADD_ELEMENT_IN_PREVIEW'
export const SYNC_ELEMENTS_IN_PREVIEW = 'SYNC_ELEMENTS_IN_PREVIEW'
export const SAVING_LIBRARY_FLAG = 'SAVING_LIBRARY_FLAG'
export const SET_MATCHING_APPROACH = 'SET_MATCHING_APPROACH'
export const CLEAR_MATCHING_APPROACH = 'CLEAR_MATCHING_APPROACH'
export const CLEAR_CURRENT_MATCH_CONFIG_TEMPLATE = 'CLEAR_CURRENT_MATCH_CONFIG_TEMPLATE'
export const CLEAR_ENRICHING_APPROACH = 'CLEAR_ENRICHING_APPROACH'
export const UPDATE_ENRICHING_LAYOUT_HASHES = 'UPDATE_ENRICHING_LAYOUT_HASHES'
export const UPDATE_PROCEED_CONFIRMATION_REQUIRED = 'UPDATE_PROCEED_CONFIRMATION_REQUIRED'
export const SET_SF_VERSION = 'SET_SF_VERSION'
export const UPDATE_DEPRECATED_ELEMENTS = 'UPDATE_DEPRECATED_ELEMENTS'
export const UPDATE_DEPRECATED_MODAL = 'UPDATE_DEPRECATED_MODAL'
export const SHOW_MODAL_CONFLICT_FAMILY_TREE = 'SHOW_MODAL_CONFLICT_FAMILY_TREE'

export * from './crmSourceType'
export * from './EnrichingBlock'
export * from './FileInfo'
export * from './InitialProjectWizardState'
export * from './MatchConfigTemplateData'
export * from './MatchRuleInfo'
export * from './Project'
export * from './ProjectWizardActionTypes'
export * from './ProjectWizardState'
export * from './Source'
export * from './TemplateData'
export * from './ThirdPartyIntegration'
export * from './updateCurrentProjectRequest'
