import { ReactElement, useState } from 'react'
import { Icon } from '../../../local-core-ui'
import styles from './match-string-container.module.scss'

interface MatchStringContainerI {
	matchStringIndex: number
	matchString: string

	onClickDelete(index: number): void

	testId: string
	readonly?: boolean
}

export const MatchStringContainer = ({
	matchStringIndex,
	matchString,
	onClickDelete,
	testId,
	readonly = false
}: MatchStringContainerI): ReactElement => {
	const [isTrashVisible, setTrashVisible] = useState(false)

	return (
		<div className={styles.matchStringContainer}>
			<div
				className={styles.matchStringDiv}
				onMouseEnter={() => setTrashVisible(true)}
				onMouseLeave={() => setTrashVisible(false)}
				data-testid={testId + '-match-string-div'}
			>
				<label data-testid="match-string" className={styles.matchString}>
					{matchString.split('').join('  ')}
				</label>
			</div>
			{!readonly && isTrashVisible && (
				<div
					className={styles.matchStringTrash}
					onMouseEnter={() => setTrashVisible(true)}
					onMouseLeave={() => setTrashVisible(false)}
					onClick={() => {
						onClickDelete(matchStringIndex)
					}}
					data-testid={testId + '-match-string-trash'}
				>
					<Icon testId="trash-match-string-container" type="trash" />
				</div>
			)}
		</div>
	)
}
