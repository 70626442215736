import {
	ColorBlueBrand,
	ColorGray,
	ColorGrayDarker,
	ColorOrange
} from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import cx from 'classnames'
import * as React from 'react'
import { Icon, ToolTip } from '../../local-core-ui'
import styles from './adjustable-progress-bar.module.scss'

export interface AdjustableProgressVisualProps {
	totalRequirements: number
	remaining: number
	newRequirements: number
	satisfiedRequirements: number
	/** In pixels */
	progressBarWidth?: number
	tooltipMessage?: string
}

export const AdjustableProgressBar = ({
	totalRequirements = 0,
	remaining = 0,
	newRequirements = 0,
	satisfiedRequirements = 0,
	progressBarWidth = 180,
	tooltipMessage
}: AdjustableProgressVisualProps): React.ReactElement => {
	const requirementWidth = progressBarWidth / totalRequirements
	if (remaining <= 0) {
		remaining = 0
	}
	const isActiveState = satisfiedRequirements + newRequirements !== 0

	return (
		<div className={styles.progressContainer}>
			<div className={styles.containerTooltip}>
				<ToolTip
					customContent={
						<div className="containerDataTooltip">
							<span className="text">{tooltipMessage}</span>
						</div>
					}
					position={'top'}
					testId={'testId'}
				>
					<div className={styles.fieldLimit}>Field Limit (Max {totalRequirements})</div>
				</ToolTip>
			</div>
			<div className={cx(styles.numbersContainer, `${isActiveState ? styles.active : styles.inactive}`)}>
				{`${satisfiedRequirements + newRequirements ? satisfiedRequirements + newRequirements : 0} / `}
			</div>
			<div
				className={cx(styles.numbersContainerTotal, `${isActiveState ? styles.active : styles.inactive}`)}
			>{` ${totalRequirements}`}</div>
			<div className={styles.barContainer} style={{ width: `${progressBarWidth}px` }}>
				<div
					className={cx(styles.progressBar, `${isActiveState ? styles.active : styles.inactive}`)}
					style={{
						width: `${satisfiedRequirements * requirementWidth}px`
					}}
				/>
				<div
					className={cx(styles.whiteSpace, `${isActiveState ? styles.active : styles.inactive}`)}
					style={{
						width: `${4}px`
					}}
				/>
				<div
					className={cx(styles.new, `${isActiveState ? styles.active : styles.inactive}`)}
					style={{
						width: `${newRequirements * requirementWidth}px`
					}}
				/>
				<div
					className={cx(styles.whiteSpace, `${isActiveState ? styles.active : styles.inactive}`)}
					style={{
						width: `${4}px`
					}}
				/>
				<div className={cx(styles.verticalLine, `${isActiveState ? styles.active : styles.inactive}`)} />
			</div>
			<div className={styles.statusIcon}>
				<div className={styles.orangeDotIconWrapper}>
					<Icon testId="empty-dot-orange-progress" type={'empty-dot'} color={ColorOrange} />
				</div>
				<p className={styles.existingNumber} color={ColorGrayDarker}>{`${satisfiedRequirements}`}</p>
				<p className={styles.existingText} color={ColorGrayDarker}>{`Existing`}</p>

				<div className={styles.blueDotIconWrapper}>
					<Icon testId="empty-dot-blue-progress" type={'empty-dot'} color={ColorBlueBrand} />
				</div>
				<p className={styles.existingNumber} color={ColorGrayDarker}>{`${newRequirements}`}</p>
				<div className="container-tooltip">
					<p className={styles.existingText} color={ColorGrayDarker}>{`Newly selected elements`}</p>
				</div>

				<div className={styles.grayDotIconWrapper}>
					<Icon testId="empty-dot-gray-progress" type={'empty-dot'} color={ColorGray} />
				</div>
				<p className={styles.existingNumber} color={ColorGrayDarker}>{`${remaining}`}</p>
				<p className={styles.existingText} color={ColorGrayDarker}>{`Remaining`}</p>
			</div>
		</div>
	)
}
