import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { PrecedenceProfile } from '../types/match-recommendation/GetMatchRecommendationProfile'
import { getMatchPrecedenceProfiles } from './api/getMatchPrecedenceProfiles'

export const useMatchPrecedenceProfiles = (): UseQueryResult<PrecedenceProfile> => {
	const apiClient = useApi()
	const queryKey = ['getMatchPrecedenceProfiles']
	const queryFunction = () => getMatchPrecedenceProfiles(apiClient)

	return useQuery(queryKey, queryFunction)
}
