import { UserDetail } from '../../../types'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { addTeamMemberAction } from '../actions'

export const addTeamMember =
	(user: UserDetail): AppThunk<Promise<void>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<void> => {
		const projectWizard = getState().projectWizard
		const apiClient = getApiClient4Thunks(dispatch)
		if (projectWizard.currentProject.id) {
			const teamId = projectWizard.currentProject.team.TeamId
			if (teamId && teamId !== '') {
				const teamName = projectWizard.currentProject.team.TeamName
				const newRecipientList = []
				projectWizard.currentProject.team.TeamMembers.forEach((currentUser: UserDetail) => {
					newRecipientList.push(currentUser.Email)
				})
				const url = `/pls/teams/teamId/${teamId}`
				newRecipientList.push(user.Email)
				return apiClient
					.put(url, {
						teamName: teamName,
						teamMembers: newRecipientList
					})
					.then((response) => {
						if (response.status === 200) {
							dispatch(addTeamMemberAction(user))
						}
						return new Promise<void>((resolve) => resolve())
					})
					.catch((error) => {
						console.error(error)
					})
			} else {
				return new Promise<void>((resolve) => resolve())
			}
		} else {
			dispatch(addTeamMemberAction(user))
			return new Promise<void>((resolve) => {
				resolve()
			})
		}
	}
