import { FC, MouseEvent, PropsWithChildren, useEffect, useState } from 'react'
import { Icon } from '..'
import styles from './dots.module.scss'

export interface customColors {
	selected?: string
	notSelected?: string
}

export interface IDotsProps {
	numberDots: number
	onChangeDot(idx: number): void
	selectedRadio: number
	testId: string
	colors?: customColors
}
export const Dots: FC<PropsWithChildren<IDotsProps>> = ({
	numberDots,
	onChangeDot,
	selectedRadio,
	testId,
	colors = { selected: 'ColorBluePrimary', notSelected: 'ColorSlateLight' }
}: PropsWithChildren<IDotsProps>) => {
	const onChange = (evt: MouseEvent<HTMLDivElement>) => {
		const idx = parseInt(evt.currentTarget.id.replace('dot', ''))
		onChangeDot(idx)
	}
	const createDots = () => {
		const radioButtonsArray = []
		for (let i = 0; i < numberDots; i++) {
			radioButtonsArray.push(
				<div
					key={'dot' + i}
					id={'dot' + i}
					className={styles.dot}
					onClick={(evt) => onChange(evt)}
					data-testid={testId + '-' + i}
				>
					{selectedRadio === i ? (
						<Icon testId={`dots-${testId}`} type={'full-dot'} size="mini" color={colors.selected} />
					) : (
						<Icon
							testId={`emtpy-dots-${testId}`}
							type={'empty-dot'}
							size="mini"
							color={colors.notSelected}
						/>
					)}
				</div>
			)
		}
		return radioButtonsArray
	}
	const [dots, setDots] = useState([])

	useEffect(() => {
		setDots(createDots())
	}, [selectedRadio])

	return (
		<div data-testid={`dots-container-${testId}`} className={styles.containerDots}>
			{dots}
		</div>
	)
}

export default Dots
