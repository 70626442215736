import { DNBButton } from '@dnb-uux-design-system/react'
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined'
import { AxiosResponse } from 'axios'
import cx from 'classnames'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useApi } from '../../hooks'
import { useFeatures } from '../../hooks/useEntitlements'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { useRouteQuery } from '../../hooks/useRouteQuery'
import { useDataReport } from '../../queries/useDataReport'
import { RootState, useAppSelector } from '../../store'
import { GetMatchRulesResponse, MatchRule, MatchStyle, Upload } from '../../types'
import { removeFileNameExtension } from '../../utilities'
import styles from './report.module.scss'
import { Appendix } from './sections/appendix/appendix'
import { ContactMatchSummary } from './sections/contact-match-summary/contact-match-summary'
import { Cover } from './sections/cover'
import { EmailVerification } from './sections/email-verification/email-verification'
import { GeographicalDistribution } from './sections/geographical-distribution'
import { InputPresence } from './sections/input-presence'
import { MatchSummary } from './sections/match-summary'
import { ParallelPassMatching } from './sections/parallel-pass-matching/parallel-pass-matching'
import { SinglePassMatching } from './sections/single-pass-matching/single-pass-matching'
import { getTenantDisplayName } from './sections/utils/getTenantDisplayName'

interface ReportPageProps {
	children: ReactElement
}

const ReportPage = ({ children }: ReportPageProps) => {
	return <div className={styles.page}>{children}</div>
}

const Report = (): ReactElement => {
	const { t } = useTranslation()
	const apiClient = useApi()
	const history = useHistory()
	const query = useRouteQuery()
	const selectSession = (state: RootState) => state.session
	const session = useAppSelector(selectSession)
	const currentTenant = session.tenant
	const tenant = query.get('tenant') || ''
	const uploadId = query.get('uploadId') || ''

	const [upload, setUpload] = useState<Upload | null>(null)
	const [matchRules, setMatchRules] = useState<Array<MatchRule>>([])
	const [useMockData] = useLocalStorage('useMockData', 'false')
	const [fileName, setFileName] = useState('')
	const EnableFileLevelDashboardDownload = useFeatures(['EnableFileLevelDashboardDownload'])
	const isReportAvailable = EnableFileLevelDashboardDownload && tenant === currentTenant
	const tenantName = getTenantDisplayName(currentTenant, session.tenants)
	const uploadReport = useDataReport('Upload', uploadId, useMockData).data ?? null
	const uploadURL = `/pls/uploads/uploadId/${uploadId}`

	useEffect(() => {
		const dcpFooter = document.querySelector("div[class^='_dcpAppFooterContainer']") as HTMLDivElement
		window.scrollTo(0, 0)

		if (dcpFooter) {
			dcpFooter.style.display = 'none'
		}

		if (isReportAvailable) {
			apiClient
				.get(uploadURL)
				.then((response) => {
					setUpload(response.data)
					setFileName(removeFileNameExtension(response.data.displayName))
				})
				.catch((error) => {
					console.error(error)
				})
		} else {
			history.push('/dashboard/overview')
		}

		return () => {
			if (dcpFooter) {
				dcpFooter.style.display = 'flex'
			}
		}
	}, [])

	useEffect(() => {
		const sourceId = upload?.sourceId ?? null

		if (sourceId) {
			const url = `/pls/matchrules/sourceId/${sourceId}?includeArchived=false&includeInactive=false`

			apiClient
				.get(url)
				.then((response: AxiosResponse<Array<GetMatchRulesResponse>>) => {
					setMatchRules(response.data)
				})
				.catch((error) => {
					console.error(error)
				})
		}

		// We want to update the hook only when the "upload" prop changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [upload])

	const printReport = () => {
		window.print()
	}

	return (
		<div id={'printable-report'} className={styles.reportContent}>
			<div className={styles.header}>
				<div className={styles.buttonContainer}>
					<DNBButton
						id="print-button"
						onClick={printReport}
						size="default"
						startIcon={<PrintOutlinedIcon />}
						fullWidth
					>
						{t('report.header.button.print')}
					</DNBButton>
				</div>
			</div>

			<Cover fileName={fileName} upload={upload} tenant={tenantName} />
			<ReportPage>
				<div
					className={cx(styles.firstPage, {
						[styles.longFileName]: fileName.length > 76
					})}
				>
					{fileName.length > 0 && <span className={styles.fileName}>{fileName}</span>}
					<div className={cx(styles.tilesContainer, styles.topLevelTiles)}>
						<InputPresence fileName={fileName} uploadReport={uploadReport} />
						<MatchSummary uploadReport={uploadReport} />
					</div>
				</div>
			</ReportPage>
			<ReportPage>
				<div className={styles.tilesContainer}>
					{matchRules.find((matchRule) => matchRule.matchStyle === MatchStyle.MATCH_QUALITY) ? (
						<SinglePassMatching uploadReport={uploadReport} />
					) : (
						<ParallelPassMatching uploadReport={uploadReport} matchRules={matchRules} />
					)}
				</div>
			</ReportPage>
			<ReportPage>
				<GeographicalDistribution uploadReport={uploadReport} />
			</ReportPage>
			<ReportPage>
				<ContactMatchSummary uploadReport={uploadReport} />
			</ReportPage>
			<ReportPage>
				<EmailVerification uploadReport={uploadReport} />
			</ReportPage>
			<Appendix uploadReport={uploadReport} matchRules={matchRules} />
		</div>
	)
}

export { Report, ReportPage }
