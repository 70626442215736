import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { getCrmAvailableSourceTypes } from './api/getCrmAvailableSourceTypes'

interface AvailableSourceTypesParams {
	platformName: string
	orgId: string
	sourceId: string
	matchType: string
}

export const useCrmAvailableSourceTypes = (
	platformName: string,
	orgId: string,
	sourceId: string,
	matchType: string,
	enabled: boolean
): UseQueryResult<Array<string>> => {
	const apiClient = useApi()

	const queryKey = ['getCrmAvailableSourceTypes', { platformName, orgId, sourceId, matchType }]
	const queryFunction = () => getCrmAvailableSourceTypes(apiClient, platformName, orgId, matchType)
	return useQuery(queryKey, queryFunction, {
		enabled: enabled
	})
}

export const useMutationCrmAvailableSourceTypes = (): UseMutationResult<
	string[],
	unknown,
	AvailableSourceTypesParams,
	unknown
> => {
	const apiClient = useApi()

	const mutationFn = ({ platformName, orgId, matchType }: AvailableSourceTypesParams) =>
		getCrmAvailableSourceTypes(apiClient, platformName, orgId, matchType)

	return useMutation(mutationFn)
}
