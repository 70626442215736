import { ColorBlueBrandLight, ColorSlateLighter } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { Divider } from '../../local-core-ui'
import { AdjustableProgressVisual } from '../adjustable-progress-visual/adjustable-progress-visual'
import styles from './collapsable-dropdown-collection-header.module.scss'

export interface CollapsableDropdownCollectionHeaderProps {
	title: string
	collapsed: boolean
	onToggleCollapsed: () => void
	totalRequired?: number
	satisfiedRequirements?: number
	someFieldEdited?: boolean
	testId: string
}
export const CollapsableDropdownCollectionHeader = ({
	title,
	collapsed,
	onToggleCollapsed,
	totalRequired = 0,
	satisfiedRequirements = 0,
	someFieldEdited = false,
	testId
}: CollapsableDropdownCollectionHeaderProps) => {
	return (
		<>
			<Divider size="medium" color={someFieldEdited ? ColorBlueBrandLight : ColorSlateLighter} />
			<div onClick={onToggleCollapsed} className={styles.titleSection} data-testid={testId + '-cddc-title'}>
				<div data-testid={`${testId}-title`} className={styles.titleText}>
					{title}
				</div>
				{totalRequired > 0 && (
					<AdjustableProgressVisual
						totalRequirements={totalRequired}
						satisfiedRequirements={satisfiedRequirements}
						progressBarWidth={180}
					/>
				)}
				<div className={collapsed ? styles.rightArrow : styles.bottomArrow} />
			</div>
		</>
	)
}
