import { RootState, SessionState, useAppSelector } from '../store'

export interface AvailableRights {
	entitlements?: Record<string, unknown>[]
	features?: Record<string, unknown>[]
}

export const useEntitlementsFeatures = (entitlements?: string[], features?: string[]): AvailableRights => {
	const session = (state: RootState) => state.session
	const sessionState: SessionState = useAppSelector(session)
	const userEntitlements = Object.keys(sessionState.entitlements)
	const tenantFeatures = Object.keys(sessionState.featureFlags)
	const matchedAvailableRights: AvailableRights = {}
	if (entitlements && entitlements.length > 0) {
		matchedAvailableRights.entitlements = userEntitlements
			.filter((entitlement) => {
				return entitlements.indexOf(entitlement) > -1
			})
			.map((entitlement) => {
				return { entitlement, permission: sessionState.entitlements[entitlement] }
			})
	}
	if (features && features.length > 0) {
		matchedAvailableRights.features = tenantFeatures
			.filter((feature) => {
				return features.indexOf(feature) > -1
			})
			.map((feature) => {
				return { feature, permission: sessionState.featureFlags[feature] }
			})
	}

	return matchedAvailableRights
}

export const useEntitlement = (entitlement: string, actions?: string[]): boolean => {
	const session = (state: RootState) => state.session
	const sessionState: SessionState = useAppSelector(session)
	if (!entitlement) {
		return true
	}
	const userEntitlements = Object.keys(sessionState.entitlements)
	const availableRights = userEntitlements.indexOf(entitlement) > -1
	if (availableRights && actions) {
		return actions.reduce((a, c: string) => {
			const entitlementActions = sessionState.entitlements[entitlement]
			return a && c && entitlementActions[c]
		}, true)
	}
	return availableRights
}

export const useFeatures = (features?: string[]): boolean => {
	const session = (state: RootState) => state.session
	const sessionState: SessionState = useAppSelector(session)
	const tenantFeatures = Object.entries(sessionState.featureFlags)
	return features && features.length > 0
		? tenantFeatures.some(([feature, flag]) => features?.includes(feature) && (typeof flag !== 'boolean' || flag))
		: true
}

export const useModules = (modules?: string[]): boolean => {
	const session = (state: RootState) => state.session
	const sessionState: SessionState = useAppSelector(session)
	const tenantModules = sessionState.moduleFlags || []
	return modules && modules.length > 0 ? tenantModules.some((module: string) => modules.includes(module)) : true
}

export const useEnvironment = (environment?: string): boolean => {
	const session = (state: RootState) => state.session
	const sessionState: SessionState = useAppSelector(session)
	return environment === sessionState.environment
}

export const usePlatform = (platform?: string): boolean => {
	const session = (state: RootState) => state.session
	const sessionState: SessionState = useAppSelector(session)
	return platform === sessionState.platform
}

export const useAccessLevel = (accessLevel?: string[]): boolean => {
	const session = (state: RootState) => state.session
	const sessionState: SessionState = useAppSelector(session)
	return accessLevel && accessLevel.length > 0 ? accessLevel.includes(sessionState.user?.AccessLevel ?? '') : true
}
