import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { useApi } from '../hooks'
import { startRematch } from './api/startRematch'

export const useStartRematch = (sourceId: string): UseMutationResult<void, unknown, void, unknown> => {
	const apiClient = useApi()
	const queryClient = useQueryClient()

	const mutationFn = () => startRematch(apiClient, sourceId)

	return useMutation(mutationFn, {
		onSuccess: () => {
			queryClient.invalidateQueries(['getSources', { sourceId, inflateMetadata: true }])
		}
	})
}
