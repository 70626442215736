import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { numberDelimeters } from '../../helpers'
import { Icon } from '../../local-core-ui'
import { AssignedToWithName } from '../assignments-manager/assignments-manager-list'
import styles from './assignments-to-tile.module.scss'

interface AssignmentsToDetailProps {
	isSelected: boolean
	assigneeSummary: AssignedToWithName
}

export const AssignmentsToDetail = ({ isSelected, assigneeSummary }: AssignmentsToDetailProps): ReactElement => {
	const { t } = useTranslation()
	const { high, medium, low } = assigneeSummary

	const isHighDisabled = high === 0
	const isMediumDisabled = medium === 0
	const isLowDisabled = low === 0

	return (
		<div className={styles.assignmentsToTileBody}>
			<div className={styles.assignmentsToTileDataContainer}>
				<div
					className={`${styles.assignmentsToTilePriorityData}  ${
						isHighDisabled ? styles.assignmentsToTilePriorityDisabled : ''
					}`}
				>
					<div className={styles.assignmentsToTilePriorityContainer}>
						<Icon
							testId="high-priority-assignment-to-detail"
							type={isSelected ? 'high-priority-circle' : 'high-priority-bg'}
							size="mini"
						/>
						<span
							className={`${styles.assignmentsToTilePriorityLvl} ${
								isSelected ? styles.assignmentsToTileSelectedTxt : ''
							}`}
						>
							{t('assignments.to.tile.high.priority')}
						</span>
					</div>
					<span
						className={`${styles.assignmentsToTileCount} ${
							isSelected ? styles.assignmentsToTileSelectedTxt : ''
						}`}
					>
						{numberDelimeters(high)}
					</span>
				</div>
				<div
					className={`${styles.assignmentsToTilePriorityData} ${
						isMediumDisabled ? styles.assignmentsToTilePriorityDisabled : ''
					}`}
				>
					<div className={styles.assignmentsToTilePriorityContainer}>
						<Icon
							testId="medium-priority-assignments-to-detail"
							type={isSelected ? 'medium-priority-circle' : 'medium-priority-bg'}
							size="mini"
						/>
						<span
							className={`${styles.assignmentsToTilePriorityLvl} ${
								isSelected ? styles.assignmentsToTileSelectedTxt : ''
							}`}
						>
							{t('assignments.to.tile.medium.priority')}
						</span>
					</div>
					<span
						className={`${styles.assignmentsToTileCount} ${
							isSelected ? styles.assignmentsToTileSelectedTxt : ''
						}`}
					>
						{numberDelimeters(medium)}
					</span>
				</div>
				<div
					className={`${styles.assignmentsToTilePriorityData} ${
						isLowDisabled ? styles.assignmentsToTilePriorityDisabled : ''
					}`}
				>
					<div className={styles.assignmentsToTilePriorityContainer}>
						<Icon
							testId="low-priority-assignments-to-detail"
							type={isSelected ? 'low-priority-circle' : 'low-priority-bg'}
							size="mini"
						/>
						<span
							className={`${styles.assignmentsToTilePriorityLvl} ${
								isSelected ? styles.assignmentsToTileSelectedTxt : ''
							}`}
						>
							{t('assignments.to.tile.low.priority')}
						</span>
					</div>
					<span
						className={`${styles.assignmentsToTileCount} ${
							isSelected ? styles.assignmentsToTileSelectedTxt : ''
						}`}
					>
						{numberDelimeters(low)}
					</span>
				</div>
			</div>
		</div>
	)
}
