import { AxiosInstance, AxiosResponse } from 'axios'
import ProjectUIFacade from '../../dashboard/ProjectUIFacade'
import { Project, ProjectListResponse } from '../../types'

export const getProjects = async (apiClient: AxiosInstance): Promise<ProjectUIFacade[]> => {
	let allProjectsReceived = false
	const projectList: Project[] = []
	let pageIdx = 1
	while (!allProjectsReceived) {
		const url = `/pls/projects/list?pageSize=500&pageIndex=${pageIdx}&includeArchived=true`
		await apiClient
			.get(url)
			.then((response: AxiosResponse<ProjectListResponse>) => {
				const projects: Project[] = response.data
				if (projects.length === 0) {
					allProjectsReceived = true
				} else {
					projectList.push(...projects)
					if (projects.length < 500) {
						allProjectsReceived = true
					} else {
						pageIdx++
					}
				}
			})
			.catch(() => {
				allProjectsReceived = true
			})
	}

	return projectList.map((project) => {
		return {
			id: project.projectId,
			name: project.projectDisplayName,
			modifiedOn: new Date(project.updated),
			createdOn: new Date(project.created),
			...project
		} as ProjectUIFacade
	})
}
