import { DNBButton } from '@dnb-uux-design-system/react'
import AddIcon from '@mui/icons-material/Add'
import { MouseEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { DCPLogo } from '../components/dcp-logo/dcp-logo'
import { LanguageSelector } from '../components/language-selector/language-selector'
import { SwitchRoutes } from '../components/switch-routes/switch-routes'
import { UserMenu } from '../components/user-menu/user-menu'
import { DynamicWizardContext } from '../components/wizardComposer/helpers/DynamicWizardContext'
import { useWizardContext } from '../components/wizardComposer/hooks/useWizardContext'
import { setDynamicWizardContext } from '../components/wizardComposer/store/wizardSlice'
import { useSession } from '../hooks'
import { useFeatures, useModules, usePlatform } from '../hooks/useEntitlements'
import { AppBar } from '../local-core-ui'
import { useAppDispatch } from '../store'
import { newProjectAction } from '../store/projectWizard/actions'
import DashboardRoutes from './dashboard-routes'
import styles from './dashboard.module.scss'
import { TabsDashboard } from './tabsDashboard/tabsDashboard'

export function Dashboard(): ReactElement {
	const { wizardContext } = useWizardContext()
	const history = useHistory()
	const location = useLocation()
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const [logOutService] = useSession(dispatch)
	const [source, setSource] = useState('overview')
	const isAPIEnabled = useFeatures(['EnableAPI'])
	const isC4S = useModules(['C4S'])
	const isSalesforce = usePlatform('salesforce')

	useEffect(() => {
		let mounted = true
		if (location.pathname.includes('projects')) {
			wizardContext.dispatch(setDynamicWizardContext(new DynamicWizardContext(false, false)))
			if (mounted) setSource('projects')
		} else {
			if (mounted) setSource('overview')
		}
		return () => {
			mounted = false
		}
	}, [location.pathname])

	const onCreateProjectHandler = (e: MouseEvent) => {
		e.preventDefault()
		dispatch(newProjectAction(isAPIEnabled, isC4S && isSalesforce))
		history.push('/steps/project-wizard/project', { source: source })
	}

	const onLogOut = () => {
		logOutService()
	}

	return (
		<div className={styles.dashboardBar}>
			<AppBar className="fixed">
				<div className={styles.barContainer}>
					<span className={styles.logoContainer}>
						<DCPLogo />
					</span>
					<TabsDashboard />
					<span className={styles.leftSection}>
						<span className={styles.centered}>
							<div className={styles.containerOptionsBar}>
								<div className={styles.optionUser}>
									<div className={styles.menuContainer}>
										<LanguageSelector />
										<UserMenu onSignOut={onLogOut} testId="dcp-app-bar-user-menu" />
									</div>
								</div>
							</div>
							<div className={styles.divider} />
							<span className={styles.leftItem}>
								<DNBButton
									size="small"
									variant="primary"
									onClick={onCreateProjectHandler}
									data-testid="create-project"
									startIcon={<AddIcon />}
								>
									{t('createNewProject')}
								</DNBButton>
							</span>
						</span>
					</span>
				</div>
			</AppBar>
			<SwitchRoutes routes={DashboardRoutes} />
		</div>
	)
}
