import { AxiosInstance, AxiosResponse } from 'axios'
import ProjectUIFacade from '../../dashboard/ProjectUIFacade'
import { GetProjectResponse, Team } from '../../types'

export const getProject = async (apiClient: AxiosInstance, projectId: string): Promise<ProjectUIFacade> => {
	const url = `/pls/projects/projectId/${projectId}?includeSources=true&includeArchived=true`
	const projResponse: AxiosResponse<GetProjectResponse> = await apiClient.get(url)
	const teamUrl = `/pls/teams/teamId/${projResponse.data.teamId}`
	const team: AxiosResponse<Team> = await apiClient.get(teamUrl)

	return {
		id: projResponse.data.projectId,
		name: projResponse.data.projectDisplayName,
		modifiedOn: new Date(projResponse.data.updated),
		createdOn: new Date(projResponse.data.created),
		collapsed: true,
		team: team.data,
		uploadIds: [],
		...projResponse.data
	} as ProjectUIFacade
}
