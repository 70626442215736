/**
 * @class Textarea
 */

import cx from 'classnames'
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import styles from './textarea.module.scss'

export interface ITextAreaProps {
	id: string
	placeholder?: string
	value: string
	label: string
	autoGrowth?: boolean
	maxLines?: number
	maxLength?: number
	displayRemainingCharacters?: boolean
	remainingCharactersText?: string
	disabled?: boolean
	size?: 'mini' | 'small' | 'medium' | 'large' | 'fluid'
	onChange(event: ChangeEvent<HTMLTextAreaElement>): void
	testId: string
}

export const Textarea: FC<ITextAreaProps> = ({
	id,
	placeholder,
	value,
	label,
	autoGrowth = true,
	maxLines,
	maxLength,
	displayRemainingCharacters = false,
	remainingCharactersText,
	disabled = false,
	size = 'small',
	onChange,
	testId
}: ITextAreaProps) => {
	const elementId = id + '-ta'

	const [classAutogrowth, setClassAutogrowth] = useState(styles.autogrowth)
	const textareaRef = useRef(null)
	const remainingCharacters = maxLength - (value?.length | 0)
	const autoExpand = (element: HTMLElement): void => {
		if (autoGrowth) {
			const computed: CSSStyleDeclaration = window.getComputedStyle(element)
			const lineHeight: string = computed.getPropertyValue('line-height')
			let heightLimit: number

			element.style.height = lineHeight

			let newHeight = element.scrollHeight

			if (maxLines) {
				heightLimit = parseInt(lineHeight, 10) * maxLines

				if (newHeight > heightLimit) {
					newHeight = heightLimit
					setClassAutogrowth(styles.autogrowthMaxlines)
				} else {
					setClassAutogrowth(styles.autogrowth)
				}
			}
			element.style.height = newHeight + 'px'
		} else {
			setClassAutogrowth(styles.noAutogrowth)
		}
	}

	const resizeIE = (event) => {
		const { current } = textareaRef
		if (navigator.userAgent.indexOf('Trident/7.0') !== -1) {
			current.classList.add(styles.paddingIE)
			if ((current.value === '' || current.value === null) && event.keyCode === 8) {
				current.classList.remove(styles.paddingIE)
			}
		}
	}

	const onChangeTextarea = (event) => {
		onChange(event)
	}

	useEffect(() => {
		const { current } = textareaRef
		current?.addEventListener('input', autoExpand(current))
		return () => {
			current.removeEventListener('input', autoExpand(current))
		}
	}, [value, autoGrowth])

	return (
		<div
			data-testid={`text-area-container-${testId}`}
			className={size === 'fluid' ? cx(styles.textarea, styles.fluid) : styles.textarea}
			id={id}
		>
			<label data-testid={`text-area-label-${testId}`} htmlFor={elementId} className={styles.labelInput}>
				{label}
			</label>
			<textarea
				id={elementId}
				name={elementId}
				placeholder={placeholder}
				value={value}
				className={cx(
					styles.common,
					styles[size],
					`${disabled ? styles.disabled : styles.defaultStyle}`,
					classAutogrowth
				)}
				onChange={(e) => onChangeTextarea(e)}
				onKeyUp={resizeIE}
				maxLength={maxLength}
				disabled={disabled}
				ref={textareaRef}
				data-testid={testId}
			/>
			{displayRemainingCharacters && maxLength && (
				<div
					data-testid={`text-area-remaining-${testId}`}
					className={styles.remainingCharacters}
				>{`${remainingCharacters} ${remainingCharactersText ? remainingCharactersText : ''}`}</div>
			)}
		</div>
	)
}

export default Textarea
