import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { getMatchedRecordSummary, MatchedRecordSummary } from './api/getMatchedRecordSummary'

export const useMatchedRecordSummary = (
	sourceId?: string,
	isC4SPlatform?: boolean
): UseQueryResult<MatchedRecordSummary> => {
	const apiClient = useApi()

	const queryKey = ['getMatchedRecordSummary', { sourceId }]
	const queryFunction = () => getMatchedRecordSummary(apiClient, sourceId, isC4SPlatform)

	return useQuery(queryKey, queryFunction)
}
