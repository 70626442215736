import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { SignOutReason } from '../store'
import { ApiErrorState, setApiErrorAction } from '../store/apiError'

export function getApiClient(
	signOutSession: ((reason?: SignOutReason) => void) | null = null,
	dispatch?: Dispatch
): AxiosInstance {
	const apiClient: AxiosInstance = axios.create()

	//initialize get if not there
	if (!apiClient.defaults.headers.get) {
		apiClient.defaults.headers.get = {}
	}

	//disable IE ajax request caching
	apiClient.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT'
	apiClient.defaults.headers.get['Cache-Control'] = 'no-cache'
	apiClient.defaults.headers.get['Pragma'] = 'no-cache'

	const errorHandler = (error: AxiosError) => {
		// Handle errors
		console.error(error)

		// if status is 401, clear token document
		const resp = error.response
		const isInDevSite =
			window.location.hostname.includes('localhost') || window.location.hostname.includes('connect-dev')
		if (resp && resp.status === 401) {
			console.warn('SessionInfo expired!')
			if (signOutSession) signOutSession(SignOutReason.backend)
		} else if (isInDevSite && dispatch) {
			const responseData = resp?.data
			const previousURL = window.location.href
			const requestURL = resp?.config?.url || error.config?.url
			const URLIdaasMatch = requestURL?.match(/idaas/g)
			let additionalInfo = ''
			const errorMessagesMap = new Map<string, string>([
				['LEDP_61008', 'error.message.modal.error.orgID.message']
			])
			const errorMessage = errorMessagesMap.get(responseData?.errorCode)
			if (responseData?.errorCode === 'LEDP_61008') {
				additionalInfo = responseData?.errorCode
			}
			const errorCode = responseData?.errorCode
			const message = errorMessage
			const requestPayload = URLIdaasMatch && URLIdaasMatch.length > 0 ? '' : JSON.stringify(resp?.config?.data)
			const response = responseData?.title || responseData?.errorMsg || JSON.stringify(responseData) || error
			const errorData: ApiErrorState = {
				previousURL,
				requestURL,
				requestPayload,
				response,
				message,
				additionalInfo,
				errorCode
			}
			const errorCodeDiffEmailsDomains = 'LEDP_18271'
			const errorCodeNotPrimaryDomain = 'LEDP_18275'
			const errorPackageVersion = 'LEDP_90003'

			//Condition to customError for addUser. Show more DCP-2175 and DCP-1414
			if (
				errorCode !== errorCodeDiffEmailsDomains &&
				errorCode !== errorPackageVersion &&
				errorCode !== errorCodeNotPrimaryDomain
			) {
				//TODO Review this condition of this dispatch in the future
				if (errorMessage !== undefined) {
					dispatch(setApiErrorAction(errorData))
				}
				dispatch(setApiErrorAction(errorData))
			}
		} else {
			const responseData = resp?.data
			const errorCode = responseData?.errorCode
			const errorCodeDiffEmailsDomains = 'LEDP_18271'
			const errorCodeNotPrimaryDomain = 'LEDP_18275'
			const errorPackageVersion = 'LEDP_90003'
			//TODO This condition will be removed/modified according to the TECHDEBT resultsDCP-2590
			if (
				errorCode !== errorCodeDiffEmailsDomains &&
				errorCode !== errorPackageVersion &&
				errorCode !== errorCodeNotPrimaryDomain
			) {
				alert(JSON.stringify(resp?.data) || error)
			}
		}

		return Promise.reject({
			...error
		})
	}

	const successHandler = (response: AxiosResponse) => {
		return response
	}

	apiClient.interceptors.response.use(
		(response) => successHandler(response),
		(error) => errorHandler(error)
	)

	return apiClient
}
