import { ColorGrayDark, ColorOrangeBurnt } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon, ToolTip } from '../../local-core-ui'
import { Indicator } from './record-block'
import styles from './record-compare-card.module.scss'

interface RecordHeaderProps {
	businessName?: string
	tradeStyle?: string
	comparisonBusinessName?: string
	comparisonTradeStyle?: string
	showIndicator: boolean
	showIcon: boolean
	iconLabel: string
}

export const RecordHeader = ({
	businessName,
	tradeStyle,
	comparisonBusinessName,
	comparisonTradeStyle,
	showIndicator,
	showIcon,
	iconLabel
}: RecordHeaderProps): ReactElement => {
	const emptyField = <span className={styles.emptyField}>&#9866;&#9866;&#9866;</span>
	const regExp = /[\s]+/
	const { t } = useTranslation()
	const hasNoComparisonField = (field?: string): boolean => {
		return field === undefined || field === null || field?.length === 0
	}
	const hasField = (field?: string): boolean => {
		return field !== undefined && field !== null && field?.length > 0
	}

	const isExtraName = hasNoComparisonField(comparisonBusinessName) && hasField(businessName)

	const isExtraTradeStyle = hasNoComparisonField(comparisonTradeStyle) && hasField(tradeStyle)

	const wordExists = (sentence: string, word: string): boolean => {
		const wordsInSentence = sentence?.toLowerCase().split(regExp) || []
		return wordsInSentence.includes(word)
	}

	const compareStrings = (sentence: string, comparison: string): string[] => {
		const detailWords = sentence?.toLowerCase().split(regExp)
		return detailWords.reduce((differences, word) => {
			const nonExistingWords: string[] = []
			if (!wordExists(comparison, word)) {
				nonExistingWords.push(word)
			}
			return [...differences, ...nonExistingWords]
		}, [] as string[])
	}

	const businessNameDiffWords =
		businessName && comparisonBusinessName ? compareStrings(businessName, comparisonBusinessName) : []
	const areBusinessNameDiffs = businessNameDiffWords?.length !== 0

	const tradeStyleDiffWords =
		tradeStyle && comparisonTradeStyle ? compareStrings(tradeStyle, comparisonTradeStyle) : []
	const areTradeStyleDiffs = tradeStyleDiffWords?.length !== 0

	const [showBusinessTooltip, setShowBusinessTooltip] = useState<boolean>(false)
	const businessNameContainer = useRef<HTMLParagraphElement>(null)

	useEffect(() => {
		if (businessNameContainer.current) {
			const hasXScroll = businessNameContainer.current.scrollWidth > businessNameContainer.current.offsetWidth
			if (hasXScroll) {
				setShowBusinessTooltip(true)
			} else if (showBusinessTooltip) {
				setShowBusinessTooltip(false)
			}
		}
		/**
		 * We only want to run this effect when businessName prop changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [businessName])

	return (
		<div className={styles.recordHeader}>
			{showIcon && (
				<div className={styles.recordBlockIconContainer}>
					<Icon testId="business-record-header" type="business" color={ColorGrayDark} title={t(iconLabel)} />
				</div>
			)}
			<div>
				<div className={styles.recordDataContainer}>
					{showIndicator && <Indicator areDiffs={areBusinessNameDiffs} isExtraDetail={isExtraName} />}
					<ToolTip
						effect="float"
						position="right"
						testId={businessName + '-tooltip'}
						customContent={
							businessName && showBusinessTooltip ? (
								<p
									className={`${styles.recordDetailTooltipContainer} ${styles.wordSpacing} ${
										styles.wrapText
									} ${isExtraName && showIndicator ? styles.greenTxt : ''}`}
								>
									<BusinessName
										businessName={businessName}
										businessNameDiffWords={businessNameDiffWords}
										showIndicator={showIndicator}
									/>
								</p>
							) : undefined
						}
					>
						<p
							ref={businessNameContainer}
							className={`${styles.recordCompany} ${isExtraName && showIndicator ? styles.greenTxt : ''}`}
						>
							{businessName ? (
								<BusinessName
									businessName={businessName}
									businessNameDiffWords={businessNameDiffWords}
									showIndicator={showIndicator}
								/>
							) : (
								emptyField
							)}
						</p>
					</ToolTip>
				</div>
				<div className={styles.recordDataContainer}>
					{showIndicator && <Indicator areDiffs={areTradeStyleDiffs} isExtraDetail={isExtraTradeStyle} />}
					<p className={styles.recordTradeStyleContainer}>
						{t('card.compare.record.trade.style')}
						<span
							className={`${styles.recordTradeStyle} ${
								isExtraTradeStyle && showIndicator ? styles.greenTxt : ''
							}`}
							title={tradeStyle}
						>
							{tradeStyle
								? tradeStyle.split(' ').map((word, idx) => {
										const isADiffWord = tradeStyleDiffWords.includes(word.toLowerCase())
										const diffWordColor = isADiffWord && showIndicator ? ColorOrangeBurnt : ''
										return (
											<span
												key={`${tradeStyle}-word-${word}-idx-${idx}`}
												style={{ color: diffWordColor }}
												className={styles.recordTradeStyleWord}
											>
												{word}
											</span>
										)
								  })
								: emptyField}
						</span>
					</p>
				</div>
			</div>
		</div>
	)
}

interface BusinessNameProps {
	businessName: string
	businessNameDiffWords: string[]
	showIndicator: boolean
}

const BusinessName = ({ businessName, businessNameDiffWords, showIndicator }: BusinessNameProps) => {
	return (
		<>
			{businessName.split(' ').map((word, idx) => {
				const isADiffWord = businessNameDiffWords.includes(word.toLowerCase())
				const diffWordColor = isADiffWord && showIndicator ? ColorOrangeBurnt : ''
				return (
					<span
						key={`${businessName}-word-${word}-idx-${idx}`}
						className={styles.recordCompanyWord}
						style={{ color: diffWordColor }}
					>
						{word}
					</span>
				)
			})}
		</>
	)
}
