import { FC } from 'react'
import { useWizardContext } from '../../../components/wizardComposer/hooks/useWizardContext'
import { useWizardRouteMatch } from '../../../components/wizardComposer/hooks/useWizardRouteMatch'
import { clearEnrichingApproach } from '../../../store/projectWizard/actions/clearEnrichingApproach'
import { SelectYourData } from './select-your-data/select-your-data'
import { TemplatePicker } from './template-picker/template-picker'

export const WizardComposerChooseFields: FC = () => {
	const { previous } = useWizardRouteMatch()
	const { wizardContext } = useWizardContext()
	return (
		<>
			{wizardContext.projectWizardState.currentProject.enrichingApproach === 'saved-template' &&
			wizardContext.projectWizardState.currentProject.currentTemplate === undefined ? (
				<TemplatePicker
					onClose={previous}
					onTemplatesClear={() => {
						wizardContext.dispatch(clearEnrichingApproach())
						previous().then()
					}}
				/>
			) : (
				<SelectYourData />
			)}
		</>
	)
}
