import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { ConnectionSource, getConnectionSources } from './api/getConnectionSources'

export const useConnectionSources = (connectionName: string): UseQueryResult<ConnectionSource[]> => {
	const apiClient = useApi()
	const queryKey = ['getConnectionSources', connectionName]
	const queryFunction = () => getConnectionSources(apiClient, connectionName)

	return useQuery(queryKey, queryFunction)
}
