import { ReactElement } from 'react'
import { InformationSubSection } from '../information-sub-section/information-sub-section'
import styles from './information-section.module.scss'

export interface FieldInformation {
	field: string
	information: string
}

export interface IInformationSectionProps {
	fieldsInformation: Array<FieldInformation>
}

export const InformationSection = ({ fieldsInformation }: IInformationSectionProps): ReactElement => {
	const lengthFieldsInformation = fieldsInformation.length
	const classContainer = lengthFieldsInformation === 1 ? styles.uniqueItem : ''
	return (
		<div className={styles.containerCollectionInformationBar} data-testid="container-info-collection">
			{fieldsInformation.map((field, index) => {
				if (field.information !== '') {
					return (
						<div
							data-testid={'info-mapping-' + index}
							key={index}
							className={`${styles.flexContainer} ${classContainer}`}
						>
							<InformationSubSection
								key={index}
								uniqueItem={lengthFieldsInformation === 1}
								field={field}
							/>
							{lengthFieldsInformation > 1 && index != lengthFieldsInformation - 1 ? (
								<div className={styles.spaceRight} />
							) : null}
						</div>
					)
				}
			})}
		</div>
	)
}
