import { DNBTypography } from '@dnb-uux-design-system/react'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './manual-match-example.module.scss'

export const ManualMatchExample = (): ReactElement => {
	const { t } = useTranslation()
	const stringExample: Array<string> = 'A.ABAZ.FA.F'.split('')
	const fieldsName: Array<string> = [
		'business.name',
		'street.number',
		'street.name',
		'city',
		'state',
		'mailing.address',
		'telephone',
		'zip',
		'density',
		'uniqueness',
		'industrysic'
	]

	return (
		<div className={styles.manualMatchExample}>
			<div className={styles.containerExample}>
				<div className={styles.positionExample}>
					<div className={styles.mmeTitle}>
						<DNBTypography variant="compact-bold" display="inline" mr={2}>
							{t('manual.match.example')}
						</DNBTypography>
						<br />
						<DNBTypography variant="compact-body">{stringExample}</DNBTypography>
					</div>
					<div className={styles.mmeString}>
						{stringExample.map((letter: string, idx: number) => (
							<div className={styles.mmeLetter} key={`keyExample-${letter}-${idx}`}>
								{letter}
							</div>
						))}
					</div>
					<div className={styles.containermmeDescs}>
						<div className={styles.mmeDescs}>
							{fieldsName.map((field: string, idx: number) => (
								<div className={styles.mmeDescsReference} key={`container-name-${field}-${idx}`}>
									<div className={styles.hideText}>{t(`manual.match.example.${field}`)}</div>
									<div className={styles.mmeDesc}>
										<p
											className={styles.mmeDescCont}
											title={t(`manual.match.example.${field}`) as string}
										>
											{t(`manual.match.example.${field}`)}
										</p>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
