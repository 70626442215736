import { Button } from '@mui/material'
import { ChangeEvent, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColorGrayDark } from '../../../design-tokens/build/shared/token-colors.json'
import { SearchButtonNInput } from '../components/search-button-n-input/search-button-n-input'
import { Tile } from '../components/tile/tile'
import { Divider, Icon } from '../local-core-ui'
import { useComplianceCodes } from '../queries/useComplianceCodes'

import { Spinner } from '../components/spinner/spinner'
import { CodeList } from '../queries/api/getComplianceCodes'
import styles from './compliance-codes-page.module.scss'

type SortOrder = 'asc' | 'desc'

export function ComplianceCodesPage(): ReactElement {
	const { t } = useTranslation()
	const complianceData = useComplianceCodes()
	const { data: complianceCodes, isLoading } = complianceData
	const codeLists = complianceCodes?.codeTables?.flatMap((table) => table.codeLists) ?? []

	const [itemsPerPage, setItemsPerPage] = useState(10)
	const [currentPage, setCurrentPage] = useState(1)
	const startIndex = (currentPage - 1) * itemsPerPage
	const endIndex = startIndex + itemsPerPage

	const [sortColumn, setSortColumn] = useState<string>('code')
	const [sortOrder, setSortOrder] = useState<SortOrder>('asc')

	const [searchTerm, setSearchTerm] = useState<string>('') // Estado para la búsqueda

	const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
		setItemsPerPage(parseInt(event.target.value, 10))
		setCurrentPage(1)
	}

	const handlePrevPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1)
		}
	}

	const handleNextPage = () => {
		if (codeLists && currentPage * itemsPerPage < codeLists.length) {
			setCurrentPage(currentPage + 1)
		}
	}

	const handleSort = (column: string) => {
		if (sortColumn === column) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
		} else {
			setSortColumn(column)
			setSortOrder('asc')
		}
	}

	const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value
		setSearchTerm(inputValue)
		setCurrentPage(1)
	}

	const filteredData = codeLists.filter(
		(item) =>
			item.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item.description.toLowerCase().includes(searchTerm.toLowerCase())
	)

	const sortedData = [...filteredData].sort((a, b) => {
		const column = sortColumn as keyof CodeList
		const valueA = a[column]
		const valueB = b[column]

		if (typeof valueA === 'string' && typeof valueB === 'string') {
			return sortOrder === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA)
		}
		return 0
	})

	if (isLoading) {
		return (
			<div>
				<Spinner />
			</div>
		)
	}

	const displayedItems = sortedData.slice(startIndex, endIndex)

	return (
		<div className={styles.complianceContainer}>
			<Tile testId={'compliance-insights-tile'} className={styles.complianceTile}>
				<h1 data-testid={'compliance-title'} className={styles.complianceTitle}>
					{t('compliance.code.title')}
				</h1>
				<div className={styles.containerDescription}>
					<p className={styles.complianceDescriptionTitle}>{t('compliance.code.marketing')}</p>
					<p className={styles.complianceDescription}>{t('compliance.code.description')}</p>

					<SearchButtonNInput
						value={searchTerm} // El valor del campo de búsqueda
						onChangeFilter={(inputValue) =>
							handleSearchChange({ target: { value: inputValue } } as ChangeEvent<HTMLInputElement>)
						}
						hint={t('compliance.code.search')}
						type={1}
						testId={'connections-search-bar'}
					/>

					<div className={styles.connectionsContainer}>
						<section className={styles.tableContainer}>
							<div className={styles.divider} />
							<div className={styles.tableHeader}>
								<div className={`${styles.codeCol}`} onClick={() => handleSort('code')}>
									{t('compliance.code.code.field')}
									{sortOrder === 'asc' ? (
										<Icon testId="arrow-sort-down" type="arrow-sort-down" size="mini" />
									) : (
										<Icon testId="arrow-sort-up" type="arrow-sort-up" size="mini" />
									)}
								</div>
								<div className={`${styles.descriptionCol}`}>
									{t('compliance.code.description.field')}
								</div>
							</div>
							{displayedItems?.map((complianceData, index) => (
								<div key={index}>
									{index === 0 ? (
										<div className={styles.dividerG} />
									) : (
										<Divider color={ColorGrayDark} />
									)}

									<div className={styles.row}>
										<div className={`${styles.codeCol}`}>{complianceData.code}</div>
										<div className={`${styles.descriptionCol}`}>{complianceData.description}</div>
									</div>
								</div>
							))}
							<div className={styles.divider} />
						</section>
						<div className={styles.paginationContainer}>
							<div className={styles.itemsPerPage}>
								<label htmlFor="itemsPerPage">{t('connection.source.items.per.page')}</label>
								<select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange}>
									<option value={10}>10</option>
									<option value={20}>20</option>
									<option value={30}>30</option>
									<option value={40}>40</option>
									<option value={50}>50</option>
									<option value={100}>100</option>
								</select>
								<label>{`${startIndex + 1}-${Math.min(endIndex, filteredData.length)} ${t(
									'connection.source.of'
								)} ${filteredData.length} items`}</label>
								<div className={styles.paginationControls}>
									<div className={styles.button}>
										<Button onClick={handlePrevPage} disabled={currentPage === 1}>
											<Icon testId="chevron-left-source" type="chevron-left" size="mini" />{' '}
										</Button>
									</div>
									<div className={styles.button}>
										<Button
											onClick={handleNextPage}
											disabled={currentPage * itemsPerPage >= filteredData.length}
										>
											<Icon testId="chevron-right-source" type="chevron-right" size="mini" />{' '}
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Tile>
		</div>
	)
}
