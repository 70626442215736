import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { abbreviateNumber } from '../../../helpers'
import { BaseDonutChart } from '../../../local-core-ui'
import { GetMatchSummaryCodes } from '../../../types/match-recommendation/GetMatchRecommendationProfile'
import styles from '../../match-recommendation-charts/match-summary-code-report/match-summary-code-report.module.scss'

interface DonutChartSummaryCode {
	label: string
	value: number
	netTotal: string
	description?: string
}

interface ReportSummaryCode {
	code: string
	count: number
	rate: number
}

interface MatchSummaryCodeReportProps {
	title: string
	data: ReportSummaryCode[]
	matchCodes: GetMatchSummaryCodes
	showDividers?: boolean
	matchedCount: number
	colors: Array<string>
	testId: string
}
export const MatchSummaryCodeReport: FC<MatchSummaryCodeReportProps> = ({
	title,
	data,
	showDividers = false,
	matchedCount,
	colors,
	matchCodes,
	testId
}: MatchSummaryCodeReportProps) => {
	const { t } = useTranslation()

	matchCodes['No Match'] = 'No Match'
	const donutData: DonutChartSummaryCode[] = data.map((field: ReportSummaryCode) => {
		return {
			label: field.code,
			value: Math.round(field.count),
			netTotal: new Intl.NumberFormat().format(Math.round(field.rate)),
			description: matchCodes[field.code]
		} as DonutChartSummaryCode
	})

	const sortedDonutData = [...donutData].sort((a, b) => b.value - a.value)

	const precision = matchedCount > 1000 ? 1 : 0
	const abbreviateMatchedCount = abbreviateNumber(matchedCount, precision)

	return (
		<>
			<div className={styles.title}>{title}</div>
			<div className={styles.matchSummaryCodeReportContainer} data-testid={testId + 'mr-summary-code'}>
				<div className={`${styles.donutChartContainer}`} style={{ color: colors[0] }}>
					<div className={`${styles.titleContainer}`}>
						<div className={`${styles.titleNumber}`}>{abbreviateMatchedCount}</div>
						<div className={`${styles.titleString}`}>{t('input.presence.successfully.absorbed')}</div>
					</div>
					<BaseDonutChart
						colors={colors}
						testId={testId + '-ccMR'}
						data={sortedDonutData}
						showKeys={true}
						showDividers={showDividers}
						showTooltip={true}
						size="large"
						typeKeys={'extended'}
					/>
				</div>
			</div>
		</>
	)
}
