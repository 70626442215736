import { EnrichingBlock } from '../../../../store/projectWizard/types'
import { Element } from '../../../../types'

export const filterLayoutOfRemovedElements = (
	enrichingBlockList: Array<EnrichingBlock>,
	removedElements: Array<Element>
): Element[][] => {
	return enrichingBlockList
		.map((enrichingBlock) => {
			return enrichingBlock.elements.filter((element) => {
				return !removedElements.find((removedElement) => {
					return removedElement.elementId === element.elementId
				})
			})
		})
		.filter((enrichingBlock) => enrichingBlock.length)
}
