import { ColorBlueBrand } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { FooterButtons } from '../../../components/footer-buttons/footer-buttons'
import { MultiSelectOverviewCard } from '../../../components/multi-select-overview-card/multi-select-overview-card'
import { RecordComparisonCards } from '../../../components/record-comparison-cards/record-comparison-cards'
import { numberDelimeters } from '../../../helpers'
import { Icon } from '../../../local-core-ui'
import { RootState, useAppSelector } from '../../../store'
import { TileTypes } from '../../../store/steward/types'
import { InputFields, MatchCandidate } from '../../../types'
import styles from '../dashboard-steward.module.scss'
import { getIsAssignAvailable, getPrimaryBtn } from '../footer-buttons-conditions'

interface CardRightSideProps {
	isMultiSelectActive: boolean
	onMultiSelect(): void
	onTableView(): void
	assignmentName: string
	isStewardFilterTabsAvailable: boolean
	selectedTile: TileTypes
	onAssign(): void
}

export function CardRightSide({
	isMultiSelectActive,
	onMultiSelect,
	onTableView,
	assignmentName,
	isStewardFilterTabsAvailable,
	selectedTile,
	onAssign
}: CardRightSideProps): ReactElement {
	const selectSteward = (state: RootState) => state.steward
	const steward = useAppSelector(selectSteward)
	const { selectedRecords, requestedClassification } = steward

	const { t } = useTranslation()
	const selectedRecordsCount = selectedRecords.length

	const hasAddressLine = (recordFields: InputFields | MatchCandidate): boolean => {
		return (
			(recordFields.streetAddressLine1 !== null && recordFields.streetAddressLine1.length !== 0) ||
			(recordFields.streetAddressLine2 !== null && recordFields.streetAddressLine2.length !== 0)
		)
	}

	const hasZipOrCity = (recordFields: InputFields | MatchCandidate): boolean => {
		return (
			(recordFields.postalCode !== null && recordFields.postalCode.length !== 0) ||
			(recordFields.city !== null && recordFields.city.length !== 0)
		)
	}

	const hasState = (recordFields: InputFields | MatchCandidate): boolean => {
		return recordFields.state !== null && recordFields.state.length !== 0
	}

	const getHasAddress = (recordFields: InputFields | MatchCandidate): boolean => {
		return hasAddressLine(recordFields) || hasZipOrCity(recordFields) || hasState(recordFields)
	}

	const mapAddress = (recordFields: InputFields | MatchCandidate): string => {
		return `${recordFields.streetAddressLine1 || ''} ${recordFields.streetAddressLine2 || ''}${
			hasAddressLine(recordFields) && hasZipOrCity(recordFields) ? ',' : ''
		} ${recordFields.postalCode || ''} ${recordFields.city || ''}${hasState(recordFields) ? ',' : ''} ${
			recordFields.state || ''
		}`
	}

	const mapUserRecord = () => {
		const selectedRecord = selectedRecords[0]
		const inputFields = selectedRecord?.inputFields
		if (inputFields) {
			return {
				businessName: inputFields.companyName,
				tradeStyle: inputFields.tradestyle,
				address: getHasAddress(inputFields) ? mapAddress(inputFields) : '',
				country: inputFields.country,
				duns: inputFields.duns,
				uniqueId: inputFields.registrationNumber,
				phoneNumber: inputFields.phoneNumber,
				website: inputFields.website
			}
		}
		return {}
	}

	const mapDnBMatch = () => {
		const selectedRecord = selectedRecords[0]
		const matchCandidate = selectedRecord?.matchCandidate
		if (matchCandidate) {
			return {
				businessName: matchCandidate.companyName,
				tradeStyle: matchCandidate.tradestyle,
				address: getHasAddress(matchCandidate) ? mapAddress(matchCandidate) : '',
				country: matchCandidate.country,
				duns: matchCandidate.matchedDuns,
				phoneNumber: matchCandidate.phoneNumber,
				isPublic: matchCandidate.isPublic,
				isMarketable: matchCandidate.isMarketable,
				confidenceCode: matchCandidate.matchInsight.ConfidenceCode,
				matchGrade: matchCandidate.matchInsight.MatchGrade.rawCode,
				matchDataProfile: matchCandidate.matchInsight.MatchDataProfile.text
			}
		}
		return {}
	}

	return (
		<div className={styles.dashboardStewardRightSide}>
			<div
				className={`${styles.dashboardStewardRightSideHeader} ${
					isMultiSelectActive ? styles.dashboardStewardRightSideSpaceBetween : ''
				}`}
			>
				{isMultiSelectActive && (
					<span>
						{t('steward.card.view.multi.selection', {
							selectedRecordsCount: numberDelimeters(selectedRecordsCount),
							assignmentName: assignmentName
						})}
					</span>
				)}
				{!isStewardFilterTabsAvailable && (
					<div className={styles.dashboardStewardIconsViewContainer}>
						<button className={styles.dashboardStewardIconBtnContainer} onClick={onTableView}>
							<Icon testId="table-view-card-compo" type="table-view" />
						</button>
						<div className={styles.dashboardStewardIconContainer}>
							<Icon testId="card-view-card-compo" type="card-view" color={ColorBlueBrand} />
						</div>
					</div>
				)}
			</div>
			{isMultiSelectActive ? (
				<div className={styles.dashboardStewardMultiSelectContainer}>
					<div className={styles.dashboardStewardMultiSelect}>
						<MultiSelectOverviewCard />
					</div>
					<FooterButtons
						primaryBtn={getPrimaryBtn(selectedTile, requestedClassification)}
						justifyContent="flex-end"
						onAssign={getIsAssignAvailable(selectedRecords, selectedTile, onAssign)}
						onCancel={isMultiSelectActive ? onMultiSelect : undefined}
					/>
				</div>
			) : (
				<div className={styles.dashboardStewardRecordComparisonContainer}>
					{selectedRecords.length !== 0 && (
						<>
							<div className={styles.dashboardStewardRecordComparisonCards}>
								<RecordComparisonCards
									userRecord={mapUserRecord()}
									dnbMatch={mapDnBMatch()}
									additionalInsights={undefined}
									lastModified="10.10.10"
									onEdit={() => console.log('open edit modal')}
									member={1}
									familyMembers={23}
								/>
							</div>
							<FooterButtons
								primaryBtn={getPrimaryBtn(selectedTile, requestedClassification)}
								justifyContent="flex-end"
								onAssign={getIsAssignAvailable(selectedRecords, selectedTile, onAssign)}
								onCancel={isMultiSelectActive ? onMultiSelect : undefined}
							/>
						</>
					)}
				</div>
			)}
		</div>
	)
}
