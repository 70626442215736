import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './company-detail-tile.module.scss'
import { Accepted, NeedsReview, Rejected } from './legends'

export const LegendTooltip = (): ReactElement => {
	const { t } = useTranslation()

	return (
		<div className={styles.companyDetailLegendTooltipContainer}>
			<p className={styles.companyDetailLegendTitle}>{t('company.detail.tile.legend')}</p>
			<div className={`${styles.companyDetailLegendContainer}`}>
				<Accepted />
				<p className={styles.companyDetailLegendLabel}>{t('company.detail.tile.accepted.legend')}</p>
			</div>
			<div className={`${styles.companyDetailLegendContainer}`}>
				<Rejected />
				<p className={styles.companyDetailLegendLabel}>{t('company.detail.tile.rejected.legend')}</p>
			</div>
			<div className={`${styles.companyDetailLegendContainer}`}>
				<NeedsReview />
				<p className={styles.companyDetailLegendLabel}>{t('company.detail.tile.needs.review.legend')}</p>
			</div>
		</div>
	)
}
