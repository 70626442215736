/**
 * @class Carousel
 */
import cx from 'classnames'
import { camelCase } from 'lodash-es'
import { Children, FC, PropsWithChildren, useEffect, useState } from 'react'
import { Icon } from '..'
import Dots, { customColors } from '../dots/dots'
import styles from './carousel.module.scss'

export interface IProps {
	showDots?: boolean
	infinite?: boolean
	id: string
	colors?: customColors
	testId: string
	alignItems?: 'left' | 'center'
}

export const Carousel: FC<PropsWithChildren<IProps>> = ({
	children,
	showDots = true,
	infinite = false,
	id,
	colors,
	testId,
	alignItems = 'center'
}: PropsWithChildren<IProps>) => {
	const [arrowNextDisabled, setArrowNextDisabled] = useState(false)
	const [arrowBackDisabled, setArrowBackDisabled] = useState(!infinite)

	const getChildrenProps = (childIndex) => {
		if (children.hasOwnProperty('length') && children[childIndex] && children[childIndex].props) {
			return children[childIndex].props
		} else if (children['props']) {
			return children['props']
		}
	}
	const [activeIndex, setActiveIndex] = useState(0)
	const childrenArray = Children.toArray(children)
	const lengthChild = childrenArray.length

	useEffect(() => {
		if (!children.hasOwnProperty('length')) {
			setActiveIndex(0)
		}
	}, [children])

	const goToPrev = () => {
		let index = activeIndex
		if (index >= 1) {
			index--
		} else if (index < 1 && infinite) {
			index = lengthChild - 1
		}
		changeItem(index)
	}

	const goToNext = () => {
		let index = activeIndex
		if (index < lengthChild - 1) {
			index++
		} else if (index === lengthChild - 1 && infinite) {
			index = 0
		}
		changeItem(index)
	}

	const renderCarousel = () => {
		return (
			<div data-testid={`carousel-container-general-${testId}`} className={styles.containerChildren}>
				<div
					className={cx(styles.widthContainer, styles[camelCase(`align-${alignItems}`)])}
					data-testid={`${testId}-container`}
				>
					{childrenArray.map((child, index) => (index === activeIndex ? child : undefined))}
				</div>
			</div>
		)
	}

	const changeItem = (index: number) => {
		if (index !== activeIndex) {
			setActiveIndex(index)
		}
	}

	useEffect(() => {
		if (!infinite) {
			if (activeIndex >= 1) {
				setArrowBackDisabled(false)
			} else {
				setArrowBackDisabled(true)
			}
			if (activeIndex < lengthChild - 1) {
				setArrowNextDisabled(false)
			} else {
				setArrowNextDisabled(true)
			}
		}
	}, [activeIndex, children])

	return (
		<div data-testid={`carousel-container-${testId}`} className={styles.carousel} id={id}>
			<div data-testid={`carousel-container-middle-${testId}`} className={styles.containerMiddle}>
				<div
					className={cx(styles.arrows, styles.previous, {
						[styles.disabled]: arrowBackDisabled && !infinite,
						[styles.notVisible]: lengthChild <= 1
					})}
					onClick={goToPrev}
					data-testid={testId + '-prev'}
				>
					<Icon
						testId={`carousel-arrow-back-${testId}`}
						type="chevron-left"
						size="small"
						title="arrow-back"
						color={arrowBackDisabled && !infinite ? 'ColorGrayLighter' : 'ColorGrayDarker'}
					/>
				</div>
				{renderCarousel()}
				<div
					className={cx(styles.arrows, styles.next, {
						[styles.disabled]: arrowNextDisabled && !infinite,
						[styles.notVisible]: lengthChild <= 1
					})}
					onClick={goToNext}
					data-testid={testId + '-next'}
				>
					<Icon
						testId={`carousel-arrow-next-${testId}`}
						type="chevron-right"
						size="small"
						title="arrow-next"
						color={arrowNextDisabled && !infinite ? 'ColorGrayLighter' : 'ColorGrayDarker'}
					/>
				</div>
			</div>
			{showDots ? (
				<div className={cx({ [styles.notVisible]: lengthChild <= 1 })} data-testid={`${testId}-dots-container`}>
					<Dots
						numberDots={lengthChild}
						onChangeDot={changeItem}
						selectedRadio={activeIndex}
						testId={testId + '-dots'}
						colors={colors}
					/>
				</div>
			) : undefined}
		</div>
	)
}

export default Carousel
