import { FC, MouseEvent, MouseEventHandler, PropsWithChildren, ReactElement, useMemo, useRef, useState } from 'react'
import { getCoords } from '../helpers/getCoords'
import { getParentDepth } from '../helpers/getParentDepth'
import { ZDepthDropdown } from '../shared/tokens.json'
import styles from './tooltip.module.scss'
import ToolTipContent from './tooltipContent'

interface IToolTipData {
	label: string
	value: string
}

export interface ToolTipInfo {
	position?: 'top' | 'right' | 'rightTop' | 'rightBottom' | 'left' | 'leftTop' | 'leftBottom' | 'bottom'
	values?: Array<IToolTipData>
	text?: string
	title?: string
	matchScore?: number
	effect?: 'float' | 'solid'
	customContent?: ReactElement
	keepTooltipOnMouseOver?: boolean
	testId: string
}

type coords = { x: number; y: number }
type tooltipDimensions = { tooltipHeight: number; tooltipWidth: number }
type containerDimensions = { containerHeight: number; containerWidth: number }

export const ToolTip: FC<PropsWithChildren<ToolTipInfo>> = ({
	children,
	position = 'bottom',
	values,
	text,
	title,
	matchScore,
	effect = 'float',
	customContent,
	keepTooltipOnMouseOver = false,
	testId
}: PropsWithChildren<ToolTipInfo>) => {
	const [showToolTip, setShowToolTip] = useState(false)
	const toolTipRef = useRef<HTMLDivElement | null>(null)
	const container = useRef(null)
	const [tooltipCoords, setTooltipCoords] = useState<coords>({ x: 0, y: 0 })

	const myId = useMemo(() => (testId ? testId + '-' : '' + Math.floor(Math.random() * 1000 + 1).toString()), [])

	const mouseOver: MouseEventHandler<HTMLDivElement> = (event) => {
		setShowToolTip(true)
	}

	const mouseOut: MouseEventHandler<HTMLDivElement> = (event) => {
		if (!toolTipRef.current || !toolTipRef.current.contains(event.target as Node)) setShowToolTip(false)
	}

	const mouseMove: MouseEventHandler<HTMLDivElement> = (event) => {
		if (container.current.contains(event.target as Node)) {
			setShowToolTip(true)
		}
		if (showToolTip && toolTipRef.current) {
			repositionBasedOn(event)
		}
	}

	const setCoordsSolid = (
		newCoords: coords,
		tooltipDimensions: tooltipDimensions,
		containerDimensions: containerDimensions,
		containerBoundingRect: Record<string, number>
	) => {
		const xCenter = (containerBoundingRect.left + containerBoundingRect.right - tooltipDimensions.tooltipWidth) / 2
		const yCenter = (containerBoundingRect.top + containerBoundingRect.bottom - tooltipDimensions.tooltipHeight) / 2

		switch (position) {
			case 'top':
				newCoords.x = xCenter
				newCoords.y = containerBoundingRect.top - tooltipDimensions.tooltipHeight
				break
			case 'bottom':
				newCoords.x = xCenter
				newCoords.y = containerBoundingRect.bottom
				break
			case 'right':
				newCoords.x = containerBoundingRect.right
				newCoords.y = yCenter
				break
			case 'left':
				newCoords.x = containerBoundingRect.left - tooltipDimensions.tooltipWidth
				newCoords.y = yCenter
				break
			case 'rightTop':
				newCoords.x = containerBoundingRect.right
				newCoords.y = containerBoundingRect.top
				break
			case 'rightBottom':
				newCoords.x = containerBoundingRect.right
				newCoords.y = containerBoundingRect.top + containerDimensions.containerHeight
				break
			case 'leftTop':
				newCoords.x = containerBoundingRect.left - tooltipDimensions.tooltipWidth
				newCoords.y = containerBoundingRect.top
				break
			case 'leftBottom':
				newCoords.x = containerBoundingRect.left - tooltipDimensions.tooltipWidth
				newCoords.y = containerBoundingRect.top + containerDimensions.containerHeight
				break
			default:
				break
		}
	}

	const setCoordsFloat = (
		newCoords: coords,
		event: MouseEvent,
		tooltipDimensions: tooltipDimensions,
		containerDimensions: containerDimensions,
		containerBoundingRect: Record<string, number>
	) => {
		const xCenter = (containerBoundingRect.right + containerBoundingRect.left - tooltipDimensions.tooltipWidth) / 2
		const yCenter = (containerBoundingRect.top + containerBoundingRect.bottom - tooltipDimensions.tooltipHeight) / 2

		switch (position) {
			case 'top':
				newCoords.x = xCenter
				newCoords.y = event.pageY - tooltipDimensions.tooltipHeight - 25
				break
			case 'bottom':
				newCoords.x = xCenter
				newCoords.y = event.pageY + 15
				break
			case 'right':
				newCoords.x = event.pageX + 60
				newCoords.y = yCenter
				break
			case 'left':
				newCoords.x = event.pageX - tooltipDimensions.tooltipWidth - 60
				newCoords.y = yCenter
				break
			case 'rightTop':
				newCoords.x = event.pageX + 60
				newCoords.y = containerBoundingRect.top
				break
			case 'rightBottom':
				newCoords.x = event.pageX + 8
				newCoords.y = containerBoundingRect.top + containerDimensions.containerHeight
				break
			case 'leftTop':
				newCoords.x = event.pageX - tooltipDimensions.tooltipWidth - 8
				newCoords.y = containerBoundingRect.top
				break
			case 'leftBottom':
				newCoords.x = event.pageX - tooltipDimensions.tooltipWidth - 8
				newCoords.y = containerBoundingRect.top + containerDimensions.containerHeight
				break
			default:
				break
		}
	}

	const reposition = (newCoords: coords, event?: MouseEvent): void => {
		const toolTipDimensions = {
			tooltipHeight: toolTipRef.current.clientHeight,
			tooltipWidth: toolTipRef.current.clientWidth
		}

		const containerDimensions = {
			containerHeight: container.current.clientHeight,
			containerWidth: container.current.clientWidth
		}
		const containerBoundingRect = getCoords(container.current)

		if (effect === 'solid') {
			setCoordsSolid(newCoords, toolTipDimensions, containerDimensions, containerBoundingRect)
		} else if (effect === 'float') {
			setCoordsFloat(newCoords, event, toolTipDimensions, containerDimensions, containerBoundingRect)
		}
	}

	const repositionBasedOn = (event: MouseEvent<HTMLDivElement>): void => {
		// only reposition if the tooltip has a ref (a.k.a. exists)
		const newCoords: coords = { x: 0, y: 0 }

		if (effect === 'float') {
			reposition(newCoords, event)
		} else if (effect === 'solid' && container.current) {
			reposition(newCoords)
		}
		if (container.current) {
			const containerCoords = getCoords(container.current)

			// Let's see whether we are off screen and adjust accordingly
			if (containerCoords.left + newCoords.x < 0) newCoords.x = 0
			if (containerCoords.top + newCoords.y < 0) newCoords.y = 0
		}

		setTooltipCoords(newCoords)
	}

	return (
		<>
			<div
				className={styles.tooltipChildrenContainer}
				ref={container}
				onMouseOver={mouseOver}
				onMouseOut={mouseOut}
				onMouseMove={mouseMove}
				data-testid={`tooltip-container-${testId}`}
			>
				{children}
			</div>
			<ToolTipContent
				id={myId}
				show={showToolTip}
				testId={testId}
				customContent={customContent}
				effect={effect}
				keepTooltipOnMouseOver={keepTooltipOnMouseOver}
				matchScore={matchScore}
				position={position}
				text={text}
				title={title}
				values={values}
				ref={toolTipRef}
				top={tooltipCoords.y}
				left={tooltipCoords.x}
				zIndex={getParentDepth(container.current, ZDepthDropdown)}
			/>
		</>
	)
}

export default ToolTip
