import { AxiosInstance, AxiosResponse } from 'axios'

export interface RUMSummary {
	recordsToProcess: number
	recordsToExclude: number
	isExcludeFlagConfigured: boolean
	status?: string
}

export const getRUMRules = async (
	apiClient: AxiosInstance,
	sourceId?: string,
	isC4SPlatform = false
): Promise<RUMSummary> => {
	if (!!sourceId && isC4SPlatform) {
		const url = `/pls/integration/sourceId/${sourceId}/rumsummary`
		return apiClient.get(url).then((response: AxiosResponse<RUMSummary>) => response.data)
	} else {
		return {
			recordsToProcess: 0,
			recordsToExclude: 0,
			isExcludeFlagConfigured: false
		}
	}
}
