import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { Upload } from '../types'
import { getUploads } from './api/getUploads'

export const useUploads = (sourceId: string): UseQueryResult<Array<Upload>> => {
	const apiClient = useApi()

	const queryKey = ['getUploads', sourceId]
	const queryFunction = () => getUploads(apiClient, sourceId)
	return useQuery(queryKey, queryFunction)
}
