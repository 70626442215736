import { ReactElement, useEffect } from 'react'
import { useAppDispatch } from '../store'
import { ApiErrorState, setApiErrorAction } from '../store/apiError'

export function TemporalErrorModal(): ReactElement {
	const dispatch = useAppDispatch()
	useEffect(() => {
		const errorData: ApiErrorState = {
			errorCode: 'LEDP_05010',
			message: 'Internal server error. Resubmit request.'
		}
		dispatch(setApiErrorAction(errorData))
	}, [dispatch])

	return <div />
}
