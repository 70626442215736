import { AxiosInstance, AxiosResponse } from 'axios'
import { LoginDocResponse } from '../../../types'
import { getApiClient } from '../../../utilities'
import { AppThunk, TDispatch, TGetState } from '../../index'
import { authenticateSession, terminateSession } from '../actions'
import { SessionStatus, SignOutReason } from '../types'
import { authenticateWithEnterpriseSSO } from './authenticateWithEnterpriseSSO'

export const authenticate =
	(): AppThunk =>
	(dispatch: TDispatch, getState: TGetState): void => {
		if (getState().session.status === SessionStatus.unauthenticated) {
			const apiClient: AxiosInstance = getApiClient()

			apiClient
				.get('/pls/login-doc')
				.then((response: AxiosResponse<LoginDocResponse>) => {
					const availableConnectTenants = response.data.Result.Tenants.filter((tenant) =>
						tenant.EntitledApps.includes('DnB')
					)
					if (availableConnectTenants.length === 0) dispatch(terminateSession(SignOutReason.unauthorized))
					else
						dispatch(
							authenticateSession({
								token: response.data.Uniqueness + '.' + response.data.Randomness,
								authenticator: getState().session.authenticator,
								tenants: availableConnectTenants,
								username: response.data.UserName,
								firstName: response.data.FirstName,
								lastName: response.data.LastName
							})
						)
				})
				.catch(() => {
					dispatch(terminateSession(SignOutReason.backend))
					dispatch(authenticateWithEnterpriseSSO(window.location.origin))
				})
		}
	}
