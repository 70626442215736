import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../local-core-ui'
import styles from './toggleIndicator.module.scss'

export interface ToggleIndicatorProps {
	textKeyPrefix: string
	enabled: boolean
}

export const ToggleIndicator = ({ textKeyPrefix, enabled }: ToggleIndicatorProps): ReactElement => {
	const { t } = useTranslation()
	const iconName = enabled ? 'check-circle' : 'denied'
	const textName = `${textKeyPrefix}.${enabled ? 'enabled' : 'disabled'}`

	return (
		<div data-testid="indicator-icon" className={styles.indicator}>
			<Icon testId="icon-toggle-indicator" type={iconName} color={!enabled ? 'ColorGrayDark' : undefined} />
			<span data-testid="text-indicator" className={styles.text}>
				{t(textName)}
			</span>
		</div>
	)
}
