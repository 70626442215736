import cx from 'classnames'
import { FC, PropsWithChildren } from 'react'
import styles from './app-bar.module.scss'

/**
- The app bar at the top of most pages.
- Doesn't provide any content itself, but takes children.
**/
export interface AppBarProps {
	className?: string
	testId?: string
}

export const AppBar: FC<PropsWithChildren<AppBarProps>> = (props: PropsWithChildren<AppBarProps>) => (
	<header
		data-testid={props.testId}
		className={cx(styles.appBar, props.className ? styles[props.className] || props.className : styles.absolute)}
	>
		{props.children}
	</header>
)

export default AppBar
