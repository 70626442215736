/**
 * @class Slider
 */
import cx from 'classnames'
import * as d3 from 'd3'
import { FC, useEffect, useRef } from 'react'
import { createTokenList, getValueToken } from '../design-tokens/utils'
import chartColors from '../shared/chart-colors.json'
import * as tokens from '../shared/token-colors.json'
import styles from './slider.module.scss'

export interface ISliderProps {
	onChange(any)
	minValue: number
	maxValue: number
	sliderValue: number
	width: number
	valueFillOrientation: 'left' | 'right'
	color?: string
	testId: string
	disabled?: boolean
}

const buildSlider = (
	placeholder,
	minValue,
	maxValue,
	sliderValue,
	width,
	valueFillOrientation,
	onChange,
	color,
	testId,
	disabled = false
): void => {
	placeholder.selectAll('g').remove()
	placeholder.selectAll('defs').remove()
	const margins = {
		top: 22,
		left: 16,
		right: 16
	}

	const horizontalMargins = margins.left + margins.right

	const labelWidths = {
		min: getTextWidth(minValue, 'label'),
		max: getTextWidth(maxValue, 'label')
	}

	const tickList = []
	if (maxValue <= 10) {
		for (let i = 1; i <= maxValue; i++) {
			tickList.push(i)
		}
	} else if (maxValue >= 100) {
		for (let i = 0; i <= maxValue; i = i + 10) {
			i === 0 ? tickList.push(1) : tickList.push(i)
		}
	}

	const xScale = d3
		.scaleLinear()
		.domain([minValue, maxValue])
		.range([0, width - horizontalMargins - labelWidths.max])

	const dragScale = d3
		.scaleLinear()
		.domain([0, width - horizontalMargins])
		.range([minValue, maxValue])

	function getTextWidth(text, type) {
		//note this is created and removed because it needs to be rendered in order for D3 to get width
		const svgContainer = d3.select('svg')
		const tempText = svgContainer
			.append('g')
			.attr('class', 'temp')
			.append('text')
			.text(Math.round(text))
			.attr(
				'class',
				type === 'marker' ? (disabled ? styles.disabledMarkerText : styles.markerText) : styles.labelText
			)

		const textWidth = tempText.node() === null ? 0 : tempText.node().getComputedTextLength()

		d3.select('.temp').remove()
		return textWidth
	}

	function dragged(event) {
		const scaled = dragScale(event.x)
		if (scaled > maxValue) {
			sliderValue = maxValue
		} else if (scaled < minValue) {
			sliderValue = minValue
		} else {
			sliderValue = scaled
		}
		render()
	}
	function dragEnded(event) {
		const xPos = event.x
		//snap to interger or 10 based system
		const scaled = maxValue <= 10 ? Math.round(dragScale(xPos)) : Math.round(dragScale(xPos) / 10) * 10
		if (scaled > maxValue) {
			sliderValue = maxValue
		} else if (scaled < minValue) {
			sliderValue = minValue
		} else {
			sliderValue = scaled
		}
		onChange(sliderValue)
		render()
	}
	function keypressed() {
		const valueScale = maxValue > 10 ? 10 : 1
		switch (d3.event.key) {
			case 'ArrowLeft':
				if (sliderValue > minValue) {
					sliderValue = sliderValue - valueScale === 0 ? 1 : sliderValue - valueScale
				}
				render()
				break
			case 'ArrowRight':
				if (sliderValue < maxValue) {
					sliderValue = sliderValue + valueScale >= maxValue ? maxValue : sliderValue + valueScale
				}
				break
		}
		render()
	}

	const slider = disabled
		? placeholder.attr('width', width).attr('height', 50).attr('data-testid', testId)
		: placeholder
				.attr('width', width)
				.attr('height', 50)
				.attr('data-testid', testId)
				.call(d3.drag().on('drag', dragged).on('end', dragEnded))

	const gradient = slider
		.append('defs')
		.append('linearGradient')
		.attr('gradientUnits', 'userSpaceOnUse')
		.attr('id', 'Gradient1')
	gradient.append('stop').attr('class', styles.stop1).attr('offset', '10%')
	gradient.append('stop').attr('class', styles.stop2).attr('offset', '60%')
	gradient.append('stop').attr('class', styles.stop3).attr('offset', '90%')

	const sliderBackground = slider.append('g')
	sliderBackground
		.append('rect')
		.attr('class', styles.slideBackground)
		.attr('width', width - horizontalMargins - labelWidths.max)
		.attr('height', 4)
		.attr('x', margins.left)
		.attr('y', 14)

	const ticks = slider.append('g')
	ticks
		.selectAll('rect')
		.data(tickList)
		.join('rect')
		.attr('class', disabled ? styles.disabledTick : styles.tick)
		.attr('height', 12)
		.attr('width', 1)
		.attr('x', (d) => xScale(d) + margins.left)
		.attr('y', margins.top)
		.attr('data-testid', testId + '-tick')

	const valueFill = slider
		.append('g')
		.append('rect')
		.attr('class', 'slide-value')
		.attr('height', 8)
		.attr('y', 12)
		.attr('fill', valueFillOrientation === 'right' ? 'url(#Gradient1)' : chartColors.color.chart.high.value)

	const minLabel = slider.append('g')
	minLabel
		.append('rect')
		.attr('fill', color)
		.attr('width', labelWidths.min + margins.left)
		.attr('height', 40)
		.attr('class', 'min-label')
		.attr('x', 0)
		.attr('y', 0)
	minLabel
		.append('text')
		.text(minValue)
		.attr('class', cx('min-label', styles.labelText))
		.attr('x', margins.left - labelWidths.min / 2)
		.attr('y', margins.top)

	const maxLabel = slider.append('g')
	maxLabel
		.append('rect')
		.attr('class', 'max-label')
		.attr('fill', color)
		.attr('width', labelWidths.max + margins.right)
		.attr('height', 40)
		.attr('y', 0)
		.attr('x', width - horizontalMargins - labelWidths.max - 8)
	maxLabel
		.append('text')
		.text(maxValue)
		.attr('x', width - horizontalMargins - labelWidths.max)
		.attr('y', margins.top)
		.attr('class', cx('max-label', styles.labelText))

	const marker = slider.append('g').attr('data-testid', testId + '-g')
	marker
		.append('rect')
		.attr('class', disabled ? styles.disabledMarkerBackground : styles.markerBackground)
		.attr('y', 0)
		.attr('height', 32)
		.attr('ry', 16)
		.attr('rx', 16)
		.attr('focusable', false)
		.attr('data-testid', testId + '-marker')
		.on('keydown', keypressed)
	const markerText = marker
		.append('text')
		.text(Math.round(sliderValue) == 0 ? minValue : Math.round(sliderValue))
		.attr('class', disabled ? styles.disabledMarkerText : styles.markerText)
		.attr('y', 24)
		.attr('data-testid', testId + '-text')
	function getMarkerWidth() {
		const textWidth = getTextWidth(sliderValue, 'marker')
		return sliderValue < 10 ? 32 : textWidth + 32
	}

	function render() {
		valueFill
			.attr('width', valueFillOrientation === 'left' ? xScale(sliderValue) : xScale(maxValue - sliderValue + 1))
			.attr(
				'x',
				valueFillOrientation === 'left'
					? margins.left
					: width - xScale(maxValue - sliderValue + 1) - margins.right - labelWidths.max
			)
		marker
			.selectAll('rect')
			.attr('x', xScale(sliderValue) + margins.left - getMarkerWidth() / 2)
			.attr('width', getMarkerWidth())
		markerText
			.attr('x', xScale(sliderValue) + margins.left - getTextWidth(sliderValue, 'marker') / 2)
			.text(Math.round(sliderValue) == 0 ? minValue : Math.round(sliderValue))
	}
	render()
}

export const Slider: FC<ISliderProps> = ({
	minValue,
	maxValue,
	sliderValue,
	width,
	onChange,
	valueFillOrientation,
	color = 'ColorWhite',
	testId,
	disabled = false
}: ISliderProps) => {
	const d3Container = useRef(null)
	useEffect(() => {
		if (minValue && maxValue && sliderValue && width && onChange && d3Container.current) {
			const placeholder = d3.select(d3Container.current)
			const colorsTokenList = createTokenList(tokens)
			buildSlider(
				placeholder,
				minValue,
				maxValue,
				sliderValue,
				width,
				valueFillOrientation,
				onChange,
				getValueToken(colorsTokenList, color),
				testId,
				disabled
			)
		}
	}, [minValue, maxValue, sliderValue, width, valueFillOrientation, onChange, color, d3Container.current])

	return (
		<svg
			data-testid={`slider-svg-${testId}`}
			className="slider-component"
			width={400}
			height={200}
			ref={d3Container}
		/>
	)
}

export default Slider
