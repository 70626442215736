import { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckBox, Divider } from '../../local-core-ui'
import { Record } from '../../types'
import { DiffCompare } from './diff-compare'
import styles from './table-compare.module.scss'

interface TableCompareRecordProps {
	record: Record
	isMultiSelectActive: boolean
	isSelected: boolean
	onRecordCheck(recordId: string, checked: boolean): void
}

const Dashes: FC = () => <div className={styles.dashes}>— — —</div>

export const TableCompareRecord: FC<TableCompareRecordProps> = ({
	record,
	isMultiSelectActive,
	isSelected,
	onRecordCheck
}: TableCompareRecordProps) => {
	const { t } = useTranslation()
	const hasMatch = record.matchCandidate !== undefined
	const colSpan = isMultiSelectActive ? 6 : 5

	const handleCheckBoxChange = (event: ChangeEvent<HTMLInputElement>) => {
		onRecordCheck(record.recordId, event.target.checked)
	}

	return (
		<>
			<tr className={`${isSelected ? styles.selectedRecord : ''} ${styles.spacer}`} />
			<tr className={`${isSelected ? styles.selectedRecord : styles.record}`}>
				{isMultiSelectActive && hasMatch && (
					<td className={styles.checkboxCell}>
						<div className={styles.checkboxContainer}>
							<CheckBox
								id={`${record.inputFields.companyName}-checkbox`}
								checked={isSelected}
								testId={`${record.inputFields.companyName}-checkbox`}
								onChange={handleCheckBoxChange}
							/>
						</div>
					</td>
				)}
				<td>
					<div className="name">{record.inputFields?.companyName || <Dashes />}</div>
					<div className={styles.info}>
						<span className={styles.label}>{t('table.compare.record.submitted.by')}</span>
						{record.uploadedBy}
					</div>
				</td>
				<td>{record.inputFields?.tradestyle || <Dashes />}</td>
				<td>{record.inputFields?.website || <Dashes />}</td>
				<td>
					{record.inputFields?.streetAddressLine1 || <Dashes />}
					<br />
					{record.inputFields?.streetAddressLine2 || <Dashes />}
					<br />
					{`${record.inputFields?.city},  ${record.inputFields?.state} ${record.inputFields?.postalCode}` || (
						<Dashes />
					)}
					<br />
					{record.inputFields?.country || <Dashes />}
				</td>
				<td>{record.inputFields?.phoneNumber || <Dashes />}</td>
			</tr>
			<tr className={`${styles.matchCounts} ${isSelected ? styles.selectedRecord : styles.record}`}>
				<td colSpan={colSpan}>
					<div className={styles.matchCountsContainer}>
						<div className={`${styles.tag} ${isMultiSelectActive ? styles.checkboxSpacing : ''}`}>
							{t('table.compare.dnb.match.candidates')}
						</div>
						<Divider size="small" color="$color-gray" />
					</div>
				</td>
			</tr>
			<tr className={`${styles.matches} ${isSelected ? styles.selectedRecord : styles.record}`}>
				{isMultiSelectActive && <td />}
				<td>
					<div className={styles.cc}>{record.matchCandidate?.matchInsight.ConfidenceCode}</div>
					<div>
						<DiffCompare
							original={record.inputFields?.companyName}
							compare={record.matchCandidate?.companyName}
						/>
					</div>
					<div className={styles.info}>
						<div>
							<span className={styles.label}>{t('table.compare.record.duns')}</span>
							{record.matchCandidate?.matchedDuns}
						</div>
						<div>
							<span className={styles.label}>{t('table.compare.record.match.data.profile')}</span>
							{record.matchCandidate?.matchInsight.MatchDataProfile.text}
						</div>
						<div>
							<span className={styles.label}>{t('table.compare.record.match.string')}</span>
							<span className={styles.matchStringValue}>
								{record.matchCandidate?.matchInsight.MatchGrade.rawCode}
							</span>
						</div>
					</div>
				</td>
				<td>
					<DiffCompare
						original={record.inputFields?.tradestyle}
						compare={record.matchCandidate?.tradestyle}
					/>
				</td>
				<td>
					<DiffCompare original={record.inputFields?.website} compare={record.matchCandidate?.website} />
				</td>
				<td>
					<DiffCompare
						original={record.inputFields?.streetAddressLine1}
						compare={record.matchCandidate?.streetAddressLine1}
					/>
					<DiffCompare
						original={record.inputFields?.streetAddressLine2}
						compare={record.matchCandidate?.streetAddressLine2}
					/>
					<DiffCompare
						original={
							record.inputFields.city +
							', ' +
							record.inputFields.state +
							' ' +
							record.inputFields.postalCode
						}
						compare={
							record.matchCandidate.city +
							', ' +
							record.matchCandidate.state +
							' ' +
							record.matchCandidate.postalCode
						}
					/>
					<DiffCompare original={record.inputFields?.country} compare={record.matchCandidate?.country} />
				</td>
				<td>
					<DiffCompare
						original={record.inputFields?.phoneNumber}
						compare={record.matchCandidate?.phoneNumber}
					/>
				</td>
			</tr>
			<tr className={`${isSelected ? styles.selectedRecord : styles.record}`}>
				<td
					colSpan={colSpan}
					className={`${styles.bigDivider} ${isMultiSelectActive ? styles.checkboxSpacing : ''}`}
				>
					<Divider size="large" color="$color-gray" />
				</td>
			</tr>
		</>
	)
}
