import { EntityType } from '../types/sources/EntityType'
import { IntegrationType } from '../types/sources/IntegrationInfo'

export const versionLimit = import.meta.env.VITE_C4S_TRADEUP_MIN_VERSION

export const isTradeUpEnabled = (
	enableTradeUpFlag: boolean,
	enableTradeUpC4SFlag: boolean,
	integrationType?: IntegrationType,
	entityType?: EntityType,
	currentVersion?: string
) => {
	const isNotContact = entityType !== EntityType.CONTACTS
	let isTradeUpFlagsEnabled = enableTradeUpFlag

	if (integrationType === 'Salesforce') {
		const hasMinPackageVersion = !!(currentVersion && currentVersion >= versionLimit)
		isTradeUpFlagsEnabled = enableTradeUpC4SFlag && hasMinPackageVersion
	}

	return isNotContact && isTradeUpFlagsEnabled
}
