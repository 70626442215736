import { DNBButton } from '@dnb-uux-design-system/react'
import { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Modal } from '../../../../../local-core-ui'
import { FieldDefinition } from '../../../../../types'
import styles from '../alternate-fields-warning-modal/alternate-fields-warning-modal.module.scss'

interface AlternateFieldsWarningModalProps {
	testId: string
	isOpen: boolean
	onClose: () => void
	alternateFields: Array<FieldDefinition>
	onProceed: () => void
}

export function AlternateFieldsWarningModal({
	testId,
	isOpen,
	onClose,
	alternateFields,
	onProceed
}: AlternateFieldsWarningModalProps): ReactElement {
	const { t } = useTranslation()
	const onContinue = () => {
		onClose()
		onProceed()
	}

	return (
		<div
			className={styles.containerAlternateFieldsWarningModal}
			data-testid="alternate-fields-warning-modal-container"
		>
			<Modal open={isOpen} onClose={onClose} testId={'AlternateFieldsWarningModal'}>
				<div className={styles.header}>
					<h1 data-testid="alternate-fields-warning-modal-title" className={styles.title}>
						{t('alternate.fields.warning.title')}
					</h1>
				</div>
				<div className={styles.body}>
					<div className={styles.textWrapper}>
						<Trans i18nKey={'alternate.fields.warning.body.text1'} className={styles.textLabel} />
					</div>
					<ul>
						{alternateFields.map((row, index) => (
							<li key={index}>{t(row.fieldName)}</li>
						))}
					</ul>
					<div className={styles.textWrapper}>
						<Trans i18nKey={'alternate.fields.warning.body.text2'} className={styles.textLabel} />
					</div>
				</div>

				<div className={styles.footer}>
					<hr className={styles.divider}></hr>
					<div className={styles.buttonGroup}>
						<DNBButton
							size="default"
							variant="secondary"
							onClick={onClose}
							data-testid={testId + '-modal-cancel'}
						>
							{t('matching.step.modal.cancel')}
						</DNBButton>
						<DNBButton
							className={styles.marginClass}
							size="default"
							variant="primary"
							onClick={onContinue}
							data-testid={testId + '-modal-continue'}
						>
							{t('continue')}
						</DNBButton>
					</div>
				</div>
			</Modal>
		</div>
	)
}
