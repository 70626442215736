import { FC, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { CountrySelectWithMap, Icon } from '../../local-core-ui'
import styles from './match-country-selector.module.scss'

interface MatchCountrySelectorProps {
	selectedCountries?: string[] | 'All'
	disabledCountries?: string[]

	setSelectedCountriesValue?(selectedCountries: string[]): void

	onDialogBoxClose?(event: MouseEvent<HTMLDivElement>): void

	color?: string
	testId: string
	readonly?: boolean
	showDialogBox?: boolean
}

export const MatchCountrySelector: FC<MatchCountrySelectorProps> = ({
	selectedCountries = 'All',
	disabledCountries = [],
	setSelectedCountriesValue,
	onDialogBoxClose,
	color,
	testId,
	readonly = false,
	showDialogBox
}: MatchCountrySelectorProps) => {
	const { t } = useTranslation()

	if (selectedCountries != 'All') {
		disabledCountries = disabledCountries.filter((c) => !selectedCountries.includes(c))
	}

	return (
		<div className={styles.matchCountrySelector} data-testid="match-country-selector">
			<div className={styles.body}>
				{readonly && showDialogBox && (
					<div data-testid="dialog-container" className={styles.dialogBoxContainer}>
						<div data-testid="dialog-text-container" className={styles.dialogBoxTextContainer}>
							<span data-testid="first-dialog-text" className={styles.semiBoldText}>
								{t('matching.step.country.select.dialog.box.line.1')}
								&nbsp;
							</span>
							<span data-testid="second-dialog-text">
								{t('matching.step.country.select.dialog.box.line.2')}&nbsp;
							</span>
							<span data-testid="third-dialog-text" className={styles.semiBoldText}>
								{t('matching.step.country.select.dialog.box.line.3')}
								&nbsp;
							</span>
							<span data-testid="last-dialog-text">
								{t('matching.step.country.select.dialog.box.line.4')}
							</span>
						</div>
						<div
							className={styles.buttonCloseDialogBox}
							onClick={(event) => {
								if (onDialogBoxClose) onDialogBoxClose(event)
							}}
							data-testid="button-close-dialog-box"
						>
							<Icon
								testId="X-match-country-selector"
								type={'x'}
								size={'mini'}
								color={'ColorSeaGreenDark'}
							/>
						</div>
					</div>
				)}
				<CountrySelectWithMap
					width={558}
					value={selectedCountries}
					disabledCountries={disabledCountries}
					disallowSelectAll={selectedCountries !== 'All'}
					readOnly={selectedCountries === 'All' || readonly}
					onChange={(countries) => {
						if (setSelectedCountriesValue) setSelectedCountriesValue(countries)
					}}
					translationFunction={t}
					selectAllErrorMessage={t('matching.step.country.select.all.error.message')}
					color={color}
					testId={testId + '-cswm'}
				/>
				{selectedCountries === 'All' && <div className={styles.chip}>{t('all.countries')}</div>}
			</div>
		</div>
	)
}
