import { AxiosInstance } from 'axios'
import { UpdateUserRequest, UserDetail } from '../../types'

export const updateUser = async (apiClient: AxiosInstance, user: UserDetail): Promise<void> => {
	const url = `/pls/users`

	const updateRequest: UpdateUserRequest = {
		AccessLevel: user.AccessLevel || 'BUSINESS_ANALYST',
		targetUsername: user.Email
	}

	return apiClient.put(url, updateRequest)
}
