import { DNBButton, DNBToggle } from '@dnb-uux-design-system/react'
import { ChangeEvent, MouseEvent, ReactElement } from 'react'
import styles from './action-configuration-card.module.scss'

export interface ActionConfigurationCardProps {
	isDisabled?: boolean
	active?: boolean
	isLoading?: boolean
	title: string
	description?: string
	toggleLabel?: string
	toggleDescription?: string
	buttonLabel?: string
	buttonDescription?: string
	contactMatchMode?: boolean
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void
	onClick?: (event: MouseEvent<HTMLButtonElement>) => void
}

export const ActionConfigurationCard = ({
	isDisabled,
	active,
	isLoading,
	title,
	description,
	toggleLabel,
	toggleDescription,
	buttonLabel,
	buttonDescription,
	onChange,
	onClick,
	contactMatchMode
}: ActionConfigurationCardProps): ReactElement => {
	return (
		<div className={styles.container}>
			<div className={styles.cardTitle}>{title}</div>
			{!isLoading && <div className={styles.body}>{description}</div>}
			{!contactMatchMode && (
				<div className={styles.toggleContainer}>
					<div className={styles.boldBody}>{toggleLabel}</div>
					<div className={styles.toggle}>
						<DNBToggle onChange={onChange} checked={active} isLoading={isLoading} />
					</div>
				</div>
			)}
			{!isLoading && <div className={styles.compactBody}>{toggleDescription}</div>}
			{onClick && !isLoading && (
				<div>
					<div className={styles.body}>{buttonDescription}</div>
					<div className={styles.buttonContainer}>
						<DNBButton size="small" variant="primary" onClick={onClick} disabled={isDisabled}>
							{buttonLabel}
						</DNBButton>
					</div>
				</div>
			)}
		</div>
	)
}
