import { ColorGrayDark, ColorGreenBottleLight } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import cx from 'classnames'
import { ReactElement } from 'react'
import { Icon } from '../../local-core-ui'
import styles from './adjustable-progress-visual.module.scss'

export interface AdjustableProgressVisualProps {
	totalRequirements: number
	satisfiedRequirements: number
	/** In pixels */
	progressBarWidth?: number
}

export const AdjustableProgressVisual = ({
	totalRequirements = 0,
	satisfiedRequirements = 0,
	progressBarWidth = 180
}: AdjustableProgressVisualProps): ReactElement => {
	const requirementWidth = progressBarWidth / totalRequirements

	const getStatusIcon = () => {
		if (totalRequirements <= satisfiedRequirements) {
			return <Icon testId="simple-check-black-progress" type={'simple-check'} color={ColorGreenBottleLight} />
		} else {
			return (
				<div className={styles.emptyDotIconWrapper}>
					<Icon testId="empty-dot-gray-visual" type={'empty-dot'} color={ColorGrayDark} />
				</div>
			)
		}
	}

	const getState = () => {
		if (satisfiedRequirements >= totalRequirements) {
			return styles.complete
		} else if (satisfiedRequirements === 0) {
			return styles.inactive
		} else return styles.active
	}

	return (
		<div className={styles.progressContainer}>
			<div className={styles.statusIcon}>{getStatusIcon()}</div>
			<div className={styles.barContainer} style={{ width: `${progressBarWidth}px` }}>
				<div
					className={cx(styles.progressBar, getState())}
					style={{
						width: `${satisfiedRequirements * requirementWidth}px`
					}}
				/>
			</div>
			<div className={styles.iconStar}>
				<Icon type={'star-solid'} size={'mini'} />
			</div>
			<div className={cx(styles.numbersContainer, getState())}>
				{`${satisfiedRequirements ? satisfiedRequirements : 0}/${totalRequirements}`}
			</div>
		</div>
	)
}
