import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { EnrichmentApproachTile } from '../../../../components/enrichment-approach-tile/enrichment-approach-tile'
import { Grid } from '../../../../local-core-ui'
import { useEnrichmentTemplateFacadeList } from '../../../../queries/useEnrichmentTemplateFacadeList'
import { RootState, useAppDispatch, useAppSelector } from '../../../../store'
import { setEnrichingApproach } from '../../../../store/projectWizard/actions'
import styles from './enrichment-approach.module.scss'

interface EnrichmentApproachProps {
	onNext: () => void
}

export function EnrichmentApproach({ onNext }: EnrichmentApproachProps): ReactElement {
	const { t } = useTranslation()
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const dispatch = useAppDispatch()
	const selectedDomain = projectWizardState.currentProject.purposeOfUse.domain
	const selectedRecordType = projectWizardState.currentProject.purposeOfUse.recordType
	const enrichmentTemplateFacadeList = useEnrichmentTemplateFacadeList(
		projectWizardState.currentProject.purposeOfUse,
		projectWizardState.currentProject.source.entityType
	)

	const differentPurpose = !enrichmentTemplateFacadeList.data?.find(
		(template) =>
			(template.purposeOfUse.domain === selectedDomain &&
				template.purposeOfUse.recordType === selectedRecordType) ||
			!template.purposeOfUse.domain
	)

	return (
		<Grid testId="container-enrichment-approach" container>
			<Grid testId="enrichment-approach-panel">
				<div className={styles.enrichmentApproachPanel}>
					<h2 data-testid="enrichment-approach-title" className={styles.title}>
						{t('enrichment.approach.title')}
					</h2>
					<p className={styles.description}>{t('enrichment.approach.description')}</p>
					<EnrichmentApproachTile
						type={'saved-template'}
						onClick={() => {
							dispatch(setEnrichingApproach('saved-template'))
							onNext()
						}}
						purposeOfUse={`${selectedDomain} ${selectedRecordType}`}
						differentPurpose={differentPurpose}
						templateCount={enrichmentTemplateFacadeList.data?.length}
					/>
					<EnrichmentApproachTile
						type={'data-blocks'}
						onClick={() => {
							dispatch(setEnrichingApproach('data-blocks'))
							onNext()
						}}
					/>
				</div>
			</Grid>
		</Grid>
	)
}
