import { ColorGrayDarker } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ChangeEvent, MouseEvent, ReactElement, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ToggleSwitch } from '../../local-core-ui'
import { TileTypes } from '../../store/steward/types'
import { AssignmentDetails, StewardAssigneeSummary, SummaryResponse } from '../../types'
import { ExpandedInput } from '../expanded-input/expanded-input'
import { AssignedToWithName, AssignmentsManagerList } from './assignments-manager-list'
import styles from './assignments-manager.module.scss'

export interface AssignmentsManagerProps {
	publishedRecordsCounts?: SummaryResponse
	unassignedRecordsCount?: number
	assignedRecordsCount?: number
	assignedToMe: StewardAssigneeSummary
	assignedToList?: StewardAssigneeSummary[]
	selectedTile?: TileTypes
	onTileSelected(tileIdx: TileTypes): void
	selectedAssignmentId?: string
	onAssignmentSelected(assignment: AssignmentDetails | undefined): void
	onAssign(): void
	onAssigneeSelected(assigneeSummary: AssignedToWithName): void
}

export const AssignmentsManager = ({
	publishedRecordsCounts,
	unassignedRecordsCount,
	assignedRecordsCount,
	assignedToMe,
	assignedToList,
	selectedTile,
	onTileSelected,
	selectedAssignmentId,
	onAssignmentSelected,
	onAssign,
	onAssigneeSelected
}: AssignmentsManagerProps): ReactElement => {
	const { t } = useTranslation()
	const [filterCriteria, setFilterCriteria] = useState<string>('')
	const [showDashboards, setShowDashboards] = useState<boolean>(false)
	const [showToolBar, setShowToolBar] = useState<boolean>(false)
	const managerContainer = useRef<HTMLDivElement>(null)

	const handleFilterChange = (newValue: string) => {
		setFilterCriteria(newValue)
	}

	const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
		setShowDashboards(event.target.checked)
	}

	const handleMouseOut = (event: MouseEvent<HTMLDivElement>) => {
		if (managerContainer.current) {
			const hoverTargetNode = event.relatedTarget as Node
			const hoverTargetElement = event.relatedTarget as Element

			const hasHoverTile = managerContainer.current.contains(hoverTargetNode)

			const managerContainerCoords = managerContainer.current.getBoundingClientRect()
			let isOverContainer = false
			if (hoverTargetElement) {
				const hoverElementCoords = hoverTargetElement.getBoundingClientRect()
				isOverContainer =
					managerContainerCoords.top < hoverElementCoords.top &&
					managerContainerCoords.bottom > hoverElementCoords.top &&
					managerContainerCoords.right > hoverElementCoords.left &&
					managerContainerCoords.left < hoverElementCoords.left
			}

			if (!hasHoverTile && !isOverContainer) {
				setShowToolBar(false)
			}
		}
	}

	const handleHideDashboard = () => {
		setShowDashboards(!showDashboards)
	}

	return (
		<div
			ref={managerContainer}
			className={styles.assignmentsManagerContainer}
			onMouseEnter={() => setShowToolBar(true)}
			onMouseOut={handleMouseOut}
		>
			<div
				className={`${styles.assignmentsManagerToolBar} ${
					showToolBar ? styles.assignmentsManagerToolBarShow : ''
				}`}
			>
				<div className={styles.assignmentsManagerFilterInput}>
					<ExpandedInput
						id="assignments-manager-filter-input"
						hint={t('assignments.manager.search.hint')}
						value={filterCriteria}
						testId="assignments-manager-filter-input"
						searchIcon
						iconColor={ColorGrayDarker}
						onChangeFunction={handleFilterChange}
					/>
				</div>
				<ToggleSwitch
					id="assignments-manager-switch"
					label={t('assignments.manager.show.metrics')}
					checked={showDashboards}
					onChange={handleSwitchChange}
					testId="assignments-manager-switch"
				/>
			</div>
			<AssignmentsManagerList
				filterCriteria={filterCriteria}
				showDashboards={showDashboards}
				publishedRecordsCounts={publishedRecordsCounts}
				unassignedRecordsCount={unassignedRecordsCount}
				assignedRecordsCount={assignedRecordsCount}
				assignedToMe={assignedToMe}
				assignedToList={assignedToList}
				onHideDashboard={handleHideDashboard}
				selectedTile={selectedTile}
				onTileSelected={onTileSelected}
				selectedAssignmentId={selectedAssignmentId}
				onAssignmentSelected={onAssignmentSelected}
				onAssign={onAssign}
				onAssigneeSelected={onAssigneeSelected}
			/>
		</div>
	)
}
