import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { getCrmAvailableSourceTypesDetails } from './api/getCrmAvailableSourceTypesDetails'

export const useCrmAvailableSourceTypesDetails = (
	platformName: string,
	orgId: string,
	sourceId: string,
	matchType: string
): UseQueryResult<Array<string>> => {
	const apiClient = useApi()

	const queryKey = ['getCrmAvailableSourceTypesDetails', { platformName, orgId, sourceId, matchType }]
	const queryFunction = () => getCrmAvailableSourceTypesDetails(apiClient, platformName, orgId, matchType)
	return useQuery(queryKey, queryFunction)
}
