import { ReactElement, useEffect } from 'react'
import { ProjectOverview } from '../../../components/project-overview/project-overview'
import { RootState, useAppDispatch, useAppSelector } from '../../../store'
import { setOverviewViewedAction } from '../../../store/projectWizard/actions'
import { setEnrichMappingViewedAction } from '../../../store/projectWizard/actions/setEnrichMappingViewedAction'
import styles from './project-overview-wizard.module.scss'

export const ProjectOverviewWizard = (): ReactElement => {
	const dispatch = useAppDispatch()

	const projectWizardSelector = (state: RootState) => state.projectWizard
	const projectWizard = useAppSelector(projectWizardSelector)

	useEffect(() => {
		dispatch(setOverviewViewedAction())
		dispatch(setEnrichMappingViewedAction(false))
		/**
		 * We only want to run this effect when setOverviewViewedAction or the id from the current project in the project wizard changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setOverviewViewedAction, projectWizard.currentProject.id])

	return (
		<div className={styles.projectOverviewMetadataWizard}>
			{projectWizard.currentProject.id ? (
				<div className="source-settings">
					<ProjectOverview
						projectId={projectWizard.currentProject.id}
						testId="pow-overview"
						readOnly={false}
						wizardMode={true}
					/>
				</div>
			) : null}
		</div>
	)
}
