import { WizardContext } from '../../../components/wizardComposer/helpers/WizardContext'
import {
	WizardComposerStepDefinition,
	WizardComposerSubStepStates
} from '../../../components/wizardComposer/types/WizardComposerStepDefinition'
import { replaceFileAction } from '../../../store/projectWizard/actions'
import { createProject, updateProject } from '../../../store/projectWizard/thunks'
import { ProjectWizardState } from '../../../store/projectWizard/types'
import { SetupProject } from './setup-project'

const areRequiredFieldsComplete = (projectWizardState: ProjectWizardState) => {
	return !!(
		projectWizardState.currentProject.name.length > 0 &&
		projectWizardState.currentProject.purposeOfUse.domain &&
		projectWizardState.currentProject.purposeOfUse.recordType &&
		!projectWizardState.loadingNextStep
	)
}

export const WizardComposerSetupProjectStepDefinition = (context: WizardContext): WizardComposerStepDefinition => {
	return {
		title: 'project.wizard.step.title.define.project',
		progress: () =>
			context.projectWizardState.currentProject.id
				? 100
				: context.projectWizardState.currentProject.name.length > 0 &&
				  context.projectWizardState.currentProject.purposeOfUse.domain &&
				  context.projectWizardState.currentProject.purposeOfUse.recordType
				? 90
				: context.projectWizardState.currentProject.name.length > 0
				? 45
				: context.projectWizardState.currentProject.purposeOfUse.domain &&
				  context.projectWizardState.currentProject.purposeOfUse.recordType
				? 45
				: 0,
		onNext: async () => {
			if (!context.projectWizardState.currentProject.id) {
				await context
					.dispatch(createProject())
					.then(() => {
						context.dispatch(replaceFileAction())
					})
					.catch((err) => {
						throw err
					})
			} else {
				await context.dispatch(updateProject()).catch((err) => {
					throw err
				})
			}
		},
		onPrevious: undefined,
		subSteps: [
			{
				title: 'create.project.form.label.project.name',
				state: () =>
					context.projectWizardState.currentProject.name.length
						? WizardComposerSubStepStates.complete
						: WizardComposerSubStepStates.pending,
				onNext: undefined,
				onPrevious: undefined,
				isNextEnabled: () => areRequiredFieldsComplete(context.projectWizardState),
				route: { path: '/define', component: SetupProject }
			}
		]
	}
}
