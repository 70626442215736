import { DNBButton } from '@dnb-uux-design-system/react'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import dbconnect from '../../assets/images/dnb-amp-logo.svg'
import { getLocalizedDateString } from '../../helpers'
import { Connection, System } from '../../queries/api/getConnections'
import { ConnectionStatus } from '../connection-status/connection-status'
import salesforce from '../integration-tile/png/salesforce.png'
import snowflake from '../integration-tile/png/snowflakes.png'
import styles from './connection-tile.module.scss'

export interface ConnectionTileProps {
	connection: Connection
	onClickSeeConnection(connectionName: string): void
	isStatusLoading: boolean
}

export const ConnectionTile = ({
	connection,
	onClickSeeConnection,
	isStatusLoading
}: ConnectionTileProps): ReactElement => {
	const { t } = useTranslation()
	const system = connection.system
	const systemSrc = system === System.Salesforce ? salesforce : system === System.Snowflake ? snowflake : dbconnect

	const label = (title: string, description: string | number) => (
		<div className={styles.label}>
			<div className={styles.bold}>{`${title}:`} </div>
			&nbsp;
			<div className={styles.light}>{description}</div>
		</div>
	)

	return (
		<div className={styles.connectionContainer}>
			<div className={styles.headerContainer}>
				{system === System.Salesforce || system === System.Snowflake || system === System.ConnectManage ? (
					<img src={systemSrc} alt={'snowflakes'} className={styles.systemImg} />
				) : (
					<div className={styles.emptyImg} />
				)}
				<div className={styles.descriptionContainer}>
					<div className={styles.bold}>{connection.displayName}</div>
					<div className={styles.light}>{system}</div>
				</div>
			</div>
			<ConnectionStatus connectionStatus={connection.status} isStatusLoading={isStatusLoading} />
			<div className={styles.infoContainer}>
				{label(t('connection.created.by'), connection.user)}
				{label(t('connection.created.date'), getLocalizedDateString(connection.created))}
				{label(t('connection.updated.date'), getLocalizedDateString(connection.updated))}
			</div>
			<DNBButton
				size="small"
				variant="secondary"
				onClick={() => onClickSeeConnection(connection.name)}
				data-testid="create-project"
				className={styles.seeConnection}
			>
				{t('connections.see')}
			</DNBButton>
		</div>
	)
}
