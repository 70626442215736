/**
 * @class DragDrop
 **/

import cx from 'classnames'
import { ChangeEvent, DragEvent, FC, useRef, useState } from 'react'
import Icon from '../icon/icon'
import styles from './drag-drop.module.scss'

export interface IDragDropProps {
	id: string
	label: string | JSX.Element
	/** Use event.dataTransfer.files to get the files when there are dropped and if the files are selected by the input use event.target.files */
	onSelection(event: ChangeEvent<HTMLInputElement> | DragEvent<HTMLInputElement>, fileApproved: boolean): void
	accept?: string
	multiple?: boolean
	disabled?: boolean
	testId: string
}

export const DragDrop: FC<IDragDropProps> = ({
	id,
	label,
	onSelection,
	accept = '',
	multiple = false,
	disabled = false,
	testId
}: IDragDropProps) => {
	const [draggingFile, setDraggingFile] = useState<boolean>(false)
	const inputFileEl = useRef(null)

	const isFileTypeValid = (fileList: FileList) => {
		if (accept) {
			const acceptedTypes = accept.split(',').map((item) => item.trim())
			for (let i = 0; i < fileList.length; i++) {
				const fileType = fileList[i].name.slice(fileList[i].name.lastIndexOf('.'))
				if (!acceptedTypes.includes(fileType)) return false
			}
			return true
		}
	}

	const setOrRemoveTitle = (isValidFile: boolean, fileList: FileList) => {
		if (isValidFile) {
			let title = ''
			for (let i = 0; i < fileList.length; i++) {
				title = title.concat('\n', fileList[i].name)
			}
			inputFileEl.current.title = title
		} else {
			inputFileEl.current.removeAttribute('title')
		}
	}

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
		event.preventDefault()
		const isValidFile = isFileTypeValid(event.target.files)
		onSelection(event, isValidFile)
		setOrRemoveTitle(isValidFile, event.target.files)
		// Since the selection is handled by onSelection, it is safe to remove
		// the selected file, which is needed to trigger a new event in case you select
		// the same file
		inputFileEl.current.value = ''
	}

	const handleDrop = (event: DragEvent<HTMLInputElement>) => {
		event.preventDefault()
		const isValidFile = isFileTypeValid(event.dataTransfer.files)
		onSelection(event, isValidFile)
		setDraggingFile(false)
		setOrRemoveTitle(isValidFile, event.dataTransfer.files)
	}

	return (
		<div
			id={id}
			className={cx(styles.dragDropContainer, {
				[styles.draggingFile]: draggingFile,
				[styles.disabledDrag]: disabled
			})}
			data-testid={`drag-drop-container-${testId}`}
		>
			<input
				id={`input-file-${id}`}
				ref={inputFileEl}
				type="file"
				className={styles.inputFile}
				onChange={handleOnChange}
				accept={accept}
				multiple={multiple}
				onDragOver={(event) => {
					if (!disabled) {
						event.preventDefault()
					}
				}}
				onDragEnter={(event) => {
					if (!disabled) {
						event.preventDefault()
						setDraggingFile(true)
					}
				}}
				onDragLeave={(event) => {
					event.preventDefault()
					setDraggingFile(false)
				}}
				onDrop={handleDrop}
				disabled={disabled}
				data-testid={testId}
			/>
			<div data-testid={`drag-drop-icon-container-${testId}`} className={styles.iconLabelContainer}>
				<Icon testId={`drag-drop-icon-${testId}`} type="laptop" size="large" />
				<label
					data-testid={`drag-drop-label-${testId}`}
					id={`drag-drop-label-${id}`}
					className={styles.labelContainer}
					htmlFor={`input-file-${id}`}
				>
					{label}
				</label>
			</div>
		</div>
	)
}

export default DragDrop
