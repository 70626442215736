import cx from 'classnames'
import { PropsWithChildren, ReactElement } from 'react'
import styles from './report-tile.module.scss'

interface ReportTileProps {
	subtitle?: string
	title?: string
	header?: string
	className?: HTMLDivElement['className']
	pageIndicator?: string
}

const ReportTile = ({
	children,
	title = '',
	subtitle = '',
	header = '',
	className = '',
	pageIndicator = ''
}: PropsWithChildren<ReportTileProps>): ReactElement => {
	const hasHeader = header.length > 0 || title.length > 0 || subtitle.length > 0 || pageIndicator.length > 0
	return (
		<div className={cx(styles.reportTile, { [className]: className.length > 0 })}>
			{hasHeader ? (
				<div className={styles.tileHeader}>
					{header.length > 0 && <span className={styles.h5}>{header}</span>}
					{(title.length > 0 || pageIndicator.length > 0) && (
						<div className={styles.titleContainer}>
							{title.length > 0 && <span className={styles.h6}>{title}</span>}
							{pageIndicator && <span className={styles.pageIndicator}>{pageIndicator}</span>}
						</div>
					)}

					{subtitle.length > 0 && <span className={styles.compact}>{subtitle}</span>}
				</div>
			) : null}
			{children}
		</div>
	)
}

export { ReportTile }
