import cx from 'classnames'
import { ChangeEvent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { AgingBucket } from './AgingBucket'
import mandatoryElements from './arrayMandatoryElesAndAlerts.json'
import { ElementUIFacade } from './ElementUIFacade'
import { countSelectedElementChildren } from './helpers/element-tree-helpers'
import styles from './ranges-and-figures-children-tree.module.scss'
import { RangesandFiguresCheckboxSubelements } from './rangesandfigures-checkbox-subelements'

interface RangesAndFiguresChildrenTreeProps {
	index: number
	element: ElementUIFacade
	testId: string
	selectedElementList: Array<ElementUIFacade>
	onChange: (e: ChangeEvent<HTMLInputElement>, option: ElementUIFacade) => void
	defaultElements?: Array<ElementUIFacade>
	showBlockInformation?: boolean
	onDropSelectorChange?: (newSelectedElements: Array<ElementUIFacade>) => void
	mandatoryIdArray: Array<string>
	setMandatoryIdArray: (arr: Array<string>) => void
	errorBannerVisibility(mandatoryIdArray: Array<string>): void
}

export const RangesAndFiguresChildrenTree = ({
	index,
	element,
	testId,
	selectedElementList,
	onChange,
	defaultElements,
	showBlockInformation,
	onDropSelectorChange,
	mandatoryIdArray,
	setMandatoryIdArray,
	errorBannerVisibility
}: RangesAndFiguresChildrenTreeProps): ReactElement => {
	const { t } = useTranslation()

	const mandatoryPrefixElements = ['ExperCnt', 'pstDuePrCnt', 'ttlPstDueAmt']
	const validationCheck = selectedElementList.some((elem) => {
		const elemSuffix = elem.elementId.split('_')[1]
		return mandatoryPrefixElements.indexOf(elemSuffix) >= 0
	})

	if (element.showChildrenBlocks) {
		let selectedChildrenCount = 0
		const blockChildren = element.childs || []
		selectedChildrenCount = countSelectedElementChildren(blockChildren, selectedElementList, defaultElements)
		const checkMandatorySubelements = blockChildren?.some((child) =>
			mandatoryElements.mandatoryIds.includes(child.elementId)
		)
		const checkOverallSubelements = blockChildren?.every((child) =>
			mandatoryElements.mandatoryIds.includes(child.elementId)
		)
		return (
			<div>
				<div className={`${styles.containerItemSmall}`}>
					<AgingBucket
						element={element}
						selectedElementList={selectedElementList}
						onDropSelectorChange={onDropSelectorChange}
						mandatoryIdArray={mandatoryIdArray}
						setMandatoryIdArray={setMandatoryIdArray}
						errorBannerVisibility={errorBannerVisibility}
					/>
				</div>
				{/* <div className={styles.fieldsToReturnAgingContainer}>{t('fields.to.return.text')}</div> */}
				<div className={styles.agingPaddingDiv}>
					{checkMandatorySubelements && (
						<div
							data-testid="subelements-mandatory-title"
							className={cx(styles.wantToKnowQuestionContainer)}
						>
							{t('subelements.mandatory.title')}
						</div>
					)}
					{blockChildren.map(
						(blockChild, indexChild) =>
							mandatoryElements.mandatoryIds.indexOf(blockChild.elementId) > -1 && (
								<RangesandFiguresCheckboxSubelements
									key={blockChild.elementId + 'parentsubpanel-' + index}
									blockChild={blockChild}
									indexChild={indexChild}
									index={index}
									element={element}
									testId={testId}
									selectedElementList={selectedElementList}
									onChange={onChange}
									defaultElements={defaultElements}
									showBlockInformation={showBlockInformation}
									mandatoryIdArray={mandatoryIdArray}
									setMandatoryIdArray={setMandatoryIdArray}
									errorBannerVisibility={errorBannerVisibility}
								/>
							)
					)}
					{!checkOverallSubelements && (
						<div data-testid="subelements-overall-title" className={cx(styles.wantToKnowQuestionContainer)}>
							{t('subelements.overall.title')}
						</div>
					)}
					{blockChildren.map(
						(blockChild, indexChild) =>
							mandatoryElements.mandatoryIds.indexOf(blockChild.elementId) === -1 && (
								<RangesandFiguresCheckboxSubelements
									key={blockChild.elementId + 'parentsubpanel-' + index}
									blockChild={blockChild}
									indexChild={indexChild}
									index={index}
									element={element}
									testId={testId}
									selectedElementList={selectedElementList}
									onChange={onChange}
									defaultElements={defaultElements}
									showBlockInformation={showBlockInformation}
									mandatoryIdArray={[]}
									setMandatoryIdArray={setMandatoryIdArray}
									errorBannerVisibility={errorBannerVisibility}
								/>
							)
					)}
				</div>
				{validationCheck && (
					<div className={styles.childrenSelectorMessageAging}>
						<div data-testid="columns-result-message-children-tree" className={styles.columnsResultMessage}>
							{t('columns.number.result', { columns: selectedChildrenCount })}
						</div>
						<div data-testid="available-data-message-children-tree" className={styles.availableDataMessage}>
							{t('available.data')}
						</div>
					</div>
				)}
				{element.maximumElementsInOutput && element?.maximumElementsInOutput?.current === 0 && (
					<div className={cx(styles.childrenSelectorMessageAging, styles.selectOneElementContainerAging)}>
						<div className={styles.selectTwoElement}>{t('select.the.number')}</div>
					</div>
				)}
			</div>
		)
	} else return <></>
}
