import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { DataReport } from '../types'
import { getDataReport } from './api/getDataReport'

export const useDataReport = (
	level: string,
	ownerId: string,
	useMockData: string
): UseQueryResult<DataReport, unknown> => {
	const apiClient = useApi()

	const queryKey = ['getDataReport', { level, ownerId, useMockData }]
	const queryFunction = () => getDataReport(apiClient, level, ownerId, useMockData)
	return useQuery(queryKey, queryFunction)
}
