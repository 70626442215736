import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './apiSpecs.module.scss'
import { IParagraphProps, Paragraph } from './paragraph'

interface ApiSpecsProps {
	sourceName: string
	sourceId: string
}

export const ApiSpecs = ({ sourceName, sourceId }: ApiSpecsProps): ReactElement => {
	const { t } = useTranslation()
	const paragraphs: Array<IParagraphProps> = [
		{ title: t('apiSpec.modal.sourceName'), text: sourceName },
		{
			title: t('apiSpec.modal.apiURL'),
			text: `${import.meta.env.VITE_PLUS_DNB}/v1/connect/matchEnrich/v2/${sourceId}`,
			iconCopy: true
		},
		{ title: t('apiSpec.modal.credentials.title'), text: t('apiSpec.modal.credentials.text') },
		{
			title: t('apiSpec.modal.fullDocumentation'),
			text: 'https://directplus.documentation.dnb.com/html/guides/DNBConnect/DNBConnect.html',
			isLink: true,
			testId: 'link-fullDocumentation'
		}
	]

	return (
		<>
			<div className={styles.apiSpecModal}>
				<div className={styles.titleModal}>{t('apiSpec.modal.title')}</div>
				<div className={styles.bodyModal}>
					{paragraphs.map((paragraph, index) => (
						<Paragraph
							title={paragraph.title}
							text={paragraph.text}
							isLink={paragraph.isLink}
							iconCopy={paragraph.iconCopy}
							key={'paragraph-' + index}
						/>
					))}
				</div>
			</div>
		</>
	)
}
