export enum PriorityLevel {
	'LOW',
	'MEDIUM',
	'HIGH'
}

export enum State {
	'ACTIVE',
	'ARCHIVED'
}
