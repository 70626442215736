import { ChangeEvent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, ToggleSwitch } from '../../local-core-ui'
import styles from './stewardableToggle.module.scss'

export interface StewardableToggleProps {
	disabled?: boolean
	checked: boolean
	onChange(event: ChangeEvent<HTMLInputElement>): void
}
export const StewardableToggle = ({ disabled = false, checked, onChange }: StewardableToggleProps): ReactElement => {
	const { t } = useTranslation()

	return (
		<>
			<Grid testId="container-steward-toggle" container>
				<Grid testId="first-space-steward-toggle" sm={12}>
					&nbsp;
				</Grid>
				<Grid testId="steward-toggle-switch" sm={12}>
					<ToggleSwitch
						onChange={onChange}
						label={t('stewardable.toggle.label')}
						testId="stewardable-toggle"
						id="stewardable-toggle"
						disabled={disabled}
						checked={checked}
					/>
				</Grid>
				<Grid testId="second-space-steward-toggle" sm={12}>
					&nbsp;
				</Grid>
				<Grid testId="explanation-steward-toggle" sm={12}>
					<div className={styles.explanation}>{t('stewardable.explanation')}</div>
				</Grid>
			</Grid>
		</>
	)
}
