import { UserDetail } from '../../../types'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { updateCurrentProjectAction } from '../actions'

export const updateTeamMembers =
	(users: Array<UserDetail>): AppThunk =>
	(dispatch: TDispatch, getState: TGetState) => {
		const projectWizard = getState().projectWizard
		const apiClient = getApiClient4Thunks(dispatch)
		if (projectWizard.currentProject.id) {
			const teamId = projectWizard.currentProject?.team?.TeamId
			if (teamId && teamId !== '') {
				const teamName = projectWizard.currentProject.team.TeamName
				const newRecipientList = users.map((user) => user.Email)

				const url = `/pls/teams/teamId/${teamId}`
				return apiClient
					.put(url, {
						teamName: teamName,
						teamMembers: newRecipientList
					})
					.then((response) => {
						if (response.status === 200) {
							dispatch(
								updateCurrentProjectAction({
									team: { ...projectWizard.currentProject.team, TeamMembers: users }
								})
							)
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}
		} else {
			dispatch(
				updateCurrentProjectAction({
					team: { ...projectWizard.currentProject.team, TeamMembers: users }
				})
			)
		}
	}
