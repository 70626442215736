export const smallCountriesIdList = [
	'ABW',
	'AIA',
	'ALA',
	'AND',
	'ASM',
	'ATF',
	'ATG',
	'BES',
	'BHR',
	'BHS',
	'BLM',
	'BLZ',
	'BMU',
	'BRB',
	'BRN',
	'BVT',
	'CCK',
	'COK',
	'COM',
	'CPV',
	'CXR',
	'CYM',
	'CYP',
	'DJI',
	'DMA',
	'FJI',
	'FLK',
	'FRO',
	'FSM',
	'GGY',
	'GIB',
	'GLP',
	'GMB',
	'GRD',
	'GUM',
	'HKG',
	'HMD',
	'IMN',
	'IOT',
	'ISR',
	'JAM',
	'JEY',
	'KIR',
	'KNA',
	'KWT',
	'LBN',
	'LCA',
	'LIE',
	'LKA',
	'LUX',
	'MAC',
	'MAF',
	'MCO',
	'MDV',
	'MHL',
	'MLT',
	'MNE',
	'MNP',
	'MSR',
	'MTQ',
	'MUS',
	'MYT',
	'NCL',
	'NFK',
	'NIU',
	'NRU',
	'PCN',
	'PLW',
	'PRI',
	'PSE',
	'PYF',
	'QAT',
	'REU',
	'RWA',
	'SGP',
	'SGS',
	'SHN',
	'SMR',
	'SPM',
	'STP',
	'SXM',
	'SYC',
	'TCA',
	'TKL',
	'TLS',
	'TON',
	'TTO',
	'TUV',
	'TWN',
	'UMI',
	'VAT',
	'VCT',
	'VGB',
	'VIR',
	'VUT',
	'WLF',
	'WSM'
]
