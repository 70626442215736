import { useFormik } from 'formik'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Avatar, Button, Dropdown, DropdownOptions, IAvatarData, Input, Modal } from '../../local-core-ui'
import { UserDetail } from '../../types'
import styles from './user-detail-modal.module.scss'

interface UserDetailModalProps {
	user?: UserDetail
	addUserFunction?(user: UserDetail): void
	deleteUserFunction?(userId: UserDetail): void
	updateUserFunction?(user: UserDetail): void
	roles: Array<DropdownOptions>
	testId: string
	open: boolean
	onClose: () => void
	addButtonDisable?: boolean
}

export const UserDetailModal = ({
	user,
	addUserFunction,
	deleteUserFunction,
	updateUserFunction,
	roles,
	testId,
	open = false,
	onClose,
	addButtonDisable
}: UserDetailModalProps): ReactElement => {
	const { t } = useTranslation()
	const [isCloseConfirmationModalOpen, setCloseConfirmationModalOpen] = useState<boolean>(false)
	const [thereAreInputValues, setThereAreInputValues] = useState<boolean>(false)

	const newUserSchema = yup.object({
		FirstName: yup.string().required(t('user.detail.modal.error.firstName')),
		LastName: yup.string().required(t('user.detail.modal.error.lastName')),
		Email: yup
			.string()
			.email(t('user.detail.modal.error.formatEmail'))
			.required(t('user.detail.modal.error.email')),
		AccessLevel: yup.string().required(t('user.detail.modal.error.role'))
	})

	const userData = {
		FirstName: user?.FirstName || '',
		LastName: user?.LastName || '',
		Email: user?.Email || '',
		AccessLevel: user?.AccessLevel || ''
	}

	const formik = useFormik({
		initialValues: {
			...userData
		},
		enableReinitialize: true,
		validationSchema: newUserSchema,
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onSubmit: () => {}
	})

	function translateToUserData(user: typeof userData): Array<IAvatarData> {
		const avatarData: Array<IAvatarData> = []
		avatarData.push({ label: 'First Name', value: user.FirstName })
		avatarData.push({ label: 'Last Name', value: user.LastName })
		avatarData.push({ label: 'Email', value: user.Email })
		avatarData.push({ label: 'Role', value: user.AccessLevel })

		return avatarData
	}

	useEffect(() => {
		if (user) {
			setThereAreInputValues(true)
		}
	}, [user])

	useEffect(() => {
		formik.resetForm(user as Partial<UserDetail>)
		/**
		 * We only want to run this effect when open prop changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open])

	return (
		<div
			className={`${styles.containerUserDetailModal} ${
				isCloseConfirmationModalOpen ? styles.closeCreateUserModal : styles.addUserModal
			}`}
		>
			<Modal
				open={open}
				isContainer={!isCloseConfirmationModalOpen}
				onClose={() => {
					if (thereAreInputValues || formik.dirty) {
						setCloseConfirmationModalOpen(true)
					} else {
						onClose()
					}
				}}
				showButtonClose={!isCloseConfirmationModalOpen}
				testId="CloseUserModal"
			>
				{isCloseConfirmationModalOpen ? (
					<div className={styles.closeCreateUserModal}>
						<div className={styles.modalBody}>
							<span>{t('user.close.modal.text')}</span>
						</div>
						<div className={`${styles.modalFooter} ${styles.closeModal}`}>
							<Button
								text={t('user.close.modal.close')}
								type="primary"
								size="large"
								onClick={() => {
									onClose()
									setCloseConfirmationModalOpen(false)
									setThereAreInputValues(false)
								}}
								testId={testId + '-modal-close'}
							/>
							<Button
								text={t('user.close.modal.back')}
								type="secondary"
								size="medium"
								onClick={() => {
									setCloseConfirmationModalOpen(false)
								}}
								testId={testId + '-modal-back'}
							/>
						</div>
					</div>
				) : (
					<div className={styles.addUserModal}>
						<div className={styles.modalHeader}>
							<div className={styles.avatarNUsername}>
								<div className={styles.avatarContainer}>
									<Avatar
										userData={translateToUserData(userData)}
										onClick={() => console.log('avatar')}
										testId={testId + '-avatar'}
									/>
								</div>
								<h1 className={styles.userName}>
									{user?.FirstName || ''} {user?.LastName || ''}
								</h1>
							</div>
						</div>
						<form onSubmit={formik.handleSubmit} data-testid={testId + '-form'}>
							<div className={styles.modalBody}>
								<div className={styles.modalColumn}>
									<Input
										id="firstName"
										hint={t('user.detail.modal.firstName.hint')}
										label={t('user.detail.modal.firstName.label')}
										value={formik.values.FirstName}
										onChangeFunction={(value: string) => {
											formik.setFieldValue('FirstName', value)
										}}
										onBlurFunction={() => formik.handleBlur({ target: { name: 'FirstName' } })}
										size="small"
										required
										error={formik.touched.FirstName && !!formik.errors.FirstName}
										errorMessage={formik.errors.FirstName ? formik.errors.FirstName : ''}
										showCheckIcon={false}
										disabled={!!user?.Email}
										maxLength={30}
										testId={testId + '-first-name'}
									/>
								</div>
								<div className={styles.modalColumn}>
									<Input
										id="last-name"
										hint={t('user.detail.modal.lastName.hint')}
										label={t('user.detail.modal.lastName.label')}
										value={formik.values.LastName}
										onChangeFunction={(value) => formik.setFieldValue('LastName', value)}
										onBlurFunction={() => formik.handleBlur({ target: { name: 'LastName' } })}
										size="small"
										required
										error={formik.touched.LastName && !!formik.errors.LastName}
										errorMessage={formik.errors.LastName ? formik.errors.LastName : ''}
										showCheckIcon={false}
										disabled={!!user?.Email}
										maxLength={30}
										testId={testId + '-last-name'}
									/>
								</div>
								<div className={styles.modalColumn}>
									<Dropdown
										id="role"
										hint={t('user.detail.modal.role.hint')}
										label={t('user.detail.modal.role.label')}
										options={roles}
										onChangeFunction={(value) => formik.setFieldValue('AccessLevel', value)}
										onBlurFunction={() => formik.handleBlur({ target: { name: 'AccessLevel' } })}
										size="small"
										isRequired
										isValid={!formik.touched.AccessLevel || !formik.errors.AccessLevel}
										errorMessage={
											formik.touched.AccessLevel && formik.errors.AccessLevel
												? formik.errors.AccessLevel
												: ''
										}
										selected={formik.values.AccessLevel}
										insideForm={true}
										showCheckIcon={false}
										testId={testId + '-role'}
									/>
								</div>
								<div className={styles.modalColumn}>
									<Input
										id="email"
										hint={t('user.detail.modal.email.hint')}
										label={t('user.detail.modal.email.label')}
										value={formik.values.Email}
										onChangeFunction={(value) => formik.setFieldValue('Email', value)}
										onBlurFunction={() => formik.handleBlur({ target: { name: 'Email' } })}
										size="small"
										required
										error={formik.touched.Email && !!formik.errors.Email}
										errorMessage={formik.errors.Email ? formik.errors.Email : ''}
										showCheckIcon={false}
										disabled={!!user?.Email}
										testId={testId + '-email'}
									/>
								</div>
							</div>
							{user?.Email ? (
								<div className={styles.modalFooter}>
									<Button
										text={t('user.detail.modal.update.button')}
										type="primary"
										size="medium"
										onClick={() => {
											if (user) {
												if (updateUserFunction) {
													updateUserFunction(formik.values as UserDetail)
												}
											}
										}}
										isDisabled={!formik.isValid}
										testId={testId + '-update'}
									/>
									<Button
										text={t('user.detail.modal.remove.button')}
										type="secondary"
										size="medium"
										onClick={() => {
											if (user && deleteUserFunction) {
												deleteUserFunction(formik.values as UserDetail)
											}
										}}
										testId={testId + '-remove'}
									/>
								</div>
							) : (
								<div className={styles.modalFooter}>
									<Button
										text={t('user.detail.modal.add.button')}
										type="primary"
										size="medium"
										onClick={() => {
											if (addUserFunction && formik.values) {
												addUserFunction(formik.values as UserDetail)
											}
										}}
										isDisabled={!formik.dirty || !formik.isValid || addButtonDisable}
										testId={testId + '-add'}
									/>
								</div>
							)}
						</form>
					</div>
				)}
			</Modal>
		</div>
	)
}
