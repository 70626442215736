import cx from 'classnames'
import { FC, PropsWithChildren } from 'react'
import styles from './accordion.module.scss'

interface AccordionPanelProps {
	id: string
	testId?: string
}

export interface withAccordionPanelProps extends AccordionPanelProps {
	isOpen?: boolean
	existControlElement?: boolean
}

export const AccordionPanel: FC<PropsWithChildren<withAccordionPanelProps>> = ({
	children,
	id,
	isOpen = false,
	existControlElement = false,
	testId
}) => {
	return (
		<>
			{isOpen && (
				<div
					data-testid={`accordion-collapsable-section-${testId}`}
					id={id}
					role="region"
					className={styles.collapsableRowSection}
				>
					<div
						className={cx(
							styles.accordionItemContent,
							existControlElement ? styles.spaceControlElement : ''
						)}
						data-testid={`accordion-item-content-${testId}`}
					>
						{children}
					</div>
				</div>
			)}
		</>
	)
}

export default AccordionPanel
