import { ReactElement, useState } from 'react'
import { CompanyListManager, recordSortBy } from '../../../components/company-list-manager/company-list-manager'
import { Filter, FilterSummary } from '../../../components/filter-summary/filter-summary'
import { PaginationButtons } from '../../../components/pagination-buttons/pagination-buttons'
import { LoadingState } from '../../../local-core-ui'
import { RootState, useAppDispatch, useAppSelector } from '../../../store'
import { setSelectedRecords } from '../../../store/steward/stewardSlice'
import { paginateOverRecords } from '../../../store/steward/thunks'
import { PageSize } from '../../../store/steward/types'
import styles from '../dashboard-steward.module.scss'

interface CardLeftSideProps {
	isMultiSelectActive: boolean
	onToggleMultiSelect(): void
	recordListSortBy?: recordSortBy
	onRecordListSortByChange(sortBy: recordSortBy): void
	onPageChange(): void
}

export function CardLeftSide({
	isMultiSelectActive,
	onToggleMultiSelect,
	recordListSortBy,
	onRecordListSortByChange,
	onPageChange
}: CardLeftSideProps): ReactElement {
	const selectSteward = (state: RootState) => state.steward
	const steward = useAppSelector(selectSteward)
	const dispatch = useAppDispatch()

	const [selectedFilters, setSelectedFilters] = useState<Filter[]>([])

	const handleRemoveFilter = (newList: Filter[]) => {
		setSelectedFilters(newList)
	}

	const handleNext = () => {
		dispatch(paginateOverRecords(steward.pageIndex + 1))
		onPageChange()
	}

	const handlePrevious = () => {
		dispatch(paginateOverRecords(steward.pageIndex - 1))
		onPageChange()
	}

	const handlePageSizeChange = (newPageSize: PageSize) => {
		if (!isMultiSelectActive) {
			dispatch(setSelectedRecords([]))
		}
		dispatch(paginateOverRecords(1, newPageSize))
		if (!isMultiSelectActive) {
			dispatch(setSelectedRecords([]))
		}
	}

	return (
		<div className={styles.dashboardStewardLeftSide}>
			{steward.retrieving && (
				<div className={styles.dashboardStewardCardViewLoader}>
					<LoadingState />
				</div>
			)}
			<FilterSummary
				filters={selectedFilters}
				testId="stewardship-card-filters"
				onAddFilterClicked={() => console.log('open filters modal')}
				onMoreClicked={() => console.log('open filters modal')}
				onRemovedFilter={handleRemoveFilter}
			/>
			<CompanyListManager
				isMultiSelectActive={isMultiSelectActive}
				onToggleMultiSelect={onToggleMultiSelect}
				currentSorting={recordListSortBy}
				onSortChange={onRecordListSortByChange}
			/>
			<div className={styles.dashboardStewardPaginationBtnContainer}>
				<PaginationButtons
					onNext={handleNext}
					onPrevious={handlePrevious}
					pageSize={steward.pageSize}
					onPageSizeChange={handlePageSizeChange}
					listLength={steward.total}
					showBtnTxt={false}
					currentPage={steward.pageIndex}
				/>
			</div>
		</div>
	)
}
