import world from '../../../local-core-ui/assets/data/world.json'
import { MatchRuleInfo, SPECIAL_RULE } from '../../../store/projectWizard/types'

export const isMatchRulesReadyToValidate = (matchRules: Array<MatchRuleInfo>): boolean => {
	let allRequiredFieldsFilled = true
	if (matchRules.length > 0) {
		let totalSelected = 0
		for (const matchRuleInfo of matchRules) {
			totalSelected += matchRuleInfo.matchRule.allowedValues?.length || 0
			if (
				matchRuleInfo.matchRule.ruleType === SPECIAL_RULE &&
				(!matchRuleInfo.matchRule.allowedValues || matchRuleInfo.matchRule.allowedValues.length <= 0)
			) {
				allRequiredFieldsFilled = false
				break
			}
		}
		if (totalSelected === world.features.length) {
			allRequiredFieldsFilled = false
		}
	} else {
		allRequiredFieldsFilled = false
	}
	return allRequiredFieldsFilled
}
