import { FieldDefinition, MappingSchema } from '../../../types'

export const changeFieldTypesToText = (data: MappingSchema) => {
	const fieldsAutodetection = data.autodetectionResultsMap as Record<string, FieldDefinition>
	const fieldsAdditionalData = data.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap
	Object.keys(fieldsAutodetection).forEach((key) => {
		fieldsAutodetection[key].fieldType = 'TEXT'
	})

	Object.keys(fieldsAdditionalData).forEach((key) => {
		fieldsAdditionalData[key].forEach((value, index) => {
			fieldsAdditionalData[key][index].fieldType = 'TEXT'
		})
	})
}
