import { MatchType } from './MatchType'

export const GetMatchQualityMode = (matchGradePatterns: Array<string>): string => {
	if (matchGradePatterns.length === 1) {
		//this check is put in to prevent easy mode from being selected when string contains F or Z (which is only allowed in manual mode)
		return matchGradePatterns[0].includes('F') || matchGradePatterns[0].includes('Z')
			? MatchType.MANUAL
			: MatchType.EASY
	} else if (matchGradePatterns.length === 0) {
		return MatchType.EASY
	} else {
		return matchGradePatterns.length > 1 ? MatchType.MANUAL : MatchType.EASY
	}
}
