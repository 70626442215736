import { BaseDonutChart, IDonutChartProps } from '../../local-core-ui/base-donut-chart/base-donut-chart'
import styles from './report-donut-chart.module.scss'

interface ReportDonutChartData {
	field: string
	count: number
	rate: number
	color: string
}

interface ReportDonutChartProps {
	data: ReportDonutChartData[]
	size?: number
	statLabel?: string
	statValue?: string
}

const ReportDonutChart = ({ data, size = 128, statLabel = '', statValue = '' }: ReportDonutChartProps) => {
	// Formulas obtained by linear interpolation
	const statContainerPos = {
		left: (size * 0.5 - 58).toString() + 'px',
		top: (size * 0.5 - 25).toString() + 'px'
	}

	const chart = data.reduce(
		(acc, { field, rate, color }) => {
			acc.data.push({
				label: field,
				value: rate
			})

			acc.colors.push(color)
			return acc
		},
		{
			data: [],
			colors: []
		} as {
			data: IDonutChartProps['data']
			colors: string[]
		}
	)

	return (
		<div className={styles.donutChartContainer}>
			<div className={`${styles.totalStatContainer}`} style={{ ...statContainerPos }}>
				<div className={`${styles.statNumber}`}>{statValue}</div>
				<div className={`${styles.statTitle}`}>{statLabel}</div>
			</div>
			<BaseDonutChart
				colors={chart.colors}
				data={chart.data}
				showDividers
				showTooltip={false}
				showKeys={false}
				size={size}
				orderedColors
			/>
		</div>
	)
}

export { ReportDonutChart }
