import { ColorGrayDarker } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { DNBToggle } from '@dnb-uux-design-system/react'
import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataBlockElementSelector } from '../../components/data-block-element-selector/data-block-element-selector'
import { sideBlocksStringReplace } from '../../components/data-block-searcher/helpers/side-blocks-string-replace'
import { ExpandedSearchButtonNInput } from '../../components/expanded-search-button-n-input/expanded-search-button-n-input'
import { Accordion, AccordionHeader, AccordionPanel, Icon } from '../../local-core-ui'
import { ElementDetail, LevelDetail } from '../../types'
import { dataCategory, levelObj } from './dashboard-myDataBlocks'
import styles from './dashboard-myDataBlocks.module.scss'

interface DataBlockResultsPanelProps {
	blockList: Array<ElementDetail>
	dataBlocksFilterArray: dataCategory
	testId: string
	filterApplied: number
	showNoEntitlementMessage: boolean
}
export const DataBlockResultsPanel = ({
	blockList,
	dataBlocksFilterArray,
	testId,
	showNoEntitlementMessage
}: DataBlockResultsPanelProps): ReactElement => {
	const { t } = useTranslation()
	const searchTermlength = 3
	const [searchInputValue, setSearchInputValue] = useState('')
	const [expanded, setexpanded] = useState<boolean>(true)
	const onChangeFilter = (filterValue: string) => {
		setSearchInputValue(filterValue)
	}

	const renderDataBlockElements = (
		levels: Array<LevelDetail>,
		levelObj: levelObj,
		blockId: string
	): Array<ReactElement> => {
		return levels.reduce((elems: Array<ReactElement>, level, indexr) => {
			const levelId = level?.level?.levelId
			const isLevelSelected = levelObj[levelId as keyof typeof levelObj]
			if (isLevelSelected) {
				const elements = level?.elements
				const items: Array<ReactElement> = elements.map((element, index) => {
					if (
						searchInputValue.length < searchTermlength ||
						(searchInputValue.length >= searchTermlength &&
							element.displayName.toLowerCase().includes(searchInputValue.toLowerCase()))
					) {
						return (
							<Accordion
								testId={testId + '-accordionItem-' + index}
								allowMultipleOpen={true}
								id={testId + '-accordionItem-' + index}
								key={`${indexr}_${index}_${element.elementId}`}
								expanded={expanded}
							>
								<AccordionHeader
									title={sideBlocksStringReplace(element.displayName)}
									isOpen={true}
									controls={testId + '-accordion-panel' + index}
									stickyAdditionalText={true}
									testId={testId + '-accordion-header-' + index}
								></AccordionHeader>
								<AccordionPanel
									id={testId + '-accordion-panel' + index}
									testId={testId + '-accordion-panel' + index}
									isOpen={true}
									existControlElement={true}
								>
									<DataBlockElementSelector
										data-testid={testId + '-elements'}
										element={element}
										dataBlockName={blockId}
										level={levelId}
									/>
								</AccordionPanel>
							</Accordion>
						)
					} else return <></>
				})
				items && items.length && elems.push(...items)
			}
			return elems
		}, [])
	}

	return (
		<>
			<div data-testid={testId + '-searchCont'} className={styles.searchContainer}>
				<div className={styles.searchSection}>
					<ExpandedSearchButtonNInput
						value={searchInputValue}
						onChangeFilter={onChangeFilter}
						hint={t('mydata.block.searcher.searcher.keyword')}
						testId={testId}
						onBlurFunction={() => {
							if (searchInputValue.length === 0) {
							}
						}}
					/>
				</div>
				<div data-testid={testId + '-expandAll-text'} className="expandAlltext">
					{t('accordion.expand.all')}
				</div>
				<DNBToggle
					data-testid={testId + '-expandall'}
					checked={expanded}
					onChange={() => {
						setexpanded(!expanded)
					}}
				/>
			</div>
			{blockList && blockList?.length ? (
				<div data-testid={testId + '-search-result-cont'} className={styles.datablockSearchResultsCont}>
					{blockList.map((block, index) => {
						const levelObj = dataBlocksFilterArray[block.blockId as keyof typeof dataBlocksFilterArray]
						const levels = block?.levels
						return (
							dataBlocksFilterArray[block.blockId as keyof typeof dataBlocksFilterArray] &&
							renderDataBlockElements(levels, levelObj, block.blockId)
						)
					})}
				</div>
			) : showNoEntitlementMessage ? (
				<div className={styles.noDataBlocksContainer}>
					<div className={styles.noDataBlocksSpan}>
						<Icon testId="alert-big-error-modal" type="warning" size="mini" color={ColorGrayDarker} />
						<span className={styles.noDataBlocksMessage}>{t('subscriber.no.data.blocks')}</span>
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	)
}

const areEqual = (prevProps: DataBlockResultsPanelProps, nextProps: DataBlockResultsPanelProps) => {
	return (
		prevProps.filterApplied === nextProps.filterApplied &&
		prevProps.showNoEntitlementMessage === nextProps.showNoEntitlementMessage
	)
}
export const MemDataBlockResultsPanel = React.memo(DataBlockResultsPanel, areEqual)
