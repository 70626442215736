import { useMutation, UseMutationResult } from 'react-query'
import { SnowflakeData } from '../components/snowflake-form/snowflake-form'
import { useApi } from '../hooks'
import { System } from './api/getConnections'
import { updateConnection } from './api/updateConnection'

interface CreateConnectionParams {
	connectionData: SnowflakeData
	systemType: System
}

export const useUpdateConnection = (): UseMutationResult<unknown, unknown, CreateConnectionParams, unknown> => {
	const apiClient = useApi()

	const mutationFn = ({ connectionData, systemType }: CreateConnectionParams) =>
		updateConnection(apiClient, connectionData, systemType)

	return useMutation(mutationFn)
}
