import { AxiosInstance, AxiosResponse } from 'axios'
import { GetMatchRecommendationProfile } from '../../types/match-recommendation/GetMatchRecommendationProfile'

type recordMatchRecommendation = Record<string, GetMatchRecommendationProfile>
export const getMatchRecommendationProfile = async (
	apiClient: AxiosInstance,
	profiles: Array<string>
): Promise<Array<recordMatchRecommendation>> => {
	const url = '/pls/matchrules/matchRecommendation/'
	const orderProfiles: Record<string, Array<string>> = { stewardshipProfiles: ['Standard', 'Precision', 'Recall'] }

	const sortOptions = (data: recordMatchRecommendation, profile: string) => {
		const orderList = orderProfiles[`${profile}`]
		if (orderList !== undefined) {
			const listOrder: recordMatchRecommendation = {}
			orderList.forEach((profile: string) => {
				listOrder[`${profile}`] = data[`${profile}`]
			})
			return listOrder
		}
		return data
	}

	const promiseArray: Array<Promise<recordMatchRecommendation>> = profiles.map((profile: string) => {
		return new Promise<recordMatchRecommendation>((resolve, reject) => {
			apiClient
				.get(url + profile + '/')
				.then((response: AxiosResponse<recordMatchRecommendation>) => {
					const data = sortOptions(response.data, profile)
					const profileData: recordMatchRecommendation = {
						[`${profile}`]: data
					} as unknown as recordMatchRecommendation
					resolve(profileData)
				})
				.catch((e) => {
					reject(e)
				})
		})
	})
	return await Promise.all(promiseArray).then((response: Array<recordMatchRecommendation>) => response)
}
