import { isEmpty } from 'lodash-es'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColorGray } from '../../../../design-tokens/build/shared/token-colors.json'
import { Button, Divider, Modal } from '../../local-core-ui/'
import { ConnectionDetail } from '../../queries/api/getConnection'
import { System } from '../../queries/api/getConnections'
import snowflakes from '../integration-tile/png/snowflakes.png'
import { Snackbar, SnackType } from '../snackbar/snackbar'
import { SnowflakeData, SnowflakeForm } from '../snowflake-form/snowflake-form'
import styles from './snowflake-modal.module.scss'

interface SnowflakeModalProps {
	isEditable?: boolean
	open: boolean
	onCloseModal(): void
	title: string
	error?: string
	onCloseError?(): void
	closeText?: string
	continueText: string
	onContinue(snowData?: SnowflakeData): void
	isContinueDisable: boolean
	isCloseDisable?: boolean
	dataToFill?: ConnectionDetail
	showPassword?: boolean
	testId: string
}

export const SnowflakeModal = ({
	isEditable,
	open,
	onCloseModal,
	title,
	error,
	onCloseError,
	closeText,
	continueText,
	onContinue,
	isContinueDisable,
	isCloseDisable,
	dataToFill,
	showPassword,
	testId
}: SnowflakeModalProps): ReactElement => {
	const { t } = useTranslation()
	const [snowData, setSnowData] = useState<SnowflakeData>()
	const [enableContinue, setEnableContinue] = useState(false)

	useEffect(() => {
		if (
			snowData &&
			!isEmpty(snowData.username) &&
			(showPassword ? !isEmpty(snowData.password) : true) &&
			!isEmpty(snowData.organization) &&
			!isEmpty(snowData.domainName) &&
			!isEmpty(snowData.cloud) &&
			!isEmpty(snowData.region) &&
			!isEmpty(snowData.cloudRegion)
		) {
			setEnableContinue(true)
		} else {
			setEnableContinue(false)
		}
	}, [snowData, showPassword])

	return (
		<Modal open={open} onClose={() => onCloseModal()} testId="newSnowConnectionModal" fixWidth={false}>
			<div className={styles.mainContainer}>
				<div className={`${styles.titleContainer} ${styles.snowTitle}`}>
					{dataToFill?.system === System.Snowflake ? (
						<img className={styles.snowImg} src={snowflakes} alt={'snowflakes'} />
					) : (
						<div className={styles.emptyImg} />
					)}
					<h2 className={styles.title}>{title}</h2>
				</div>
				{error && onCloseError && !isEmpty(error) && (
					<div className={styles.errorContainer}>
						<Snackbar
							message={error}
							title={t('snackbar.title')}
							onClose={() => onCloseError()}
							type={SnackType.error}
						/>
					</div>
				)}
				<SnowflakeForm
					isEditable={isEditable}
					onChangeForm={(response) => setSnowData(response)}
					connectionData={dataToFill}
					showPassword={showPassword}
				/>
			</div>
			<div className={styles.buttonContainer}>
				<Divider color={ColorGray} />
				<div className={styles.buttons}>
					{closeText && (
						<Button
							size={'small'}
							type={'secondary'}
							text={closeText}
							onClick={() => onCloseModal()}
							isDisabled={isCloseDisable}
							testId={testId + '-cancel'}
						/>
					)}
					<div className={styles.continueButton}>
						<Button
							size={'small'}
							text={continueText}
							onClick={() => onContinue(snowData)}
							isDisabled={!enableContinue || isContinueDisable}
							testId={testId + '-continue'}
						/>
					</div>
				</div>
			</div>
		</Modal>
	)
}
