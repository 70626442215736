import { AxiosInstance, AxiosResponse } from 'axios'
import { TFunction } from 'i18next'
import { isEmpty } from 'lodash-es'
import { SalesforceEnvironment } from '../../../components/salesforce-modal/salesforce-modal'
import { createConnection, SalesforceData } from '../../../queries/api/createConnection'
import { CreateQueryStatus } from '../../../queries/api/getC4SActiveConnection'
import { System } from '../../../queries/api/getConnections'
import { SetUpSalesforceSettings } from '../../../types'
import { authenticateWithSalesforce } from '../../../utilities'
import { setApiErrorAction } from '../../apiError'
import { setConnectionErrors, setCreateQueryStatus, setIsSelectedSandbox } from '../../connection/connectionSlice'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'

const WRONG_ORGANIZATION_ERROR = "can't be updated with the details of a different systemId"
const C4S_CONNECTED_APP_MIGRATION_ERROR = 'Error updating Authentication during migration'
const sendSalesforceCodeToBackend = (
	code: string,
	isSandbox: boolean,
	integrationUsername: string,
	orgId: string,
	platform: string,
	apiClient: AxiosInstance
) => {
	const setUpSalesforceRequest: SetUpSalesforceSettings = {
		oAuthSettings: {
			oauth: {
				code: code,
				redirectUrl: window.location.origin,
				isSandbox: isSandbox
			}
		},
		username: integrationUsername
	}
	apiClient
		.post('/pls/integration/' + `${platform}` + '/organization/' + `${orgId}`, setUpSalesforceRequest)
		.catch((err: string) => console.log(err))
}

const createSalesforceConnection = (
	code: string,
	isSandbox: boolean,
	orgId: string,
	apiClient: AxiosInstance,
	dispatch: TDispatch,
	t: TFunction<'translation'>,
	connectionName?: string
) => {
	dispatch(setCreateQueryStatus(CreateQueryStatus.Loading))
	const salesforceData: SalesforceData = {
		oAuthSettings: {
			oauth: {
				code: code,
				redirectUrl: window.location.origin,
				isSandbox: isSandbox
			}
		},
		nameConnection: connectionName
	}

	if (!isEmpty(orgId)) {
		salesforceData.systemId = orgId
	}

	createConnection(apiClient, System.Salesforce, salesforceData).then((response) => {
		if (response.Success) {
			dispatch(setCreateQueryStatus(CreateQueryStatus.Success))
		} else if (response.Errors) {
			dispatch(setCreateQueryStatus(CreateQueryStatus.Error))
			if (response.Errors.length > 0 && response.Errors[0].includes(WRONG_ORGANIZATION_ERROR))
				dispatch(setConnectionErrors([t('connection.reauthenticate.wrong.org')]))
			if (response.Errors.length > 0 && response.Errors[0].includes(C4S_CONNECTED_APP_MIGRATION_ERROR))
				dispatch(setConnectionErrors([t('connection.reauthenticate.migration.error')]))
			else dispatch(setConnectionErrors(response.Errors))
		}
	})
}

export const authenticateAgainst3rdParty =
	(
		receivedCode: string,
		disableC4SOAuth: boolean,
		enableManagedConnectedApp: boolean,
		enableC4SAuthMgmt: boolean,
		instanceURL?: string,
		environment?: SalesforceEnvironment
	): AppThunk =>
	(dispatch: TDispatch, getState: TGetState): void => {
		const apiClient = getApiClient4Thunks(dispatch)
		const platform = getState().session.platform
		const orgId = getState().session.orgid
		const domain = getState().session.integrationDomain
		const isSandbox = getState().session.isSandbox || false
		const integrationUsername = getState().session.integrationUsername || ''
		const isAdmin = getState().session.user?.AccessLevel === 'EXTERNAL_ADMIN'
		const isC4SPlatform = platform === 'salesforce'

		if (!isC4SPlatform && enableC4SAuthMgmt) {
			dispatch(setIsSelectedSandbox(environment === SalesforceEnvironment.Sandbox))
		}

		const validateOAuth = () => {
			if (disableC4SOAuth && !enableC4SAuthMgmt) {
				dispatch(setApiErrorAction({ oAuthC4SError: { isAdmin, domain, isSandbox } }))
			} else {
				authenticateWithSalesforce(
					domain,
					enableManagedConnectedApp,
					isSandbox,
					enableC4SAuthMgmt,
					instanceURL,
					environment,
					isC4SPlatform
				)
			}
		}

		const getInstallStatus = () => {
			const url = '/pls/integration/' + `${platform}` + '/organization/' + `${orgId}`
			apiClient
				.get(url)
				.then((response: AxiosResponse<boolean>) => {
					if (!response.data) {
						validateOAuth()
					}
				})
				.catch((err: string) => console.log(err))
		}

		if (receivedCode) {
			sendSalesforceCodeToBackend(receivedCode, isSandbox, integrationUsername, orgId, platform, apiClient)
		} else {
			if (enableC4SAuthMgmt) {
				validateOAuth()
			} else {
				getInstallStatus()
			}
		}
	}

export const authenticateAgainst3rdPartyEnableC4SAuthMgmt =
	(receivedCode: string, t: TFunction<'translation'>): AppThunk =>
	(dispatch: TDispatch, getState: TGetState): void => {
		const platform = getState().session.platform
		const isSalesforce = platform === 'salesforce'
		const orgId = getState().session.orgid
		const isSandbox = isSalesforce ? getState().session.isSandbox || false : getState().connection.isSelectedSandbox
		const apiClient = getApiClient4Thunks(dispatch)
		const connectionName = getState().connection.selectedConnectionName
			? getState().connection.selectedConnectionName
			: undefined

		if (receivedCode) {
			createSalesforceConnection(receivedCode, isSandbox, orgId, apiClient, dispatch, t, connectionName)
		}
	}
