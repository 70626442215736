import { AxiosInstance, AxiosResponse } from 'axios'
import { cloneDeep } from 'lodash-es'
import { MappingSchema, Source, ValidateMappingResponse } from '../../../types'
import { TDispatch } from '../../index'
import { updateCurrentProjectAction } from '../actions'

export const readMappingInfoIntoStore = async (
	selectedSource: Source,
	apiClient: AxiosInstance,
	dispatch: TDispatch
) => {
	const mappingUrl = `/pls/sources/mappings?entityType=${selectedSource.entityType}&sourceId=${selectedSource.sourceId}`
	await apiClient
		.get(mappingUrl)
		.then(async (mappingResponse: AxiosResponse<MappingSchema>) => {
			const mappingValidationUrl = `/pls/sources/validate?entityType=${selectedSource.entityType}&sourceId=${selectedSource.sourceId}`

			const mapping = cloneDeep(mappingResponse.data)
			mapping.existingFieldDefinitionsMap = {}

			await apiClient
				.post(mappingValidationUrl, mapping)
				.then((mappingValidationResponse: AxiosResponse<ValidateMappingResponse>) => {
					dispatch(
						updateCurrentProjectAction({
							mappingInfo: {
								mapping: mappingResponse.data,
								validationResponse: mappingValidationResponse.data,
								isDataMappingValidated: true,
								isDataTypesValidated: true,
								counterGroupsMapping: [],
								haveGroupsIncomplete: false,
								areDataGroupsValidated: false,
								continueWithoutAddCountry: true,
								requireAdditionalCountry: false
							}
						})
					)
				})
				.catch((e) => {
					console.error(e)
				})
		})
		.catch((error) => {
			console.error(error)
		})
}
