import { ColorBlueBrand, ColorBluePrimary } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UseQueryResult } from 'react-query'
import {
	EnrichmentRuleSettingRow,
	EnrichmentRulesSettingsCollection
} from '../../../../components/enrichment-rules-settings-collection/enrichment-rules-settings-collection'
import { versionLimit } from '../../../../helpers/validateTradeUp'
import { useFeatures } from '../../../../hooks/useEntitlements'
import { DropdownOptions, Grid, LoadingState, Tab, Tabs } from '../../../../local-core-ui'
import { useDataBlocksEntitlementsByPurposeOfUse } from '../../../../queries/useDataBlocksEntitlementsByPurposeOfUse'
import { useDataBlocksMetadata } from '../../../../queries/useDataBlocksMetadata'
import { useEnrichmentRulesTemplate } from '../../../../queries/useEnrichmentRulesTemplate'
import { RootState, useAppDispatch, useAppSelector } from '../../../../store'
import { updateCurrentProjectAction } from '../../../../store/projectWizard/actions'
import { setEnrichMappingViewedAction } from '../../../../store/projectWizard/actions/setEnrichMappingViewedAction'
import { Block, EnrichmentRule, GetEnrichmentRulesResponse } from '../../../../types'
import { EnrichmentRuleDnBField } from '../../../../types/enrichment-rules/EnrichmentRuleDnBField'
import { EntityType } from '../../../../types/sources/EntityType'
import { sortDataBlockList } from '../utils/sortDataBlockList'
import ElementsToDisable from './disabled-elements.json'
import styles from './enrich-crm-data-source.module.scss'
import TradeUpElementsToDisabled from './trade-up-disabled-elements.json'

export interface BlockCollection {
	blockId: string
	displayName: string
	collection: Array<EnrichmentRuleSettingRow>
}

const getEnrichmentRulesFromBlocks = (blocks: Array<BlockCollection>): Array<EnrichmentRule> => {
	const rules: Array<EnrichmentRule> = []
	blocks?.map((enrichmentRule: BlockCollection) => {
		enrichmentRule.collection.forEach((row) => {
			if (row.selected && row.selected !== '') {
				rules.push({
					dnbField: row.id,
					sourceField: row.selected,
					writeRule: row.alwaysSelected ? 'RULE_WRITE_ALWAYS' : 'RULE_WRITE_WHEN_BLANK'
				})
			}
		})
	})
	return rules
}

export const EnrichCrmDataSource = (): ReactElement => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)

	const [dnbBlocks, setDnbBlocks] = useState<Array<BlockCollection>>([])
	const [selectedDnbElements, setSelectedDnbElements] = useState<Array<EnrichmentRuleSettingRow>>([])
	const [selectedDataBlockIdx, setSelectedDataBlockIdx] = useState(0)
	const [usedOptions, setUsedOptions] = useState<Array<string | undefined>>([])
	const [options, setOptions] = useState<Array<DropdownOptions>>([])
	const [mappedElements, setMappedElements] = useState<Array<EnrichmentRuleSettingRow>>([])
	const dataBlocksMetadata = useDataBlocksMetadata()
	const entityType = projectWizardState.currentProject?.source?.entityType || undefined
	const dataBlocksEntitlements: UseQueryResult<Array<Block>, unknown> = useDataBlocksEntitlementsByPurposeOfUse(
		projectWizardState.currentProject.purposeOfUse,
		entityType,
		true
	)
	const enrichmentRulesTemplateQuery: UseQueryResult<GetEnrichmentRulesResponse, unknown> =
		useEnrichmentRulesTemplate(projectWizardState.currentProject.source.id || '')
	const isTradeUpEnabled = useFeatures(['EnableTradeUp'])
	const isC4STradeUpEnabled = useFeatures(['EnableTradeUpC4S'])
	const isNotContact = projectWizardState?.currentProject?.source?.entityType !== EntityType.CONTACTS
	const isHigherVersion = projectWizardState?.currentProject?.versionSF || '' > versionLimit
	const isTradeUpToggleOn = projectWizardState.currentProject.tradeUp
	const disabledElements =
		isTradeUpEnabled && isC4STradeUpEnabled && isNotContact && isTradeUpToggleOn && isHigherVersion
			? [...ElementsToDisable, ...TradeUpElementsToDisabled]
			: ElementsToDisable

	useEffect(() => {
		if (enrichmentRulesTemplateQuery.isSuccess && enrichmentRulesTemplateQuery.data) {
			setOptions(
				enrichmentRulesTemplateQuery.data.sourceFields.map((field) => {
					return {
						label: field.screenName || '',
						value: field.columnName || '',
						disabled: !!enrichmentRulesTemplateQuery.data?.rules?.filter(
							(enrichmentRule) => enrichmentRule.sourceField === field.columnName
						).length
					}
				})
			)
			dispatch(
				updateCurrentProjectAction({
					enrichmentRules: enrichmentRulesTemplateQuery.data?.rules || []
				})
			)
		}
		dispatch(setEnrichMappingViewedAction(true))
		/**
		 * We only want to run this effect when data or isFetching or isSuccess from enrichmentRulesTemplateQuery or
		 * the id from the source in the current project changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		enrichmentRulesTemplateQuery.data,
		enrichmentRulesTemplateQuery.isFetching,
		enrichmentRulesTemplateQuery.isSuccess,
		projectWizardState.currentProject.source.id
	])

	useEffect(() => {
		if (
			dataBlocksEntitlements.isSuccess &&
			dataBlocksEntitlements.data &&
			dataBlocksMetadata.isSuccess &&
			dataBlocksMetadata.data &&
			enrichmentRulesTemplateQuery.isSuccess &&
			enrichmentRulesTemplateQuery.data
		) {
			//Create a map with D&B fields for easy consumption <(dnbField), Array of MapFieldDefinition>
			let compatibleSourceFieldsMap = new Map<string, EnrichmentRuleDnBField>()
			if (enrichmentRulesTemplateQuery.data) {
				compatibleSourceFieldsMap = enrichmentRulesTemplateQuery.data.dnbFields.reduce(
					(map: Map<string, EnrichmentRuleDnBField>, dnbField: EnrichmentRuleDnBField) => {
						map.set(dnbField.name, dnbField)
						return map
					},
					compatibleSourceFieldsMap
				)
				//Add rules as display only in case field is no longer available in Enrichment Layout
				enrichmentRulesTemplateQuery?.data.rules.forEach((rule) => {
					if (!compatibleSourceFieldsMap.has(rule.dnbField))
						compatibleSourceFieldsMap.set(rule.dnbField, {
							compatibleSourceFields: [],
							recommendedSourceField: undefined,
							name: rule.dnbField
						} as EnrichmentRuleDnBField)
				})
			}
			const blocks = dataBlocksEntitlements.data
				?.map((block) => {
					return {
						blockId: block.blockId,
						displayName:
							dataBlocksMetadata?.data?.find((metaData) => metaData.blockId === block.blockId)
								?.displayName || '',
						collection:
							block.levels
								?.map((level) => level.elements)
								?.reduce((accumulator, value) => accumulator.concat(value), [])
								.filter((element) => compatibleSourceFieldsMap.has(element.elementId))
								.map((enrichmentField) => {
									const rowElement = {
										id: enrichmentField.elementId,
										label: enrichmentField.displayName,
										alwaysSelected:
											block.blockId === 'entityresolution' ||
											enrichmentField.elementId === 'duns_traded_up',
										disabled: disabledElements.includes(enrichmentField.elementId),
										validOptions:
											compatibleSourceFieldsMap?.get(enrichmentField.elementId)
												?.compatibleSourceFields || [],
										recommendedOption: compatibleSourceFieldsMap?.get(enrichmentField.elementId)
											?.recommendedSourceField
									} as EnrichmentRuleSettingRow
									if (enrichmentRulesTemplateQuery.data?.rules?.length) {
										enrichmentRulesTemplateQuery.data?.rules.map(
											(enrichmentRule: EnrichmentRule) => {
												if (enrichmentRule.dnbField === rowElement.id) {
													rowElement.selected = enrichmentRule.sourceField
													rowElement.alwaysSelected =
														enrichmentRule.writeRule === 'RULE_WRITE_ALWAYS'
												}
											}
										)
									}
									return rowElement
								}) || []
					} as unknown as BlockCollection
				})
				.filter((block) => block.collection.length > 0)
				.sort(sortDataBlockList)
			setDnbBlocks(blocks)
			setUsedOptions(calculateUsedOptions(blocks))

			if (blocks.length != 0) {
				let items: Array<EnrichmentRuleSettingRow> = []
				for (const block of blocks) {
					const filteredItems = block.collection.filter((item) => item.selected)
					items = [...items, ...filteredItems]
				}
				setMappedElements(items)
			}
		}
		/**
		 * We only want to run this effect when isFetching from dataBlocksMetadata or isFetching from dataBlocksEntitlements
		 * or isFetching from enrichmentRulesTemplateQuery changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataBlocksMetadata.isFetching, dataBlocksEntitlements.isFetching, enrichmentRulesTemplateQuery.isFetching])

	useEffect(() => {
		if (dnbBlocks.length > 0) {
			setSelectedDnbElements(dnbBlocks[selectedDataBlockIdx].collection)
		}
	}, [dnbBlocks, dnbBlocks.length, selectedDataBlockIdx])

	const onTabChange = (tabIndex: number) => {
		setSelectedDataBlockIdx(tabIndex)
	}

	const calculateUsedOptions = (dnbBlocks: Array<BlockCollection>) => {
		return dnbBlocks.flatMap((block) => block.collection.map((row) => row.selected).filter((row) => row))
	}

	return (
		<Grid testId="container-crm-data-source" container>
			<Grid testId="enrich-crm-panel">
				<div className={styles.enrichCrmPanel}>
					<h1>
						{t('enrich.crm.title', {
							object: t(
								`upload.crm.source.${projectWizardState.currentProject.thirdPartyIntegration?.crmSource}`
							)
						})}
					</h1>
					<p className={styles.pUsed}>
						{t('enrich.crm.used')}
						<span>{t(projectWizardState.currentProject.purposeOfUse.domain)}</span>
					</p>
					<p className={styles.spanBoldGreen}>
						{t('enrich.crm.elements.mapped', {
							numberElements: mappedElements.length
						})}
					</p>
					<h2>
						{t('enrich.crm.description', {
							object: `${projectWizardState.currentProject.thirdPartyIntegration?.crmSource?.toLowerCase()}`
						})}
					</h2>
					{dataBlocksMetadata.isFetching ||
					dataBlocksEntitlements.isFetching ||
					enrichmentRulesTemplateQuery.isFetching ? (
						<LoadingState />
					) : (
						<>
							{dnbBlocks.length > 0 && (
								<Tabs
									colors={{
										defaultColor: ColorBluePrimary,
										hoverColor: ColorBlueBrand
									}}
									testId={'enrichCRM-tab-control'}
									onChange={onTabChange}
									value={selectedDataBlockIdx}
									maxVisibleTabs={4}
								>
									{dnbBlocks.map((block) => {
										return (
											<Tab
												label={block.displayName}
												key={block.blockId}
												id="tabActive"
												aria-controls="projectListContainer"
											/>
										)
									})}
								</Tabs>
							)}
							<EnrichmentRulesSettingsCollection
								crmSource={projectWizardState.currentProject.thirdPartyIntegration?.crmSource || ''}
								rows={selectedDnbElements}
								options={options}
								disabled={projectWizardState.loadingNextStep}
								testId={'c4sDD'}
								usedOptions={usedOptions}
								onChange={(changedRow) => {
									const rowIdx = selectedDnbElements.findIndex((row) => row.id === changedRow.id)
									if (rowIdx > -1) {
										selectedDnbElements[rowIdx] = changedRow
										dnbBlocks[selectedDataBlockIdx].collection = selectedDnbElements
										if (changedRow.selected === '') {
											setMappedElements(
												mappedElements.filter((element) => element.label != changedRow.label)
											)
										} else {
											setMappedElements([
												...mappedElements.filter(
													(element) => element.label != changedRow.label
												),
												changedRow
											])
										}
									}
									setSelectedDnbElements(selectedDnbElements)
									dispatch(
										updateCurrentProjectAction({
											enrichmentRules: getEnrichmentRulesFromBlocks(dnbBlocks)
										})
									)

									setUsedOptions(calculateUsedOptions(dnbBlocks))
								}}
								collectionId={dnbBlocks[selectedDataBlockIdx]?.blockId}
							/>
						</>
					)}
				</div>
			</Grid>
		</Grid>
	)
}
