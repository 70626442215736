import cx from 'classnames'
import { useEffect, useState } from 'react'
import { MatchRule, MatchStyle } from '../../../../types'
import { BaseReportSection } from '../../types/BaseReportSection'
import styles from './appendix.module.scss'
import { CodeDescriptions } from './code-description/code-descriptions'
import { FullGeographicalDistribution } from './full-geographical-distribution/full-geographical-distribution'

interface AppendixProps extends BaseReportSection {
	matchRules: MatchRule[]
}

const Appendix = ({ uploadReport, matchRules }: AppendixProps) => {
	const [displayCodesDescription, setDisplayCodesDescription] = useState(false)
	const geoDistributionReport = uploadReport ? uploadReport.geoDistributionReport : null
	const displayFullGeographicalDistribution =
		geoDistributionReport &&
		geoDistributionReport.geoDistributionList &&
		geoDistributionReport.geoDistributionList.length > 10

	useEffect(() => {
		if (matchRules.length > 0) {
			setDisplayCodesDescription(matchRules[0].matchStyle === MatchStyle.MATCH_RECOMMENDATION)
		}
	}, [matchRules])

	return (
		<div className={cx(styles.appendix)}>
			{displayCodesDescription && <CodeDescriptions />}
			{displayFullGeographicalDistribution && (
				<FullGeographicalDistribution
					geoDistributionReport={geoDistributionReport}
					firstAppendix={!displayCodesDescription}
				/>
			)}
		</div>
	)
}

export { Appendix }
