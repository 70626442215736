import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { abbreviateNumber } from '../../helpers'
import { DataReport } from '../../types'
import styles from './overall-stats.module.scss'

export interface OverallStatsProps {
	dataReport: DataReport | undefined
}

export const OverallStats: FC<OverallStatsProps> = ({ dataReport }) => {
	const { t } = useTranslation()

	const renderStat = (stat: string, header: string) => {
		return (
			<div className={styles.dataStatContainer}>
				<div className={styles.dataStat}>{stat}</div>
				<h2 className={styles.headingH2Semibold}>{t(header)}</h2>
			</div>
		)
	}

	const getAvgFillRates = () => {
		if (dataReport) {
			const eachInputPresence = dataReport.inputPresenceReport?.inputPresenceList.reduce(
				(counter, input) => counter + input.count,
				0
			)
			const totalInputPresence = dataReport.basicStats?.totalSubmitted
			const avgFillRates =
				eachInputPresence / (totalInputPresence * dataReport.inputPresenceReport?.inputPresenceList.length)
			return `${Math.floor(Number.isNaN(avgFillRates) ? 0 : avgFillRates * 100)}%`
		} else return '0%'
	}

	const getRecordsMatched = () => {
		return `${abbreviateNumber(dataReport?.matchToDunsReport?.matched || 0, 0)}`
	}

	const getCountriesMarkets = () => {
		const countriesMarkets = dataReport?.geoDistributionReport?.geoDistributionList.filter(
			(country) => country.count > 0
		)
		return `${countriesMarkets?.length || 0}`
	}

	const getDuplicates = () => {
		return `${abbreviateNumber(dataReport?.duplicationReport?.duplicateRecords || 0, 0)}`
	}

	return (
		<div className={styles.overallStatsContainer}>
			{renderStat(getAvgFillRates(), 'overall-stat-avg-fill-rate')}
			{renderStat(getRecordsMatched(), 'overall-stat-records-matched')}
			{renderStat(getCountriesMarkets(), 'overall-stat-countries-&-markets')}
			{renderStat(getDuplicates(), 'overall-stat-duplicates')}
		</div>
	)
}
