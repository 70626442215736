export const MIN_MATCH_STRING_CHARS = 7
export const MAX_MATCH_STRING_CHARS = 11
export const ANY_GRADE = '.'

export const generateMatchRegex = (matchGrades: string[]): string => {
	let count = 1
	let matchRegex = ''

	for (let i = 0; i < matchGrades.length; i++) {
		if (matchGrades[i] === matchGrades[i + 1]) {
			count++
		} else {
			if (count === 1) {
				matchRegex += matchGrades[i]
			}

			if (count > 1) {
				matchRegex += `${matchGrades[i]}{${count}}`
			}

			count = 1
		}
	}

	const remainingGrades = MAX_MATCH_STRING_CHARS - matchGrades.length

	if (remainingGrades === 1) {
		matchRegex += `${ANY_GRADE}`
	}

	if (remainingGrades > 1) {
		matchRegex += `${ANY_GRADE}{${remainingGrades}}`
	}

	return matchRegex
}
