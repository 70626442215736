import { orderBy } from 'lodash-es'
import { CheckBox, Dropdown, DropdownOptions, Icon, LoadingState } from '../../local-core-ui/'

import { ChangeEvent, ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PurposeOfUse, UserDetail } from '../../types'
import { MatchTemplateFacade } from '../match-template-picker/match-template-picker'
import { sbniType, SearchButtonNInput } from '../search-button-n-input/search-button-n-input'
import styles from './template-tile-picker.module.scss'

export interface ActiveFilters {
	showTemplatesForDomain: boolean
	sortAscending: boolean
	searchValue: string
	sbniTypeShow: sbniType
	sortBy: TemplatesFilter['sort']
}

interface TemplateData {
	updateTime?: number
	createTime: number
	name: string
	createdBy: UserDetail
	purposeOfUse?: PurposeOfUse
}

interface TemplateTilePickerProps {
	listTemplates?: Array<TemplateData>
	testId: string
	title: string
	purposeOfUse?: PurposeOfUse
	sortByPurposeOfUse?: boolean
	children?: ReactNode

	onChangeFilteredTemplates(
		filteredTemplates: Array<TemplateData> | Array<MatchTemplateFacade>,
		filtersActive?: ActiveFilters
	): void

	filters?: ActiveFilters
	isLoading?: boolean
}

interface TemplatesFilter {
	sort: 'byName' | 'byCreatedBy' | 'byDate' | 'byPurposeOfUse'
	order: 'asc' | 'desc'
}

export const TemplateTilePicker = ({
	listTemplates = [],
	testId,
	title,
	children,
	purposeOfUse,
	sortByPurposeOfUse,
	onChangeFilteredTemplates,
	filters = {
		showTemplatesForDomain: false,
		sortAscending: true,
		searchValue: '',
		sbniTypeShow: sbniType.button,
		sortBy: sortByPurposeOfUse ? 'byPurposeOfUse' : 'byDate'
	},
	isLoading
}: TemplateTilePickerProps): ReactElement => {
	const { t } = useTranslation()
	const [showTemplatesForDomain, setShowTemplatesForDomain] = useState<boolean>(filters?.showTemplatesForDomain)
	const [sortAscending, setSortAscending] = useState<boolean>(filters?.sortAscending)
	const [searchValue, setSearchValue] = useState<string>(filters?.searchValue)
	const [sbniTypeShow, setSbniTypeShow] = useState(filters?.sbniTypeShow)
	const [sortBy, setSortBy] = useState<TemplatesFilter['sort']>(filters?.sortBy)
	const childRef = useRef<HTMLDivElement>(null)

	const sortOptions: Array<DropdownOptions> = [
		{
			label: t('template.tile.picker.sortBy.date'),
			value: 'byDate'
		},
		{
			label: t('template.tile.picker.sortBy.name'),
			value: 'byName'
		},
		{
			label: t('template.tile.picker.sortBy.createdBy'),
			value: 'byCreatedBy'
		}
	]
	if (sortByPurposeOfUse) {
		sortOptions.push({
			label: t('template.tile.picker.sortBy.purposeOfUse'),
			value: 'byPurposeOfUse'
		})
	}

	const onChangeShowTemplates = (event: ChangeEvent<HTMLInputElement>) => {
		setShowTemplatesForDomain(event.target.checked)
	}

	const onChangeSort = (selection: TemplatesFilter['sort']) => {
		setSortBy(selection)
	}

	const onClickOrder = () => {
		setSortAscending(!sortAscending)
	}

	const onChangeFilter = (value: string) => {
		setSearchValue(value)
	}

	const onClickSearch = () => {
		setSbniTypeShow(sbniTypeShow === sbniType.input ? sbniType.button : sbniType.input)
	}

	const defineSortFunction = (sort: TemplatesFilter['sort']) => {
		switch (sort) {
			case 'byDate':
				return (template: TemplateData) => template.updateTime || template.createTime
			case 'byName':
				return (template: TemplateData) => template.name.toLowerCase()
			case 'byCreatedBy':
				return (template: TemplateData) => template.createdBy.FirstName.toLowerCase()
			case 'byPurposeOfUse':
				return (template: TemplateData) =>
					template.purposeOfUse ? [template.purposeOfUse.domain, template.purposeOfUse.recordType] : []
		}
	}

	const filterAndSortTemplates = () => {
		const filteredTemplatesList = [...listTemplates]
		const filterList = filteredTemplatesList.filter((template: TemplateData) => {
			const templateName = template.name.toLowerCase()
			const templateCreatedBy = template.createdBy.FirstName.toLowerCase()
			const templatePurposeOfUse =
				purposeOfUse && template.purposeOfUse
					? template.purposeOfUse.domain + ' ' + template.purposeOfUse.recordType
					: ''
			const selectedPurposeOfUse = purposeOfUse ? purposeOfUse.domain + ' ' + purposeOfUse.recordType : ''
			const includesSearchValue =
				templateName.includes(searchValue.toLowerCase()) ||
				templateCreatedBy.includes(searchValue.toLowerCase())
			if (
				!showTemplatesForDomain ||
				(template.purposeOfUse && !template.purposeOfUse.domain) ||
				(showTemplatesForDomain && templatePurposeOfUse === selectedPurposeOfUse)
			) {
				return includesSearchValue
			}
		})
		const orderByCriteria: TemplatesFilter['order'] = sortAscending ? 'asc' : 'desc'
		const filterAndSortList = orderBy(filterList, defineSortFunction(sortBy), orderByCriteria)
		onChangeFilteredTemplates(filterAndSortList, {
			showTemplatesForDomain: showTemplatesForDomain,
			sortAscending: sortAscending,
			searchValue: searchValue,
			sbniTypeShow: sbniTypeShow,
			sortBy: sortBy
		})
	}

	useEffect(() => {
		filterAndSortTemplates()
		// This should be only executed when a filter criteria changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listTemplates, searchValue, sortAscending, sortBy, showTemplatesForDomain])

	return (
		<div className={styles.templatePickerModalContent}>
			<h1 data-testid="template-picker-title" className={styles.templatePickerTitle}>
				{title}
			</h1>
			{purposeOfUse && (
				<div className={styles.templatePickerShowDomainContainer}>
					<p className={styles.templatePickerShowTemplates}>{t('template.tile.picker.show.templates')}</p>
					<CheckBox
						id="tile-picker-show-domain"
						label={t('template.tile.picker.for.domain', { domain: purposeOfUse.domain })}
						checked={showTemplatesForDomain}
						testId={testId + 'tile-picker-show-domain'}
						onChange={onChangeShowTemplates}
					/>
				</div>
			)}
			<div className={styles.templateTilePickerFilters}>
				<div className={styles.pickerSortBy}>
					<Dropdown
						id="picker-sort-by"
						hint={''}
						onChangeFunction={onChangeSort}
						label={''}
						options={sortOptions}
						testId={testId + 'picker-sort-by'}
						selected={sortBy}
					/>
				</div>
				<button
					className={styles.templateTilePickerOrderButton}
					onClick={onClickOrder}
					data-testid={testId + 'template-tile-picker-order-by'}
				>
					<Icon
						testId="arrows-template-tile-picker"
						type={sortAscending ? 'arrow-sort-down' : 'arrow-sort-up'}
					/>
				</button>
				<div className={styles.templateTilePickerSearchButton}>
					<SearchButtonNInput
						value={searchValue}
						onChangeFilter={onChangeFilter}
						hint={t('template.tile.picker.search.hint')}
						testId={testId + 'template-tile-picker-filter'}
						type={sbniTypeShow}
						onClick={onClickSearch}
						showButtonAsIcon={true}
					/>
				</div>
			</div>
			<div className={styles.templateTilePickerTemplates}>
				{isLoading ? (
					<div>
						<LoadingState />
					</div>
				) : (
					<div
						data-testid="template-tile-picker-list"
						className={styles.templateTilePickerList}
						ref={childRef}
					>
						{children}
					</div>
				)}
			</div>
		</div>
	)
}
