/**
 * @class ContextMenu
 */

import { ColorBlueBrand } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import cx from 'classnames'
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { getCoords } from '../helpers/getCoords'
import Icon from '../icon/icon'
import styles from './context-menu.module.scss'

export interface ContextMenuOption {
	label: string
	action: string
	disabled?: boolean
}

interface DotsMenuProps {
	id?: string
	options: ContextMenuOption[]
	onMenuClicked: (option: string, indx: number) => void
	alignment?: 'right' | 'left'
	testId: string
	withIndicator?: string
}

interface RelativePosition {
	top: number
	left: number
}
export const ContextMenu: FC<PropsWithChildren<DotsMenuProps>> = ({
	children,
	id = undefined,
	options,
	onMenuClicked,
	alignment = 'right',
	testId,
	withIndicator = ''
}: PropsWithChildren<DotsMenuProps>) => {
	const [showMenu, setShowMenu] = useState(false)
	const menuRef = useRef(null)
	const buttonRef = useRef(null)
	const [myId] = useState(id ? id : Math.floor(Math.random() * 1000 + 1).toString())

	const handleOutsideClick = (event) => {
		if (menuRef.current && !menuRef.current.contains(event.target)) {
			onContextClicked(true)
		}
	}

	const onContextClicked = (reset = false): void => {
		const newShowMenuValue = !(reset || showMenu)
		menuRef.current.className = cx(
			styles.context,
			styles.menu,
			styles[alignment],
			newShowMenuValue ? styles.show : styles.hide
		)
		menuRef.current.ariaHidden = !newShowMenuValue
		setShowMenu(newShowMenuValue)
		if (newShowMenuValue) {
			repositionMenu()
			addListeners()
		} else {
			removeListeners()
		}
	}

	const repositionMenu = () => {
		if (buttonRef.current) {
			const parentBoundingRect = getCoords(buttonRef.current)
			const marginLeft = parseInt(
				window.getComputedStyle(document.body).getPropertyValue('margin-left').replace('px', '')
			)

			menuRef.current.style.top = parentBoundingRect.top - 8 + 'px'
			if (alignment === 'right') menuRef.current.style.left = parentBoundingRect.left - marginLeft + 8 + 'px'
			else menuRef.current.style.left = parentBoundingRect.right - marginLeft - 198 + 'px'
		}
	}

	useEffect(() => {
		addListeners()
		return () => removeListeners()
	}, [menuRef.current])

	useEffect(() => {
		return () => {
			document.getElementById('context-menu-options-' + testId).remove()
			removeListeners()
		}
	}, [myId])

	const hash = options.map((option) => option.label).join('')
	useEffect(() => {
		let mounted = true
		const menuContainerId = 'context-menu-options-' + testId
		const menuContainer = document.createElement('div')
		menuContainer.id = menuContainerId
		menuContainer.style.cssText = 'position:absolute;top:0;'
		menuContainer.className = cx(
			styles.context,
			styles.menu,
			styles[alignment],
			showMenu ? styles.show : styles.hide
		)
		document.body.appendChild(menuContainer)

		menuRef.current = menuContainer
		const rootContextMenu = createRoot(menuContainer)
		rootContextMenu.render(
			<ul className={styles.optionList} data-testid={`ul-${testId}`}>
				{options.map((option, indx) => {
					if (mounted)
						return (
							<li
								key={indx}
								className={cx(
									styles.option,
									{ [styles.disabledState]: option.disabled },
									withIndicator !== '' && withIndicator === option.action ? styles.indicator : ''
								)}
								data-action={option.action}
								onClick={(e) => {
									if (!option.disabled) {
										e.preventDefault()
										setShowMenu(false)
										onContextClicked(true)
										onMenuClicked(option.action, indx)
									}
								}}
								data-testid={testId + '-option-' + option.action}
							>
								<>
									{option.label}
									{withIndicator === option.action && (
										<Icon type={'simple-check'} color={ColorBlueBrand} size={'mini'} />
									)}
								</>
							</li>
						)
				})}
			</ul>
		)

		return () => {
			mounted = false
		}
	}, [myId, hash])

	const removeListeners = () => {
		document.removeEventListener('mousedown', handleOutsideClick)
		document.removeEventListener('wheel', repositionMenu)
	}

	const addListeners = () => {
		document.addEventListener('mousedown', handleOutsideClick)
		document.addEventListener('wheel', repositionMenu)
	}

	return (
		<span
			className={styles.context}
			ref={buttonRef}
			onClick={() => onContextClicked()}
			onKeyPress={() => onContextClicked()}
			id={'context-menu-' + myId}
			data-testid={testId}
		>
			{children ? (
				children
			) : (
				<Icon testId={`context-icon-dots-${testId}`} type="three-dots" color={'ColorGrayDark'} />
			)}
		</span>
	)
}
export default ContextMenu
