import { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import iconCheck from '../../assets/images/icon-green-check.svg'
import { Icon } from '../../local-core-ui'
import ToggleSwitchAssignment from '../../local-core-ui/toggle-switch-assignment/toggle-switch-assignment'
import { useSetTransactionalMatch } from '../../queries/useSetTransactionalMatch'
import { useSource } from '../../queries/useSource'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import { updateCurrentProjectAction } from '../../store/projectWizard/actions'
import { AssignmentConfig } from '../../types'
import { EntityType } from '../../types/sources/EntityType'
import styles from './source-transactional-match.module.scss'

interface SourceTransactionalMatchProps {
	sourceId: string
	readOnly: boolean
	wizardMode: boolean
}

const assigmentDefault = {
	enabled: true,
	triggerAssignmentRules: true
}

export const SourceTransactionalMatch: FC<SourceTransactionalMatchProps> = ({
	sourceId,
	readOnly,
	wizardMode
}: SourceTransactionalMatchProps) => {
	const setTransactionalMatchMutation = useSetTransactionalMatch(sourceId)
	const sourceQuery = useSource(sourceId, true)
	const { t } = useTranslation()
	const [transactionalAssignmentMatch, setTransactionalAssignmentMatch] = useState<AssignmentConfig>(assigmentDefault)
	const [transactionalMatch, setTransactionalMatch] = useState<boolean>(true)
	const [enforceAssignment, setEnforceAssignment] = useState<boolean>(true)
	const projectWizardSelector = (state: RootState) => state.projectWizard
	const projectWizard = useAppSelector(projectWizardSelector)
	const dispatch = useAppDispatch()
	const [entityType, setEntityType] = useState<string>('')
	const [documentType, setDocumentType] = useState<string>('')

	const changeTransactionalMatch = (config: AssignmentConfig) => {
		setTransactionalAssignmentMatch(config)

		if (wizardMode) {
			const currentIntegrationInfo = projectWizard.currentProject.source.integrationInfo
			dispatch(
				updateCurrentProjectAction({
					source: {
						integrationInfo: {
							...currentIntegrationInfo,
							refreshRematchConfig: config,
							isTransactional: config.enabled
						}
					}
				})
			)
		} else setTransactionalMatchMutation.mutate(config)
		// The Wizard handles this on its own, so we don't need to set it explicitly
	}

	const handleSwitchTransactionalMatch = (event: ChangeEvent<HTMLInputElement>) => {
		setTransactionalMatch(event.target.checked)

		changeTransactionalMatch({
			...transactionalAssignmentMatch,
			enabled: event.target.checked,
			triggerAssignmentRules: event.target.checked ? enforceAssignment : false
		})

		// }
		// else {
		// 	checkRefreshRunning()
		// }
	}

	const changeEnforceAssignmentRules = (config: AssignmentConfig) => {
		setTransactionalAssignmentMatch(config)
		if (wizardMode) {
			const currentIntegrationInfo = projectWizard.currentProject.source.integrationInfo
			dispatch(
				updateCurrentProjectAction({
					source: {
						integrationInfo: {
							...currentIntegrationInfo,
							refreshRematchConfig: config
						}
					}
				})
			)
		} else setTransactionalMatchMutation.mutate(config)
		// The Wizard handles this on its own, so we don't need to set it explicitly
	}

	const handleSwitchEnforceAssignmentRules = (event: ChangeEvent<HTMLInputElement>) => {
		setEnforceAssignment(event.target.checked)
		// if (event.target.checked) {
		changeEnforceAssignmentRules({ ...transactionalAssignmentMatch, triggerAssignmentRules: event.target.checked })
		// }
		// else {
		// 	checkRefreshRunning()
		// }
	}

	useEffect(() => {
		setEntityType(sourceQuery.data?.entityType ?? '')
		setDocumentType(sourceQuery.data?.integrationInfo?.documentType ?? '')
		if (!wizardMode && sourceQuery.isSuccess && !sourceQuery.isFetching) {
			setTransactionalMatch(sourceQuery.data?.integrationInfo?.isTransactional || false)
			setEnforceAssignment(
				sourceQuery.data?.integrationInfo?.refreshRematchConfig.triggerAssignmentRules || false
			)
		}
		/**
		 * We only want to run this effect when the flag isFetching from sourceQuery or wizardMode prop changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceQuery.isFetching, wizardMode])

	useEffect(() => {
		if (wizardMode) {
			if (projectWizard.currentProject.source.integrationInfo?.isTransactional) {
				setTransactionalMatch(projectWizard.currentProject.source.integrationInfo.isTransactional || false)
				setEnforceAssignment(
					sourceQuery.data?.integrationInfo?.refreshRematchConfig.triggerAssignmentRules || false
				)
			}
		}
		/**
		 * We only want to run this effect when wizardMode prop changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [wizardMode])

	return (
		<div>
			{readOnly ? (
				<div className={styles.rowTransactional}>
					<div className={styles.refreshSettingsProjectDetails}>
						<div className={styles.sourceSettingsProjectDetails}>
							<div className={styles.refreshLabelProjectDetails}>
								{t('source.transactional.match.onDemand')}
							</div>
							{sourceQuery.data?.integrationInfo?.isTransactional === true ? (
								<div className={styles.checkContainerProjectDetails}>
									<img
										className={styles.checkIconProjectDetails}
										src={iconCheck}
										alt="transactional-true"
									/>
									<div className={styles.transactionalLabelProjectDetails}>
										{t('source.transactional.match')}
									</div>
								</div>
							) : (
								<div className={styles.checkContainerProjectDetails}>
									<Icon
										testId="denied-source-transactional-match"
										type={'denied'}
										color={'ColorJetBlack'}
										size={'mini'}
									/>
									<span className={styles.disableLabelProjectDetails}>
										{t('source.transactional.match')}
									</span>
								</div>
							)}
							{(entityType !== EntityType.CONTACTS || documentType === 'Lead') && (
								<>
									{sourceQuery.data?.integrationInfo?.refreshRematchConfig.triggerAssignmentRules ===
									true ? (
										<div className={styles.checkContainerProjectDetails}>
											<img
												className={styles.checkIconProjectDetails}
												src={iconCheck}
												alt="assignment-true"
											/>
											<div className={styles.transactionalLabelProjectDetails}>
												{t('source.enforce.assignment.rules')}
											</div>
										</div>
									) : (
										<div className={styles.checkContainerProjectDetails}>
											<Icon
												testId="denied-source-assignment-rules"
												type={'denied'}
												color={'ColorJetBlack'}
												size={'mini'}
											/>
											<span className={styles.disableLabelProjectDetails}>
												{t('source.enforce.assignment.rules')}
											</span>
										</div>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			) : (
				<>
					<div>
						<div className={styles.transactionalMatchAssignmentTitle}>
							{t('source.transactional.match.onDemand')}
						</div>
						<div className={styles.transactionalMatchAssignment}>
							<ToggleSwitchAssignment
								id="transactional-match-switch"
								label={t('source.transactional.match')}
								checked={transactionalMatch}
								onChange={handleSwitchTransactionalMatch}
								testId="source-switch-transactional-match"
							/>
						</div>
						{(entityType !== EntityType.CONTACTS || documentType === 'Lead') && (
							<div className={styles.transactionalMatchAssignmentRule}>
								<ToggleSwitchAssignment
									id="enforce-assignment-rules-switch"
									label={t('source.enforce.assignment.rules')}
									checked={!transactionalMatch ? false : enforceAssignment}
									disabled={!transactionalMatch}
									onChange={handleSwitchEnforceAssignmentRules}
									testId="source-switch-enforce-assignment-rules"
								/>
							</div>
						)}
					</div>
				</>
			)}
		</div>
	)
}
