import { Fragment, ReactElement, useState } from 'react'
import styles from './StringComparisonView.module.scss'

export interface StringComparisonViewProps {
	original?: string
	compare?: string
	testId?: string
}
export const StringComparisonView = ({ original, compare, testId }: StringComparisonViewProps): ReactElement => {
	const splitText = (sentence?: string): string[] => {
		if (sentence)
			return sentence
				.replace(/\.+/g, '^.^') // We encapsulate the . in between ^ so we dont remove the sign on splitting
				.replace(/,+/g, '^,^') // We encapsulate the , in between ^ so we dont remove the sign on splitting
				.split(/[\s^]+/)
		else return []
	}

	const isNullOrUndefined = (word?: string) => {
		return word === null || word === undefined || word === ''
	}
	const [originalWords] = useState(splitText(original?.toLowerCase()))
	const [tokens] = useState(splitText(compare))
	let missmatchIdx = 0

	return (
		<span className={styles.comparison} data-testid={testId}>
			{tokens.map((word, idx) => {
				const doesWordExist = originalWords.includes(word?.toLowerCase())
				const isNextToPunctuation = /[,.]+/.test(tokens[idx + 1]) || idx + 1 === tokens.length

				return (
					<Fragment key={idx}>
						{doesWordExist ? (
							`${word}${isNextToPunctuation ? '' : ' '}`
						) : (
							<span
								key={`word-${word}-idx-${idx}`}
								className={`${isNullOrUndefined(original) ? styles.additional : styles.differing}`}
								data-testid={`${testId}-${missmatchIdx++}`}
							>
								{`${word}${isNextToPunctuation ? '' : ' '}`}
							</span>
						)}
					</Fragment>
				)
			})}
		</span>
	)
}
