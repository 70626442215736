import { Element, ElementDetail, ElementsToUpdate, LevelDetail, RelationOriginalReplacements } from '../types'

export const filterUpdatableElems = (
	dataList: Array<ElementDetail>,
	selectedElements: Array<string>
): Record<string, ElementsToUpdate> => {
	const copyList: Array<ElementDetail> = JSON.parse(JSON.stringify(dataList))
	const relationDeprecateReplace: Record<string, ElementsToUpdate> = {}
	copyList.forEach((block: ElementDetail) => {
		let deprecatedElements: Array<RelationOriginalReplacements> = []
		block.levels.forEach((level: LevelDetail) => {
			const elementsByLevel: Array<RelationOriginalReplacements> = []
			level.elements.forEach((element: Element) => {
				if (selectedElements.includes(element.elementId)) {
					if (element.deprecated) {
						elementsByLevel.push({ original: element, replacements: [] })
					}
				}
			})
			deprecatedElements = deprecatedElements.concat(elementsByLevel)
		})
		if (deprecatedElements.length > 0) {
			relationDeprecateReplace[`${block.blockId}`] = { elements: deprecatedElements }
		}
	})
	return relationDeprecateReplace
}
