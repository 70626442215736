import cx from 'classnames'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import deleteIcon from '../../assets/images/DeleteIcon.png'
import { Dropdown, DropdownOptions } from '../../local-core-ui'
import styles from './dropdown-collection.module.scss'

export interface DropdownCollectionRow {
	id: string
	label: string
	title?: string
	isRequired: boolean
	selected?: string
	errorMessage?: string
	isValid?: boolean
	dropdownValues: Array<DropdownOptions>
	isAlternateExpanded?: boolean
	onCollapse?: () => void
	showAdditionalInfo?: boolean
}

export interface IDropdownCollectionProps {
	onChangeFunction(rows: Array<DropdownCollectionRow>): void
	unselectText?: string
	rows: Array<DropdownCollectionRow>
	testId: string
	title?: string
	collectionId?: string
	alternativeFields?: Record<string, string>
	disabled?: boolean
	isAlternateExpanded?: boolean
	onCollapse?: () => void
	showAdditionalInfo?: boolean
	showAlert?: string
	showWarningIcon?: boolean
}

export const DropdownCollection = ({
	onChangeFunction,
	unselectText,
	rows,
	title,
	testId,
	collectionId = '',
	alternativeFields = undefined,
	disabled = false,
	isAlternateExpanded,
	onCollapse,
	showAdditionalInfo,
	showAlert,
	showWarningIcon = false
}: IDropdownCollectionProps): ReactElement => {
	const { t } = useTranslation()

	const updateSelected = (id: string, selected: string) => {
		const rowIndexToUpdate = rows.findIndex((row) => row.id === id)
		if (selected) {
			rows[rowIndexToUpdate].selected = selected
		} else {
			delete rows[rowIndexToUpdate].selected
		}
		onChangeFunction(rows)
	}
	const isDynamicRequired = (field: string) => {
		if (alternativeFields)
			if (Object.keys(alternativeFields).includes(field)) {
				return true
			}
		return false
	}

	return (
		<div data-testid="dropdown-collection-container" className={styles.dropdownCollection}>
			{rows.map((row: DropdownCollectionRow, fieldIndex: number) => (
				<div
					key={fieldIndex}
					className={cx(
						styles.rowSection,
						isAlternateExpanded && fieldIndex > 0 ? styles.paddingDropdownAlternate : ''
					)}
					aria-required={row.isRequired}
					aria-selected={row.selected === undefined}
					data-testid="dropdown-row-section"
				>
					{onCollapse !== undefined ? (
						isAlternateExpanded ? (
							fieldIndex === rows.length - 1 ? (
								<div className={styles.labelText}>
									<span>{row.label}</span>
									<span className={styles.plusIcon} onClick={onCollapse}>
										<img className={styles.deleteIcon} src={deleteIcon}></img>
									</span>
								</div>
							) : (
								<div className={styles.labelText}>{row.label}</div>
							)
						) : (
							<div className={styles.labelText}>
								<span>{row.label}</span>
								{showAdditionalInfo && (
									<span onClick={onCollapse} className={styles.plusIcon}>
										+
									</span>
								)}
							</div>
						)
					) : (
						<div className={styles.labelText}>{row.label}</div>
					)}
					<div className={isAlternateExpanded && fieldIndex > 0 ? styles.paddingDropdownAlternate : ''}>
						<Dropdown
							id={collectionId + '-' + row.id}
							hint={
								isAlternateExpanded && fieldIndex > 0
									? t('data.type.mapping.alternate.dropdown.hint')
									: t('file.mapping.dropdown.hint')
							}
							onChangeFunction={(selected: string) => updateSelected(row.id, selected)}
							label={''}
							options={row.dropdownValues}
							size={'fluid'}
							isRequired={row.isRequired}
							requiredIndicator={'star'}
							isDynamicRequired={isDynamicRequired(row.id)}
							labelPosition="left"
							selected={row.selected}
							canUnselect={true}
							unselectText={unselectText}
							errorMessage={row.errorMessage}
							isValid={row.isValid}
							testId={testId + '-dd-collection'}
							disabled={disabled}
							typeAhead={true}
							warningIndicator={
								(showAlert !== undefined && showAlert === row.id) ||
								(row.selected === undefined && showWarningIcon && row.isRequired)
							}
						/>
					</div>
				</div>
			))}
		</div>
	)
}
