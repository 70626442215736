/**
 * @class Button
 */
import cx from 'classnames'
import { camelCase } from 'lodash-es'
import { FC, MouseEvent, useEffect, useRef, useState } from 'react'
import { createTokenList, getValueToken } from '../design-tokens/utils'
import { Icon, IconType } from '../icon/icon'
import * as tokens from '../shared/token-colors.json'
import styles from './button.module.scss'

type buttonColors = {
	textColor: string
	backgroundColor: string
	borderColor: string
}

interface IButtonProps {
	text: string
	onClick(event: MouseEvent<HTMLButtonElement>): void
	onMouseDown?(event: MouseEvent<HTMLButtonElement>): void
	onMouseUp?(event: MouseEvent<HTMLButtonElement>): void
	onMouseLeave?(event: MouseEvent<HTMLButtonElement>): void
	isDisabled?: boolean
	type?: 'primary' | 'secondary'
	size?: 'mini' | 'small' | 'medium' | 'large'
	short?: boolean
	colors?: {
		normalState: buttonColors
		hoverState: buttonColors
		focusState: buttonColors
		disabledState: buttonColors
	}
	iconType?: IconType
	iconPosition?: 'left' | 'right'
	iconColor?: string
	testId: string
	slideEffect?: boolean
}

export const Button: FC<IButtonProps> = ({
	text,
	onClick,
	onMouseDown,
	onMouseUp,
	onMouseLeave,
	isDisabled = false,
	type = 'primary',
	size = 'small',
	short = false,
	colors,
	iconType,
	iconPosition = 'left',
	iconColor,
	testId,
	slideEffect = false
}: IButtonProps) => {
	const [focused, setFocused] = useState<boolean>(false),
		[personalizedColor, setPersonalizedColor] = useState<buttonColors>({
			textColor: '',
			backgroundColor: '',
			borderColor: ''
		}),
		buttonColorsValues = useRef({
			normalState: {
				textColor: '',
				backgroundColor: '',
				borderColor: ''
			},
			hoverState: {
				textColor: '',
				backgroundColor: '',
				borderColor: ''
			},
			focusState: {
				textColor: '',
				backgroundColor: '',
				borderColor: ''
			},
			disabledState: {
				textColor: '',
				backgroundColor: '',
				borderColor: ''
			}
		})
	const iconBackgroundColor = iconColor
		? iconColor
		: type === 'primary'
		? tokens.ColorWhite
		: type === 'secondary'
		? tokens.ColorBluePrimary
		: ''

	useEffect(() => {
		if (colors) {
			const colorsTokenList = createTokenList(tokens)
			Object.keys(colors).forEach((stateKey) => {
				Object.keys(colors[stateKey]).forEach((colorKey) => {
					buttonColorsValues.current[stateKey][colorKey] = getValueToken(
						colorsTokenList,
						colors[stateKey][colorKey]
					)
				})
			})
			setPersonalizedColor(() => {
				if (isDisabled) {
					return buttonColorsValues.current.disabledState
				}
				return buttonColorsValues.current.normalState
			})
		}
	}, [colors, isDisabled])

	const buttonRef = useRef<HTMLButtonElement>(null)
	const textRef = useRef<HTMLSpanElement>(null)
	const [isSlideable, setIsSlideable] = useState(false)

	const checkTextWidth = () => {
		if (buttonRef.current && textRef.current) {
			const paddingSidesButton = 28
			const buttonWidth = buttonRef.current.offsetWidth - paddingSidesButton
			const textWidth = textRef.current.scrollWidth
			setIsSlideable(textWidth > buttonWidth)
		}
	}

	useEffect(() => {
		checkTextWidth()
		window.addEventListener('resize', checkTextWidth)
		return () => window.removeEventListener('resize', checkTextWidth)
	}, [])

	return (
		<button
			data-testid={testId}
			onClick={(e) => {
				onClick(e)
				setFocused(false)
			}}
			onFocus={() => {
				setFocused(true)
				if (colors && !isDisabled) {
					setPersonalizedColor(buttonColorsValues.current.focusState)
				}
			}}
			onBlur={() => {
				if (colors && !isDisabled) {
					setPersonalizedColor(buttonColorsValues.current.normalState)
				}
			}}
			onMouseOver={() => {
				if (colors && !isDisabled) {
					setPersonalizedColor(buttonColorsValues.current.hoverState)
				}
			}}
			onMouseLeave={(e) => {
				if (onMouseLeave) {
					onMouseLeave(e)
				}
				if (colors && !isDisabled) {
					setPersonalizedColor(buttonColorsValues.current.normalState)
				}
			}}
			onMouseDown={onMouseDown}
			onMouseUp={onMouseUp}
			className={cx(styles.button, styles[camelCase(`button-${type}`)], styles[camelCase(`button-${size}`)], {
				[styles.short]: short,
				[styles.focused]: focused
			})}
			disabled={isDisabled}
			style={{
				color: personalizedColor.textColor,
				backgroundColor: personalizedColor.backgroundColor,
				borderColor: personalizedColor.borderColor
			}}
			ref={buttonRef}
		>
			<span
				data-testid={`button-container-${testId}`}
				className={cx(styles.content, `${slideEffect ? styles.slideEffect : ''}`)}
			>
				{iconType && iconPosition === 'left' && (
					<span
						data-testid={`button-icon-container-left-${testId}`}
						className={cx(styles.iconContainer, styles.left)}
					>
						<Icon testId={`icon-left-${testId}`} type={iconType} size="mini" color={iconBackgroundColor} />
					</span>
				)}

				{slideEffect ? (
					<span
						className={cx({
							[styles.buttonText]: isSlideable,
							[styles.slide]: isSlideable
						})}
						ref={textRef}
					>
						{text}
					</span>
				) : (
					<>{text}</>
				)}

				{iconType && iconPosition === 'right' && (
					<span
						data-testid={`button-icon-container-right-${testId}`}
						className={cx(styles.iconContainer, styles.right)}
					>
						<Icon testId={`icon-right-${testId}`} type={iconType} size="mini" color={iconBackgroundColor} />
					</span>
				)}
			</span>
		</button>
	)
}

export default Button
