import { useQuery, UseQueryResult } from 'react-query'
import ProjectUIFacade from '../dashboard/ProjectUIFacade'
import { useApi } from '../hooks'
import { getProject } from './api/getProject'

export const useProject = (projectId: string): UseQueryResult<ProjectUIFacade> => {
	const apiClient = useApi()

	const queryKey = ['getProjects', projectId]
	const queryFunction = () => getProject(apiClient, projectId)
	return useQuery(queryKey, queryFunction, { enabled: projectId !== undefined && projectId !== '' })
}
