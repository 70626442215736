import { AxiosInstance, AxiosResponse } from 'axios'
import { Source } from '../../types'

export const getSources = async (apiClient: AxiosInstance, projectId: string) => {
	let allSourcesReceived = false
	const sourceList: Source[] = []
	let pageIdx = 1
	while (!allSourcesReceived) {
		const url = `/pls/sources/projectId/${projectId}?pageSize=20&pageIndex=${pageIdx}`
		await apiClient
			.get(url)
			.then((response: AxiosResponse<Source[]>) => {
				const sources: Source[] = response.data
				if (sources.length === 0) {
					allSourcesReceived = true
				} else {
					sourceList.push(...sources)
					if (sources.length < 20) {
						allSourcesReceived = true
					} else {
						pageIdx++
					}
				}
			})
			.catch(() => {
				allSourcesReceived = true
			})
	}
	return sourceList
}
