/**
 * @class LoadingState
 */

import { FC, ReactElement, useEffect, useState } from 'react'
import { ColorGreen, ColorSlatePowder } from '../shared/token-colors.json'
import styles from './loading-state.module.scss'

export interface LoadingStateProps {
	loadingMessage?: string | ReactElement
	testId?: string
}

export const LoadingState: FC<LoadingStateProps> = ({ loadingMessage, testId }: LoadingStateProps) => {
	const [spinnerIE, setSpinnerIE] = useState<boolean>(false)

	useEffect(() => {
		if (navigator.userAgent.indexOf('Trident/7.0') !== -1) {
			setSpinnerIE(true)
		}
	}, [])

	return (
		<div data-testid={`loading-container-${testId}`} className={styles.loadingContainer}>
			<div data-testid={`loader-position-${testId}`} className={styles.loaderPosition}>
				<div data-testid={`loader-container-${testId}`} className={styles.loaderContainer}>
					<svg data-testid={`svg-loader-${testId}`} viewBox="22 22 44 44" className={styles.insideCircle}>
						<circle
							data-testid={`circle-container-${testId}`}
							cx={44}
							cy={44}
							r={16}
							fill="none"
							stroke={ColorSlatePowder}
							strokeWidth={2.5}
						/>
					</svg>
					{spinnerIE ? (
						<div data-testid={`spinner-IE-container-${testId}`} className={styles.SpinnerIE}>
							<div />
							<div />
							<div />
							<div />
						</div>
					) : (
						<svg data-testid={`spinner-svg-${testId}`} viewBox="22 22 44 44">
							<circle
								data-testid={`spinner-circle-${testId}`}
								cx={44}
								cy={44}
								r={16}
								fill="none"
								stroke={ColorGreen}
								strokeWidth={2.5}
								className={styles.outsideCircle}
							/>
						</svg>
					)}
				</div>
				{loadingMessage && (
					<div data-testid={`loading-message-${testId}`} className={styles.loadingMessage}>
						{loadingMessage}
					</div>
				)}
			</div>
		</div>
	)
}

export default LoadingState
