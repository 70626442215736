import { camelCase, isEqual } from 'lodash-es'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MatchInclusion } from '../../../../../components/match-inclusion/match-inclusion'
import { useFeatures } from '../../../../../hooks/useEntitlements'
import { Accordion, AccordionHeader, AccordionPanel, Grid } from '../../../../../local-core-ui'
import { useMatchPrecedenceProfiles } from '../../../../../queries/useMatchPrecedenceProfiles'
import { useMatchRecommendation } from '../../../../../queries/useMatchRecommendation'
import { useMatchSummaryCodes } from '../../../../../queries/useMatchSummaryCodes'
import { RootState, useAppDispatch, useAppSelector } from '../../../../../store'
import { updateMatchRule } from '../../../../../store/projectWizard/actions'
import { MatchRuleInfo } from '../../../../../store/projectWizard/types'
import { MatchStyle } from '../../../../../types'
import {
	GetMatchRecommendationProfile,
	Results
} from '../../../../../types/match-recommendation/GetMatchRecommendationProfile'
import { MatchRecommendationProfiles } from './match-recommendation-profiles'
import { MatchRecommendationSummary } from './match-recommendation-summary'
import styles from './match-recommendation.module.scss'

export function MatchRecommendation(): ReactElement {
	const { t } = useTranslation()
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const matchRuleBase = projectWizardState.currentProject.matchRules[0].matchRule
	const dispatch = useAppDispatch()

	const EnablePassProfile = useFeatures(['EnablePassProfile'])
	const profiles: Array<string> = ['stewardshipProfiles']
	if (EnablePassProfile) profiles.push('passProfiles')

	const [stewardshipProfiles, setStewardshipProfiles] = useState<Results>()
	const [passProfiles, setPassProfiles] = useState<Array<string>>()

	const [optionPrecedenceSelected, setOptionPrecedenceSelected] = useState<string>(
		matchRuleBase.precedenceProfile ? matchRuleBase.precedenceProfile : 'Standard'
	)
	const [selectedOptionStewardship, setSelectedOptionStewardship] = useState<string>(
		matchRuleBase.stewardshipProfile ? matchRuleBase.stewardshipProfile : 'Standard'
	)
	const [selectedOptionPassProfile, setSelectedOptionPassProfile] = useState<string>(
		matchRuleBase.passProfile ? matchRuleBase.passProfile : 'Standard'
	)

	const [summaryData, setSummaryData] = useState<Results>()
	const [roleOrder, setRoleOrder] = useState<Array<string>>([])

	const matchCodesQuery = useMatchSummaryCodes(true)
	const matchRecommendationProfiles = useMatchRecommendation(profiles)
	const matchPrecedenceProfiles = useMatchPrecedenceProfiles()
	const defaultLowestConfidenceValue = 6
	const maxStrLengthToShow = 45
	const currentMatchRule = projectWizardState.currentProject.matchRules.length
		? projectWizardState.currentProject.matchRules[0]
		: undefined
	const initialMatchRule = projectWizardState.currentProject.initialMatchRules.length
		? projectWizardState.currentProject.initialMatchRules[0]
		: undefined

	useEffect(() => {
		const typeProfilesMR = matchRecommendationProfiles.data as unknown as Array<GetMatchRecommendationProfile>
		if (typeProfilesMR !== undefined) {
			typeProfilesMR.forEach((profile) => {
				const nameProfile = Object.keys(profile)[0]
				if (nameProfile === 'stewardshipProfiles') {
					setStewardshipProfiles(profile[nameProfile])
				} else if (nameProfile === 'passProfiles') {
					setPassProfiles(profile[nameProfile] as unknown as Array<string>)
				}
			})
		}
	}, [matchRecommendationProfiles.data])

	const getModifiersOptions = () =>
		matchPrecedenceProfiles.data?.map((modifierRule) => modifierRule.profileName) || []

	const getMappedRules = (profile: string, rules: string[]) =>
		rules.map((rule) => ({
			value: rule,
			label: `match.recommendation.${profile}.rules.${camelCase(rule)}`
		}))

	const getRoleOrderSelected = () => {
		const profileName = optionPrecedenceSelected
		const roleOrder = matchPrecedenceProfiles.data?.find((precedence) =>
			precedence.profileName === profileName ? precedence.ruleOrder : undefined
		)
		const rule = JSON.parse(JSON.stringify(projectWizardState.currentProject.matchRules[0]))
		setRoleOrder(roleOrder ? roleOrder.ruleOrder : [])
		rule.matchRule.precedenceCriterion = roleOrder?.ruleOrder
		rule.matchRule.stewardshipProfile = selectedOptionStewardship
		rule.matchRule.precedenceProfile = optionPrecedenceSelected
		rule.matchRule.passProfile = selectedOptionPassProfile
		rule.matchRule.matchStyle = MatchStyle.MATCH_RECOMMENDATION
		dispatch(updateMatchRule(0, rule))
	}

	const getSummaryData = () => {
		if (stewardshipProfiles !== undefined) {
			const outcomeList = stewardshipProfiles[selectedOptionStewardship]
			delete outcomeList['rule']
			setSummaryData(outcomeList as unknown as Results)
		}
	}

	const setExclusionCriterion = (newExclusionCriteria: Array<string>, idx: number) => {
		const updatedMatchRule = projectWizardState.currentProject.matchRules[idx]
		updatedMatchRule.matchRule.exclusionCriterion = newExclusionCriteria
		dispatch(updateMatchRule(idx, updatedMatchRule))
	}

	const getInitialButton = (field: string) => {
		switch (field) {
			case 'acceptCriterion':
				return (
					currentMatchRule?.matchRule.acceptCriterion.LowestConfidenceCode ===
						initialMatchRule?.matchRule.acceptCriterion.LowestConfidenceCode ||
					(initialMatchRule === undefined &&
						currentMatchRule?.matchRule.acceptCriterion.LowestConfidenceCode ===
							defaultLowestConfidenceValue)
				)
			case 'MatchGradePatterns':
				return isEqual(
					currentMatchRule?.matchRule.acceptCriterion.MatchGradePatterns,
					initialMatchRule?.matchRule.acceptCriterion.MatchGradePatterns
				)
			case 'exclusionCriterion':
				const initialExclusionCriterion =
					initialMatchRule?.matchRule.exclusionCriterion !== undefined
						? initialMatchRule?.matchRule.exclusionCriterion
						: []
				const newValuesExclusionCriterion =
					currentMatchRule?.matchRule.exclusionCriterion !== undefined
						? currentMatchRule.matchRule.exclusionCriterion
						: []
				return isEqual(newValuesExclusionCriterion, initialExclusionCriterion)
		}
	}

	const arrayDifference = (array1: string[], array2?: string[]) => {
		let difference: string[] = array1
		if (array2) {
			difference = array1.filter((key: string) => !array2.includes(key))
		}
		return difference
	}

	const getInclusionCriteria = (rule: MatchRuleInfo) => {
		const inclusionCriteriaKeys = [
			'NonHeadQuarters',
			'OutOfBusiness',
			'Unreachable',
			'NonMarketable',
			'Undeliverable'
		]
		return arrayDifference(inclusionCriteriaKeys, rule?.matchRule.exclusionCriterion)?.map((includeKey) => {
			return t(`match.rule.summary.tile.${includeKey}`)
		})
	}

	const matchRecommendationRules = {
		precedenceRules: getMappedRules('precedence', getModifiersOptions()),
		stewardshipRules: getMappedRules(
			'stewardship',
			stewardshipProfiles !== undefined ? Object.keys(stewardshipProfiles) : []
		),
		passProfileSelection: getMappedRules('passProfile', passProfiles ?? [])
	}

	const { precedenceRules, stewardshipRules, passProfileSelection } = matchRecommendationRules

	const inclusionString = useMemo(() => {
		const inclusionCriteria = getInclusionCriteria(currentMatchRule)
		let inclusionCriteriaString = inclusionCriteria.join(', ')
		if (inclusionCriteriaString.length > maxStrLengthToShow) {
			inclusionCriteriaString = inclusionCriteriaString.substring(0, 42).trim() + '...'
		}
		const showing = inclusionCriteriaString.split(', ').length
		const total = inclusionCriteria.length
		const remaining = total - showing
		return `${inclusionCriteriaString} ${remaining > 0 ? '+' + remaining : ''}`
		/**
		 * We only want to run this effect when the exclusionCriterion from the match rule changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentMatchRule?.matchRule.exclusionCriterion])

	useEffect(() => {
		if (matchRecommendationProfiles.data) getSummaryData()
		getRoleOrderSelected()
	}, [
		matchPrecedenceProfiles.status,
		stewardshipProfiles,
		selectedOptionStewardship,
		optionPrecedenceSelected,
		selectedOptionPassProfile
	])

	return (
		<Grid testId="container-match-recommendation" container>
			<Grid testId="match-recommendation-panel">
				<div className={styles.matchingPanel}>
					<div className={styles.panelHeaderContainer}>
						<div className={styles.stepTitleWrapper}>
							<h1 data-testid="match-recommendation-step-title">{t('file.matching.line.1')}</h1>
						</div>
					</div>
					<div className={styles.panelDescriptionContainer}>
						<p>{t('file.matching.description')}</p>
					</div>
					<div>
						<div className={styles.recommendationRulesContainer}>
							<div className={styles.columns}>
								<div className={styles.profiles}>
									<h3>{t('title.column.profiles.match.recommendation')}</h3>
									<div className={styles.optionsProfile} key={`options-precedenceProfile`}>
										<MatchRecommendationProfiles
											rulesOf={'precedenceProfiles'}
											profilesOptions={precedenceRules}
											onSelectProfile={(option: string) => {
												setOptionPrecedenceSelected(option)
											}}
											profileSelected={optionPrecedenceSelected}
											group={'precedenceProfiles'}
										/>
									</div>
									{stewardshipProfiles !== undefined ? (
										<div className={styles.optionsProfile} key={`options-stewardshipProfiles`}>
											<MatchRecommendationProfiles
												rulesOf={'stewardshipProfiles'}
												profilesOptions={stewardshipRules}
												onSelectProfile={(option: string) => {
													setSelectedOptionStewardship(option)
												}}
												profileSelected={selectedOptionStewardship}
												group={'stewardshipProfiles'}
											/>
										</div>
									) : null}
									{EnablePassProfile && passProfiles?.length && (
										<>
											<h3>{t('title.subsection.mr.other.settings')}</h3>
											<div className={styles.optionsProfile} key={`options-passProfile`}>
												<MatchRecommendationProfiles
													rulesOf={'passProfiles'}
													profilesOptions={passProfileSelection}
													onSelectProfile={(option: string) => {
														setSelectedOptionPassProfile(option)
													}}
													profileSelected={selectedOptionPassProfile}
													group={'passprofiles'}
												/>
											</div>
										</>
									)}
								</div>
								<div className={styles.description}>
									<h3 className={styles.title}>{t('title.column.profiles.match.description')}</h3>
									<p className={styles.subtitle}>{t('file.matching.reminder')}</p>
									{matchRecommendationProfiles.data !== undefined &&
										matchCodesQuery.status === 'success' &&
										summaryData !== undefined && (
											<MatchRecommendationSummary
												summaryCodes={matchCodesQuery?.data || []}
												summaryData={summaryData}
												ruleOrder={roleOrder}
											/>
										)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.inclusionCriteriaCont}>
					<Accordion
						testId={'rule-item-accordion'}
						id={'rule-item-accordion-rule-item'}
						allowMultipleOpen={true}
					>
						<AccordionHeader
							title={t('matching.step.accordion.title.inclusions')}
							key={'inclusions'}
							controls={'inclusions-panel'}
							type={getInitialButton('exclusionCriterion') ? 'button' : 'normal'}
							isOpen={!getInitialButton('exclusionCriterion')}
							additionalText={{
								textExpanded: inclusionString,
								textCollapsed: inclusionString
							}}
							testId="inclusions-header"
						/>
						<AccordionPanel id="inclusions-panel" testId="inclusions-panel">
							<MatchInclusion
								selectedOptions={currentMatchRule?.matchRule.exclusionCriterion || []}
								updateInclusion={(updatedOptions) => setExclusionCriterion(updatedOptions, 0)}
								testId={'rule-item-match-inclusion'}
								readonly={false}
							/>
						</AccordionPanel>
					</Accordion>
				</div>
			</Grid>
		</Grid>
	)
}
