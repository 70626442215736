import { ColorWhite } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import i18next from 'i18next'
import { MouseEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import background from '../assets/images/bg/img-choose-system.jpg'
import dnbAmpLogo from '../assets/images/dnb-amp-logo.svg'
import dnbWordMarkLogo from '../assets/images/dnb-wordmark-logo.svg'
import { LogoutLink } from '../components/logout-link/logout-link'
import { Button, Dropdown, DropdownOptions, Modal } from '../local-core-ui'
import { attachToTenant, RootState, useAppDispatch, useAppSelector } from '../store'
import { Tenant } from '../types'
import styles from './select-tenant.module.scss'

export function SelectTenant(): ReactElement {
	const { t } = useTranslation()
	const selectSession = (state: RootState) => state.session

	const session = useAppSelector(selectSession)
	const dispatch = useAppDispatch()
	const queryClient = useQueryClient()
	const [selectedTenant, setSelectedTenant] = useState<Tenant>(),
		[dropdownOption, setDropdownOptions] = useState([] as DropdownOptions[])
	const [tenants, setTenants] = useState([] as Tenant[])
	const [username, setUsername] = useState('')
	const [showNotSupportedViewportModal, setShowNotSupportedViewportModal] = useState(false)
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const MIN_SUPPORTED_VIEWPORT_SIZE = 1200

	const goToDashboard = async (e: MouseEvent | KeyboardEvent) => {
		if (e) e.preventDefault()
		if (session.token && selectedTenant) {
			queryClient.resetQueries(['getDataBlocksEntitlements'])
			await dispatch(attachToTenant(session.token, selectedTenant, i18next.language))
		}
	}

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowWidth(window.innerWidth)
		}

		window.addEventListener('resize', handleWindowResize)

		return () => {
			window.removeEventListener('resize', handleWindowResize)
		}
	}, [])

	useEffect(() => {
		setShowNotSupportedViewportModal(windowWidth < MIN_SUPPORTED_VIEWPORT_SIZE)
	}, [windowWidth])

	useEffect(() => {
		const getTenants = () => {
			setUsername(session.username)
			const allTenant = session.tenants
			if (allTenant !== null) {
				if (allTenant.length === 1) {
					const theTenant = allTenant[0]
					setSelectedTenant(theTenant)
					dispatch(attachToTenant(session.token, theTenant, i18next.language))
				} else {
					setTenants(allTenant)
					const tenantsName = allTenant.map((tenant: Tenant) => {
						return {
							label: `${tenant.DisplayName} (${tenant.SubscriberNumber})`,
							value: tenant.DisplayName
						}
					})
					setDropdownOptions(tenantsName)
				}
			}
		}

		getTenants()
	}, [session, dispatch])

	useEffect(() => {
		const element = document.getElementsByClassName('button')[0] as HTMLElement
		if (element) element.focus()
	}, [selectedTenant])

	return (
		<div className={styles.selectTenantContainer}>
			<Modal
				open={showNotSupportedViewportModal}
				showButtonClose={true}
				onClose={() => setShowNotSupportedViewportModal(false)}
				testId="not-supported-viewport-Modal"
			>
				<div className={styles.notSupportedViewportModal}>{t('select.tenant.modal.notSupportedViewport')}</div>
			</Modal>
			<img alt="background" src={background} className={styles.backgroundImage} />
			<div className={styles.mask}>
				<div className={styles.selectTenantCardContainer}>
					<div className={styles.logoContainer}>
						<img alt="D&B logo" src={dnbAmpLogo} width="71px" />
						<img
							alt="dun & bradstreet"
							src={dnbWordMarkLogo}
							width="241px"
							className={styles.dnbWordmarkLogo}
						/>
					</div>
					<div className={styles.formContainer}>
						<h1 className={styles.productName}>{t('product.name')}</h1>
						<div className={styles.usernameContainer}>
							<p className={styles.usernameLabel}>{t('username')}</p>
							<p className={styles.usernameText}>{username}</p>
						</div>
						<div className={styles.systemContainer}>
							<Dropdown
								id="dropdown-system"
								label={t('company')}
								onChangeFunction={(selection) => {
									const tenantObj = tenants.find((tenant: Tenant) => tenant.DisplayName === selection)
									setSelectedTenant(tenantObj)
								}}
								hint={t('select.tenant.choose.company')}
								options={dropdownOption}
								size={'large'}
								typeAhead={true}
								color={ColorWhite}
								testId="select-tenant-dd"
							/>
							<div className={styles.buttonContainer}>
								<Button
									type="secondary"
									size="large"
									text={t('go')}
									onClick={goToDashboard}
									isDisabled={selectedTenant === undefined}
									testId="select-tenant-go-btn"
								/>
							</div>
						</div>
						<div className={styles.logoutContainer}>
							<BrowserRouter>
								<span className={styles.logoutArrow}>&#10094; </span>
								<LogoutLink text={'signout'} />
							</BrowserRouter>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
