import { DNBButton } from '@dnb-uux-design-system/react'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import iconCheck from '../../assets/images/green-check-no-outline.svg'
import { useFeatures } from '../../hooks/useEntitlements'
import { useOrgId } from '../../hooks/useOrgId'
import { usePlatformName } from '../../hooks/usePlatformName'
import { Divider, Grid, Icon, Modal, RadioButton } from '../../local-core-ui'
import { CrmSource } from '../../project/steps/setup-source/select-input/select-crm-source/crm-source'
import { useCrmAvailableSourceTypesDetails } from '../../queries/useCrmAvailableSourceTypesDetails'
import { useProject } from '../../queries/useProject'
import { RootState, useAppSelector } from '../../store'
import { EntityType } from '../../types/sources/EntityType'
import { ProjectOverview } from '../project-overview/project-overview'
import { timestampFormatter } from '../source-settings/timestampFormatter'
import styles from './upload-crm-source-tile.module.scss'

export interface UploadCRMTileProps {
	item: CrmSource
	radioGroup: string
	onSelected: (item: CrmSource, selectedConnectionName: string) => void
	checked: boolean
	selectedConnectionName?: string
	disabled?: boolean
}

export const UploadCRMSourceTile = ({
	item,
	radioGroup,
	onSelected,
	checked,
	selectedConnectionName,
	disabled = false
}: UploadCRMTileProps): ReactElement => {
	const { t } = useTranslation()
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const [showProjectOverviewModal, setShowProjectOverviewModal] = useState(false)
	const matchType = projectWizardState.currentProject.source.entityType?.toLowerCase() || 'accounts'
	const orgId = useOrgId()
	const platformName = usePlatformName()
	const enableC4SAuthMgmt = useFeatures(['EnableC4SAuthMgmt'])
	const platform = enableC4SAuthMgmt ? 'salesforce' : platformName || ''
	const systemId = enableC4SAuthMgmt ? selectedConnectionName || '' : orgId || ''
	const availableCrmSourceTypes = useCrmAvailableSourceTypesDetails(
		platform,
		systemId,
		projectWizardState.currentProject.source.id || '',
		matchType
	)
	const { data } = availableCrmSourceTypes
	const getDataByEntityType = (data: any, type: string) => data?.find((item: any) => item.entityType === type)
	const getValues = {
		account: getDataByEntityType(data, EntityType.ACCOUNTS),
		lead: getDataByEntityType(data, EntityType.LEADS),
		contact: getDataByEntityType(data, EntityType.CONTACTS)
	}
	const projectOverview = useProject(getValues?.[item.type]?.projectId)

	function sourcesDetailsShow(fieldName: string) {
		if (fieldName === 'entityType' && projectOverview.data?.sources) {
			const source = projectOverview.data?.sources?.find((source) => source.entityType)
			const entityType = source?.entityType

			return entityType === EntityType.ACCOUNTS
				? t('create.project.form.label.company')
				: t('create.project.form.label.contact')
		}
		return getValues?.[item.type]?.[fieldName]
	}

	const onChange = () => {
		onSelected(item, selectedConnectionName || '')
	}

	function sourcesDetailsShowToDate(fieldName: string) {
		return timestampFormatter(getValues?.[item.type]?.[fieldName])
	}

	return (
		<div className={styles.uploadCrmTileContainer}>
			<div className={styles.uploadCrmTileRadio}>
				{!item.available ? (
					<img className={styles.uploadCrmCompletedCheck} src={iconCheck} alt={'icon-check'} />
				) : (
					<RadioButton
						group={radioGroup}
						onChange={onChange}
						key={item.type}
						disabled={!item.available || item.disabled || disabled}
						checked={checked}
						value={item.type}
						id={item.type}
						label={''}
						testId={`crm-source-item-${item.type}`}
					/>
				)}
			</div>
			<div className={`${styles.uploadCrmTileItem} ${disabled ? styles.itemDisabled : ''}`}>
				<div className={styles.uploadCrmTypeContainer}>
					<div className={`${styles.uploadCrmTileName} ${item.available ? '' : styles.unavailable}`}>
						{t(item.type)}
					</div>
					{!item.available && (
						<div className={styles.buttonContainer}>
							<DNBButton
								size="small"
								variant="text"
								onClick={() => {
									setShowProjectOverviewModal(true)
								}}
								data-testid=""
								endIcon={<Icon type="chevron-right" size="mini" />}
								disabled={false}
							>
								{t('view.project.details')}
							</DNBButton>
						</div>
					)}
				</div>
				<Divider color="lightgray" />
				{item.available ? (
					<div className={`${styles.uploadCrmTileName} ${styles.uploadCrmTileDescription}`}>
						{item.onlyThis
							? t('upload.crm.source.configuration.only.this')
							: t('upload.crm.source.configuration.available')}
					</div>
				) : (
					<Grid container gutter={2}>
						<Grid md={2}>
							<div
								className={`${styles.uploadCrmTileMsg} ${styles.uploadCrmTileDescription} ${
									item.available ? '' : styles.complete
								}`}
							>
								{t('upload.crm.source.configuration.complete')}
							</div>
						</Grid>
						<Grid md={5}>
							<div className={styles.uploadCrmTileColumn}>
								<div className={styles.uploadCrmTileLabel}>
									<span>{t('upload.crm.source.configuration.details.projectName')}</span>
									<div className={styles.compactBody}>{sourcesDetailsShow('projectName')}</div>
								</div>
								<div className={styles.uploadCrmTileLabel}>
									<span>{t('upload.crm.source.configuration.details.matchType')}</span>
									<div className={styles.compactBody}>{sourcesDetailsShow('entityType')}</div>
								</div>
							</div>
						</Grid>
						<Grid md={5}>
							<div className={styles.uploadCrmTileColumn}>
								<div className={styles.uploadCrmTileLabel}>
									<span>{t('upload.crm.source.configuration.details.configuredBy')}</span>
									<span className={styles.compactBody}>{sourcesDetailsShow('createdBy')}</span>
								</div>
								<div className={styles.uploadCrmTileLabel}>
									<span>{t('upload.crm.source.configuration.details.configuredOn')}</span>
									<span className={styles.compactBody}>{sourcesDetailsShowToDate('updated')}</span>
								</div>
							</div>
						</Grid>
					</Grid>
				)}
			</div>
			<Modal
				open={showProjectOverviewModal}
				isContainer={true}
				onClose={() => {
					setShowProjectOverviewModal(false)
				}}
				testId="ProjectBarOverviewModal"
			>
				<ProjectOverview
					readOnly={true}
					projectId={sourcesDetailsShow('projectId')}
					testId={'-project-overview'}
				/>
			</Modal>
		</div>
	)
}
