import { DNBButton } from '@dnb-uux-design-system/react'
import { orderBy } from 'lodash-es'
import { MouseEvent, ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ProjectUIFacade from '../../dashboard/ProjectUIFacade'
import { useAccessLevel, useFeatures, useModules, usePlatform } from '../../hooks/useEntitlements'
import { Icon } from '../../local-core-ui'
import { ConnectionStatus } from '../../queries/api/getC4SActiveConnection'
import { useProjects } from '../../queries/useProjects'
import { useUsers } from '../../queries/useUsers'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import { toggleProjectExpanded } from '../../store/dashboard/dashboardSlice'
import { newProjectAction } from '../../store/projectWizard/actions'
import { BigButton } from '../big-button/big-button'
import { ProjectFilter, ProjectFilterBar, recordPerPage } from '../project-filter-bar/project-filter-bar'
import { SkeletonProjectBar } from '../skeletons/skeleton-project-bar/skeleton-project-bar'
import { Snackbar, SnackType } from '../snackbar/snackbar'
import styles from './project-source-file-manager.module.scss'
import { ProjectBar } from './project/project-bar'

export const ProjectSourceFileManager = (): ReactElement => {
	const { t } = useTranslation()
	const filterDefaults: ProjectFilter = {
		quickFilter: '',
		status: 'active',
		createdByMe: true,
		sortBy: 'createdon',
		sortByLabel: t('search.label.sortByCreatedOn'),
		sortAscending: false
	}
	const dispatch = useAppDispatch()
	const history = useHistory()
	const selectProjectsFromWizard = (state: RootState) => state.projectWizard.projects
	const selectDashboard = (state: RootState) => state.dashboard
	const inProgressProjects = useAppSelector(selectProjectsFromWizard)
	const selectSession = (state: RootState) => state.session
	const sessionState = useAppSelector(selectSession)
	const dashboardState = useAppSelector(selectDashboard)
	const selectConnections = (state: RootState) => state.connection
	const connection = useAppSelector(selectConnections)
	const currentUser = sessionState.user?.EmailAddress
	const usersQuery = useUsers()
	const [filteredList, setFilteredList] = useState<ProjectUIFacade[]>([])
	const [recordsPerPage, setRecordsPerPage] = useState<recordPerPage>(20)
	const [firstListElement, setFirstListElement] = useState<number>(0)
	const [lastListElement, setLastListElement] = useState<number>(recordsPerPage)
	const [currentProjectSelected, setCurrentProjectSelected] = useState<string>('')
	const [projectFilters, setProjectFilters] = useState<ProjectFilter>(filterDefaults)
	const [urlParams, setUrlParams] = useState<ProjectFilter | undefined>(undefined)
	const projectSourceFilterBarRef = useRef<HTMLDivElement>(null)
	const [projectParam, setProjectParam] = useState<string>('')
	const [hasC4SActiveConnection, setHasC4SActiveConnection] = useState(true)
	const isAPIEnabled = useFeatures(['EnableAPI'])
	const enableC4SAuthMgmt = useFeatures(['EnableC4SAuthMgmt'])
	const isC4S = useModules(['C4S'])
	const isSalesforce = usePlatform('salesforce')
	const isAdmin = useAccessLevel(['INTERNAL_ADMIN', 'EXTERNAL_ADMIN', 'SUPER_ADMIN'])

	const query = useProjects()

	useEffect(() => {
		const project = history.location.pathname.split('/')
		if (project[project.length - 1] !== '') {
			setProjectParam(project[project.length - 1])
			setCurrentProjectSelected(project[project.length - 1])
		}
		const searchParams = new URLSearchParams(history.location.search)
		const createdByMe =
			searchParams.get('createdByMe') === null ? true : searchParams.get('createdByMe')?.includes('true') || false
		const sortBy = searchParams.get('sortBy') as 'name' | 'submitted' | 'matched' | 'unmatched' | 'createdon'
		const status = searchParams.get('status') as 'active' | 'archived'
		const sortByLabel = searchParams.get('sortByLabel')
		const params: ProjectFilter = {
			quickFilter: '',
			status: status === null ? 'active' : status,
			createdByMe: createdByMe,
			sortBy: sortBy === null ? 'createdon' : sortBy,
			sortByLabel: sortByLabel === null ? t('search.label.sortByCreatedOn') : sortByLabel,
			sortAscending: searchParams.get('sortDirection') === 'asc' || !searchParams.get('sortDirection') === null
		}
		setUrlParams(params)
		// Only should run at startup to get the URL filters
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const resetListOrder = () => {
			const listStart = 0
			const listEnd = Math.min(recordsPerPage, filteredList.length)
			setFirstListElement(listStart)
			setLastListElement(listEnd)
		}
		resetListOrder()
	}, [recordsPerPage, filteredList.length])

	useEffect(() => {
		history.push({
			pathname: `/dashboard/projects/${currentProjectSelected}`,
			search: history.location.search
		})
		/**
		 * We only want to run this effect when currentProjectSelected state changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentProjectSelected])

	useEffect(() => {
		if (currentProjectSelected !== '' && projectParam !== '' && query.data?.length) {
			setProjectParam('')
			const positionProject = filteredList.findIndex((project: ProjectUIFacade, index: number) => {
				if (project.id === projectParam) return index
			})
			const page = Math.trunc(positionProject / recordsPerPage)
			if (page !== 0) {
				setFirstListElement(page * recordsPerPage)
				setLastListElement(getLastListElement('', page))
			}
		}
		// Only should run when the returned projects list changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredList])

	useEffect(() => {
		filterList()
		/**
		 * We only want to run this effect when the projectFilters or the data, length or isFetched flag from query changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectFilters, recordsPerPage, query.data, query.data?.length, query.isFetched])

	const defineSortFunction = (sortBy: ProjectFilter['sortBy']) => {
		switch (sortBy) {
			case 'name':
				return (project: ProjectUIFacade) => project.projectDisplayName.toLowerCase().trim()
			case 'submitted':
				return (project: ProjectUIFacade) => {
					if (project.basicStats) {
						return project.basicStats.successCnt
					} else {
						return -1
					}
				}
			case 'matched':
				return (project: ProjectUIFacade) => {
					if (project.basicStats) {
						return project.basicStats.matchedCnt
					} else {
						return -1
					}
				}
			case 'unmatched':
				return (project: ProjectUIFacade) => {
					if (project.basicStats) {
						return project.basicStats.unmatchedCnt
					} else {
						return -1
					}
				}
			case 'createdon':
				return (project: ProjectUIFacade) => project.createdOn
		}
	}

	const createdByFilter = (project: ProjectUIFacade) => {
		const createdBy = project.createdBy
		const filterByCreatedBy =
			!projectFilters.createdByMe || (projectFilters.createdByMe && createdBy === currentUser)
		if (filterByCreatedBy) {
			return project
		}
	}

	const filterList = () => {
		const newProjectList = query.data?.filter((project) => {
			const projectName = project.projectDisplayName?.toLowerCase()
			const projectStatus = project.archived

			const statusFilter = projectFilters.status === 'archived'
			if (projectName?.includes(projectFilters.quickFilter.toLowerCase())) {
				if (statusFilter === projectStatus) {
					return createdByFilter(project)
				}
			}
		})
		const orderList = orderBy(
			newProjectList,
			defineSortFunction(projectFilters.sortBy),
			projectFilters.sortAscending ? ['asc'] : ['desc']
		)
		setFilteredList(orderList)
	}

	const handleOnChangeFilter = (filters: ProjectFilter) => {
		setFirstListElement(0)
		setLastListElement(recordsPerPage)
		setProjectFilters(filters)
		history.push({
			pathname: `/dashboard/projects/${currentProjectSelected}`,
			search: `?status=${filters.status}&createdByMe=${filters.createdByMe}&sortBy=${
				filters.sortBy
			}&sortDirection=${filters.sortAscending ? 'asc' : 'desc'}`
		})
	}

	const handleExpand = (projectId: string) => {
		dispatch(toggleProjectExpanded(projectId))
		if (currentProjectSelected !== projectId) {
			setCurrentProjectSelected(projectId)
		} else {
			setCurrentProjectSelected('')
		}
	}

	const goToPrevPg = () => {
		setLastListElement(firstListElement)
		setFirstListElement(getLastListElement('prev'))
		window.scrollTo(0, projectSourceFilterBarRef.current?.offsetTop || 0)
	}

	const goToNxtPg = () => {
		setFirstListElement(lastListElement)
		setLastListElement(getLastListElement('next'))
		window.scrollTo(0, projectSourceFilterBarRef.current?.offsetTop || 0)
	}

	const getLastListElement = (param: string, page?: number) => {
		if (param === 'next') {
			return Math.min(lastListElement + recordsPerPage, filteredList.length)
		} else if (param === 'prev') {
			return firstListElement - recordsPerPage
		} else if (page && page !== 0) {
			return (page + 1) * recordsPerPage - 1
		} else return lastListElement
	}

	const getUserDetail = (project: ProjectUIFacade) => {
		return usersQuery.data?.find((user) => {
			return user.Email === project.createdBy
		})
	}

	const onCreateProjectHandler = (e: MouseEvent) => {
		e.preventDefault()
		dispatch(newProjectAction(isAPIEnabled, isC4S && isSalesforce))
		history.push('/steps/project-wizard/project', { source: 'projects' })
	}

	const getActiveProjectsCount = (): number => {
		return query.data
			? query.data.reduce((accumulator, project) => {
					const value = project.archived ? 0 : 1
					return accumulator + value
			  }, 0)
			: 0
	}

	const getArchivedProjectsCount = (): number => {
		return query.data
			? query.data.reduce((accumulator, project) => {
					const value = project.archived ? 1 : 0
					return accumulator + value
			  }, 0)
			: 0
	}

	const SkeletonProjects = () => {
		const numberOfProjects = 10
		const generatedComponent: Array<JSX.Element> = []
		for (let i = 0; i < numberOfProjects; i++) {
			generatedComponent.push(<SkeletonProjectBar key={'skeletons-' + i} />)
		}
		return <>{generatedComponent}</>
	}

	return (
		<div className={styles.projectSourceFileManager}>
			{isSalesforce &&
				enableC4SAuthMgmt &&
				(connection.currentConnectionStatus === ConnectionStatus.Failed ||
					connection.currentConnectionStatus === ConnectionStatus.Unknown) &&
				hasC4SActiveConnection && (
					<div className={styles.snackbarContainer}>
						<Snackbar
							title={t('connection.missing.non.admin')}
							type={SnackType.warning}
							message={t('connection.missing.non.admin.message') || ''}
							onClose={() => setHasC4SActiveConnection(false)}
							linkText={isAdmin ? t('connection.missing.see') || '' : undefined}
							onClickLink={isAdmin ? () => history.push('/connections') : undefined}
							showClose
							isBanner
						/>
					</div>
				)}
			<div className={styles.filterBarContainer} ref={projectSourceFilterBarRef}>
				<ProjectFilterBar
					testId="filter-bar"
					onChangeFilter={handleOnChangeFilter}
					setRecordsPerPage={(newNumberOfRecords) => setRecordsPerPage(newNumberOfRecords)}
					initialFilters={urlParams || filterDefaults}
					activeProjectsCount={getActiveProjectsCount()}
					archivedProjectsCount={getArchivedProjectsCount()}
					presentedProjectsCount={filteredList.length}
				/>
			</div>
			<div className={styles.bigButtonWrapper}>
				<BigButton
					text={t('createNewProject')}
					onClick={onCreateProjectHandler}
					testId={'create-project-big-button'}
				/>
			</div>
			<div id="projectListContainer" className={styles.projectListContainer}>
				{filteredList.length > 0 ? (
					<>
						{filteredList.slice(firstListElement, lastListElement).map((project: ProjectUIFacade) => {
							const inProgress = inProgressProjects[project.projectId] !== undefined
							return (
								<ProjectBar
									key={project.projectId}
									project={project}
									user={getUserDetail(project) ? getUserDetail(project) : undefined}
									expanded={dashboardState.expandedProjects.includes(project.projectId)}
									onExpand={handleExpand}
									testId={project.projectId}
									inProgress={inProgress}
								/>
							)
						})}
					</>
				) : (
					query.isFetching && (
						<div data-testid="container-skeleton">
							<SkeletonProjects />
						</div>
					)
				)}
			</div>
			<div className={styles.paginationButtonContainer}>
				<div className={styles.previousButtonWrapper}>
					<DNBButton
						size="small"
						variant="text"
						onClick={goToPrevPg}
						data-testid="project-source-file-manager-previous-page-btn"
						startIcon={<Icon testId="chevron-left-project-source-file" type="chevron-left" size="mini" />}
						disabled={firstListElement === 0}
					>
						{t('project.manager.previous.page', {
							items: firstListElement === 0 ? '' : recordsPerPage
						})}
					</DNBButton>
				</div>
				<div className="next-button-wrapper">
					<DNBButton
						size="small"
						variant="text"
						onClick={goToNxtPg}
						data-testid="project-source-file-manager-next-page-btn"
						endIcon={<Icon testId="chevron-right-project-source-file" type="chevron-right" size="mini" />}
						disabled={lastListElement >= filteredList.length}
					>
						{t('project.manager.next.page', {
							items: lastListElement >= filteredList.length ? '' : recordsPerPage
						})}
					</DNBButton>
				</div>
			</div>
		</div>
	)
}
