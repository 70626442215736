import { useQuery, UseQueryResult } from 'react-query'
import { EnrichmentTemplateFacade } from '../components/enrichment-template-picker/enrichment-template-picker'
import { getEnrichmentLayoutFacadeByTemplates } from '../helpers'
import { useApi } from '../hooks'
import { PurposeOfUse } from '../types'
import { EntityType } from '../types/sources/EntityType'
import { getEnrichmentTemplateList } from './api/getEnrichmentTemplateList'
import { getUsers } from './api/getUsers'

export const useEnrichmentTemplateFacadeList = (
	purposeOfUse: PurposeOfUse,
	entityType?: EntityType
): UseQueryResult<EnrichmentTemplateFacade[], unknown> => {
	const apiClient = useApi()

	const queryKey = ['getEnrichmentTemplateFacadeList', purposeOfUse]
	if (entityType) queryKey.push(entityType)
	const queryFunction = () => {
		return getUsers(apiClient).then((usersList) =>
			getEnrichmentTemplateList(apiClient).then((enrichmentTemplateList) =>
				getEnrichmentLayoutFacadeByTemplates(
					apiClient,
					enrichmentTemplateList,
					usersList || [],
					purposeOfUse,
					entityType
				)
			)
		)
	}
	return useQuery(queryKey, queryFunction)
}
