import { DNBButton } from '@dnb-uux-design-system/react'
import cx from 'classnames'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { getLocalizedDateString } from '../../helpers'
import { Icon, Modal } from '../../local-core-ui'
import { ElementsToUpdate } from '../../types'
import { BoxName, typeBox } from './box-name'
import styles from './new-elems-version-modal.module.scss'
interface NewElemsVersionModalProps {
	isOpen: boolean
	elementsToUpdate: Record<string, ElementsToUpdate>
	onClose(): void
	onUpdate(): void
}
export const NewElemsVersionModal = ({
	isOpen,
	elementsToUpdate,
	onClose,
	onUpdate
}: NewElemsVersionModalProps): ReactElement => {
	const { t } = useTranslation()

	return (
		<div className={styles.containerNewElemsVersionModal}>
			<div className={styles.modalWrapper}>
				<Modal open={isOpen} isContainer={true} showButtonClose={false} testId={'new-elems-version-modal'}>
					<div className={styles.header}>
						<h1 className={styles.title}>{t('title.banner.update.elements')}</h1>
					</div>
					<div className={styles.body}>
						<div>{t('description.banner.update.elements')}</div>
						<div className={styles.containerElements}>
							<div>
								<div className={styles.rowTitles}>
									<div className={styles.firstColumn}>
										{t('update.elems.modal.title.column.current.element')}
									</div>
									<div className={styles.secondColumn}>
										{t('update.elems.modal.title.column.sunset.date')}
									</div>
									<div className={styles.thirdColumn}></div>
									<div className={styles.fourColumn}>
										{t('update.elems.modal.title.column.replacement.element')}
									</div>
								</div>
								<div className={styles.containerRows}>
									{Object.entries(elementsToUpdate).map((element) => {
										return element[1].elements.map((itemList) => {
											if (itemList.replacements.length) {
												return itemList.replacements.map((replaceElement, idx) => {
													return (
														<div
															className={styles.elementsRow}
															key={
																itemList.original.elementId +
																'-rowElements-' +
																replaceElement.elementId
															}
														>
															<BoxName
																text={itemList.original.displayName}
																testId={replaceElement.elementId + '-' + idx}
															/>
															<div className={cx(styles.secondColumn, styles.typeButton)}>
																<div>
																	{itemList.original.deprecateDate !== undefined
																		? getLocalizedDateString(
																				new Date(
																					itemList.original.deprecateDate
																				)
																		  )
																		: t(
																				'text.deprecated.modal.column.sunset.date.tbd'
																		  )}
																</div>
															</div>
															<div className={styles.thirdColumn}>
																<Icon type={'arrow-next'} size={'mini'} />
															</div>
															<BoxName
																text={
																	itemList.replacements.length
																		? replaceElement.displayName
																		: t(
																				'update.elems.modal.text.element.deprecated'
																		  )
																}
																testId={itemList.original.elementId}
															/>
														</div>
													)
												})
											} else {
												return (
													<div
														className={styles.elementsRow}
														key={itemList.original.elementId + '-rowElements'}
													>
														<BoxName
															text={itemList.original.displayName}
															testId={itemList.original.elementId}
														/>
														<div className={cx(styles.secondColumn, styles.typeButton)}>
															<div>
																{itemList.original.deprecateDate !== undefined
																	? getLocalizedDateString(
																			new Date(itemList.original.deprecateDate)
																	  )
																	: t('text.deprecated.modal.column.sunset.date.tbd')}
															</div>
														</div>
														<div className={styles.thirdColumn}>
															<Icon type={'arrow-next'} size={'mini'} />
														</div>
														<BoxName
															text={t('update.elems.modal.text.element.deprecated')}
															type={typeBox.deprecated}
															testId={'deprecates-' + itemList.original.elementId}
														/>
													</div>
												)
											}
										})
									})}
								</div>
								<div className={styles.footer}>
									<hr className={styles.divider}></hr>
									<div className={styles.buttonGroup}>
										<DNBButton
											size={'default'}
											variant={'secondary'}
											data-testid={'keep-new-elems-version-modal'}
											onClick={() => {
												onClose()
											}}
										>
											{t('update.elems.modal.button.keep.current.elements')}
										</DNBButton>
										<DNBButton
											className={styles.marginClass}
											size={'default'}
											variant={'primary'}
											data-testid={'update-new-elems-version-modal'}
											onClick={() => {
												onUpdate()
											}}
										>
											{t('update.elems.modal.button.update.new.elements')}
										</DNBButton>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		</div>
	)
}
