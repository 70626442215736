const suffixes = ['', 'K', 'M', 'B', 'T', 'P', 'E', 'Z']

export const abbreviateNumber = (originalValue: number, precision = 2): string => {
	if (originalValue && originalValue > 0) {
		const exponent = Math.log10(originalValue)
		const suffixExponent = Math.floor(exponent / 3)
		let abbreviatedNumber = originalValue / Math.pow(1000, suffixExponent)
		abbreviatedNumber = Math.trunc(abbreviatedNumber * Math.pow(10, precision)) / Math.pow(10, precision)

		return abbreviatedNumber.toFixed(precision) + suffixes[suffixExponent]
	} else {
		return Number(0).toString()
	}
}

export const numberDelimeters = (originalValue?: number): string => {
	return originalValue || originalValue === 0 ? originalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '- -'
}
