import { ColorBlueBrand } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon, LoadingState } from '../../local-core-ui'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import { getAssignments } from '../../store/steward/thunks'
import { UserDetail } from '../../types'
import { ScrollableList } from '../scrollable-list/scrollable-list'
import { AssignmentPickerTile } from './assignment-picker-tile'
import styles from './assignment-picker.module.scss'

interface AssignmentPickerProps {
	title: string
	selectedPersonId: string
	onAddToThisAssignment(assignmentId: string): void
	testId: string
	onNewAssignment(): void
	user?: UserDetail
}

type Priority = 'LOW' | 'MEDIUM' | 'HIGH'
export interface AssignmentDetails {
	assignmentId: string
	displayName: string
	createdBy: string
	state: 'ACTIVE' | 'ARCHIVED'
	assignee: string
	description: string
	priority: Priority
	totalRecords: number
}

export const AssignmentPicker = ({
	selectedPersonId,
	onAddToThisAssignment,
	testId,
	onNewAssignment,
	user
}: AssignmentPickerProps): ReactElement => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()

	const selectSteward = (state: RootState) => state.steward
	const steward = useAppSelector(selectSteward)
	const [isGettingAssignments, setIsGettingAssignments] = useState<boolean>(false)

	useEffect(() => {
		setIsGettingAssignments(true)
		dispatch(getAssignments(selectedPersonId))
		setIsGettingAssignments(false)
	}, [dispatch, selectedPersonId])

	return (
		<>
			<div className={styles.assignmentPicker}>
				{isGettingAssignments && (
					<div className={styles.loaderContainer}>
						<LoadingState />
					</div>
				)}
				<ScrollableList testId={testId + '-scrollable-list'} setToStartOnChildrenChange>
					<div
						className={`${styles.tile} ${styles.newAssignment}`}
						tabIndex={0}
						onClick={onNewAssignment}
						data-testid={testId + '-new-assignment'}
					>
						<div className={styles.assignmentTitle}>{t('assignment.picker.new.assignment')}</div>
						<div className={styles.newIcon}>
							<Icon testId="plus-picker" type="plus" size="medium" color={ColorBlueBrand} />
						</div>
					</div>
					{steward.assignments?.map((assignment) => (
						<AssignmentPickerTile
							key={`assignment-picker-tile-${assignment.assignmentId}`}
							assigneeUser={user?.FirstName + ' ' + user?.LastName}
							assignmentDetail={assignment}
							onAddToThisAssignment={onAddToThisAssignment}
						/>
					))}
				</ScrollableList>
				<div className={styles.title}>
					{t('assignment.picker.title', { name: user?.FirstName + ' ' + user?.LastName })}
				</div>
			</div>
		</>
	)
}
