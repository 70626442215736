import requiredElementList from '../../../project/steps/enriching/select-your-data/required-elements.json'
import { Element, UserAccessLevel } from '../../../types'
import { ApiErrorState, setApiErrorAction } from '../../apiError'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { setSavingLibraryFlag, updateCurrentProjectAction } from '../actions'

export const createOrUpdateTemplate =
	(
		isEnableTradeUp: boolean,
		isEnabledGenerateJson: boolean,
		templateName: string,
		templateId?: string
	): AppThunk<Promise<void>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<void> => {
		const currentProject = getState().projectWizard.currentProject
		const user = getState().session.user !== null ? getState().session.user : undefined
		const selectSession = getState().session.user?.EmailAddress
		const apiClient = getApiClient4Thunks(dispatch)
		let url
		let elements: string[] = []

		currentProject.enrichingLayout.forEach((block) => {
			const valuesSelectedOfBlock = block.elements.map((element: Element) => {
				return element.elementId
			})
			elements = elements.concat(valuesSelectedOfBlock.filter((item) => elements.indexOf(item) === -1))
		})
		const missingRequiredElementList = requiredElementList.filter((elementId) => !elements.includes(elementId))
		elements.push(...missingRequiredElementList)
		const data = {
			domain: currentProject.purposeOfUse.domain,
			recordType: currentProject.purposeOfUse.recordType,
			elements: elements,
			createdBy: selectSession,
			archived: false,
			generateJSON: isEnabledGenerateJson,
			tradeUp: isEnableTradeUp && currentProject.tradeUp ? currentProject.entityTradeUp : null,
			includeBranches: currentProject.includeBranches ?? null
		}

		let apiCall
		if (templateId) {
			url = `/pls/enrichment-template`
			apiCall = apiClient.put(url, { ...data, templateId: templateId })
		} else {
			url = `/pls/enrichment-template/create-template`
			apiCall = apiClient.post(url, { ...data, templateName: templateName })
		}
		return apiCall
			.then(async (response) => {
				dispatch(setSavingLibraryFlag(false))
				if (!templateId) {
					dispatch(
						updateCurrentProjectAction({
							currentTemplate: {
								templateId: response.data.Result.templateId,
								templateName: response.data.Result.templateName,
								createdBy: {
									id: user?.Identifier || '',
									AccessLevel: user?.AccessLevel as UserAccessLevel,
									FirstName: '',
									LastName: '',
									Title: '',
									Email: user?.EmailAddress || '',
									Username: user?.DisplayName || ''
								},
								generateJson: false
							}
						})
					)
				} else {
					dispatch(
						updateCurrentProjectAction({
							currentTemplate: {
								templateId: response.data.Result.templateId,
								templateName: response.data.Result.templateName,
								generateJson: isEnabledGenerateJson,
								tradeUp: response.data.Result.tradeUp,
								includeBranches: response.data.Result.includeBranches
							}
						})
					)
				}
			})
			.catch((e) => {
				dispatch(setSavingLibraryFlag(false))
				let errorMessage = JSON.parse(e.request.response).UIAction
				if (errorMessage) {
					const errorData: ApiErrorState = {
						errorCode: errorMessage.code,
						message: errorMessage.message
					}
					dispatch(setApiErrorAction(errorData))
				} else {
					errorMessage = JSON.parse(e.request.response)
					const errorData: ApiErrorState = {
						errorCode: errorMessage.errorCode,
						message: errorMessage.errorMessage
					}
					dispatch(setApiErrorAction(errorData))
				}
				throw e
			})
	}
