import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { PurposeOfUse } from '../types'
import { getRequiredElementsForConnectManage } from './api/getRequiredElementsForConnectManage'
import { RequiredElementsResponse } from './api/getRequiredElementsForFamilyTree'

export const useRequiredElementsForConnectManage = (
	purposeOfUse: PurposeOfUse,
	enabled = true
): UseQueryResult<RequiredElementsResponse, unknown> => {
	const apiClient = useApi()

	const queryKey = ['getRequiredElementsForConnectManage', purposeOfUse]
	const queryFunction = (): Promise<RequiredElementsResponse> =>
		getRequiredElementsForConnectManage(apiClient, purposeOfUse)
	return useQuery(queryKey, queryFunction, { enabled: enabled })
}
