import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProjectSortCriteria } from './ProjectSortCriteria'

export interface DashboardState {
	expandedProjects: Array<string>
	sortCriteria: ProjectSortCriteria
	filter: string
}

const initialState: DashboardState = {
	expandedProjects: [],
	filter: '',
	sortCriteria: {
		sortBy: 'name',
		sortType: 'alpha',
		sortAscending: true,
		sortLabel: 'A-Z'
	}
}

const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		changeSortCriteria: (state, action: PayloadAction<ProjectSortCriteria>) => {
			state.sortCriteria = action.payload
		},
		toggleProjectExpanded: (state, action: PayloadAction<string>) => {
			const currentCollapsed = state.expandedProjects
			if (currentCollapsed.includes(action.payload))
				currentCollapsed.splice(currentCollapsed.indexOf(action.payload), 1)
			else currentCollapsed.push(action.payload)
			state.expandedProjects = currentCollapsed
		}
	}
})

// Extract the action creators object and the reducer
const { actions, reducer } = dashboardSlice
// Extract and export each action creator by name
export const { changeSortCriteria, toggleProjectExpanded } = actions

export default reducer
