import { useEffect, useRef } from 'react'
import { SignOutReason, TDispatch } from '../store'
import { activityMonitor, STATES } from '../utilities'
import { useSession } from './useSession'

const TIME_INTERVAL_INACTIVITY_BEFORE_LOGOUT: number = 60 * 60 * 1000 // One Hour
const TIME_INTERVAL_INACTIVITY_BEFORE_IDLE: number = 55 * 60 * 1000 // 55 mins

type callback = () => void

const nothing: callback = () => {
	console.log('do nothing')
}

// Hook
export function useSessionTimeOutHandler(dispatch: TDispatch, idleCallback: callback): callback {
	const [logOutService, checkSession] = useSession(dispatch)
	const resetMonitor = useRef(nothing)

	useEffect(() => {
		const monitor = activityMonitor({
			timeToIdle: TIME_INTERVAL_INACTIVITY_BEFORE_IDLE,
			timeToInactive: TIME_INTERVAL_INACTIVITY_BEFORE_LOGOUT
		})

		const inactivityLogOut = () => {
			logOutService(SignOutReason.timeout)
		}

		monitor.on(STATES.INACTIVE, inactivityLogOut)
		monitor.on(STATES.ACTIVE, checkSession)
		monitor.on(STATES.IDLE, idleCallback)
		resetMonitor.current = monitor.reset
		return () => monitor.stop()
		// We need this code to get executed only once, hence the []
		// we can't declare logOutService inside of useEffect due to another rule so commenting
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return resetMonitor.current
}
