import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useWizardContext } from '../../../../components/wizardComposer/hooks/useWizardContext'
import { RadioButton } from '../../../../local-core-ui'
import { RootState, useAppDispatch, useAppSelector } from '../../../../store'
import { updateCurrentProjectAction } from '../../../../store/projectWizard/actions'
import { EntityType } from '../../../../types/sources/EntityType'
import styles from './chooseMatchType.module.scss'

export const ChooseMatchType: FC = () => {
	const { t } = useTranslation()
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const dispatch = useAppDispatch()
	const { wizardContext } = useWizardContext()

	const disabled =
		projectWizardState.currentProject.source.id !== undefined ||
		projectWizardState.currentProject.fileInfo?.uploadInfo !== undefined

	const onSetMatchType = (selectedMatchType: EntityType) => {
		dispatch(
			updateCurrentProjectAction({
				source: {
					entityType: selectedMatchType
				}
			})
		)
	}
	return (
		<div>
			<h1 className={styles.header}>{t('setup.source.choose.match')}</h1>
			<h2 className={styles.body14Semibold}>{t('setup.source.choose.match.explanation')}</h2>
			<div className={`${styles.titleRow} ${styles.paragraphBold}`}>
				<div className={styles.optionTitles}>
					<span className={styles.radioContainer} />
					<div className={styles.optionTitles}>
						<span className={`${styles.optionToken} ${styles.size200}`}></span>
						<span className={styles.withoutVerticalDivider} />
						<span className={`${styles.optionToken} ${styles.size300}`}>
							{t('setup.source.choose.match.required.input')}
						</span>
						<span className={styles.withoutVerticalDivider} />
						<span className={`${styles.optionToken} ${styles.size240}`}>
							{t('setup.source.choose.match.primary.output')}
						</span>
						<span className={styles.withoutVerticalDivider} />
						<span className={`${styles.optionToken} ${styles.size300}`}>
							{t('setup.source.choose.match.accompanying.output')}
						</span>
					</div>
				</div>
			</div>
			<div
				className={`${styles.optionRow} ${styles.body14Semibold}`}
				onClick={() => !disabled && onSetMatchType(EntityType.ACCOUNTS)}
			>
				<span className={styles.radioContainer}>
					<RadioButton
						value={'company'}
						group={'matchType'}
						id={'company'}
						label={''}
						onChange={() => onSetMatchType(EntityType.ACCOUNTS)}
						testId={'matchTypeCompany'}
						checked={projectWizardState.currentProject.source.entityType === EntityType.ACCOUNTS}
						disabled={disabled}
					/>
				</span>
				<div
					className={`${styles.optionDescription} ${
						projectWizardState.currentProject.source.entityType === EntityType.ACCOUNTS
							? styles.selected
							: ''
					}`}
				>
					<span className={`${styles.optionToken} ${styles.size200} ${styles.paragraphSemibold}`}>
						{t('setup.source.choose.match.company.match')}
					</span>
					<span className={styles.withVerticalDivider} />
					<span className={`${styles.optionToken} ${styles.size300}`}>
						{t('setup.source.choose.match.company.match.required.input')}
					</span>
					<span className={styles.withVerticalDivider} />
					<span className={`${styles.optionToken} ${styles.size240}`}>
						{t('setup.source.choose.match.company.match.primary.output')}
					</span>
					<span className={styles.withVerticalDivider} />
					<span className={`${styles.optionToken} ${styles.size300}`}>
						{t('setup.source.choose.match.company.match.accompanying.output')}
					</span>
				</div>
			</div>
			<div
				className={`${styles.optionRow} ${styles.body14Semibold}`}
				onClick={() => !disabled && onSetMatchType(EntityType.CONTACTS)}
			>
				<span className={styles.radioContainer}>
					<RadioButton
						value={'contact'}
						group={'matchType'}
						id={'contact'}
						label={''}
						onChange={() => onSetMatchType(EntityType.CONTACTS)}
						testId={'matchTypeContact'}
						checked={projectWizardState.currentProject.source.entityType === EntityType.CONTACTS}
						disabled={disabled}
					/>
				</span>
				<div
					className={`${styles.optionDescription} ${
						projectWizardState.currentProject.source.entityType === EntityType.CONTACTS
							? styles.selected
							: ''
					}`}
				>
					<span className={`${styles.optionToken} ${styles.size200} ${styles.paragraphSemibold}`}>
						{t('setup.source.choose.match.contact.match')}
					</span>
					<span className={styles.withVerticalDivider} />
					<span className={`${styles.optionToken} ${styles.size300}`}>
						{t('setup.source.choose.match.contact.match.required.input')}
					</span>
					<span className={styles.withVerticalDivider} />
					<span className={`${styles.optionToken} ${styles.size240}`}>
						{wizardContext.enableEmailVerification
							? t('setup.source.choose.match.contact.match.primary.output.emailVerificationEnabled')
							: t('setup.source.choose.match.contact.match.primary.output')}
					</span>
					<span className={styles.withVerticalDivider} />
					<span className={`${styles.optionToken} ${styles.size300}`}>
						{wizardContext.enableEmailVerification
							? t('setup.source.choose.match.contact.match.accompanying.output.emailVerificationEnabled')
							: t('setup.source.choose.match.contact.match.accompanying.output')}
					</span>
				</div>
			</div>
		</div>
	)
}
