import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { setSelectedRecords } from '../stewardSlice'
import { getRecords } from './getRecords'

export const addRecordsToAssignment =
	(assignmentId: string): AppThunk<Promise<void>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<void> => {
		const apiClient = getApiClient4Thunks(dispatch)
		const steward = getState().steward
		const url = `/pls/steward/assignments/${assignmentId}/records/assign`
		const recordIds = steward.selectedRecords.map((record) => record.recordId)
		const firstRecordId = recordIds[0]
		const firstSelectedRecordIndex = steward.records
			? steward.records.findIndex((record) => record.recordId === firstRecordId)
			: -1
		const isSingleSelection = recordIds.length === 1

		return apiClient.put(url, recordIds).then(() => {
			const selectRecordIndex =
				isSingleSelection && firstSelectedRecordIndex !== -1 ? firstSelectedRecordIndex : undefined
			dispatch(setSelectedRecords([]))
			dispatch(
				getRecords(
					steward.forTile,
					steward.pageIndex,
					steward.pageSize,
					steward.requestedClassification,
					steward.forAssignee,
					steward.forAssignment,
					selectRecordIndex
				)
			)
		})
	}
