import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { MatchTemplate } from '../types'
import { getMatchTemplate } from './api/getMatchTemplate'

export const useMatchTemplate = (templateId: string): UseQueryResult<MatchTemplate | undefined> => {
	const apiClient = useApi()

	const queryKey = ['getMatchTemplates', templateId]
	const queryFunction = () => {
		if (templateId && templateId !== '') return getMatchTemplate(apiClient, templateId)
	}
	return useQuery(queryKey, queryFunction)
}
