import { FC, useEffect, useState } from 'react'
import { useSource } from '../../queries/useSource'
import { SourceRefreshRematch } from './source-refresh-rematch'
import styles from './source-settings.module.scss'
import { SourceTransactionalMatch } from './source-transactional-match'

interface SourceSettingsProps {
	entityType: string
	sourceId: string
	readOnly: boolean
	wizardMode: boolean
}

export const SourceSettings: FC<SourceSettingsProps> = ({
	entityType,
	sourceId,
	readOnly,
	wizardMode
}: SourceSettingsProps) => {
	const sourceQuery = useSource(sourceId, true)
	const [isSalesforceSource, setSalesforceSource] = useState<boolean>(false)

	useEffect(() => {
		if (sourceQuery.isSuccess && sourceQuery.isFetched) {
			setSalesforceSource(sourceQuery.data?.integrationInfo?.integrationType === 'Salesforce')
		}
		/**
		 * We only want to run this effect when the flag isFetching from sourceQuery changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceQuery.isFetching])

	return isSalesforceSource ? (
		<div className={styles.sourceSettings}>
			<SourceRefreshRematch
				entityType={entityType}
				sourceId={sourceId}
				readOnly={readOnly}
				wizardMode={wizardMode}
			/>
			<SourceTransactionalMatch sourceId={sourceId} readOnly={readOnly} wizardMode={wizardMode} />
		</div>
	) : null
}
