import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../local-core-ui'
import styles from './icontoggleindicator.module.scss'

interface IconToggleIndicatorProps {
	textKeyPrefix: string
	enabled: boolean
}

export const IconToggleIndicator = ({ textKeyPrefix, enabled }: IconToggleIndicatorProps): ReactElement => {
	const { t } = useTranslation()
	const iconName = enabled ? 'simple-check' : 'x'
	const textName = `${textKeyPrefix}.${enabled ? 'enabled' : 'disabled'}`

	return (
		<div data-testid="indicator-icon" className={styles.indicator}>
			<Icon testId="icon-toggle-indicator" type={iconName} color={!enabled ? 'ColorScaleRed' : 'ColorGrass'} />
			<span data-testid="text-indicator" className={styles.text}>
				{t(textName)}
			</span>
		</div>
	)
}
