import { ColorGray, ColorGrayDarker, ColorWhite } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../local-core-ui'
import { PageSize } from '../../store/steward/types'
import { ElementsPerPage } from '../elements-per-page/elements-per-page'
import styles from './pagination-buttons.module.scss'

const grayBtn = {
	normalState: {
		textColor: ColorWhite,
		backgroundColor: ColorGrayDarker,
		borderColor: ColorGrayDarker
	},
	hoverState: {
		textColor: ColorWhite,
		backgroundColor: ColorGray,
		borderColor: ColorGray
	},
	focusState: {
		textColor: ColorWhite,
		backgroundColor: ColorGray,
		borderColor: ColorGray
	},
	disabledState: {
		textColor: ColorWhite,
		backgroundColor: ColorGrayDarker,
		borderColor: ColorGrayDarker
	}
}

interface PaginationButtonsProps {
	onNext(): void
	onPrevious(): void
	pageSize: PageSize
	onPageSizeChange(pageSize: PageSize): void
	listLength: number
	showBtnTxt?: boolean
	currentPage: number
}

export const PaginationButtons = ({
	onNext,
	onPrevious,
	pageSize,
	onPageSizeChange,
	listLength,
	showBtnTxt = true,
	currentPage
}: PaginationButtonsProps): ReactElement => {
	const { t } = useTranslation()
	const pageSizeOptions: PageSize[] = [20, 50, 100]
	const lastPage = Math.ceil(listLength / pageSize) || 1

	return (
		<div className={styles.paginationBtnsContainer}>
			{currentPage !== 1 && (
				<div className={`${styles.paginationBtnContainer} ${!showBtnTxt && styles.removeSpacing}`}>
					<Button
						text={
							showBtnTxt
								? t('pagination.buttons.previous', {
										pageSize
								  })
								: ''
						}
						onClick={onPrevious}
						testId="pagination-btns-previous"
						iconType="chevron-left"
						iconPosition="left"
						colors={grayBtn}
						short
					/>
				</div>
			)}
			{currentPage !== lastPage && (
				<div className={`${styles.paginationBtnContainer} ${!showBtnTxt && styles.removeSpacing}`}>
					<Button
						text={
							showBtnTxt
								? t('pagination.buttons.next', {
										pageSize
								  })
								: ''
						}
						onClick={onNext}
						testId="pagination-btns-next"
						iconType="chevron-right"
						iconPosition="right"
						colors={grayBtn}
						short
					/>
				</div>
			)}
			<ElementsPerPage
				paginationOptions={pageSizeOptions}
				selectedElement={pageSize}
				onElementsPerPageChanged={onPageSizeChange}
				textRight={t('pagination.buttons.records.page')}
			/>
		</div>
	)
}
