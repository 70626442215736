import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from '../../../../local-core-ui'
import styles from '../../refresh-rematch-now-confirmation-modal.module.scss'

interface ConfirmationModalStartProcessProps {
	open: boolean
	onClose(): void
	testId: string
	showButtonClose?: boolean
	onConfirmClick(): void
}

export const ConfirmationModalStartProcess = ({
	open,
	onClose,
	onConfirmClick,
	testId,
	showButtonClose = true
}: ConfirmationModalStartProcessProps): ReactElement => {
	const { t } = useTranslation()
	return (
		<div className={styles.confirmationModalSourceContainer} data-testid="confirmation-modal-source-container">
			<Modal
				open={open}
				isContainer={false}
				onClose={onClose}
				showButtonClose={showButtonClose}
				testId="confirmation-modal-source"
			>
				<div>
					<p className={styles.confirmationModalSourceDescription}>
						{t('source.refreshRematchNow.enable.description.complement')}
					</p>
				</div>

				<div className={styles.confirmationModalSourceButtonsContainer}>
					<Button
						text={t('source.refreshRematchNow.enable.btn.start')}
						onClick={onConfirmClick}
						testId={testId + '-confirm'}
					/>
					<Button
						type="secondary"
						text={t('confirmation.modal.cancel')}
						onClick={onClose}
						testId={testId + '-cancel'}
					/>
				</div>
			</Modal>
		</div>
	)
}
