import { AxiosInstance, AxiosResponse } from 'axios'
import { GetTeamResponse } from '../../../types'

export const updateTeam = async (teamId: string, newMembers: Array<string>, apiClient: AxiosInstance) => {
	if (teamId && teamId !== '') {
		const url = `/pls/teams/teamId/${teamId}`
		const teamName = await apiClient
			.get(url)
			.then((teamResponse: AxiosResponse<GetTeamResponse>) => teamResponse.data.TeamName)
			.catch(() => '')
		await apiClient
			.put(url, {
				teamName: teamName,
				teamMembers: newMembers
			})
			.catch((error) => {
				console.error(error)
			})
		return apiClient
			.get(url)
			.then((teamResponse: AxiosResponse<GetTeamResponse>) => teamResponse.data)
			.catch(() => {
				return { TeamId: teamId, TeamName: teamName, TeamMembers: [] }
			})
	} else {
		return Promise.resolve({ TeamId: '', TeamName: '', TeamMembers: [] })
	}
}
