import { IRouteProps } from '../../switch-routes/switch-routes'

export type WizardComposerAction = () => Promise<void>

export enum WizardComposerSubStepStates {
	pending,
	complete
}

export interface WizardComposerSubStepDefinition {
	title: string | (() => string)
	state: () => WizardComposerSubStepStates
	onNext?: WizardComposerAction
	onPrevious?: WizardComposerAction
	isNextEnabled: () => boolean
	route: IRouteProps
	onSaveProgress?: WizardComposerAction
}

export interface WizardComposerStepDefinition {
	title: string | (() => string)
	progress: () => number
	onNext?: WizardComposerAction
	onPrevious?: WizardComposerAction
	onCancel?: WizardComposerAction
	subSteps: Array<WizardComposerSubStepDefinition> | (() => Array<WizardComposerSubStepDefinition>)
	onSaveProgress?: WizardComposerAction
}
