import { ReactElement } from 'react'
import styles from './integration-tile.module.scss'

export interface IntegrationConfig {
	id: string
	title: string
	description: string
	isDisable: boolean
	isDisplayed: boolean
	imgSource: string
}

interface IntegrationTileProps {
	open?: boolean
	config: IntegrationConfig
	testId: string
	isSelected: boolean
	onClickTile(id: string): void
}

export const IntegrationTile = ({ testId, config, isSelected, onClickTile }: IntegrationTileProps): ReactElement => {
	return (
		<div
			className={`${styles.integrationTile} ${
				isSelected && !config.isDisable ? styles.selectedTile : styles.notSelectedTile
			} ${config.isDisable ? styles.integrationTileDisabled : ''}`}
			onClick={() => {
				if (!config.isDisable) onClickTile(config.id)
			}}
			data-testid={testId}
		>
			<img className={styles.imgTile} src={config.imgSource} alt={'snowflakes'} />
			<div className={styles.descriptionContainer}>
				<div className={styles.title}>{config.title}</div>
				<div className={styles.description}>{config.description}</div>
			</div>
		</div>
	)
}
