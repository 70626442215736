import { ColorMulberry, ColorPinkLighter } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, HalfDonutChart } from '../../local-core-ui'
import { DonutChart } from '../donut-chart/donut-chart'
import styles from './contact-match-overview.module.scss'

export interface MatchDriver {
	count: number
	matchDriver: string
	rate: number
}

interface ContactMatchOverviewProps {
	matchedCount: number
	unmatchedCount: number
	primaryColor?: string
	secondaryColor?: string
	testId: string
	matchDrivers: MatchDriver[]
	colors: Array<string>
}

export const ContactMatchOverview: FC<ContactMatchOverviewProps> = ({
	matchedCount,
	unmatchedCount,
	primaryColor = ColorMulberry,
	secondaryColor = ColorPinkLighter,
	testId,
	matchDrivers,
	colors
}: ContactMatchOverviewProps) => {
	const { t } = useTranslation()

	const chartData = Object()
	chartData[t('dashboard.contactMatchOverview.matched')] = matchedCount
	chartData[t('dashboard.contactMatchOverview.unmatched')] = unmatchedCount

	const chartColors = Object()
	chartColors[t('dashboard.contactMatchOverview.matched')] = primaryColor
	chartColors[t('dashboard.contactMatchOverview.unmatched')] = secondaryColor

	const mapMatchDrivers = matchDrivers.map((driver: MatchDriver) => {
		const label = driver.matchDriver !== undefined ? t('label.graph.' + driver.matchDriver.replaceAll(' ', '')) : ''
		return {
			label: label,
			value: driver.count,
			rate: driver.rate
		}
	})

	return (
		<div className={styles.contactMatchOverview} data-testid={testId}>
			<Grid testId="container-contact-match-overview" container>
				<Grid testId="title-subtitle-contact-first" md={5} sm={5}>
					<div className={styles.header}>
						<div className={styles.title}>{t('dashboard.contactMatchOverview.title')}</div>
					</div>
				</Grid>
				<Grid testId="title-subtitle-contact-second" md={7} sm={7}>
					<div className={`${styles.header} ${styles.withMargin}`}>
						<div className={styles.title}>{t('dashboard.contactMatchDriversOverview.title')}</div>
					</div>
				</Grid>
				<Grid testId="half-donut-contact-match" md={5} sm={5}>
					<HalfDonutChart
						colors={chartColors}
						data={chartData}
						defaultKey={t('dashboard.contactMatchOverview.matched') as string}
						testId="contactMatchDashboard"
					/>
				</Grid>
				<Grid testId="donut-contact-match" md={7} sm={7}>
					<div className={`${styles.withMargin}`}>
						<DonutChart
							colors={colors}
							data={mapMatchDrivers}
							showDividers={true}
							testId={'contact-match-drivers-dashboard' + testId}
							title={t('dashboard.contactMatchDriversOverview.matched') as string}
							matchedCount={matchedCount}
						/>
					</div>
				</Grid>
			</Grid>
		</div>
	)
}
