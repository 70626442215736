import { useTranslation } from 'react-i18next'
import { ColDef, ReportTable, RowDef } from '../../../../../components/report-table/report-table'
import { ReportTile } from '../../../../../components/report-tile/report-tile'
import { useMatchSummaryCodes } from '../../../../../queries/useMatchSummaryCodes'
import { ReportPage } from '../../../report'
import { getOrderingMap } from '../../utils/getOrderingMap'
import styles from '../appendix.module.scss'

type CodeDescriptions = Record<string, string> | undefined

type CodeDescriptionsList = {
	code: string
	description: string
}

const rowHeight = '4.4rem'
const NO_MATCH_CODE = 'No Match'
const codeOrdering = ['DUN', 'SIT', 'REG', 'CMP', 'GEO', 'URL', 'UNC', NO_MATCH_CODE]

const CodeDescriptions = () => {
	const { t } = useTranslation()
	const codeDescriptions = useMatchSummaryCodes(true).data as CodeDescriptions
	let codeDescriptionsList: CodeDescriptionsList[] = []

	const tableColumns: ColDef[] = [
		{
			field: 'summaryCodes',
			label: t('report.page.appendix.tile.codeDescriptions.table.column1') as string
		},
		{
			field: 'description',
			label: t('report.page.appendix.tile.codeDescriptions.table.column2') as string,
			width: 750
		}
	]

	const codeOrderingMap = getOrderingMap(codeOrdering)

	if (codeDescriptions) {
		codeDescriptionsList = Object.entries(codeDescriptions)
			.map(([key, value]) => ({
				code: key,
				description: value
			}))
			.sort((a, b) => {
				let codeA = ''
				let codeB = ''

				if (a.code === NO_MATCH_CODE) {
					codeA = NO_MATCH_CODE
				} else {
					codeA = a.code.slice(0, 3)
				}

				if (b.code === NO_MATCH_CODE) {
					codeB = NO_MATCH_CODE
				} else {
					codeB = b.code.slice(0, 3)
				}

				const codeAValue = codeOrderingMap[codeA] ?? 0
				const codeBValue = codeOrderingMap[codeB] ?? 0

				if (codeAValue < codeBValue) {
					return -1
				}

				if (codeAValue > codeBValue) {
					return 1
				}

				if (codeAValue === codeBValue) {
					if (a.code < b.code) {
						return -1
					}

					if (a.code > b.code) {
						return 1
					}
				}

				return 0
			})
	}

	const tableRows: RowDef[] = codeDescriptionsList.map(({ code, description }) => [code, description])

	return (
		<ReportPage>
			<div className={styles.appendixPage}>
				<span className={styles.header}>{t('report.page.appendix.header')}</span>
				<ReportTile title={t('report.page.appendix.tile.codeDescriptions.title') as string}>
					<ReportTable columns={tableColumns} rows={tableRows} rowHeight={rowHeight} zebraStripes="even" />
				</ReportTile>
			</div>
		</ReportPage>
	)
}

export { CodeDescriptions }
