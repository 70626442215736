import { AxiosResponse } from 'axios'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AssignmentModal } from '../../components/assignment-modal/assignment-modal'
import { AssignmentsManager } from '../../components/assignments-manager/assignments-manager'
import { AssignedToWithName } from '../../components/assignments-manager/assignments-manager-list'
import { ConfirmationModal } from '../../components/confirmation-modal/confirmation-modal'
import { RecordClassification, StewardFilterTabs } from '../../components/steward-filter-tabs/steward-filter-tabs'
import { useApi } from '../../hooks'
import { Button, LoadingState, Modal } from '../../local-core-ui'
import { usePublishedSummary } from '../../queries/usePublishedSummary'
import { useReadyToSteward } from '../../queries/useReadyToSteward'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import {
	setForTile,
	setRequestedClassification,
	setSelectedAssignee,
	setSelectedAssignment
} from '../../store/steward/stewardSlice'
import { addRecordsToAssignment, assignAllUnassignedRecords, movePublishedToReview } from '../../store/steward/thunks'
import { TileTypes } from '../../store/steward/types'
import {
	AssignmentDetails,
	GetAssignmentsSummaryListResponse,
	StewardAssigneeSummary,
	SummaryResponse,
	UnassignedList
} from '../../types'
import styles from './dashboard-steward.module.scss'
import { StewardCardView } from './steward-card-view/steward-card-view'
import { StewardTableView } from './steward-table-view/steward-table-view'

export type stewardViews = 'cardView' | 'tableView'

export function DashboardSteward(): ReactElement {
	const { t } = useTranslation()
	const selectSteward = (state: RootState) => state.steward
	const steward = useAppSelector(selectSteward)

	const [firstTime, setFirstTime] = useState(true)
	const [isLoading, setIsLoading] = useState(true)
	const [assignment, setAssignment] = useState<AssignmentDetails | undefined>()
	const [assignee, setAssignee] = useState<AssignedToWithName | undefined>()
	const [selectedView, setSelectedView] = useState<stewardViews>('cardView')

	const [assignedToMe, setAssignedToMe] = useState<StewardAssigneeSummary>({
		assignee: '',
		totalAssignments: 0,
		high: 0,
		medium: 0,
		low: 0
	})
	const [assignedToList, setAssignedToList] = useState<StewardAssigneeSummary[]>([])
	const [isGettingAssignees, setIsGettingAssignees] = useState<boolean>(false)
	const [unassignedRecordsCount, setUnassignedRecordsCount] = useState<number>()
	const [publishedRecordsCounts, setPublishedRecordsCounts] = useState<SummaryResponse>()
	const [isManagerHidden, setIsManagerHidden] = useState<boolean>(false)
	const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState<boolean>(false)
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)
	const [isAssignAllUnassigned, setIsAssignAllUnassigned] = useState<boolean>(false)
	const [isMoveToReviewModalOpen, setIsMoveToReviewModalOpen] = useState<boolean>(false)
	const [isMultiSelectActive, setIsMultiSelectActive] = useState<boolean>(false)
	const stewardViewContainer = useRef<HTMLDivElement>(null)
	const assignmentManagerContainer = useRef<HTMLDivElement>(null)
	const isAssignmentTileSelected = steward.forTile !== undefined && steward.forTile >= 0
	const isStewardFilterTabsAvailable = steward.forTile === TileTypes.Published || isAssignmentTileSelected
	const dispatch = useAppDispatch()
	const selectSession = (state: RootState) => state.session
	const session = useAppSelector(selectSession)
	const apiClient = useApi()
	const currentUser = session.user?.EmailAddress
	const hasBodyYScroll = window.innerHeight < document.body.scrollHeight

	const readyToStewardQuery = useReadyToSteward()
	const publishedSummaryQuery = usePublishedSummary()

	useEffect(() => {
		if (readyToStewardQuery.isFetched) {
			const isReadyToSteward = readyToStewardQuery.data || false
			setFirstTime(!isReadyToSteward)
		}
		/**
		 * We only want to run this effect when the flag isLoading from readyToStewardQuery  changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readyToStewardQuery.isLoading])

	const getAssignedToMe = (mounted = true) => {
		const url = '/pls/steward/assignments/summary/session'
		apiClient.get(url).then((response: AxiosResponse<StewardAssigneeSummary>) => {
			const mySummary = response.data
			if (mounted) setAssignedToMe(mySummary)
		})
	}

	const getAssignedToStewards = async (mounted = true) => {
		if (mounted) setIsGettingAssignees(true)
		const pageSize = 100
		let pageIndex = 1
		const assignees: StewardAssigneeSummary[] = []
		let totalAssignees = 0
		do {
			const url = `/pls/steward/assignments/summary/list?pageIndex=${pageIndex}&pageSize=${pageSize}`
			await apiClient.get(url).then((response: AxiosResponse<GetAssignmentsSummaryListResponse>) => {
				const stewardsSummaryResponse = response.data
				const stewardSummaries = stewardsSummaryResponse.summaries
				assignees.push(...stewardSummaries)
				totalAssignees = stewardsSummaryResponse.totalAssignees
				pageIndex++
			})
		} while (assignees.length < totalAssignees)
		const mySummaryIdx = assignees.findIndex((stewardSummary) => stewardSummary.assignee === currentUser)
		assignees.splice(mySummaryIdx, 1)
		if (mounted) {
			setAssignedToList(assignees)
			setIsGettingAssignees(false)
		}
	}

	useEffect(() => {
		if (publishedSummaryQuery.isFetched) setPublishedRecordsCounts(publishedSummaryQuery.data)
		/**
		 * We only want to run this effect when the flag isLoading from publishedSummaryQuery  changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [publishedSummaryQuery.isLoading])

	const getUnassignedTotalCount = (mounted = true) => {
		const url = '/pls/steward/records/list/unassigned?pageSize=1'
		apiClient.get(url).then((response: AxiosResponse<UnassignedList>) => {
			const unassignedData = response.data
			if (mounted) setUnassignedRecordsCount(unassignedData.total)
		})
	}

	useEffect(() => {
		let mounted = true
		if (!firstTime) {
			getAssignedToMe(mounted)
			getAssignedToStewards(mounted)
			getUnassignedTotalCount(mounted)
		} else setIsLoading(false)
		return () => {
			mounted = false
		}
		/**
		 * We only want to run this effect when the dispatch promise or firstTime changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, firstTime])

	useEffect(() => {
		const getLandingTile = () => {
			if (publishedRecordsCounts && publishedRecordsCounts.total > 0) {
				dispatch(setForTile(TileTypes.Published))
			} else if (unassignedRecordsCount && unassignedRecordsCount > 0) {
				dispatch(setForTile(TileTypes.Unassigned))
			}
			setIsLoading(false)
		}
		const isLanding = steward.forTile === undefined
		const hasSetCounts = publishedRecordsCounts !== undefined && unassignedRecordsCount !== undefined
		if (isLanding && hasSetCounts) {
			getLandingTile()
		}
		/**
		 * We only want to run this effect when the flag siLoading from publishedSummaryQuery  changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [publishedRecordsCounts, unassignedRecordsCount, steward.forTile])

	const handleTileSelected = (tileIdx: TileTypes) => {
		dispatch(setForTile(tileIdx))
		setDefaultClassification(tileIdx)
	}

	const setDefaultClassification = (tileIdx?: TileTypes) => {
		const defaultClassification =
			tileIdx === TileTypes.Published ? RecordClassification.Accepted : RecordClassification.NeedsReview
		dispatch(setRequestedClassification(defaultClassification))
	}

	const getAssignmentName = (): string => {
		switch (steward.forTile) {
			case TileTypes.Published:
				return (
					t('assignment.published.tile.title') +
					' > ' +
					t(`dashboard.tab.steward.${steward.requestedClassification}`)
				)
			case TileTypes.Unassigned:
				return t('assignment.overview.tile.unassigned')
			case TileTypes.Assigned:
				return t('assignment.overview.tile.assigned')
			case TileTypes.AssignedToMe:
			default:
				const isAssignedToMe = steward.forTile === TileTypes.AssignedToMe && assignedToMe.totalAssignments > 0
				const assignmentName = assignment?.displayName
				const assigneeName = isAssignedToMe ? t('assignments.to.tile.me') : assignee?.assigneeName
				return (
					(assignmentName || assigneeName) +
					' > ' +
					t(`dashboard.tab.steward.${steward.requestedClassification}`)
				)
		}
	}

	const updateTileCounts = (recordsAddedToMe: boolean) => {
		if (recordsAddedToMe) {
			getAssignedToMe()
		} else {
			getAssignedToStewards()
		}
		getUnassignedTotalCount()
	}

	const handleAddRecordsToAssignment = (assignmentId: string, selectedPerson: string) => {
		const thunkToDispatch = isAssignAllUnassigned ? assignAllUnassignedRecords : addRecordsToAssignment
		dispatch(thunkToDispatch(assignmentId)).then(() => {
			const recordsAddedToMe = selectedPerson === currentUser
			updateTileCounts(recordsAddedToMe)
			setIsAssignmentModalOpen(false)
			setIsConfirmationModalOpen(true)
			if (isAssignAllUnassigned) setIsAssignAllUnassigned(false)
		})
		setIsMultiSelectActive(false)
	}

	const assignUnassignedRecords = () => {
		setIsAssignAllUnassigned(true)
		setIsAssignmentModalOpen(true)
	}

	const handleSelectedAssignment = (newAssignment: AssignmentDetails | undefined) => {
		setAssignment(newAssignment)
		if (newAssignment !== undefined) {
			dispatch(setSelectedAssignment(newAssignment.assignmentId))
		} else {
			dispatch(setSelectedAssignment(newAssignment))
		}
	}

	const handleSelectedAssignee = (assigneeSummary: AssignedToWithName) => {
		setAssignee(assigneeSummary)
		dispatch(setSelectedAssignee(assigneeSummary.assignee))
	}

	useEffect(() => {
		const getAssignmentManagerPosition = () => {
			const viewContainer = stewardViewContainer.current
			const assignmentManager = assignmentManagerContainer.current
			if (viewContainer && assignmentManager) {
				const assignmentManagerOpenHeight = 327
				const isAssignmentManagerOpen = assignmentManager.offsetHeight === assignmentManagerOpenHeight
				const filterTabsTopHidden = -70
				const managerOpenTopHidden = -160
				const managerClosedTopHidden = -40
				const filterTabsMin = isAssignmentManagerOpen
					? managerOpenTopHidden + filterTabsTopHidden
					: managerClosedTopHidden + filterTabsTopHidden
				const defaultMin = isAssignmentManagerOpen ? managerOpenTopHidden : managerClosedTopHidden
				const minScroll = isStewardFilterTabsAvailable ? filterTabsMin : defaultMin
				const managerTop = assignmentManager.getBoundingClientRect().top
				if (managerTop < minScroll && !isManagerHidden) {
					setIsManagerHidden(true)
				} else if (managerTop > minScroll && isManagerHidden) {
					setIsManagerHidden(false)
				}
			}
		}
		window.addEventListener('scroll', getAssignmentManagerPosition)
		return () => {
			window.removeEventListener('scroll', getAssignmentManagerPosition)
		}
	}, [isManagerHidden, isStewardFilterTabsAvailable])

	return (
		<div className={styles.dashboardStewardContainer}>
			{isLoading || isGettingAssignees ? (
				<div className={styles.dashboardStewardLoaderContainer}>
					<LoadingState />
				</div>
			) : (
				firstTime && (
					<div className={styles.dashboardStewardFirstTimeCopyContainer}>
						<p className={styles.dashboardStewardFirstTimeCopyDescription}>
							{t('first.time.video.stewardship.description')}
						</p>
						<p className={styles.dashboardStewardFirstTimeCopyAdditionalDescription}>
							{t('first.time.video.stewardship.additional.description')}
						</p>
					</div>
				)
			)}
			<AssignmentModal
				testId="dashboard-steward-assignment-modal"
				isOpen={isAssignmentModalOpen}
				onClose={() => setIsAssignmentModalOpen(false)}
				onAddToThisAssignment={handleAddRecordsToAssignment}
			/>
			<Modal
				open={isConfirmationModalOpen}
				isContainer={false}
				showButtonClose={false}
				testId="StewardConfirmationModal"
			>
				<div className={styles.dashboardStewardConfirmationModal}>
					<p className={styles.dashboardStewardConfirmationMessage}>
						{t('dashboard.steward.confirmation.modal.message')}
					</p>
					<Button
						testId="dashboard-steward-confirmation-modal-button"
						text={t('dashboard.steward.confirmation.modal.view.assignments')}
						onClick={() => {
							setIsConfirmationModalOpen(false)
						}}
						size="large"
					/>
				</div>
			</Modal>
			<ConfirmationModal
				open={isMoveToReviewModalOpen}
				onClose={() => setIsMoveToReviewModalOpen(false)}
				description={t('dashboard.steward.multi.review.confirmation.description')}
				confirmText={t('continue')}
				onConfirmClick={() => {
					dispatch(movePublishedToReview())
					setIsMoveToReviewModalOpen(false)
					setIsMultiSelectActive(false)
				}}
				testId="dashboard-steward-multi-review-confirmation-modal"
				showButtonClose={false}
			/>
			<div className={`${firstTime ? styles.dashboardStewardBlurContainer : ''}`}>
				<div
					ref={assignmentManagerContainer}
					className={`${styles.dashboardStewardManagerContainer} ${
						hasBodyYScroll ? styles.dashboardStewardManagerScrollOnBody : ''
					}`}
				>
					<AssignmentsManager
						publishedRecordsCounts={publishedRecordsCounts}
						unassignedRecordsCount={unassignedRecordsCount}
						assignedRecordsCount={0}
						assignedToMe={assignedToMe}
						assignedToList={assignedToList}
						selectedTile={steward.forTile}
						onTileSelected={handleTileSelected}
						selectedAssignmentId={assignment?.assignmentId}
						onAssignmentSelected={handleSelectedAssignment}
						onAssign={assignUnassignedRecords}
						onAssigneeSelected={handleSelectedAssignee}
					/>
				</div>
				{isStewardFilterTabsAvailable && (
					<StewardFilterTabs
						selectedView={selectedView}
						onChangeView={setSelectedView}
						isNeedsReviewAvailable={isAssignmentTileSelected}
					/>
				)}
				{isManagerHidden && (
					<div className={styles.dashboardStewardAssignmentNameContainer}>
						<p className={styles.dashboardStewardAssignmentName}>{getAssignmentName()}</p>
					</div>
				)}
				<div ref={stewardViewContainer}>
					{selectedView === 'cardView' && steward.forTile !== undefined && (
						<StewardCardView
							onTableView={() => setSelectedView('tableView')}
							assignmentName={getAssignmentName()}
							isStewardFilterTabsAvailable={isStewardFilterTabsAvailable}
							selectedTile={steward.forTile}
							recordListSortBy="nameAsc"
							onRecordListSortByChange={(sortBy) => console.log('onRecordListSortByChange', sortBy)}
							onAssign={() => setIsAssignmentModalOpen(true)}
							onMultiSelectToggle={() => setIsMultiSelectActive(!isMultiSelectActive)}
							isMultiSelectActive={isMultiSelectActive}
						/>
					)}
					{selectedView === 'tableView' && steward.forTile !== undefined && (
						<StewardTableView
							onCardView={() => setSelectedView('cardView')}
							isStewardFilterTabsAvailable={isStewardFilterTabsAvailable}
							recordListSortBy="nameAsc"
							onRecordListSortByChange={(sortBy) => console.log('onRecordListSortByChange', sortBy)}
							onAssign={() => setIsAssignmentModalOpen(true)}
							selectedTile={steward.forTile}
							onMultiSelectToggle={() => setIsMultiSelectActive(!isMultiSelectActive)}
							isMultiSelectActive={isMultiSelectActive}
						/>
					)}
				</div>
			</div>
		</div>
	)
}
