import cx from 'classnames'
import * as d3 from 'd3'
import { FC, MouseEvent, useEffect, useMemo, useRef, useState } from 'react'
import world from '../assets/data/world.json'
import { createTokenList, getValueToken } from '../design-tokens/utils'
import { pxToRem } from '../helpers/converter-px-rem'
import * as tokens from '../shared/token-colors.json'
import styles from './country-map-summary.module.scss'

export interface IRegionCustom {
	blockId: string
	values?: Array<string>
	blockName: string
	color: string
	baseRule?: boolean
}

interface ICountryMapSummary {
	testId: string
	width?: number
	countrySummary: Array<IRegionCustom>
	translationFunction?(i18nKey: string): string
	toggleCountry?(group: IRegionCustom): void
}

interface ITooltipData {
	blockId?: string
	blockName?: string
	visible: boolean
	color?: string
	groupCountries?: Array<string>
	group?: IRegionCustom
}

interface ICountry {
	properties: {
		name: string
	}
	id2: string
	id: string
}

export type RegionOptions = 'world' | 'amer' | 'emea' | 'apac' | 'au' | 'as' | 'eu' | 'na' | 'sa' | 'af'

export const CountryMapSummary: FC<ICountryMapSummary> = ({
	testId,
	width = 700,
	countrySummary,
	translationFunction,
	toggleCountry
}: ICountryMapSummary) => {
	const colorsTokenList = createTokenList(tokens)
	const mapContainer = useRef<HTMLDivElement>()
	const svgMap = useRef<SVGSVGElement>()
	const height: number = width * 0.65
	const tooltip = useRef<HTMLDivElement>()
	const [tooltipData, setTooltipData] = useState<ITooltipData>({ visible: false })

	const scale = 100
	const translateX = 0
	const translateY = 0

	const path = useMemo(() => {
		const projection = d3.geoMercator().fitWidth(width, world)
		return d3.geoPath(projection)
	}, [scale, translateX, translateY])

	useEffect(() => {
		const transform = `translate(${translateX},${translateY})scale(${scale / 100})`
		d3.select(svgMap.current).transition().duration(750).attr('transform', transform)
	}, [scale, translateX, translateY])

	const getDataRegion = (country) => {
		const getData = countrySummary.find((item: IRegionCustom) => {
			if (item.values) {
				return item.values.find((value) => {
					return value === country.id || value === country.id2
				})
			}
			return false
		})
		if (getData !== undefined) {
			return getData
		} else {
			const idxBaseRule = countrySummary.findIndex((regionCustom) => regionCustom.baseRule)
			if (idxBaseRule > -1) {
				let countries = countrySummary[idxBaseRule]?.values ? countrySummary[idxBaseRule].values : []
				countries = countries.concat(country.id2)
				countrySummary[idxBaseRule].values = countries
				return countrySummary[idxBaseRule]
			} else {
				return undefined
			}
		}
	}

	const mapMouseMove = (event: MouseEvent): void => {
		if (event.relatedTarget === null && (event.target as Element).tagName === 'svg') {
			setTooltipData({ visible: false })
		}
		if (tooltip.current) {
			const offset = mapContainer.current.getBoundingClientRect()
			tooltip.current.setAttribute(
				'style',
				'left:' +
					pxToRem(event.clientX - offset.x + 5) +
					'; top: ' +
					pxToRem(event.clientY - offset.y - 35) +
					';'
			)
		}
	}

	const pathMouseOver = (group: IRegionCustom, color: string, event: MouseEvent): void => {
		const groupCountries = group.values.map((country) => {
			const countryFeature = world.features.find((countryFeatures) => countryFeatures.id2 === country)
			return getCountryTranslation(countryFeature)
		})
		if (tooltip.current) {
			const offset = mapContainer.current.getBoundingClientRect()
			tooltip.current.setAttribute(
				'style',
				'left:' +
					pxToRem(event.clientX - offset.x - 2) +
					'; top: ' +
					pxToRem(event.clientY - offset.y - 35) +
					';'
			)
		}
		setTooltipData({
			blockName: group.blockName,
			visible: true,
			color,
			groupCountries,
			blockId: group.blockId,
			group
		})
	}

	const pathMouseOut = (event: MouseEvent): void => {
		const x = event.relatedTarget as HTMLElement
		if ((x !== null && x.tagName !== 'SPAN') || x === null) {
			setTooltipData({ visible: false })
		}
	}

	const getCountryTranslation = (country: ICountry): string => {
		let name = country.properties.name
		if (translationFunction && translationFunction('country.' + country.id2.toLowerCase())) {
			name = translationFunction('country.' + country.id2.toLowerCase())
		}
		return name
	}

	const pathClick = (group: IRegionCustom): void => {
		if (toggleCountry) toggleCountry(group)
	}

	const mouseOutCountries = () => {
		setTooltipData({ visible: false })
	}

	return (
		<>
			<div
				data-testid={`map-container-${testId}`}
				ref={mapContainer}
				className={cx(styles.mapContainer)}
				style={{ width: pxToRem(width) }}
			>
				<svg
					ref={svgMap}
					className={cx(styles.map)}
					onMouseMove={mapMouseMove}
					style={{ height: pxToRem(height) }}
					data-testid={testId + '-svg'}
				>
					<defs data-testid={`map-defs-${testId}`}>
						<pattern
							id="disabled-fill"
							className={styles.disabledFill}
							x="0"
							y="0"
							width="8"
							height="8"
							patternUnits="userSpaceOnUse"
							data-testid={`map-pattern-${testId}`}
						>
							<line data-testid={`map-line-1-${testId}`} x1="6" y1="-2" x2="-2" y2="6" />
							<line data-testid={`map-line-2-${testId}`} x1="10" y1="2" x2="2" y2="10" />
						</pattern>
					</defs>
					{world.features.map((country) => {
						const group = getDataRegion(country)
						const color = group?.color || '$color-gray-light'
						return (
							<path
								key={country.id}
								id={'country-' + country.id}
								d={path(country)}
								data-testid={testId + '-country-' + country.id}
								style={{ fill: getValueToken(colorsTokenList, color) }}
								onMouseOver={(event) => {
									if (group) {
										pathMouseOver(group, getValueToken(colorsTokenList, color), event)
									}
								}}
								onMouseOut={pathMouseOut}
								onClick={() => {
									pathClick(group)
								}}
							/>
						)
					})}
				</svg>
			</div>
			<div
				ref={tooltip}
				className={cx(styles.tooltip, { [styles.visible]: tooltipData.visible })}
				data-testid={testId + '-tooltip'}
				onClick={() => {
					if (tooltipData.group) {
						pathClick(tooltipData.group)
					}
				}}
			>
				<div data-testid={`map-first-line-container-${testId}`} className={styles.firstLine}>
					<div
						data-testid={`map-square-color-${testId}`}
						className={styles.squareColor}
						style={{ backgroundColor: tooltipData.color }}
					/>
					<span data-testid={`map-tooltip-data-${testId}`}>{tooltipData.blockName}</span>
				</div>
				<div data-testid={`map-container-detail-${testId}`} className={styles.containerDetail}>
					<div
						data-testid={`map-container-countries-group-${testId}`}
						className={styles.countries}
						onMouseOut={mouseOutCountries}
						key={tooltipData.blockId}
					>
						<span data-testid={`map-tooltip-countries-${testId}`}>
							{tooltipData.groupCountries?.join(', ')}
						</span>
					</div>
				</div>
			</div>
		</>
	)
}

export default CountryMapSummary
