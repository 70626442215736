import { Element } from '../../../../types'
import { generateHashCode } from '../../../../utilities'

export const generateHashesOfDataBlocks = (dataBlockList: Element[][]): string => {
	const hashes = dataBlockList.map((dataBlock) => {
		const elementsId = dataBlock.map((element) => element.elementId)
		return generateHashCode(elementsId.join('|'))
	})
	return hashes
		.filter((hash: number) => hash !== undefined)
		.sort()
		.join('|')
}
