export interface DataReport {
	basicStats: {
		totalSubmitted: number
		successCnt: number
		errorCnt: number
		matchedCnt: number
		pendingReviewCnt: number
		unmatchedCnt: number
	}
	inputPresenceReport: {
		inputPresenceList: Array<{
			field: string
			count: number
			rate: number
		}>
	}
	geoDistributionReport: {
		geoDistributionList: Array<{
			geoCode: string
			geoName?: string
			count: number
			rate: number
		}>
	}
	matchToDunsReport: {
		matched: number
		unmatched: number
		noMatchCnt: number
		matchedRate: number
		unmatchedRate: number
		noMatchRate: number
		confidenceRateList: Array<{
			confidenceCode: number
			count: number
			rate: number
			classification: string
		}>
	}
	matchToContactReport: {
		matched: number
		unmatched: number
		noMatchCnt: number
		matchedRate: number
		unmatchedRate: number
		noMatchRate: number
		matchDrivers: Array<{
			count: number
			matchDriver: string
			rate: number
		}>
	}
	duplicationReport: {
		uniqueRecords: number
		duplicateRecords: number
		uniqueRate: number
		duplicateRate: number
	}
	refreshTimestamp: number
	emailCategoryReport: {
		emailCategoryList: Array<{
			description: string
			count: number
			rate: number
		}>
		total: number
	}
	emailQualityReport: {
		emailQualityList: Array<{
			description: string
			count: number
			rate: number
			total: number
		}>
	}

	emailTypeReport: {
		emailTypeList: Array<{
			description: string
			count: number
			rate: number
			total: number
		}>
	}

	matchSummaryCodeReport: {
		summaryCodeList: Array<{
			code: string
			count: number
			rate: number
		}>
		total: number
	}

	stewardshipValueReport: {
		stewardshipValueList: Array<{
			value: string
			count: number
			rate: number
		}>
		total: number
	}
}

const DataReportStub = {
	basicStats: {
		totalSubmitted: 0,
		successCnt: 0,
		errorCnt: 0,
		matchedCnt: 0,
		pendingReviewCnt: 0,
		unmatchedCnt: 0
	},
	inputPresenceReport: {
		inputPresenceList: [
			{
				field: '',
				count: 0,
				rate: 0
			}
		]
	},
	geoDistributionReport: {
		geoDistributionList: [
			{
				geoCode: '',
				geoName: '',
				count: 0,
				rate: 0
			}
		]
	},
	matchToDunsReport: {
		matched: 0,
		unmatched: 0,
		noMatchCnt: 0,
		matchedRate: 0,
		unmatchedRate: 0,
		noMatchRate: 0,
		confidenceRateList: [
			{
				confidenceCode: 0,
				count: 0,
				rate: 0,
				classification: ''
			}
		]
	},
	matchToContactReport: {
		matched: 0,
		unmatched: 0,
		noMatchCnt: 0,
		matchedRate: 0,
		unmatchedRate: 0,
		noMatchRate: 0,
		matchDrivers: [
			{
				count: 0,
				matchDriver: '',
				rate: 0
			}
		]
	},
	duplicationReport: {
		uniqueRecords: 0,
		duplicateRecords: 0,
		uniqueRate: 0,
		duplicateRate: 0
	},
	refreshTimestamp: 0
}

export { DataReportStub }
