import { FC } from 'react'
import { Trans } from 'react-i18next'
import { useModules, usePlatform } from '../../hooks/useEntitlements'
import styles from './dcp-app-footer.module.scss'

export const DcpAppFooter: FC = () => {
	const privacyPolicyLink = 'https://www.dnb.com/utility-pages/privacy-policy.html'
	const termsLink = 'https://www.dnb.com/utility-pages/terms-of-use.html'
	const isC4S = useModules(['C4S'])
	const isSalesforce = usePlatform('salesforce')

	return (
		<div className={styles.dcpAppFooterContainer}>
			<Trans i18nKey="dcp.app.footer" values={{ year: new Date().getFullYear().toString() }}>
				<span />
				<a
					href={
						isC4S && isSalesforce
							? import.meta.env.VITE_C4S_SUPPORT_URL
							: import.meta.env.VITE_DISCOVER_SUPPORT_URL
					}
					target="_blank"
					rel="noopener noreferrer"
					data-testid="support-link"
				/>
				<a href={privacyPolicyLink} target="_blank" rel="noopener noreferrer" data-testid="pp-link" />
				<a href={termsLink} target="_blank" rel="noopener noreferrer" data-testid="terms-link" />
			</Trans>
		</div>
	)
}
