import { WizardContext } from '../../../components/wizardComposer/helpers/WizardContext'
import {
	WizardComposerStepDefinition,
	WizardComposerSubStepStates
} from '../../../components/wizardComposer/types/WizardComposerStepDefinition'
import { startImport, startTray } from '../../../store/projectWizard/thunks'
import { ProjectWizardState } from '../../../store/projectWizard/types'
import { ProjectOverviewWizard } from './project-overview-wizard'

export const WizardComposerProjectOverviewStepDefinition = (context: WizardContext): WizardComposerStepDefinition => {
	const fileOnlyState = (projectWizardState: ProjectWizardState) =>
		projectWizardState.currentProject.source.isApi && !projectWizardState.currentProject.source.isFile

	return {
		title: () =>
			fileOnlyState(context.projectWizardState)
				? 'project.wizard.step.title.review'
				: 'project.wizard.step.title.review.submit',
		progress: () => (context.projectWizardState.overviewStep.viewed ? 100 : 0),
		onNext: async () => {
			const thunkToDispatch = context.projectWizardState.currentProject.thirdPartyIntegration
				? startTray
				: startImport
			await context
				.dispatch(thunkToDispatch())
				.then()
				.catch((err) => {
					throw err
				})
		},
		onPrevious: undefined,
		subSteps: [
			{
				title: 'project.wizard.step.title.review.submit',
				state: () =>
					context.projectWizardState.overviewStep.viewed
						? WizardComposerSubStepStates.complete
						: WizardComposerSubStepStates.pending,
				onNext: undefined,
				onPrevious: undefined,
				isNextEnabled: () => !context.projectWizardState.loadingNextStep,
				route: { path: '/review', component: ProjectOverviewWizard }
			}
		]
	}
}
