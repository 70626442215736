/**
 * @class ToggleSwitchAssignment
 */

import { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '../icon/icon'
import { ColorGrayDarker, ColorWhite } from '../shared/token-colors.json'
import styles from './toggle-switch-assignment.module.scss'

export interface IToggleSwitchAssignmentProps {
	id: string
	/** Use event.target.checked to get the new input value (returns a boolean)*/
	onChange(event: ChangeEvent<HTMLInputElement>): void
	checked?: boolean
	label: string
	disabled?: boolean
	testId: string
}

export const ToggleSwitchAssignment: FC<IToggleSwitchAssignmentProps> = ({
	id,
	onChange,
	checked = false,
	label,
	disabled = false,
	testId
}: IToggleSwitchAssignmentProps) => {
	const { t } = useTranslation()
	return (
		<div data-testid={`toggle-switch-container-${testId}`} className={styles.ToggleSwitchContainer}>
			<label data-testid={`toggle-label-${testId}`} htmlFor={id}>
				{label}
			</label>
			<div
				className={
					t('source.enforce.assignment.rules') == label
						? styles.ToggleMarginAssignment
						: t('source.transactional.match') == label
						? styles.ToggleMarginTransactional
						: styles.ToggleMarginRefresh
				}
			>
				<div data-testid={`toggle-switch-${testId}`} className={styles.ToggleSwitch}>
					<input
						id={id}
						type="checkbox"
						checked={checked}
						onChange={onChange}
						disabled={disabled}
						data-testid={testId}
						aria-hidden="true"
					/>
					<span data-testid={`toggle-slider-${testId}`} className={styles.Slider}>
						<span data-testid={`toggle-bullet-${testId}`} className={styles.Bullet}>
							<Icon
								testId={`toggle-empty-dot-${testId}`}
								type="empty-dot"
								size="mini"
								color={checked ? ColorWhite : ColorGrayDarker}
							/>
						</span>
					</span>
				</div>
			</div>
		</div>
	)
}

export default ToggleSwitchAssignment
