import { DNBButton } from '@dnb-uux-design-system/react'
import { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Modal } from '../../../../local-core-ui'
import styles from './trade-up-modal.module.scss'

interface TradeUpModalProps {
	showTradeUp: boolean
	onModalProceed(): void
	onModalTurnOff(): void
}

export function TradeUpModal({ showTradeUp, onModalProceed, onModalTurnOff }: TradeUpModalProps): ReactElement {
	const { t } = useTranslation()

	return (
		<Modal open={showTradeUp} showButtonClose={false} testId="ConfirmTradeUpModalC4S">
			<p className={styles.modalContent}>
				<Trans i18nKey={'enriching.step.tradeup.model.warning.header.note'} />
				<Trans i18nKey={'enriching.step.tradeup.model.warning.content.p1'} />
				<Trans i18nKey={'enriching.step.tradeup.model.warning.content.p2'} />
				<Trans i18nKey={'enriching.step.tradeup.model.warning.content.p3'} />
				<Trans i18nKey={'enriching.step.tradeup.model.warning.content.p4'} />
			</p>
			<div className={styles.modalButtonsContainer}>
				<DNBButton
					size="small"
					variant="secondary"
					onClick={() => onModalProceed()}
					data-testid={'proceed-modal-button'}
				>
					{t('enriching.step.tradeup.proceed')}
				</DNBButton>
				<DNBButton
					size="small"
					variant="secondary"
					onClick={() => onModalTurnOff()}
					data-testid={'turnoff-modal-button'}
				>
					{t('enriching.step.tradeup.trunoff')}
				</DNBButton>
			</div>
		</Modal>
	)
}
