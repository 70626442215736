/**
 * Modal
 */
import cx from 'classnames'
import { FC, MouseEvent, PropsWithChildren, useEffect, useState } from 'react'
import { Icon } from '../icon/icon'
import styles from './modal.module.scss'

/**
- The app bar at the top of most pages.
- Doesn't provide any content itself, but takes children.
**/

export interface ModalProps {
	open?: boolean
	isContainer?: boolean
	onClose?(event: MouseEvent<HTMLDivElement>): void
	showButtonClose?: boolean
	disableBackdropFilter?: boolean
	fixWidth?: boolean
	testId: string
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
	children,
	open = false,
	isContainer = false,
	onClose,
	showButtonClose = true,
	disableBackdropFilter = false,
	fixWidth = true,
	testId
}: PropsWithChildren<ModalProps>) => {
	const [noScrollSet, setNoScrollSet] = useState(false)
	const noScrollClass = styles.noScrollOnBody
	useEffect(() => {
		if (open) {
			if (!document.body.classList.contains(noScrollClass)) {
				document.body.classList.add(noScrollClass)
				setNoScrollSet(true)
			}
		} else {
			if (noScrollSet) {
				document.body.classList.remove(noScrollClass)
				setNoScrollSet(false)
			}
		}
		return () => {
			if (noScrollSet) {
				document.body.classList.remove(noScrollClass)
				setNoScrollSet(false)
			}
		}
	}, [open, noScrollSet])

	return (
		<>
			{open && (
				<div
					data-testid={`modal-container-${testId}`}
					className={cx(
						styles.modalOverlay,
						{ [styles.open]: open },
						{ [styles.noFilter]: disableBackdropFilter }
					)}
				>
					<div
						id={'modal'}
						data-testid={`modal-type-container-${testId}`}
						className={cx(
							styles.modal,
							{ [isContainer ? styles.typeContainer : styles.typeNotification]: fixWidth },
							{
								[styles.fixWidth]: fixWidth
							}
						)}
					>
						{showButtonClose && (
							<div
								data-testid={testId + '-close'}
								className={styles.buttonClose}
								onClick={(e) => onClose(e)}
							>
								<Icon
									testId={`modal-close-${testId}`}
									type={'x'}
									size={'small'}
									color={'ColorBluePrimary'}
								/>
							</div>
						)}
						{children}
					</div>
				</div>
			)}
		</>
	)
}

export default Modal
