import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { RumUsage } from '../types'
import { getRumUsage } from './api/getRumUsage'

export const useRumUsage = (): UseQueryResult<Array<RumUsage>> => {
	const apiClient = useApi()

	const queryKey = ['getRumUsage']
	const queryFunction = () => getRumUsage(apiClient)

	return useQuery(queryKey, queryFunction)
}
