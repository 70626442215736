import { FC } from 'react'
import { Redirect, useRouteMatch } from 'react-router-dom'
import { IRouteProps, SwitchRoutes } from '../../../switch-routes/switch-routes'
import { composeRoute } from '../../helpers/composeRoute'
import { useWizardRouteMatch } from '../../hooks/useWizardRouteMatch'
import { WizardKey } from '../../wizardComposer'
import styles from './stepContainer.module.scss'

interface StepSubRoutesProps {
	subStepsRoutes?: IRouteProps[]
}

const StepSubRoutes: FC<StepSubRoutesProps> = ({ subStepsRoutes }) => {
	return (
		<>
			{subStepsRoutes && subStepsRoutes.length > 0 ? (
				<SwitchRoutes routes={subStepsRoutes} />
			) : (
				<div>Uhh Ohh something went wrong</div>
			)}
		</>
	)
}

export const StepContainer: FC = () => {
	const wizardParams = useRouteMatch<WizardKey>('/:prefix/:wizKey/:steps/:substep')
	const wizardStepParams = useRouteMatch<WizardKey>('/:prefix/:wizKey/:step')

	const { currentStep, currentSubStep, currentStepRoute, ready } = useWizardRouteMatch()

	const currentStepSubSteps =
		typeof currentStep?.subSteps === 'function' ? currentStep.subSteps() : currentStep?.subSteps

	const currentStepSubStepsRoutes = currentStepSubSteps?.map((subStepDefinition) =>
		composeRoute(
			subStepDefinition.route,
			`/${wizardStepParams?.params.prefix}/${wizardStepParams?.params.wizKey}/${wizardStepParams?.params.step}`
		)
	)

	const subStepToJumpToRoute = currentSubStep
		? composeRoute(currentSubStep.route, `${currentStepRoute?.path}`)
		: undefined

	return (
		<>
			{ready && (
				<>
					<div data-testid={`panel-${currentSubStep?.title}`} className={styles.panel}>
						{(wizardParams?.params.substep === undefined || wizardParams?.params.substep === '') &&
						subStepToJumpToRoute &&
						subStepToJumpToRoute?.path != wizardStepParams?.url ? (
							<Redirect to={subStepToJumpToRoute?.path || '/'} />
						) : (
							<StepSubRoutes subStepsRoutes={currentStepSubStepsRoutes} />
						)}
					</div>
				</>
			)}
		</>
	)
}
