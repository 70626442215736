import { forwardRef, ReactElement, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { MatchScore } from './matchScore'
import { RowContent } from './rowContent'
import styles from './tooltip.module.scss'

interface IToolTipData {
	label: string
	value: string
}

export interface ToolTipInfo {
	position?: 'top' | 'right' | 'rightTop' | 'rightBottom' | 'left' | 'leftTop' | 'leftBottom' | 'bottom'
	id: string
	values?: Array<IToolTipData>
	text?: string
	title?: string
	matchScore?: number
	effect?: 'float' | 'solid'
	customContent?: ReactElement
	keepTooltipOnMouseOver?: boolean
	testId: string
	show: boolean
	top?: number
	left?: number
	zIndex: string
}

export const ToolTipContent = forwardRef<HTMLDivElement, ToolTipInfo>(
	(
		{
			position = 'bottom',
			values,
			text,
			title,
			matchScore,
			effect = 'float',
			customContent,
			keepTooltipOnMouseOver = false,
			testId,
			show,
			id,
			top = 0,
			left = 0,
			zIndex
		}: ToolTipInfo,
		ref
	) => {
		const containerRef = useRef<HTMLElement | null>(null)
		const [onTooltip, setOnTooltip] = useState(false)

		useEffect(() => {
			// Look for existing target dom element to append to, or create one
			if (id) {
				const container = document.getElementById(id)
					? document.getElementById(id)
					: document.createElement('div')

				if (container && !container.id) {
					container.id = id
					document.body.appendChild(container)
					containerRef.current = container

					containerRef.current.style.position = 'absolute'
					containerRef.current.style.top = `${top}px`
					containerRef.current.style.left = `${left}px`
					containerRef.current.style.zIndex = zIndex
					if (keepTooltipOnMouseOver) {
						container.addEventListener('mouseover', () => mouseOverTooltip())
						container.addEventListener('mouseout', () => mouseOutTooltip())
					}
				}

				return () => {
					if (containerRef.current) document.body.removeChild(containerRef.current)
				}
			}
		}, [id])

		useEffect(() => {
			if (containerRef.current) {
				containerRef.current.style.top = `${top}px`
				containerRef.current.style.left = `${left}px`
				containerRef.current.style.zIndex = zIndex
			}
		}, [top, left, zIndex])

		const mouseOverTooltip = () => {
			if (containerRef.current) {
				setOnTooltip(true)
			}
		}

		const mouseOutTooltip = () => {
			if (containerRef.current) {
				setOnTooltip(false)
			}
		}

		const copyOfValue = values ? [...values] : []

		const getContent = () => {
			if (customContent) {
				return (
					<div
						data-testid={`tooltip-container-common-${testId}`}
						className={styles.tooltipContainerCommon}
						ref={ref}
					>
						{customContent}
					</div>
				)
			} else if (values && copyOfValue.length != 0) {
				return (
					<div
						data-testid={`tooltip-container-general-${testId}`}
						className={styles.tooltipContainerCommon}
						ref={ref}
					>
						<div
							data-testid={`tooltip-container-general-table-${testId}`}
							className={styles.tooltipContainerTable}
						>
							<div data-testid={`tooltip-content-${testId}`} className={styles.tooltipContent}>
								{matchScore !== undefined ? (
									<MatchScore testId={`tooltip-score-${testId}`} matchScore={matchScore} />
								) : undefined}
								<div data-testid={`tooltip-container-table-${testId}`} className={styles.dataTableDiv}>
									<table data-testid={`tooltip-table-${testId}`} className={styles.dataTable}>
										<tbody data-testid={`tooltip-body-${testId}`}>
											{title ? (
												<tr data-testid={`tooltip-tr-${testId}`}>
													<td
														data-testid={`tooltip-td-${testId}`}
														colSpan={2}
														className={styles.title}
													>
														{title}
													</td>
												</tr>
											) : undefined}
											{copyOfValue.map((row, index) => {
												return (
													<RowContent
														testId={`tooltip-row-content-${testId}`}
														key={index}
														label={row.label}
														value={row.value}
													/>
												)
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				)
			} else if (text) {
				return (
					<div
						data-testid={`tooltip-container-text-${testId}`}
						className={styles.tooltipContainerCommon}
						ref={ref}
					>
						<div className={styles.singleText} data-testid={`${testId}-tooltipContent`}>
							{text}
						</div>
					</div>
				)
			}
		}
		if ((show || onTooltip) && containerRef.current) {
			return createPortal(getContent(), containerRef.current)
		} else return undefined
	}
)

export default ToolTipContent

ToolTipContent.displayName = 'ToolTipContent'
