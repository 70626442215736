/**
 * @class RadioButton
 */
import { ChangeEvent, FC } from 'react'
import Icon from '../icon/icon'
import styles from './radio-button.module.scss'

interface IRadioButtonProps {
	// ... props interface
	value: string
	group: string
	id: string
	label: string
	disabled?: boolean
	checked?: boolean
	onChange: (event: ChangeEvent<HTMLInputElement>) => void
	testId: string
}

export const RadioButton: FC<IRadioButtonProps> = ({
	value,
	group,
	id,
	label,
	disabled = false,
	checked = false,
	onChange,
	testId
}: IRadioButtonProps) => {
	return (
		<div data-testid={`radio-container-${testId}`} className={styles.radioButtonContainer}>
			<input
				checked={checked}
				className={styles.radioButton}
				data-testid={`${checked ? testId + '-checked' : testId + '-unchecked'}`}
				disabled={disabled}
				id={id}
				name={group}
				onChange={onChange}
				type="radio"
				value={value}
			/>
			<div data-testid={`radio-custom-${testId}`} className={styles.radioButtonCustom}>
				<Icon testId={`radio-img-${testId}`} type={checked ? 'radio-btn-active' : 'radio-btn-inactive'} />
			</div>
			<label data-testid={testId + '-label'} htmlFor={id}>
				{label}
			</label>
		</div>
	)
}

export default RadioButton
