import cx from 'classnames'
import * as d3 from 'd3'
import { FC, MouseEvent, useState } from 'react'
import world from '../assets/data/world.json'
import { GDMTooltip } from './gdm-tooltip'
import styles from './geographical-distribution-map.module.scss'

interface ICountry {
	properties: {
		name: string
	}
	id2: string
}

interface IDistributionData {
	countryCode: string
	total: number
	percent: number
}

export interface IGDMMapProps {
	data?: Array<IDistributionData>
	color: string
	width?: number
	translationFunction(i18nKey: string): string
	testId: string
	zeroColor?: string
	disableTooltip?: boolean
	orientation?: 'horizontal' | 'vertical'
}

export const GDMMap: FC<IGDMMapProps> = ({
	data = [],
	color,
	width = 330,
	translationFunction,
	testId,
	zeroColor,
	disableTooltip = false,
	orientation = 'horizontal'
}: IGDMMapProps) => {
	const projection = d3.geoMercator().fitWidth(width, world)
	const path = d3.geoPath(projection)

	const height: number = width * 0.65

	const maxPercent = Math.max(...data.map((d) => d.percent))

	const [tooltipData, setTooltipData] = useState<IDistributionData>({
		countryCode: '',
		total: 0,
		percent: 0
	})

	const [tooltipVisible, setTooltipVisible] = useState(false)

	const pathMouseOver = (countryCode): void => {
		const countryData: Array<IDistributionData> = data.filter((c) => c.countryCode === countryCode)
		if (countryData.length > 0 && !disableTooltip) {
			setTooltipData(countryData[0])
			setTooltipVisible(true)
		}
	}

	const pathMouseOut = (): void => {
		setTooltipVisible(false)
	}

	const mapMouseMove = (event: MouseEvent): void => {
		if (event.currentTarget.parentElement && !disableTooltip) {
			event.currentTarget.parentElement
				.getElementsByClassName(styles.tooltip)[0]
				.setAttribute('style', 'left:' + (event.clientX + 5) + 'px; top: ' + (event.clientY - 35) + 'px;')
		}
	}

	return (
		<div
			data-testid={`gdm-container-map-${testId}`}
			id="gdm-map"
			className={cx({ [styles.centerMap]: orientation === 'vertical' })}
		>
			<svg
				className={cx(styles.map)}
				onMouseMove={mapMouseMove}
				style={{ height: height + 'px', width: width + 'px' }}
				data-testid={`gdm-svg-${testId}`}
			>
				{world.features.map((country) => {
					const countryData = data.filter((c) => c.countryCode === country.id2)
					const percent = countryData.length > 0 ? countryData[0].percent : 0
					const percentOfMax = percent / maxPercent
					const opacity = percent === 0 ? 1 : percentOfMax > 0.2 ? percentOfMax : 0.2

					const style = {
						fillOpacity: opacity,
						fill: percent === 0 ? zeroColor : color
					}
					if (percent === 0 && zeroColor === undefined) {
						delete style.fill
					}

					return (
						<path
							key={country.id}
							id={'country-' + country.id}
							d={path(country)}
							style={style}
							onMouseOver={() => {
								pathMouseOver(country.id2)
							}}
							onMouseOut={pathMouseOut}
							data-testid={testId + '-' + country.id}
						/>
					)
				})}
			</svg>
			{!disableTooltip && (
				<GDMTooltip
					testId={`gdm-map-tooltip-${testId}`}
					data={tooltipData}
					visible={tooltipVisible}
					translationFunction={translationFunction}
				/>
			)}
		</div>
	)
}

export { world }
