import { ColorGrayDarker } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { DNBButton } from '@dnb-uux-design-system/react'
import { ChangeEvent, MutableRefObject, ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { calculateUsage } from '../../helpers/integration/customObjectUsageCalculator'
import { useApi } from '../../hooks'
import { useModules } from '../../hooks/useEntitlements'
import { CheckBox, Icon, Modal, RadioButton } from '../../local-core-ui'
import { useIntegrationCustomObjectUsage } from '../../queries/useIntegrationCustomObjectUsage'
import { RootState, useAppSelector } from '../../store'
import { EnrichingBlock } from '../../store/projectWizard/types'
import { Element, ElementDetail, LevelDetail, MatchingApproach } from '../../types'
import { FieldUsage } from '../../types/enrichment-layouts/FieldUsage'
import { EntityType } from '../../types/sources/EntityType'
import { getEmailDefaultElements } from '../../utils'
import { AdjustableProgressBar } from '../adjustable-progress-bar/adjustable-progress-bar'
import { DataBlockResults } from '../datablock-results/datablock-results'
import { ExpandedSearchButtonNInput } from '../expanded-search-button-n-input/expanded-search-button-n-input'
import { Snackbar, SnackType } from '../snackbar/snackbar'
import arrayPrefixes from './array-prefixes.json'
import assessmentDelinquencyScoreCommentaryPrefixes from './assessment-delinquency-score-commentary-prefixes.json'
import assessmentDelinquencyScoreOverrideReasonsPrefixes from './assessment-delinquency-score-override-reasons-element-prefixes.json'
import assessmentFailureScoreCommentaryPrefixes from './assessment-failure-score-commentary-element-prefixes.json'
import assessmentFailureScoreOverrideReasonsPrefixes from './assessment-failure-score-override-reasons-element-prefixes.json'
import assessmentPaydexScoreHistoryPrefixes from './assessment-paydex-score-history-prefixes.json'
import assessmentStandardRatingOverrideReasonsPrefixes from './assessment-standard-rating-override-reasons-element-prefixes.json'
import assessmentStandardRatingReasonPrefixes from './assessment-standard-rating-reason-element-prefixes.json'
import classificationsSEC from './child-nodes-schema/schemaType-classification.json'
import { CreateSelectorRef } from './createSelectorRef'
import styles from './data-block-searcher.module.scss'
import { ElementUIFacade, FilteredObject } from './ElementUIFacade'
import emmaPrefixes from './emma-element-prefixes.json'
import familyTreeRolesPlayedPrefixes from './family-tree-roles-played-element-prefixes.json'
import flattenArrayConfiguration from './flattenArray-configuration.json'
import { buildAssessmentDelinquencyScoreCommentaryTree } from './helpers/buildAssessmentDelinquencyScoreCommentaryTree'
import { buildAssessmentDelinquencyScoreOverrideReasonsTree } from './helpers/buildAssessmentDelinquencyScoreOverrideReasonsTree'
import { buildAssessmentFailureScoreCommentaryTree } from './helpers/buildAssessmentFailureScoreCommentaryTree'
import { buildAssessmentFailureScoreOverrideReasonsTree } from './helpers/buildAssessmentFailureScoreOverrideReasonsTree'
import { buildAssessmentPaydexScoreHistoryTree } from './helpers/buildAssessmentPaydexScoreHistoryTree'
import { buildAssessmentStandardRatingOverrideReasonsTree } from './helpers/buildAssessmentStandardRatingOverrideReasonsTree'
import { buildAssessmentStandardRatingReasonTree } from './helpers/buildAssessmentStandardRatingReasonTree'
import { buildEMMATree } from './helpers/buildEMMATree'
import { buildFamilyTreeRolesPlayedTree } from './helpers/buildFamilyTreeRolesPlayedTree'
import { buildIndustryCodesTree } from './helpers/buildIndustryCodesTree'
import { buildNumberOfEmployeesTree } from './helpers/buildNumberOfEmployeesTree'
import { buildOrganizationEmailTree } from './helpers/buildOrganizationEmailTree'
import { buildSplitPayMannerPastDueSummaryTree } from './helpers/buildSplitPayMannerPastDueSummaryTree'
import { buildSubjectCommentsTree } from './helpers/buildSubjectCommentsTree'
import { buildThirdPartyAssessmentsTree } from './helpers/buildThirdPartyAssesmentsTree'
import { buildTradeStyleNamesTree } from './helpers/buildTradeStyleNamesTree'
import { buildTree } from './helpers/buildTree'
import { buildWorstPayMannerPastDueSummaryTree } from './helpers/buildWorstPayMannerPastDueSummaryTree'
import { constructArrayFlattenElementsBuildTree } from './helpers/constructArrayFlattenElementsBuildTree'
import {
	searchDisplayNameInElementTree,
	setInitialRefNestedValues,
	setRefInitialValue
} from './helpers/element-tree-helpers'
import { sideBlocksStringReplace } from './helpers/side-blocks-string-replace'
import industriesCodeList from './industries-codes.json'
import numberOfEmployeesPrefixes from './number-of-employees-element-prefixes.json'
import organizationEmailPrefixes from './organization-email-prefixes.json'
import splitPayMannerPastDueSummaryPrefixes from './split-pay-manner-past-due-summary-element-prefixes.json'
import subjectCommentsPrefixes from './subject-comments-element-prefixes.json'
import thirdPartyAssessmentsPrefixes from './third-party-assessments-element-prefixes.json'
import tradeStyleNamesPrefixes from './trade-style-names-element-prefixes.json'
import worstPayMannerPastDueSummaryPrefixes from './worst-pay-manner-past-due-summary-element-prefixes.json'

interface DataBlockSearcherProps {
	title: string
	dataBlockList: Array<ElementDetail> | ElementDetail | undefined
	selectedElements?: Array<EnrichingBlock> | Array<Element>
	defaultElements?: Array<EnrichingBlock> | Array<Element>
	open: boolean
	onClose: () => void
	onConfirm: (selectedElements: Array<Element>) => void
	testId: string
	expandedSearch?: boolean
	showBlockInformation?: boolean
	showSelectedCount?: boolean
	activeTradeUp?: boolean
	selectedDataTypeEnrichment?: string
	showBannerUpdateElements?: boolean
	onShowModalAffectedElements?(): void
}

export interface ItemsDisplayedPerLvl {
	[level: string]: number
}

export const DataBlockSearcher = ({
	title,
	dataBlockList,
	selectedElements,
	defaultElements,
	open,
	onClose,
	onConfirm,
	testId,
	expandedSearch = false,
	showBlockInformation = true,
	showSelectedCount = true,
	activeTradeUp = false,
	selectedDataTypeEnrichment,
	showBannerUpdateElements = false,
	onShowModalAffectedElements
}: DataBlockSearcherProps): ReactElement => {
	const { t } = useTranslation()

	const pageSize = 100
	const sortElements = (a: ElementUIFacade, b: ElementUIFacade) => {
		if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
			return 1
		} else if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
			return -1
		} else {
			return 0
		}
	}
	const [filteredElementList, setFilteredElementList] = useState<Array<ElementUIFacade>>([])
	const [searchInputValue, setSearchInputValue] = useState('')
	const [flattenedSelectedElements, setFlattenedSelectedElements] = useState<Array<ElementUIFacade>>([])
	const [displayedItems, setDisplayedItems] = useState<number>(0)
	const [dataBlockElements, setDataBlockElements] = useState<Array<ElementUIFacade>>([])
	const [flattenDefaultElements, setFlattenDefaultElements] = useState<Array<ElementUIFacade>>([])
	const [changedElements, setChangedElements] = useState(false)
	const [showCloseModal, setShowCloseModal] = useState(false)
	const [levelButtons, setLevelButtons] = useState<Array<string>>([])
	const [selectedLevels, setSelectedLevels] = useState<Array<string>>([])
	const [countPerLevel, setCountPerLevel] = useState<ItemsDisplayedPerLvl>({})
	const tsnSelectorValue = useRef<number>(0)
	const emmaSelectorValue = useRef<number>(0)
	const assessmentFailureScoreCommentarySelectorValue = useRef<number>(0)
	const assessmentDelinquencyScoreCommentarySelectorValue = useRef<number>(0)
	const assessmentStandardRatingReasonSelectorValue = useRef<number>(0)
	const assessmentFailureScoreOverrideReasonsSelectorValue = useRef<number>(0)
	const assessmentDelinquencyScoreOverrideReasonsSelectorValue = useRef<number>(0)
	const splitPayMannerPastDueSummarySelectorValue = useRef<number>(0)
	const worstPayMannerPastDueSummarySelectorValue = useRef<number>(0)
	const assessmentStandardRatingOverrideReasonsSelectorValue = useRef<number>(0)
	const assessmentPaydexScoreHistorySelectorValue = useRef<number>(0)
	const organizationEmailSelectorValue = useRef<number>(0)
	const competitorsSelectorValue = useRef<number>(0)
	const otherCompetitorsSelectorValue = useRef<number>(0)
	const registeredAddressesSelectorValue = useRef<number>(0)
	const banksSelectorValue = useRef<number>(0)
	const tsrComCodeSelectorValue = useRef<number>(0)
	const companyTelephoneAggregateSelectorValue = useRef<number>(0)
	const operationsSelectorValue = useRef<number>(0)
	const UNSPSCSelectorValue = useRef<number>(0)
	const formerPrimaryNameSelectorValue = useRef<number>(0)
	const formerRegisteredNameSelectorValue = useRef<number>(0)
	const socioeconomicClassificationValue = useRef<number>(0)
	const subjectCommentsLevel1Value = useRef<number>(0)
	const subjectCommentsLevel2Value = useRef<number>(0)
	const selectoreRefArray: Array<MutableRefObject<number>> = []
	Object.keys(arrayPrefixes).map((key) => {
		selectoreRefArray[key] = CreateSelectorRef()
	})

	const [selectedDataType, setSelectedDataType] = useState('all')
	const [showSearchSection, setShowSearchSection] = useState(expandedSearch)
	const isC4S = useModules(['C4S'])
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const isRecommendationMatch =
		projectWizardState.currentProject?.matchingApproach === MatchingApproach.START_SCRATCH_MR

	const [mandatoryIdArr, setMandatoryIdArr] = useState<Array<string>>([])
	const [isErrorBannerVisible, setIsErrorBannerVisible] = useState(false)
	const errorBannerVisibility = (mandatoryIdArray: Array<string>) => {
		setMandatoryIdArr(mandatoryIdArray)
	}

	useEffect(() => {
		if (!open) {
			onChangeFilter('')
			setSelectedLevels([...levelButtons])
		}
		/**
		 * We only want to run this effect when open prop changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open])

	function isDataBlock(
		elements: Array<EnrichingBlock> | Array<Element> | undefined
	): elements is Array<EnrichingBlock> {
		if (elements && elements.length > 0) {
			return (elements as Array<EnrichingBlock>)[0].elements !== undefined
		} else {
			return false
		}
	}

	function isAnArray(element: Array<unknown> | unknown | undefined): element is Array<unknown> {
		if (element) {
			return (element as Array<unknown>).length !== undefined
		} else {
			return false
		}
	}
	const apiClient = useApi()
	const enableEmailVerification = projectWizardState.currentProject?.source?.enable_email_verification
	const [EVIDS, setEVIDS] = useState([])
	useEffect(() => {
		if (enableEmailVerification === false) {
			const EVBlocksParsed = getEmailDefaultElements(apiClient)
			const EVIDS = EVBlocksParsed?.elements?.map(function (element: ElementUIFacade) {
				return element.elementId
			})
			setEVIDS(EVIDS)
		}
	}, [enableEmailVerification === false])
	useEffect(() => {
		if (defaultElements && defaultElements.length > 0) {
			if (isDataBlock(defaultElements)) {
				// This is an Array<EnrichingBlock>
				const flattenedDataBlockList: Array<ElementUIFacade> = defaultElements.reduce((elementsList, block) => {
					const blockElements: Array<ElementUIFacade> = block.elements.map((element) => {
						return {
							blockId: block.blockId,
							elementId: element.elementId,
							displayName: element.displayName,
							description: element.description,
							dataType: element.dataType,
							example: element.example,
							level: '1',
							deprecated: element.deprecated,
							replacements: element.replacements
						}
					})
					return [...elementsList, ...blockElements]
				}, [] as Array<ElementUIFacade>)
				setFlattenDefaultElements(flattenedDataBlockList)
			} else {
				// This is an Array<Element>
				setFlattenDefaultElements(defaultElements)
			}
		}
	}, [defaultElements])

	const dataBlocks = dataBlockList ? (isAnArray(dataBlockList) ? [...dataBlockList] : [dataBlockList]) : undefined
	const [fieldUsage, setFieldUsage] = useState<FieldUsage>({} as FieldUsage)

	useEffect(() => {
		const flattenedDataBlockList: Array<ElementUIFacade> = []
		const flattenFilteredObject: FilteredObject = {}
		const flattenedIndustryCodesDataBlockList: Array<ElementUIFacade> = []
		const listFlattenElementsNumOfEmployees: Array<ElementUIFacade> = []
		const listFlattenElementsFamTreeRolesPlayed: Array<ElementUIFacade> = []
		const listFlattenElementsTradeStyleNames: Array<ElementUIFacade> = []
		const listFlattenElementsEmma: Array<ElementUIFacade> = []
		const listFlattenElementsAssessmentFailureScoreCommentary: Array<ElementUIFacade> = []
		const listFlattenElementsAssessmentDelinquencyScoreCommentary: Array<ElementUIFacade> = []
		const listFlattenElementsAssessmentStandardRatingReason: Array<ElementUIFacade> = []
		const listFlattenElementsAssessmentFailureScoreOverrideReasons: Array<ElementUIFacade> = []
		const listFlattenElementsAssessmentDelinquencyScoreOverrideReasons: Array<ElementUIFacade> = []
		const listFlattenElementsSplitPayMannerPastDueSummary: Array<ElementUIFacade> = []
		const listFlattenElementsWorstPayMannerPastDueSummary: Array<ElementUIFacade> = []
		const listFlattenElementsAssessmentStandardRatingOverrideReasons: Array<ElementUIFacade> = []
		const listFlattenElementsAssessmentPaydexScoreHistory: Array<ElementUIFacade> = []
		const listFlattenElementsOrganizationEmail: Array<ElementUIFacade> = []
		const listFlattenElementscompetitors: Array<ElementUIFacade> = []
		const listFlattenElementsOtherCompetitors: Array<ElementUIFacade> = []
		const listFlattenElementsCompanyTelephoneAggregate: Array<ElementUIFacade> = []
		const listFlattenElementsOperations: Array<ElementUIFacade> = []
		const listFlattenElementsUNSPSC: Array<ElementUIFacade> = []
		const listFlattenElementsFormerPrimaryName: Array<ElementUIFacade> = []
		const listFlattenElementsFormerRegisteredName: Array<ElementUIFacade> = []
		const listFlattenElementsThirdPartyAssessments: Array<ElementUIFacade> = []
		const levelsList: ItemsDisplayedPerLvl = {}
		const listFlattenElementsRegdAddresses: Array<ElementUIFacade> = []
		const listFlattenElementsBanks: Array<ElementUIFacade> = []
		const listFlattenElementsTSRComCode: Array<ElementUIFacade> = []
		const listFlattenElementsSECDetails: Array<ElementUIFacade> = []
		const listFlattenElementsSubjectComments: Array<ElementUIFacade> = []

		if (dataBlocks) {
			dataBlocks.forEach((block: ElementDetail) => {
				block.levels.forEach((level) => {
					const levelNumber = level.level.displayName.replace('Level ', '').replace('level ', '')
					setLevelLength(levelsList, level)
					level.elements.forEach((element: ElementUIFacade) => {
						const allDataBlock: ElementUIFacade = {
							blockId: block.blockId,
							elementId: element.elementId,
							displayName: element.displayName,
							description: element.description,
							dataType: element.dataType,
							example: element.example,
							level: levelNumber,
							deprecated: element.deprecated,
							replacements: element.replacements
						}
						let isSingleElement = true
						Object.keys(flattenArrayConfiguration).forEach((key) => {
							const elementPrefixArray = arrayPrefixes[key as keyof typeof arrayPrefixes]
							const filteredEles = elementPrefixArray.filter((prefix) =>
								allDataBlock.elementId.includes(prefix)
							)

							if (filteredEles.length > 0) {
								isSingleElement = false
								if (flattenFilteredObject[`${key}`]) {
									flattenFilteredObject[`${key}`].push(allDataBlock)
								} else {
									flattenFilteredObject[`${key}`] = []
									flattenFilteredObject[`${key}`].push(allDataBlock)
								}
							}
						})

						const filteredIndustries = industriesCodeList.filter((industryCode) =>
							allDataBlock.elementId.includes(industryCode)
						)
						const filteredNumberOfEmployees = numberOfEmployeesPrefixes.filter((prefix) =>
							allDataBlock.elementId.startsWith(prefix)
						)
						const filteredFamilyTreeRolesPlayed = familyTreeRolesPlayedPrefixes.filter((prefix) =>
							allDataBlock.elementId.startsWith(prefix)
						)
						const filteredTradeStyleNames = tradeStyleNamesPrefixes.filter((prefix) =>
							allDataBlock.elementId.startsWith(prefix)
						)
						const filteredEmma = emmaPrefixes.filter((prefix) => allDataBlock.elementId.startsWith(prefix))
						const filteredAssessmentFailureScoreCommentary =
							assessmentFailureScoreCommentaryPrefixes.filter((prefix) =>
								allDataBlock.elementId.startsWith(prefix)
							)
						const filteredAssessmentDelinquencyScoreCommentary =
							assessmentDelinquencyScoreCommentaryPrefixes.filter((prefix) =>
								allDataBlock.elementId.startsWith(prefix)
							)
						const filteredSplitPayMannerPastDueSummary = splitPayMannerPastDueSummaryPrefixes.filter(
							(prefix) => allDataBlock.elementId.startsWith(prefix)
						)
						const filteredWorstPayMannerPastDueSummary = worstPayMannerPastDueSummaryPrefixes.filter(
							(prefix) => allDataBlock.elementId.startsWith(prefix)
						)
						const filteredAssessmentStandardRatingReason = assessmentStandardRatingReasonPrefixes.filter(
							(prefix) => allDataBlock.elementId.startsWith(prefix)
						)
						const filteredAssessmentFailureScoreOverrideReasons =
							assessmentFailureScoreOverrideReasonsPrefixes.filter((prefix) =>
								allDataBlock.elementId.startsWith(prefix)
							)
						const filteredAssessmentDelinquencyScoreOverrideReasons =
							assessmentDelinquencyScoreOverrideReasonsPrefixes.filter((prefix) =>
								allDataBlock.elementId.startsWith(prefix)
							)
						const filteredAssessmentStandardRatingOverrideReasons =
							assessmentStandardRatingOverrideReasonsPrefixes.filter((prefix) =>
								allDataBlock.elementId.startsWith(prefix)
							)
						const filteredAssessmentPaydexScoreHistory = assessmentPaydexScoreHistoryPrefixes.filter(
							(prefix) => allDataBlock.elementId.startsWith(prefix)
						)
						const filteredOrganizationEmail = organizationEmailPrefixes.filter((prefix) =>
							allDataBlock.elementId.startsWith(prefix)
						)
						const filteredCompetitors = arrayPrefixes['competitorsId'].filter((prefix) =>
							allDataBlock.elementId.startsWith(prefix)
						)
						const filteredRegdAddresses = arrayPrefixes['registeredAddressesId'].filter((prefix) =>
							allDataBlock.elementId.startsWith(prefix)
						)
						const filteredBanks = arrayPrefixes['banksId'].filter((prefix) =>
							allDataBlock.elementId.startsWith(prefix)
						)
						const filteredTSRComCode = arrayPrefixes['TSRCommodityCodesId'].filter((prefix) =>
							allDataBlock.elementId.startsWith(prefix)
						)
						const filteredOtherCompetitors = arrayPrefixes['otherCompetitorsId'].filter((prefix) =>
							allDataBlock.elementId.startsWith(prefix)
						)
						const filteredCompanyTelephoneAggregate = arrayPrefixes['companyTelephoneAggregateId'].filter(
							(prefix) => allDataBlock.elementId.startsWith(prefix)
						)
						const filteredOperations = arrayPrefixes['operationsId'].filter((prefix) =>
							allDataBlock.elementId.startsWith(prefix)
						)
						const filteredUNSPSC = arrayPrefixes['unspscId'].filter((prefix) =>
							allDataBlock.elementId.startsWith(prefix)
						)
						const filteredFormerPrimaryName = arrayPrefixes['formerPrimaryNameId'].filter((prefix) =>
							allDataBlock.elementId.startsWith(prefix)
						)
						const filteredFormerRegisteredName = arrayPrefixes['formerRegisteredNameId'].filter((prefix) =>
							allDataBlock.elementId.startsWith(prefix)
						)
						const filteredThirdPartyAssessments = thirdPartyAssessmentsPrefixes.filter((prefix) =>
							allDataBlock.elementId.startsWith(prefix)
						)
						const filteredSECDetails = arrayPrefixes['SECDetailsId'].filter((prefix) => {
							return allDataBlock.elementId.includes('_' + prefix + '_')
						})
						const filteredSubjectComments = subjectCommentsPrefixes.subjectCommentsId.filter((prefix) =>
							allDataBlock.elementId.includes(prefix)
						)
						if (filteredIndustries.length > 0) {
							flattenedIndustryCodesDataBlockList.push(allDataBlock)
						} else if (filteredNumberOfEmployees.length > 0) {
							listFlattenElementsNumOfEmployees.push(allDataBlock)
						} else if (filteredFamilyTreeRolesPlayed.length > 0) {
							listFlattenElementsFamTreeRolesPlayed.push(allDataBlock)
						} else if (filteredTradeStyleNames.length > 0) {
							listFlattenElementsTradeStyleNames.push(allDataBlock)
						} else if (filteredEmma.length > 0) {
							listFlattenElementsEmma.push(allDataBlock)
						} else if (filteredAssessmentFailureScoreCommentary.length > 0) {
							listFlattenElementsAssessmentFailureScoreCommentary.push(allDataBlock)
						} else if (filteredAssessmentDelinquencyScoreCommentary.length > 0) {
							listFlattenElementsAssessmentDelinquencyScoreCommentary.push(allDataBlock)
						} else if (filteredAssessmentStandardRatingReason.length > 0) {
							listFlattenElementsAssessmentStandardRatingReason.push(allDataBlock)
						} else if (filteredAssessmentFailureScoreOverrideReasons.length > 0) {
							listFlattenElementsAssessmentFailureScoreOverrideReasons.push(allDataBlock)
						} else if (filteredAssessmentDelinquencyScoreOverrideReasons.length > 0) {
							listFlattenElementsAssessmentDelinquencyScoreOverrideReasons.push(allDataBlock)
						} else if (filteredSplitPayMannerPastDueSummary.length > 0) {
							listFlattenElementsSplitPayMannerPastDueSummary.push(allDataBlock)
						} else if (filteredWorstPayMannerPastDueSummary.length > 0) {
							listFlattenElementsWorstPayMannerPastDueSummary.push(allDataBlock)
						} else if (filteredAssessmentStandardRatingOverrideReasons.length > 0) {
							listFlattenElementsAssessmentStandardRatingOverrideReasons.push(allDataBlock)
						} else if (filteredAssessmentPaydexScoreHistory.length > 0) {
							listFlattenElementsAssessmentPaydexScoreHistory.push(allDataBlock)
						} else if (filteredOrganizationEmail.length > 0) {
							listFlattenElementsOrganizationEmail.push(allDataBlock)
						} else if (filteredCompetitors.length > 0) {
							listFlattenElementscompetitors.push(allDataBlock)
						} else if (filteredRegdAddresses.length > 0) {
							listFlattenElementsRegdAddresses.push(allDataBlock)
						} else if (filteredBanks.length > 0) {
							listFlattenElementsBanks.push(allDataBlock)
						} else if (filteredTSRComCode.length > 0) {
							listFlattenElementsTSRComCode.push(allDataBlock)
						} else if (filteredOtherCompetitors.length > 0) {
							listFlattenElementsOtherCompetitors.push(allDataBlock)
						} else if (filteredCompanyTelephoneAggregate.length > 0) {
							listFlattenElementsCompanyTelephoneAggregate.push(allDataBlock)
						} else if (filteredOperations.length > 0) {
							listFlattenElementsOperations.push(allDataBlock)
						} else if (filteredUNSPSC.length > 0) {
							listFlattenElementsUNSPSC.push(allDataBlock)
						} else if (filteredFormerPrimaryName.length > 0) {
							listFlattenElementsFormerPrimaryName.push(allDataBlock)
						} else if (filteredFormerRegisteredName.length > 0) {
							listFlattenElementsFormerRegisteredName.push(allDataBlock)
						} else if (filteredThirdPartyAssessments.length > 0) {
							listFlattenElementsThirdPartyAssessments.push(allDataBlock)
						} else if (filteredSECDetails.length > 0) {
							listFlattenElementsSECDetails.push(allDataBlock)
						} else if (filteredSubjectComments.length > 0) {
							listFlattenElementsSubjectComments.push(allDataBlock)
						} else {
							isSingleElement && flattenedDataBlockList.push(allDataBlock)
						}
					})
				})
			})
		}

		flattenedDataBlockList.push(
			...buildIndustryCodesTree(flattenedIndustryCodesDataBlockList, sideBlocksStringReplace)
		)
		flattenedDataBlockList.push(
			...buildNumberOfEmployeesTree(listFlattenElementsNumOfEmployees, sideBlocksStringReplace)
		)
		flattenedDataBlockList.push(
			...buildFamilyTreeRolesPlayedTree(listFlattenElementsFamTreeRolesPlayed, sideBlocksStringReplace)
		)
		flattenedDataBlockList.push(
			...buildTradeStyleNamesTree(listFlattenElementsTradeStyleNames, sideBlocksStringReplace, tsnSelectorValue)
		)
		flattenedDataBlockList.push(
			...buildEMMATree(listFlattenElementsEmma, sideBlocksStringReplace, emmaSelectorValue)
		)
		flattenedDataBlockList.push(
			...buildAssessmentFailureScoreCommentaryTree(
				listFlattenElementsAssessmentFailureScoreCommentary,
				sideBlocksStringReplace,
				assessmentFailureScoreCommentarySelectorValue
			)
		)
		flattenedDataBlockList.push(
			...buildAssessmentDelinquencyScoreCommentaryTree(
				listFlattenElementsAssessmentDelinquencyScoreCommentary,
				sideBlocksStringReplace,
				assessmentDelinquencyScoreCommentarySelectorValue
			)
		)
		flattenedDataBlockList.push(
			...buildAssessmentStandardRatingReasonTree(
				listFlattenElementsAssessmentStandardRatingReason,
				sideBlocksStringReplace,
				assessmentStandardRatingReasonSelectorValue
			)
		)
		flattenedDataBlockList.push(
			...buildAssessmentFailureScoreOverrideReasonsTree(
				listFlattenElementsAssessmentFailureScoreOverrideReasons,
				sideBlocksStringReplace,
				assessmentFailureScoreOverrideReasonsSelectorValue
			)
		)

		flattenedDataBlockList.push(
			...buildAssessmentDelinquencyScoreOverrideReasonsTree(
				listFlattenElementsAssessmentDelinquencyScoreOverrideReasons,
				sideBlocksStringReplace,
				assessmentDelinquencyScoreOverrideReasonsSelectorValue
			)
		)
		flattenedDataBlockList.push(
			...buildSplitPayMannerPastDueSummaryTree(
				listFlattenElementsSplitPayMannerPastDueSummary,
				sideBlocksStringReplace,
				splitPayMannerPastDueSummarySelectorValue
			)
		)
		flattenedDataBlockList.push(
			...buildWorstPayMannerPastDueSummaryTree(
				listFlattenElementsWorstPayMannerPastDueSummary,
				sideBlocksStringReplace,
				worstPayMannerPastDueSummarySelectorValue
			)
		)
		flattenedDataBlockList.push(
			...buildAssessmentStandardRatingOverrideReasonsTree(
				listFlattenElementsAssessmentStandardRatingOverrideReasons,
				sideBlocksStringReplace,
				assessmentStandardRatingOverrideReasonsSelectorValue
			)
		)
		flattenedDataBlockList.push(
			...buildAssessmentPaydexScoreHistoryTree(
				listFlattenElementsAssessmentPaydexScoreHistory,
				sideBlocksStringReplace,
				assessmentPaydexScoreHistorySelectorValue
			)
		)
		flattenedDataBlockList.push(
			...buildOrganizationEmailTree(
				listFlattenElementsOrganizationEmail,
				sideBlocksStringReplace,
				organizationEmailSelectorValue
			)
		)
		flattenedDataBlockList.push(
			...constructArrayFlattenElementsBuildTree(
				listFlattenElementscompetitors,
				sideBlocksStringReplace,
				competitorsSelectorValue,
				{
					id: 'competitorsId',
					optionsLength: 5,
					level: '3',
					showChildrenBlocks: true,
					containsChildrenSelector: true,
					considerChildrenForCount: true,
					isRequiredByDefault: []
				}
			)
		)
		flattenedDataBlockList.push(
			...constructArrayFlattenElementsBuildTree(
				listFlattenElementsRegdAddresses,
				sideBlocksStringReplace,
				registeredAddressesSelectorValue,
				{
					id: 'registeredAddressesId',
					optionsLength: 5,
					level: '3',
					showChildrenBlocks: true,
					containsChildrenSelector: true,
					considerChildrenForCount: true,
					isRequiredByDefault: []
				}
			)
		)
		flattenedDataBlockList.push(
			...constructArrayFlattenElementsBuildTree(
				listFlattenElementsBanks,
				sideBlocksStringReplace,
				banksSelectorValue,
				{
					id: 'banksId',
					optionsLength: 5,
					level: '2',
					showChildrenBlocks: true,
					containsChildrenSelector: true,
					considerChildrenForCount: true,
					isRequiredByDefault: []
				}
			)
		)
		flattenedDataBlockList.push(
			...constructArrayFlattenElementsBuildTree(
				listFlattenElementsTSRComCode,
				sideBlocksStringReplace,
				tsrComCodeSelectorValue,
				{
					id: 'TSRCommodityCodesId',
					optionsLength: 5,
					level: '3',
					showChildrenBlocks: true,
					containsChildrenSelector: true,
					considerChildrenForCount: true,
					isRequiredByDefault: ['tsrcommodity_name_']
				}
			)
		)
		flattenedDataBlockList.push(
			...constructArrayFlattenElementsBuildTree(
				listFlattenElementsOtherCompetitors,
				sideBlocksStringReplace,
				otherCompetitorsSelectorValue,
				{
					id: 'otherCompetitorsId',
					optionsLength: 5,
					level: '3',
					showChildrenBlocks: true,
					containsChildrenSelector: true,
					considerChildrenForCount: true,
					isRequiredByDefault: ['other_competitor_duns_number_']
				}
			)
		)
		flattenedDataBlockList.push(
			...constructArrayFlattenElementsBuildTree(
				listFlattenElementsCompanyTelephoneAggregate,
				sideBlocksStringReplace,
				companyTelephoneAggregateSelectorValue,
				{
					id: 'companyTelephoneAggregateId',
					optionsLength: 5,
					level: '1',
					showChildrenBlocks: true,
					containsChildrenSelector: true,
					considerChildrenForCount: true,
					isRequiredByDefault: ['telephone_telephonenumber_']
				}
			)
		)
		flattenedDataBlockList.push(
			...constructArrayFlattenElementsBuildTree(
				listFlattenElementsOperations,
				sideBlocksStringReplace,
				operationsSelectorValue,
				{
					id: 'operationsId',
					optionsLength: 5,
					level: '3',
					showChildrenBlocks: true,
					containsChildrenSelector: true,
					considerChildrenForCount: true,
					isRequiredByDefault: ['operations_description_']
				}
			)
		)
		flattenedDataBlockList.push(
			...constructArrayFlattenElementsBuildTree(
				listFlattenElementsUNSPSC,
				sideBlocksStringReplace,
				UNSPSCSelectorValue,
				{
					id: 'unspscId',
					optionsLength: 5,
					level: '1',
					showChildrenBlocks: true,
					containsChildrenSelector: true,
					considerChildrenForCount: true,
					isRequiredByDefault: []
				}
			)
		)
		flattenedDataBlockList.push(
			...constructArrayFlattenElementsBuildTree(
				listFlattenElementsFormerPrimaryName,
				sideBlocksStringReplace,
				formerPrimaryNameSelectorValue,
				{
					id: 'formerPrimaryNameId',
					optionsLength: 5,
					level: '3',
					showChildrenBlocks: true,
					containsChildrenSelector: true,
					considerChildrenForCount: true,
					isRequiredByDefault: ['former_primary_name_value_']
				}
			)
		)
		flattenedDataBlockList.push(
			...constructArrayFlattenElementsBuildTree(
				listFlattenElementsFormerRegisteredName,
				sideBlocksStringReplace,
				formerRegisteredNameSelectorValue,
				{
					id: 'formerRegisteredNameId',
					optionsLength: 5,
					level: '3',
					showChildrenBlocks: true,
					containsChildrenSelector: true,
					considerChildrenForCount: true,
					isRequiredByDefault: ['former_registered_name_value_']
				}
			)
		)
		flattenedDataBlockList.push(
			...buildTree(listFlattenElementsSECDetails, sideBlocksStringReplace, socioeconomicClassificationValue, {
				id: 'SECDetailsId',
				optionsLength: 5,
				level: '3',
				showChildrenBlocks: true,
				containsChildrenSelector: true,
				considerChildrenForCount: true,
				isRequiredByDefault: ['classification_desc', 'dataprovidername'],
				isClassificationsPattern: true
			})
		)
		flattenedDataBlockList.push(
			...buildSubjectCommentsTree(
				listFlattenElementsSubjectComments,
				sideBlocksStringReplace,
				subjectCommentsLevel1Value,
				subjectCommentsLevel2Value,
				{
					id: 'subjectCommentsId',
					level: '2',
					optionsLength: 3,
					showChildrenBlocks: true,
					containsChildrenSelector: true,
					considerChildrenForCount: true,
					isRequiredByDefault: ['subjectcomments_commentdate_']
				}
			)
		)
		flattenedDataBlockList.push(
			...buildThirdPartyAssessmentsTree(
				listFlattenElementsThirdPartyAssessments,
				sideBlocksStringReplace,
				initialFlattenSelectedElements
			)
		)

		Object.keys(flattenFilteredObject).forEach((key) => {
			const flattenElements = flattenFilteredObject[key]
			const arrayConfig = flattenArrayConfiguration[key as keyof typeof flattenArrayConfiguration]
			if (arrayConfig) {
				flattenedDataBlockList.push(
					...constructArrayFlattenElementsBuildTree(
						flattenElements,
						sideBlocksStringReplace,
						selectoreRefArray[key],
						arrayConfig
					)
				)
			}
		})
		const blockLevels = Object.keys(levelsList)
		setLevelButtons([...blockLevels])
		setSelectedLevels([...blockLevels])
		setCountPerLevel(levelsList)
		setDataBlockElements(flattenedDataBlockList)
		setChangedElements(false)
		setFilteredElementList(dataBlockElements.sort(sortElements))
		setDisplayedItems(dataBlockElements.length > pageSize ? pageSize : dataBlockElements.length)
		/**
		 * We only want to run this effect when the length in dataBlocks or the open prop changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataBlocks?.length, open])

	const initialFlattenSelectedElements = useMemo((): Array<ElementUIFacade> => {
		if (selectedElements && selectedElements.length > 0) {
			if (isDataBlock(selectedElements)) {
				return selectedElements.reduce((elementsList: ElementUIFacade[], block: EnrichingBlock) => {
					const blockElements = block.elements.map((element: ElementUIFacade) => {
						const elementLevel = dataBlocks?.find((block) => {
							block.levels.find((level) => {
								level.elements.find((el) => el.elementId === element.elementId)
							})
						})
						return {
							blockId: block.blockId,
							elementId: element.elementId,
							displayName: element.displayName,
							description: element.description,
							dataType: element.dataType,
							example: element.example,
							level: elementLevel?.levels[0].level.displayName || element.level || '1',
							deprecated: element.deprecated,
							replacements: element.replacements
						}
					})
					return [...elementsList, ...blockElements]
				}, [] as Array<ElementUIFacade>)
			} else {
				return selectedElements ? (selectedElements as ElementUIFacade[]) : []
			}
		} else {
			return []
		}
		/**
		 * We only want to run this effect when the length in selectedElements or the open prop changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedElements?.length, open])

	useEffect(() => {
		setFlattenedSelectedElements(initialFlattenSelectedElements)
		setRefInitialValue(tsnSelectorValue, tradeStyleNamesPrefixes, initialFlattenSelectedElements)
		setRefInitialValue(emmaSelectorValue, emmaPrefixes, initialFlattenSelectedElements)
		setRefInitialValue(
			assessmentFailureScoreCommentarySelectorValue,
			assessmentFailureScoreCommentaryPrefixes,
			initialFlattenSelectedElements
		)
		setRefInitialValue(
			assessmentDelinquencyScoreCommentarySelectorValue,
			assessmentDelinquencyScoreCommentaryPrefixes,
			initialFlattenSelectedElements
		)
		setRefInitialValue(
			splitPayMannerPastDueSummarySelectorValue,
			splitPayMannerPastDueSummaryPrefixes,
			initialFlattenSelectedElements
		)
		setRefInitialValue(
			worstPayMannerPastDueSummarySelectorValue,
			worstPayMannerPastDueSummaryPrefixes,
			initialFlattenSelectedElements
		)
		setRefInitialValue(
			assessmentStandardRatingReasonSelectorValue,
			assessmentStandardRatingReasonPrefixes,
			initialFlattenSelectedElements
		)
		setRefInitialValue(
			assessmentFailureScoreOverrideReasonsSelectorValue,
			assessmentFailureScoreOverrideReasonsPrefixes,
			initialFlattenSelectedElements
		)
		setRefInitialValue(
			assessmentDelinquencyScoreOverrideReasonsSelectorValue,
			assessmentDelinquencyScoreOverrideReasonsPrefixes,
			initialFlattenSelectedElements
		)
		setRefInitialValue(
			assessmentStandardRatingOverrideReasonsSelectorValue,
			assessmentStandardRatingOverrideReasonsPrefixes,
			initialFlattenSelectedElements
		)
		setRefInitialValue(
			assessmentPaydexScoreHistorySelectorValue,
			assessmentPaydexScoreHistoryPrefixes,
			initialFlattenSelectedElements
		)
		setRefInitialValue(organizationEmailSelectorValue, organizationEmailPrefixes, initialFlattenSelectedElements)
		setRefInitialValue(competitorsSelectorValue, arrayPrefixes['competitorsId'], initialFlattenSelectedElements)
		setRefInitialValue(
			otherCompetitorsSelectorValue,
			arrayPrefixes['otherCompetitorsId'],
			initialFlattenSelectedElements
		)
		setRefInitialValue(banksSelectorValue, arrayPrefixes['banksId'], initialFlattenSelectedElements)
		setRefInitialValue(
			tsrComCodeSelectorValue,
			arrayPrefixes['TSRCommodityCodesId'],
			initialFlattenSelectedElements
		)
		setRefInitialValue(
			registeredAddressesSelectorValue,
			arrayPrefixes['registeredAddressesId'],
			initialFlattenSelectedElements
		)
		setRefInitialValue(
			companyTelephoneAggregateSelectorValue,
			arrayPrefixes['companyTelephoneAggregateId'],
			initialFlattenSelectedElements
		)
		setRefInitialValue(operationsSelectorValue, arrayPrefixes['operationsId'], initialFlattenSelectedElements)
		setRefInitialValue(UNSPSCSelectorValue, arrayPrefixes['unspscId'], initialFlattenSelectedElements)
		setRefInitialValue(
			formerPrimaryNameSelectorValue,
			arrayPrefixes['formerPrimaryNameId'],
			initialFlattenSelectedElements
		)
		setRefInitialValue(
			formerRegisteredNameSelectorValue,
			arrayPrefixes['formerRegisteredNameId'],
			initialFlattenSelectedElements
		)
		setRefInitialValue(
			socioeconomicClassificationValue,
			arrayPrefixes['SECDetailsId'],
			initialFlattenSelectedElements,
			classificationsSEC.SECDetailsId.classifications
		)
		setInitialRefNestedValues({
			selectorValue1: subjectCommentsLevel1Value,
			selectorValue2: subjectCommentsLevel2Value,
			selectedElements: initialFlattenSelectedElements,
			level1Prefixes: subjectCommentsPrefixes.level1Prefixes,
			level2Prefixes: subjectCommentsPrefixes.level2Prefixes
		})
		Object.keys(arrayPrefixes).map((key) => {
			setRefInitialValue(
				selectoreRefArray[key],
				arrayPrefixes[key as keyof typeof arrayPrefixes],
				initialFlattenSelectedElements
			)
		})
		/**
		 * We only want to run this effect when the memo initialFlattenSelectedElements changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialFlattenSelectedElements])

	const setLevelLength = (levelsList: ItemsDisplayedPerLvl, level: LevelDetail): void => {
		const levelNumber = level.level.displayName.replace('Level ', '').replace('level ', '')
		if (levelsList[levelNumber]) {
			levelsList[levelNumber] = levelsList[levelNumber] + level.elements.length
		} else {
			levelsList[levelNumber] = level.elements.length
		}
	}

	const onChangeFilter = (filterValue: string) => {
		setSearchInputValue(filterValue)
		let elementArrayFiltered
		if (filterValue.length > 2) {
			elementArrayFiltered = dataBlockElements.filter((element) =>
				searchDisplayNameInElementTree(element, filterValue, selectedLevels)
			)
			setFilteredElementList(elementArrayFiltered.sort(sortElements))
			setDisplayedItems(elementArrayFiltered.length > pageSize ? pageSize : elementArrayFiltered.length)
		} else {
			elementArrayFiltered = dataBlockElements.filter(
				(element) => !!element.level && selectedLevels.includes(element.level)
			)
			setFilteredElementList(elementArrayFiltered.sort(sortElements))
			setDisplayedItems(elementArrayFiltered.length > pageSize ? pageSize : elementArrayFiltered.length)
		}
	}

	const toggleElement = (newSelectedElements: Array<ElementUIFacade>, changedElements: boolean) => {
		setFlattenedSelectedElements(newSelectedElements)
		setChangedElements(changedElements)
	}

	const fetchMoreData = (levelElements: ElementUIFacade[], itemsDisplayed: number) => {
		if (levelElements.length > itemsDisplayed + pageSize) {
			setDisplayedItems(itemsDisplayed + pageSize)
		} else {
			setDisplayedItems(levelElements.length)
		}
	}

	const sortLevels = (a: string, b: string) => {
		if (a.match(/\d/) || b.match(/\d/)) {
			if (a < b) {
				return 1
			} else if (a > b) {
				return -1
			} else {
				return 0
			}
		} else {
			if (a > b) {
				return 1
			} else if (a < b) {
				return -1
			} else {
				return 0
			}
		}
	}

	const onCloseModal = () => {
		changedElements ? setShowCloseModal(true) : onClose()
	}

	const changeSelectedLevels = (event: ChangeEvent<HTMLInputElement>, lvl: string) => {
		if (event.target.checked) {
			setSelectedLevels([...selectedLevels, lvl])
		} else {
			const indexToRemove = selectedLevels.findIndex((level) => level === lvl)
			if (indexToRemove > -1) {
				const newSelectedLevels = [...selectedLevels]
				newSelectedLevels.splice(indexToRemove, 1)
				setSelectedLevels(newSelectedLevels)
			}
		}
	}
	const customObjectUsageQuery = useIntegrationCustomObjectUsage(
		projectWizardState.currentProject.source.id || '',
		!!projectWizardState.currentProject.thirdPartyIntegration //This should be a boolean, the query will only run if this is true
	)

	useEffect(() => {
		//Should only be used on CRM Sources
		if (!!projectWizardState.currentProject.thirdPartyIntegration && customObjectUsageQuery.isSuccess) {
			const usage = calculateUsage(
				customObjectUsageQuery.data,
				projectWizardState.currentProject.enrichingLayout,
				flattenedSelectedElements,
				dataBlockList ? (isAnArray(dataBlockList) ? dataBlockList[0] : dataBlockList) : undefined
			)
			setFieldUsage(usage)
		} else if (customObjectUsageQuery.isError) {
			console.error('Failed fetching Custom Object Field usage', customObjectUsageQuery.error)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		projectWizardState.currentProject.source.id,
		customObjectUsageQuery.isFetching,
		projectWizardState.currentProject.enrichingLayout.length,
		flattenedSelectedElements?.length
	])

	useEffect(() => {
		if (selectedDataTypeEnrichment === 'selected' && searchInputValue === '') {
			setSelectedDataType(selectedDataTypeEnrichment)
			filterByDataType(selectedDataTypeEnrichment)
		} else {
			setSelectedDataType('all')
			onChangeFilter(searchInputValue)
		}
		/**
		 * onChangeFilter is not added as a dependency because it would cause the other dependencies to change with each render.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedLevels])

	const getLabelFilter = (lvl: string) => {
		if (lvl === '0' || lvl === '1' || lvl === '2' || lvl === '3' || lvl === '4' || lvl === '5') {
			return t('all.data.block.searcher.levels.label.level', {
				level: lvl,
				count: countPerLevel[lvl]
			})
		} else {
			return t('all.data.block.searcher.levels.label.level.sideBlock.' + lvl, {
				count: countPerLevel[lvl]
			})
		}
	}

	const searchSelectedInElementTree = (
		currentElement: ElementUIFacade,
		selectedElement: ElementUIFacade
	): boolean => {
		let elementFound = selectedElement.elementId === currentElement.elementId
		if (!elementFound && currentElement.childs) {
			elementFound = currentElement.childs.some((child) => searchSelectedInElementTree(child, selectedElement))
		}
		return elementFound
	}

	const filterByDataType = (dataType: string) => {
		if (dataBlockElements) {
			let filteredSelectedElements: Array<ElementUIFacade> = []
			if (dataType === 'selected') {
				flattenedSelectedElements?.forEach((selectedEl) => {
					const elementWithLvl = dataBlockElements.find((element) =>
						searchSelectedInElementTree(element, selectedEl)
					)
					if (elementWithLvl && !!elementWithLvl.level && selectedLevels.includes(elementWithLvl.level)) {
						if (
							filteredSelectedElements.filter(
								(selectedElement) => selectedElement.elementId === elementWithLvl.elementId
							).length === 0
						)
							filteredSelectedElements.push(elementWithLvl)
					}
				})
				setFilteredElementList(filteredSelectedElements.sort(sortElements))
				setDisplayedItems(
					filteredSelectedElements.length > pageSize ? pageSize : filteredSelectedElements.length
				)
			} else {
				filteredSelectedElements = dataBlockElements.filter(
					(element) => !!element.level && selectedLevels.includes(element.level)
				)
				setFilteredElementList(filteredSelectedElements.sort(sortElements))
				setDisplayedItems(dataBlockElements.length > pageSize ? pageSize : dataBlockElements.length)
			}
		}
	}

	const onChangeDataType = (event: ChangeEvent<HTMLInputElement>) => {
		filterByDataType(event.target.value)
		setSelectedDataType(event.target.value)
	}

	const onShowSearchSection = () => {
		setShowSearchSection(true)
		setSelectedDataType('all')
		onChangeFilter('')
	}

	return (
		<div className={styles.containerDataSearcherModal} data-testid="data-block-searcher-modal-container">
			<div className={styles.modalWrapper} data-testid="data-block-searcher-modal-container">
				<Modal open={open} isContainer={true} onClose={onCloseModal} testId="DataBlock-Searcher-Modal">
					<div className={styles.header}>
						<h1 data-testid="title-modal-individual" className={styles.title}>
							{sideBlocksStringReplace(title)}
						</h1>
						{showSelectedCount ? (
							<div className={styles.selected}>
								{t('data.block.searcher.selected', { selected: flattenedSelectedElements.length })}
							</div>
						) : undefined}
					</div>
					<div className={styles.body}>
						{!showSearchSection && (
							<>
								<div className={styles.radioButtonGroupContainer}>
									<div className={styles.radioButtonShowContainer}>
										<RadioButton
											group={'show'}
											value={'all'}
											id={'all'}
											checked={selectedDataType === 'all'}
											label={t('data.block.searcher.show.all')}
											onChange={onChangeDataType}
											testId={testId + '-show-all-radiobutton'}
										/>
									</div>
									<div>
										<RadioButton
											group={'show'}
											value={'selected'}
											id={'selected'}
											checked={selectedDataType === 'selected'}
											label={t('data.block.searcher.show.selected')}
											onChange={onChangeDataType}
											testId={testId + '-show-selected-radiobutton'}
										/>
									</div>
									<div>
										<label data-testid="selected-individual-elements" className={styles.elements}>
											({flattenedSelectedElements.length})
										</label>
									</div>
								</div>
								<div className={styles.searchButtonSection}>
									<div className={styles.searchButtonContainer} onClick={onShowSearchSection}>
										<label data-testid="searcher-individual" className={styles.searchLabel}>
											{t('data.block.searcher.search.label')}
										</label>
										<div className={styles.searchIcon}>
											<Icon
												testId="search-data-block-searcher"
												type={'search'}
												color={ColorGrayDarker}
											/>
										</div>
									</div>
								</div>
							</>
						)}
						{showSearchSection && (
							<div className={styles.searchSection}>
								<ExpandedSearchButtonNInput
									value={searchInputValue}
									onChangeFilter={onChangeFilter}
									hint={t('data.block.searcher.searcher')}
									testId={testId + '-search'}
									onBlurFunction={() => {
										if (
											mandatoryIdArr &&
											mandatoryIdArr.length > 0 &&
											mandatoryIdArr.findIndex((ele) => ele === searchInputValue) >= 0
										) {
										} else {
											setShowSearchSection(false)
											if (searchInputValue.length === 0 && !expandedSearch) {
											}
										}
									}}
								/>
							</div>
						)}

						<div className={styles.checkboxesContainer}>
							{levelButtons.sort(sortLevels).map((lvl) => (
								<CheckBox
									id={`cb-${lvl}`}
									key={`cb-${lvl}`}
									label={getLabelFilter(lvl)}
									checked={selectedLevels.includes(lvl)}
									testId={`-cb-${lvl}`}
									type="tab"
									onChange={(event) => {
										changeSelectedLevels(event, lvl)
									}}
								/>
							))}
						</div>

						{showBannerUpdateElements && (
							<div className={styles.containerBannerUpdateElms}>
								<Snackbar
									title={t('title.banner.update.elements')}
									message={t('description.banner.update.elements') as string}
									type={SnackType.information}
									isBanner
									linkText={t('link.banner.update.elements') as string}
									onClickLink={() => {
										if (onShowModalAffectedElements) {
											onShowModalAffectedElements()
										}
									}}
								/>
							</div>
						)}

						<DataBlockResults
							flattenSelectedElements={flattenedSelectedElements}
							flattenDefaultElements={flattenDefaultElements}
							toggleElement={toggleElement}
							displayedItems={displayedItems}
							filteredElementList={filteredElementList}
							fetchMoreData={fetchMoreData}
							testId={testId}
							showBlockInformation={showBlockInformation}
							isErrorBannerVisible={isErrorBannerVisible}
							errorBannerVisibility={errorBannerVisibility}
							onChangeFilter={onChangeFilter}
							activeTradeUp={activeTradeUp}
							setShowSearchSection={setShowSearchSection}
							setSearchInputValue={setSearchInputValue}
							enableEmailVerification={enableEmailVerification}
							EVIDS={EVIDS}
							tradeToggle={projectWizardState?.currentProject?.tradeUp}
							isRecommendationMatch={isRecommendationMatch}
						/>
					</div>
					<div className={styles.footer}>
						<DNBButton
							size="default"
							variant="primary"
							onClick={() => {
								const cleanSelectedElements: Array<ElementUIFacade> = []
								flattenedSelectedElements.map((elementSelected: ElementUIFacade) => {
									const index = industriesCodeList.findIndex(
										(industryCode) => elementSelected.elementId === industryCode
									)
									if (index === -1) {
										cleanSelectedElements.push(elementSelected)
									}
								})
								if (mandatoryIdArr && mandatoryIdArr.length > 0) {
									setShowSearchSection(true)
									setSearchInputValue(mandatoryIdArr[0])
									onChangeFilter(mandatoryIdArr[0])
									setIsErrorBannerVisible(true)
								} else {
									setIsErrorBannerVisible(false)
									onConfirm(cleanSelectedElements)
								}
							}}
							data-testid={testId + '-dbs-done'}
						>
							{t('data.block.searcher.done')}
						</DNBButton>
						{isC4S && projectWizardState.currentProject.thirdPartyIntegration ? (
							<div className={styles.progressBar}>
								<AdjustableProgressBar
									totalRequirements={fieldUsage.fieldLimit}
									satisfiedRequirements={fieldUsage.existingFields}
									newRequirements={fieldUsage.newFields}
									remaining={fieldUsage.remainingFields}
									progressBarWidth={421}
									tooltipMessage={
										projectWizardState.currentProject.source.entityType === EntityType.CONTACTS
											? t('component.progress.bar.tooltip.contact')
											: t('component.progress.bar.tooltip.account')
									}
								/>
							</div>
						) : undefined}
						{fieldUsage.newFields + fieldUsage.existingFields > fieldUsage.fieldLimit ? (
							<div className={styles.errorMessage}>{t('enriching.step.progress.bar.errorMessage')}</div>
						) : (
							<div className={styles.footerExceedFieldsOk} />
						)}
					</div>
				</Modal>
			</div>
			<div className={styles.closeDataModal} data-testid="close-data-block-searcher-modal-container">
				<Modal
					open={showCloseModal}
					isContainer={true}
					showButtonClose={false}
					testId="CloseDataBlockSearcher-Modal"
				>
					<p className={styles.closeModalText}>{t('all.data.block.close.modal.text')}</p>
					<div className={styles.buttonsContainer}>
						<DNBButton
							size="small"
							variant="secondary"
							onClick={() => {
								setFlattenedSelectedElements(initialFlattenSelectedElements)
								setShowCloseModal(false)
								setChangedElements(false)
								onClose()
							}}
							data-testid={testId + 'close-modal-warn-close'}
						>
							{t('all.data.block.close.modal.close')}
						</DNBButton>

						<DNBButton
							size="small"
							variant="secondary"
							onClick={() => setShowCloseModal(false)}
							data-testid={testId + 'close-modal-warn-continue'}
						>
							{t('all.data.block.close.modal.back')}
						</DNBButton>
					</div>
				</Modal>
			</div>
		</div>
	)
}
