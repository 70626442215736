import { ColorBlueBrand } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement } from 'react'
import { Icon } from '../../local-core-ui'
import { copyToClipboard } from '../upload-status-details/upload-status-utilities'
import styles from './paragraph.module.scss'

export interface IParagraphProps {
	title: string
	text: string
	isLink?: boolean
	iconCopy?: boolean
	testId?: string
}

export const Paragraph = ({
	title,
	text,
	isLink = false,
	iconCopy = false,
	testId = ''
}: IParagraphProps): ReactElement => {
	return (
		<div className={styles.paragraph} data-testid={testId}>
			<p className={styles.paragraphTitle}>{title}</p>
			<div className={styles.body}>
				{isLink ? (
					<p>
						<a
							className={`${styles.paragraphLink}`}
							href={text}
							target="_blank"
							rel="noopener noreferrer"
							data-testid="link-apiSpec"
						>
							{text}
						</a>
					</p>
				) : (
					<p className={`${iconCopy ? styles.paragraphLink : styles.paragraphText}`}>{text}</p>
				)}

				{iconCopy && (
					<div onClick={() => copyToClipboard(text)} data-testid={'buttonCopy-' + testId}>
						<Icon testId="copy-paragraph" key={'iconCopy'} type="copy" color={ColorBlueBrand} />
					</div>
				)}
			</div>
		</div>
	)
}
