export const chartColorsMap = new Map([
	['category-1', '#0092cc'],
	['category-2', '#005172'],
	['category-3', '#8E47B5'],
	['category-4', '#BA4589'],
	['category-5', '#006D75'],
	['category-6', '#089E94'],
	['category-7', '#632A6E'],
	['category-8', '#9B3054'],
	['category-9', '#99D3EB'],
	['category-10', '#99B9C7'],
	['category-11', '#D2B5E1'],
	['category-12', '#E3B5D0'],
	['category-13', '#99C5C8'],
	['category-14', '#9CD8D4'],
	['category-15', '#C1AAC5'],
	['category-16', '#D7ACBB'],
	['uncategory-1', '#656769'],
	['uncategory-3', '#CBCDCE'],
	['success', '#007132']
])
