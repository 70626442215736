import { ColorWhite } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../local-core-ui'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import { setSelectedRecords } from '../../store/steward/stewardSlice'
import { Record } from '../../types'
import styles from './multi-select-overview-card.module.scss'

export const MultiSelectOverviewCard = (): ReactElement => {
	const selectSteward = (state: RootState) => state.steward
	const steward = useAppSelector(selectSteward)
	const { selectedRecords } = steward
	const dispatch = useAppDispatch()

	const { t } = useTranslation()
	const pageSize = 24
	const [lastCompany, setLastCompany] = useState<number>(pageSize)

	const handleRemoveRecord = (idx: number) => {
		const newSelectedRecords = [...selectedRecords]
		newSelectedRecords.splice(idx, 1)
		dispatch(setSelectedRecords(newSelectedRecords))
	}

	return (
		<div className={styles.multiSelectOverviewCardContainer}>
			{selectedRecords.slice(0, lastCompany).map((record, idx) => {
				return (
					<Company
						key={`${record.inputFields.companyName}-${idx}`}
						record={record}
						onRemoveRecord={() => handleRemoveRecord(idx)}
					/>
				)
			})}
			{selectedRecords.length > lastCompany && (
				<button
					className={styles.multiSelectOverviewShowMore}
					onClick={() => setLastCompany(lastCompany + pageSize)}
					data-testid="multi-select-overview-card"
				>
					{t('multi.select.overview.card.show.more')}
				</button>
			)}
		</div>
	)
}

interface CompanyProps {
	record: Record
	onRemoveRecord(): void
}

const Company = ({ record, onRemoveRecord }: CompanyProps): ReactElement => {
	const missingField = '-'
	return (
		<div className={styles.multiSelectOverviewCompanyContainer}>
			<div className={styles.multiSelectOverviewCompany}>
				<span className={styles.multiSelectOverviewMatchCode}>
					{record.matchCandidate.matchInsight.ConfidenceCode}
				</span>
				<span className={styles.multiSelectOverviewName} title={record.inputFields.companyName}>
					{record.inputFields.companyName || missingField}
				</span>
				<button className={styles.multiSelectOverviewXBtn} onClick={onRemoveRecord}>
					<Icon testId="X-multi-select-overview-card" type="x" size="small" color={ColorWhite} />
				</button>
			</div>
		</div>
	)
}
