import { counterGroup } from '../../../types/sources/HandlerCounterByGroupMapping'
import { AppThunk, TDispatch, TGetState } from '../../index'
import { updateCurrentProjectAction } from '../actions'

export const validateGroupsMapping =
	(): AppThunk<Promise<unknown>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<unknown> => {
		const mappingInfo = getState().projectWizard.currentProject.mappingInfo
		if (mappingInfo.haveGroupsIncomplete && mappingInfo.areDataGroupsValidated) {
			dispatch(
				updateCurrentProjectAction({
					mappingInfo: {
						...getState().projectWizard.currentProject.mappingInfo,
						haveGroupsIncomplete: false,
						areDataGroupsValidated: true
					}
				})
			)
			return new Promise<void>((resolve) => resolve())
		} else {
			const groupsCounter = Object.values(mappingInfo.counterGroupsMapping || [])
			let existSomePartialGroup = false
			let existSomeGroupComplete = false
			groupsCounter.forEach((counter: counterGroup) => {
				if (counter.fieldsRequiredFull > 0 && counter.totalRequired > counter.fieldsRequiredFull) {
					existSomePartialGroup = true
				}
				if (counter.totalRequired <= counter.fieldsRequiredFull) {
					existSomeGroupComplete = true
				}
			})
			if (existSomePartialGroup && existSomeGroupComplete) {
				dispatch(
					updateCurrentProjectAction({
						mappingInfo: {
							...getState().projectWizard.currentProject.mappingInfo,
							haveGroupsIncomplete: true,
							areDataGroupsValidated: true
						}
					})
				)
				return new Promise<void>((resolve, reject) => reject())
			} else {
				dispatch(
					updateCurrentProjectAction({
						mappingInfo: {
							...getState().projectWizard.currentProject.mappingInfo,
							haveGroupsIncomplete: false,
							areDataGroupsValidated: true
						}
					})
				)
				return new Promise<void>((resolve) => resolve())
			}
		}
	}
