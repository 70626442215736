import { AxiosInstance, AxiosResponse } from 'axios'
import { GetMatchSummaryCodes } from '../../types/match-recommendation/GetMatchRecommendationProfile'

export const getMatchSummaryCodes = async (
	apiClient: AxiosInstance,
	enableMatchRecommendation?: boolean
): Promise<GetMatchSummaryCodes> => {
	if (enableMatchRecommendation) {
		const url = '/pls/matchrules/matchRecommendation/summaryCodes'
		return apiClient.get(url).then((response: AxiosResponse<GetMatchSummaryCodes>) => response.data)
	} else return new Promise<GetMatchSummaryCodes>((resolve) => [])
}
