type getParentDepth = (element: HTMLElement, defaultDepth: string) => string

export const getParentDepth: getParentDepth = (element, defaultDepth) => {
	if (element) {
		const zIndex = document.defaultView.getComputedStyle(element).zIndex
		if (zIndex.length === 0 || zIndex === 'auto') {
			return getParentDepth(element.parentElement, defaultDepth)
		} else {
			return zIndex
		}
	} else {
		return defaultDepth
	}
}
