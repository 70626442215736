import { AxiosInstance, AxiosResponse } from 'axios'
import contactsDefaultElementList from '../project/steps/enriching/select-your-data/contacts-default-elements.json'
import defaultElementList from '../project/steps/enriching/select-your-data/default-elements.json'
import recommendationDefaultElementList from '../project/steps/enriching/select-your-data/recommendation-default-elements.json'
import { EnrichingBlock } from '../store/projectWizard/types'
import { ElementsResponse } from '../types'
import { EntityType } from '../types/sources/EntityType'

export const getEmailVerificationBlockDefaultElements = (apiClient: AxiosInstance) => {
	const elementsUrl = '/pls/data-blocks/elements?blockIds=emailverification'
	const dataBlock: any = {
		blockId: '',
		elements: []
	}
	return apiClient
		.get(elementsUrl)
		.then((elementsResponse: AxiosResponse<ElementsResponse>) => {
			elementsResponse?.data?.forEach((element) => {
				let customLevelElement
				element?.levels?.forEach((level) => {
					const levelNumber = level?.level?.displayName?.replace('Level ', '').replace('level ', '')
					level?.elements?.forEach((levelElement) => {
						customLevelElement = {
							elementId: levelElement.elementId,
							displayName: levelElement.displayName,
							level: levelNumber
						}
						dataBlock.elements.push(customLevelElement)
					})
					if (dataBlock.blockId === '') {
						dataBlock.blockId = element.blockId
					}
				})
			})
			return dataBlock
		})
		.catch((e) => {
			console.error(e)
		})
}

export const setEmailDefaultElements = async (apiClient: AxiosInstance) => {
	if (!localStorage.getItem('EVBlocks')) {
		const defaultElementsEV = getEmailVerificationBlockDefaultElements(apiClient)
		defaultElementsEV?.then((res) => {
			if (res !== null && res !== undefined) localStorage.setItem('EVBlocks', JSON.stringify(res))
		})
	}
}

export const getEmailDefaultElements = (apiClient: AxiosInstance) => {
	if (!localStorage.getItem('EVBlocks')) setEmailDefaultElements(apiClient)
	const EVBlocks = localStorage.getItem('EVBlocks')
	const EVBlocksParsed = EVBlocks && JSON.parse(EVBlocks)
	return EVBlocksParsed
}

export const getDefaultElementList = (
	entityType: string | undefined,
	enableEmailVerification: boolean | undefined,
	apiClient: AxiosInstance,
	isRecommendationMatch: boolean
): Array<EnrichingBlock> => {
	// DCP-196: For Match Recommendation add 4 output elements to CER data block
	if (defaultElementList[1].elements.length > 9) {
		defaultElementList[1].elements.splice(9, 4)
	}
	if (isRecommendationMatch) {
		recommendationDefaultElementList.forEach((ele) => {
			const existingEle = defaultElementList[1]?.elements.filter(
				(existingEle) => existingEle.elementId === ele.elementId
			)
			existingEle.length ? '' : defaultElementList[1]?.elements.push(ele)
		})
	}
	if (entityType === EntityType.CONTACTS) {
		if (!defaultElementList.find((ele) => ele.blockId === 'contacts')) {
			defaultElementList.push(contactsDefaultElementList)
		}
		if (enableEmailVerification && !defaultElementList.find((ele) => ele.blockId === 'emailverification')) {
			const EVBlocksParsed = getEmailDefaultElements(apiClient)
			if (EVBlocksParsed && EVBlocksParsed !== null) {
				defaultElementList.push(EVBlocksParsed)
			}
		}
		if (
			defaultElementList.find((ele) => ele.blockId === 'emailverification') &&
			(enableEmailVerification === false || enableEmailVerification === undefined)
		) {
			const indexNumber = defaultElementList.findIndex((ele) => ele.blockId === 'emailverification')
			defaultElementList.splice(indexNumber, 1)
		}
		return defaultElementList
	} else {
		return defaultElementList.filter((ele) => ele.blockId !== 'contacts' && ele.blockId !== 'emailverification')
	}
}
