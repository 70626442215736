import { ReportTile } from '../../../../components/report-tile/report-tile'
import { MatchRule } from '../../../../types'
import styles from './parallel-pass-matching.module.scss'
// TODO: Switch to color tokens from UUX design-tokens
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BaseReportSection } from '../../types/BaseReportSection'
import { MatchSummaryCodeCount } from './match-summary-code-count/match-summary-code-count'
import { StewardshipCount } from './stewardship-count/stewardship-count'

interface ParallelPassMatchingProps extends BaseReportSection {
	matchRules: MatchRule[]
}

type Profiles = {
	precedence: string
	stewardship: string
}

const DEFAULT_PROFILE = 'Standard'

const ParallelPassMatching = ({ uploadReport, matchRules }: ParallelPassMatchingProps) => {
	const { t } = useTranslation()
	const [profiles, setProfiles] = useState<Profiles>({
		precedence: DEFAULT_PROFILE,
		stewardship: DEFAULT_PROFILE
	})

	const matchSummaryCodeReport = uploadReport?.matchSummaryCodeReport ?? null
	const stewardshipValueReport = uploadReport?.stewardshipValueReport ?? null

	useEffect(() => {
		if (matchRules.length > 0) {
			setProfiles({
				precedence: matchRules[0].precedenceProfile ?? DEFAULT_PROFILE,
				stewardship: matchRules[0].stewardshipProfile ?? DEFAULT_PROFILE
			})
		}
	}, [matchRules])

	return (
		<ReportTile header={t('report.page.parallelPassMatching.header') as string}>
			<div className={styles.parallelPassMatching}>
				<MatchSummaryCodeCount matchSummaryCodeReport={matchSummaryCodeReport} profile={profiles.precedence} />
				<StewardshipCount stewardshipValueReport={stewardshipValueReport} profile={profiles.stewardship} />
			</div>
		</ReportTile>
	)
}

export { ParallelPassMatching }
