import { AxiosInstance } from 'axios'
import { getApiClient } from '../../../utilities'
import { AppThunk, TDispatch, TGetState } from '../../index'
import { terminateSession } from '../actions'
import { SessionStatus, SignOutReason } from '../types'

export const checkSession =
	(): AppThunk =>
	(dispatch: TDispatch, getState: TGetState): void => {
		if (getState().session.status === SessionStatus.authenticated && getState().session.token) {
			const apiClient: AxiosInstance = getApiClient()

			const endpoint = getState().session.tenant ? '/pls/tenantconfig' : '/pls/login-doc'

			apiClient.get(endpoint).catch(() => {
				dispatch(terminateSession(SignOutReason.backend))
			})
		}
	}
