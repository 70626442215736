import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { RadioButton } from '../../local-core-ui'
import { InputType } from '../../project/steps/setup-source/select-input/selectInput'
import styles from './upload-type-card.module.scss'

export interface UploadTypeCardProps {
	disabled?: boolean
	isBeta?: boolean
	type: InputType
	plusApi?: boolean
	group: string
	checked: boolean
	onChange: () => void
}

export const UploadTypeCard = ({
	disabled = false,
	isBeta = true,
	type,
	plusApi = false,
	group,
	checked,
	onChange
}: UploadTypeCardProps): ReactElement => {
	const { t } = useTranslation()
	return (
		<div className={styles.container}>
			<div className={styles.checkboxContainer}>
				<RadioButton
					value={type}
					group={group}
					id={type}
					label={''}
					onChange={onChange}
					testId={type}
					disabled={disabled}
					checked={checked}
				/>
			</div>
			<div
				className={`${styles.card} ${checked ? styles.selected : ''}`}
				onClick={() => !disabled && onChange && onChange()}
			>
				<div data-testid="flag-container-upload-type-card" className={styles.title}>
					{t(`upload.type.${type.toLowerCase()}${plusApi ? '-api' : ''}`)}{' '}
					{isBeta ? (
						<span data-testid="flag-upload-type-card" className={styles.betaFlag}>
							Beta
						</span>
					) : undefined}
				</div>
				<hr className={styles.divider} />
				<div className={styles.description}>
					<div>{t(`upload.type.${type.toLowerCase()}${plusApi ? '-api' : ''}.line1`)}</div>
					<div className={styles.line2}>
						{t(`upload.type.${type.toLowerCase()}${plusApi ? '-api' : ''}.line2`)}
					</div>
				</div>
			</div>
		</div>
	)
}
