import { AxiosInstance } from 'axios'
import { AssignmentConfig } from '../../types'

export const setTransactionalMatch = async (
	apiClient: AxiosInstance,
	sourceId: string,
	assigmentRematch: AssignmentConfig = {
		enabled: false,
		triggerAssignmentRules: false
	}
): Promise<void> => {
	const url = `/pls/sources/sourceId/${sourceId}/transactionalMatch`

	return apiClient.put(url, assigmentRematch)
}
