import { DNBButton } from '@dnb-uux-design-system/react'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import MapImage from '../../assets/images/choose-match-step-map-image.png'
import TileImage from '../../assets/images/choose-match-step-tile-image.png'
import styles from '../approach-tile/approach-tile.module.scss'

export interface MatchingApproachTileInterface {
	type: 'choose-template' | 'start-from-scratch'
	onClick: () => void
	isButtonDisabled?: boolean
}

export const MatchingApproachTile = ({
	type,
	onClick,
	isButtonDisabled
}: MatchingApproachTileInterface): ReactElement => {
	const { t } = useTranslation()

	const approachTileData: {
		imageSource: string
		alt: string
		buttonLabel: string
		labelSection: ReactElement
	} =
		type === 'choose-template'
			? {
					imageSource: TileImage,
					alt: t('choose.match.step.image.tile.alt'),
					buttonLabel: t('choose.match.step.choose.template.button'),
					labelSection: (
						<div className={styles.textContainer}>
							{t('choose.match.step.choose.template.line.1')}
							<br />
							{t('choose.match.step.choose.template.line.2')}
							<br />
							{t('choose.match.step.choose.template.line.3')}
						</div>
					)
			  }
			: {
					imageSource: MapImage,
					alt: t('choose.match.step.image.map.alt'),
					buttonLabel: t('choose.match.step.from.scratch.button'),
					labelSection: (
						<div className={styles.textContainer}>
							{t('choose.match.step.from.scratch.line.1')}
							<br />
							{t('choose.match.step.from.scratch.line.2')}
							<br />
							{t('choose.match.step.from.scratch.line.3')}
						</div>
					)
			  }

	return (
		<div className={styles.approachTileContainer}>
			<div className={styles.leftColumn}>
				<div className={styles.imageWrapper}>
					<img
						src={approachTileData.imageSource}
						alt={approachTileData.alt}
						data-testid="choose-match-step-map-image"
					/>
				</div>
				{approachTileData.labelSection}
			</div>
			<div className={styles.verticalDivider} />
			<div className={styles.rightColumn}>
				<div className={styles.buttonTxtWrapper}>
					<div className={styles.buttonWrapper}>
						<DNBButton
							size="default"
							variant="primary"
							onClick={onClick}
							data-testid={`choose-match-step-${type}-button`}
							disabled={isButtonDisabled}
						>
							{approachTileData.buttonLabel}
						</DNBButton>
					</div>
				</div>
			</div>
		</div>
	)
}
