import { ElementUIFacade } from '../../../../components/data-block-searcher/ElementUIFacade'
import { TDispatch } from '../../../../store'
import { updateCurrentProjectAction } from '../../../../store/projectWizard/actions'
import { EnrichingBlock } from '../../../../store/projectWizard/types'
import { Element } from '../../../../types'

export const updateEnrichingLayout = (
	dispatch: TDispatch,
	newEnrichingLayout: Array<EnrichingBlock>,
	newSelectedElements: Array<Element>,
	blockId: string
): void => {
	const blockIdx = newEnrichingLayout.findIndex((block) => block.blockId === blockId)
	if (newSelectedElements.length === 0 && blockIdx !== -1) {
		newEnrichingLayout.splice(blockIdx, 1)
	} else {
		if (blockIdx !== -1) {
			newEnrichingLayout[blockIdx].elements = newSelectedElements
		} else {
			const newBlock = {
				blockId: blockId,
				elements: newSelectedElements
			}
			newEnrichingLayout.push(newBlock)
		}
	}
	dispatch(
		updateCurrentProjectAction({
			enrichingLayout: newEnrichingLayout
		})
	)
}

export const updateEnrichingLayoutWithTradeUp = (
	dispatch: TDispatch,
	flattenTradeUps: ElementUIFacade[],
	newEnrichingLayouts: EnrichingBlock[],
	enableTradeUp: boolean
): void => {
	const tmpEnrichingLayout = JSON.parse(JSON.stringify(newEnrichingLayouts))
	let newElems: ElementUIFacade[] = []
	const enrichBlockElem = tmpEnrichingLayout.filter((block: EnrichingBlock) => block.blockId === 'baseinfo')[0]
	if (flattenTradeUps.length && enrichBlockElem) {
		if (enableTradeUp) {
			newElems = enrichBlockElem.elements.concat(flattenTradeUps)
		} else {
			newElems = enrichBlockElem.elements.filter(
				(el: ElementUIFacade) => el.elementId !== flattenTradeUps[0].elementId
			)
		}
		updateEnrichingLayout(dispatch, newEnrichingLayouts, newElems, 'baseinfo')
	}
}
