import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { useApi } from '../hooks'
import { AssignmentConfig } from '../types'
import { setTransactionalMatch } from './api/setTransactionalMatch'

const assignmentDefault: AssignmentConfig = {
	enabled: false,
	triggerAssignmentRules: false
}

export const useSetTransactionalMatch = (
	sourceId: string
): UseMutationResult<void, unknown, AssignmentConfig | undefined, unknown> => {
	const apiClient = useApi()
	const queryClient = useQueryClient()

	const mutationFn = (assigment: AssignmentConfig = assignmentDefault) =>
		setTransactionalMatch(apiClient, sourceId, assigment)

	return useMutation(mutationFn, {
		onSuccess: () => {
			queryClient.invalidateQueries(['getSources', { sourceId, inflateMetadata: true }])
		}
	})
}
