export type setShowCopiedMessage = (showCopiedMessage: boolean) => void

export const copyToClipboard = (textToCopy: string, setShowCopiedMessage?: setShowCopiedMessage): void => {
	navigator.clipboard.writeText(textToCopy)

	if (setShowCopiedMessage) {
		setShowCopiedMessage(true)
		const hideCopiedMessage = setTimeout(() => {
			setShowCopiedMessage(false)
			clearTimeout(hideCopiedMessage)
		}, 2300)
	}
}
