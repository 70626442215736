import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { getUpload, UploadUIFacade } from './api/getUpload'

export const useUpload = (uploadId: string): UseQueryResult<UploadUIFacade> => {
	const apiClient = useApi()

	const queryKey = ['getUploads', uploadId]
	const queryFunction = () => getUpload(apiClient, uploadId)
	return useQuery(queryKey, queryFunction)
}
