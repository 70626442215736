import { AxiosInstance } from 'axios'
import i18next from 'i18next'
import { isEmpty } from 'lodash-es'
import LogRocket from 'logrocket'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import appRoutes from './app-routes'
import { CenterPanel } from './components/center-panel/center-panel'
import { DcpAppFooter } from './components/dcp-app-footer/dcp-app-footer'
import { ErrorModal } from './components/error-modal/error-modal'
import { Spinner } from './components/spinner/spinner'
import { SwitchRoutes } from './components/switch-routes/switch-routes'
import { useSession, useSessionTimeOutHandler } from './hooks'
import { useFeatures, useModules, usePlatform } from './hooks/useEntitlements'
import { useOrgId } from './hooks/useOrgId'
import { Button, Modal } from './local-core-ui'
import { IdaasLogin } from './login/IdaasLogin'
import { LoggedOut } from './logout/logged-out'
import { useConnectionStatus } from './queries/useConnectionStatus'
import { SelectTenant } from './select-tenant/select-tenant'
import { attachToTenant, RootState, useAppDispatch, useAppSelector } from './store'
import { clearConnectionSlice, setCurrentConnectionStatus } from './store/connection/connectionSlice'
import { outboundTransform } from './store/projectWizard/transformer'
import {
	authenticate,
	authenticateAgainst3rdParty,
	authenticateAgainst3rdPartyEnableC4SAuthMgmt,
	authenticateWithEnterpriseSSO,
	authenticateWithIdaas,
	SessionAuthenticator,
	SessionStatus,
	setEnvironmentPlatformOrgIdAction,
	SignOutReason,
	terminateSession
} from './store/session'
import { UnauthorizedLogin } from './unauthorized-login/unauthorized-login'
import { authenticateWithSalesforce, getApiClient } from './utilities'

export function App(): ReactElement {
	const { t } = useTranslation()
	const selectSession = (state: RootState) => state.session
	const session = useAppSelector(selectSession)
	const dispatch = useAppDispatch()
	const [signOut] = useSession(dispatch)
	const queryClient = useQueryClient()
	const [showModal, setShowModal] = useState(false)
	const resetActivityMonitor = useSessionTimeOutHandler(dispatch, () => setShowModal(true))
	const url = new URL(window.location.href)
	const query = new URLSearchParams(url.search)
	const environment = query.get('env')
	let receivedAuthCode: string = url.searchParams.get('code') || ''
	const reAuthenticate: string = url.searchParams.get('sfdcReauthenticate') || ''
	const error: string = url.searchParams.get('error') || ''
	const isC4S = useModules(['C4S'])
	const orgId = useOrgId()
	const isC4SPlatform = usePlatform('salesforce')
	const disableC4SOAuth = useFeatures(['DisableC4SOAuth'])
	const enableManagedConnectedApp = useFeatures(['C4SEnableManagedConnectedApp'])
	const enableC4SAuthMgmt = useFeatures(['EnableC4SAuthMgmt'])
	const useConnectionStatusQuery = useConnectionStatus(orgId || '', enableC4SAuthMgmt && !!orgId && isC4SPlatform)

	useEffect(() => {
		window.addEventListener('storage', (event) => {
			if (
				event.key === 'currentTenant' &&
				session.tenant !== event.newValue &&
				window.localStorage.getItem('currentTenant') !== null
			) {
				const currentTenant = JSON.parse(window.localStorage.getItem('currentTenant'))
				const decrypt = outboundTransform(currentTenant, 'local')
				dispatch(attachToTenant(session.token, decrypt, i18next.language))
				queryClient.resetQueries(['getDataBlocksEntitlements'])
			}
		})
	}, [])

	useEffect(() => {
		if (session.status === SessionStatus.unauthenticated) {
			if (url.searchParams.has('env') && url.searchParams.has('platform') && url.searchParams.has('orgid')) {
				dispatch(setEnvironmentPlatformOrgIdAction(url))
			}
			if (url.pathname.includes('error')) {
				dispatch(terminateSession(SignOutReason.unauthorized))
			} else {
				const apiClient: AxiosInstance = getApiClient()
				let authenticated = false
				apiClient
					.get('/pls/login-doc')
					.then(() => {
						authenticated = true
					})
					.finally(() => {
						if (authenticated) {
							dispatch(authenticate())
						} else {
							if (
								url.pathname.includes('idaas-login') ||
								environment === 'iframe' ||
								(import.meta.env.VITE_DISABLE_OKTA &&
									import.meta.env.VITE_DISABLE_OKTA.toUpperCase() === 'TRUE')
							) {
								if (session.authenticator !== SessionAuthenticator.idaas)
									dispatch(authenticateWithIdaas())
							} else {
								if (session.authenticator === SessionAuthenticator.enterpriseSSO) {
									dispatch(authenticateWithEnterpriseSSO(window.location.href))
								}
							}
						}
					})
			}
		}
		/**
		 * We dont want to run this effect when the url object changes because this force the dependencies of the hook to change on every render.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session.status, session.authenticator, dispatch])

	useEffect(() => {
		if (session.status === SessionStatus.authenticated && session.tenant !== '') {
			if (enableC4SAuthMgmt) {
				dispatch(authenticateAgainst3rdPartyEnableC4SAuthMgmt(receivedAuthCode, t))
				receivedAuthCode = ''
			} else if (
				session.environment !== null &&
				session.environment !== '' &&
				session.integrationUsername !== '' &&
				isC4S
			) {
				dispatch(
					authenticateAgainst3rdParty(
						receivedAuthCode,
						disableC4SOAuth,
						enableManagedConnectedApp,
						enableC4SAuthMgmt
					)
				)
			}
		}
		/**
		 * We dont want to run this effect when the dispatch function changes because this could make an infinite loop.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session.status, session.tenant, session.environment, session.integrationUsername, receivedAuthCode])

	useEffect(() => {
		if (!isEmpty(error)) {
			localStorage.setItem('C4SError', error)
			window.close()
		}
		/**
		 * We dont want to run this effect when user deny Access on C4S OAuth and returns an error.
		 */
	}, [error])

	useEffect(() => {
		if (reAuthenticate) authenticateWithSalesforce(session.integrationDomain)
		/**
		 * We only want to run this effect when reAuthenticate changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reAuthenticate])

	useEffect(() => {
		if (session.username && import.meta.env.VITE_LOG_ROCKET_KEY) LogRocket.identify(session.username)
	}, [session.username])

	useEffect(() => {
		if (session.status === SessionStatus.signedout) {
			dispatch(clearConnectionSlice())
		}
		/**
		 * We want to run this effect to clear the connection redux slice
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session.status])

	useEffect(() => {
		if (
			session.status === SessionStatus.authenticated &&
			session.tenant !== '' &&
			useConnectionStatusQuery.isFetched &&
			useConnectionStatusQuery.data &&
			enableC4SAuthMgmt
		) {
			dispatch(setCurrentConnectionStatus(useConnectionStatusQuery.data.status))
		}
		/**
		 * We want to run this effect to know the status of the current connection only if user is into C4S iFrame
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [useConnectionStatusQuery.data, useConnectionStatusQuery.isFetched])

	return (
		<div className="app-container">
			<ErrorModal />
			<Modal
				open={session.status !== SessionStatus.signedout && showModal}
				isContainer={true}
				showButtonClose={false}
				testId="Session-Modal"
			>
				{t('session.about.to.end')}{' '}
				<div className={`signOutButtonsContainer`}>
					<Button
						onClick={() => {
							resetActivityMonitor()
							setShowModal(false)
						}}
						size="large"
						text={t('stay.signed.in')}
						testId="stay-signed-in"
					/>
					<Button
						onClick={() => {
							signOut()
						}}
						size="medium"
						type="secondary"
						text={t('sign.out')}
						testId="sign-out"
					/>
				</div>
			</Modal>

			{session.status === SessionStatus.signedout ? (
				<>{session.signoutreason === SignOutReason.unauthorized ? <UnauthorizedLogin /> : <LoggedOut />}</>
			) : session.status === SessionStatus.unauthenticated ? (
				<>
					{session.authenticator === SessionAuthenticator.idaas ? (
						<IdaasLogin />
					) : (
						<CenterPanel>
							<Spinner />
						</CenterPanel>
					)}
				</>
			) : (
				<div>
					{(session.user === null || session.tenant === '') && session.tenants.length > 0 ? (
						<SelectTenant />
					) : (
						<BrowserRouter>
							<main>
								<SwitchRoutes routes={appRoutes} />
							</main>
							<DcpAppFooter />
						</BrowserRouter>
					)}
				</div>
			)}
		</div>
	)
}
