// Hook
export function useStorage<S>(
	key: string,
	context: Storage,
	initialValue: S | null = null
): [S, (newValue: S) => void] {
	const getValue = () => {
		try {
			// ensure we store the initialValue
			if (initialValue !== null) {
				context.setItem(key, JSON.stringify(initialValue))
			}
			// Get from local storage by key
			const item = context.getItem(key) || ''
			// Parse stored json or if none return ''
			return item ? JSON.parse(item) : ''
		} catch (error) {
			// If error also return initialValue
			console.log(error)
			return initialValue
		}
	}
	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = (value: S | null) => {
		try {
			if (value) {
				// Allow value to be a function so we have same API as useState
				const valueToStore = value instanceof Function ? value() : value
				// Save to local storage
				context.setItem(key, JSON.stringify(valueToStore))
			} else {
				// Remove key
				context.removeItem(key)
			}
		} catch (error) {
			// A more advanced implementation would handle the error case
			console.log(error)
		}
	}
	const storedValue = getValue()
	return [storedValue, setValue]
}
