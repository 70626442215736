import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { numberDelimeters } from '../../helpers'
import styles from './assignment-overview-tile.module.scss'

interface AssignmentOverviewDetailProps {
	isSelected: boolean
	type: 'assigned' | 'unassigned'
	onDownloadRecords(): void
	onAssign?(): void
	totalCount: number
}

export const AssignmentOverviewDetail = ({
	isSelected,
	type,
	onDownloadRecords,
	onAssign,
	totalCount
}: AssignmentOverviewDetailProps): ReactElement => {
	const { t } = useTranslation()

	return (
		<>
			<div className={styles.assignmentOverviewTileBody}>
				<div className={styles.assignmentOverviewTileDataContainer}>
					<span
						className={`${styles.assignmentOverviewTotal} ${
							isSelected ? styles.assignmentOverviewTileSelectedTxt : ''
						}`}
					>
						{t('assignment.overview.tile.total')}
					</span>
					<span
						className={`${styles.assignmentOverviewTotal} ${styles.assignmentOverviewTileCount} ${
							isSelected ? styles.assignmentOverviewTileSelectedTxt : ''
						}`}
					>
						{numberDelimeters(totalCount)}
					</span>
				</div>
			</div>
			<div className={styles.assignmentOverviewTileFooter}>
				{type === 'unassigned' && onAssign && (
					<button
						className={`${styles.assignmentOverviewTileBtn} ${
							isSelected ? styles.assignmentOverviewTileBtnSelected : ''
						}`}
						onClick={onAssign}
						data-testid={`${type}-overview-tile-assign-btn`}
					>
						{t('assignment.overview.tile.assign.btn')}
					</button>
				)}
				<button
					className={`${styles.assignmentOverviewTileBtn} ${
						isSelected ? styles.assignmentOverviewTileBtnSelected : ''
					}`}
					onClick={onDownloadRecords}
					data-testid={`${type}-overview-tile-download-btn`}
				>
					{t('assignments.to.tile.download.records')}
				</button>
			</div>
		</>
	)
}
