import { ReactElement, useEffect, useRef, useState } from 'react'
import styles from './data-table.module.scss'

export interface CellData {
	columnName: string
	description: string
}

interface informationCell {
	dataTable: Array<CellData>
}

export const DataTable = ({ dataTable }: informationCell): ReactElement => {
	const refTable = useRef<HTMLInputElement>(null)
	const [columns, setColumns] = useState(1)

	useEffect(() => {
		if (refTable.current) {
			setColumns(Math.trunc(refTable.current?.offsetWidth / 76))
		}
	}, [])

	return (
		<div className={styles.dataTableContainer} ref={refTable}>
			<div
				className={styles.dataTable}
				style={{
					gridTemplateColumns: `repeat(${columns}, fit-content(100%))`
				}}
			>
				{dataTable.map((dataCell: CellData, index: number) => (
					<div key={index} className={styles.cellTable}>
						<div title={dataCell.columnName}>{dataCell.columnName}</div>
						<div title={dataCell.description}>{dataCell.description}</div>
					</div>
				))}
			</div>
		</div>
	)
}
