import { ColorBlueBrand } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import spinnerGif from '../../assets/images/spinner.gif'
import { numberDelimeters } from '../../helpers'
import { Icon, IconType } from '../../local-core-ui'
import { AssignmentDetails } from '../../types'
import styles from './assignment-picker.module.scss'

interface AssignmentPickerTileProps {
	assigneeUser: string
	assignmentDetail: AssignmentDetails
	onAddToThisAssignment(assignmentId: string): void
}

export const AssignmentPickerTile = ({
	assigneeUser,
	assignmentDetail,
	onAddToThisAssignment
}: AssignmentPickerTileProps): ReactElement => {
	const { t } = useTranslation()
	const { assignmentId, displayName, totalRecords, priority } = assignmentDetail
	const priorityLowerCase = priority.toLowerCase()
	const priorityIcon = `${priorityLowerCase}-priority-bg` as IconType
	const [isAssigning, setIsAssigning] = useState<boolean>(false)

	const getPriorityLabel = () => {
		return t(`assignment.picker.priority.${priority}`)
	}

	return (
		<div className={styles.assignmentPickerTileContainer}>
			<h4 className={styles.assignmentPickerTileName} title={displayName}>
				{displayName}
			</h4>
			<div className={styles.assignmentPickerTileBody}>
				<div className={styles.assignmentPickerTileRecordsContainer}>
					<span className={styles.assignmentPickerTileRecordsLabel}>{t('assignment.picker.records')}</span>
					<span className={styles.assignmentPickerTileData}>{numberDelimeters(totalRecords)}</span>
				</div>
				<div className={styles.assignmentPickerTileRow}>
					<span className={styles.assignmentPickerTileLabel}>{t('assignment.tile.priority')}</span>
					<span className={`${styles.assignmentPickerTilePriorityLvl} ${styles.assignmentPickerTileData}`}>
						<span className={styles.assignmentPickerTileIconContainer}>
							<Icon testId="priority-picker-tile" type={priorityIcon} size="mini" />
						</span>
						{getPriorityLabel()}
					</span>
				</div>
				<div className={styles.assignmentPickerTileRow}>
					<span className={styles.assignmentPickerTileLabel}>{t('assignment.tile.assignee')}</span>
					<span className={styles.assignmentPickerTileData}>{assigneeUser}</span>
				</div>
				<div className={styles.assignmentPickerTileBtnContainer}>
					<button
						className={styles.assignmentPickerTileBtn}
						onClick={() => {
							onAddToThisAssignment(assignmentId)
							setIsAssigning(true)
						}}
						data-testid={`${assignmentId}-add-to-assignment-btn`}
					>
						{isAssigning ? (
							<div className={styles.assignmentPickerTileLoaderContainer}>
								<img className={styles.assignmentPickerTileLoader} src={spinnerGif} alt="loading" />
							</div>
						) : (
							<span className={styles.assignmentPickerTileBtnIcon}>
								<Icon testId="plus-picket-tile" type="plus" size="mini" color={ColorBlueBrand} />
							</span>
						)}
						<span className={styles.assignmentPickerTileBtnText}>
							{t('assignment.picker.add.to.assignment')}
						</span>
					</button>
				</div>
			</div>
		</div>
	)
}
