import { AxiosResponse } from 'axios'
import { clone } from 'lodash-es'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useApi } from '../../hooks'
import { Button, Modal } from '../../local-core-ui'
import elementsToCopy from '../../project/steps/mapping-v2/elements-to-copy.json'
import { AdditionalConfigGroup } from '../../project/steps/mapping-v2/file-mapping/additional-config-group'
import {
	CreateSourceRequest,
	FieldDefinitionsRecordsMap,
	FieldValidationMessagesDefinition,
	MapFieldDefinition,
	MappingSchema,
	Source,
	ValidateMappingResponse
} from '../../types'
import {
	CollapsableDropdownCollectionManager,
	ColumnDefinition,
	FieldGroup,
	Mapping
} from '../collapsable-dropdown-collection-manager/collapsable-dropdown-collection-manager'
import styles from './modal-enrich-only.module.scss'
interface ModalEnrichOnlyProps {
	open: boolean
	columns: Array<ColumnDefinition>
	mappingData: MappingSchema
	onChangeFunction(updatedMap: FieldDefinitionsRecordsMap): void
	fileImportId: string
	sourceData: Source
	projectId: string
	startImport(): void
	onClose(): void
	onBack(): void
	testId: string
	isMapping4MixedFile?: boolean
	onChange4MixedFile?(newValue: string | undefined): void
	isDirectOnly4MixedFile?: boolean | undefined
}
export const ModalEnrichOnly = ({
	open,
	columns,
	mappingData,
	onChangeFunction,
	fileImportId,
	sourceData,
	projectId,
	startImport,
	onClose,
	onBack,
	testId,
	isMapping4MixedFile = false,
	onChange4MixedFile,
	isDirectOnly4MixedFile
}: ModalEnrichOnlyProps): ReactElement => {
	const { t } = useTranslation()
	const apiClient = useApi()
	const unselectText = t('file.mapping.remove.selection')
	const [isFirstTimeMappingValidation, setIsFirstTimeMappingValidation] = useState<boolean>(true)
	const [isBlockStartImport, setIsBlockStartImport] = useState<boolean>(true)
	const [fieldValidationMessagesMap, setFieldValidationMessagesMap] = useState<{
		[key: string]: FieldValidationMessagesDefinition[]
	}>({})

	const fieldDefinitionsRecordsMap: FieldDefinitionsRecordsMap =
		mappingData.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap

	useEffect(() => {
		/* The confirm button is blocked when there is no value for the field */
		if (fieldDefinitionsRecordsMap && fieldDefinitionsRecordsMap['dunsNumber'][0].inCurrentImport) {
			setIsBlockStartImport(false)
		} else setIsBlockStartImport(true)
	}, [fieldDefinitionsRecordsMap])

	/*For the moment only is management group 'DUNS Number'*/
	const groups = Object.keys(fieldDefinitionsRecordsMap).filter((field: string) => field === 'dunsNumber')
	const getFieldGroups = () =>
		groups.map((group) => {
			const fieldsWithinGroup = fieldDefinitionsRecordsMap[group].map(
				(fieldDefinition) => fieldDefinition.fieldName
			)
			return {
				id: group,
				fields: [
					...fieldsWithinGroup,
					...elementsToCopy
						.filter(
							(elementToCopy) =>
								elementToCopy.destinationKey === group &&
								!fieldsWithinGroup.includes(elementToCopy.field) // don't duplicate field if already exists (contactEmail -> websiteUrl
						)
						.map((element) => element.field)
				],
				additionalCheckSpec: undefined,
				additionalConfigElem: getAdditionalConfigGroup(group)
			} as FieldGroup
		})

	const mappings = groups
		// AdditionalData will contain all the columns that can be mapped so we don't include them on the mapped fields
		.filter((group) => group !== 'additionalData')
		.map((group) => {
			return fieldDefinitionsRecordsMap[group]?.map((fieldDefinition) => {
				return {
					field: fieldDefinition.fieldName,
					column: fieldDefinition.columnName,
					inCurrentImport: fieldDefinition.inCurrentImport
				} as Mapping
			})
		})
		.reduce((results, mapping) => results.concat(mapping), [])

	function sortColumns() {
		return columns.sort((a, b) => {
			const descriptionA = ((a?.description ?? a?.name ?? '') as string).toUpperCase()
			const descriptionB = ((b?.description ?? b?.name ?? '') as string).toUpperCase()
			if (descriptionA < descriptionB) {
				return -1
			}
			if (descriptionA > descriptionB) {
				return 1
			}
			return 0
		})
	}

	const processMappingChanges = (mappingChanges: Array<Mapping>) => {
		if (mappingChanges[0].column === undefined) {
			setIsBlockStartImport(true)
		} else {
			setIsBlockStartImport(false)
		}
		const newFieldDefinitionsRecordsMap = clone(fieldDefinitionsRecordsMap)
		groups.forEach((group) => {
			newFieldDefinitionsRecordsMap[group].forEach((existingMapping) => {
				const newMapping = mappingChanges.find(
					(mappingChange) => mappingChange.field === existingMapping.fieldName
				)
				if (newMapping) {
					const columnToBeChanged = existingMapping.columnName

					existingMapping.columnName = newMapping.column
					// Let the BE know we are including / excluding the field from the import
					existingMapping.inCurrentImport = existingMapping?.columnName !== undefined

					if (columnToBeChanged && columnToBeChanged !== existingMapping.columnName) {
						// the column was removed (or changed) we should add it back to additionalData
						newFieldDefinitionsRecordsMap['additionalData'].push({
							fieldType: 'TEXT',
							columnName: columnToBeChanged,
							inCurrentImport: true
						})
					}
				}
			})
		})
		// When a field that's on additionalData is mapped to a section, we need to remove it from additionalData

		mappingChanges.forEach((change) => {
			if (change.column) {
				const idx = newFieldDefinitionsRecordsMap['additionalData'].findIndex(
					(fieldDefinition) => fieldDefinition.columnName === change.column
				)
				if (idx > -1) newFieldDefinitionsRecordsMap['additionalData'].splice(idx, 1)
			}
		})
		onChangeFunction(newFieldDefinitionsRecordsMap)
	}

	const validateMapping = () => {
		const urlSrc = `/pls/sources/mappings?entityType=${sourceData.entityType}${'&sourceId=' + sourceData.sourceId}`
		const urlValidation = `/pls/sources/validate?entityType=Accounts&fileImportId=${fileImportId}`
		const urlUpdate = '/pls/sources'
		apiClient.get(urlSrc).then((responseSrc: AxiosResponse<MappingSchema>) => {
			const mappingSrc: MappingSchema = responseSrc.data
			const newDunsNumber: Array<MapFieldDefinition> =
				mappingData.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap['dunsNumber']
			mappingSrc.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap['dunsNumber'] = newDunsNumber
			//Only the fields related to the change of duns are left
			const changesMappingFile =
				mappingData.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap.additionalData.filter(
					(addiitionalField) => {
						return !addiitionalField.fieldName
					}
				)
			mappingSrc.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap.additionalData =
				mappingSrc.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap.additionalData.concat(
					changesMappingFile
				)
			//If the selected column exists in the source mapping, when selecting it like new duns
			// it is necessary to remove the 'additionalData' column.
			mappingSrc.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap.additionalData =
				mappingSrc.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap.additionalData.filter(
					(aditionalField) => {
						return (
							!aditionalField.fieldName ||
							(aditionalField.fieldName && aditionalField.columnName !== newDunsNumber[0].columnName)
						)
					}
				)
			mappingSrc.existingFieldDefinitionsMap = {}
			apiClient.post(urlValidation, mappingSrc).then((response: AxiosResponse<ValidateMappingResponse>) => {
				const validationResult = response.data.validationResult
				if (
					(validationResult === 'WARNING' &&
						isFirstTimeMappingValidation &&
						response.data.fieldValidationMessagesMap['additionalData']) ||
					validationResult === 'ERROR'
				) {
					setIsFirstTimeMappingValidation(false)
					setFieldValidationMessagesMap(response.data.fieldValidationMessagesMap)
					setIsBlockStartImport(false)
				} else {
					let request: CreateSourceRequest = {
						inbound_document_type: '',
						project_id: projectId,
						field_definitions_record: mappingSrc.currentFieldDefinitionsRecord,
						display_name: sourceData.sourceDisplayName,
						isApi: sourceData.isApi,
						isFile: sourceData.isFile,
						delimiter_info: sourceData.delimiterInfo,
						enable_ame: sourceData.enableAme,
						stewardable: sourceData.stewardable
					}
					if (isMapping4MixedFile) {
						request = { ...request, direct_enrich: isDirectOnly4MixedFile }
					}
					apiClient
						.put(urlUpdate, {
							...request,
							source_id: sourceData.sourceId
						})
						.then(() => {
							startImport()
						})
				}
			})
		})
	}

	const getAdditionalConfigGroup = (group: string): JSX.Element | undefined => {
		if (group === 'dunsNumber' && isMapping4MixedFile) {
			const fieldDUNS = mappings.filter((mapping) => mapping.field === 'DUNS')
			return (
				<AdditionalConfigGroup
					title={t('title.additional.config.duns') as string}
					valueSelected={
						isDirectOnly4MixedFile !== undefined ? isDirectOnly4MixedFile.toString() : 'undefined'
					}
					options={[
						{ label: t('radio.button.option.enrich.using'), value: 'true' },
						{
							label: t('radio.button.option.rematch.records'),
							value: 'false'
						}
					]}
					isDisabled={fieldDUNS.length > 0 && fieldDUNS[0].column === undefined}
					onChangeFunction={(newValue) => {
						onChange4MixedFile ? onChange4MixedFile(newValue) : null
					}}
					isSubSection={true}
				/>
			)
		}
	}

	return (
		<Modal testId={'modal-only-enrich-Criteria'} open={open} isContainer={true} onClose={onClose}>
			<div className={styles.modalEnrichOnly}>
				<h1>
					{isMapping4MixedFile
						? t('file.mapping.line.1.company')
						: t('source.process.file.only.enrich.title')}
				</h1>
				<span className={styles.spanBold}>{t('source.process.file.only.enrich.instruction')}</span>
				<span>{t('source.process.file.only.enrich.description')}</span>
				<div>
					<CollapsableDropdownCollectionManager
						isProjectWizard={false}
						fieldGroups={getFieldGroups()}
						mappings={mappings}
						columns={sortColumns()}
						onChangeFunction={processMappingChanges}
						unselectText={unselectText}
						requiredFields={['DUNS']}
						mappingData={mappingData}
						errorMessages={fieldValidationMessagesMap}
						testId="cddcm-file-mapping-enrich-only"
					/>
				</div>
				<div className={styles.buttonsContainer}>
					<Button
						text={t('source.process.file.only.enrich.button.back')}
						onClick={() => {
							onBack()
						}}
						size="medium"
						type="secondary"
						testId={testId + '-back-onlyEnrich'}
					/>
					<Button
						text={t('source.process.file.only.enrich.button.confirm')}
						onClick={() => {
							setIsBlockStartImport(true)
							validateMapping()
						}}
						size="medium"
						type="primary"
						isDisabled={isBlockStartImport}
						testId={testId + '-confirm-onlyEnrich'}
					/>
				</div>
			</div>
		</Modal>
	)
}
