import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, DropdownOptions, Grid } from '../../local-core-ui'

export const FilePreviewerDelimiter = (
	detectedDelimiter: string,
	testId: string,
	onDelimiterChange: (delimiter: string) => void,
	onIdentifierChange: (delimiter: string) => void,
	onEscapeChange: (delimiter: string) => void,
	disableFileDelimiterChanges = false
) => {
	const { t } = useTranslation()
	const COMMA = ','
	const SEMICOLON = ';'
	const DOUBLE_QUOTE = '"'
	const BACKSLASH = '\\'
	const [delimiter, setDelimiter] = useState<string>(detectedDelimiter)
	const [delimiterError, setDelimiterError] = useState<boolean>(true)

	const escape = BACKSLASH
	const identifier = DOUBLE_QUOTE

	/*TODO DCP-3385 uncomment when needed
	const example =
		'01234' +
		delimiter +
		escape +
		identifier +
		t('upload.chooser.some.text') +
		identifier +
		escape +
		delimiter +
		escape +
		identifier +
		t('upload.chooser.another.text') +
		identifier +
		escape
	 */

	const delimiterOptions: DropdownOptions[] = [
		{ label: t('upload.chooser.option.comma'), value: COMMA },
		//TODO DCP-3385 uncomment when needed
		/*{ label: t('upload.chooser.option.tab'), value: TAB },
		{ label: t('upload.chooser.option.pipe'), value: PIPE },*/
		{ label: t('upload.chooser.option.semicolon'), value: SEMICOLON }
	]

	useEffect(() => {
		//TODO DCP-3385 delimiter === TAB || delimiter === PIPE ||
		if (delimiter === COMMA || delimiter === SEMICOLON) {
			setDelimiterError(false)
		}
	}, [delimiter, identifier, escape])

	return (
		<div className="_delimiterContainer_">
			<div className="_delimiterText_">{t('upload.chooser.delimiter.text')}</div>
			<Grid testId="container-file-delimiter" container gutter={1}>
				<Grid testId="container-dropdown-file-delimiter" sm={6}>
					<Dropdown
						id={'upload-delimiter-dropdown'}
						hint={''}
						onChangeFunction={(selectedDelimiter) => {
							setDelimiter(selectedDelimiter)
							onDelimiterChange(selectedDelimiter)
						}}
						label={t('upload.chooser.delimiter.label')}
						options={delimiterOptions}
						testId={testId + 'upload-delimiter-dropdown'}
						isRequired={true}
						size={'fluid'}
						selected={delimiter}
						insideForm={delimiterError}
						disabled={delimiterError || disableFileDelimiterChanges}
					/>
					{delimiterError ? <p className="_errorText_">{t('upload.chooser.delimiter.error')}</p> : <p></p>}
				</Grid>
				<Grid testId="last-file-delimiter-empty" sm={6} />
			</Grid>
		</div>
	)
}
