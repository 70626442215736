import { ReactElement, useEffect, useState } from 'react'
import styles from './spinner.module.scss'

interface SpinnerProps {
	smallSized?: boolean
}
export function Spinner({ smallSized = false }: SpinnerProps): ReactElement {
	const [spinnerIE, setSpinnerIE] = useState(false)

	useEffect(() => {
		if (navigator.userAgent.indexOf('Trident/7.0') !== -1) {
			setSpinnerIE(true)
		}
	}, [])

	return (
		<div className={styles.spinnerPosition}>
			<div
				className={`${styles.spinnerContainer} ${smallSized ? styles.spinnerContainerSmall : ''}`}
				data-testid="spinner-container"
			>
				<svg viewBox={'22 22 44 44'}>
					<circle cx={44} cy={44} r={20} fill={'none'} stroke="#A4CDDA" strokeWidth={2.5} />
					{!spinnerIE && (
						<circle
							cx={44}
							cy={44}
							r={20}
							fill={'none'}
							stroke="#005172"
							strokeWidth={2.5}
							className={styles.spinnerCircle}
						/>
					)}
				</svg>
				{spinnerIE && (
					<div className={styles.spinnerIE}>
						<div />
						<div />
						<div />
						<div />
					</div>
				)}
			</div>
		</div>
	)
}
