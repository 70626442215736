import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RadioButton, ToggleSwitch } from '../../../local-core-ui'
import { generateArrayFromMatchRegex, generateMatchRegex } from '../../../utils'
import styles from './easy-match-string-setup.module.scss'

interface EasyMatchStringSetupI {
	matchStrings: Array<string>
	onChange(matchString: string): void
	testId: string
	readonly?: boolean
	useEasyMatchString: boolean
	onChangeEasyMatchString(newValue: boolean): void
}

interface MatchFieldSetupProps {
	field: string
	index: number
	onChange: (event: ChangeEvent<HTMLInputElement>) => void
	readonly?: boolean
	fieldSetup: string
	testId: string
}

type FieldSetupOptions = { [key: string]: string }

const fields = [
	'businessName',
	'streetNumber',
	'streetName',
	'city',
	'state',
	'mailingAddress',
	'telephone',
	'postalCode',
	'density',
	'uniqueness',
	'industrysic'
]

const defaultFieldSetup = '[AB]'

const getInitialSetupState = (matchString: string): FieldSetupOptions => {
	const fieldSetupOptions: { [key: string]: string } = {}
	let allGrade = 'NONE'

	if (matchString && matchString.length > 0) {
		const matchStringArray = generateArrayFromMatchRegex(matchString)
		fields.forEach((field, index) => (fieldSetupOptions[field] = matchStringArray[index]))

		switch (matchString) {
			case 'A{11}.{3}':
				allGrade = 'A'
				break
			case '.{14}':
				allGrade = '.'
				break
			case '[AB]{11}.{3}':
			default:
				allGrade = '[AB]'
				break
		}
	} else {
		fields.forEach((field) => (fieldSetupOptions[field] = defaultFieldSetup))
		allGrade = defaultFieldSetup
	}

	return {
		allfields: allGrade,
		...fieldSetupOptions
	}
}

const MatchFieldSetup = ({
	field,
	onChange,
	index,
	fieldSetup,
	testId,
	readonly
}: MatchFieldSetupProps): ReactElement => {
	const { t } = useTranslation()
	const matchQualityGrades = ['A', 'B', 'F', 'Z']

	let selectedGrade = 'ABFZ'

	if (fieldSetup === 'A') {
		selectedGrade = 'A'
	}

	if (fieldSetup === '[AB]') {
		selectedGrade = 'AB'
	}

	return (
		<div key={index} className={styles.easyMatchRow}>
			<label data-testid="label-radio-matching">{t(`easy.match.string.setup.${field}`)}</label>
			<div className={styles.matchRadioButtonGroup}>
				<div className={styles.matchRadioButton}>
					<RadioButton
						value="A"
						group={field}
						id={`${field}-exact`}
						label={t('easy.match.string.setup.exact')}
						onChange={onChange}
						checked={fieldSetup === 'A'}
						testId={testId + '-rb-exact-' + index}
						disabled={readonly}
					/>
				</div>
				<div className={styles.matchRadioButton}>
					<RadioButton
						value="[AB]"
						group={field}
						id={`${field}-similar`}
						label={t('easy.match.string.setup.similar')}
						onChange={onChange}
						checked={fieldSetup === '[AB]'}
						testId={testId + '-rb-similar-' + index}
						disabled={readonly}
					/>
				</div>
				<div className={styles.matchRadioButton}>
					<RadioButton
						value="."
						group={field}
						id={`${field}-ignore`}
						label={t('easy.match.string.setup.ignore')}
						onChange={onChange}
						checked={fieldSetup === '.'}
						testId={testId + '-rb-ignore-' + index}
						disabled={readonly}
					/>
				</div>
			</div>
			<div className={styles.easyMatchGradeRow}>
				{matchQualityGrades.map((grade, index) => {
					return (
						<div
							data-testid={testId + '_' + grade + '_' + index}
							key={`grade_${grade}`}
							className={styles.easyMatchStringGrid}
						>
							<div
								className={
									selectedGrade.includes(grade)
										? styles.easyMatchStringImgSelected
										: styles.easyMatchStringImg
								}
							>
								{grade}
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export const EasyMatchStringSetup = ({
	matchStrings,
	onChange,
	testId,
	readonly = false,
	useEasyMatchString,
	onChangeEasyMatchString
}: EasyMatchStringSetupI): ReactElement => {
	const { t } = useTranslation()
	const matchQualityGrades = ['A', 'B', 'F', 'Z']
	const fieldToExclude = 'allfields'
	const initialFieldSetup = getInitialSetupState(matchStrings[0])

	const [setupOptions, setSetupOptions] = useState<FieldSetupOptions>(initialFieldSetup)

	const updateFieldGrade = (field: string, value: string) => {
		setSetupOptions((prevOptions) => ({
			...prevOptions,
			[field]: value
		}))
	}

	const handleAllFieldsChange = (match: string) => {
		for (const field in initialFieldSetup) {
			updateFieldGrade(field, match)
		}
	}

	const handleOptionChange = (field: string, match: string) => {
		updateFieldGrade(field, match)
	}

	const handleOnChange = (field: string, event: ChangeEvent<HTMLInputElement>) => {
		const newMatch = event.target.value
		if (field === fieldToExclude) {
			handleAllFieldsChange(newMatch)
		} else {
			handleOptionChange(field, newMatch)
		}
	}

	useEffect(() => {
		const matchGradeOptions = { ...setupOptions }
		delete matchGradeOptions[fieldToExclude]

		onChange(generateMatchRegex(Object.values(matchGradeOptions)))
		/**
		 * We don't want to run this effect when the `onChange` prop changes because this force the dependencies of the hook to change on every render.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setupOptions])

	useEffect(() => {
		if (!useEasyMatchString && !readonly) {
			handleAllFieldsChange('[AB]')
		}
	}, [useEasyMatchString])

	return (
		<>
			<div className={styles.useEasyMatch}>
				<div className={styles.labelEasyMatch}>{t('text.label.switch.use.easy.match')}</div>
				<ToggleSwitch
					id={'toggle-easy-match'}
					label={''}
					onChange={() => {
						onChangeEasyMatchString(!useEasyMatchString)
					}}
					checked={useEasyMatchString}
					testId={'toggle-easy-match-string'}
					disabled={readonly}
				></ToggleSwitch>
			</div>
			<div data-testid={testId + 'easyMatchStringDefinitionCont'} className={styles.easyMatchStringDefinition}>
				<div className={styles.easyMatchStringDefinitionInfo}>
					<div>{t(`easy.match.string.setup.field.definition.info`)}</div>
				</div>
				<div
					data-testid={testId + 'easyMatchStringDefinitionGrid'}
					className={styles.easyMatchStringDefinitionGrid}
				>
					{matchQualityGrades.map((grade, index) => {
						return (
							<div
								data-testid={testId + '_' + grade + '_' + index}
								key={grade + '_' + index}
								className={styles.easyMatchStringGrid}
							>
								<div className={styles.easyMatchStringImg}>{grade}</div>
								<div className={styles.easyMatchStringImgDec}>
									<label data-testid="label-radio-matching" className={styles.labelHeader}>
										{t(`manual.match.key.${grade}.desc`)}
									</label>
									<label data-testid="label-radio-matching">
										{t(`manual.match.key.${grade}.example`)}
									</label>
								</div>
							</div>
						)
					})}
				</div>
			</div>
			<div className={styles.easyMatchStringSetup}>
				<div className={styles.easyMatchRow}>
					<span data-testid="header-field">{t(`easy.match.string.setup.field`)}</span>
					<span data-testid="header-acceptableMthQlty">
						{t(`easy.match.string.setup.AcceptableMatchQuality`)}
					</span>
					<span data-testid="header-mthqltyGrades">{t(`easy.match.string.setup.MatchQualityGrades`)}</span>
				</div>
				{Object.keys(setupOptions).map((field, index) => (
					<MatchFieldSetup
						field={field}
						fieldSetup={setupOptions[field]}
						index={index}
						key={field}
						onChange={(event) => {
							handleOnChange(field, event)
						}}
						readonly={readonly || !useEasyMatchString}
						testId={testId}
					/>
				))}
			</div>
		</>
	)
}
