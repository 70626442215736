import { FC } from 'react'
import styles from './loading-states.module.scss'

export const LoadingProjectStatus: FC = () => {
	return (
		<div className={styles.loadingContainer}>
			<div className={styles.header} />
			<div className={styles.containerValuesEmpties}>
				<div className={styles.emptyCurrentProjectState} />
				<div className={styles.emptyProjectStartedBy} />
			</div>
		</div>
	)
}
