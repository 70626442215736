import { ChangeEvent, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Modal, ToggleSwitch } from '../../local-core-ui'
import styles from './ameToggle.module.scss'

export interface AmeToggleProps {
	disabled?: boolean
	checked: boolean
	onChange(event: ChangeEvent<HTMLInputElement>): void
}
export const AmeToggle = ({ disabled = false, checked, onChange }: AmeToggleProps): ReactElement => {
	const { t } = useTranslation()
	const [showModal, setShowModal] = useState(false)

	const internalOnChange = (event: ChangeEvent<HTMLInputElement>) => {
		onChange(event)
		if (event.target.checked) setShowModal(true)
	}
	return (
		<>
			<Grid testId="container-ame-toggle" container>
				<Grid testId="first-space-ame-toggle" sm={12}>
					&nbsp;
				</Grid>
				<Grid testId="container-ame-toggle-switch" sm={12}>
					<ToggleSwitch
						onChange={internalOnChange}
						label={t('ame.toggle.label')}
						testId="ame-toggle"
						id="ame-toggle"
						disabled={disabled}
						checked={checked}
					/>
				</Grid>
				<Grid testId="second-space-ame-toggle" sm={12}>
					&nbsp;
				</Grid>
				<Grid testId="explanation-container-ame-toggle" sm={12}>
					<div data-testid="ame-explanation" className={styles.explanation}>
						{t('ame.explanation')}
					</div>
				</Grid>
			</Grid>
			{showModal ? (
				<Modal
					open={showModal}
					showButtonClose={true}
					onClose={() => setShowModal(false)}
					testId="AME-Confirm-Modal"
				>
					<div data-testid="ame-enabled-description" className={styles.confirmationModal}>
						{t('ame.enabled.description')}
					</div>
					<Button
						text={t('ame.confirm')}
						type="primary"
						size="small"
						onClick={() => setShowModal(false)}
						testId={'ame-toggle-ok'}
					/>
				</Modal>
			) : undefined}
		</>
	)
}
