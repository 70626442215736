import cx from 'classnames'
import styles from './skeleton-section-data.module.scss'

export const SkeletonSectionData = () => {
	return (
		<div className={styles.skeletonSectionData}>
			<div className={cx(styles.lineTitle, styles.pulse)} />
			<div className={styles.containerCircleTitle}>
				<div className={cx(styles.circle, styles.pulse)} />
				<div className={cx(styles.lineTitle, styles.pulse)} />
			</div>
			<div className={styles.bigParagraph}>
				<div className={cx(styles.lineTitle, styles.pulse)} />
				<div className={cx(styles.lineTitle, styles.pulse)} />
				<div className={cx(styles.lineTitle, styles.pulse)} />
				<div className={cx(styles.lineTitle, styles.pulse)} />
				<div className={cx(styles.lineTitle, styles.pulse)} />
			</div>
			<div className={styles.twoBar}>
				<div className={cx(styles.lineTitle, styles.pulse)} />
				<div className={cx(styles.lineTitle, styles.pulse)} />
			</div>
			<div className={styles.containerTiles}>
				<div className={styles.tileB}>
					<div className={cx(styles.lineTitle, styles.pulse)} />
					<div className={cx(styles.lineTitle, styles.pulse)} />
				</div>
				<div className={styles.tileB}>
					<div className={cx(styles.lineTitle, styles.pulse)} />
					<div className={cx(styles.lineTitle, styles.pulse)} />
				</div>
				<div className={styles.tileB}>
					<div className={cx(styles.lineTitle, styles.pulse)} />
					<div className={cx(styles.lineTitle, styles.pulse)} />
				</div>
				<div className={styles.tileB}>
					<div className={cx(styles.lineTitle, styles.pulse)} />
					<div className={cx(styles.lineTitle, styles.pulse)} />
				</div>
			</div>
		</div>
	)
}
