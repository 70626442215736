import * as d3 from 'd3'
import styles from '../base-donut-chart/base-donut-chart.module.scss'

interface DrawKeysProps {
	svg: d3.Selection<SVGElement>
	selector: string
	data: Array<{ [key: string]: unknown }>
	textForKey: (d: { data: { label: string; value: number }; [key: string]: unknown }) => string
	showKeys: boolean
	color: (d: { [key: string]: unknown }) => unknown
	dx: number
	dy: number
	iconPadding: number
	iconRadius: number
	keyLabel: string
	containerTooltips?: string
	orientation?: 'horizontal' | 'vertical'
}

export const drawKeys = ({
	svg,
	selector,
	data,
	textForKey,
	showKeys,
	color,
	dx,
	dy,
	iconPadding,
	iconRadius,
	keyLabel,
	containerTooltips,
	orientation = 'vertical'
}: DrawKeysProps): void => {
	const keyGroup = svg.select(selector)

	let keyDetails = keyGroup.selectAll('.key').data(data, textForKey)

	keyDetails.exit().remove()
	if (showKeys) {
		const g = keyDetails.enter().append('g').attr('class', 'key')

		g.append('circle')
			.attr('r', iconRadius)
			.attr('fill', (d) => {
				return color(d.data.label.toLowerCase().replace(/[\s+]/g, ''))
			})

		g.append('text')
			.attr('dy', '5px') // sorry rems, but freaking IE does not understand you
			.attr('dx', '15px') // sorry rems, but freaking IE does not understand you
			.attr('class', keyLabel)
			.attr('fill', (d) => {
				return color(d.data.label.toLowerCase().replace(/[\s+]/g, ''))
			})
			.on('mouseover', function (d, i) {
				const id = 'containerTooltipsLegend-' + i.data.label.replaceAll(/[\s+]/g, '')
				if (containerTooltips && (i.data.netTotal || i.data.value) && !document.getElementById(id)) {
					d3.select(this).transition().duration('50').attr('opacity', '.85')
					const containerTooltip = d3
						.select(containerTooltips)
						.append('div')
						.attr('id', id)
						.style('position', 'absolute')
						.style('opacity', 0)
					containerTooltip.transition().duration(50).style('opacity', 1)
					const containerTextTooltip = containerTooltip
						.append('div')
						.classed(styles.containerTooltipPie, true)
					containerTextTooltip
						.append('div')
						.classed(styles.textTooltipDesc, true)
						.text(
							i.data.label +
								' : ' +
								(i.data.netTotal !== undefined ? i.data.netTotal : i.data.value).toLocaleString()
						)
					containerTooltip.style('left', d.offsetX + 7 + 'px').style('top', d.offsetY - 15 + 'px')
				}
			})
			.on('mouseout', function (d, i) {
				if (containerTooltips) {
					const containerTooltip = d3
						.select(containerTooltips)
						.select('#containerTooltipsLegend-' + i.data.label.replaceAll(/[\s+]/g, ''))
					d3.select(this).transition().duration('50').attr('opacity', '1')
					containerTooltip.transition().duration('50').style('opacity', 0)
					containerTooltip.remove()
				}
			})

		keyDetails = g.merge(keyDetails)

		keyGroup.selectAll('g.key').attr('transform', function (d, i) {
			if (orientation === 'vertical') {
				const vert = i * iconPadding + dy
				return `translate(${dx}, ${vert})`
			} else {
				const labelOffset = i * d.data.label.length * 16
				const horizontal = labelOffset + dx
				return `translate(${horizontal}, ${dy})`
			}
		})

		keyDetails.select('text').text(textForKey)
	} else {
		keyDetails.remove()
	}
}
