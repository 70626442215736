import { DNBDialog } from '@dnb-uux-design-system/react'
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styles from './router-modal-prompt.module.scss'

interface RouterModalPromptProps {
	disableSaveChangesButton?: boolean
	onClose?: () => void
	onContinueWithoutSaving?: () => void
	onSaveChanges: () => void
	when: boolean
}

const RouterModalPrompt = ({
	disableSaveChangesButton: disableSaveChanges = false,
	onClose,
	onContinueWithoutSaving,
	onSaveChanges,
	when
}: RouterModalPromptProps): ReactElement | null => {
	const { t } = useTranslation()
	const history = useHistory()
	const [currentPath, setCurrentPath] = useState('')
	const [showPrompt, setShowPrompt] = useState(false)

	/**
	 * Added ref to avoid double prompt warning when using history block inside useEffect
	 */
	const unblockRef = useRef<(() => void) | null>()

	const handleClose = () => {
		if (onClose) {
			onClose()
		}
		setShowPrompt(false)
	}

	const handleSaveChanges = useCallback(async () => {
		if (onSaveChanges) {
			await Promise.resolve(onSaveChanges())
		}

		setShowPrompt(false)
	}, [onSaveChanges])

	const handleContinueWithoutSaving = useCallback(async () => {
		if (onContinueWithoutSaving) {
			await Promise.resolve(onContinueWithoutSaving())
		}

		if (unblockRef.current) {
			unblockRef.current()
		}

		setShowPrompt(false)
		history.push(currentPath)
	}, [currentPath, history, onContinueWithoutSaving])

	useEffect(() => {
		if (when) {
			unblockRef.current = history.block((location) => {
				setCurrentPath(location.pathname)
				setShowPrompt(true)
				return false
			})
		}

		return () => {
			unblockRef.current && unblockRef.current()
		}
	}, [when, history])

	return showPrompt ? (
		<DNBDialog
			hasCloseButton
			onClose={handleClose}
			open={showPrompt}
			primaryCTA={handleSaveChanges}
			primaryCTALabel={t('dashboard.settings.unsavedChanges.modal.saveChanges.button') ?? ''}
			secondaryCTA={handleContinueWithoutSaving}
			secondaryCTALabel={t('dashboard.settings.unsavedChanges.modal.cancelChanges.button') ?? ''}
			disabledPrimaryCTA={disableSaveChanges}
		>
			<div className={styles.selectAllElementsModalContent}>
				<div className={styles.textWrapper}>
					<p className={styles.title}>{t('dashboard.settings.unsavedChanges.modal.title')}</p>

					<p className={styles.description}>{t('dashboard.settings.unsavedChanges.modal.description')}</p>
				</div>
			</div>
		</DNBDialog>
	) : null
}

export { RouterModalPrompt }
export type { RouterModalPromptProps }
