import { useTranslation } from 'react-i18next'
import { Snackbar, SnackType } from '../snackbar/snackbar'

const ReportSnackbar = () => {
	const { t } = useTranslation()

	return <Snackbar type={SnackType.information} title={t('report.snackbar.noData')} />
}

export { ReportSnackbar }
