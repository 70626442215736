import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Button, Dropdown, Icon, Input, Modal, Textarea } from '../../local-core-ui'
import { useUsers } from '../../queries/useUsers'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import { createAssignmentWithRecords } from '../../store/steward/thunks'
import { AssignmentMethod, AssignmentPriority, CreateAssignmentRequest, UserDetail } from '../../types'
import styles from './new-assignment-modal.module.scss'

interface NewAssignmentModalProps {
	isOpen: boolean
	userId: string
	role: string
	onClose(): void
	testId: string
}

export const NewAssignmentModal = ({
	isOpen,
	onClose,
	userId,
	role,
	testId
}: NewAssignmentModalProps): ReactElement => {
	const { t } = useTranslation()

	const [assignmentName, setAssignmentName] = useState('')
	const [priority, setPriority] = useState('')
	const [description, setDescription] = useState('')

	const stewardState = (state: RootState) => state.steward
	const steward = useAppSelector(stewardState)

	const usersQuery = useUsers()

	const user = usersQuery.data?.find((aUser: UserDetail) => userId === aUser.Email)

	const dispatch = useAppDispatch()

	const createNewAssignment = () => {
		const newAssignment: CreateAssignmentRequest = {
			assignee: userId,
			displayName: assignmentName,
			description,
			priority: priority as AssignmentPriority,
			assignmentMethod: 'ID' as AssignmentMethod,
			recordIds: steward.selectedRecords.map((r) => r.recordId)
		}
		dispatch(createAssignmentWithRecords(newAssignment))
		onClose()
	}

	useEffect(() => {
		if (isOpen) {
			setAssignmentName('')
			setPriority('')
			setDescription('')
		}
	}, [isOpen])

	return (
		<Modal
			open={isOpen}
			showButtonClose={true}
			onClose={() => {
				onClose()
			}}
			testId="NewAssignmentModal"
		>
			<div className={styles.modalBody}>
				<div
					className={styles.clickable}
					tabIndex={0}
					onClick={() => {
						onClose()
					}}
				>
					<Icon testId="arrow-back-new-assignment" type="arrow-back" />
				</div>
				<div className={styles.title}>{t('assignment.new')}</div>
				<div className={styles.userCard}>
					<Avatar
						testId={testId + '-avatar'}
						userData={[{ label: 'Name', value: `${user?.FirstName} ${user?.LastName}` }]}
					/>
					<div className={styles.userInfo}>
						<div className="name">
							{user?.FirstName} {user?.LastName}
						</div>
						<div className={styles.role}>{role}</div>
					</div>
				</div>
				<div>
					<Input
						id="new-assignment-name"
						onChangeFunction={setAssignmentName}
						value={assignmentName}
						label={t('assignment.name')}
						hint={t('assignment.name.hint')}
						required={true}
						testId={testId + '-assignment-name'}
					/>
				</div>
				<div className={styles.priorityField}>
					<Dropdown
						id="new-assignment-priority"
						hint={t('assignment.priority.hint')}
						onChangeFunction={setPriority}
						label={t('assignment.priority')}
						options={[
							{
								label: t('assignment.priority.low'),
								value: 'LOW'
							},
							{
								label: t('assignment.priority.medium'),
								value: 'MEDIUM'
							},
							{
								label: t('assignment.priority.high'),
								value: 'HIGH'
							}
						]}
						isRequired={true}
						testId={testId + '-priority'}
					/>
				</div>
				<div className={styles.descContainer}>
					<Textarea
						id="new-assignment-description"
						value={description}
						placeholder={t('assignment.desc.hint')}
						label={t('assignment.desc')}
						onChange={(e) => {
							setDescription(e.currentTarget.value)
						}}
						displayRemainingCharacters={true}
						size="medium"
						maxLength={500}
						maxLines={5}
						autoGrowth={false}
						remainingCharactersText={t('assignment.desc.characters.left')}
						testId={testId + '-assignment-description'}
					/>
				</div>
				<div className={styles.buttonContainer}>
					<Button
						text={t('assignment.create')}
						onClick={createNewAssignment}
						testId={testId + '-create-button'}
						isDisabled={!assignmentName || !priority}
					/>
				</div>
			</div>
		</Modal>
	)
}
