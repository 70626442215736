import { DNBButton, DNBTypography } from '@dnb-uux-design-system/react'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorState, Grid } from '../../../local-core-ui'
import {
	generateArrayFromMatchRegex,
	generateMatchRegex,
	MAX_MATCH_STRING_CHARS,
	MIN_MATCH_STRING_CHARS
} from '../../../utils'
import { ManualMatchExample } from '../../manual-match-example/manual-match-example'
import { ManualMatchKey } from '../../manual-match-key/manual-match-key'
import { ManualMatchStringInput } from '../manual-match-string-input/manual-match-string-input'
import { MatchStringContainer } from '../match-string-container/match-string-container'
import styles from './manual-match-string-setup.module.scss'

interface ManualMatchStringSetupI {
	matchStrings: Array<string>
	updateMatchStrings: (updatedMatchStrings: Array<string>) => void
	testId: string
	readonly?: boolean
}

export const ManualMatchStringSetup = ({
	matchStrings,
	updateMatchStrings,
	testId,
	readonly = false
}: ManualMatchStringSetupI): ReactElement => {
	const { t } = useTranslation()
	const [newMatchString, setNewMatchString] = useState<string>('')
	const [duplicateMatchString, setDuplicateMatchString] = useState(false)

	const removeMatchString = (index: number) => {
		const newMatchArray = [...matchStrings]
		newMatchArray.splice(index, 1)
		updateMatchStrings(newMatchArray)
	}

	const addNewMatchStringCallback = useCallback(() => {
		const newMatchArray = [...matchStrings]
		newMatchArray.push(generateMatchRegex(newMatchString.split('')))
		updateMatchStrings(newMatchArray)
		setNewMatchString('')
	}, [matchStrings, newMatchString, updateMatchStrings])

	useEffect(() => {
		const handleKey = (event: KeyboardEvent) => {
			if (event.key === 'Enter') {
				event.preventDefault()
				if (newMatchString?.length === MIN_MATCH_STRING_CHARS) {
					addNewMatchStringCallback()
				}
			}
		}
		document.addEventListener('keydown', handleKey)
		return () => {
			document.removeEventListener('keydown', handleKey)
		}
	}, [newMatchString, addNewMatchStringCallback])

	useEffect(() => {
		if (newMatchString !== '' && matchStrings.includes(generateMatchRegex(newMatchString.split('')))) {
			setDuplicateMatchString(true)
		} else {
			setDuplicateMatchString(false)
		}
	}, [newMatchString, matchStrings])

	return (
		<Grid testId="container-manual-match-string" container gutter={3}>
			<DNBTypography variant="compact-body">{t('manual.match.description')}</DNBTypography>
			<div className={styles.matchInfo}>
				<ManualMatchKey />
				<ManualMatchExample />
			</div>

			{matchStrings.map((matchString, index) => (
				<Grid testId="match-string" key={index} md={6} sm={6} lg={6} xl={6}>
					<div className={index % 2 !== 0 ? styles.containerFloatRight : ''}>
						<MatchStringContainer
							matchString={generateArrayFromMatchRegex(matchString).join('')}
							matchStringIndex={index}
							onClickDelete={removeMatchString}
							testId={testId + '-msc'}
							readonly={readonly}
						/>
					</div>
				</Grid>
			))}
			<Grid testId="manual-match-string-selection" md={6} sm={6} lg={6} xl={6}>
				<div
					className={`${styles.manualMatchInputSection} ${
						matchStrings.length % 2 !== 0 ? styles.containerFloatRight : ''
					}`}
				>
					{!readonly && (
						<>
							<ManualMatchStringInput
								value={newMatchString}
								onChange={setNewMatchString}
								testId={testId + '-mmsi'}
								error={duplicateMatchString}
							/>
							<div className={styles.manualMatchButton}>
								<DNBButton
									data-testid={testId + '-add-match-string'}
									disabled={
										newMatchString?.length < MIN_MATCH_STRING_CHARS ||
										newMatchString?.length > MAX_MATCH_STRING_CHARS ||
										matchStrings.includes(generateMatchRegex(newMatchString.split('')))
									}
									onClick={() => addNewMatchStringCallback()}
									size="compact"
								>
									{t('manual.match.string.setup.add')}
								</DNBButton>
							</div>
						</>
					)}
					{duplicateMatchString && (
						<div
							className={`${styles.manualMatchInputDuplicate} ${
								matchStrings.length % 2 !== 0
									? styles.duplicateMatchStringFloatLeft
									: styles.duplicateMatchStringFloatRight
							}`}
						>
							<ErrorState
								errorMessage={t('manual.match.string.duplicate') as string}
								onClose={() => setDuplicateMatchString(false)}
								testId={testId + '-error-state'}
							/>
						</div>
					)}
				</div>
			</Grid>
		</Grid>
	)
}
