import { TFunction } from 'react-i18next'
import { ContextMenuOption } from '../../local-core-ui'

export const assignContextMenuOptions = (t: TFunction<'translation'>, showDetail: boolean): ContextMenuOption[] => {
	return [
		{
			label: t(`assignments.to.tile.${showDetail ? 'hide' : 'show'}.dashboard`),
			action: 'hideDashboard'
		},
		{
			label: t('assignments.to.tile.download.records'),
			action: 'downloadRecords'
		}
	]
}

export const unAssignContextMenuOptions = (t: TFunction<'translation'>, showDetail: boolean): ContextMenuOption[] => {
	return [
		{
			label: t(`assignments.to.tile.${showDetail ? 'hide' : 'show'}.dashboard`),
			action: 'hideDashboard'
		},
		{
			label: t('assignments.to.tile.assign.records'),
			action: 'assignRecords'
		},
		{
			label: t('assignments.to.tile.download.records'),
			action: 'downloadRecords'
		}
	]
}
