export const usedFeatureFlags = [
	'EnrichmentLibrary',
	'EnableAPI',
	'EnableFileDelimiters',
	'C4SBeta',
	'EnableContactMatching',
	'EnableTradeUpC4S',
	'EnableTradeUp',
	'EnableEmailVerification',
	'EnableAmeMatch',
	'EnableJsonEnrichment',
	'EnhancedCompanyEntityResolution',
	'EnableContactAPI',
	'EnableUIOverageAlert',
	'EnableMatchRecommendation',
	'EnablePassProfile',
	'InternationalContacts',
	'EnableDataRetentionPolicy',
	'EnableFileLevelDashboardDownload',
	'EnableDiscoverRefresh',
	'EnableCompanyFinancialsV3',
	'EnableUpwardFamilyTreeSideFile',
	'EnableFullFamilyTreeSideFile',
	'EnableEnrichedOnlyMixedFile',
	'EnableConnectionManagement'
]
