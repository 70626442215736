import PropTypes from 'prop-types'
import { FC, forwardRef } from 'react'
import { Locale, supportedLocales } from '../../i18n'
import styles from './language-list.module.scss'

interface LanguageProps {
	short: string
	long: string
}
const Language: FC<LanguageProps> = ({ short, long }) => {
	return (
		<>
			<b>{long}</b> {short}
		</>
	)
}
export interface LanguageListProps {
	selectedLocale: Locale
	onNewSelection?: (newLocale: Locale) => void
}
export const LanguageList = forwardRef<HTMLSpanElement, LanguageListProps>(function LanguageList(
	{ onNewSelection, selectedLocale },
	ref
) {
	const sortByLong = (a: Locale, b: Locale) => (a.long > b.long ? 1 : a.long < b.long ? -1 : 0)
	return (
		<span className={styles.languageListContainer} ref={ref}>
			<span>
				<div className={styles.selectedLanguage} data-testid="language-selected">
					<Language short={selectedLocale.short} long={selectedLocale.long} />
				</div>
				<ul className={styles.listOfLanguages} data-testid="languages-list">
					{supportedLocales.sort(sortByLong).map((supportedLocale) => (
						<li
							key={supportedLocale.key}
							onClick={(e) => {
								e.preventDefault()
								if (supportedLocale.key !== selectedLocale.key && onNewSelection)
									onNewSelection(supportedLocale)
							}}
							className={supportedLocale.key === selectedLocale.key ? styles.disabled : styles.selectable}
							data-testid={'language-' + supportedLocale.short}
						>
							<Language short={supportedLocale.short} long={supportedLocale.long} />
						</li>
					))}
				</ul>
			</span>
		</span>
	)
})

LanguageList.propTypes = {
	selectedLocale: PropTypes.any.isRequired,
	onNewSelection: PropTypes.func
}
