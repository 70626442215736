import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from '../local-core-ui'
import { initializeSessionAction, RootState, SignOutReason, useAppDispatch, useAppSelector } from '../store'
import styles from './logged-out.module.scss'

export function LoggedOut(): ReactElement {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const selectSession = (state: RootState) => state.session

	const session = useAppSelector(selectSession)

	const signInAgain = () => {
		dispatch(initializeSessionAction())
	}

	useEffect(() => {
		setTimeout(signInAgain, 150000)
		/**
		 * We only want to run this effect once, which is why we are leaving the dependency array.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Modal open={true} isContainer={true} showButtonClose={false} testId="LoggedOutModal">
			{session.signoutreason === SignOutReason.userrequested
				? t('successfully.signed.out')
				: t('logged.out.inactivity')}
			<div className={styles.buttonsContainer}>
				<Button onClick={signInAgain} size="large" text={t('sign.in.again')} testId="sign-in-again" />
			</div>
		</Modal>
	)
}
