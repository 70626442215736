import { FC } from 'react'
import spinnerGif from '../../assets/images/spinner.gif'
import { ContextMenu, ContextMenuOption } from '../../local-core-ui'
import styles from './information-section-header.module.scss'

interface IInformationSectionHeaderProps {
	sectionName: string
	options: ContextMenuOption[]
	menuClicked(action: string, index: number): void
	loading?: boolean
	readOnly?: boolean
	testId: string
}
export const InformationSectionHeader: FC<IInformationSectionHeaderProps> = ({
	sectionName,
	options,
	menuClicked,
	loading = false,
	readOnly,
	testId
}: IInformationSectionHeaderProps) => {
	return (
		<div>
			<div className={styles.containerHeader}>
				<div className={styles.titleSection} data-testid="title-header">
					{sectionName}
				</div>
				{loading ? (
					<div>
						<img src={spinnerGif} alt="loading" />
					</div>
				) : null}
				<div className="menu">
					{!readOnly && (
						<ContextMenu
							options={options}
							onMenuClicked={menuClicked}
							alignment="left"
							testId={testId + '-ish-menu'}
						/>
					)}
				</div>
			</div>
		</div>
	)
}
