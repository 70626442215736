export const timestampFormatter = (timestamp: string | undefined): string => {
	if (timestamp) {
		const date = new Date(timestamp)
		const month = date.getMonth() + 1
		const day = date.getDate()
		const fullYear = date.getFullYear().toString()
		const year = fullYear.substring(fullYear.length - 2)
		let hours = date.getHours()
		const ampm = hours >= 12 ? 'PM' : 'AM'
		hours = hours % 12
		hours = hours ? hours : 12
		return `${month}.${day}.${year} / ${hours} ${ampm}`
	}
	return ''
}
