import { isEmpty } from 'lodash-es'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { ColorGray } from '../../../../design-tokens/build/shared/token-colors.json'
import { Button, Divider, Modal } from '../../local-core-ui/'
import styles from './c4s-fail-connection-modal.module.scss'

interface C4SFailConnectionModalProps {
	open: boolean
	onClose(): void
	onRetry(): void
	testId: string
	errors?: string[]
}

export const C4SFailConnectionModal = ({
	open,
	onClose,
	onRetry,
	testId,
	errors
}: C4SFailConnectionModalProps): ReactElement => {
	const { t } = useTranslation()

	return (
		<Modal open={open} onClose={() => onClose()} testId={testId} showButtonClose={false} fixWidth={false}>
			<div className={styles.mainContainer}>
				<div className={styles.title}>{t('create.salesforce.failed.title')}</div>
				<div className={styles.description}>{t('create.salesforce.failed.message')}</div>
				{!isEmpty(errors) && (
					<div className={styles.errorMessage}>
						{errors?.map((error, key) => (
							<p key={key}>{error}</p>
						))}
					</div>
				)}
			</div>
			<div className={`${styles.buttonContainer} ${isEmpty(errors) ? styles.margin : ''}`}>
				<Divider color={ColorGray} />
				<div className={styles.buttons}>
					<Button
						size={'small'}
						type={'secondary'}
						text={t('Close')}
						onClick={() => onClose()}
						testId={testId + '-close'}
					/>
					<div className={styles.continueButton}>
						<Button size={'small'} text={t('Retry')} onClick={() => onRetry()} testId={testId + '-retry'} />
					</div>
				</div>
			</div>
		</Modal>
	)
}
