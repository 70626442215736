import { useTranslation } from 'react-i18next'
import { ReportProgressBar } from '../../../../components/report-progress-bar/report-progress-bar'
import { ReportSnackbar } from '../../../../components/report-snackbar/report-snackbar'
import { ColDef, ReportTable, RowDef } from '../../../../components/report-table/report-table'
import { ReportTile } from '../../../../components/report-tile/report-tile'
import { BaseReportSection } from '../../types/BaseReportSection'

type SinglePassMatchingProps = BaseReportSection

type ConfidenceData = {
	confidenceCode: number
	count: number
	rate: number
}

const rowHeight = '2.8rem'

const SinglePassMatching = ({ uploadReport }: SinglePassMatchingProps) => {
	const { t } = useTranslation()
	const matchToDunsReport = uploadReport?.matchToDunsReport ?? null
	const confidenceRateList = matchToDunsReport ? [...matchToDunsReport.confidenceRateList] : []
	const displayReport = confidenceRateList.length > 0

	const tableColumns: ColDef[] = [
		{
			label: t('report.page.singlePassMatching.tile.table.column1') as string,
			field: 'confidenceCode'
		},
		{
			label: t('report.page.singlePassMatching.tile.table.column2') as string,
			field: 'count'
		},
		{
			label: t('report.page.singlePassMatching.tile.table.column3') as string,
			field: 'percentage'
		},
		{
			field: 'progress-bar'
		}
	]

	const totalRecords = confidenceRateList.reduce((acc, current) => {
		acc += current.count
		return acc
	}, 0)

	const confidenceData = Array.from(Array<ConfidenceData>(11), (_, idx) => ({
		confidenceCode: idx,
		rate: 0,
		count: 0
	}))

	confidenceRateList.forEach(({ confidenceCode, count, rate }) => {
		confidenceData[confidenceCode] = {
			confidenceCode,
			count,
			rate
		}
	})

	const tableRows: RowDef[] = confidenceData.map(({ confidenceCode, count }) => [
		confidenceCode.toString(),
		new Intl.NumberFormat().format(count),
		Math.round((count * 100) / totalRecords).toString() + '%',
		<ReportProgressBar
			key={`progress-bar-${confidenceCode}`}
			id={`progress-bar-${confidenceCode}`}
			value={count}
			total={totalRecords}
		/>
	])

	return (
		<ReportTile header={t('report.page.singlePassMatching.header') as string}>
			<ReportTile
				title={t('report.page.singlePassMatching.tile.title') as string}
				subtitle={t('report.page.singlePassMatching.tile.subtitle') as string}
			>
				{displayReport ? (
					<ReportTable columns={tableColumns} rows={tableRows} rowHeight={rowHeight} hideBorders />
				) : (
					<ReportSnackbar />
				)}
			</ReportTile>
		</ReportTile>
	)
}

export { SinglePassMatching }
