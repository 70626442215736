import { btnOptions } from '../../components/footer-buttons/footer-buttons'
import { RecordClassification } from '../../components/steward-filter-tabs/steward-filter-tabs'
import { TileTypes } from '../../store/steward/types'
import { Record } from '../../types'

export const getIsAssignAvailable = (
	selectedRecords: Record[],
	selectedTile: TileTypes,
	onAssign?: () => void
): (() => void) | undefined => {
	const selectedRecordsCount = selectedRecords.length
	const hasSelectedRecords = selectedRecordsCount > 0
	const hasAutoAcceptedRecords = selectedRecords.some((record) => record.classification === 'AUTO_ACCEPTED')
	const hasSelectedPublishedTile = selectedTile === TileTypes.Published
	const hasSelectedUnassignedTile = selectedTile === TileTypes.Unassigned
	const enableAssign =
		(hasSelectedPublishedTile && hasSelectedRecords && hasAutoAcceptedRecords) ||
		(hasSelectedUnassignedTile && hasSelectedRecords)
	return enableAssign ? onAssign : undefined
}

export const getPrimaryBtn = (selectedTile: TileTypes, requestedClassification?: RecordClassification): btnOptions => {
	switch (selectedTile) {
		case TileTypes.Published:
			return 'moveToReview'
		case TileTypes.Unassigned:
			return 'assign'
		case TileTypes.Assigned:
			return 'assign'
		case TileTypes.AssignedToMe:
		default:
			return requestedClassification === RecordClassification.NeedsReview ? 'accept' : 'assign'
	}
}
