import { WizardComposerEnrichingStepDefinition } from '../../../project/steps/enriching/WizardComposerEnrichingStepDefinition'
import { WizardComposerMappingStepDefinition } from '../../../project/steps/mapping-v2/WizardComposerMappingStepDefinition'
import { WizardComposerMatchingStepDefinition } from '../../../project/steps/matching-v2/WizardComposerMatchingStepDefinition'
import { WizardComposerProjectOverviewStepDefinition } from '../../../project/steps/overview/WizardComposerProjectOverviewStepDefinition'
import { WizardComposerSetupProjectStepDefinition } from '../../../project/steps/setup-project/WizardComposerSetupProjectStepDefinition'
import { WizardComposerSetupSourceStepDefinition } from '../../../project/steps/setup-source/WizardComposerSetupSourceStepDefinition'
import { WizardContext } from '../helpers/WizardContext'
import { WizardCompleteSetupExtraInfo } from '../types/WizardCompleteSetupExtraInfo'
import { WizardRoute } from '../types/WizardRoute'

export const projectWizard: WizardRoute[] = [
	{
		path: '/steps/project-wizard/project',
		stepDefinition: WizardComposerSetupProjectStepDefinition
	},
	{
		path: '/steps/project-wizard/select-data',
		stepDefinition: WizardComposerSetupSourceStepDefinition
	},
	{
		path: '/steps/project-wizard/mapping',
		stepDefinition: WizardComposerMappingStepDefinition,
		disabled: (context) =>
			context.enableApi &&
			context.projectWizardState.currentProject.source.isApi &&
			!context.projectWizardState.currentProject.source.isFile &&
			context.projectWizardState.currentProject.thirdPartyIntegration === undefined
	},
	{
		path: '/steps/project-wizard/matching',
		stepDefinition: WizardComposerMatchingStepDefinition
	},
	{
		path: '/steps/project-wizard/enrichment',
		stepDefinition: WizardComposerEnrichingStepDefinition
	},
	{
		path: '/steps/project-wizard/review',
		stepDefinition: WizardComposerProjectOverviewStepDefinition,
		extraInfo: (context: WizardContext): WizardCompleteSetupExtraInfo | undefined => {
			if (context.projectWizardState.currentProject.thirdPartyIntegration) {
				return {
					completeSetupFirstLineMessage: context.t('wizard.complete.setup.button.message', {
						object: context.t(
							`${context.projectWizardState.currentProject.thirdPartyIntegration?.crmSource}`
						)
					}),
					completeSetupSecondLineMessage: context.t('wizard.complete.setup.button.message.secondLine')
				} as WizardCompleteSetupExtraInfo
			}
		}
	}
]
