// Polynomial rolling hash function
// https://cp-algorithms.com/string/string-hashing.html#determine-the-number-of-different-substrings-in-a-string
// https://byby.dev/polynomial-rolling-hash#:~:text=Hash%20functions%20are%20used%20to,keys%20by%20comparing%20their%20fingerprints.

export const stringHash = (s: string): number => {
	const p = 127
	const m = 1e9 + 9
	let hash_value = 0

	for (let idx = 0; idx < s.length; idx++) {
		const c = s.charCodeAt(idx) - 31 // I consider only the ascii values that are letters, that is starting on the space (dec 32)
		hash_value = (hash_value * p + c) % m
	}
	return hash_value
}
