import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { Grid } from '../../../../local-core-ui'
import { PillButton } from '../../../pill-button/pill-button'
import { useWizardRouteMatch } from '../../hooks/useWizardRouteMatch'
import { LocationState } from '../wizardStepper/wizardStepper'
import styles from './wizardCompleteSetupButton.module.scss'

export const StepCtas: FC = () => {
	const { areWeDoneYet, isNextDisabled, next } = useWizardRouteMatch()
	const { t } = useTranslation()
	const [origin, setOrigin] = useState('overview')
	const location = useLocation<LocationState>()
	const history = useHistory()

	useEffect(() => {
		if (location.state) setOrigin(location.state.source)
		/**
		 * location.state could be undefined, we only want to update this effect when location.state is defined and
		 * source property changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.state?.source])

	const saveStep = () => {
		next().then(() => history.push(`/dashboard/${origin}`))
	}

	const close = () => {
		history.push(`/dashboard/${origin}`)
	}

	return (
		<>
			<div className={styles.wizardFooter}>
				<div className={styles.pillButtonContainer}>
					<div className={styles.completeMySetupContainer}>
						<Grid testId="container-stepctas" container>
							<Grid testId="save-stepctas" sm={5}>
								{areWeDoneYet() ? (
									<PillButton
										text={t('create.project.form.label.save')}
										onClick={saveStep}
										disabled={isNextDisabled()}
										testId={'step-composer-save-button'}
									/>
								) : undefined}
							</Grid>
							<Grid testId="space-stepctas" sm={2}>
								&nbsp;
							</Grid>
							<Grid testId="cancel-stepctas" sm={5}>
								<PillButton
									text={t('confirmation.modal.cancel')}
									onClick={close}
									testId={'step-composer-cancel-button'}
								/>
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
		</>
	)
}
