import { AxiosInstance, AxiosResponse } from 'axios'

export interface MatchedRecordSummary {
	hasMatchedRecords: boolean
}

export const getMatchedRecordSummary = async (
	apiClient: AxiosInstance,
	sourceId?: string,
	isC4SPlatform = false
): Promise<MatchedRecordSummary> => {
	if (!!sourceId && isC4SPlatform) {
		const url = `/pls/integration/sourceId/${sourceId}/matchedRecordSummary`
		return apiClient.get(url).then((response: AxiosResponse<MatchedRecordSummary>) => response.data)
	} else {
		return {
			hasMatchedRecords: false
		}
	}
}
