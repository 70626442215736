import { ReactElement } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { ForgotPassword } from '../forgotPassword/ForgotPassword'
import { LoginTemplate } from './LoginTemplate'

export const IdaasLogin = (): ReactElement => {
	return (
		<Router>
			<Route path="*" exact component={LoginTemplate} />
			<Route path="/signin/forgot-password" exact component={ForgotPassword} />
		</Router>
	)
}
