import { EnrichingBlock } from '../store/projectWizard/types'

export const fullFamilyTreeSelected = (selectedBlocks?: Array<EnrichingBlock>): boolean => {
	const block = selectedBlocks?.find((block) => block.blockId == 'hierarchyconnections')
	const fullFamilyTree = block?.elements?.find((element) => {
		const detailLevel = element as unknown as { level: string }
		return detailLevel.level === 'Full Family Tree'
	})

	return fullFamilyTree !== undefined
}
