import { t } from 'i18next'
export const toSentenceCase = (str: string): string => {
	const skipSentenceCase = ['TSRCommodityCodesId.element', 'unspscId.element']
	const doNotSkipSentenceCase = skipSentenceCase.map((sentence) => {
		return t(sentence)
	})
	if (doNotSkipSentenceCase.indexOf(str) > -1) {
		return str.trim()
	} else {
		const regEx = new RegExp(/(^(d&b)\s\w)|(d&b)|(^[a-zA-Z])/, 'g')
		return str
			.trim()
			.toLowerCase()
			.replace(regEx, (match) => match.toUpperCase())
	}
}
