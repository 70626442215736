import { useQuery, UseQueryResult } from 'react-query'
import { SourceUIFacade } from '../dashboard/SourceUIFacade'
import { useApi } from '../hooks'
import { getSource } from './api/getSource'

export const useSource = (sourceId: string, inflateMetadata = false): UseQueryResult<SourceUIFacade> => {
	const apiClient = useApi()

	const queryKey = ['getSources', { sourceId, inflateMetadata }]
	const queryFunction = () => getSource(apiClient, sourceId, inflateMetadata)
	return useQuery(queryKey, queryFunction, { enabled: sourceId !== undefined && sourceId !== '' })
}
