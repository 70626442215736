import { ColorBrandPrimary } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { FC } from 'react'
import { Icon, IconType } from '../../local-core-ui'
import styles from './pill-button.module.scss'

interface PillButtonProps {
	text: string
	disabled?: boolean
	onClick: () => void
	testId: string
	type?: 'primary' | 'secondary'
	short?: boolean
	icon?: IconType
}

export const PillButton: FC<PillButtonProps> = ({
	text,
	disabled,
	onClick,
	testId,
	type = 'primary',
	short,
	icon
}: PillButtonProps) => {
	return (
		<button
			className={`${styles.pillCommon} ${!disabled ? styles.pillActive : ''} ${
				type === 'secondary' ? styles.secondary : ''
			} ${short ? styles.short : ''}`}
			disabled={disabled}
			onClick={onClick}
			data-testid={testId}
		>
			{icon && (
				<span className={styles.iconContainer}>
					<Icon
						testId="icon-pill-button"
						type={icon}
						size="mini"
						color={type === 'secondary' ? ColorBrandPrimary : ''}
					/>
				</span>
			)}
			{text}
		</button>
	)
}
