import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { useApi } from '../hooks'
import { Record } from '../types'
import { changeRecordsToStewardStatus } from './api/changeRecordsToStewardStatus'

export interface ChangeRecordsToStewardStatusParams {
	assignmentId: string
	newCommitStatus: boolean
	records: Array<Record>
}
export const useChangeRecordsToStewardStatus = (): UseMutationResult<
	void,
	unknown,
	ChangeRecordsToStewardStatusParams,
	unknown
> => {
	const apiClient = useApi()
	const queryClient = useQueryClient()

	const mutationFn = ({ assignmentId, newCommitStatus, records }: ChangeRecordsToStewardStatusParams) =>
		changeRecordsToStewardStatus(apiClient, assignmentId, newCommitStatus, records)

	return useMutation(mutationFn, {
		onSuccess: () => {
			const queryKey = ['getRecordsToSteward']
			queryClient.invalidateQueries(queryKey)
			// Invalidate all the queries related to RecordsToSteward
		}
	})
}
