import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Record } from '../../types'
import { TableCompareRecord } from './table-compare-record'
import styles from './table-compare.module.scss'

interface TableCompareProps {
	data: Array<Record>
	isMultiSelectActive: boolean
	selectedRecords: string[]
	onRecordCheck(recordId: string, checked: boolean): void
}

export const TableCompare: FC<TableCompareProps> = ({
	data,
	isMultiSelectActive,
	selectedRecords,
	onRecordCheck
}: TableCompareProps) => {
	const { t } = useTranslation()

	return (
		<div className={styles.tableCompare}>
			<table>
				<thead>
					<tr>
						{isMultiSelectActive && <th className={styles.checkBoxColumn} />}
						<th>{t('table.compare.record.business.name')}</th>
						<th>{t('table.compare.record.trade.style')}</th>
						<th>{t('table.compare.record.website')}</th>
						<th>{t('table.compare.record.address')}</th>
						<th>{t('table.compare.record.phone')}</th>
					</tr>
				</thead>
				<tbody>
					{data.map((record, i) => {
						const isSelected = selectedRecords?.includes(record.recordId) || false
						return (
							<div
								key={i}
								className={styles.recordContainer}
								onClick={() => onRecordCheck(record.recordId, !isSelected)}
							>
								<TableCompareRecord
									record={record}
									isMultiSelectActive={isMultiSelectActive}
									isSelected={isSelected}
									onRecordCheck={onRecordCheck}
								/>
							</div>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}
