import { isEmpty } from 'lodash-es'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, DropdownOptions, Input } from '../../local-core-ui/'
import { ConnectionStatus } from '../../queries/api/getC4SActiveConnection'
import { ConnectionDetail } from '../../queries/api/getConnection'
import styles from './snowflake-form.module.scss'

export interface SnowflakeData {
	connectionType: 'Snowflake'
	username: string
	password?: string
	organization: string
	domainName: string
	cloud: SnowCloud
	region: SnowRegion
	cloudRegion: SnowCloudRegion
	nameConnection?: string
	status?: ConnectionStatus
	name?: string
}

interface SnowflakeFormProps {
	isEditable?: boolean
	connectionData?: ConnectionDetail
	showPassword?: boolean
	size?: 'large' | 'fluid'
	editableCloudRegion?: boolean
	onChangeForm?(data?: SnowflakeData): void
}

export enum SnowCloud {
	AWS = 'AWS',
	Empty = ''
}

export enum SnowRegion {
	NA = 'NA',
	Empty = ''
}

export enum SnowCloudRegion {
	USEast = 'US East (N. Virginia)',
	Empty = ''
}

export const SnowflakeForm = ({
	isEditable,
	connectionData,
	showPassword = true,
	size = 'fluid',
	editableCloudRegion = true,
	onChangeForm
}: SnowflakeFormProps): ReactElement => {
	const { t } = useTranslation()
	const [username, setUsername] = useState(connectionData?.user || '')
	const [password, setPassword] = useState('')
	const { authenticationSummary } = connectionData || {}
	const [organization, setOrganization] = useState(authenticationSummary?.organization || '')
	const [domain, setDomain] = useState(authenticationSummary?.domainName || '')
	const [cloud, setCloud] = useState<SnowCloud>(authenticationSummary?.cloud || SnowCloud.Empty)
	const [region, setRegion] = useState<SnowRegion>(authenticationSummary?.region || SnowRegion.Empty)
	const [cloudRegion, setCloudRegion] = useState<SnowCloudRegion>(
		authenticationSummary?.cloudRegion || SnowCloudRegion.Empty
	)

	const clouds: Array<DropdownOptions> = [
		{
			label: 'AWS',
			value: SnowCloud.AWS
		}
	]
	const regions: Array<DropdownOptions> = [
		{
			label: 'North America',
			value: SnowRegion.NA
		}
	]
	const cloudRegions: Array<DropdownOptions> = [
		{
			label: 'US East (N. Virginia)',
			value: SnowCloudRegion.USEast
		}
	]

	const getLabel = (label: string, isDropdown?: boolean) => (
		<div className={isDropdown ? styles.dropdownFieldName : styles.fieldName}>
			{label}
			{isEditable && <div className={styles.requiredSymbol}>&nbsp;*</div>}
		</div>
	)

	useEffect(() => {
		if (onChangeForm) {
			onChangeForm({
				connectionType: 'Snowflake',
				username: username,
				password: password,
				organization: organization,
				domainName: domain,
				cloud: cloud,
				region: region,
				cloudRegion: cloudRegion
			})
		}
		/**
		 * We want to run this effect only when any state for the form change.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [username, password, organization, domain, cloud, region, cloudRegion])

	return (
		<div className={styles.mainContainer} data-testid="snowflake-form">
			{getLabel(t('create.snowflake.username'))}
			<Input
				id="userName"
				hint={t('create.snowflake.username.hint')}
				onChangeFunction={(value: string) => setUsername(value)}
				label={''}
				value={username}
				size={size}
				testId="snow-username"
				disabled={!isEditable}
			/>
			{showPassword && getLabel(t('create.snowflake.password'))}
			{showPassword && (
				<Input
					id="password"
					hint={t('create.snowflake.password.hint')}
					onChangeFunction={(value: string) => setPassword(value)}
					label={''}
					value={password}
					size={size}
					testId="snow-username"
					disabled={!isEditable}
				/>
			)}
			{getLabel(t('create.snowflake.organization'))}
			<Input
				id="organization"
				hint={t('create.snowflake.organization.hint')}
				onChangeFunction={(value: string) => setOrganization(value)}
				label={''}
				value={organization}
				size={size}
				testId="snow-username"
				disabled={!isEditable}
			/>
			{getLabel(t('create.snowflake.domain'))}
			<Input
				id="domain"
				hint={t('create.snowflake.domain.hint')}
				onChangeFunction={(value: string) => setDomain(value)}
				label={''}
				value={domain}
				size={size}
				testId="snow-username"
				disabled={!isEditable}
			/>
			{getLabel(t('create.snowflake.cloud'), true)}
			<Dropdown
				id={'cloud'}
				hint={t('create.snowflake.cloud.hint')}
				onChangeFunction={(value: SnowCloud) => setCloud(value)}
				label={''}
				options={clouds}
				size={size}
				selected={cloud}
				testId="snow-cloud"
				disabled={!isEditable || !editableCloudRegion}
			/>
			{getLabel(t('create.snowflake.region'), true)}
			<Dropdown
				id={'region'}
				hint={t('create.snowflake.region.hint')}
				onChangeFunction={(value: SnowRegion) => setRegion(value)}
				label={''}
				options={regions}
				size={size}
				selected={region}
				testId="snow-cloud"
				disabled={!isEditable || !editableCloudRegion}
			/>
			{getLabel(t('create.snowflake.cloud.region'), true)}
			<Dropdown
				id={'cloud-region'}
				hint={t('create.snowflake.cloud.region.hint')}
				onChangeFunction={(value: SnowCloudRegion) => setCloudRegion(value)}
				label={''}
				options={cloudRegions}
				size={size}
				selected={cloudRegion}
				disabled={isEmpty(cloud) || isEmpty(region) || !isEditable || !editableCloudRegion}
				testId="snow-cloud"
			/>
		</div>
	)
}
