import { MatchTemplateFacade } from '../../../../components/match-template-picker/match-template-picker'
import { MatchRule, MatchTemplate, MatchTemplateList, UserDetail } from '../../../../types'

export const getMatchLayoutFacadeByTemplates = async (
	matchTemplateList: MatchTemplateList,
	userList: Array<UserDetail>,
	sourceId: string
): Promise<Array<MatchTemplateFacade>> => {
	return matchTemplateList?.map((template: MatchTemplate): MatchTemplateFacade => {
		const userDetail = getUserDetail(userList, template.createdBy)
		const matchRules: Array<MatchRule> = template.matchRuleTemplates
			?.filter((rule) => !rule.archived)
			.map((rule): MatchRule => {
				return {
					matchRuleTemplateId: rule.matchRuleTemplateId,
					matchRuleId: rule.matchRuleTemplateId,
					sourceId: sourceId,
					displayName: rule.displayName,
					state: rule.state,
					created: rule.createTime,
					ruleType: rule.ruleType,
					versionId: rule.versionId,
					matchKey: rule.matchKey,
					allowedValues: rule.allowedValues,
					acceptCriterion: {
						LowestConfidenceCode: rule.acceptCriterion.LowestConfidenceCode,
						HighestConfidenceCode: rule.acceptCriterion.HighestConfidenceCode,
						MatchGradePatterns: rule.acceptCriterion.MatchGradePatterns
					},
					exclusionCriterion: rule.exclusionCriterion
				}
			})
		return {
			templateId: template.matchConfigTemplateId,
			name: template.displayName,
			createdBy:
				userDetail == undefined
					? {
							id: template.createdBy,
							AccessLevel: 'THIRD_PARTY_USER',
							FirstName: '',
							LastName: '',
							Title: '',
							Email: '',
							Username: ''
					  }
					: userDetail,

			createTime: template.createTime == undefined ? 0 : template.createTime,
			updateTime: template.updateTime,
			matchRules: matchRules
		}
	})
}

const getUserDetail = (userList: Array<UserDetail>, userEmail: string) => {
	return userList.find((user) => {
		return user.Email === userEmail
	})
}
