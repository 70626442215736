import { ApiErrorActionTypes, ApiErrorState, CLEAR_API_ERROR, SET_API_ERROR } from './types'

export function setApiErrorAction(errorData: ApiErrorState): ApiErrorActionTypes {
	return {
		type: SET_API_ERROR,
		payload: errorData
	}
}

export function clearApiErrorAction(): ApiErrorActionTypes {
	return {
		type: CLEAR_API_ERROR
	}
}
