import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { GetMatchRecommendationProfile } from '../types/match-recommendation/GetMatchRecommendationProfile'
import { getMatchRecommendationProfile } from './api/getMatchRecommendationProfile'

export const useMatchRecommendation = (profiles: Array<string>): UseQueryResult<GetMatchRecommendationProfile> => {
	const apiClient = useApi()
	const queryKey = ['getMatchRecommendationProfile']
	const queryFunction = () => getMatchRecommendationProfile(apiClient, profiles)

	return useQuery(queryKey, queryFunction)
}
