import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { updateCurrentProjectAction } from '../actions'
import { BASE_RULE, MatchRuleInfo } from '../types'

export const createOrUpdateMatchConfigTemplate =
	(templateName?: string): AppThunk<Promise<void>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<void> => {
		const currentProject = getState().projectWizard.currentProject
		const matchConfigTemplate = currentProject.currentMatchConfigTemplate
		const currentUser = getState().session.user?.EmailAddress
		const apiClient = getApiClient4Thunks(dispatch)
		const isUpdate = templateName === undefined

		interface MatchConfigFacade {
			matchConfigTemplateId?: string
			matchRuleTemplateId?: string
			matchRuleId?: string
			displayName?: string
			state?: string
			ruleType?: string
			matchKey?: string
			allowedValues?: string[]
			versionId?: number
			exclusionCriterion?: string[]
			acceptCriterion: {
				LowestConfidenceCode?: number
				HighestConfidenceCode?: number
				MatchGradePatterns?: string[]
			}
			archived: boolean
		}

		const mapMatchRuleTemplates = () => {
			return currentProject.matchRules.map((matchRuleInfo) => {
				const matchRule = matchRuleInfo.matchRule
				const acceptCriterion = matchRule.acceptCriterion
				const matchRuleTemplate: MatchConfigFacade = {
					displayName: matchRule.displayName,
					state: matchRule.state,
					ruleType: matchRule.ruleType,
					matchKey: matchRule.matchKey,
					allowedValues: matchRule.allowedValues,
					exclusionCriterion: matchRule.exclusionCriterion,
					acceptCriterion: {
						LowestConfidenceCode: acceptCriterion.LowestConfidenceCode,
						HighestConfidenceCode: acceptCriterion.HighestConfidenceCode,
						MatchGradePatterns: acceptCriterion.MatchGradePatterns
					},
					archived: false
				}
				if (isUpdate) {
					if (matchRuleInfo.matchConfigTemplateId) {
						matchRuleTemplate.matchConfigTemplateId = matchRuleInfo.matchConfigTemplateId
					}
					if (matchRuleInfo.matchRuleTemplateId) {
						matchRuleTemplate.matchRuleTemplateId = matchRuleInfo.matchRuleTemplateId
					}
				}
				return matchRuleTemplate
			})
		}

		const data = {
			displayName: templateName || matchConfigTemplate?.templateName,
			createdBy: currentUser,
			matchRuleTemplates: mapMatchRuleTemplates(),
			archived: false
		}

		const mapRulesWithIds = (
			matchConfigTemplateId: string,
			matchRuleTemplates: MatchConfigFacade[]
		): MatchRuleInfo[] => {
			return currentProject.matchRules.map((matchRuleInfo) => {
				return {
					...matchRuleInfo,
					matchConfigTemplateId,
					matchRuleTemplateId:
						matchRuleInfo.matchRule.ruleType === BASE_RULE
							? matchRuleTemplates.find((matchRuleTemplate) => matchRuleTemplate.ruleType === BASE_RULE)
									?.matchRuleTemplateId
							: matchRuleTemplates.find(
									(matchRuleTemplate) =>
										matchRuleTemplate.ruleType !== BASE_RULE &&
										matchRuleTemplate.displayName === matchRuleInfo.matchRule.displayName
							  )?.matchRuleTemplateId
				}
			})
		}

		return new Promise((resolve, reject) => {
			if (isUpdate) {
				apiClient
					.put(`/pls/match-template/matchConfigTemplateId/${matchConfigTemplate?.templateId}`, {
						...data,
						matchConfigTemplateId: matchConfigTemplate?.templateId
					})
					.then((response) => {
						const matchConfig = response.data
						const matchConfigTemplateId = matchConfig.matchConfigTemplateId
						const templateRules = mapRulesWithIds(matchConfigTemplateId, matchConfig.matchRuleTemplates)
						dispatch(
							updateCurrentProjectAction({
								matchRules: templateRules
							})
						)
						resolve()
					})
					.catch((e) => reject(e))
			} else {
				apiClient
					.post('/pls/match-template/', data)
					.then((response) => {
						const matchConfig = response.data
						const matchConfigTemplateId = matchConfig.matchConfigTemplateId
						const templateRules = mapRulesWithIds(matchConfigTemplateId, matchConfig.matchRuleTemplates)
						dispatch(
							updateCurrentProjectAction({
								matchRules: templateRules,
								currentMatchConfigTemplate: {
									templateId: matchConfigTemplateId,
									templateName: matchConfig.displayName,
									createdBy: matchConfig.createdBy
								}
							})
						)
						resolve()
					})
					.catch((e) => reject(e))
			}
		})
	}
