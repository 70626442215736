import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { ElementUIFacade } from './ElementUIFacade'
import styles from './tree-header.module.scss'

interface TreeHeaderProps {
	element: ElementUIFacade
	showBlockInformation?: boolean
}

export const TreeHeader = ({ element, showBlockInformation }: TreeHeaderProps): ReactElement => {
	const { t } = useTranslation()
	return (
		<>
			{element.blockId && showBlockInformation ? (
				<div className={styles.topLevelBlock}>{element.blockId}</div>
			) : undefined}
			{!element.hideElementDetail ? (
				<div className={styles.topLevelMetadata}>
					{element.level && <b>{t(`data.block.element.selector.level`)}</b>}
					{element.level}
					{element.dataType && (
						<b>
							{t(`data.block.element.selector.type`)}
							{': '}
						</b>
					)}
					{element.dataType}
				</div>
			) : undefined}
			{element.description && (
				<div data-testid="top-element-description" className={styles.topLevelElementDescription}>
					{element.description}
				</div>
			)}
		</>
	)
}
