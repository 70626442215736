import { createTransform } from 'redux-persist'
import { decrypt, encrypt } from '../../helpers'

const connectEncryptionKeyID = 'connectKey'

const localStorage = window['localStorage']
const sessionStorage = window['sessionStorage']

const getEncryptionKey = (storage: 'local' | 'session'): string => {
	let encryptionKey =
		storage === 'local'
			? localStorage.getItem(connectEncryptionKeyID)
			: sessionStorage.getItem(connectEncryptionKeyID)
	if (encryptionKey === null || encryptionKey === '') {
		encryptionKey = window.crypto.getRandomValues(new Uint8Array(16)).toString()
		if (encryptionKey) {
			if (storage === 'local') {
				localStorage.setItem(connectEncryptionKeyID, encryptionKey)
			} else {
				sessionStorage.setItem(connectEncryptionKeyID, encryptionKey)
			}
		}
	}
	return encryptionKey
}

const localStorageKeys = ['projects', 'currentProject']
const sessionStorageKeys: Array<string> = [
	'username',
	'user',
	'tenants',
	'tenant',
	'lastName',
	'firstName',
	'featureFlags',
	'moduleFlags',
	'entitlements',
	'environment',
	'integrationUsername',
	'integrationDomain'
]

const outboundSessionTransform = (outboundState: string) => {
	if (outboundState === undefined) return []
	const decryptedState = decrypt(outboundState, getEncryptionKey('session'))
	if (decryptedState === '') return ''
	return JSON.parse(decryptedState)
}

export const inboundTransform = (inboundState: unknown, storage: 'local' | 'session') => {
	if (inboundState === undefined) {
		return []
	}
	const serializedState = JSON.stringify(inboundState)
	return encrypt(serializedState, getEncryptionKey(storage))
}

export const outboundTransform = (outboundState: string, storage: 'local' | 'session') => {
	if (outboundState === undefined) return {}
	const decryptedState = decrypt(outboundState, getEncryptionKey(storage))
	const deserializedState = JSON.parse(decryptedState)
	return { ...deserializedState }
}

export const LocalTransformer = createTransform<unknown, string, unknown, unknown>(
	// transform state on its way to being serialized and persisted.
	(inboundState: unknown) => {
		return inboundTransform(inboundState, 'local')
	},

	// transform state being rehydrated
	(outboundState: string) => {
		return outboundTransform(outboundState, 'local')
	},
	{ whitelist: localStorageKeys }
)

export const SessionTransformer = createTransform<unknown, string, unknown, unknown>(
	// transform state on its way to being serialized and persisted.
	(inboundState: unknown) => {
		return inboundTransform(inboundState, 'session')
	},

	// transform state being rehydrated
	(outboundState: string) => {
		return outboundSessionTransform(outboundState)
	},
	{ whitelist: sessionStorageKeys }
)
