import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'
import { Dropdown } from '../../local-core-ui'
import { UserDetail } from '../../types'
import { findLocale } from '../language-selector/findLocale'
import styles from './search-n-sort.module.scss'

interface SortUser {
	sortBy: 'name' | 'email'
	sortByLabel: string
	sortAscending: boolean
}

export interface OptionSort {
	label: string
	value: string
}

export interface SearchNSortProps {
	optionsDropdown: Array<OptionSort>
	hintDropdown: string
	labelDropdown?: string
	listToSort?: Array<UserDetail>
	onChangeSort(listToSort: Array<UserDetail>): void
	testId: string
}

export const SearchNSort = ({
	optionsDropdown,
	hintDropdown,
	labelDropdown = '',
	listToSort,
	onChangeSort,
	testId
}: SearchNSortProps): ReactElement => {
	const { t } = useTranslation()
	const SortingDefaults: SortUser = {
		sortBy: 'name',
		sortByLabel: t('search.label.sortByName'),
		sortAscending: true
	}
	const [sortState, setSortState] = useState<SortUser['sortBy']>('name')
	const [sorterUser, setSorterUser] = useState<SortUser>(SortingDefaults)
	const selectedLocale = findLocale(i18n.language)

	useEffect(() => {
		let updateSorterUser = sorterUser
		updateSorterUser = {
			...updateSorterUser,
			sortByLabel: optionsDropdown.find(({ value }) => value === sortState)?.label,
			sortBy: sortState
		}
		setSorterUser(updateSorterUser)
	}, [selectedLocale])
	useEffect(() => {
		if (listToSort) {
			listToSort.sort((a, b) => {
				const userA = getFieldUser(a)
				const userB = getFieldUser(b)
				if (sorterUser.sortAscending) {
					if (userA < userB) {
						return -1
					}
					if (userA > userB) {
						return 1
					}
				} else {
					if (userA > userB) {
						return -1
					}
					if (userA < userB) {
						return 1
					}
				}
				return 0
			})
			onChangeSort(listToSort)
		}
		//Array of dependencies is necessary to preserve the ordering
		// in the different events that update the list
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sorterUser.sortBy, sorterUser.sortAscending, listToSort])

	const getFieldUser = (user: UserDetail) => {
		const noName = t('user.name.noneProvided')
		switch (sorterUser.sortBy) {
			case 'name':
				return user.FirstName === null || user.LastName === null
					? noName
					: (user.FirstName + ' ' + user.LastName).toLowerCase()
			case 'email':
				return user.Email.toLowerCase()
			default:
				return user.FirstName === null || user.LastName === null
					? noName
					: (user.FirstName + ' ' + user.LastName).toLowerCase()
		}
	}

	return (
		<div className={styles.rowSort}>
			<div>
				<Dropdown
					id={'sortDropdown'}
					label={labelDropdown}
					onChangeFunction={(val: SortUser['sortBy']) => {
						setSortState(val)
						let updateSorterUser = sorterUser
						updateSorterUser = {
							...updateSorterUser,
							sortByLabel: optionsDropdown.find(({ value }) => value === val)?.label,
							sortBy: val
						}
						setSorterUser(updateSorterUser)
					}}
					hint={hintDropdown}
					options={optionsDropdown}
					selected={optionsDropdown.find(({ label }) => label === sorterUser.sortByLabel)?.value}
					testId={testId + '-sort'}
				/>
			</div>
		</div>
	)
}
