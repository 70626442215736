import { AxiosInstance } from 'axios'
import { useMemo } from 'react'
import { useAppDispatch } from '../store'
import { getApiClient } from '../utilities'
import { useSession } from './useSession'

// Hook
export function useApi(): AxiosInstance {
	const dispatch = useAppDispatch()
	const [signOutSession] = useSession(dispatch)

	/**
	 * We only want to run this memo when the signOutSession changes.
	 */
	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useMemo(() => getApiClient(signOutSession, dispatch), [signOutSession])
}
