import {
	DNBBanner,
	DNBButton,
	DNBSnackbar,
	DNBTextField,
	DNBToggle,
	DNBTypography,
	theme
} from '@dnb-uux-design-system/react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { isEqual } from 'lodash-es'
import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useApi } from '../../hooks'
import { RouterModalPrompt } from '../router-modal-prompt/router-modal-prompt'
import styles from './data-retention-settings.module.scss'

type RetentionPeriodSettings = {
	retentionPeriodEnabled: boolean
	retentionPeriod: number | null
}

const DataRetentionSettings = (): ReactElement => {
	const { t } = useTranslation()
	const [cancelButtonEnabled, setCancelButtonEnabled] = useState(false)
	const [changesConfirmed, setChangesConfirmed] = useState(false)
	const [displayInfoSnackbar, setDisplayInfoSnackbar] = useState(false)
	const [displaySuccessSnackbar, setDisplaySuccessSnackbar] = useState(false)
	const [hasError, setHasError] = useState(false)
	const [hasPendingChanges, setHasPendingChanges] = useState(false)
	const [saveButtonEnabled, setSaveButtonEnabled] = useState(false)
	const [savedSettings, setSavedSettings] = useState<RetentionPeriodSettings>({
		retentionPeriodEnabled: false,
		retentionPeriod: null
	})
	const [currentSettings, setCurrentSettings] = useState<RetentionPeriodSettings>({
		retentionPeriodEnabled: false,
		retentionPeriod: null
	})
	const [toggleOn, setToggleOn] = useState(false)
	const DEFAULT_RETENTION_PERIOD = 90
	const MIN_DAYS = 1
	const MAX_DAYS = 365
	const apiClient = useApi()
	const urlTenantConfig = '/pls/tenantconfig'
	const urlRetentionPeriod = `${urlTenantConfig}/updateConfig`

	useEffect(() => {
		apiClient.get(urlTenantConfig).then((response) => {
			const data = response.data
			const retentionPeriodEnabled = data.EnableRetentionPeriod ?? false
			const retentionPeriod = data.RetentionPeriod ?? null

			setSavedSettings({
				retentionPeriodEnabled,
				retentionPeriod
			})

			setCurrentSettings({
				retentionPeriodEnabled,
				retentionPeriod
			})
		})
		/**
		 * We only want to get the tenantconfig when the component mounts
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const { retentionPeriodEnabled, retentionPeriod } = currentSettings
		const retentionPeriodInRange =
			retentionPeriod !== null && retentionPeriod >= MIN_DAYS && retentionPeriod <= MAX_DAYS

		setHasPendingChanges(!isEqual(savedSettings, currentSettings))
		setHasError(!retentionPeriodInRange && retentionPeriodEnabled)
		setChangesConfirmed(false)
		setToggleOn(retentionPeriodEnabled)
	}, [currentSettings, savedSettings])

	useEffect(() => {
		setSaveButtonEnabled(hasPendingChanges && !hasError)
		setCancelButtonEnabled(hasPendingChanges)
	}, [hasPendingChanges, hasError])

	const handleChangeInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const newValue = Number(event.target.value)

		if (!isNaN(newValue) && newValue >= 0) {
			setCurrentSettings({
				...currentSettings,
				retentionPeriod: newValue
			})
		}
	}

	const handleChangeToggle = (event: ChangeEvent<HTMLInputElement>) => {
		const isChecked = event.target.checked
		let retentionPeriod = currentSettings.retentionPeriod

		if (isChecked && retentionPeriod === null) {
			retentionPeriod = DEFAULT_RETENTION_PERIOD
		}

		setCurrentSettings({
			retentionPeriod,
			retentionPeriodEnabled: isChecked
		})
	}

	const saveChanges = () => {
		const { retentionPeriodEnabled, retentionPeriod } = currentSettings

		apiClient
			.post(urlRetentionPeriod, {
				enableRetentionPeriod: retentionPeriodEnabled,
				retentionPeriod
			})
			.then()
			.catch((error) => {
				console.error(error)
			})

		setChangesConfirmed(true)
		setDisplaySuccessSnackbar(true)
		setSavedSettings(currentSettings)
	}

	const handleClickSaveChanges = () => {
		saveChanges()
	}

	const handleClickCancelChanges = () => {
		setCurrentSettings(savedSettings)
		setDisplayInfoSnackbar(true)
	}

	return (
		<div className={styles.dataRetentionSettingsContainer}>
			<RouterModalPrompt
				when={hasPendingChanges}
				onSaveChanges={saveChanges}
				disableSaveChangesButton={!saveButtonEnabled}
			/>
			<DNBTypography variant="h4">{t('dashboard.settings.retention.title')}</DNBTypography>
			<DNBTypography variant="h5">{t('dashboard.settings.retention.subtitle')}</DNBTypography>
			{!toggleOn && <DNBBanner severity="info">{t('dashboard.settings.retention.banner')}</DNBBanner>}
			<div className={styles.retentionInputContainer}>
				<div className={styles.inputDescriptionWrapper}>
					<DNBTypography>{t('dashboard.settings.retention.description')}</DNBTypography>
					<div className={styles.buttonsContainer}>
						<DNBButton
							className={styles.button}
							data-testid="data-retention-cancel-button"
							disabled={!cancelButtonEnabled}
							onClick={handleClickCancelChanges}
							size="compact"
							variant="secondary"
						>
							{t('dashboard.settings.retention.cancelChanges.button')}
						</DNBButton>
						<DNBButton
							className={styles.button}
							data-testid="data-retention-confirm-button"
							disabled={!saveButtonEnabled}
							onClick={handleClickSaveChanges}
							size="compact"
						>
							{t('dashboard.settings.retention.button')}
						</DNBButton>
					</div>
				</div>
				<div className={styles.inputContainer}>
					{changesConfirmed && (
						<CheckCircleOutlineIcon sx={{ color: theme.colors.ColorNotificationSuccess }} />
					)}
					{hasError && <ErrorOutlineIcon sx={{ color: theme.colors.ColorNotificationError }} />}
					<div className={styles.topRow}>
						<div className={styles.inputWrapper}>
							<DNBTextField
								disabled={!toggleOn}
								error={hasError}
								onChange={handleChangeInput}
								placeholder="#"
								value={currentSettings.retentionPeriod ?? ''}
							/>
							<DNBTypography>{t('dashboard.settings.retention.period')}</DNBTypography>
						</div>
						{hasError && (
							<DNBTypography variant="caption" className={styles.errorMessage}>
								<Trans
									i18nKey={'dashboard.settings.retention.input.error'}
									values={{ minDays: MIN_DAYS, maxDays: MAX_DAYS }}
								/>
							</DNBTypography>
						)}
					</div>
				</div>
				<DNBToggle checked={toggleOn} onChange={handleChangeToggle} />
			</div>
			<DNBSnackbar
				isOpen={displaySuccessSnackbar}
				handleClose={() => {
					setDisplaySuccessSnackbar(false)
				}}
				hasCloseIcon={false}
				snackbarText={'Data retention period updated successfully'}
				variant="success"
			/>
			<DNBSnackbar
				isOpen={displayInfoSnackbar}
				handleClose={() => {
					setDisplayInfoSnackbar(false)
				}}
				hasCloseIcon={false}
				snackbarText={'Changes cancelled'}
				variant="info"
			/>
		</div>
	)
}

export { DataRetentionSettings }
