export const generateArrayFromMatchRegex = (matchString: string): Array<string> => {
	const matchArrayWithRepetitions: Array<string> = []

	let matchValue = ''
	for (const char of matchString.split('')) {
		if (char === ']' || char === '}') {
			matchValue += char
		} else if (char === '[' || matchValue.includes('[') || char === '{' || matchValue.includes('{')) {
			matchValue += char
			continue
		} else {
			matchValue = char
		}

		matchArrayWithRepetitions.push(matchValue)
		matchValue = ''
	}

	let cleanMatchArray: Array<string> = []
	matchArrayWithRepetitions.forEach((value, index) => {
		if (value.includes('{')) {
			cleanMatchArray = cleanMatchArray.concat(
				new Array(-1 + Number.parseInt(value.replace('{', '').replace('}', ''))).fill(
					matchArrayWithRepetitions[index - 1]
				)
			)
		} else {
			cleanMatchArray.push(value)
		}
	})

	cleanMatchArray.splice(11, 3)
	return cleanMatchArray
}
