import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConnectionStatus, CreateQueryStatus } from '../../queries/api/getC4SActiveConnection'
import { AuthOrigins } from '../session/types/AuthOrigins'

export interface ConnectionState {
	authOrigin: AuthOrigins
	currentConnectionStatus: ConnectionStatus
	createQueryStatus: CreateQueryStatus
	connectionErrors: string[]
	selectedConnectionName: string
	typeConnection: string
	isSelectedSandbox: boolean
}

export const initialState: ConnectionState = {
	//Origin descriptor to keep track of which component initiated the connection creation process
	authOrigin: AuthOrigins.Empty,
	//Status of the connection associated with the current organization, while inside of the iframe
	currentConnectionStatus: ConnectionStatus.Empty,
	//Status of the current connection create request (currently used for Salesforce oauth)
	createQueryStatus: CreateQueryStatus.Empty,
	connectionErrors: [],
	//Name of the connection currently being viewed
	selectedConnectionName: AuthOrigins.Empty,
	typeConnection: '',
	//environment selected between sandbox or production
	isSelectedSandbox: false
}

const connectionSlice = createSlice({
	name: 'connection',
	initialState,
	reducers: {
		setAuthOrigin: (state, action: PayloadAction<AuthOrigins>) => {
			state.authOrigin = action.payload
		},
		clearAuthOrigin: (state) => {
			state.authOrigin = AuthOrigins.Empty
		},
		setCurrentConnectionStatus: (state, action: PayloadAction<ConnectionStatus>) => {
			state.currentConnectionStatus = action.payload
		},
		setCreateQueryStatus: (state, action: PayloadAction<CreateQueryStatus>) => {
			state.createQueryStatus = action.payload
		},
		setConnectionErrors: (state, action: PayloadAction<string[]>) => {
			state.connectionErrors = action.payload
		},
		clearCreateQueryStatus: (state) => {
			state.createQueryStatus = CreateQueryStatus.Empty
			state.connectionErrors = []
		},
		setSelectedConnectionName: (state, action: PayloadAction<string>) => {
			state.selectedConnectionName = action.payload
		},
		clearSelectedConnectionName: (state) => {
			state.selectedConnectionName = AuthOrigins.Empty
		},
		setTypeConnection: (state, action: PayloadAction<string>) => {
			state.typeConnection = action.payload
		},
		clearTypeConnection: (state) => {
			state.typeConnection = ''
		},
		setIsSelectedSandbox: (state, action: PayloadAction<boolean>) => {
			state.isSelectedSandbox = action.payload
		},
		clearIsSelectedSandbox: (state) => {
			state.isSelectedSandbox = false
		},
		clearConnectionSlice: (state) => {
			state.authOrigin = AuthOrigins.Empty
			state.selectedConnectionName = AuthOrigins.Empty
			state.currentConnectionStatus = ConnectionStatus.Empty
			state.createQueryStatus = CreateQueryStatus.Empty
			state.connectionErrors = []
			state.isSelectedSandbox = false
		}
	}
})

// Extract the action creators object and the reducer
const { actions, reducer } = connectionSlice
// Extract and export each action creator by name
export const {
	setAuthOrigin,
	clearAuthOrigin,
	setCurrentConnectionStatus,
	setCreateQueryStatus,
	setConnectionErrors,
	clearCreateQueryStatus,
	setSelectedConnectionName,
	clearSelectedConnectionName,
	setTypeConnection,
	clearTypeConnection,
	setIsSelectedSandbox,
	clearIsSelectedSandbox,
	clearConnectionSlice
} = actions

export default reducer
