import { ColorOrange } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { FC } from 'react'
import styles from './show-hide-section-icon.module.scss'

interface IShowHideSectionIconProps {
	displayedSection: boolean
	onClickSectionIcon(): void
	iconColor?: string
	testId: string
}

export const ShowHideSectionIcon: FC<IShowHideSectionIconProps> = ({
	displayedSection,
	onClickSectionIcon,
	iconColor = ColorOrange,
	testId
}: IShowHideSectionIconProps) => {
	const showIcon = (
		<g>
			<path
				className={styles.showSectionDashboard}
				d="M15,19V4C6.7,4,0,10.7,0,19s6.7,15,15,15s15-6.7,15-15H15z"
				fill={iconColor}
			/>
			<path className={styles.showSectionDashboard} d="M19,0v15h15C34,6.7,27.3,0,19,0" fill={iconColor} />
		</g>
	)

	const hideIcon = (
		<g>
			<path
				className={styles.showSectionDashboard}
				d="M16.3,10.6H24c0-2.2-0.7-4.2-1.8-5.9L16.3,10.6z"
				fill={iconColor}
			/>
			<path
				className={styles.showSectionDashboard}
				d="M23,1.1c-0.3-0.3-0.8-0.3-1.1,0L1.2,21.7c-0.3,0.3-0.3,0.8,0,1.1C1.4,23,1.6,23,1.8,23s0.4-0.1,0.6-0.2L23,2.2
		C23.3,1.9,23.3,1.4,23,1.1z"
				fill={iconColor}
			/>
			<path
				className={styles.showSectionDashboard}
				d="M13.4,0v7.8l5.9-5.9C17.7,0.7,15.6,0,13.4,0z"
				fill={iconColor}
			/>
			<path
				className={styles.showSectionDashboard}
				d="M4.7,22.2c1.7,1.1,3.7,1.8,5.9,1.8c5.8,0,10.6-4.7,10.6-10.6h-7.7L4.7,22.2z"
				fill={iconColor}
			/>
			<path
				className={styles.showSectionDashboard}
				d="M10.6,10.6V2.8C4.7,2.8,0,7.6,0,13.4c0,2.2,0.7,4.2,1.8,5.9L10.6,10.6z"
				fill={iconColor}
			/>
		</g>
	)

	return (
		<div
			className={styles.showHideSectionIconContainer}
			tabIndex={0}
			onClick={onClickSectionIcon}
			onKeyPress={(event) => {
				if (event.key === 'Enter') onClickSectionIcon()
			}}
			data-testid={testId}
		>
			<svg x="0px" y="0px" viewBox={displayedSection ? '0 0 24 24' : '0 0 34 34'} width={24}>
				{displayedSection ? hideIcon : showIcon}
			</svg>
		</div>
	)
}
