import { AxiosResponse } from 'axios'
import { LogOutApiResponse } from '../../../types'
import { getApiClient } from '../../../utilities'
import { AppThunk, TDispatch } from '../../index'
import { terminateSession } from '../actions'
import { SignOutReason } from '../types'

export const signOut =
	(reason?: SignOutReason): AppThunk<Promise<void>> =>
	(dispatch: TDispatch): Promise<void> => {
		const apiClient = getApiClient()
		return apiClient
			.get('/pls/logout?redirectTo=' + window.location.origin + '/logged-out')
			.then((response: AxiosResponse<LogOutApiResponse>) => {
				if (response != null && response.data.Success) {
					dispatch(terminateSession(reason))
					if (response.data.Result !== '') {
						window.location.replace(response.data.Result)
					}
					//terminateGainsightSession()
				}
			})
			.catch(() => {
				dispatch(terminateSession(reason))
				//window.location.replace('/logged-out')
			})
	}
