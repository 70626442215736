import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { ComplianceCodes, getComplianceCodes } from './api/getComplianceCodes'

export const useComplianceCodes = (): UseQueryResult<ComplianceCodes> => {
	const apiClient = useApi()
	const queryKey = ['getComplianceCodes']
	const queryFunction = () => getComplianceCodes(apiClient)

	return useQuery(queryKey, queryFunction)
}
