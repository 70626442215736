import { DNBRadio } from '@dnb-uux-design-system/react'
import { isEmpty } from 'lodash-es'
import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnDefinition } from '../../../../components/collapsable-dropdown-collection-manager/collapsable-dropdown-collection-manager'
import { useApi } from '../../../../hooks'
import { useFeatures } from '../../../../hooks/useEntitlements'
import { Grid, LoadingState } from '../../../../local-core-ui'
import { RootState, TDispatch, useAppDispatch, useAppSelector } from '../../../../store'
import { updateCurrentProjectAction, updateLoadingNextStep } from '../../../../store/projectWizard/actions'
import { getV2Mapping } from '../../../../store/projectWizard/thunks'
import { setEmailDefaultElements } from '../../../../utils/getDefaultElementList'
import { DataMapper } from './data-mapper'
import styles from './email-verification.module.scss'

export function EmailVerification(): ReactElement {
	const { t } = useTranslation()

	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const dispatch: TDispatch = useAppDispatch()
	const [isLoading, setIsLoading] = useState(true)
	const [emailVerification, setEmailVerification] = useState(true)
	const passDelimiterInfo = useFeatures(['EnableFileDelimiters'])
	const EnableInternationalContacts = useFeatures(['InternationalContacts'])
	const apiClient = useApi()

	useEffect(() => {
		let mounted = true
		setIsLoading(true)
		dispatch(updateLoadingNextStep(true))
		dispatch(getV2Mapping(passDelimiterInfo)).then(() => {
			if (mounted) setIsLoading(false)
			dispatch(updateLoadingNextStep(false))
		})
		return () => {
			mounted = false
		}
		/**
		 * We only want to run this effect when the dospatch promise changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch])

	useEffect(() => {
		if (
			isLoading &&
			!isEmpty(
				projectWizardState.currentProject.mappingInfo.mapping?.currentFieldDefinitionsRecord
					.fieldDefinitionsRecordsMap
			)
		) {
			setIsLoading(false)
		}
	}, [
		isLoading,
		projectWizardState.currentProject.mappingInfo.mapping?.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap
	])

	const fieldDefinitionsRecordsMap = {
		...projectWizardState.currentProject.mappingInfo.mapping?.currentFieldDefinitionsRecord
			.fieldDefinitionsRecordsMap
	}
	let columns: Array<ColumnDefinition> = []
	let columnKeys = Object.keys(projectWizardState.currentProject.mappingInfo.mapping.autodetectionResultsMap)
	if (columnKeys.length > 0) {
		columns = columnKeys.map((key) => {
			const autodectectedColumn =
				projectWizardState.currentProject.mappingInfo.mapping.autodetectionResultsMap[key]
			return {
				name: autodectectedColumn.columnName,
				description: autodectectedColumn.screenName
			} as ColumnDefinition
		})
	} else {
		const isC4SSource = projectWizardState.currentProject.source.integrationInfo !== undefined
		columnKeys = Object.keys(projectWizardState.currentProject.mappingInfo.mapping.existingFieldDefinitionsMap)
		// When the BE doesn't send anything we need to check on the currentFieldDefinitionsRecord and extract whatever info
		// we can grab from there. The BE returns the autodetection mostly on C4S, and proper is left alone in the dark, thank
		// you wise BE developer / architect
		if (columnKeys.length > 0) {
			columns = columnKeys.map((key) => {
				const autodectectedColumn =
					projectWizardState.currentProject.mappingInfo.mapping.existingFieldDefinitionsMap[key]
				return {
					name: autodectectedColumn.columnName,
					description: isC4SSource
						? autodectectedColumn.screenName || autodectectedColumn.columnName
						: autodectectedColumn.columnName
				} as ColumnDefinition
			})
		}
	}

	useEffect(() => {
		if (projectWizardState.currentProject.source.enable_email_verification === undefined) {
			dispatch(updateCurrentProjectAction({ source: { enable_email_verification: true } }))
		}
		setEmailDefaultElements(apiClient)
	}, [])

	return (
		<Grid testId="email-container-mapping" container>
			<Grid testId="email-mapping-panel">
				<div data-testid="email-mapping-panel" className={styles.emailMappingPanel}>
					<h1>{t('file.mapping.contact.email.verification')}</h1>
					<span>{t('file.mapping.contact.email.desc')}</span>
					<span>{t('would.you.like.email.verification')}</span>
					<div className={styles.selectionCont}>
						<DNBRadio
							label={'Yes'}
							size="small"
							value={'Yes'}
							checked={projectWizardState.currentProject.source.enable_email_verification === true}
							data-testid={'email-verification-yes'}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								setEmailVerification(true)
								dispatch(updateCurrentProjectAction({ source: { enable_email_verification: true } }))
							}}
						/>
						<DNBRadio
							label={'No'}
							size="small"
							value={'No'}
							checked={projectWizardState.currentProject.source.enable_email_verification === false}
							data-testid={'email-verification-no'}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								setEmailVerification(false)
								dispatch(updateCurrentProjectAction({ source: { enable_email_verification: false } }))
							}}
						/>
					</div>
					{isLoading && (
						<div className={styles.mappingPanelLoader}>
							<LoadingState />
						</div>
					)}

					{projectWizardState.currentProject.source.enable_email_verification === true &&
						(projectWizardState.currentProject.mappingInfo.validationResponse?.fieldValidationMessagesMap?.contactEmail?.find(
							(ele) => ele.messageLevel === 'WARNING'
						) ||
							projectWizardState.currentProject.mappingInfo.validationResponse?.fieldDefinitionsRecordsMap?.contactEmail?.find(
								(ele) => ele.inCurrentImport === false
							)) && (
							<DataMapper
								fieldDefinitionsRecordsMap={fieldDefinitionsRecordsMap}
								fieldValidationMessagesMap={
									projectWizardState.currentProject.mappingInfo.validationResponse
										?.fieldValidationMessagesMap
								}
								columns={columns}
								disabled={projectWizardState.loadingNextStep || isLoading}
								showAmeText={projectWizardState.currentProject.source.enableAme}
								onChangeFunction={(updatedData) => {
									dispatch(
										updateCurrentProjectAction({
											mappingInfo: {
												mapping: {
													...projectWizardState.currentProject.mappingInfo.mapping,
													currentFieldDefinitionsRecord: {
														...projectWizardState.currentProject.mappingInfo.mapping
															.currentFieldDefinitionsRecord,
														fieldDefinitionsRecordsMap: { ...updatedData }
													}
												},
												isDataMappingValidated: false,
												isDataTypesValidated: false
											}
										})
									)
								}}
								isEmailVerificationEnabled={emailVerification}
								isEnabledInternationalContacts={EnableInternationalContacts}
							/>
						)}
				</div>
			</Grid>
		</Grid>
	)
}
