import { useMutation, UseMutationResult } from 'react-query'
import { useApi } from '../hooks'
import { deactivateConnection } from './api/deactivateConnection'

interface DeactivateConnectionParams {
	connectionName: string
}

export const useDeactivateConnection = (): UseMutationResult<unknown, unknown, DeactivateConnectionParams, unknown> => {
	const apiClient = useApi()
	const mutationFn = ({ connectionName }: DeactivateConnectionParams) =>
		deactivateConnection(apiClient, connectionName)

	return useMutation(mutationFn)
}
