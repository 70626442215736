import { ReactElement } from 'react'
import { EnrichmentTemplatePicker } from '../../../../components/enrichment-template-picker/enrichment-template-picker'
import { RootState, useAppSelector } from '../../../../store'

interface TemplatePickerProps {
	onSelect?: () => void
	onClose: () => void
	onTemplatesClear: () => void
}

export function TemplatePicker({ onSelect, onClose, onTemplatesClear }: TemplatePickerProps): ReactElement {
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)

	return (
		<EnrichmentTemplatePicker
			open={true}
			onClose={onClose}
			onSelect={onSelect}
			onTemplatesClear={onTemplatesClear}
			testId="temporal-enrichment-picker"
			purposeOfUse={{
				domain: projectWizardState.currentProject.purposeOfUse.domain,
				recordType: projectWizardState.currentProject.purposeOfUse.recordType
			}}
		/>
	)
}
