import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { ConnectionStatusResponse } from './api/getC4SActiveConnection'
import { getConnectionsStatus } from './api/getConnectionsStatus'

export const useConnectionsStatus = (connectionNames: string[]): UseQueryResult<ConnectionStatusResponse[]> => {
	const apiClient = useApi()
	const queryKey = ['getConnectionsStatus', connectionNames]
	const queryFunction = () => getConnectionsStatus(apiClient, connectionNames)

	return useQuery(queryKey, queryFunction, { enabled: connectionNames.length > 0 })
}
