if (import.meta.env.VITE_DISABLE_GAINSIGHT !== 'true') {
	const urlParams = new URLSearchParams(window.location.search)
	//per gainsight instruction this is inverted on the value
	const platform = JSON.parse(window.sessionStorage.getItem('persist:session'))?.platform || '""'
	const isIFrame = urlParams.get('env') === 'iframe' || platform !== '""' ? false : true

	const enableGainsight = function (n, t, a, e, co) {
		const i = 'aptrinsic'
		;(n[i] =
			n[i] ||
			function () {
				;(n[i].q = n[i].q || []).push(arguments)
			}),
			(n[i].p = e)
		n[i].c = co

		const r = t.createElement('script')
		;(r.async = !0), (r.src = a + '?a=' + e)

		const c = t.getElementsByTagName('script')[0]
		c.parentNode.insertBefore(r, c)
	}

	enableGainsight(
		window,
		document,
		'https://web-sdk.aptrinsic.com/api/aptrinsic.js',
		import.meta.env.VITE_GAINSIGHT_CODE,
		{ fullDomainCookie: true, iframeModeEnabled: isIFrame }
	)
}
