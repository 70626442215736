import { ReactElement } from 'react'
import { OverallStats } from '../../components/overall-stats/overall-stats'
import { ProjectSourceFileManager } from '../../components/project-source-file-manager/project-source-file-manager'
import { useDataReport } from '../../queries/useDataReport'

export function DashboardProjects(): ReactElement {
	const query = useDataReport('Tenant', 'anystring', 'false')

	return (
		<div className="dashboard-projects">
			<OverallStats dataReport={query.data} />
			<ProjectSourceFileManager />
		</div>
	)
}
