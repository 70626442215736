import { ColorHan, ColorLilac } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { DNBDialog } from '@dnb-uux-design-system/react'
import { AxiosResponse } from 'axios'
import { FC, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useApi } from '../../hooks'
import { useFeatures, useModules } from '../../hooks/useEntitlements'
import { Button, Carousel, ContextMenu, Divider, LoadingState, Modal } from '../../local-core-ui'
import { useDeleteSource } from '../../queries/useDeleteSource'
import { useFileSizeLimit } from '../../queries/useFileSizeLimit'
import { useFinalizeRecordsForUpload } from '../../queries/useFinalizeRecordsForUpload'
import { useC4SSourcePackageVersion } from '../../queries/useSFPackageVersion'
import { useSource } from '../../queries/useSource'
import { useUploads } from '../../queries/useUploads'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import { setSFVersion, updateCurrentProjectAction } from '../../store/projectWizard/actions'
import { GetMatchRulesResponse, JobDetailsResponse, PurposeOfUse, Upload, UploadType } from '../../types'
import { EntityType } from '../../types/sources/EntityType'
import { removeFileNameExtension } from '../../utilities'
import { ApiSpecs } from '../apiSpecs/apiSpecs'
import { DashboardComponent } from '../dashboard-component/dashboard-component'
import { FiltersUploadsModal } from '../filters-uploads-modal/filters-uploads-modal'
import { NewFileProcessor } from '../new-file-processor/new-file-processor'
import { S3CredentialsModal } from '../s3-credentials-modal/s3-credentials-modal'
import { ShowHideSectionIcon } from '../show-hide-section-icon/show-hide-section-icon'
import { Snackbar, SnackType } from '../snackbar/snackbar'
import { Spinner } from '../spinner/spinner'
import { UploadStatusDetails } from '../upload-status-details/upload-status-details'
import { UploadSummary } from '../upload-summary/upload-summary'
import { extractStatsFromUpload } from '../upload-tile/extractStatsFromUpload'
import { RelationUploads, UploadTile } from '../upload-tile/upload-tile'
import styles from './source-summary.module.scss'

interface SourceSummaryProps {
	projectId: string
	sourceId: string
	sourceName: string
	entityType?: EntityType
	testId: string
	isApiOnly: boolean
	purposeOfUse: PurposeOfUse | undefined
	EVforCurrentSrc: boolean
	isCRMSource?: boolean
}

export const SourceSummary: FC<SourceSummaryProps> = ({
	projectId,
	sourceId,
	sourceName,
	testId,
	isApiOnly,
	purposeOfUse,
	entityType,
	EVforCurrentSrc,
	isCRMSource
}: SourceSummaryProps) => {
	const { t } = useTranslation()
	const history = useHistory()
	const dispatch = useAppDispatch()

	const [selectedUpload, setSelectedUpload] = useState<Upload>()
	const [displaySourceSummary, setDisplaySourceSummary] = useState(true)
	const [showProcessNewFileModal, setShowProcessNewFileModal] = useState(false)
	const [showEVMaxThresholdModal, setShowEVMaxThresholdModal] = useState(false)
	const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false)
	const [showFiltersUploads, setShowFiltersUploads] = useState(false)
	const [isReadingProjectToStore, setIsReadingProjectToStore] = useState(false)
	const [isEnrichOnlyMode, setIsEnrichOnlyMode] = useState(false)
	const [isMatchRecommendation, setIsMatchRecommendation] = useState(false)
	const [isRefreshingUpload, setIsRefreshingUpload] = useState<Upload>()
	const primaryColor = ColorHan
	const secondaryColor = ColorLilac
	const primaryScssColor = '$color-han'
	const tertiaryScssColor = '$color-han-powder'
	const colorToken = 'ColorHan'
	const [isS3CredentialsModalOpen, setIsS3CredentialsModalOpen] = useState(false)
	const [isAPIDetailsModalOpen, setIsAPIDetailsModalOpen] = useState(false)
	const [showFileSizeLimitModal, setShowFileSizeLimitModal] = useState(false)
	const [tiles2Show, setTiles2Show] = useState<Array<RelationUploads>>([])
	const enableAPI = useFeatures(['EnableAPI'])
	const enableContactApi = useFeatures(['EnableContactAPI'])
	const EnableEmailVerification = useFeatures(['EnableEmailVerification'])
	const EnableEnrichOnly = useFeatures(['EnableEnrichOnly'])
	const EnableInternationalContacts = useFeatures(['InternationalContacts'])
	const enableEnrichedOnlyMixedFile = useFeatures(['EnableEnrichedOnlyMixedFile'])
	const uploadsQuery = useUploads(sourceId)
	const sourceQuery = useSource(sourceId, true)

	const apiClient = useApi()
	const stewardshipEnabled = useModules(['Stewardship'])
	const finalizeUploadMutation = useFinalizeRecordsForUpload()
	const deleteSourceMutation = useDeleteSource(projectId, sourceId)
	const currentVersion = useC4SSourcePackageVersion(sourceId, '', isCRMSource)
	const usageQuery = useFileSizeLimit()
	const fileSizeLimit = usageQuery.data
	const selectSession = (state: RootState) => state.session
	const session = useAppSelector(selectSession)
	const tenant = session.tenant
	const uploadsPerPage = 3
	let mounted = true

	const getOptions = () => {
		const isEntityTypeContact = sourceQuery.data && sourceQuery.data.entityType === 'Contacts'
		const options = sourceId
			? [
					{ label: t('source.context.menu.get.s3.details'), action: 's3Details' },
					{ label: t('source.context.menu.change.mapping'), action: 'changeMapping' },
					{
						label: t('source.context.menu.change.match.rules'),
						action: 'changeMatchRules'
					},
					{
						label: t('source.context.menu.change.edit.data.blocks'),
						action: 'editDataBlocks'
					},
					{ label: t('archive.source.menu'), action: 'archive' }
			  ]
			: [
					{ label: t('archive.source.menu'), action: 'archive' },
					{ label: t('finish.setup'), action: 'finishSetup', disabled: uploadsQuery.isFetching }
			  ]
		if (
			enableAPI &&
			sourceId &&
			(!isEntityTypeContact || (isEntityTypeContact && enableContactApi)) &&
			!isMatchRecommendation
		) {
			options.splice(2, 0, { label: t('getAPIDetails'), action: 'getAPIDetails' })
		}
		if (sourceId && sourceQuery.data) {
			const isAccountSource = sourceQuery.data.integrationInfo?.documentType === 'Account'
			const isLeadSource = sourceQuery.data.integrationInfo?.documentType === 'Lead'
			const isContactSource = sourceQuery.data.integrationInfo?.documentType === 'Contact'
			if (!isAccountSource && !isLeadSource && !isContactSource) {
				if (sourceQuery.data.isFile && !isEntityTypeContact && EnableEnrichOnly) {
					options.unshift(
						{
							label: t('source.process.new.file.match.and.enrich'),
							action: 'processNewFile',
							disabled: false
						},
						{
							label: t('source.process.new.file.only.enrich'),
							action: 'processNewFile2OnlyEnrich',
							disabled: false
						}
					)
				} else
					options.unshift({
						label: t('source.process.new.file'),
						action: 'processNewFile',
						disabled: false
					})
			}
		}
		return options
	}
	const getOptionsApi = () => {
		return [
			{ label: t('getAPIDetails'), action: 'getAPIDetails' },
			{
				label: t('source.context.menu.change.match.rules'),
				action: 'changeMatchRules'
			},
			{
				label: t('source.context.menu.change.edit.data.blocks'),
				action: 'editDataBlocks'
			},
			{ label: t('archive.source.menu'), action: 'archive' }
		]
	}

	const sourceContextMenuOptions = enableAPI && isApiOnly ? getOptionsApi() : getOptions()

	const [s3Pathway, setS3Pathway] = useState('')

	const sourceContextMenuActions = async (action: string) => {
		const isEntityTypeContact = sourceQuery.data && sourceQuery.data.entityType === 'Contacts'
		const isEmailEnabled = sourceQuery.data && sourceQuery.data.enable_email_verification

		switch (action) {
			case 'pause':
				pauseSource()
				break
			case 'processNewFile':
				isEntityTypeContact && !EnableEmailVerification && isEmailEnabled
					? setShowEVMaxThresholdModal(true)
					: setShowProcessNewFileModal(true)
				break
			case 's3Details':
				setIsS3CredentialsModalOpen(true)
				break
			case 'changeMapping':
				setIsReadingProjectToStore(true)
				history.push(`/steps/project-wizard/mapping/define?projectId=${projectId}&sourceId=${sourceId}`)
				break
			case 'changeMatchRules':
				setIsReadingProjectToStore(true)
				history.push(`/steps/project-wizard/matching/summary?projectId=${projectId}&sourceId=${sourceId}`)
				break
			case 'editDataBlocks':
				dispatch(
					updateCurrentProjectAction({
						source: {
							company_match_type: isMatchRecommendation ? 'MatchRecommendation' : 'Traditional'
						}
					})
				)
				history.push(`/steps/project-wizard/enrichment/output?projectId=${projectId}&sourceId=${sourceId}`)
				setIsReadingProjectToStore(true)
				await dispatch(setSFVersion(currentVersion.data?.version || ''))
				break
			case 'getAPIDetails':
				setIsAPIDetailsModalOpen(true)
				break
			case 'finishSetup':
				setIsReadingProjectToStore(true)
				history.push(`/steps/project-wizard/lastStep?projectId=${projectId}&sourceId=${sourceId}`)
				break
			case 'configureBatchFiles':
				setIsReadingProjectToStore(true)
				history.push(`/steps/project-wizard/select-data?projectId=${projectId}&sourceId=${sourceId}`)
				break
			case 'archive':
				setShowArchiveConfirmation(true)
				break
			case 'processNewFile2OnlyEnrich':
				setIsEnrichOnlyMode(true)
				setShowProcessNewFileModal(true)
				break
			default:
				break
		}
	}

	const pauseSource = () => {
		const url = `/pls/sources/sourceId/${sourceId}/pause`
		apiClient.put(url).then((response: AxiosResponse) => {
			if (response.status !== 200) {
				console.log('Could not pause source ', sourceId)
			}
		})
	}

	const handleOnModalClose = () => {
		setIsS3CredentialsModalOpen(false)
	}

	const getUploads = (): void => {
		//TODO:  change pageSize when BE is prepared to pagination
		// TODO The following code should be removed when BE supports downloading files for old projects
		// or when old project are not required to download files anymore
		// This code is necessary because old projects do not support the downloadableFiles property
		// in the uploadConfig object, so we need to get what files can we download in the OLD way
		// starts code to get downloadable files for old projects
		if (uploadsQuery.data) {
			for (const upload of uploadsQuery.data) {
				const wasSubmitted = upload.uploadConfig?.hasOwnProperty('uploadStats')

				if (
					upload.status !== 'NEW' &&
					!upload.uploadConfig?.hasOwnProperty('downloadableFiles') &&
					wasSubmitted &&
					mounted
				) {
					apiClient
						.get(`/pls/uploads/uploadId/${upload.uploadId}/jobDetails`)
						.then((jobResponse: AxiosResponse<JobDetailsResponse>) => {
							const jobDetails = jobResponse.data
							const downloadableFiles = ['RAW']

							if (jobDetails.uploadStats?.import.failedIngested !== 0) {
								downloadableFiles.push('IMPORT_ERRORS')
							}
							if (jobDetails.status !== 'ERROR') {
								if (jobDetails.uploadStats?.match?.matched !== 0) {
									downloadableFiles.push('MATCHED')
								}
								if (jobDetails.uploadStats?.match?.unmatched !== 0) {
									downloadableFiles.push('UNMATCHED')
								}
							}

							if (upload.uploadConfig) {
								upload.uploadConfig.downloadableFiles = downloadableFiles
							} else {
								upload.uploadConfig = { downloadableFiles: downloadableFiles }
							}
						})
				}
			}
			// ends code to get downloadable files for old projects
		}
	}

	const handleRefresh = (upload: Upload, originalUpload?: Upload) => {
		setSelectedUpload(upload)
		const { uploadStats } = originalUpload ? originalUpload : upload
		const submittedRows = uploadStats.import?.submitted ?? 0
		const fileLimitExceeded = fileSizeLimit && fileSizeLimit !== -1 && submittedRows > fileSizeLimit

		if (fileLimitExceeded) {
			setShowFileSizeLimitModal(true)
		} else {
			refreshData(originalUpload ? originalUpload : upload)
		}
	}

	const refreshData = (upload?: Upload) => {
		if (upload) {
			const { uploadId } = upload
			const url = `/pls/uploads/startimport`
			const request = {
				project_id: projectId,
				source_id: sourceId,
				uploadId: uploadId,
				uploadType: UploadType.UPLOAD_REFRESH
			}
			setIsRefreshingUpload(upload)
			setTimeout(() => {
				setIsRefreshingUpload(undefined)
			}, 10000)

			apiClient
				.post(url, request)
				.then((response) => {
					const newUpload = response.data
					const idxGroupRefresh = tiles2Show.findIndex(
						(tilesGroup) => tilesGroup.original.uploadId === upload.uploadId
					)
					if (idxGroupRefresh > -1) {
						const copyTiles: Array<RelationUploads> = JSON.parse(JSON.stringify(tiles2Show))
						if (copyTiles[idxGroupRefresh].versions === undefined) {
							copyTiles[idxGroupRefresh].versions = new Array<Upload>(newUpload)
						} else copyTiles[idxGroupRefresh].versions?.unshift(newUpload)
						copyTiles[idxGroupRefresh].selectedUploadVersion = newUpload
						setSelectedUpload(newUpload)
						setTiles2Show([...copyTiles])
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}
	}

	const handleReport = (uploadId: string) => {
		history.push(`/report?tenant=${tenant}&uploadId=${uploadId}`)
	}

	useEffect(() => {
		const url = `/pls/matchrules/sourceId/${sourceId}?includeArchived=false&includeInactive=false`

		apiClient.get(url).then((response: AxiosResponse<Array<GetMatchRulesResponse>>) => {
			const sourceRules = response.data
			if (sourceRules.length) {
				setIsMatchRecommendation(sourceRules[0].matchStyle === 'MATCH_RECOMMENDATION')
				localStorage.setItem(
					'company_match_type',
					isMatchRecommendation ? 'MatchRecommendation' : 'Traditional'
				)
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceId])

	useEffect(() => {
		if (uploadsQuery.isFetched && uploadsQuery.isSuccess) getUploads()
		calculateTiles2show()
		// This should be executed only once and I can't include the definitions within
		// useEffect since apiClient is a hook, hence I'm disabling following warning
		// eslint-disable-next-line react-hooks/exhaustive-deps
		return () => {
			/**
			 * TODO: evaluate if it is possible to store "mounted" in a "useRef" hook since it is used outside of the
			 * useEffect and for each render its value is lost.
			 */
			// eslint-disable-next-line react-hooks/exhaustive-deps
			mounted = false
		}
		/**
		 * We only want to run this effect when sourceId or the flag isFetching from uploadsQuery changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceId, uploadsQuery.isFetching])

	useEffect(() => {
		if (uploadsQuery.data && uploadsQuery.data.length > 0) {
			const data = uploadsQuery.data[0]
			if (data?.type === UploadType.REFRESH) {
				setSelectedUpload(undefined)
			} else {
				setSelectedUpload(uploadsQuery.data[0])
			}
		}
		/**
		 * We only want to run this effect when the flag isFetching from uploadsQuery changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadsQuery.isFetching])

	const getUploadSummaryTitle = (): string => {
		const filteredUploadList: Array<Upload> = tiles2Show.reduce((filteredUploadList: Array<Upload>, tiles) => {
			if (tiles.original.uploadId === selectedUpload?.uploadId) {
				filteredUploadList.push(tiles.original)
			} else {
				const filterVersion = tiles.versions?.filter(
					(versionUpload) => versionUpload.uploadId === selectedUpload?.uploadId
				)
				if (filterVersion && filterVersion.length > 0) {
					filteredUploadList.push(filterVersion[0])
				}
			}
			return filteredUploadList
		}, [])

		return filteredUploadList && filteredUploadList.length > 0 ? filteredUploadList[0].displayName : ''
	}

	useEffect(() => {
		if (sourceQuery.isFetched && sourceQuery.data?.sourceFullPath) {
			setS3Pathway(sourceQuery.data?.sourceFullPath)
		}
		/**
		 * We only want to run this effect when the flag isFetching from sourceQuery or sourceId or projectId props changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectId, sourceId, sourceQuery.isFetching])

	const calculateTiles2show = () => {
		if (uploadsQuery.data) {
			const tiles2ShowTemp = uploadsQuery.data.reduce(
				(tiles2ShowTemp: Array<RelationUploads>, upload: Upload) => {
					const relationUpload: RelationUploads = {}
					if (!upload.uploadConfig?.initialUploadId) {
						relationUpload.original = upload
						const versions = uploadsQuery.data.filter(
							(uploadData) => upload.uploadId === uploadData.uploadConfig?.initialUploadId
						)
						if (versions.length) {
							relationUpload.versions = versions.sort((a, b) => b.uploadCreatedTime - a.uploadCreatedTime)
							relationUpload.selectedUploadVersion = versions[0]
						} else relationUpload.selectedUploadVersion = upload
						tiles2ShowTemp.push(relationUpload)
					}
					return tiles2ShowTemp
				},
				[]
			)
			tiles2ShowTemp.sort((a: RelationUploads, b: RelationUploads) => {
				const lastVersionA = a.versions?.length ? a.versions[0] : a.original
				const lastVersionB = b.versions?.length ? b.versions[0] : b.original
				return lastVersionB.uploadCreatedTime - lastVersionA.uploadCreatedTime
			})
			setTiles2Show(tiles2ShowTemp || [])
		}
	}

	const generateCarouselPages = () => {
		const carouselPages = Math.ceil(tiles2Show?.length ? tiles2Show.length / uploadsPerPage : 0)
		const generatedComponent: Array<JSX.Element> = []
		const integrationType = sourceQuery.data?.integrationInfo?.integrationType
		for (
			let pageIndex = 0, uploadIndex = 0;
			pageIndex < carouselPages && mounted;
			pageIndex++, uploadIndex += uploadsPerPage
		) {
			const tilesToDisplay = tiles2Show
				?.slice(uploadIndex, uploadIndex + uploadsPerPage)
				.filter((tile2Show: RelationUploads) => {
					if (
						(tile2Show.original.uploadConfiguration?.isApiAggregation && enableAPI) ||
						!tile2Show.original.uploadConfiguration?.isApiAggregation ||
						(integrationType === 'Salesforce' && tile2Show.original.type !== UploadType.UPLOAD_REFRESH)
					) {
						return tile2Show
					}
				})
			generatedComponent.push(
				<div className={styles.blockCarouselPage} key={`block-carousel-page-${pageIndex}`}>
					{tilesToDisplay?.map((upload: RelationUploads, index: number) => {
						let uploadCurrent: Upload = upload.original
						if (upload.versions !== undefined) {
							const versionSelected = upload.versions?.filter(
								(version) => selectedUpload?.uploadId === version.uploadId
							)[0]
							if (versionSelected) {
								uploadCurrent = versionSelected
							} else if (upload.original.uploadId !== selectedUpload?.uploadId) {
								uploadCurrent = upload.selectedUploadVersion
							}
						}
						const {
							displayName,
							isApiAggregation,
							type,
							uploadConfig,
							uploadCreatedTime,
							uploadDiagnostics,
							uploadId
						} = uploadCurrent

						return (
							<div key={'upload-' + pageIndex + '-' + index}>
								<UploadTile
									upload={uploadCurrent}
									key={'source-upload-' + displayName + '-' + pageIndex + '-' + index}
									errorMessageUpload={
										uploadDiagnostics.lastErrorMessage
											? JSON.parse(uploadDiagnostics.lastErrorMessage).errorMsg
											: ''
									}
									stats={extractStatsFromUpload(uploadCurrent, t)}
									displayedSection={uploadId === selectedUpload?.uploadId}
									onClickSelection={() => {
										if (type === UploadType.REFRESH) {
											setSelectedUpload(() => undefined)
										} else {
											setSelectedUpload(() =>
												uploadId === selectedUpload?.uploadId ? undefined : uploadCurrent
											)
										}
									}}
									onClickUpdateNow={() => finalizeUploadMutation.mutate(uploadId)}
									s3Pathway={s3Pathway}
									downloadableFiles={uploadConfig?.downloadableFiles}
									uploadApplicationId={uploadDiagnostics.applicationId}
									testId={'upload-tile-' + pageIndex + '-' + index}
									createdDate={uploadCreatedTime}
									showContextMenu={!isApiOnly && !isApiAggregation}
									isApiBased={isApiAggregation}
									showUpdateNowButton={stewardshipEnabled}
									showNotice={EnableInternationalContacts && entityType === EntityType.CONTACTS}
									sourceEntityType={entityType}
									sourceIntegrationType={sourceQuery.data?.integrationInfo?.integrationType}
									stewardshipDisabled={
										!(uploadCurrent.readyToSteward && sourceQuery.data?.stewardable)
									}
									expirationDate={uploadCurrent.expires}
									onClickRefresh={handleRefresh}
									onClickReport={handleReport}
									relationVersions={upload}
									onClickVersion={(onVersion: Upload) => {
										setSelectedUpload(onVersion)
										const idxChangeVersion = tiles2Show.findIndex(
											(tileRelated) =>
												tileRelated.original.uploadId === onVersion.uploadId ||
												tileRelated.versions?.filter((version) => {
													return version.uploadId === onVersion.uploadId
												}).length
										)
										const copyTiles: Array<RelationUploads> = JSON.parse(JSON.stringify(tiles2Show))
										copyTiles[idxChangeVersion].selectedUploadVersion = onVersion
										setTiles2Show([...copyTiles])
									}}
								/>
							</div>
						)
					})}
				</div>
			)
		}
		return generatedComponent
	}

	const openUploadsModal = () => {
		setShowFiltersUploads(true)
	}

	return (
		<div data-testid="source-summary" className={styles.sourceSummary}>
			{showProcessNewFileModal && (
				<NewFileProcessor
					title={sourceName}
					projectId={projectId}
					sourceId={sourceId}
					onClose={(existNewUpload: boolean) => {
						setShowProcessNewFileModal(false)
						setIsEnrichOnlyMode(false)
						if (existNewUpload) {
							getUploads()
						}
					}}
					open={showProcessNewFileModal}
					testId={testId + '-nfp'}
					purposeOfUse={purposeOfUse}
					withOptionEnrichOnly={isEnrichOnlyMode}
					isModeMixedFileActivated={
						sourceQuery.data &&
						sourceQuery.data.entityType !== EntityType.CONTACTS &&
						enableEnrichedOnlyMixedFile
					}
				/>
			)}

			<Modal
				open={showFiltersUploads && !showFileSizeLimitModal}
				onClose={() => {
					setShowFiltersUploads(false)
				}}
				disableBackdropFilter
				testId="FilterUploadsModal"
			>
				<FiltersUploadsModal
					totalUploads={tiles2Show.length || 0}
					sourceId={sourceId}
					sourceName={sourceName}
					uploadsList={tiles2Show || []}
					onClickRefresh={handleRefresh}
					onClose={() => {
						setShowFiltersUploads(false)
					}}
					s3Pathway={s3Pathway}
					selectedUpload={(upload: Upload) => {
						setSelectedUpload(upload)
					}}
					showMenuTiles={!isApiOnly}
					sourceEntityType={entityType}
					sourceIntegrationType={sourceQuery.data?.integrationInfo?.integrationType}
					testId={'upload-modal-in-summary'}
				/>
			</Modal>
			<Modal
				isContainer={true}
				open={showArchiveConfirmation}
				onClose={() => {
					setShowArchiveConfirmation(false)
				}}
				testId="ProjectBarModal"
			>
				<div className={styles.archiveModal}>
					<div className={styles.archiveModalText}>{t('archive.source.warning')}</div>
					<div className={styles.archiveModalButton}>
						<Button
							text={t('archive.source.button')}
							type="primary"
							size="small"
							onClick={() => {
								deleteSourceMutation.mutate()
								setShowArchiveConfirmation(false)
							}}
							testId={testId + '-archive'}
						/>
					</div>
				</div>
			</Modal>
			<Modal
				isContainer={true}
				open={showEVMaxThresholdModal}
				onClose={() => {
					setShowEVMaxThresholdModal(false)
				}}
				testId="EVMaxThresholdModel"
			>
				<div className={styles.archiveModal}>
					<div className={styles.archiveModalText}>{t('emailverification.maxthresold.warning')}</div>
					<div className={styles.archiveModalButton} />
				</div>
			</Modal>
			<S3CredentialsModal
				projectId={projectId}
				open={isS3CredentialsModalOpen}
				onModalClose={handleOnModalClose}
				sourceId={sourceId}
				testId={testId + '-s3-creds'}
			/>
			<Modal
				isContainer={true}
				open={isAPIDetailsModalOpen}
				onClose={() => setIsAPIDetailsModalOpen(false)}
				testId="APISpecsModal"
			>
				<ApiSpecs sourceName={sourceName} sourceId={sourceId} />
			</Modal>
			<DNBDialog
				hasCloseButton
				onClose={() => setShowFileSizeLimitModal(false)}
				open={showFileSizeLimitModal}
				primaryCTA={() => {
					refreshData(selectedUpload)
					setShowFileSizeLimitModal(false)
				}}
				primaryCTALabel={t('upload.chooser.modal.recordLimit.button.continue') ?? ''}
				secondaryCTA={() => setShowFileSizeLimitModal(false)}
				secondaryCTALabel={t('upload.chooser.modal.recordLimit.button.cancel') ?? ''}
			>
				<div className={styles.recordLimitModal}>
					<p className={styles.title}>{t('upload.chooser.modal.recordLimit.title')}</p>

					<p className={styles.description}>
						<Trans
							i18nKey="upload.chooser.modal.recordLimit.description"
							tOptions={{ recordLimit: fileSizeLimit?.toLocaleString() }}
						/>
					</p>
				</div>
			</DNBDialog>
			<div data-testid="sources-list-toolbar" className={styles.sourcesListToolbar}>
				{isReadingProjectToStore && (
					<div className={styles.loadingStepSpinner}>
						<Spinner />
					</div>
				)}
				<ShowHideSectionIcon
					iconColor={ColorHan}
					displayedSection={displaySourceSummary}
					onClickSectionIcon={() => setDisplaySourceSummary(!displaySourceSummary)}
					testId={testId + '-show-hide'}
				/>
				{uploadsQuery.isFetched && (
					<ContextMenu
						key={sourceId}
						options={sourceContextMenuOptions}
						onMenuClicked={sourceContextMenuActions}
						alignment="left"
						testId={testId + '-menu'}
					/>
				)}
			</div>
			{displaySourceSummary && (
				<>
					<DashboardComponent
						mode="panels"
						level="Source"
						ownerId={sourceId}
						projectId={projectId}
						primaryColor={primaryColor}
						secondaryColor={secondaryColor}
						primaryScssColor={primaryScssColor}
						tertiaryScssColor={tertiaryScssColor}
						colorToken={colorToken}
						hasUploads={uploadsQuery.data ? uploadsQuery.data.length > 0 : false}
						hasErrors={uploadsQuery.data?.find((u: Upload) => u.status === 'ERROR') !== undefined}
						testId={testId + '-dbc-source'}
						showContactGraphs={entityType === EntityType.CONTACTS}
						EVforCurrentSrc={EVforCurrentSrc}
					/>
					<Divider />
				</>
			)}
			<div className={styles.headerSubSectionUploads}>
				<h2>{t('title.section.input.data')}</h2>
				<div className={styles.textLinkModal}>
					<div data-testid="show-all-uploads" onClick={() => openUploadsModal()}>
						{t('link.show.all.uploads', { counterUploads: tiles2Show.length })}
					</div>
				</div>
			</div>
			<div className={styles.uploadsListContainer}>
				{uploadsQuery.isFetching ? (
					<div className={styles.loadingUploads}>
						<LoadingState />
					</div>
				) : uploadsQuery.data && uploadsQuery.data?.length > 0 ? (
					<Carousel
						id={'UploadsCarousel'}
						colors={{ selected: 'ColorScaleOrange' }}
						testId={testId + '-uploads-carousel'}
						alignItems={'left'}
					>
						{generateCarouselPages()}
					</Carousel>
				) : (
					t('no.uploads')
				)}
			</div>
			{isRefreshingUpload !== undefined && (
				<div className={styles.successMessage}>
					<Snackbar
						title={`${removeFileNameExtension(isRefreshingUpload.displayName)} ${t(
							'snackbar.message.refresh.upload'
						)}`}
						type={SnackType.success}
					/>
				</div>
			)}
			{selectedUpload && (
				<UploadSummary
					key={'summary-' + selectedUpload.uploadId}
					title={getUploadSummaryTitle()}
					uploadId={selectedUpload.uploadId}
					status={selectedUpload.status}
					testId={testId + '-upload-summary'}
					entityType={entityType}
					sourceWithEV={sourceQuery.data?.enable_email_verification || false}
				/>
			)}
			{selectedUpload && (
				<UploadStatusDetails
					sourceId={sourceId}
					key={'statusDetails-' + selectedUpload.uploadId}
					upload={selectedUpload}
					s3Pathway={s3Pathway}
					downloadableFiles={selectedUpload.uploadConfig?.downloadableFiles}
					testId={testId + '-upload-status'}
				/>
			)}
		</div>
	)
}
