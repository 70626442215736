import { AxiosResponse } from 'axios'
import { CreateProjectRequest, CreateProjectResponse, PurposeOfUse, Team } from '../../../types'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { updateCurrentProjectAction, updateLoadingNextStep } from '../actions'
import { updateTeam } from './updateTeam'

export const createProject =
	(): AppThunk<Promise<void>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<void> => {
		dispatch(updateLoadingNextStep(true))
		const currentProject = getState().projectWizard.currentProject
		const url = '/pls/projects'
		const purposeOfUse: PurposeOfUse = {
			domain: currentProject.purposeOfUse.domain,
			recordType: currentProject.purposeOfUse.recordType
		}
		const createProjectRequest: CreateProjectRequest = {
			displayName: currentProject.name,
			projectDescription: currentProject.projectDescription,
			projectType: currentProject.type,
			purposeOfUse: purposeOfUse
		}
		const apiClient = getApiClient4Thunks(dispatch)
		return apiClient
			.post(url, createProjectRequest)
			.then(async (response: AxiosResponse<CreateProjectResponse>) => {
				const responseProject = response.data
				if (responseProject.projectId) {
					let team: Team = { TeamId: '', TeamName: '', TeamMembers: [] }
					if (responseProject.teamId !== undefined) {
						const teamMembers = getState().projectWizard.currentProject.team.TeamMembers.map(
							(teamMember) => teamMember.Email
						)
						team = await updateTeam(responseProject.teamId, teamMembers, apiClient)
					}
					dispatch(
						updateCurrentProjectAction({
							id: responseProject.projectId,
							team: team,
							purposeOfUse: responseProject.purposeOfUse
						})
					)
				}
				dispatch(updateLoadingNextStep(false))
			})
			.catch((error) => {
				console.log(error)
				dispatch(updateLoadingNextStep(false))
				throw error
			})
	}
