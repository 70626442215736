import { ColorGrayDark } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import informationIcon from '../../assets/images/info.svg'
import { Button, CheckBox, Divider, Modal } from '../../local-core-ui'
import styles from './files-download-modal.module.scss'
import { IFilesDownloadModalProps } from './IFilesDownloadModalProps'

export const FilesDownloadModal = ({
	isApiBased,
	displayFilesDownloadModal,
	setDisplayFilesDownloadModal,
	onDownloadButtonClick,
	downloadableFiles = [],
	testId,
	salesforceSource,
	showNotice = false,
	enrichOnly
}: IFilesDownloadModalProps): ReactElement => {
	type FileTypes =
		| 'matchedEnriched'
		| 'notMatched'
		| 'inputFile'
		| 'ingestionErrorFile'
		| 'processingErrorFile'
		| 'publishingErrorFile'
		| 'fullOutput'

	type FileTypesLabels = {
		[key in FileTypes]: {
			name: string
			beKey: string
		}
	}

	type FileTypesCheckBoxes = {
		[key in FileTypes]: boolean
	}

	const { t } = useTranslation()

	const [disabledDownloadButton, setDisabledDownloadButton] = useState<boolean>(false)

	const fileTypes: FileTypesLabels = {
		matchedEnriched: {
			name: enrichOnly ? t('upload.tile.matched.enriched.and_or') : t('upload.tile.matched.enriched.and'),
			beKey: 'MATCHED'
		},
		notMatched: {
			name: t('upload.tile.not.matched'),
			beKey: 'UNMATCHED'
		},
		inputFile: {
			name: t('upload.tile.input.file'),
			beKey: 'RAW'
		},
		ingestionErrorFile: {
			name: t('upload.tile.ingestion.error.file'),
			beKey: 'IMPORT_ERRORS'
		},
		processingErrorFile: {
			name: t('upload.tile.processing.error.file'),
			beKey: 'PROCESS_ERRORS'
		},
		publishingErrorFile: {
			name: t('upload.tile.publishing.error.file'),
			beKey: 'PUBLISH_ERRORS'
		},
		fullOutput: {
			name: t('upload.tile.full.output.file'),
			beKey: 'CONSOLIDATED'
		}
	}
	const ingestionErrorFileDisabled = !downloadableFiles.includes(fileTypes.ingestionErrorFile.beKey)
	const processingErrorFileDisabled = !downloadableFiles.includes(fileTypes.processingErrorFile.beKey)
	const matchedEnrichedFileDisabled = !downloadableFiles.includes(fileTypes.matchedEnriched.beKey)
	const notMatchedFileDisabled = !downloadableFiles.includes(fileTypes.notMatched.beKey)
	const inputFileDisabled = !downloadableFiles.includes(fileTypes.inputFile.beKey)
	const publishingErrorFileDisabled = !downloadableFiles.includes(fileTypes.publishingErrorFile.beKey)
	const fullOutputFileDisabled = !downloadableFiles.includes(fileTypes.fullOutput.beKey)

	const [fileTypesSelected, setFileTypesSelected] = useState<FileTypesCheckBoxes>({
		fullOutput: false,
		matchedEnriched: false,
		notMatched: false,
		inputFile: false,
		ingestionErrorFile: false,
		processingErrorFile: false,
		publishingErrorFile: false
	})

	const isNothingSelected = (filesSelected: FileTypesCheckBoxes): boolean => {
		let nothingSelected = true
		let key: FileTypes
		for (key in filesSelected) {
			if (filesSelected.hasOwnProperty(key) && filesSelected[key]) {
				nothingSelected = false
				break
			}
		}
		return nothingSelected
	}

	const initializeCheckboxes = () => {
		setFileTypesSelected({
			fullOutput: !fullOutputFileDisabled,
			matchedEnriched: !matchedEnrichedFileDisabled && fullOutputFileDisabled,
			notMatched: !notMatchedFileDisabled && fullOutputFileDisabled,
			inputFile: !inputFileDisabled && fullOutputFileDisabled,
			ingestionErrorFile: !ingestionErrorFileDisabled && fullOutputFileDisabled,
			processingErrorFile: !processingErrorFileDisabled && fullOutputFileDisabled,
			publishingErrorFile: !publishingErrorFileDisabled && fullOutputFileDisabled
		})
	}

	const onFileTypeSelected = (event: ChangeEvent<HTMLInputElement>, fileTypeName: FileTypes) => {
		const newFilesSelected: FileTypesCheckBoxes = {
			...fileTypesSelected,
			[fileTypeName]: event.target.checked
		}
		setDisabledDownloadButton(isNothingSelected(newFilesSelected))
		setFileTypesSelected(newFilesSelected)
	}

	const onDownloadDataClick = () => {
		setDisplayFilesDownloadModal(false)
		const files = []
		let key: FileTypes
		for (key in fileTypesSelected) {
			if (fileTypesSelected.hasOwnProperty(key) && fileTypesSelected[key]) {
				files.push(fileTypes[key].beKey)
			}
		}
		onDownloadButtonClick(files.join(','))
	}

	const disableDownloadButtonOnStart = () => {
		if (isNothingSelected(fileTypesSelected)) {
			setDisabledDownloadButton(true)
		} else {
			setDisabledDownloadButton(false)
		}
	}

	useEffect(() => {
		disableDownloadButtonOnStart()
		// We need to make the api call only when the component did mount to see which files don't exist and then disable the checkbox
		// If I add disableDownloadButtonOnStart to the dependencies then the api will be called an unnecessary number of times
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fileTypesSelected])

	useEffect(() => {
		initializeCheckboxes()
	}, [downloadableFiles?.length])

	return (
		<div className={styles.filesDownloadModalContainer}>
			<Modal
				open={displayFilesDownloadModal}
				isContainer={true}
				onClose={() => setDisplayFilesDownloadModal(false)}
				testId="FilesDownloadModal"
			>
				<div className={styles.uploadTileDownloadModalContentContainer}>
					<h4 className={styles.uploadTileDownloadModalContentTitle}>{t('upload.tile.modal.title')}</h4>

					{showNotice && (
						<div className={styles.notice}>
							<img className={styles.informationIcon} src={informationIcon} alt={'information'} />
							<div className={styles.noticeText}>
								<Trans i18nKey={'notice.use.contact.text'}>
									<span />
									<Link className={styles.noticeLink} to="/compliance-insights" replace={true}>
										D&B Compliance Insights
									</Link>
								</Trans>
							</div>
						</div>
					)}
					<div
						data-testid="download-full-output"
						className={styles.uploadTileDownloadModalContentCheckBoxesContainer}
					>
						<div className={styles.uploadTileDownloadModalContentCheckBoxesGroup}>
							<div className={styles.uploadTileDownloadModalContentCheckBox}>
								<CheckBox
									id={fileTypes.fullOutput.name}
									value={fileTypes.fullOutput.name}
									label={fileTypes.fullOutput.name}
									checked={fileTypesSelected.fullOutput}
									onChange={(e) => onFileTypeSelected(e, 'fullOutput')}
									disabled={fullOutputFileDisabled}
									testId={testId + '-cb-full-output'}
								/>
								<p className={styles.uploadTileDownloadModalContentCheckboxMessage}>
									{t('upload.tile.full.output.file.description')}
								</p>
							</div>
						</div>
					</div>
					{!isApiBased ? (
						<>
							<div className={styles.dividerContainer}>
								<Divider color={ColorGrayDark} />
							</div>
							<div
								data-testid="download-files-correct"
								className={styles.uploadTileDownloadModalContentCheckBoxesContainer}
							>
								<div className={styles.uploadTileDownloadModalContentCheckBoxesGroup}>
									<div className={styles.uploadTileDownloadModalContentCheckBox}>
										<CheckBox
											id={fileTypes.inputFile.name}
											value={fileTypes.inputFile.name}
											label={fileTypes.inputFile.name}
											checked={fileTypesSelected.inputFile}
											onChange={(e) => onFileTypeSelected(e, 'inputFile')}
											disabled={inputFileDisabled}
											testId={testId + '-input-file'}
										/>
									</div>
									<div className={styles.uploadTileDownloadModalContentCheckBox}>
										<CheckBox
											id={fileTypes.notMatched.name}
											value={fileTypes.notMatched.name}
											label={fileTypes.notMatched.name}
											checked={fileTypesSelected.notMatched}
											onChange={(e) => onFileTypeSelected(e, 'notMatched')}
											disabled={notMatchedFileDisabled}
											testId={testId + '-cb-not-matched'}
										/>
										{notMatchedFileDisabled && (
											<p className={styles.uploadTileDownloadModalContentCheckboxDisabledMessage}>
												{t('upload.tile.not.matched.file.disabled')}
											</p>
										)}
									</div>
									<div className={styles.uploadTileDownloadModalContentCheckBox}>
										<CheckBox
											id={fileTypes.matchedEnriched.name}
											value={fileTypes.matchedEnriched.name}
											label={fileTypes.matchedEnriched.name}
											checked={fileTypesSelected.matchedEnriched}
											onChange={(e) => onFileTypeSelected(e, 'matchedEnriched')}
											disabled={matchedEnrichedFileDisabled}
											testId={testId + '-cb-enriched'}
										/>
										{matchedEnrichedFileDisabled && (
											<p className={styles.uploadTileDownloadModalContentCheckboxDisabledMessage}>
												{t('upload.tile.matched.enriched.file.disabled')}
											</p>
										)}
									</div>
								</div>
							</div>
						</>
					) : undefined}
					{(!processingErrorFileDisabled ||
						!ingestionErrorFileDisabled ||
						(salesforceSource && !publishingErrorFileDisabled)) && (
						<>
							<div className={styles.dividerContainer}>
								<Divider color={ColorGrayDark} />
							</div>
							<div
								data-testid="download-files-error"
								className={styles.uploadTileDownloadModalContentCheckBoxesContainer}
							>
								<div className={styles.uploadTileDownloadModalContentCheckBoxesGroup}>
									<div className={styles.uploadTileDownloadModalContentCheckBox}>
										<CheckBox
											id={fileTypes.ingestionErrorFile.name}
											value={fileTypes.ingestionErrorFile.name}
											label={fileTypes.ingestionErrorFile.name}
											checked={fileTypesSelected.ingestionErrorFile}
											onChange={(e) => onFileTypeSelected(e, 'ingestionErrorFile')}
											disabled={ingestionErrorFileDisabled}
											testId={testId + '-ingest-error-file'}
										/>
										{ingestionErrorFileDisabled && (
											<p className={styles.uploadTileDownloadModalContentCheckboxDisabledMessage}>
												{t('upload.tile.error.file.disabled')}
											</p>
										)}
									</div>
									<div className={styles.uploadTileDownloadModalContentCheckBox}>
										<CheckBox
											id={fileTypes.processingErrorFile.name}
											value={fileTypes.processingErrorFile.name}
											label={fileTypes.processingErrorFile.name}
											checked={fileTypesSelected.processingErrorFile}
											onChange={(e) => onFileTypeSelected(e, 'processingErrorFile')}
											disabled={processingErrorFileDisabled}
											testId={testId + '-proc-error-file'}
										/>
										{processingErrorFileDisabled && (
											<p className={styles.uploadTileDownloadModalContentCheckboxDisabledMessage}>
												{t('upload.tile.error.file.disabled')}
											</p>
										)}
									</div>
								</div>
								{salesforceSource ? (
									<div className={styles.uploadTileDownloadModalContentCheckBoxesGroup}>
										<div className={styles.uploadTileDownloadModalContentCheckBox}>
											<CheckBox
												id={fileTypes.publishingErrorFile.name}
												value={fileTypes.publishingErrorFile.name}
												label={fileTypes.publishingErrorFile.name}
												checked={fileTypesSelected.publishingErrorFile}
												onChange={(e) => onFileTypeSelected(e, 'publishingErrorFile')}
												disabled={publishingErrorFileDisabled}
												testId={testId + '-publ-error-file'}
											/>
											{publishingErrorFileDisabled && (
												<p
													className={
														styles.uploadTileDownloadModalContentCheckboxDisabledMessage
													}
												>
													{t('upload.tile.error.file.disabled')}
												</p>
											)}
										</div>
									</div>
								) : null}
							</div>
						</>
					)}
				</div>

				<div
					data-testid="files-download-modal-buttons-container"
					className={styles.filesDownloadModalButtonsContainer}
				>
					<div className={styles.downloadButton}>
						<Button
							size="medium"
							type="primary"
							text={t('upload.tile.download.data')}
							onClick={() => onDownloadDataClick()}
							isDisabled={disabledDownloadButton}
							testId={testId + '-download-data'}
						/>
					</div>
				</div>
			</Modal>
		</div>
	)
}
