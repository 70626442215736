import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { getRUMRules, RUMSummary } from './api/getRUMRules'

export const useRUMRules = (sourceId?: string, isC4SPlatform?: boolean): UseQueryResult<RUMSummary> => {
	const apiClient = useApi()

	const queryKey = ['getRUMRules', { sourceId }]
	const queryFunction = () => getRUMRules(apiClient, sourceId, isC4SPlatform)

	return useQuery(queryKey, queryFunction)
}
