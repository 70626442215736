import { ChangeEvent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { RadioButton, ToggleSwitch } from '../../../../local-core-ui'
import styles from './trade-up-selector.module.scss'

interface TradeUpSelectorProps {
	enableTradeUp: boolean
	selectedTradeUpEntity: 'domhq' | 'hq'
	onChangeTradeUpEntity(event: ChangeEvent<HTMLInputElement>): void
	onChangeTradeUp(): void
	testId: string
}

export function TradeUpSelector({
	enableTradeUp,
	selectedTradeUpEntity,
	onChangeTradeUp,
	onChangeTradeUpEntity,
	testId
}: TradeUpSelectorProps): ReactElement {
	const { t } = useTranslation()

	return (
		<>
			<div className={styles.enableTradeUpContainer} data-testid={'trade-up-controls-container'}>
				<ToggleSwitch
					id={testId + '-switch-enable-trade-up'}
					onChange={onChangeTradeUp}
					label={t('title.tradeUp')}
					testId={testId + '-switch-enable-trade-up'}
					checked={enableTradeUp}
				/>
				<div className={styles.containerDescriptionAndOpts}>
					<span className={styles.description}>{t('description.tradeUp')}</span>
					<div className={styles.containerRadios}>
						<div className={styles.radioWrapper} data-testid={'radio-tradeup-hq-container'}>
							<RadioButton
								value={'hq'}
								group={'tradeup'}
								id={'radio-trade-up-hq'}
								label={t('description.tradeUp.option.hq')}
								onChange={onChangeTradeUpEntity}
								testId={testId + '-radio-trade-up-hq'}
								disabled={!enableTradeUp}
								checked={selectedTradeUpEntity === 'hq'}
							/>
						</div>
						<div className={styles.radioWrapper} data-testid={'radio-tradeup-domhq-container'}>
							<RadioButton
								value={'domhq'}
								group={'tradeup'}
								id={'radio-trade-up-domestic-hq'}
								label={t('description.tradeUp.option.domhq')}
								onChange={onChangeTradeUpEntity}
								testId={testId + '-radio-trade-up-domestic-hq'}
								disabled={!enableTradeUp}
								checked={selectedTradeUpEntity === 'domhq'}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
