import { DNBButton } from '@dnb-uux-design-system/react'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Modal, ToolTip } from '../../local-core-ui'
import { RUMSummary } from '../../queries/api/getRUMRules'
import { ToggleIndicator } from '../toggleIndicator/toggleIndicator'
import styles from './rum-limits-modal.module.scss'

export interface IRUMLimits {
	addOns: Array<ReportRumLimits> | Array<any>
	regions: Array<ReportRumLimits> | Array<any>
}

interface ReportRumLimits {
	region: string
	RUM: number
	remaining: number
}

export interface IRUMLimitsModalProps extends IRUMLimits {
	open: boolean
	processFile: () => void
	saveNoProcessFile: () => void
	records: number
	crmSource?: string
	rumSummary?: RUMSummary
	isSalesforce?: boolean
}

export const RumLimitsModal: FC<IRUMLimitsModalProps> = ({
	open,
	addOns,
	regions,
	processFile,
	saveNoProcessFile,
	records,
	crmSource,
	rumSummary,
	isSalesforce = false
}: IRUMLimitsModalProps) => {
	const { t } = useTranslation()
	const columnsTable = ['rum', 'remaining']
	const source = crmSource ? crmSource.charAt(0).toUpperCase() + crmSource.slice(1) : undefined

	return (
		<div data-testid="rum-limits-modal-container" className={styles.rumLimitsModal}>
			<Modal open={open} showButtonClose={false} isContainer={true} testId="RUMFilesModal">
				{isSalesforce && (
					<div className={styles.header}>
						<ToolTip
							customContent={
								<div className={styles.rumLimitsModalTooltip}>
									{t('rumLimits.modal.checkbox.tooltip')}
								</div>
							}
							position={'top'}
							testId={'rum-exclusion-checkbox-tooltip'}
						>
							<div>
								<ToggleIndicator
									enabled={!!rumSummary?.isExcludeFlagConfigured}
									textKeyPrefix="exclusion"
								/>
							</div>
						</ToolTip>
						<div className={styles.rumLimitsModalTitle}>
							<p>
								{t('rumLimits.modal.title.c4s', {
									records:
										rumSummary?.status != 'error'
											? rumSummary?.recordsToExclude
											: t('rumLimits.modal.title.c4s.undetermined')
								})}
							</p>
						</div>
					</div>
				)}
				<div className={styles.rumLimitsModalBody}>
					{isSalesforce ? (
						<>
							<p>
								<Trans
									i18nKey="rumLimits.modal.description1.c4s"
									values={{
										records:
											rumSummary?.status != 'error' ? rumSummary?.recordsToProcess : 'unknown'
									}}
								/>
							</p>
							<p>{t('rumLimits.modal.description2.c4s')}</p>
							<p>{t('rumLimits.modal.description3.c4s', { source: source })}</p>
						</>
					) : (
						<>
							<p>{t('rumLimits.modal.description1')}</p>
							<p>
								<Trans i18nKey="rumLimits.modal.description2" values={{ records: records }} />
							</p>
						</>
					)}
					<ToolTip
						customContent={<div>{t('rumLimits.modal.tooltip.explanation')}</div>}
						position={'top'}
						testId={'rum-calculation-explanation-tooltip'}
					>
						<div>
							{regions.length > 0 && (
								<div>
									<table>
										<thead>
											<tr>
												<th key={'column-region'}>{t('rumLimits.modal.column.regions')}</th>
												{columnsTable.map((headerText) => (
													<th key={`column-region-${headerText}`}>
														{t(`rumLimits.modal.column.${headerText}`)}
													</th>
												))}
											</tr>
										</thead>
										<tbody>
											{regions.map((regionLimit) => {
												return (
													<tr key={'region-' + regionLimit?.region}>
														<td>{regionLimit?.region}</td>
														<td>{regionLimit?.RUM}</td>
														<td>{regionLimit?.remaining}</td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>
							)}
							{addOns.length > 0 && (
								<div>
									<table>
										<thead>
											<tr>
												<th key={'column-AddOn'}>{t('rumLimits.modal.column.addOns')}</th>
												{columnsTable.map((headerText) => (
													<th key={`column-addOn-${headerText}`}>
														{t(`rumLimits.modal.column.${headerText}`)}
													</th>
												))}
											</tr>
										</thead>
										<tbody>
											{addOns.map((addOn) => {
												return (
													<tr key={'addOn-' + addOn?.region}>
														<td>{addOn?.region}</td>
														<td>{addOn?.RUM}</td>
														<td>{addOn?.remaining}</td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>
							)}
						</div>
					</ToolTip>
					{isSalesforce ? (
						<p>{t('rumLimits.modal.question.c4s', { source: source })}</p>
					) : (
						<p>{t('rumLimits.modal.question')}</p>
					)}
					<div className={styles.buttonsContainer}>
						<DNBButton
							size="default"
							variant="primary"
							onClick={processFile}
							data-testid={'modal-continue-process-file'}
						>
							{isSalesforce
								? t('rumLimits.modal.button.processSource', { source: source })
								: t('rumLimits.modal.button.processFile')}
						</DNBButton>
						<DNBButton
							size="default"
							variant="secondary"
							onClick={saveNoProcessFile}
							data-testid={'modal-cancel-process-file'}
						>
							{isSalesforce
								? t('rumLimits.modal.button.cancelSource', { source: source })
								: t('rumLimits.modal.button.cancelProcess')}
						</DNBButton>
					</div>
				</div>
			</Modal>
		</div>
	)
}
