import cx from 'classnames'
import { forwardRef, KeyboardEvent, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { DropdownOptions } from './dropdown'
import styles from './dropdown.module.scss'

export interface optionListProps {
	id: string
	size: string
	overflow: string
	className?: string
	visibleItems: number
	options: Array<DropdownOptions>
	onSelect: (option: DropdownOptions, index: number) => void
	onHandleKey: (event: KeyboardEvent, idx: number, liRef: Array<HTMLLIElement | null>) => void
	onHandleEnter: (event: KeyboardEvent, idx: number, liRef: Array<HTMLLIElement | null>) => void
	testId: string
	show: boolean
	onHide: () => void
	parentTop: number
	parentLeft: number
	zIndex: string
}
export const OptionList = forwardRef<Partial<HTMLUListElement>, optionListProps>(
	(
		{
			id,
			size,
			overflow,
			className,
			visibleItems,
			options,
			onSelect,
			testId,
			show,
			onHide,
			parentTop,
			parentLeft,
			zIndex,
			onHandleEnter,
			onHandleKey
		},
		ref
	) => {
		// Stupid trick to bypass props validation lint error
		const localOptions = options
		const itemHeight = 25
		const [activeElement, setActiveElement] = useState<number>(0)
		const containerRef = useRef<HTMLElement | null>(null)
		const liRef = useRef<Array<HTMLLIElement | null>>([])
		const ulRef = useRef<HTMLUListElement | null>(null)

		useImperativeHandle(ref, () => ({
			focus: () => {
				if (liRef.current[0]) liRef.current[0].focus()
			}
		}))

		useEffect(() => {
			// Look for existing target dom element to append to, or create one
			const container = document.getElementById(id) ? document.getElementById(id) : document.createElement('div')

			if (container && !container.id) {
				container.id = id
				document.body.appendChild(container)
				containerRef.current = container
			}

			return function removeElement() {
				if (containerRef.current) document.body.removeChild(containerRef.current)
			}
		}, [id])

		const repositionList = () => {
			const marginLeft = parseInt(
				window.getComputedStyle(document.body).getPropertyValue('margin-left').replace('px', '')
			)
			const top = parentTop + 40
			const left = parentLeft - marginLeft
			if (containerRef.current) {
				containerRef.current.style.top = `${top}px`
				containerRef.current.style.left = `${left}px`
				containerRef.current.style.position = `absolute`
				containerRef.current.style.zIndex = zIndex
			}
		}

		useEffect(() => {
			if (show) {
				repositionList()
				document.addEventListener('wheel', repositionList)
				if (containerRef.current) containerRef.current.style.visibility = 'visible'
			} else {
				document.removeEventListener('wheel', repositionList)
				if (containerRef.current) containerRef.current.style.visibility = 'hidden'
			}
			return () => {
				document.removeEventListener('wheel', repositionList)
			}
		}, [show])

		useEffect(() => {
			const handleClickOutside = (event: MouseEvent) => {
				if (ulRef?.current && !ulRef?.current.contains(event.target as Node)) {
					onHide()
				}
			}
			document.addEventListener('mousedown', handleClickOutside)
			return () => {
				document.removeEventListener('mousedown', handleClickOutside)
			}
		}, [ulRef.current])

		if (containerRef.current) {
			return createPortal(
				<ul
					id={`dropdown-list-${id}`}
					className={cx(
						styles.dropdownList,
						styles[size],
						{
							[styles.minWidthMaxContent]: overflow === 'scroll'
						},
						className
					)}
					tabIndex={-1}
					role="listbox"
					ref={ulRef}
					style={{
						maxHeight: visibleItems * itemHeight + 2 + 'px'
					}}
					data-testid={`ul-list-dropdown-${testId}`}
				>
					{localOptions.map((option, index) => (
						<li
							id={`option-${id}${index}`}
							key={index}
							tabIndex={0}
							role="option"
							onClick={() => onSelect(option, index)}
							ref={(ref) => (liRef.current[index] = ref)}
							onFocus={() => setActiveElement(index)}
							className={cx(styles[size], { [styles.disabled]: option.disabled })}
							aria-selected={activeElement === index ? 'true' : 'false'}
							onKeyDown={(event) => onHandleKey(event, index, liRef.current)}
							onKeyUp={(event) => onHandleEnter(event, index, liRef.current)}
							data-testid={`li-list-dropdown-${testId}-${index}`}
						>
							{option.label}
						</li>
					))}
				</ul>,
				containerRef.current
			)
		} else {
			return undefined
		}
	}
)

OptionList.displayName = 'OptionList'
