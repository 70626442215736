import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PieChart as PieChartCustom } from '../../pie-chart/pie-chart'
import styles from './stewardship-value-report.module.scss'

interface StewardshipList {
	value: string
	count: number
	rate: number
}
interface StewardshipValueReportProps {
	data: Array<StewardshipList>

	colors: Array<string>
	testId: string
}
export const StewardshipValueReport: FC<StewardshipValueReportProps> = ({
	data,
	colors,
	testId
}: StewardshipValueReportProps) => {
	const { t } = useTranslation()
	const getChartColors = (list: Array<StewardshipList>) =>
		list.reduce((data, item, currentIndex) => {
			const label = item.value !== undefined ? t('label.graph.' + item.value) : ''
			return {
				...data,
				[label]: colors[currentIndex]
			}
		}, {})

	const getPieData = (list: Array<StewardshipList>) => {
		return list.map((item) => {
			return { label: item.value, value: item.count, rate: item.rate }
		})
	}

	return (
		<>
			<div className={styles.title}> {t('title.chart.stewardshipCount')}</div>
			<PieChartCustom data={getPieData(data)} testId={testId + '-pieChart'} chartColors={getChartColors(data)} />
		</>
	)
}
