import DesignTokens from '@dnb-dcp/design-tokens'
import designTokens from '@dnb-uux-design-system/design-tokens/build/index'
import { t } from 'i18next'
import { ReportSnackbar } from '../../../components/report-snackbar/report-snackbar'
import { ReportTable } from '../../../components/report-table/report-table'
import { ReportTile } from '../../../components/report-tile/report-tile'
import { GDMMap } from '../../../local-core-ui/geographical-distribution-map/gdm-map'
import styles from '../report.module.scss'
import { BaseReportSection } from '../types/BaseReportSection'
import { getGeoDataWithPercents } from './utils/getGeoDataWithPercents'
import { getGeoTableData } from './utils/getGeoTableData'

type GeographicalDistributionProps = BaseReportSection

const rowHeight = '3.6rem'

const GeographicalDistribution = ({ uploadReport }: GeographicalDistributionProps) => {
	const { TokenColors } = designTokens
	const colorValue = TokenColors.ColorSecondaryBlueLight
	const zeroColor = DesignTokens.TokenColors.ColorGray
	const geoDistributionReport = uploadReport?.geoDistributionReport
	const geoDistributionList = geoDistributionReport ? geoDistributionReport.geoDistributionList : []
	const displayMap = geoDistributionReport && geoDistributionList.length > 0

	const columns = [
		{
			label: t('report.page.geographicDistribution.table.column.countryName'),
			field: 'country'
		},
		{
			label: t('report.page.geographicDistribution.table.column.totalRecords'),
			field: 'total'
		},
		{
			label: t('report.page.geographicDistribution.table.column.totalPercentage'),
			field: 'percentage'
		}
	]

	const dataWithPercents = getGeoDataWithPercents(geoDistributionList)
	const tableRows = getGeoTableData(geoDistributionList).slice(0, 10)

	return (
		<div className={styles.geographicalDistribution}>
			<ReportTile
				header={t('report.page.geographicDistribution.title') as string}
				subtitle={t('report.page.geographicDistribution.subtitle') as string}
			>
				{displayMap ? (
					<div className={styles.mapContainer}>
						<GDMMap
							color={colorValue}
							data={dataWithPercents}
							testId={'report-gdm-map'}
							translationFunction={t}
							zeroColor={zeroColor}
							disableTooltip={true}
							width={870}
						/>
					</div>
				) : (
					<div className={styles.snackbarWrapper}>
						<ReportSnackbar />
					</div>
				)}
				<ReportTile
					title={t('report.page.geographicDistribution.table.title') as string}
					subtitle={t('report.page.geographicDistribution.table.subtitle') as string}
				>
					{displayMap ? (
						<ReportTable columns={columns} rowHeight={rowHeight} rows={tableRows} zebraStripes="even" />
					) : (
						<ReportSnackbar />
					)}
				</ReportTile>
			</ReportTile>
		</div>
	)
}

export { GeographicalDistribution }
