import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { useApi } from '../hooks'
import { deleteProject } from './api/deleteProject'

export const useDeleteProject = (): UseMutationResult<void, unknown, string, unknown> => {
	const apiClient = useApi()
	const queryClient = useQueryClient()

	const mutationFn = (projectId: string) => deleteProject(apiClient, projectId)

	return useMutation(mutationFn, {
		onSuccess: (data) => {
			queryClient.invalidateQueries('getProjects')
			queryClient.invalidateQueries(['getProjects', data])
		}
	})
}
