import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { PurposeOfUse } from '../types'
import { getRequiredElementsForFamilyTree, RequiredElementsResponse } from './api/getRequiredElementsForFamilyTree'

export const useRequiredElementsForFamilyTree = (
	purposeOfUse: PurposeOfUse
): UseQueryResult<RequiredElementsResponse, unknown> => {
	const apiClient = useApi()

	const queryKey = ['getRequiredElementsForFamilyTree', purposeOfUse]
	const queryFunction = (): Promise<RequiredElementsResponse> =>
		getRequiredElementsForFamilyTree(apiClient, purposeOfUse)
	return useQuery(queryKey, queryFunction)
}
