import { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Textarea } from '../../local-core-ui'
import styles from './description-text-area.module.scss'

export interface DescriptionTextAreaProps {
	label?: string
	placeholder?: string
	maxLength?: number
	value: string
	onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
	testId: string
	disabled?: boolean
}
export const DescriptionTextArea: FC<DescriptionTextAreaProps> = ({
	label,
	placeholder,
	maxLength,
	value,
	onChange,
	testId,
	disabled = false
}: DescriptionTextAreaProps) => {
	const { t } = useTranslation()
	return (
		<div className={styles.textareaWrapper}>
			<Textarea
				id="description"
				placeholder={placeholder ? placeholder : t('create.project.form.textarea.placeholder')}
				onChange={(e) => onChange(e)}
				value={value}
				label={label ? label : t('create.project.form.label.description')}
				maxLength={maxLength ? maxLength : 250}
				displayRemainingCharacters
				size="fluid"
				maxLines={5}
				autoGrowth={false}
				remainingCharactersText={t('create.project.form.characters.left')}
				testId={testId}
				disabled={disabled}
			/>
		</div>
	)
}
