import { IDistributionData } from '../../../../local-core-ui'
import { GeoDistributionList } from '../../types/GeoDistributionList'

const getGeographicData = (geoDistributionList: GeoDistributionList) => {
	const geographicData: Array<IDistributionData> = []

	if (geoDistributionList) {
		return geoDistributionList
			.map((country) => {
				return {
					countryCode: country.geoCode,
					matched: country.count
				} as IDistributionData
			})
			.filter((entry) => entry.countryCode !== '' || entry.matched !== 0)
	}

	return geographicData
}

const getGeoDataWithPercents = (geoDistributionList: GeoDistributionList) => {
	const dataWithTotals = getGeographicData(geoDistributionList).map((data: IDistributionData) => ({
		...data,
		total:
			data.matched +
			(data.unmatched ? data.unmatched : 0) +
			(data.duplicates ? data.duplicates : 0) +
			(data.rejected ? data.rejected : 0)
	}))

	const grandTotal = dataWithTotals.length > 0 ? dataWithTotals.map((c) => c.total).reduce((a, b) => (a += b)) : 0
	return dataWithTotals.map((d) => ({ ...d, percent: d.total / grandTotal }))
}

export { getGeoDataWithPercents }
