import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import { version } from '../package.json'

if (import.meta.env.VITE_LOG_ROCKET_KEY && !import.meta.env.VITE_DISABLE_LOG_ROCKET) {
	LogRocket.init(import.meta.env.VITE_LOG_ROCKET_KEY, {
		release: version,
		network: {
			requestSanitizer: (request) => {
				const url = request.url?.toLowerCase()
				if (url.indexOf('aptrinsic') !== -1) {
					// ignore request / response pair to Gainsight
					return null
				}

				const method = request.method?.toLowerCase()
				if (method === 'post' && url?.indexOf('idaas-login') !== -1) {
					// scrub out the body
					request.body = undefined
				}

				return request
			}
		}
	})
	setupLogRocketReact(LogRocket)
}
