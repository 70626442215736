import { AxiosResponse } from 'axios'
import { isEmpty } from 'lodash-es'
import { setRefreshRematch } from '../../../queries/api/setRefreshRematch'
import { setTransactionalMatch } from '../../../queries/api/setTransactionalMatch'
import { startRematch } from '../../../queries/api/startRematch'
import { ApiErrorState, setApiErrorAction } from '../../apiError'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { markProjectAsFinished, markSourceAsFinished } from '../../thirdPartyIntegrations/thirPartyIntegrationsSlice'
import { removeProjectFromWizardAction, updateLoadingNextStep, updateStatusStartImport } from '../actions'

export const startTray =
	(): AppThunk<Promise<void>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<void> => {
		const apiClient = getApiClient4Thunks(dispatch)
		const projectId = getState().projectWizard.currentProject.id ?? ''
		const sourceId = getState().projectWizard.currentProject.source.id
		const url = '/pls/sources/startTray'
		const salesforcePlatform = getState().session.platform === 'salesforce'
		const modules: string[] = getState().session.moduleFlags
		const isC4S = modules.includes('C4S') && salesforcePlatform
		const inProgressProjects = getState().projectWizard.projects
		const isProjectInProgress = inProgressProjects[projectId] !== undefined

		if (!isProjectInProgress || isEmpty(inProgressProjects)) {
			if (isC4S) {
				return startRematch(apiClient, sourceId || '').then(() => {
					dispatch(updateStatusStartImport(true))
					return Promise.resolve()
				})
			} else {
				dispatch(updateStatusStartImport(true))
				return Promise.resolve()
			}
		}

		dispatch(updateLoadingNextStep(true))
		if (projectId && sourceId) {
			return apiClient
				.post(url, {
					sourceId
				})
				.then((response: AxiosResponse) => {
					if (response.status === 200) {
						dispatch(updateStatusStartImport(true))
						dispatch(removeProjectFromWizardAction(projectId))
						dispatch(markProjectAsFinished(projectId))
						dispatch(markSourceAsFinished(sourceId))
					}
				})
				.then(async () => {
					if (getState().projectWizard.currentProject.source.integrationInfo !== undefined) {
						const refreshRematchConfig =
							getState().projectWizard.currentProject.source.integrationInfo?.refreshRematchConfig
						return setRefreshRematch(apiClient, sourceId, refreshRematchConfig)
					} else return Promise.resolve()
				})
				.then(async () => {
					if (getState().projectWizard.currentProject.source.integrationInfo !== undefined) {
						const isTransactional =
							getState().projectWizard.currentProject.source.integrationInfo?.isTransactional
						const assigmentRule =
							getState().projectWizard.currentProject.source.integrationInfo?.refreshRematchConfig
						return setTransactionalMatch(apiClient, sourceId, {
							enabled: isTransactional,
							triggerAssignmentRules: assigmentRule?.triggerAssignmentRules
						})
					} else return Promise.resolve()
				})
				.then(() => {
					dispatch(updateLoadingNextStep(false))
				})
				.catch((error) => {
					console.error(error)
					const errorMessage = JSON.parse(error.request.response).UIAction
					const errorData: ApiErrorState = {
						errorCode: errorMessage.code,
						message: errorMessage.message
					}
					dispatch(setApiErrorAction(errorData))
					dispatch(updateLoadingNextStep(false))
					throw error
				})
		} else {
			const errorMessage = !projectId
				? 'project.wizard.error.project.id.undefined'
				: 'project.wizard.error.source.id.undefined'
			const errorData: ApiErrorState = {
				message: errorMessage
			}
			dispatch(setApiErrorAction(errorData))
			return Promise.reject(errorMessage)
		}
	}
