import { combineReducers } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AnyAction, applyMiddleware, createStore, Reducer, Store } from 'redux'
import { createMigrate, persistReducer } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage'
import sessionStorage from 'redux-persist/lib/storage/session'
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk'
import wizardReducer from '../components/wizardComposer/store/wizardSlice'
import { apiErrorReducer } from './apiError'
import connectionSlice from './connection/connectionSlice'
import dashboardReducer from './dashboard/dashboardSlice'
import { projectWizardMigrations } from './projectWizard/migrations'
import { projectWizardReducer } from './projectWizard/reducers'
import { LocalTransformer, SessionTransformer } from './projectWizard/transformer'
import { SessionActionTypes, sessionReducer, SessionState } from './session'
import stewardReducer from './steward/stewardSlice'
import thirdPartyIntegrationsReducer from './thirdPartyIntegrations/thirPartyIntegrationsSlice'

export type AppThunk<ReturnType = Promise<unknown> | void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>
export type TAppState = ReturnType<typeof rootReducer>
export type TDispatch = ThunkDispatch<RootState, unknown, AnyAction>
export type TAction = ReturnType<TDispatch>
export type TStore = Store<TAppState, AnyAction> & { dispatch: TDispatch }
export type TGetState = () => TAppState

export const useAppDispatch = () => useDispatch<TDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

const disableEncryption = import.meta.env?.VITE_DISABLE_LOCAL_STORAGE_ENCRYPTION
const Transforms = []
const disableSessionEncryption = import.meta.env?.VITE_DISABLE_SESSION_STORAGE_ENCRYPTION
const sessionTransforms = []

if (disableEncryption === undefined || disableEncryption !== 'true') {
	Transforms.push(LocalTransformer)
}

if (disableSessionEncryption === undefined || disableSessionEncryption !== 'true') {
	sessionTransforms.push(SessionTransformer)
}

const projectWizardPersistConfig = {
	key: 'wizard',
	storage: localStorage,
	version: 5,
	migrate: createMigrate(projectWizardMigrations),
	transforms: Transforms,
	debug: false // Turn it to true to see WTH is going on with redux-persist
}

const sessionPersistConfig = {
	key: 'session',
	storage: sessionStorage,
	transforms: sessionTransforms
}

const thirdPartyIntegrationsPersistConfig = {
	key: 'thirdPartyIntegrations',
	storage: localStorage,
	version: 0
}

const ConnectionPersistConfig = {
	key: 'connection',
	storage: localStorage,
	version: 0
}

const rootReducer = combineReducers({
	session: persistReducer(sessionPersistConfig, sessionReducer as Reducer<SessionState, SessionActionTypes>),
	dashboard: dashboardReducer,
	projectWizard: persistReducer(projectWizardPersistConfig, projectWizardReducer),
	apiError: apiErrorReducer,
	steward: stewardReducer,
	thirdPartyIntegrations: persistReducer(thirdPartyIntegrationsPersistConfig, thirdPartyIntegrationsReducer),
	wizard: wizardReducer,
	connection: persistReducer(ConnectionPersistConfig, connectionSlice)
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer

const middlewares = applyMiddleware(thunk)

export const store = createStore(rootReducer, middlewares)

export * from './getApiClient4Thunks'
export * from './session'
