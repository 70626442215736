import { Element } from '../../../types'
import { EnrichingBlock } from '../types'

export const getDefaultElements = (defaultElements: Array<EnrichingBlock>) => {
	const elementsDefaultSelected: Array<string> = []
	defaultElements.forEach((defaultElement) => {
		defaultElement.elements.forEach((element: Element) => {
			elementsDefaultSelected.push(element.elementId)
		})
	})
	return elementsDefaultSelected
}
