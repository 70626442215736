import { AxiosInstance, AxiosResponse } from 'axios'
import { DataReport, DataReportStub } from '../../types'

export const getDataReport = async (
	apiClient: AxiosInstance,
	level: string,
	ownerId: string,
	useMockData: string
): Promise<DataReport> => {
	const url = `/pls/datareport?level=${level}&ownerId=${ownerId}&mock=${useMockData}`
	return apiClient.get(url).then((response: AxiosResponse<DataReport>) => {
		if (response.data || level !== 'Tenant') return response.data
		else return DataReportStub
	})
}
