import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../../local-core-ui'
import { useUsers } from '../../queries/useUsers'
import { AssignmentPicker } from '../assignment-picker/assignment-picker'
import { NewAssignmentModal } from '../new-assignment-modal/new-assignment-modal'
import { PersonPicker } from '../person-picker/person-picker'
import styles from './assignment-modal.module.scss'

interface AssignmentModalProps {
	testId: string
	isOpen: boolean
	onClose(): void
	onAddToThisAssignment(assignmentId: string, selectedPerson: string): void
}

export const AssignmentModal = ({
	testId,
	isOpen,
	onClose,
	onAddToThisAssignment
}: AssignmentModalProps): ReactElement => {
	const { t } = useTranslation()

	const [selectedPerson, setSelectedPerson] = useState<string>()
	const [newAssignmentIsOpen, setNewAssignmentIsOpen] = useState(false)

	const usersQuery = useUsers()
	const user = usersQuery.data?.find((user) => selectedPerson === user.Email)
	const allowedRoles = ['EXTERNAL_ADMIN', 'INTERNAL_ADMIN', 'DATA_STEWARD', 'ADMIN', 'SUPER_ADMIN']

	const theseUsers = usersQuery.data
		?.filter((u) => allowedRoles.indexOf(u.AccessLevel) > -1)
		.map((u) => ({
			id: u.Email,
			name: u.FirstName + ' ' + u.LastName,
			role: t('user.role.' + u.AccessLevel)
		}))

	const cleanSelectionBeforeClose = () => {
		setSelectedPerson(undefined)
		onClose()
	}

	useEffect(() => {
		if (!isOpen && selectedPerson) {
			setSelectedPerson(undefined)
		}
		/**
		 * selectedPerson is not added as a dependency because it would create an infinite loop.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	return (
		<>
			<Modal
				open={isOpen}
				showButtonClose={true}
				onClose={cleanSelectionBeforeClose}
				isContainer
				testId="Asignment-Modal"
			>
				<div className={styles.assignmentModal}>
					<h1 className={styles.title}>{t('assignment.modal.title')}</h1>
					<div className={styles.ppContainer}>
						<PersonPicker
							onSelectionChange={(p) => {
								setSelectedPerson(p[0])
							}}
							persons={theseUsers || []}
							testId={testId + '-person-picker'}
						/>
					</div>
					{selectedPerson ? (
						<div className={styles.apContainer}>
							<AssignmentPicker
								title="AssignmentPicker Title"
								selectedPersonId={selectedPerson || ''}
								onAddToThisAssignment={(assignmentId) =>
									onAddToThisAssignment(assignmentId, selectedPerson)
								}
								testId={testId + '-assignment-picker'}
								onNewAssignment={() => {
									setNewAssignmentIsOpen(true)
								}}
								user={user}
							/>
						</div>
					) : (
						<></>
					)}
				</div>
			</Modal>
			<NewAssignmentModal
				isOpen={newAssignmentIsOpen}
				onClose={() => {
					setNewAssignmentIsOpen(false)
				}}
				userId={selectedPerson || ''}
				role={t('user.role.' + user?.AccessLevel)}
				testId={testId + '-new-assignment-modal'}
			/>
		</>
	)
}
