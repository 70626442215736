import { AxiosInstance, AxiosResponse } from 'axios'
import { GetUploadResponse, JobDetailsResponse } from '../../types'

export interface UploadUIFacade extends JobDetailsResponse {
	createdBy?: string
}

export const getUpload = async (apiClient: AxiosInstance, uploadId: string): Promise<UploadUIFacade> => {
	const url = `/pls/uploads/uploadId/${uploadId}/jobDetails`
	return apiClient.get(url).then((jobDetailsResponse: AxiosResponse<JobDetailsResponse>) => {
		return apiClient.get(`/pls/uploads/uploadId/${uploadId}`).then((response: AxiosResponse<GetUploadResponse>) => {
			return {
				...jobDetailsResponse.data,
				createdBy: response.data.createdBy,
				isApiAggregation: response.data.isApiAggregation
			} as UploadUIFacade
		})
	})
}
