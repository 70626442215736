import { ColorGrayDark, ColorJetBlack, ColorWhite } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { FocusEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContextMenu, ContextMenuOption, Icon } from '../../local-core-ui'
import { AssignmentDetails } from '../../types'
import { AssignmentTileContainer } from '../assignment-tile-container/assignment-tile-container'
import { AssignmentDetail } from './assignment-detail'
import styles from './assignment-tile.module.scss'
import { State } from './types'

interface AssignmentTileProps {
	idx: number
	assignmentDetail: AssignmentDetails
	onLeftArrowClicked(idx: number): void
	onHideDashboardClick(): void
	onReassignClick(idx: number): void
	onDownloadRecordsClick(idx: number): void
	showDetail: boolean
	isSelected: boolean
	onFocus(event: FocusEvent<HTMLDivElement>): void
}

export const AssignmentTile = ({
	idx,
	assignmentDetail,
	onLeftArrowClicked,
	onHideDashboardClick,
	onReassignClick,
	onDownloadRecordsClick,
	showDetail,
	isSelected,
	onFocus
}: AssignmentTileProps): ReactElement => {
	const { t } = useTranslation()
	const { displayName, state } = assignmentDetail

	const [arrowColor, setArrowColor] = useState<string>(ColorJetBlack)
	const [contextMenuColor, setContextMenuColor] = useState<string>(ColorGrayDark)
	const contextMenuOptions: ContextMenuOption[] = [
		{
			label: t(`assignments.to.tile.${showDetail ? 'hide' : 'show'}.dashboard`),
			action: 'hideDashboard'
		},
		{
			label: t('assignments.to.tile.reassign'),
			action: 'reassign'
		},
		{
			label: t('assignments.to.tile.download.records'),
			action: 'downloadRecords'
		}
	]

	const handleContextMenuClicked = (option: string) => {
		switch (option) {
			case 'hideDashboard':
				onHideDashboardClick()
				break
			case 'reassign':
				onReassignClick(idx)
				break
			case 'downloadRecords':
				onDownloadRecordsClick(idx)
				break
		}
	}

	useEffect(() => {
		setArrowColor(isSelected ? ColorWhite : ColorJetBlack)
		setContextMenuColor(isSelected ? ColorWhite : ColorGrayDark)
	}, [isSelected])

	return (
		<AssignmentTileContainer
			isSelected={isSelected}
			showDetail={showDetail}
			testId={`assignment-${displayName}-tile`}
			onFocus={onFocus}
			isArchivedTile={State[state] === State.ARCHIVED}
		>
			<div className={`${styles.assignmentTileContainer} ${showDetail ? '' : styles.assignmentTileSummary}`}>
				<div className={styles.assignmentTileHeader}>
					<h4 className={styles.assignmentTileName} title={displayName}>
						{displayName}
					</h4>
					<div className={styles.assignmentTileIconsContainer}>
						<button
							data-testid={`assignment-${displayName}-arrow-back`}
							className={styles.assignmentTileBackContainer}
							onClick={() => onLeftArrowClicked(idx)}
						>
							<Icon testId="back-assignment-tile" type="arrow-back" color={arrowColor} size="mini" />
						</button>
						<button className={styles.assignmentTileKebabContainer}>
							<ContextMenu
								options={contextMenuOptions}
								onMenuClicked={handleContextMenuClicked}
								testId={`assignment-${displayName}-aTile-kebab`}
							>
								<Icon testId="three-dots-assignment-tile" type="three-dots" color={contextMenuColor} />
							</ContextMenu>
						</button>
					</div>
				</div>
				{showDetail && <AssignmentDetail isSelected={isSelected} assignmentDetail={assignmentDetail} />}
			</div>
		</AssignmentTileContainer>
	)
}
