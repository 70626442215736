import { AxiosInstance, AxiosResponse } from 'axios'
import { filterElementsByEntitlements, flattenElements } from '../../../helpers'
import { GetEnrichmentRulesResponseBySource, LayoutResponse, MatchingApproach, Source } from '../../../types'
import { getDefaultElementList } from '../../../utils'
import { TDispatch, TGetState } from '../../index'
import { updateCurrentProjectAction } from '../actions'

export const readEnrichingLayoutIntoStore = async (
	selectedSource: Source,
	apiClient: AxiosInstance,
	dispatch: TDispatch,
	getState: TGetState,
	isC4S: boolean
) => {
	const url = `/pls/enrichment-layouts/sourceId/${selectedSource.sourceId}`
	const enrichingLayout = getState().projectWizard.currentProject.enrichingLayout
	const entityType = getState().projectWizard.currentProject.source.entityType
	const enableEmailVerification = getState().projectWizard?.currentProject?.source?.enable_email_verification
	const isRecommendationMatch =
		getState().projectWizard.currentProject?.matchingApproach === MatchingApproach.START_SCRATCH_MR
	//DCP-5594,5595,5598 concating default elements with contact default elements

	const defaultElementList = getDefaultElementList(
		entityType,
		enableEmailVerification,
		apiClient,
		isRecommendationMatch
	)
	await apiClient
		.get(url)
		.then(async (response: AxiosResponse<LayoutResponse>) => {
			if (response.data.elements) {
				const selectedElementIDs = flattenElements(defaultElementList).map(
					(defaultElement) => defaultElement.elementId
				)
				selectedElementIDs.push(
					...response.data.elements.filter((layoutElement) => !selectedElementIDs.includes(layoutElement))
				)
				await filterElementsByEntitlements(
					apiClient,
					dispatch,
					selectedElementIDs,
					{
						domain: response.data.domain,
						recordType: response.data.recordType
					},
					enrichingLayout,
					entityType
				)
				dispatch(
					updateCurrentProjectAction({
						layoutId: response.data.layoutId,
						generateJson: response.data.generateJSON,
						tradeUp: !!response.data.tradeUp,
						entityTradeUp: response.data.tradeUp,
						includeBranches: response.data.includeBranches
					})
				)
			}
		})
		.catch((e) => {
			console.error(e)
		})
	if (isC4S) {
		await apiClient
			.get(`/pls/enrichment-rules/sourceId/${selectedSource.sourceId}`)
			.then((response: AxiosResponse<GetEnrichmentRulesResponseBySource>) => {
				dispatch(
					updateCurrentProjectAction({
						enrichmentRules: response.data?.rules
					})
				)
			})
	}
}
