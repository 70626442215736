import { ColorSlateLighter } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement } from 'react'
import { Divider } from '../../local-core-ui'
import styles from './information-sub-section.module.scss'

export interface FieldInformation {
	field: string
	information: string
}

export interface IInformationSubSectionProps {
	uniqueItem?: boolean
	field: FieldInformation
}

export const InformationSubSection = ({ uniqueItem = false, field }: IInformationSubSectionProps): ReactElement => {
	return (
		<div className={`${styles.informationBar} ${uniqueItem ? styles.rowFull : ''}`}>
			<div className={styles.containerBar}>
				<Divider size={'medium'} color={ColorSlateLighter} />
			</div>
			<div
				data-testid="container-info"
				className={`${styles.containerInformation} ${
					uniqueItem ? styles.containerUnique : styles.containerMultiple
				}`}
			>
				<div data-testid="name-field" className={`${styles.nameField} ${uniqueItem ? styles.rowFull : ''}`}>
					{field.field}
				</div>
				<div data-testid="info-field" className={styles.informationField}>
					{field.information}
				</div>
			</div>
		</div>
	)
}
