import { TFunction } from 'react-i18next'
import { ElementUIFacade } from '../ElementUIFacade'
import familyTreeRolesPlayedPrefixes from '../family-tree-roles-played-element-prefixes.json'

const buildChildrenTree = (
	listFlattenElementsFamTreeRolesPlayed: Array<ElementUIFacade>,
	root: ElementUIFacade,
	t: TFunction<'translation'>
) => {
	const groupElements: Array<ElementUIFacade> = []
	familyTreeRolesPlayedPrefixes.forEach((prefix) => {
		const groupParent: ElementUIFacade = {
			parent: root,
			elementId: `${prefix}Id`,
			displayName: t(`${prefix}.element`),
			description: t(`${prefix}.description`),
			level: '1',
			showChildrenBlocks: true,
			considerChildrenForCount: true
		}
		const elementsWithRole = listFlattenElementsFamTreeRolesPlayed.filter((famTreeRolesPlayedElement) =>
			famTreeRolesPlayedElement.elementId.startsWith(prefix)
		)
		if (elementsWithRole) {
			elementsWithRole.forEach((element) => (element.hideElementDetail = true))
			groupParent.childs = elementsWithRole
			groupElements.push(groupParent)
		}
	})
	return groupElements
}

export const buildFamilyTreeRolesPlayedTree = (
	listFlattenElementsFamTreeRolesPlayed: Array<ElementUIFacade>,
	t: TFunction<'translation'>
): Array<ElementUIFacade> => {
	const tree: Array<ElementUIFacade> = []
	if (listFlattenElementsFamTreeRolesPlayed.length) {
		const root: ElementUIFacade = {
			blockId: t(listFlattenElementsFamTreeRolesPlayed[0].blockId || ''),
			elementId: 'famTreeRolesPlayedId',
			displayName: t('famTreeRolesPlayed.element'),
			description: t('famTreeRolesPlayed.description'),
			level: '1',
			dataType: `${t('STRING')}, ${t('NUMBER')}`,
			showChildrenBlocks: true,
			considerChildrenForCount: true
		}
		tree.push(root)

		root.childs = buildChildrenTree(listFlattenElementsFamTreeRolesPlayed, root, t)
	}
	return tree
}
