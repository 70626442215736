import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContextMenuOption, ToolTip } from '../../local-core-ui'
import { UserDetail } from '../../types'
import { InformationSectionHeader } from '../information-section-header/information-section-header'
import { TeamMemberTile } from '../team-member-tile/team-member-tile'
import styles from './project-team-members.module.scss'

interface ProjectTeamMembersProps {
	teamMembers?: Array<UserDetail>
	menuOptions?: ContextMenuOption[]
	readOnly?: boolean

	onMenuClick?(action: string, index: number): void

	rowsToDisplay?: number
	testId: string
}

export const ProjectTeamMembers: FC<ProjectTeamMembersProps> = ({
	teamMembers,
	menuOptions,
	readOnly,
	onMenuClick,
	rowsToDisplay = 1,
	testId
}: ProjectTeamMembersProps) => {
	const { t } = useTranslation()
	const teamMembersProjectRef = useRef<HTMLDivElement>(null)
	const [membersToRender, setMembersToRender] = useState<{
		membersToShow: UserDetail[]
		membersForTooltip: UserDetail[]
	}>({ membersToShow: [], membersForTooltip: [] })

	useEffect(() => {
		const parentWidth = teamMembersProjectRef.current?.parentElement?.clientWidth || 0
		const teamMemberTileWidth = 211 + 24 // teamMemberTile width + spacing-medium between columns
		const plusInfoWidth = 32
		let teamsWidth = 0
		const membersToShow: UserDetail[] = []
		const membersForTooltip: UserDetail[] = []
		let currentRow = 1
		teamMembers &&
			teamMembers.forEach((userDetail, index) => {
				if (
					index + 1 !== teamMembers.length &&
					teamsWidth + teamMemberTileWidth + plusInfoWidth <= parentWidth
				) {
					teamsWidth += teamMemberTileWidth
					membersToShow.push(userDetail)
				} else if (currentRow < rowsToDisplay) {
					currentRow++
					teamsWidth = teamMemberTileWidth
					membersToShow.push(userDetail)
				} else if (
					index + 1 === teamMembers.length &&
					teamsWidth + teamMemberTileWidth <= parentWidth &&
					membersForTooltip.length === 0
				) {
					membersToShow.push(userDetail)
				} else {
					membersForTooltip.push(userDetail)
				}
			})
		setMembersToRender({
			membersToShow,
			membersForTooltip
		})
	}, [teamMembers, rowsToDisplay])

	return (
		<div data-testid="teamMembersProject" ref={teamMembersProjectRef} className={styles.teamMembersProject}>
			{menuOptions && onMenuClick ? (
				<InformationSectionHeader
					sectionName={t('team.members')}
					options={menuOptions}
					menuClicked={onMenuClick}
					readOnly={readOnly}
					testId={testId + '-ish-team-members'}
				/>
			) : null}
			{teamMembers && teamMembers.length > 0 ? (
				<div data-testid="team-section" className={styles.teamSection}>
					<div
						className={`${styles.membersSection} ${
							menuOptions && onMenuClick ? styles.membersSectionWithHeader : ''
						}`}
						data-testid="members-section"
						style={{
							gridTemplateColumns: `repeat(${Math.ceil(
								membersToRender.membersToShow.length / rowsToDisplay
							)}, fit-content(100%))`
						}}
					>
						{membersToRender.membersToShow.map((member, idx) => {
							return (
								<TeamMemberTile
									key={`member-${idx}`}
									teamMember={member}
									testId={testId + '-member-' + idx}
								/>
							)
						})}
					</div>
					{membersToRender && membersToRender.membersForTooltip.length > 0 && (
						<div className={styles.plusContainer}>
							<ToolTip
								effect="float"
								position="left"
								customContent={
									<div className={styles.tooltipMembersSection}>
										{membersToRender.membersForTooltip.map((currentMember, idx) => {
											return (
												<div key={`tooltip-member-${idx}`} className={styles.tooltipMember}>
													<TeamMemberTile
														teamMember={currentMember}
														testId={testId + '-add-member-' + idx}
													/>
												</div>
											)
										})}
									</div>
								}
								keepTooltipOnMouseOver={true}
								testId={testId + '-tt-members'}
							>
								<div
									className={styles.plusMemberInfo}
								>{`+${membersToRender.membersForTooltip.length}`}</div>
							</ToolTip>
						</div>
					)}
				</div>
			) : (
				t('no.team.members')
			)}
		</div>
	)
}
