import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmationModal } from '../../../components/confirmation-modal/confirmation-modal'
import { FilePreviewer } from '../../../components/file-previewer/file-previewer'
import { useWizardContext } from '../../../components/wizardComposer/hooks/useWizardContext'
import { useWizardRouteMatch } from '../../../components/wizardComposer/hooks/useWizardRouteMatch'
import { useFeatures } from '../../../hooks/useEntitlements'
import { RootState, useAppDispatch, useAppSelector } from '../../../store'
import { replaceFileAction } from '../../../store/projectWizard/actions'
import { uploadFile } from '../../../store/projectWizard/thunks'

export function PreviewFile(): ReactElement {
	const { t } = useTranslation()
	const { wizardContext } = useWizardContext()
	const { previous } = useWizardRouteMatch()
	const onConfirm = () => wizardContext.dispatch(uploadFile())
	const onCancel = previous

	const selectProjectWizard = (state: RootState) => state.projectWizard

	const projectWizardState = useAppSelector(selectProjectWizard)

	const dispatch = useAppDispatch()
	const enableFileDelimiters = useFeatures(['EnableFileDelimiters'])
	const [showConfirmationModal, setShowConfirmationModal] = useState(false)

	const doesLookGood = () => {
		// Let's try to find out if the csv is really valid: Papa Parse can parse almost anything, but a file that
		// does not match header columns with row columns will always seem to be a non csv
		if (
			projectWizardState.currentProject.fileInfo &&
			projectWizardState.currentProject.fileInfo.headers &&
			projectWizardState.currentProject.fileInfo.data
		) {
			const headerCount = projectWizardState.currentProject.fileInfo.headers.length
			return projectWizardState.currentProject.fileInfo.data.reduce(
				(prev: boolean, row) => prev && row.length <= headerCount,
				true
			)
		} else return false
	}

	return (
		<>
			{showConfirmationModal ? (
				<ConfirmationModal
					open={showConfirmationModal}
					onClose={() => setShowConfirmationModal(false)}
					onConfirmClick={() => {
						setShowConfirmationModal(false)
						if (onConfirm) {
							onConfirm()
						}
					}}
					description={t('file.previewer.are.you.sure')}
					confirmText={t('file.previewer.yes.i.am')}
					testId={'preview-file-wrong-format-confirmation'}
				/>
			) : (
				<FilePreviewer
					title={projectWizardState.currentProject.fileInfo?.name}
					titleSuffix={t('file.previewer.line.1')}
					headers={projectWizardState.currentProject.fileInfo?.headers}
					data={projectWizardState.currentProject.fileInfo?.data}
					percentageUploaded={
						projectWizardState.selectFileStep.fileAlreadyProcessed ||
						projectWizardState.currentProject.fileInfo?.uploadInfo?.file_import_id !== undefined
							? 100
							: projectWizardState.selectFileStep.percentageUploaded
					}
					uploadingFile={projectWizardState.selectFileStep.uploadingFile}
					onConfirm={() => {
						if (doesLookGood()) onConfirm()
						else setShowConfirmationModal(true)
					}}
					onCancel={() => {
						dispatch(replaceFileAction())
						onCancel()
					}}
					fileAlreadyProcessed={projectWizardState.selectFileStep.fileAlreadyProcessed}
					detectedDelimiter={projectWizardState.currentProject.fileInfo?.delimiter}
					file={projectWizardState.currentProject.fileInfo?.file}
					testId="upload-file-previewer"
					showFileDelimiters={enableFileDelimiters}
				/>
			)}
		</>
	)
}
