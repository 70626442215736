import { ColorBlueBrand, ColorWhite } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../local-core-ui'
import styles from './filter-summary.module.scss'

export interface Filter {
	key: string
	label: string
}

interface FilterSummaryProps {
	filters: Filter[]
	onAddFilterClicked(): void
	onMoreClicked(): void
	onRemovedFilter(newList: Filter[], removedFilter: string): void
	testId: string
}

export const FilterSummary = ({
	filters,
	onAddFilterClicked,
	onMoreClicked,
	onRemovedFilter,
	testId
}: FilterSummaryProps): ReactElement => {
	const { t } = useTranslation()
	const [filterList, setFilterList] = useState<Filter[]>([])

	const maxDisplay = 4
	const filterListSize = filterList.length
	const showMore = filterListSize > maxDisplay
	const extraFilters = filterListSize - maxDisplay

	const handleRemove = (idx: number) => {
		const newList = [...filterList]
		const removedFilter = newList[idx].key
		newList.splice(idx, 1)
		setFilterList(newList)
		onRemovedFilter([...newList].reverse(), removedFilter)
	}

	useEffect(() => {
		setFilterList([...filters].reverse())
	}, [filters])

	return (
		<div className={styles.filterSummaryContainer}>
			<button
				className={styles.filterSummaryPillBtn}
				onClick={onAddFilterClicked}
				data-testid={`${testId}-fs-add-btn`}
			>
				<span className={styles.filterSummaryPillPlus}>
					<Icon testId="plus-filter-summary" type="plus" color={ColorBlueBrand} size="mini" />
				</span>
				{t('filter.summary.filter')}
			</button>
			{filterList.slice(0, maxDisplay).map((filter, idx) => (
				<button
					key={`${filter.key}-${idx}`}
					className={styles.filterSummaryPill}
					onClick={() => handleRemove(idx)}
					data-testid={testId}
				>
					<p className={styles.filterSummaryPillText} title={filter.label}>
						{filter.label}
					</p>
					<span className={styles.filterSummaryPillX}>
						<Icon testId="X-filter-summary" type="x" color={ColorWhite} />
					</span>
				</button>
			))}
			{showMore && (
				<button
					className={styles.filterSummaryMore}
					onClick={onMoreClicked}
					data-testid={`${testId}-fs-more-btn`}
				>
					{t('filter.summary.more', { count: extraFilters })}
				</button>
			)}
		</div>
	)
}
