import cx from 'classnames'
import { camelCase } from 'lodash-es'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFeatures } from '../../../../../hooks/useEntitlements'
import { useMatchRecommendation } from '../../../../../queries/useMatchRecommendation'
import { useMatchSummaryCodes } from '../../../../../queries/useMatchSummaryCodes'
import { MatchRuleInfo } from '../../../../../store/projectWizard/types'
import {
	GetMatchRecommendationProfile,
	Results
} from '../../../../../types/match-recommendation/GetMatchRecommendationProfile'
import { MatchRecommendationSummary } from '../../match-step/match-recommendation/match-recommendation-summary'
import { getInclusionCriteria } from '../getInclusionCriteria'
import styles from './match-recommendation-summary-section.module.scss'

interface IMatchRecommendationSummarySectionProps {
	matchRuleInfo: MatchRuleInfo
}

export function MatchRecommendationSummarySection({
	matchRuleInfo
}: IMatchRecommendationSummarySectionProps): ReactElement {
	const EnablePassProfile = useFeatures(['EnablePassProfile'])
	const profiles = ['stewardshipProfiles']
	if (EnablePassProfile) profiles.push('passProfiles')
	const { t } = useTranslation()
	const matchCodesQuery = useMatchSummaryCodes(true)
	const matchRecommendationProfiles = useMatchRecommendation(profiles)
	const [stewardshipProfiles, setStewardshipProfiles] = useState<Results>()
	const [passProfiles, setPassProfiles] = useState<Array<string>>()
	const [summaryData, setSummaryData] = useState<Results>()
	const inclusionCriteria = getInclusionCriteria(matchRuleInfo)

	useEffect(() => {
		const typeProfilesMR = matchRecommendationProfiles.data as unknown as Array<GetMatchRecommendationProfile>
		if (typeProfilesMR !== undefined) {
			typeProfilesMR.forEach((profile) => {
				const nameProfile = Object.keys(profile)[0]
				if (nameProfile === 'stewardshipProfiles') {
					setStewardshipProfiles(profile[nameProfile])
				} else if (nameProfile === 'passprofiles') {
					setPassProfiles(profile[nameProfile] as unknown as Array<string>)
				}
			})
		}
	}, [matchRecommendationProfiles.data])

	const getSummaryData = () => {
		if (stewardshipProfiles !== undefined && matchRuleInfo.matchRule.stewardshipProfile) {
			const outcomeList = stewardshipProfiles[matchRuleInfo.matchRule.stewardshipProfile]
			delete outcomeList['rule']
			setSummaryData(outcomeList as unknown as Results)
		}
	}

	const getRuleLabel = (profile: string, rule: string) => {
		return `match.recommendation.${profile}.rules.${camelCase(rule)}`
	}

	useEffect(() => {
		if (matchRecommendationProfiles.data) getSummaryData()
	}, [stewardshipProfiles, passProfiles])

	return (
		<div className={styles.matchRecommendationSummarySection}>
			<div className={styles.row}>
				<div className={styles.ruleName}>{t('title.profile.precedenceProfiles')}</div>
				<div className={styles.selection}>
					{t(getRuleLabel('precedence', matchRuleInfo.matchRule.precedenceProfile ?? ''))}
				</div>
				<div className={cx(styles.ruleName, styles.ruleNameSpacing)}>
					{t('title.profile.stewardshipProfiles')}
				</div>
				<div className={styles.selection}>
					{t(getRuleLabel('stewardship', matchRuleInfo.matchRule.stewardshipProfile ?? ''))}
				</div>
				{EnablePassProfile && (
					<>
						<div className={cx(styles.ruleName, styles.ruleNameSpacing)}>
							{t('title.profile.passProfiles')}
						</div>
						<div className={styles.selection}>
							{t(getRuleLabel('passProfile', matchRuleInfo.matchRule.passProfile ?? ''))}
						</div>
					</>
				)}
			</div>
			<div className={styles.row}>
				<div className={styles.ruleName}>{t('inclusion.criteria')}</div>
				{inclusionCriteria.length > 0 ? (
					inclusionCriteria.map((inclusionCriteria, index) => (
						<div key={`${inclusionCriteria}-${index}`} className={styles.selection}>
							{t(inclusionCriteria)}
						</div>
					))
				) : (
					<div key={`${inclusionCriteria}-empty}`} className={styles.selection}>
						{t('match.rule.summary.tile.inclusion.empty')}
					</div>
				)}
			</div>

			{summaryData !== undefined && (
				<>
					<div className={styles.description}>{t('title.column.profiles.match.description')}</div>
					<p className={styles.subtitle}>{t('file.matching.reminder')}</p>
					<MatchRecommendationSummary
						summaryCodes={matchCodesQuery?.data || []}
						summaryData={summaryData}
						ruleOrder={matchRuleInfo.matchRule.precedenceCriterion || []}
					/>
				</>
			)}
		</div>
	)
}
