import { useQuery, UseQueryResult } from 'react-query'
import { RecordClassification } from '../components/steward-filter-tabs/steward-filter-tabs'
import { useApi } from '../hooks'
import { PageSize, TileTypes } from '../store/steward/types'
import { PublishedList } from '../types'
import { getRecordsToSteward } from './api/getRecordsToSteward'

export const useRecordsToSteward = (
	forTile: TileTypes | undefined,
	pageIndex: number,
	pageSize: PageSize,
	requestedClassification?: RecordClassification,
	forAssignee?: string,
	forAssignment?: string
): UseQueryResult<PublishedList, unknown> => {
	const apiClient = useApi()

	const queryKey = [
		'getRecordsToSteward',
		{ forTile, pageIndex, pageSize, requestedClassification, forAssignee, forAssignment }
	]
	const queryFunction = () =>
		getRecordsToSteward(
			apiClient,
			forTile,
			pageIndex,
			pageSize,
			requestedClassification,
			forAssignee,
			forAssignment
		)
	return useQuery(queryKey, queryFunction)
}
