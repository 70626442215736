import cx from 'classnames'
import { FC, PropsWithChildren, ReactElement, useRef } from 'react'
import styles from './report-table.module.scss'

interface ColDef {
	label?: string
	field: string
	width?: number
	align?: 'left' | 'right' | 'center'
}

type CellDef = string | ReactElement

type RowDef = CellDef[]

interface ReportTableProps {
	hideBorders?: boolean
	columns: ColDef[]
	hideHeader?: boolean
	rowHeight?: string
	rows: RowDef[]
	zebraStripes?: 'even' | 'odd'
}

interface ReportTableWrapperProps {
	hideBorder?: boolean
	width?: string
}

interface ChartKeyProps {
	label: string
	color: string
}

const ChartKey = ({ label, color }: ChartKeyProps) => {
	return (
		<div className={styles.chartKeyContainer}>
			<div className={styles.chartKeyDot} style={{ backgroundColor: color }} />
			<span>{label}</span>
		</div>
	)
}

const ReportTableWrapper = ({ hideBorder = false, width, children }: PropsWithChildren<ReportTableWrapperProps>) => {
	return (
		<div
			className={cx(styles.reportTableWrapper, { [styles.noBorder]: hideBorder })}
			style={{ width: width ?? '100%' }}
		>
			{children}
		</div>
	)
}

const ReportTable: FC<ReportTableProps> = ({
	hideBorders = false,
	columns,
	hideHeader = false,
	rowHeight,
	rows,
	zebraStripes = 'odd'
}: ReportTableProps) => {
	const rootRef = useRef<HTMLDivElement | null>(null)

	const getColWidth = ({ width }: ColDef) => {
		let computedWidth
		const tableWidth = rootRef.current?.clientWidth ?? 0
		const columnStyle = Object()
		const columnWidths: number[] = []

		columns.forEach((column) => {
			if (column.width) {
				columnWidths.push(column.width)
			}
		})

		const definedWidth = columnWidths.reduce((acc, width) => {
			return (acc += width)
		}, 0)

		const remainingWidth = tableWidth - definedWidth
		const columnsWithoutDefinedWidth = columns.length - columnWidths.length

		if (width) {
			computedWidth = width.toString() + 'px'
		} else {
			if (columnWidths.length > 0) {
				computedWidth = remainingWidth / columnsWithoutDefinedWidth + 'px'
			} else {
				const columnWidth = (tableWidth / columns.length).toString()
				computedWidth = columnWidth + 'px'
			}
		}

		columnStyle['width'] = computedWidth
		columnStyle['maxWidth'] = computedWidth

		return columnStyle
	}

	const getCelStyle = (cellIdx: number) => {
		const columnStyle = columns[cellIdx]
		let celStyle = getColWidth(columnStyle)

		if (columnStyle.align) {
			celStyle = { textAlign: columnStyle.align, ...celStyle }
		}

		return celStyle
	}

	const getColumn = (cellIdx: number) => columns[cellIdx]

	return (
		<div className={styles.reportTable} ref={rootRef}>
			<table className={cx(styles.table, { [styles.noBorder]: hideBorders })}>
				<colgroup>
					{columns.map((column, idx) => (
						<col key={`col-${idx}`} style={getColWidth(column)} />
					))}
				</colgroup>

				{!hideHeader && (
					<thead>
						<tr style={{ height: rowHeight ?? '' }}>
							{columns.map(({ field, label }, idx) => (
								<th key={`col-${field}`} style={getCelStyle(idx)}>
									{label ?? null}
								</th>
							))}
						</tr>
					</thead>
				)}

				<tbody
					className={cx({
						[styles.stripedOdd]: zebraStripes === 'odd',
						[styles.stripedEven]: zebraStripes === 'even'
					})}
				>
					{rows.map((row, i) => (
						<tr key={`row-${i}`} style={{ height: rowHeight ?? '' }}>
							{row.map((cell, j) => {
								const columnField = getColumn(j).field
								const key = `col-${columnField}-row-${i}`
								return (
									<td key={key} style={getCelStyle(j)}>
										{cell}
									</td>
								)
							})}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

export { ReportTable, ReportTableWrapper, ChartKey }
export type { ColDef, CellDef, RowDef, ReportTableProps, ReportTableWrapperProps }
