import {
	ColorBlueBrand,
	ColorBluePrimary,
	ColorFontDisabled,
	ColorJetBlack,
	ColorWhite
} from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, IconType } from '../../local-core-ui'
import { btnOptions } from './footer-buttons'
import styles from './footer-buttons.module.scss'

const darkBtn = {
	normalState: {
		textColor: ColorJetBlack,
		backgroundColor: ColorWhite,
		borderColor: ColorJetBlack
	},
	hoverState: {
		textColor: ColorJetBlack,
		backgroundColor: ColorWhite,
		borderColor: ColorJetBlack
	},
	focusState: {
		textColor: ColorJetBlack,
		backgroundColor: ColorWhite,
		borderColor: ColorJetBlack
	},
	disabledState: {
		textColor: ColorFontDisabled,
		backgroundColor: ColorWhite,
		borderColor: ColorFontDisabled
	}
}

const blueBtn = {
	normalState: {
		textColor: ColorWhite,
		backgroundColor: ColorBlueBrand,
		borderColor: ColorBlueBrand
	},
	hoverState: {
		textColor: ColorWhite,
		backgroundColor: ColorBluePrimary,
		borderColor: ColorBluePrimary
	},
	focusState: {
		textColor: ColorWhite,
		backgroundColor: ColorBluePrimary,
		borderColor: ColorBluePrimary
	},
	disabledState: {
		textColor: ColorFontDisabled,
		backgroundColor: ColorBlueBrand,
		borderColor: ColorBlueBrand
	}
}

interface ConditionalBtnProps {
	onClick?(): void
	iconType?: IconType
	isPrimary?: boolean
	type: btnOptions
	tValue?: string
	condition?: () => boolean
}

export const ConditionalBtn = ({
	onClick,
	iconType,
	isPrimary,
	type,
	tValue,
	condition = () => true
}: ConditionalBtnProps): ReactElement | null => {
	const { t } = useTranslation()
	const iconColor = isPrimary ? ColorWhite : ColorJetBlack
	const btnColors = isPrimary ? blueBtn : darkBtn

	return onClick !== undefined && condition() ? (
		<div className={styles.footerBtnContainer}>
			<Button
				text={t(`footer.buttons.${type}`, { tValue })}
				onClick={() => {
					if (onClick) onClick()
				}}
				size="medium"
				testId={`footer-btn-${type}`}
				colors={btnColors}
				iconType={iconType}
				iconColor={iconColor}
				isDisabled={onClick === undefined}
			/>
		</div>
	) : null
}
