import { TFunction } from 'react-i18next'
import { default as translations } from './sideblocks.text.json'

export const sideBlocksStringReplace: TFunction<'translation', undefined> = (key) => {
	type ObjectKey = keyof typeof translations

	const translationKey = key as ObjectKey

	return translations[translationKey] || translationKey
}
