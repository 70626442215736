import cx from 'classnames'
import * as d3 from 'd3'
import { FC, MouseEvent, RefObject, useEffect, useMemo, useRef, useState } from 'react'
import world from '../assets/data/world.json'
import styles from './country-select-with-map.module.scss'
import { smallCountriesIdList } from './small-countries-list'

interface ICountry {
	properties: {
		name: string
	}
	id2: string
}

export type RegionOptions = 'world' | 'amer' | 'emea' | 'apac' | 'au' | 'as' | 'eu' | 'na' | 'sa' | 'af'

export interface ICountrySelectMapProps {
	selectedCountries: Array<string>
	disabledCountries?: Array<string>
	width?: number
	readOnly?: boolean
	toggleCountry(country: string): void
	countryTranslationFunction(country: ICountry): string
	selectedRegion?: RegionOptions
	tooltipDataChanged(data: ITooltipData): void
	tooltip: RefObject<HTMLDivElement>
	color?: string
	testId: string
}

export interface ITooltipData {
	countryId?: string
	countryName?: string
	visible: boolean
}

const defaultWidth = 700

export const CountrySelectMap: FC<ICountrySelectMapProps> = ({
	selectedCountries,
	disabledCountries = [],
	width = defaultWidth,
	readOnly = false,
	toggleCountry,
	countryTranslationFunction,
	selectedRegion = 'world',
	tooltipDataChanged,
	tooltip,
	color,
	testId
}: ICountrySelectMapProps) => {
	const zoomSet = {
		world: {
			scale: 100,
			translate: [0, 0]
		},
		na: {
			scale: 200,
			translate: [450, 50]
		},
		sa: {
			scale: 290,
			translate: [460, -430]
		},
		eu: {
			scale: 300,
			translate: [20, 125]
		},
		as: {
			scale: 200,
			translate: [-220, -70]
		},
		af: {
			scale: 300,
			translate: [20, -275]
		},
		oc: {
			scale: 350,
			translate: [-880, -510]
		},

		emea: {
			scale: 150,
			translate: [50, -30]
		},
		apac: {
			scale: 200,
			translate: [-340, -170]
		},
		amer: {
			scale: 150,
			translate: [400, -120]
		}
	}

	const mapContainer = useRef<HTMLDivElement>()
	const svgMap = useRef<SVGSVGElement>()

	const [scale, setScale] = useState(zoomSet[selectedRegion].scale)
	const [translateX, setTranslateX] = useState((zoomSet[selectedRegion].translate[0] * width) / defaultWidth)
	const [translateY, setTranslateY] = useState((zoomSet[selectedRegion].translate[1] * width) / defaultWidth)

	const path = useMemo(() => {
		const projection = d3.geoMercator().fitWidth(width, world)
		return d3.geoPath(projection)
	}, [scale, translateX, translateY])

	const [tooltipData, setTooltipData] = useState<ITooltipData>({
		visible: false
	})

	useEffect(() => {
		tooltipDataChanged(tooltipData)
	}, [tooltipData])

	const height: number = width * 0.65

	const animateTo = (target) => {
		setScale(zoomSet[target].scale)
		setTranslateX((zoomSet[target].translate[0] * width) / defaultWidth)
		setTranslateY((zoomSet[target].translate[1] * width) / defaultWidth)
	}

	useEffect(() => {
		const transform = `translate(${translateX},${translateY})scale(${scale / 100})`

		d3.select(svgMap.current).transition().duration(750).attr('transform', transform)
	}, [scale, translateX, translateY])

	useEffect(() => {
		animateTo(selectedRegion)
	}, [selectedRegion])

	const pathMouseOver = (countryId: string, countryName: string): void => {
		setTooltipData({ countryId, countryName, visible: true })
	}

	const pathMouseOut = (): void => {
		setTooltipData({ visible: false })
	}

	const mapMouseMove = (event: MouseEvent): void => {
		const offset = mapContainer.current.getBoundingClientRect()
		tooltip.current.setAttribute(
			'style',
			'left:' + (event.clientX - offset.x + 5) + 'px; top: ' + (event.clientY - offset.y - 35) + 'px;'
		)
	}

	// hack to avoid overlapping border lines that look funky on focus
	const pathFocus = (countryId: string): void => {
		svgMap.current.querySelectorAll(`path:not(#country-${countryId})`).forEach((otherPath) => {
			svgMap.current.prepend(otherPath)
		})
	}

	const pathKeyUp = (event: KeyboardEvent, countryId: string): void => {
		if (event.keyCode == 13 || event.keyCode == 32) {
			toggleCountry(countryId)
		}
	}

	const pathClick = (countryId: string): void => {
		toggleCountry(countryId)
	}

	return (
		<>
			<div
				data-testid={`map-container-general-${testId}`}
				ref={mapContainer}
				className={cx(styles.mapContainer, { [styles.readOnly]: readOnly })}
			>
				<svg
					ref={svgMap}
					className={cx(styles.map, styles[`zoom${Math.floor(zoomSet[selectedRegion].scale / 50)}`])}
					onMouseMove={mapMouseMove}
					style={{ height: height + 'px' }}
					data-testid={testId + '-svg'}
				>
					<defs data-testid={`map-defs-${testId}`}>
						<pattern
							id="disabled-fill"
							className={styles.disabledFill}
							x="0"
							y="0"
							width="8"
							height="8"
							patternUnits="userSpaceOnUse"
							data-testid={`map-pattern-${testId}`}
						>
							<line data-testid={`map-line-1-${testId}`} x1="6" y1="-2" x2="-2" y2="6" />
							<line data-testid={`map-line-2-${testId}`} x1="10" y1="2" x2="2" y2="10" />
						</pattern>
					</defs>
					{world.features.map((country, countryIndex) => {
						const interactiveProps =
							readOnly || disabledCountries.includes(country.id)
								? {}
								: {
										tabIndex: countryIndex,
										onFocus: () => {
											pathFocus(country.id)
										},
										onKeyUp: (event) => {
											pathKeyUp(event, country.id)
										},
										onClick: () => {
											pathClick(country.id)
										}
								  }
						return (
							<path
								key={country.id}
								id={'country-' + country.id}
								d={path(country)}
								className={cx({
									[styles.selected]: selectedCountries.includes(country.id),
									[styles.disabled]: disabledCountries.includes(country.id),
									[styles.smallCountry]: smallCountriesIdList.includes(country.id)
								})}
								onMouseOver={
									!readOnly
										? () => {
												pathMouseOver(country.id2, countryTranslationFunction(country))
										  }
										: null
								}
								onMouseOut={pathMouseOut}
								style={{
									fill: selectedCountries.includes(country.id) && color ? color : ''
								}}
								{...interactiveProps}
								data-testid={testId + '-country-' + country.id}
							/>
						)
					})}
				</svg>
			</div>
			{/* <div>
				Scale: <input type="number" step={10} value={scale} onChange={(e)=>{setScale(parseInt(e.target.value))}} /><br/>
				TanslateX: <input type="number" step={10} value={translateX} onChange={(e)=>{setTranslateX(parseInt(e.target.value))}} /><br/>
				TranslateY: <input type="number" step={10} value={translateY} onChange={(e)=>{setTranslateY(parseInt(e.target.value))}} />
			</div> */}
		</>
	)
}
