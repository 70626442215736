import { ChangeEvent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckBox } from '../../local-core-ui'
import { Element } from '../../types'
import { ElementUIFacade } from '../data-block-searcher/ElementUIFacade'
import styles from './enrichment-preview-panel-item.module.scss'

export interface EnrichmentPreviewPanelItemProps {
	item: ElementUIFacade
	isChecked?: boolean
	isDisabled?: boolean
	displayCheckBox?: boolean
	onAdd?: (element: Element) => void
	onRemove?: (element: Element) => void
}

export const EnrichmentPreviewPanelItem = ({
	item,
	isChecked,
	isDisabled,
	displayCheckBox,
	onAdd,
	onRemove
}: EnrichmentPreviewPanelItemProps): ReactElement => {
	const { t } = useTranslation()

	const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
		if (event.target.checked && onAdd) onAdd(item)
		else if (onRemove) onRemove(item)
	}
	return (
		<div className={styles.enrichingPanelItem}>
			<div className={styles.enrichingPanelItemCheckBox}>
				{displayCheckBox && (
					<CheckBox
						checked={isChecked || false}
						id={'enriching-panel-item'}
						testId={'enriching-panel-checkbox-item'}
						disabled={isDisabled}
						onChange={onChange}
					/>
				)}
			</div>
			<div>
				<div
					className={`${styles.enrichingModalPanelItemName} ${
						isDisabled ? styles.enrichingModalPanelItemNameDisabled : ''
					}`}
				>
					{item.displayName}
				</div>
				<div className={styles.enrichingModalPanelItemDetails}>
					<b>
						{t(item.blockId || '')} {t(`data.block.element.selector.level`)}
					</b>
					{item.level}
					{item.dataType && (
						<b>
							{t(`data.block.element.selector.type`)}
							{': '}
						</b>
					)}
					{item.dataType}
					{item.example && (
						<b>
							{t(`data.block.element.selector.example`)}
							{': '}
						</b>
					)}
					{item.example}
				</div>
				<div className={styles.enrichingModalPanelItemDescription}>{item.description}</div>
			</div>
		</div>
	)
}
