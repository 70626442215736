import { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import information from '../../assets/images/info.svg'
import { DropdownOptions, Grid } from '../../local-core-ui'
import { EnrichmentRuleSetting } from '../enrichment-rule-setting/enrichment-rule-setting'
import styles from './enrichment-rules-settings-collection.module.scss'

export interface EnrichmentRuleSettingRow {
	id: string
	label: string
	isRequired: boolean
	selected?: string
	errorMessage?: string
	alwaysSelected: boolean
	disabled?: boolean
	validOptions: Array<string>
	recommendedOption?: string
}

export interface EnrichmentRulesSettingsCollectionProps {
	rows: Array<EnrichmentRuleSettingRow>
	options: Array<DropdownOptions>
	testId: string
	collectionId?: string
	disabled?: boolean
	onChange?: (row: EnrichmentRuleSettingRow) => void
	crmSource: string
	usedOptions: Array<string | undefined>
}

export const EnrichmentRulesSettingsCollection = ({
	rows,
	testId,
	collectionId = '',
	disabled = false,
	onChange,
	crmSource,
	options,
	usedOptions
}: EnrichmentRulesSettingsCollectionProps): ReactElement => {
	const { t } = useTranslation()

	const getSelectedLabel = (selected: string | undefined, options: Array<DropdownOptions>) => {
		return (options.find(({ value }) => value === selected) || { label: '' }).label
	}

	const onRowChange = (row: EnrichmentRuleSettingRow, selected?: string, alwaysSelected?: boolean) => {
		const newRow = { ...row }
		newRow.selected = selected || ''
		if (alwaysSelected !== undefined) newRow.alwaysSelected = alwaysSelected
		if (onChange) onChange(newRow)
	}

	const calculateOptions = (
		options: Array<DropdownOptions>,
		row: EnrichmentRuleSettingRow,
		currentlyUsedOptions: Array<string | undefined>
	) => {
		const result: Array<DropdownOptions> = []
		options
			.filter((option) => row.validOptions.includes(option.value))
			.forEach((option) => {
				//Making a copy so that we don't change the labels for other dnb field's options
				const thisOption = { ...option }

				//If an option has already been taken by another field, disable it
				thisOption.disabled =
					currentlyUsedOptions.includes(thisOption.value) && thisOption.value !== row.selected

				//If this is the recommended field, add the correct label and add to the top of the list
				if (thisOption.value === row.recommendedOption) {
					thisOption.label = t('enrich.crm.recommended.field', { sourceField: thisOption.label })
					result.unshift(thisOption)
				} else result.push(thisOption)
			})
		return result
	}

	const availableOptionsMap = useMemo<Map<string, Array<DropdownOptions>>>(() => {
		const newAvailableOptions = new Map<string, Array<DropdownOptions>>()

		rows.forEach((row) => {
			newAvailableOptions.set(row.id, calculateOptions(options, row, usedOptions))
		})
		return newAvailableOptions
		/**
		 * calculateOptions is not added as a dependency because it would cause the other dependencies to change with each render.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options, rows, usedOptions])

	return (
		<>
			{collectionId === 'entityresolution' && (
				<div
					data-testid="enrichment-rules-previewer-box-info-entityresolution"
					className={styles.dataBlockPreviewerBoxInformation}
				>
					<div className={styles.informationContainer}>
						<img className={styles.informationIcon} src={information} alt={'information'} />
						<div>{t('dataBlock.previewer.information.entityResolution')}</div>
					</div>
				</div>
			)}
			<div data-testid="enrichment-rules-settings-collection-container" className={styles.dropdownCollection}>
				<Grid testId="dropdown-rules-collection-first-container" container>
					<Grid testId="dropdown-rules-collection-second-container" sm={8}>
						<Grid testId="dropdown-rules-collection-third-container" container>
							<Grid testId="dropdown-rules-collection-label-container" sm={6}>
								<label className={styles.header}>{t('enrich.crm.name.column.product.element')}</label>
							</Grid>
							<Grid testId="dropdown-rules-collection-account-container" sm={6}>
								<div className={styles.accountColumn}>
									<label className={styles.header}>
										{t('enrich.crm.name.column.source.field', { object: t(`${crmSource}`) })}
									</label>
								</div>
							</Grid>
						</Grid>
					</Grid>
					<Grid testId="dropdown-rules-collection-settings-container" sm={4}>
						<div className={styles.centeredColumn}>
							<label className={styles.header}>{t('enrich.crm.name.column.write.settings')}</label>
						</div>
					</Grid>
				</Grid>
				{rows
					.filter((row) => row.disabled)
					.map((row: EnrichmentRuleSettingRow) => (
						<EnrichmentRuleSetting
							id={`${collectionId}-${row.id}`}
							key={`${collectionId}-${row.id}`}
							label={row.label}
							options={availableOptionsMap?.get(row.id) || []}
							selected={getSelectedLabel(row.selected, options)}
							testId={testId + '-dd-collection'}
							alwaysSelected={row.alwaysSelected}
							onChangeFunction={(selection, alwaysSelected) =>
								onRowChange(row, selection, alwaysSelected)
							}
							disabled={disabled || row.disabled}
						/>
					))}
				{rows.filter((row) => row.disabled).length > 0 ? <hr className={styles.separator} /> : undefined}
				{rows
					.filter((row) => !row.disabled)
					.map((row: EnrichmentRuleSettingRow) => (
						<EnrichmentRuleSetting
							id={`${collectionId}-${row.id}`}
							key={`${collectionId}-${row.id}`}
							label={row.label}
							options={availableOptionsMap?.get(row.id) || []}
							selected={getSelectedLabel(row.selected, options)}
							testId={testId + '-dd-collection'}
							alwaysSelected={row.alwaysSelected}
							onChangeFunction={(selection, alwaysSelected) =>
								onRowChange(row, selection, alwaysSelected)
							}
							disabled={disabled || row.disabled}
						/>
					))}
			</div>
		</>
	)
}
