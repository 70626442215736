import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './alphabetical-filter.module.scss'

export type selectionFunction = (letterSelected: string) => void
export type customCategory = { label: string; key: string; visible: boolean; enabled: boolean }

interface AlphabeticalFilterProps {
	onSelection: selectionFunction
	alphabet?: string[]
	itemsToFilter?: string[]
	testId: string
	selectedLetter?: string
	showAll?: boolean
	customCategories?: Array<customCategory>
}

export const AlphabeticalFilter: FC<AlphabeticalFilterProps> = ({
	onSelection,
	alphabet = [
		'A',
		'B',
		'C',
		'D',
		'E',
		'F',
		'G',
		'H',
		'I',
		'J',
		'K',
		'L',
		'M',
		'N',
		'O',
		'P',
		'Q',
		'R',
		'S',
		'T',
		'U',
		'V',
		'W',
		'X',
		'Y',
		'Z'
	],
	itemsToFilter = [],
	testId,
	selectedLetter = '',
	showAll = false,
	customCategories
}: AlphabeticalFilterProps) => {
	const { t } = useTranslation()
	let enabledLetters: Set<string>
	const [list, setList] = useState<Array<JSX.Element>>([])

	useEffect(() => {
		/**
		 * For each render or change in the effect dependencies it is required to re-set the value of enabledLetters.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
		enabledLetters = new Set<string>(itemsToFilter.map((item) => getFirstLetter(item)))
		buildList()
		/**
		 * We want to run this effect when the itemsToFilter or selectedLetter changes.
		 */
	}, [itemsToFilter, selectedLetter])

	const isEnabled = (letter: string) => enabledLetters?.has(letter)

	const getFirstLetter = (item: string) => {
		return item[0]?.toLowerCase()
	}

	const buildList = () => {
		const generatedComponent: Array<JSX.Element> = []
		if (customCategories) {
			generatedComponent.push(
				...customCategories
					.filter((c) => c.visible)
					.map((category) => (
						<li
							data-testid={testId + '-option-' + category.key}
							className={
								category.enabled
									? selectedLetter === category.key
										? styles.letterSelected
										: ''
									: styles.disabled
							}
							key={category.key}
							onClick={() => {
								if (category.enabled) {
									onSelection(category.key)
								}
							}}
						>
							{category.label}
						</li>
					))
			)
		}
		if (showAll)
			generatedComponent.push(
				<li
					data-testid={testId + '-all'}
					className={selectedLetter === 'All' ? styles.letterSelected : ''}
					onClick={() => {
						onSelection('All')
					}}
					key="All"
				>
					{t('alphabetical.filter.all')}
				</li>
			)
		generatedComponent.push(
			...alphabet?.map((letter) => (
				<li
					data-testid={testId + '-option-' + letter}
					className={
						isEnabled(letter.toLowerCase())
							? selectedLetter === letter
								? styles.letterSelected
								: ''
							: styles.disabled
					}
					key={letter}
					onClick={() => {
						if (isEnabled(letter.toLowerCase())) {
							onSelection(letter)
						}
					}}
				>
					{letter}
				</li>
			))
		)
		setList(generatedComponent)
	}

	return (
		<div className={styles.alphabeticalFilter}>
			<ul data-testid={testId + '-alphabetical-list'}>{list}</ul>
		</div>
	)
}
