import { MutableRefObject } from 'react'
import { TFunction } from 'react-i18next'
import { ElementUIFacade } from '../ElementUIFacade'
import tradeStyleNamesPrefixes from '../trade-style-names-element-prefixes.json'

const buildChildrenTree = (
	listFlattenElementsTSN: Array<ElementUIFacade>,
	root: ElementUIFacade,
	t: TFunction<'translation'>,
	tsnSelectorValueRef: MutableRefObject<number>
) => {
	const groupElements: Array<ElementUIFacade> = []
	tradeStyleNamesPrefixes.forEach((prefix) => {
		const elementList = listFlattenElementsTSN.filter((tsnElement) => tsnElement.elementId.startsWith(prefix))
		if (elementList && elementList.length > 0) {
			const groupParent: ElementUIFacade = {
				parent: root,
				elementId: `${prefix}Id`,
				displayName: t(`${prefix}.element`),
				description: elementList[0].description,
				level: elementList[0].level,
				dataType: elementList[0].dataType,
				example: elementList[0].example,
				showChildrenBlocks: false,
				considerChildrenForCount: false,
				maximumElementsInOutput: tsnSelectorValueRef,
				isRequiredByDefault: prefix === 'tsn_tradestylenames_name_'
			}
			elementList.sort((a: ElementUIFacade, b: ElementUIFacade) => {
				if (a.elementId > b.elementId) {
					return 1
				} else if (a.elementId < b.elementId) {
					return -1
				} else {
					return 0
				}
			})
			groupParent.childs = elementList
			groupElements.push(groupParent)
		}
	})
	return groupElements
}

export const buildTradeStyleNamesTree = (
	listFlattenElementsTSN: Array<ElementUIFacade>,
	t: TFunction<'translation'>,
	tsnSelectorValueRef: MutableRefObject<number>
): Array<ElementUIFacade> => {
	const tree: Array<ElementUIFacade> = []
	if (listFlattenElementsTSN.length) {
		const root: ElementUIFacade = {
			blockId: t(listFlattenElementsTSN[0].blockId || ''),
			elementId: 'tradeStyleNamesId',
			displayName: t('tradeStyleNames.element'),
			description: t('tradeStyleNames.description'),
			level: '1',
			dataType: t('ARRAY'),
			showChildrenBlocks: true,
			containsChildrenSelector: true,
			considerChildrenForCount: true,
			maximumElementsInOutput: tsnSelectorValueRef,
			outputDropdownOptions: [
				{ label: '1', value: '1' },
				{ label: '2', value: '2' },
				{ label: '3', value: '3' },
				{ label: '4', value: '4' },
				{ label: '5', value: '5' }
			]
		}
		tree.push(root)

		root.childs = buildChildrenTree(listFlattenElementsTSN, root, t, tsnSelectorValueRef)
	}
	return tree
}
