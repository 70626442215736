import i18next from 'i18next'

type DateToFormat = Date | number

export const getLocalizedDateString = (date: DateToFormat): string => {
	if (typeof date === 'number') {
		date = new Date(date)
	}
	return date.toLocaleDateString(i18next.language)
}

export const getLocalizedTimeString = (date: DateToFormat): string => {
	if (typeof date === 'number') {
		date = new Date(date)
	}
	return date.toLocaleTimeString(i18next.language)
}
