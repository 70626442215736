import { ChangeEvent, DragEvent, FC, Ref, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import information from '../../assets/images/info.svg'
import UploadParser from '../../helpers/uploadParser'
import { useFeatures, useModules } from '../../hooks/useEntitlements'
import { DragDrop, ErrorState, Grid, Input, LoadingState } from '../../local-core-ui'
import { InputType } from '../../project/steps/setup-source/select-input/selectInput'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import { updateCurrentProjectAction } from '../../store/projectWizard/actions'
import { AmeToggle } from '../ameToggle/ameToggle'
import { DescriptionTextArea } from '../description-text-area/description-text-area'
import { StewardableToggle } from '../stewardableToggle/stewardableToggle'
import { UploadTypeCard } from '../upload-type-card/upload-type-card'
import styles from './upload-chooser.module.scss'

interface UploadChooserProps {
	onFileSelect: (file: File, firstRows: [][], delimiter: string) => void
	onSourceChange?: (sourceName: string) => void
	sourceValue?: string
	title?: string
	disabled?: boolean
	isFileReplaceable?: boolean
	testId: string
	selectedDelimiter?: string
	acceptsApiSources?: boolean
}

export const UploadChooser: FC<UploadChooserProps> = ({
	onFileSelect,
	onSourceChange,
	sourceValue,
	title,
	disabled = false,
	isFileReplaceable = true,
	testId,
	selectedDelimiter,
	acceptsApiSources = false
}: UploadChooserProps) => {
	const enableFileDelimiters = useFeatures(['EnableFileDelimiters'])
	const { t } = useTranslation()
	const sampleFileEl: Ref<HTMLAnchorElement> = useRef(null)
	const sampleFile = '/DnB Connect Sample CSV Template.csv'
	const [sourceName, setSourceName] = useState(sourceValue || '')
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [errorMessage, setErrorMessage] = useState<string | undefined | JSX.Element>()
	const [missingSource, setMissingSource] = useState(false)
	const [retryText, setRetryText] = useState<string>('')
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const dispatch = useAppDispatch()
	const isAMEEnabled = useFeatures(['EnableAmeMatch'])
	const isStewardshipEnabled = useModules(['Stewardship'])

	const isBeta = useFeatures(['APIBetaBanner'])

	const [fileCheckboxState, setFileCheckboxState] = useState<boolean>(projectWizardState.currentProject.source.isFile)
	const [apiCheckboxState, setApiCheckboxState] = useState<boolean>(projectWizardState.currentProject.source.isApi)
	let delimiter = ''

	const isDragEvent = (
		event: ChangeEvent<HTMLInputElement> | DragEvent<HTMLInputElement>
	): event is DragEvent<HTMLInputElement> => {
		return (event as DragEvent<HTMLInputElement>).dataTransfer !== undefined
	}

	const fileSelected = async (
		event: ChangeEvent<HTMLInputElement> | DragEvent<HTMLInputElement>,
		fileApproved: boolean
	) => {
		let file: File | undefined

		if (isDragEvent(event)) {
			file = event?.dataTransfer?.files[0]
		} else {
			const changeEvent: ChangeEvent<HTMLInputElement> = event
			if (changeEvent.target && changeEvent.target.files) file = changeEvent.target.files[0]
		}

		await validateFile(file, fileApproved)
	}

	const isEmpty = (text: string | null | undefined): boolean => {
		return text == null || text.match(/^\s*$/) !== null
	}

	const getDuplicateElements = (elements: Array<string>): Array<string> => {
		const elementList = elements.map((element) => element.toLowerCase().trim())
		return elementList.filter((element, index, arr) => arr.indexOf(element) !== index)
	}

	const validateFile = async (file: File | undefined, fileApproved: boolean) => {
		const maxSize = 100 * 1024 * 1024 // 100Meg
		const minSize = 15 // 15 bytes
		const unsupportedChars = new RegExp(/[@&$#\[\]()]/, 'g') // Unsupported special characters @&$#[]()
		if (file) {
			setIsLoading(true)
			setRetryText('')
			if (fileApproved) {
				if (enableFileDelimiters) {
					delimiter =
						selectedDelimiter ||
						(await UploadParser.delimiter(file).catch(() => {
							delimiter = 'NOT_SUPPORTED'
						})) ||
						''
				}
				if (file.size > maxSize) {
					setErrorMessage(getErrorMessage('maxSizeError'))
					setIsLoading(false)
				} else if (file.size < minSize) {
					setErrorMessage(getErrorMessage('emptyFileError'))
					setIsLoading(false)
				} else if (unsupportedChars.test(file.name)) {
					setErrorMessage(getErrorMessage('unsupportedCharError', file.name))
					setIsLoading(false)
				} else {
					const numberOfRows = 20
					const results = await UploadParser.parse(file, numberOfRows, delimiter)
					getDuplicateElements(results[0])
					if (results && results.length > 0 && results[0]) {
						const emptyHeaders = results[0].filter((column) => isEmpty(column))
						if (emptyHeaders.length) {
							setErrorMessage(getErrorMessage('emptyHeader'))
							setIsLoading(false)
							setRetryText('upload.tile.error.retry.text')
						} else {
							const duplicateElements = getDuplicateElements(results[0])
							if (duplicateElements.length === 0) {
								if (results[1] && results[1].toString().length > 0) {
									if (onSourceChange) onSourceChange(sourceName)
									onFileSelect(file, results, delimiter)
									setIsLoading(false)
								} else {
									setErrorMessage(getErrorMessage('emptyFileError'))
									setIsLoading(false)
								}
							} else {
								const duplicateElementsString = duplicateElements.join(', ')
								setErrorMessage(getErrorMessage('duplicityError', duplicateElementsString))
								setIsLoading(false)
							}
						}
					} else {
						setErrorMessage(getErrorMessage('emptyFileError'))
						setIsLoading(false)
					}
				}
			} else {
				setErrorMessage(getErrorMessage('rejectedFileError', file.name))
				setIsLoading(false)
			}
		}
	}

	const getErrorMessage = (errorType: string, errorVariable?: string): string | JSX.Element => {
		switch (errorType) {
			case 'maxSizeError':
				return (
					<Trans i18nKey={'upload.tile.error.size.warning'}>
						your file exceeds limit
						<br />
						upload a smaller file
						<br />
					</Trans>
				)
			case 'rejectedFileError':
				return (
					<Trans
						i18nKey="upload.chooser.modal.content.drag.drop.error"
						tOptions={{ fileName: errorVariable, delimitedFiles: '.csv' }}
					>
						The file is not supported.
						<br /> Currently only files with extensions .csv are supported.
					</Trans>
				)
			case 'emptyFileError':
				return t('upload.tile.error.empty.file') as string
			case 'duplicityError':
				return (
					<Trans i18nKey={'upload.tile.error.duplicate.warning'}>
						your file contains duplicates
						<br />
						please resolve duplicates
						<br />
						<br />
						<strong>5</strong>
						<span>{{ errorVariable }}</span>
					</Trans>
				)
			case 'emptyHeader':
				return (
					<Trans i18nKey={'upload.tile.error.empty.header'}>
						detected missing column headers
						<br />
						please update your file
						<br />
						replace the file
						<br />
						you’ll be on your way
					</Trans>
				)
			case 'unsupportedCharError':
				return (
					<Trans i18nKey="upload.tile.error.unsupported.char" tOptions={{ fileName: errorVariable }}>
						The file name contains unsupported special characters.
						<br />
						We dont support the following special characters: @&$#[]()
					</Trans>
				)
			default:
				return ''
		}
	}

	const canDisplayDragDrop = (): boolean => {
		if (acceptsApiSources) {
			return (apiCheckboxState && fileCheckboxState) || (!apiCheckboxState && fileCheckboxState)
		} else return true
	}

	const onDescriptionChange = (newDescription: string) => {
		dispatch(
			updateCurrentProjectAction({
				source: {
					sourceDescription: newDescription
				}
			})
		)
	}

	const onAMEChange = (enable: boolean) => {
		dispatch(
			updateCurrentProjectAction({
				source: {
					enableAme: enable
				}
			})
		)
	}

	const onStewardableChange = (enable: boolean) => {
		dispatch(
			updateCurrentProjectAction({
				source: {
					stewardable: enable
				}
			})
		)
	}

	const onApiChange = () => {
		const newState = !apiCheckboxState
		setApiCheckboxState(newState)
		if (acceptsApiSources) {
			dispatch(
				updateCurrentProjectAction({
					source: {
						isFile: fileCheckboxState,
						isApi: newState
					}
				})
			)
		}
	}

	const onFileChange = () => {
		const newState = !fileCheckboxState
		setFileCheckboxState(newState)
		if (acceptsApiSources) {
			dispatch(
				updateCurrentProjectAction({
					source: {
						isFile: newState,
						isApi: apiCheckboxState
					}
				})
			)
		}
	}

	return (
		<div data-testid="upload-chooser-page-container" className={styles.uploadChooserPageContainer}>
			{title ? (
				<h1 data-testid="source-project-title" className={styles.selectFileText} title={title}>
					{title}
				</h1>
			) : (
				<h1 data-testid="source-project-header">{t('upload.chooser.data.heading')}</h1>
			)}
			<div className={`${styles.helpLine} ${styles.firstHelpLine}`}>{t('upload.chooser.help.text.line1')}</div>
			<div className={styles.helpLine}>
				{t('upload.chooser.help.text.line2.normal')}
				<b> {t('upload.chooser.help.text.line2.bold')}</b>
			</div>
			<div className={styles.tipLine}>
				<img className={styles.informationImage} src={information} alt={'information'} />{' '}
				{t('upload.chooser.tip')}
			</div>
			{onSourceChange && (
				<div className={styles.sourceName}>
					<Grid testId="container-upload-chooser" container>
						<Grid testId="input-wrapper-upload-chooser" sm={6}>
							<div className={styles.inputWrapper}>
								<Input
									id="source-name"
									label={t('upload.chooser.source.name')}
									hint={t('upload.chooser.source.name.hint')}
									value={sourceName}
									onChangeFunction={(newSource) => {
										if (onSourceChange) onSourceChange(newSource)
										if (newSource.trim() === '') {
											setMissingSource(true)
											setSourceName('')
										} else {
											setMissingSource(false)
											setSourceName(newSource)
										}
									}}
									required={true}
									size="fluid"
									error={missingSource}
									errorMessage={missingSource ? (t('missing.source.name') as string) : ''}
									onBlurFunction={() => {
										setMissingSource(sourceName.trim() === '')
									}}
									maxLength={30}
									disabled={disabled}
									testId={testId + '-upload-source-name'}
								/>
							</div>
						</Grid>
						<Grid testId="upload-chooser-description" sm={6}>
							<div className={styles.sourceNameDescription}>
								{t('upload.chooser.source.name.description')}
							</div>
						</Grid>
						{isAMEEnabled ? (
							<Grid testId="ame-toogle-container" sm={12}>
								<AmeToggle
									checked={projectWizardState.currentProject.source.enableAme || false}
									onChange={(event) => onAMEChange(event.target.checked)}
									disabled={disabled}
								/>
							</Grid>
						) : undefined}
						{isStewardshipEnabled ? (
							<Grid testId="steward-toogle-container" sm={12}>
								<StewardableToggle
									checked={projectWizardState.currentProject.source.stewardable || false}
									onChange={(event) => onStewardableChange(event.target.checked)}
								/>
							</Grid>
						) : undefined}
					</Grid>
				</div>
			)}
			{acceptsApiSources && (
				<>
					<DescriptionTextArea
						placeholder={t('upload.chooser.description.placeholder') as string}
						value={projectWizardState.currentProject.source.sourceDescription}
						maxLength={500}
						onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onDescriptionChange(e.target.value)}
						testId={`upload-chooser-description-${testId}`}
						disabled={disabled}
					/>
					<div className={styles.checkboxesHeader}>{t('upload.chooser.checkboxes.header')}</div>
					<div className={styles.checkboxContainer}>
						<div className={styles.checkboxWrapper}>
							<UploadTypeCard
								type={InputType.API}
								checked={apiCheckboxState}
								isBeta={isBeta}
								disabled={true}
								onChange={onApiChange}
								group={'uploadChooser-Type'}
							/>
						</div>
						<div className={styles.checkboxWrapper}>
							<UploadTypeCard
								type={InputType.FILE}
								checked={fileCheckboxState}
								isBeta={false}
								onChange={onFileChange}
								disabled={disabled}
								group={'uploadChooser-Type'}
							/>
						</div>
					</div>
				</>
			)}
			{canDisplayDragDrop() && (
				<>
					<div className={styles.dragAndDropComponentContainer}>
						{isLoading && <LoadingState loadingMessage={t('upload.chooser.loading.message') as string} />}
						{errorMessage && (
							<ErrorState
								errorMessage={errorMessage}
								onClose={() => setErrorMessage(undefined)}
								testId={testId + '-error'}
								retryText={t(retryText) as string}
								onRetry={() => setErrorMessage(undefined)}
							/>
						)}
						<DragDrop
							id="drag-and-drop"
							accept=".csv"
							label={
								<p className="choose-file-text">
									<Trans i18nKey="upload.chooser.choose.file">
										<span className="text-link">Choose a file</span>
										<br />
									</Trans>
								</p>
							}
							onSelection={fileSelected}
							disabled={!isFileReplaceable || disabled}
							testId={testId + '-dd'}
						/>
					</div>
					<p className={styles.sampleCsvText}>
						<Trans i18nKey="upload.chooser.data.sample">
							Use the
							<a
								ref={sampleFileEl}
								className="text-link"
								href={sampleFile}
								download
								data-testid={testId + '-sample-link'}
							/>
							<br />
						</Trans>
					</p>
				</>
			)}
		</div>
	)
}
