import { useQuery, UseQueryResult } from 'react-query'
import ProjectUIFacade from '../dashboard/ProjectUIFacade'
import { useApi } from '../hooks'
import { getProjects } from './api/getProjects'

export const useProjects = (): UseQueryResult<ProjectUIFacade[]> => {
	const apiClient = useApi()

	const queryKey = ['getProjects']
	const queryFunction = () => getProjects(apiClient)

	return useQuery(queryKey, queryFunction)
}
