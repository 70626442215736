/**
 * BaseDonutChart
 * Receive data : {key1 : value, key2 : value}
 * keys type string
 * values type number
 * **/

import cx from 'classnames'
import * as d3 from 'd3'
import { isEqual } from 'lodash-es'
import { FC, useEffect, useRef } from 'react'
import { createTokenList, getValueToken } from '../design-tokens/utils'
import { drawKeys } from '../helpers/base-keys-for-chart'
import * as tokens from '../shared/token-colors.json'
import styles from './half-donut-chart.module.scss'

interface IDataValue {
	label: string
	value: number
}

interface IHalfDonutChartProps {
	centerTextColor?: string
	colors: Record<string, string>
	data: Record<string, unknown>
	defaultKey?: string
	disableHiddenBar?: boolean
	disableLegend?: boolean
	legendOrientation?: 'horizontal' | 'vertical'
	testId: string
	zeroStateColor?: string
}

interface CreateHalfDonutChartSVG {
	centerTextColor?: string
	colors: string[]
	data: IDataValue[]
	defaultKey: string
	disableHiddenBar: boolean
	disableLegend: boolean
	legendOrientation: IHalfDonutChartProps['legendOrientation']
	size: number
	zeroStateColor: string
}

function createHalfDonutChartSVG(SVGRef: SVGSVGElement) {
	const placeholder = d3.select(SVGRef)

	return ({
		centerTextColor,
		colors,
		data,
		defaultKey,
		disableHiddenBar,
		disableLegend,
		legendOrientation,
		size,
		zeroStateColor
	}: CreateHalfDonutChartSVG) => {
		const radius = size / 2
		const widthDonut = 24
		const donutChartPositionX = 167.5
		const donutChartPositionY = 136.5
		const labelsPositionX = 55
		const labelsPositionY = 24
		const limitLeftPositionXforLabel = 68
		const limitRightPositionXforLabel = 151
		const marginLabel = 8
		const limitPositionYforLabel = 8.5
		const pointMiddleX = 111.50000000000003
		const limitPositionXtoTextAnchor = 119.5
		const positionXcontainerText = 167
		const positionYcontainerText = 136
		const positionXlegends = 68
		const positionYlegends = 162.5
		const sizeBetweenLegendsIcon = 28
		const radiusLegendsIcon = 10
		const limitPercentageToActivateMouseOver = 97
		const cornerRadius = 4

		// Extract values from the data and create ColorFunction the color scale,
		// The range array will repeat if it’s shorter than the domain array.
		const angleRange = 0.5 * Math.PI

		const allZeroes = data.reduce((previous, currentValue) => previous && currentValue.value === 0, true)
		const total = data.reduce((previous, currentValue) => previous + currentValue.value, 0)

		const formatNumber = (number: number): string => {
			return number === 0 ? '' : new Intl.NumberFormat().format(number)
		}

		if (!disableHiddenBar) {
			placeholder.append('g').attr('class', 'hidden-slices')
		}
		placeholder.append('g').attr('class', 'slices')
		placeholder.append('g').attr('class', 'labels')
		placeholder.append('g').attr('class', 'center-text')
		if (!disableLegend) {
			placeholder.append('g').attr('class', 'keys')
		}

		const defineColor = d3
			.scaleOrdinal()
			.domain([0, data.length - 1])
			.range(allZeroes ? Array(colors.length).fill(zeroStateColor) : colors)

		const hiddenColors = ['transparent', ...colors]

		const defineColorHiddenDonut = d3
			.scaleOrdinal()
			.domain([0, data.length - 1])
			.range(allZeroes ? Array(hiddenColors.length).fill(zeroStateColor) : hiddenColors)

		const definePieSlices = d3
			.pie()
			.value((d) => d.value)
			//.sort(null)
			.startAngle(angleRange)
			.endAngle(angleRange * -1)

		if (allZeroes && data.length > 1) data[1].value = 100 // UX asked for this stupid logic to display an arc when all values are 0

		const hiddenData = [...data, { label: '~~~~~', value: total * 0.01 }]
		const pieSlices = definePieSlices(data)
		const outerRadius = radius
		const arc = d3
			.arc()
			.innerRadius(radius - widthDonut)
			.outerRadius(radius)
			.cornerRadius(cornerRadius)

		// Definition of donut hidden
		const definePieSlices4HiddenDonut = d3
			.pie()
			.value((d) => d.value)
			.startAngle(angleRange)
			.endAngle(angleRange * -1)

		const pieSlices4HiddenDonut = definePieSlices4HiddenDonut(hiddenData)

		const arc4HiddenDonut = d3
			.arc()
			.innerRadius(radius - widthDonut)
			.outerRadius(radius)

		const percentage = function () {
			const valueToConvert = data[0]
			if (total == 0 || allZeroes) return 0
			return Math.round((valueToConvert.value / total) * 100)
		}

		const calculatePercentageSymbolPosition = () => {
			const percentageValue = percentage()
			let xPosition
			if (percentageValue === 100) {
				xPosition = 45
			} else if (percentageValue >= 10) {
				xPosition = 34
			} else {
				xPosition = 22
			}
			return xPosition
		}

		const defineCenterTextData = [
			{ text: percentage().toString(), class: styles.halfDonutPercentage, dx: 0, dy: -23 },
			{ text: defaultKey, class: styles.halfDonutSubtitle, dx: 0, dy: 0 },
			{ text: '%', class: styles.halfDonutPercentageSymbol, dx: calculatePercentageSymbolPosition(), dy: -41 }
		]

		const trimText = (text: string, threshold: number) => {
			if (text.length <= threshold) {
				return text
			} else {
				return text.substring(0, threshold).concat('...')
			}
		}

		// This hiddenSlices are meant to just be used to display the beginning of the arc as solid while the other
		// piece is meant to display the end as rounded
		const hiddenSlices = placeholder
			.select('.hidden-slices')
			.attr('id', () => 'cont')
			.attr('transform', 'translate(' + donutChartPositionX + ',' + donutChartPositionY + ')')
			.selectAll('path')
			.data(pieSlices4HiddenDonut)

		hiddenSlices
			.enter()
			.append('path')
			.attr('id', (d, i: number) => `hidden-arc-${i}`)
			.merge(hiddenSlices)
			.transition()
			.duration(1000)
			.attr('d', (d) => arc4HiddenDonut(d))
			.attr('fill', (d) => defineColorHiddenDonut(d.data.label))

		hiddenSlices.exit().remove()

		// append the svg object
		const slice = placeholder
			.select('.slices')
			.attr('id', () => 'cont')
			.attr('transform', 'translate(' + donutChartPositionX + ',' + donutChartPositionY + ')')
			.selectAll('path')
			.data(pieSlices)

		slice
			.enter()
			.append('path')
			.attr('id', (d, i: number) => `arc-${i}`)
			.merge(slice)
			.transition()
			.duration(1000)
			.attr('d', (d) => arc(d))
			.attr('fill', (d) => defineColor(d.data.label))

		slice.exit().remove()

		const defineAngle4Label = (d2) => {
			let angle = d2.startAngle
			// So, if the slice has close to 180 degrees (Pi in radians) we will leave some space for
			// the other number, we will leave 10 degrees, that is Pi - rad(10degrees) ~= 2.96 -> 1.39 rad
			if (Math.abs(d2.startAngle - d2.endAngle) > 2.96) angle = 1.39
			return angle
		}
		// Add Slice labels: the number we receive next to each arc
		const sliceLabels = placeholder
			.select('.labels')
			.attr('transform', 'translate(' + labelsPositionX + ',' + labelsPositionY + ')')
			.selectAll('text')
			.data(pieSlices)

		sliceLabels
			.enter()
			.append('text')
			.attr('id', (d, i) => `label-${i}`)
			.attr('class', styles.labelName)
			.merge(sliceLabels)
			.text((d) => (allZeroes ? '' : formatNumber(d.value)))
			.transition()
			.duration(1000)
			.styleTween('text-anchor', function (d) {
				this._current = this._current || d
				const interpolate = d3.interpolate(this._current, d)
				this._current = interpolate(0)
				return function (t) {
					const d2 = interpolate(t)
					const angle = defineAngle4Label(d2)

					const position = outerRadius + outerRadius * Math.sin(angle)
					if (position === pointMiddleX) return 'middle'
					else if (position < limitPositionXtoTextAnchor) return 'end'
					else return 'start'
				}
			})
			.attrTween('dx', function (d) {
				this._current = this._current || d
				const interpolate = d3.interpolate(this._current, d)
				this._current = interpolate(0)

				return function (t) {
					const d2 = interpolate(t)
					const angle = defineAngle4Label(d2)

					const positionX = outerRadius + outerRadius * Math.sin(angle)
					let margin = 0
					if (positionX < limitLeftPositionXforLabel) margin = -marginLabel
					else if (positionX > limitRightPositionXforLabel) margin = marginLabel

					return positionX + margin
				}
			})
			.attrTween('dy', function (d) {
				this._current = this._current || d
				const interpolate = d3.interpolate(this._current, d)
				this._current = interpolate(0)

				return function (t) {
					const d2 = interpolate(t)
					const angle = defineAngle4Label(d2)

					const positionY = outerRadius - outerRadius * Math.cos(angle)
					let marginBottom = 0
					if (positionY < limitPositionYforLabel) marginBottom = -marginLabel

					return positionY + marginBottom
				}
			})

		sliceLabels.exit().remove()

		const centerText = placeholder
			.select('.center-text')
			.attr('transform', 'translate(' + positionXcontainerText + ',' + positionYcontainerText + ')')
			.selectAll('text')
			.data(defineCenterTextData)

		centerText
			.enter()
			.append('text')
			.attr('class', (d) => d.class)
			.attr('text-anchor', 'middle')
			.merge(centerText)
			.text((d) => trimText(d.text, 20))
			.transition()
			.duration(1000)
			.styleTween('fill', () => () => centerTextColor && centerTextColor.length > 0 ? centerTextColor : colors[0])
			.attrTween('dx', (_, i) => () => defineCenterTextData[i].dx)
			.attrTween('dy', (_, i) => () => defineCenterTextData[i].dy)

		centerText.exit().remove()

		drawKeys({
			svg: placeholder,
			selector: '.keys',
			data: pieSlices,
			textForKey: (d) => d.data.label,
			showKeys: true,
			color: defineColor,
			dx: positionXlegends,
			dy: positionYlegends,
			iconPadding: sizeBetweenLegendsIcon,
			iconRadius: radiusLegendsIcon,
			keyLabel: cx(
				styles.keyLabel,
				`${legendOrientation === 'vertical' ? styles.keyVertical : styles.keyHorizontal}`
			),
			orientation: legendOrientation
		})

		//Add mouseover
		if (percentage() >= limitPercentageToActivateMouseOver) {
			sliceLabels.selectAll('g').attr('class', function (d, i) {
				return cx(styles.labelName, { [styles.hiddenLabel]: i !== 1 })
			})

			placeholder
				.selectAll('g')
				.select('path')
				.on('mouseover', function () {
					if (this.id === 'arc-0') {
						sliceLabels.selectAll('g').attr('class', function (d, i) {
							return cx(styles.labelName, { [styles.hiddenLabel]: i === 1 })
						})
					}
				})
				.on('mouseout', function () {
					if (this.id === 'arc-0') {
						sliceLabels.selectAll('g').attr('class', function (d, i) {
							return cx(styles.labelName, { [styles.hiddenLabel]: i !== 1 })
						})
					}
				})
		}
	}
}

export const HalfDonutChart: FC<IHalfDonutChartProps> = ({
	centerTextColor = '',
	colors,
	data,
	defaultKey = 'matched',
	disableHiddenBar = false,
	disableLegend = false,
	legendOrientation = 'vertical',
	testId,
	zeroStateColor = '#F9EEF5'
}: IHalfDonutChartProps) => {
	const d3Container = useRef(null)
	const hdcGenerator = useRef(null)
	const colorsTokenList = createTokenList(tokens)

	const getViewBoxHeigh = () => {
		if (disableLegend) {
			return 173
		} else {
			if (legendOrientation === 'vertical') {
				return 233
			} else {
				return 203
			}
		}
	}

	function objectToOrderArray(arrayToOrder, key) {
		const OrderArray = []
		Object.entries(arrayToOrder).forEach(([currentKey, value]) => {
			const valueToken = typeof value === 'string' ? getValueToken(colorsTokenList, value) : undefined
			const realValue = valueToken !== undefined ? valueToken : value
			if (currentKey === key) OrderArray.unshift({ label: currentKey, value: realValue })
			else OrderArray.push({ label: currentKey, value: realValue })
		})
		return OrderArray
	}

	useEffect(() => {
		if (hdcGenerator.current === null) {
			hdcGenerator.current = createHalfDonutChartSVG(d3Container.current)
		}
	}, [])

	const useDeepCompare = (value) => {
		const ref = useRef()
		if (!isEqual(value, ref.current)) {
			ref.current = value
		}
		return ref.current
	}

	useEffect(() => {
		if (data && d3Container.current) {
			if (defaultKey === '') defaultKey = 'matched'
			const valueColors = objectToOrderArray(colors, defaultKey).map((c) => c.value)
			const dataArray = objectToOrderArray(data, defaultKey)
			hdcGenerator.current({
				centerTextColor,
				colors: valueColors,
				data: dataArray,
				defaultKey,
				disableHiddenBar,
				disableLegend,
				legendOrientation,
				size: 223,
				zeroStateColor
			})
		}
	}, useDeepCompare([data, colors, defaultKey]))

	return (
		<svg
			width={'100%'}
			height={'100%'}
			viewBox={`0 0 355 ${getViewBoxHeigh()}`}
			preserveAspectRatio="xMidYMid meet"
			className={styles.baseDonutChart}
			ref={d3Container}
			data-testid={testId}
		/>
	)
}

export default HalfDonutChart
