import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { getC4SSourcePackageVersion, VersionResponse } from './api/getSFPackageVersion'

export const useC4SSourcePackageVersion = (
	sourceId?: string,
	versionSF?: string,
	enabled = true
): UseQueryResult<VersionResponse> => {
	const apiClient = useApi()
	const queryKey = ['getC4SSourcePackageVersion']
	const queryFunction = () => getC4SSourcePackageVersion(apiClient, sourceId, versionSF)

	return useQuery(queryKey, queryFunction, { enabled: !!sourceId && enabled })
}
