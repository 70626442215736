import { ColorBlueBrand } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { FC, useState } from 'react'
import { Icon } from '../../../../local-core-ui'
import { useWizardRouteMatch } from '../../hooks/useWizardRouteMatch'
import styles from './wizardButtons.module.scss'

export const WizardBackButton: FC = () => {
	const [arrowBackSize, setArrowBackSize] = useState<'small' | 'medium'>('small')
	const { previous, isPreviousDisabled } = useWizardRouteMatch()
	return (
		<>
			{isPreviousDisabled() ? undefined : (
				<button
					className={styles.wizardBackButton}
					onMouseOver={() => setArrowBackSize('medium')}
					onMouseLeave={() => setArrowBackSize('small')}
					onClick={() => previous()}
					data-testid={'wizard-composer-previous-button'}
				>
					<Icon
						testId="arrow-back-wizard-button"
						type="arrow-back"
						color={ColorBlueBrand}
						size={arrowBackSize}
					/>
				</button>
			)}
		</>
	)
}
