import { ColorRed } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement } from 'react'
import { Divider, Icon } from '../../local-core-ui'
import { Element } from '../../types'
import { EnrichmentPreviewPanelItem } from '../enrichment-preview-panel-item/enrichment-preview-panel-item'
import styles from './enrichment-preview-panel.module.scss'

export interface EnrichmentPreviewPanelElement extends Element {
	isChecked?: boolean
	disabled: boolean
}

export interface EnrichmentPreviewPanelProps {
	elements: Array<EnrichmentPreviewPanelElement>
	notEntitledElements?: Array<Element>
	title: string
	displayCheckBoxes: boolean
	onAddElement?: (element: Element) => void
	onRemoveElement?: (element: Element) => void
	description?: string
	type?: 'warning' | 'info'
}

export const EnrichmentPreviewPanel = ({
	elements,
	notEntitledElements,
	title,
	displayCheckBoxes,
	onAddElement,
	onRemoveElement,
	description,
	type = 'info'
}: EnrichmentPreviewPanelProps): ReactElement => {
	const onChange = (element: Element) => {
		const newSelection = [...elements]
		const selectedElement = newSelection.find((selectedElement) => selectedElement.elementId === element.elementId)
		if (selectedElement) {
			selectedElement.isChecked = !selectedElement.isChecked
			if (selectedElement.isChecked) {
				if (onAddElement) onAddElement(element)
			} else {
				if (onRemoveElement) onRemoveElement(element)
			}
		}
	}

	return (
		<div
			className={
				type === 'warning'
					? styles.enrichingPreviewPanelWarningContainer
					: styles.enrichingPreviewPanelContainer
			}
		>
			<div className={styles.enrichingPreviewPanelTitleContainer}>
				{type === 'warning' && (
					<div className={styles.enrichingModalPanelIcon}>
						<Icon testId="icon-red" type={'exclamation'} color={ColorRed} size={'mini'} />
					</div>
				)}
				<div
					className={
						type === 'warning' ? styles.enrichingModalPanelWarningTitle : styles.enrichingModalPanelTitle
					}
				>
					{title}
				</div>
			</div>
			{description && <div className={styles.enrichingModalPanelDescription}>{description}</div>}
			<Divider color={'lightgray'} />
			<div>
				{notEntitledElements &&
					notEntitledElements
						.sort((a, b) => {
							return a.displayName > b.displayName ? 1 : -1
						})
						.map((dataElement, key) => (
							<EnrichmentPreviewPanelItem
								key={key}
								item={dataElement}
								isChecked={false}
								isDisabled={true}
								displayCheckBox={displayCheckBoxes}
							/>
						))}
				{elements
					.sort((a, b) => {
						return a.displayName > b.displayName ? 1 : -1
					})
					.map((dataElement, key) => (
						<EnrichmentPreviewPanelItem
							key={key}
							item={dataElement}
							isChecked={dataElement.isChecked}
							displayCheckBox={displayCheckBoxes}
							onAdd={onChange}
							onRemove={onChange}
						/>
					))}
			</div>
		</div>
	)
}
