import { DNBButton } from '@dnb-uux-design-system/react'
import AddIcon from '@mui/icons-material/Add'
import { isEmpty, orderBy } from 'lodash-es'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ColorBlueBrand, ColorGrayDarker } from '../../../../design-tokens/build/shared/token-colors.json'
import { useFeatures, usePlatform } from '../../hooks/useEntitlements'
import { Icon } from '../../local-core-ui/icon/icon'
import { ConnectionStatus } from '../../queries/api/getC4SActiveConnection'
import { ConnectionDetail } from '../../queries/api/getConnection'
import { Connection } from '../../queries/api/getConnections'
import { RootState, useAppSelector } from '../../store'
import {
	ConnectionFilters,
	ConnectionsFilterBar,
	SortType,
	View
} from '../connections-filter-bar/connections-filter-bar'
import { ConnectionsView } from '../connections-view/connections-view'
import { NewConnectionModal } from '../new-connection-modal/new-connection-modal'
import { Snackbar, SnackType } from '../snackbar/snackbar'
import styles from './connections-manager.module.scss'

interface ConnectionsManagerProps {
	onEditConnection(connectionDetail: ConnectionDetail): void
	onReload(): void
	isLoading: boolean
	isStatusLoading: boolean
	connections: Connection[]
}

export const ConnectionsManager = ({
	onEditConnection,
	onReload,
	isLoading,
	isStatusLoading,
	connections
}: ConnectionsManagerProps): ReactElement => {
	const defaultFilters: ConnectionFilters = {
		searchFilter: '',
		sortBy: SortType.Created,
		sortByLabel: 'Created Date',
		sortAscending: false,
		view: View.tile
	}
	const { t } = useTranslation()
	const history = useHistory()
	const [showAddConnectionModal, setShowAddConnectionModal] = useState(false)
	const [tileView, setTileView] = useState(true)
	const [connectionFilters, setConnectionFilters] = useState<ConnectionFilters>(defaultFilters)
	const [filteredList, setFilteredList] = useState<Connection[]>([])
	const isC4SPlatform = usePlatform('salesforce')
	const enableC4SAuthMgmt = useFeatures(['EnableC4SAuthMgmt'])
	const selectConnections = (state: RootState) => state.connection
	const connectionSelector = useAppSelector(selectConnections)

	const handleOnChangeFilter = (filters: ConnectionFilters) => {
		setConnectionFilters(filters)
		history.push({
			pathname: `/connections`,
			search: `?sortBy=${filters.sortBy}&sortDirection=${filters.sortAscending ? 'asc' : 'desc'}&view=${
				filters.view
			}`
		})
	}

	const defineSortFunction = (sortBy: ConnectionFilters['sortBy']) => {
		switch (sortBy) {
			case SortType.Name:
				return (connection: Connection) => connection.name.toLowerCase().trim()
			case SortType.Created:
				return (connection: Connection) => connection.created
			case SortType.Updated:
				return (connection: Connection) => connection.updated
		}
	}

	const searchByType = (connection: Connection, searchType: 'exact' | 'char'): Connection | undefined => {
		const name = connection.displayName?.toLowerCase()
		const searchText = connectionFilters.searchFilter.toLowerCase().replace(/\s/g, '')
		if (searchType === 'exact') {
			if (name?.includes(searchText)) {
				return connection
			}
		} else {
			if (name.indexOf(searchText) >= 0) return connection
		}
	}

	const search = (): Connection[] | undefined => {
		const exactMatchSearch = connections?.filter((connection) => {
			return searchByType(connection, 'exact')
		})
		if (exactMatchSearch?.length === 1) {
			return exactMatchSearch
		} else {
			return connections?.filter((connection) => {
				return searchByType(connection, 'char')
			})
		}
	}

	const filterList = () => {
		if (connections.length > 0) {
			const orderList = orderBy(
				!isEmpty(connectionFilters.searchFilter) ? search() : connections,
				defineSortFunction(connectionFilters.sortBy),
				connectionFilters.sortAscending ? ['asc'] : ['desc']
			)
			setFilteredList(orderList)
		} else if (connections.length === 0) {
			setFilteredList([])
		}
	}

	const handleChangeView = (view: View) => {
		let updateProjectView = connectionFilters
		updateProjectView = {
			...updateProjectView,
			view: view
		}
		setTileView(view === View.tile)
		handleOnChangeFilter(updateProjectView)
	}

	useEffect(() => {
		filterList()
		/**
		 * We only want to run this effect when the filters change.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connectionFilters, connections])

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.href)
		if (searchParams.get('view') === View.list) handleChangeView(View.list)
		/**
		 * We only want to run this effect to keep the view
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<NewConnectionModal
				open={showAddConnectionModal}
				onCloseModal={() => setShowAddConnectionModal(false)}
				onReload={() => {
					onReload()
				}}
				testId={'add-connection-modal'}
			/>
			{isC4SPlatform &&
				(connectionSelector.currentConnectionStatus === ConnectionStatus.Failed ||
					connectionSelector.currentConnectionStatus === ConnectionStatus.Unknown) &&
				enableC4SAuthMgmt && (
					<div>
						<Snackbar
							message={t('connection.missing.non.admin.message') || ''}
							title={t('connection.missing.non.admin')}
							type={SnackType.notification}
							isBanner
						/>
					</div>
				)}
			<div className={styles.headerContainer}>
				<ConnectionsFilterBar onChangeFilter={handleOnChangeFilter} initialFilters={defaultFilters} />
				<div className={styles.rightSide}>
					<div className={styles.tileViewIconContainer} onClick={() => handleChangeView(View.tile)}>
						<Icon
							testId="arrows-project-filter-bar"
							type={'view-tile'}
							size={'mini'}
							color={tileView ? ColorBlueBrand : ColorGrayDarker}
						/>
					</div>
					<div className={styles.listViewIconContainer} onClick={() => handleChangeView(View.list)}>
						<Icon
							testId="arrows-project-filter-bar"
							type={'view-list'}
							size={'mini'}
							color={tileView ? ColorGrayDarker : ColorBlueBrand}
						/>
					</div>
					<DNBButton
						size="small"
						variant="primary"
						onClick={() => {
							setShowAddConnectionModal(true)
						}}
						data-testid="create-project"
						startIcon={<AddIcon />}
					>
						{t('dashboard.connections.create.button')}
					</DNBButton>
				</div>
			</div>
			<ConnectionsView
				connections={filteredList}
				isTileView={tileView}
				onClickNewConnection={() => setShowAddConnectionModal(true)}
				onEditConnection={onEditConnection}
				isLoading={isLoading}
				isConnectionStatusLoading={isStatusLoading}
			/>
		</>
	)
}
