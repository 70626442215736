import world from '../local-core-ui/assets/data/world.json'

export const convertTo2CountryCode = (countries: Array<string>): Array<string> => {
	const countriesInfo = world.features.filter((c) => countries.includes(c.id))
	return countriesInfo.map<string>((country) => country.id2)
}

export const convertTo3CountryCode = (countries?: Array<string>): Array<string> | undefined => {
	if (countries) {
		const countriesInfo = world.features.filter((c) => countries.includes(c.id2))
		return countriesInfo.map<string>((country) => country.id)
	}
}
