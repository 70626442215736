export const SET_API_ERROR = 'SET_API_ERROR'
export const CLEAR_API_ERROR = 'CLEAR_API_ERROR'

export interface ApiErrorState {
	errorCode?: string
	message?: string
	additionalInfo?: string
	previousURL?: string
	requestURL?: string
	requestPayload?: string
	response?: string
	oAuthC4SError?: { isAdmin: boolean; domain: string | undefined; isSandbox: boolean }
}

interface SetApiErrorAction {
	type: typeof SET_API_ERROR
	payload: ApiErrorState
}

interface ClearApiErrorAction {
	type: typeof CLEAR_API_ERROR
}

export type ApiErrorActionTypes = SetApiErrorAction | ClearApiErrorAction
