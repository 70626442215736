import { ColorBrandPrimary, ColorRed, ColorScaleDarkGreen } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { isEmpty } from 'lodash-es'
import { ReactElement } from 'react'
import { Icon } from '../../local-core-ui'
import styles from './snackbar.module.scss'

interface SnackbarProps {
	message?: string
	title: string
	type: SnackType
	showClose?: boolean
	isBanner?: boolean
	linkText?: string
	onClickLink?(): void
	onClose?(): void
}

export enum SnackType {
	error = 'warning',
	success = 'accepted',
	notification = 'notification',
	warning = 'emptywarning',
	information = 'information'
}

export const Snackbar = ({
	message,
	title,
	type,
	showClose = false,
	isBanner = false,
	linkText,
	onClickLink,
	onClose
}: SnackbarProps): ReactElement => {
	const iconColors = {
		warning: ColorRed,
		accepted: ColorScaleDarkGreen,
		notification: ColorBrandPrimary,
		emptywarning: ColorRed
	}
	const snackStyles = {
		warning: styles.error,
		accepted: styles.success,
		notification: styles.notification,
		emptywarning: styles.warning,
		information: styles.information
	}

	return (
		<div className={`${styles.snackbarContainer} ${snackStyles[type]}`} data-testid="snackbar">
			<div className={styles.header}>
				<div className={styles.title}>
					<Icon type={type} size={'mini'} color={iconColors[type]} />
					<div
						className={`${styles.titleText} ${
							isBanner ? styles.titleTextBanner : styles.titleTextSnackbar
						}`}
					>
						{title}
					</div>
				</div>
				{showClose && (
					<div className={styles.close} onClick={() => (onClose ? onClose() : '')}>
						<Icon testId={`snackbar-close`} type={'x'} size={'mini'} />
					</div>
				)}
			</div>
			<div className={`${styles.message} ${isBanner ? styles.messageBannerFont : styles.messageSnackbarFont}`}>
				{message}
			</div>
			{!isEmpty(linkText) && onClickLink && (
				<div className={styles.link} onClick={() => onClickLink()}>
					{linkText}
				</div>
			)}
		</div>
	)
}
