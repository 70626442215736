import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { useApi } from '../hooks'
import { deleteUser } from './api/deleteUser'

export const useDeleteUser = (): UseMutationResult<void, unknown, string, unknown> => {
	const apiClient = useApi()
	const queryClient = useQueryClient()

	const mutationFn = (userEmail: string) => deleteUser(apiClient, userEmail)

	return useMutation(mutationFn, {
		onSuccess: () => {
			queryClient.invalidateQueries('getUsers')
		}
	})
}
