import { ColorGrayDark } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { abbreviateNumber, numberDelimeters } from '../../helpers'
import { Divider, Icon } from '../../local-core-ui'
import { RecordCompareCard, StandardDnBFields } from '../record-compare-card/record-compare-card'
import styles from './record-comparison-cards.module.scss'

export interface AdditionalInsights {
	employees: number
	country: string
	headquarters: string
	state: string
	revenue: number
	revenueTrend: string
}

interface RecordComparisonCardsProps {
	userRecord: StandardDnBFields
	dnbMatch: StandardDnBFields
	additionalInsights?: AdditionalInsights
	lastModified?: string
	onEdit(): void
	member?: number
	familyMembers?: number
}

export const RecordComparisonCards = ({
	userRecord,
	dnbMatch,
	additionalInsights,
	lastModified,
	onEdit,
	member,
	familyMembers
}: RecordComparisonCardsProps): ReactElement => {
	const { t } = useTranslation()
	const missingField = '-'
	const isDnBMatchEmpty = Object.keys(dnbMatch).length === 0
	return (
		<div className={styles.recordComparisonCardsContainer}>
			<div className={styles.recordComparisonCardsCardCompareContainer}>
				<div className={styles.recordComparisonCardsLeftCard}>
					<div className={styles.recordComparisonCardsLastModifiedContainer}>
						<p className={styles.recordComparisonCardsLastModified}>
							{t('record.comparison.cards.last.modified')}
							<span className={styles.recordComparisonCardsLastModifiedDate}>
								{lastModified || missingField}
							</span>
						</p>
						<button
							className={styles.recordComparisonCardsEdit}
							onClick={onEdit}
							data-testid="record-comparison-cards-edit-record"
						>
							<Icon testId="pencil-record-comparison-cards" type="pencil" size="mini" />
						</button>
					</div>
					<RecordCompareCard standardFields={userRecord} comparisonFields={dnbMatch} />
				</div>
				{!isDnBMatchEmpty && (
					<div className={styles.recordComparisonCardsRightCard}>
						<div className={styles.recordComparisonCardsDnBMatch}>
							{t('record.comparison.cards.dnb.match')}
						</div>
						<div className={styles.recordComparisonCardsBranchContainer}>
							<p className={styles.recordComparisonCardsBranch}>{t('record.comparison.cards.branch')}</p>
							<p className={styles.recordComparisonCardsMember}>
								{t('record.comparison.cards.member')}
								<span className={styles.recordComparisonCardsMemberNum}>{member || missingField}</span>
							</p>
							<p className={styles.recordComparisonCardsTreeMembers}>
								{t('record.comparison.cards.family.tree.members')}{' '}
								<span className={styles.recordComparisonCardsTreeMembersNum}>
									{familyMembers || missingField}
								</span>
							</p>
						</div>
						<RecordCompareCard standardFields={dnbMatch} comparisonFields={userRecord} isDnbMatch />
					</div>
				)}
			</div>
			{additionalInsights && (
				<div className={styles.recordComparisonCardsAdditionalInsights}>
					<Divider color={ColorGrayDark} />
					<div className={styles.recordComparisonCardsInsightsHeader}>
						<Icon testId="star-solid-record-comparison-cards" type="star-solid" color={ColorGrayDark} />
						<p className={styles.recordComparisonCardsInsightsTitle}>
							{t('record.comparison.cards.additional.insights')}
						</p>
					</div>
					<div className={styles.recordComparisonCardsInsightsBody}>
						<Insight
							label={t('record.comparison.cards.employees')}
							value={numberDelimeters(additionalInsights.employees)}
						/>
						<Insight label={t('record.comparison.cards.country')} value={additionalInsights.country} />
						<Insight
							label={t('record.comparison.cards.headquarters')}
							value={additionalInsights.headquarters}
						/>
						<Insight label={t('record.comparison.cards.state')} value={additionalInsights.state} />
						<Insight
							label={t('record.comparison.cards.revenue')}
							value={`$${abbreviateNumber(additionalInsights.revenue)}`}
						/>
						<Insight
							label={t('record.comparison.cards.revenueTrend')}
							value={additionalInsights.revenueTrend}
						/>
					</div>
				</div>
			)}
		</div>
	)
}

interface InsightProps {
	label: string
	value: string
}

const Insight = ({ label, value }: InsightProps): ReactElement => {
	return (
		<div className={styles.recordComparisonCardsInsightsDataContainer}>
			<label className={styles.recordComparisonCardsInsightsLabel}>{label}</label>
			<p className={styles.recordComparisonCardsInsightsValue}>{value}</p>
		</div>
	)
}
