import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { MatchTemplate } from '../types'
import { getMatchTemplates } from './api/getMatchTemplates'

export const useMatchTemplates = (): UseQueryResult<Array<MatchTemplate>> => {
	const apiClient = useApi()

	const queryKey = ['getMatchTemplates']
	const queryFunction = () => getMatchTemplates(apiClient)
	return useQuery(queryKey, queryFunction)
}
