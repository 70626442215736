import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useApi } from '../../hooks'
import { RadioButton } from '../../local-core-ui'
import { deletePreviousMatchRules } from '../../project/steps/matching-v2/deletePreviousMatchRules'
import { RootState, TDispatch, useAppDispatch, useAppSelector } from '../../store'
import { setMatchingApproach } from '../../store/projectWizard/actions'
import { MatchingApproach } from '../../types'
import { MatchTemplatePicker } from '../match-template-picker/match-template-picker'
import styles from './match-your-data-header.module.scss'

export interface MatchYourDataHeaderProps {
	sourceName: string
	isPartialMatchEnabled: boolean
	partialMatch?: boolean
	onChange?: (newValue: boolean) => void
	testId: string
}

export const MatchYourDataHeader: FC<MatchYourDataHeaderProps> = ({
	sourceName,
	isPartialMatchEnabled,
	partialMatch = false,
	onChange,
	testId
}) => {
	const { t } = useTranslation()
	const [showChooseMachTemplate, setShowChooseMachTemplate] = useState(false)

	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const dispatch: TDispatch = useAppDispatch()
	const apiClient = useApi()

	return (
		<>
			<div className={styles.container}>
				<div className={`${styles.header} ${styles.headline5}`}>
					{t('source.options', { sourceName: sourceName })}
				</div>
				<span className={styles.column}>
					<div className={styles.headline6} data-testid="step-information-line-1-test-id">
						{t('match.template')}
					</div>
					{/* TO BE UNCOMMENTED WHEN DCP-4828 is implemented
					<div
						className={`${styles.tertiaryCompact} ${styles.link}`}
						data-testid="choose-template"
						onClick={() => setShowChooseMachTemplate(true)}
					>
						{t('choose.match.step.choose.template.button')}
					</div>*/}
				</span>
				{isPartialMatchEnabled && (
					<div className={`${styles.column} ${styles.withSeparator}`}>
						<div className={styles.headline6}>{t('company.only.matches')}</div>
						<div className={styles.selection}>
							<span className={styles.radioSection}>
								<RadioButton
									value={'include'}
									group={'comp-only-matches'}
									id={'include'}
									label={t('include')}
									checked={partialMatch}
									onChange={() => onChange && onChange(true)}
									testId={`${testId}-include-comp-only-matches`}
								/>
							</span>
							<span className={styles.radioSection}>
								<RadioButton
									value={'exclude'}
									group={'comp-only-matches'}
									id={'exclude'}
									label={t('exclude')}
									checked={!partialMatch}
									onChange={() => onChange && onChange(false)}
									testId={`${testId}-exclude-comp-only-matches`}
								/>
							</span>
						</div>
						<div className={styles.body}>{t('partial.match.description')}</div>
					</div>
				)}
			</div>
			{showChooseMachTemplate ? (
				<MatchTemplatePicker
					open={showChooseMachTemplate}
					onClose={() => {
						setShowChooseMachTemplate(false)
					}}
					onSelect={() => {
						if (projectWizardState.currentProject.source.id)
							deletePreviousMatchRules(apiClient, projectWizardState.currentProject.source.id)
						setShowChooseMachTemplate(false)
						dispatch(setMatchingApproach(MatchingApproach.CHOOSE_TEMPLATE))
					}}
					testId="temporal-match-picker"
					sourceId={projectWizardState.currentProject.source.id}
					onTemplatesClear={() => {
						setShowChooseMachTemplate(false)
					}}
				/>
			) : undefined}
		</>
	)
}
