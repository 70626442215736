import { FC, ReactNode } from 'react'
import styles from './tile.module.scss'

interface TileProps {
	className?: string
	children?: ReactNode
	testId?: string
}

export const Tile: FC<TileProps> = ({ className, children, testId }: TileProps) => {
	return (
		<div data-testid={`children-${testId}`} className={`${styles.tileContainer} ${className || ''}`}>
			{children}
		</div>
	)
}
