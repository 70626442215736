import { useCallback } from 'react'
import { checkSession, signOut, SignOutReason, TDispatch } from '../store'

// Hook
export function useSession(dispatch: TDispatch): [(reason?: SignOutReason) => Promise<void>, () => void] {
	const signOutCallBack = useCallback(
		(reason?: SignOutReason): Promise<void> => {
			console.log(`signing out with ${reason}`)
			return dispatch(signOut(reason))
		},
		[dispatch]
	)

	const checkSessionCallback = useCallback((): void => {
		dispatch(checkSession())
	}, [dispatch])

	return [signOutCallBack, checkSessionCallback] /*,
		(): void => {
			new Promise((resolve) => {
				dispatch(initializeSessionAction())
				resolve(0)
			}).then(() => {
				const url = new URL(window.location.href)
				url.searchParams.delete('token')
				window.location.replace(url.toJSON())
			})
		}
		*/
}
