import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '../../local-core-ui'
import { DataReport } from '../../types'
import { GetMatchSummaryCodes } from '../../types/match-recommendation/GetMatchRecommendationProfile'
import { TileOrPanel } from '../dashboard-component/dashboard-component'
import styles from './match-recommendation-charts.module.scss'
import { MatchSummaryCodeReport } from './match-summary-code-report/match-summary-code-report'
import { StewardshipValueReport } from './stewardship-value-report/stewardship-value-report'

interface MatchRecommendationsChartsProps {
	matchCodes: GetMatchSummaryCodes
	dataReport: DataReport
	colorsPie: Array<string>
	colorsMR: Array<string>
	mode?: 'tiles' | 'panels'
	testId: string
}
export const MatchRecommendationCharts: FC<MatchRecommendationsChartsProps> = ({
	matchCodes,
	dataReport,
	colorsPie,
	colorsMR,
	mode,
	testId
}: MatchRecommendationsChartsProps) => {
	const { t } = useTranslation()

	const MRCodeReport = () => (
		<MatchSummaryCodeReport
			title={t('title.chart.summary.code')}
			matchCodes={matchCodes}
			colors={colorsMR}
			data={dataReport?.matchSummaryCodeReport.summaryCodeList || []}
			matchedCount={dataReport.matchSummaryCodeReport.total || 0}
			testId={testId + 'mr-summaryCode'}
		/>
	)

	const StewardshipReport = () => (
		<StewardshipValueReport
			data={dataReport.stewardshipValueReport.stewardshipValueList || []}
			colors={colorsPie}
			testId={testId + '-stewardshipReport'}
		/>
	)

	return (
		<div className={styles.parallelPassResults} data-testid={testId + 'mr-charts'}>
			{mode === 'panels' ? (
				<>
					<h1>{t('dashboard.parallelPassResults.title')}</h1>
					<div className={styles.divisor}></div>
					<Grid container>
						{dataReport?.matchSummaryCodeReport?.summaryCodeList && (
							<Grid md={8} sm={8}>
								{MRCodeReport()}
							</Grid>
						)}
						{dataReport?.stewardshipValueReport?.stewardshipValueList && (
							<Grid md={4} sm={4}>
								{StewardshipReport()}
							</Grid>
						)}
					</Grid>
				</>
			) : (
				<>
					{dataReport?.matchSummaryCodeReport?.summaryCodeList && (
						<TileOrPanel className={`${styles.quadrant}`} mode={mode}>
							{MRCodeReport()}
						</TileOrPanel>
					)}
					{dataReport?.stewardshipValueReport?.stewardshipValueList && (
						<TileOrPanel className={`${styles.quadrant}`} mode={mode}>
							{StewardshipReport()}
						</TileOrPanel>
					)}
				</>
			)}
		</div>
	)
}
