import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { ConnectionStatusResponse, getConnectionStatus } from './api/getC4SActiveConnection'

interface ConnectionStatusParams {
	orgId: string
}

export const useConnectionStatus = (orgId?: string, enabled?: boolean): UseQueryResult<ConnectionStatusResponse> => {
	const apiClient = useApi()
	const queryKey = ['getC4SActiveConnection']
	const queryFunction = () => getConnectionStatus(apiClient, orgId)

	return useQuery(queryKey, queryFunction, {
		enabled: enabled
	})
}

export const useMutationConnectionStatus = (): UseMutationResult<
	ConnectionStatusResponse,
	unknown,
	ConnectionStatusParams,
	unknown
> => {
	const apiClient = useApi()
	const mutationFn = ({ orgId }: ConnectionStatusParams) => getConnectionStatus(apiClient, orgId)

	return useMutation(mutationFn)
}
