import { DNBButton } from '@dnb-uux-design-system/react'
import { AxiosResponse } from 'axios'
import { FC, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useApi, useSession } from '../../../../hooks'
import { useFeatures, useModules } from '../../../../hooks/useEntitlements'
import { Modal } from '../../../../local-core-ui'
import { useRUMRules } from '../../../../queries/useRUMRules'
import { useAppDispatch } from '../../../../store'
import { newProjectAction, removeProjectFromWizardAction } from '../../../../store/projectWizard/actions'
import { IRUMLimits, RumLimitsModal } from '../../../rum-limits-modal/rum-limits-modal'
import { useWizardContext } from '../../hooks/useWizardContext'
import { useWizardRouteMatch } from '../../hooks/useWizardRouteMatch'
import { WizardCompleteSetupExtraInfo } from '../../types/WizardCompleteSetupExtraInfo'
import { LocationState } from '../wizardStepper/wizardStepper'
import styles from './wizardCompleteSetupButton.module.scss'

declare global {
	interface Window {
		// The structure of the aptrinsic plugin in is external to us and we don't know the type
		// so disabling next line no-explicit-any
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		aptrinsic: any
	}
}
window.aptrinsic = window.aptrinsic || undefined
export const WizardCompleteSetupButton: FC = () => {
	const apiClient = useApi()
	const dispatch = useAppDispatch()
	const { areWeDoneYet, isNextDisabled, next, currentStepRouteExtraInfo } = useWizardRouteMatch()
	const { t } = useTranslation()
	const [isProjectSetupFinished, setProjectSetupFinished] = useState<boolean>(false)
	const [showModalRUMFiles, setShowModalRUMFiles] = useState<boolean>(false)
	const [rumLimits, setRUMLimits] = useState<IRUMLimits>({ addOns: [], regions: [] })
	const { wizardContext } = useWizardContext()
	const [logOutService] = useSession(wizardContext.dispatch)
	const isSalesforce = !!wizardContext.projectWizardState.currentProject.thirdPartyIntegration
	const [origin, setOrigin] = useState('overview')
	const location = useLocation<LocationState>()
	const history = useHistory()
	const isC4SPlatform = useModules(['C4S']) && isSalesforce
	const enableUIOverageAlert = useFeatures(['EnableUIOverageAlert'])
	const { data: rumRulesData, status } = useRUMRules(
		wizardContext.projectWizardState.currentProject.source.id,
		isC4SPlatform
	)
	const isExcludeFlagConfigured = rumRulesData?.isExcludeFlagConfigured || false
	const recordsToProcess = rumRulesData?.recordsToProcess || 0
	const recordsToExclude = rumRulesData?.recordsToExclude || 0
	const rumStatus = status

	useEffect(() => {
		if (location.state) setOrigin(location.state.source)
		/**
		 * location.state could be undefined, we only want to update this effect when location.state is defined and
		 * source property changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.state?.source])

	const rumLimitsResponse = () => {
		const checkRUMurl = `/pls/usage/alert/${wizardContext.projectWizardState.currentProject.purposeOfUse.domain}/${wizardContext.projectWizardState.currentProject.purposeOfUse.recordType}?record_count=${recordsToProcess}`

		apiClient
			.get(checkRUMurl)
			.then((response: AxiosResponse<IRUMLimits>) => {
				setRUMLimits(response.data)
				setShowModalRUMFiles(true)
			})
			.catch((error) => {
				console.error(error)
			})
	}

	const enableRUMFiles = () => {
		const totalRows = wizardContext.projectWizardState.currentProject.fileInfo?.totalRows
		const checkRUMurl = `/pls/usage/alert/${wizardContext.projectWizardState.currentProject.purposeOfUse.domain}/${wizardContext.projectWizardState.currentProject.purposeOfUse.recordType}?record_count=${totalRows}`

		apiClient
			.get(checkRUMurl)
			.then((checkRUMresponse: AxiosResponse<IRUMLimits>) => {
				if (checkRUMresponse.data.addOns.length > 0 || checkRUMresponse.data.regions.length > 0) {
					setRUMLimits(checkRUMresponse.data)
					setShowModalRUMFiles(true)
				} else {
					const aptrinsic = window.aptrinsic
					if (aptrinsic) {
						aptrinsic('track', 'Match Source Type', {
							matchSourceType: wizardContext.projectWizardState.currentProject.source.entityType
						})
					}
					next().then(() => {
						setProjectSetupFinished(true)
					})
				}
			})
			.catch((error) => {
				console.error(error)
			})
	}

	const finishProjectSetup = () => {
		if (isC4SPlatform && enableUIOverageAlert) {
			rumLimitsResponse()
		} else if (
			wizardContext.projectWizardState.currentProject.fileInfo?.totalRows &&
			!isSalesforce &&
			wizardContext.projectWizardState.currentProject.source.isFile &&
			enableUIOverageAlert
		) {
			enableRUMFiles()
		} else {
			const aptrinsic = window.aptrinsic
			if (aptrinsic) {
				aptrinsic('track', 'Match Source Type', {
					matchSourceType: wizardContext.projectWizardState.currentProject.source.entityType
				})
			}
			next().then(() => {
				setProjectSetupFinished(true)
			})
		}
	}
	const startOver = () => {
		wizardContext.dispatch(newProjectAction(wizardContext.enableApi, wizardContext.salesforcePlatform))
	}
	const saveAndLogout = () => {
		startOver()
		logOutService()
	}

	const backToDashboard = () => {
		startOver()
		history.push(`/dashboard/${origin}`)
	}

	return (
		<>
			{areWeDoneYet() ? (
				<>
					<div className={styles.wizardFooter}>
						<div className={styles.pillButtonContainer}>
							<div className={styles.completeMySetupContainer}>
								<DNBButton
									size="default"
									variant="prime"
									onClick={finishProjectSetup}
									disabled={isNextDisabled() || isProjectSetupFinished}
									data-testid={'wizard-composer-complete-button'}
								>
									{t('complete.my.setup')}
								</DNBButton>
							</div>
							{currentStepRouteExtraInfo &&
							(currentStepRouteExtraInfo as WizardCompleteSetupExtraInfo)
								?.completeSetupFirstLineMessage ? (
								<>
									<span className={styles.messageFirstLine}>
										{
											(currentStepRouteExtraInfo as WizardCompleteSetupExtraInfo)
												.completeSetupFirstLineMessage
										}
									</span>
									<span className={styles.messageSecondLine}>
										{
											(currentStepRouteExtraInfo as WizardCompleteSetupExtraInfo)
												.completeSetupSecondLineMessage
										}
									</span>
								</>
							) : undefined}
						</div>
					</div>
					<RumLimitsModal
						open={showModalRUMFiles}
						addOns={rumLimits?.addOns}
						regions={rumLimits?.regions}
						processFile={() => {
							next().then(() => {
								backToDashboard()
							})
							setShowModalRUMFiles(false)
						}}
						saveNoProcessFile={() => {
							dispatch(
								removeProjectFromWizardAction(wizardContext.projectWizardState.currentProject?.id || '')
							)
							history.push(`/dashboard/${origin}`)
							setShowModalRUMFiles(false)
						}}
						records={wizardContext.projectWizardState.currentProject.fileInfo?.totalRows || 0}
						crmSource={wizardContext.projectWizardState.currentProject.thirdPartyIntegration?.crmSource}
						rumSummary={{
							recordsToProcess: recordsToProcess,
							recordsToExclude: recordsToExclude,
							isExcludeFlagConfigured: isExcludeFlagConfigured,
							status: rumStatus
						}}
						isSalesforce={isSalesforce}
					/>
					<Modal open={isProjectSetupFinished} showButtonClose={false} testId="ProjectConfirmationModal">
						<p className={styles.modalContent}>
							<Trans i18nKey="enriching.modal.content">
								Thank you for submitting your details. We are currently processing <br />
								your data, and we&apos;ll notify you via email when it is complete.
							</Trans>
						</p>
						<div className={styles.buttonsContainer}>
							<DNBButton
								size="default"
								variant="primary"
								onClick={() => backToDashboard()}
								data-testid={'wizard-composer-back-to-summary'}
							>
								{t('enriching.modal.button.backTo.summaryPage')}
							</DNBButton>
							<DNBButton
								size="default"
								variant="secondary"
								onClick={() => saveAndLogout()}
								data-testid={'wizard-composer-save-and-logout'}
							>
								{t('enriching.modal.button.save.and.logout')}
							</DNBButton>
						</div>
					</Modal>
				</>
			) : undefined}
		</>
	)
}
