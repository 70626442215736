import { ColorBlueBrand } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { FC } from 'react'
import { Icon } from '../../../../local-core-ui'
import { useWizardRouteMatch } from '../../hooks/useWizardRouteMatch'
import styles from './wizardButtons.module.scss'

export const WizardNextButton: FC = () => {
	const { next, isNextDisabled, areWeDoneYet } = useWizardRouteMatch()
	return (
		<>
			{areWeDoneYet() ? undefined : (
				<button
					className={`${styles.wizardNextButton} ${isNextDisabled() ? '' : styles.active}`}
					onClick={() => next()}
					data-testid={'wizard-composer-next-button'}
					disabled={isNextDisabled()}
				>
					<Icon
						testId="arrow-next-wizard-button"
						type="arrow-next"
						color={ColorBlueBrand}
						size={`${isNextDisabled() ? 'small' : 'medium'}`}
					/>
				</button>
			)}
		</>
	)
}
