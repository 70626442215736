import { FC, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import iconCheck from '../../../../assets/images/icon-green-check.svg'
import spinnerGif from '../../../../assets/images/spinner.gif'
import { Button, Icon, Modal } from '../../../../local-core-ui'
import { newProjectAction } from '../../../../store/projectWizard/actions'
import { DCPLogo } from '../../../dcp-logo/dcp-logo'
import { WizardDefinition } from '../../definitions/wizardDefinition'
import { useWizardContext } from '../../hooks/useWizardContext'
import { useWizardRouteMatch } from '../../hooks/useWizardRouteMatch'
import { WizardComposerStepDefinition } from '../../types/WizardComposerStepDefinition'
import { WizardRoute } from '../../types/WizardRoute'
import styles from './wizardStepper.module.scss'

interface WizardStepperProps {
	routes: WizardRoute[]
}

export interface LocationState {
	source: string
}

export const WizardStepper: FC<WizardStepperProps> = ({ routes }) => {
	const { t } = useTranslation()
	const { wizardContext, dynamicWizardContext } = useWizardContext()
	const { currentStep, lastEnabledStepIndex, currentStepRoute } = useWizardRouteMatch()
	const history = useHistory()
	const [showCloseModal, setShowCloseModal] = useState(false)
	const [origin, setOrigin] = useState('overview')
	const location = useLocation<LocationState>()

	useEffect(() => {
		if (location.state) setOrigin(location.state.source)
		/**
		 * We only want to run this effect when the source in location changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.state?.source])

	const closeWizard = () => {
		if (
			WizardDefinition[0].stepDefinition(wizardContext, dynamicWizardContext).progress() === 100 ||
			wizardContext.projectWizardState.loadingNextStep
		) {
			setShowCloseModal(true)
		} else {
			wizardContext.dispatch(newProjectAction(wizardContext.enableApi, wizardContext.salesforcePlatform))
			history.push(`/dashboard/${origin}`)
		}
	}
	const getTitle = (wizardStep: WizardRoute) => {
		if (wizardStep.label) return t(wizardStep.label)
		else if (wizardStep.stepDefinition) {
			const stepDefinition = wizardStep.stepDefinition(wizardContext, dynamicWizardContext)
			if (stepDefinition.title) {
				return t(typeof stepDefinition.title === 'function' ? stepDefinition.title() : stepDefinition.title)
			}
		}
	}

	const getProgressBarIcon = (stepDefinition: WizardComposerStepDefinition, isCurrentStep: boolean) => {
		if (stepDefinition.progress() > 0) {
			if (stepDefinition.progress() < 100 && wizardContext.projectWizardState.loadingNextStep && isCurrentStep) {
				return (
					<div data-testid="wizard-spinner-whole-screen" className={styles.wizardSpinner}>
						<img src={spinnerGif} alt="loading" />
					</div>
				)
			} else {
				return (
					<div className={`${styles.indicator} ${stepDefinition.progress() === 100 ? styles.hidden : ''}`} />
				)
			}
		}
	}

	const startOver = () => {
		wizardContext.dispatch(newProjectAction(wizardContext.enableApi, wizardContext.salesforcePlatform))
	}
	const saveBackToDashboard = () => {
		if (currentStep?.onSaveProgress)
			currentStep?.onSaveProgress().then(() => {
				startOver()
				history.push(`/dashboard/${origin}`)
			})
		else {
			startOver()
			history.push(`/dashboard/${origin}`)
		}
	}

	const clearBackToDashboard = () => {
		startOver()
		if (currentStep?.onCancel) currentStep.onCancel().then(() => history.push(`/dashboard/${origin}`))
		else history.push(`/dashboard/${origin}`)
	}

	return (
		<div className={styles.container}>
			<div className={styles.topRow}>
				<div className={styles.dnbLogoContainer}>
					<DCPLogo onClick={() => closeWizard()} />
				</div>
				<h1
					data-testid="enter-project-name"
					className={styles.title}
					title={wizardContext.projectWizardState.currentProject.name}
				>
					{wizardContext.projectWizardState.currentProject.name
						? wizardContext.projectWizardState.currentProject.name
						: t('wizard.newProject.enter-name')}
				</h1>

				<div className={styles.buttonWrapper}>
					<button
						className={styles.closeButton}
						onClick={() => closeWizard()}
						data-testid="wizard-composer-close"
					>
						<Icon testId="X-wizard-stepper" type="x" size="medium" />
					</button>
				</div>
			</div>

			<div className={styles.wizardNavigationTabs}>
				{routes
					.filter((route) => !(route.disabled && route.disabled(wizardContext)))
					.map((wizardStep, index) => {
						const stepDefinition = wizardStep.stepDefinition(wizardContext, dynamicWizardContext)

						return (
							<div className={styles.tabContainer} key={index}>
								<button
									disabled={stepDefinition.progress() === 0 || index > lastEnabledStepIndex}
									onClick={() => history.push(wizardStep.path)}
									data-testid={'wizard-tab-btn-' + index}
								>
									{getTitle(wizardStep)}
								</button>
								<div className={styles.wizardStepProgressBar}>
									<div
										style={{
											width:
												wizardStep
													.stepDefinition(wizardContext, dynamicWizardContext)
													.progress() + '%'
										}}
									>
										<div className={styles.progressBarIcon}>
											{getProgressBarIcon(
												stepDefinition,
												wizardStep.path === currentStepRoute?.path
											)}
										</div>
									</div>
								</div>
								{stepDefinition.progress() === 100 ? (
									<div className={styles.containerIndicator}>
										{wizardContext.projectWizardState.loadingNextStep &&
										wizardStep.path === currentStepRoute?.path ? (
											<img
												className={`${styles.indicator} ${styles.spinner}`}
												src={spinnerGif}
												alt="loading"
												data-testid="spinner-header"
											/>
										) : (
											<img
												data-testid="step-complete"
												className={styles.indicator}
												src={iconCheck}
												alt="step complete"
											/>
										)}
									</div>
								) : null}
							</div>
						)
					})}
			</div>
			<Modal open={showCloseModal} showButtonClose={false} testId="CloseEnrichingModal">
				<div className={styles.containerModal}>
					<p className="modal-content">
						<Trans i18nKey="enriching.modal.close.content">
							Before you close the project setup window, you can...
						</Trans>
					</p>
					<div className={styles.buttonsContainer}>
						<Button
							size="small"
							type="primary"
							text={t('enriching.modal.button.save.exit')}
							onClick={() => saveBackToDashboard()}
							testId={'wizard-stepper-save-exit'}
						/>
						<Button
							size="small"
							type="secondary"
							text={t('enriching.modal.button.backTo.cancel')}
							onClick={() => clearBackToDashboard()}
							testId={'wizard-stepper-cancel'}
						/>
						<Button
							size="small"
							type="secondary"
							text={t('enriching.modal.button.continue.project')}
							onClick={() => setShowCloseModal(false)}
							testId={'wizard-stepper-continue'}
						/>
					</div>
				</div>
			</Modal>
		</div>
	)
}
