import { ProjectWizardActionTypes, SET_ALTERNATE_COMPANY_INFO } from '../types'

export interface alternateInfoObj {
	alternateCompanyExpanded: boolean
	alternateAdrsExpanded: boolean
	alternateRegNumExpanded: boolean
}

export function setAlternateInfoAction(alternateInfo: alternateInfoObj): ProjectWizardActionTypes {
	return {
		type: SET_ALTERNATE_COMPANY_INFO,
		payload: alternateInfo
	}
}
