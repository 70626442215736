import { FC } from 'react'
import { Avatar } from '../../local-core-ui'
import { UserDetail } from '../../types'
import styles from './team-member-tile.module.scss'

interface TeamMemberTileProps {
	teamMember: UserDetail
	testId: string
}

export const TeamMemberTile: FC<TeamMemberTileProps> = ({ teamMember, testId }: TeamMemberTileProps) => {
	const withoutNames = teamMember.FirstName === null || teamMember.LastName === null
	const nameToShow = withoutNames ? teamMember.Email : `${teamMember.FirstName} ${teamMember.LastName}`

	return (
		<div data-testid="team-member-tile" className={styles.teamMemberTile}>
			<Avatar
				size={'medium'}
				userData={[{ label: 'Name', value: withoutNames ? `${nameToShow[0]} ${nameToShow[1]}` : nameToShow }]}
				testId={testId + '-avatar'}
			/>
			<span className={styles.teamMemberName} data-testid="team-member-name">
				{nameToShow}
			</span>
		</div>
	)
}
