import { MouseEvent, ReactElement, useState } from 'react'
import { DCPLogo } from '../components/dcp-logo/dcp-logo'
import { useApi } from '../hooks'
import { RootState, useAppSelector } from '../store'

export function SessionInfo(): ReactElement {
	const selectSession = (state: RootState) => state.session

	const session = useAppSelector(selectSession)
	const apiClient = useApi()
	const [message, setMessage] = useState('message')
	const [apiResponse, setApiResponse] = useState('apiResponse')

	if (session === null || session.token === '') {
		setMessage('You are not logged in.')
	} else if (message !== 'You are logged in with token ' + session.token) {
		setMessage('You are logged in with token ' + session.token)
	}

	function onClick(e: MouseEvent) {
		e.preventDefault()

		const url = '/pls/session'

		apiClient.get(url).then(function (response) {
			setApiResponse(response.data)
		})
	}
	const displayApiResponse = !apiResponse === null || apiResponse === ''

	return (
		<div>
			{displayApiResponse ? (
				<p className="session-api-response">
					<pre> {JSON.stringify(apiResponse, null, 2)} </pre>
				</p>
			) : (
				<p></p>
			)}
			<div className="session">
				<header className="session-header">
					<DCPLogo />
					<p> {message} </p>
					<button onClick={onClick} data-testid="session-call-api">
						{' '}
						Call a protected API
					</button>
					<div className="session-api-response response-spacing">{JSON.stringify(apiResponse)}</div>
				</header>
			</div>
		</div>
	)
}
