import cx from 'classnames'
import { FC, useEffect, useRef, useState } from 'react'
import world from '../assets/data/world.json'
import Flag from '../flags/flag'
import Icon from '../icon/icon'
import { Button } from '../index'
import * as tokens from '../shared/token-colors.json'
import { CountrySelectMap, ITooltipData, RegionOptions } from './country-select-map'
import { CountrySelectModal } from './country-select-modal'
import styles from './country-select-with-map.module.scss'

interface IFilter {
	region?: string
	continent?: string
}

interface ICountry {
	properties: {
		name: string
	}
	id2: string
	id: string
}

export interface ICountrySelectWithMapProps {
	value?: Array<string> | 'All'
	disabledCountries?: Array<string>
	width?: number
	readOnly?: boolean
	disallowSelectAll?: boolean
	selectAllErrorMessage?: string
	onChange?(newValue: Array<string>): void
	/** Provide your translation function (usually "t" or whatever you assigned i18next's useTranslation call to)<br/>
        Ensure the proper keys are defined in your project:<br/>
        <ul>
            <li><b>country.[CC]</b> - where [CC] is the lowercase 2-letter country code for every supported country</li>
            <li><b>countrySelectWithMap.filter.all.countries</b></li>
            <li><b>countrySelectWithMap.filter.region.<i>[REGION]</i></b> - where <i>[REGION]</i> is the lowercase 4-letter region code for every supported region (currently AMER, APAC and EMEA)</li>
            <li><b>countrySelectWithMap.filter.continent.<i>[CONTINENT]</i></b> - where <i>[CONTINENT]</i> is the lowercase 2-letter continent code for every supported continent (currently NA, SA, EU, AS, AF and OC)</li>
            <li><b>countrySelectWithMap.modal.back.to.world.map</b></li>
            <li><b>countrySelectWithMap.modal.select.all</b></li>
            <li><b>countrySelectWithMap.modal.search</b></li>
            <li><b>countrySelectWithMap.modal.no.matching.countries</b></li>
        </ul>
    */
	translationFunction?(i18nKey: string): string
	color?: string
	testId: string
}

export const CountrySelectWithMap: FC<ICountrySelectWithMapProps> = ({
	value,
	disabledCountries = [],
	width = 700,
	readOnly = false,
	onChange,
	disallowSelectAll = false,
	selectAllErrorMessage = "You've selected all geographies, which isn't allowed. If you want to work with all geographies, edit your Base Rule. Otherwise, deselect at least one geographical region to continue.",
	translationFunction,
	color,
	testId
}: ICountrySelectWithMapProps) => {
	let allCountries = []
	if (value === 'All') {
		allCountries = world.features.map((country) => country.id)
	}
	const receivedCountries: Array<string> = value === 'All' ? allCountries : value || []

	const tooltip = useRef<HTMLDivElement>()
	const [tooltipData, setTooltipData] = useState<ITooltipData>({ visible: false })
	const [countries, setCountries] = useState<Array<string>>(receivedCountries)
	const [filter, setFilter] = useState<IFilter>({})
	const [modalVisible, setModalVisible] = useState<boolean>(false)
	const [errorMaximized, setErrorMaximized] = useState<boolean>(true)

	const translationKeyPrefix = 'countrySelectWithMap.filter.'
	const lblFilterAllCountries: string = translationFunction
		? translationFunction(translationKeyPrefix + 'all.countries')
		: 'All Countries'
	const lblFilterRegionAMER: string = translationFunction
		? translationFunction(translationKeyPrefix + 'region.amer')
		: 'AMER'
	const lblFilterRegionAPAC: string = translationFunction
		? translationFunction(translationKeyPrefix + 'region.apac')
		: 'APAC'
	const lblFilterRegionEMEA: string = translationFunction
		? translationFunction(translationKeyPrefix + 'region.emea')
		: 'EMEA'
	const lblFilterContNA: string = translationFunction
		? translationFunction(translationKeyPrefix + 'continent.na')
		: 'North America'
	const lblFilterContSA: string = translationFunction
		? translationFunction(translationKeyPrefix + 'continent.sa')
		: 'South America'
	const lblFilterContEU: string = translationFunction
		? translationFunction(translationKeyPrefix + 'continent.eu')
		: 'Europe'
	const lblFilterContAS: string = translationFunction
		? translationFunction(translationKeyPrefix + 'continent.as')
		: 'Asia'
	const lblFilterContAF: string = translationFunction
		? translationFunction(translationKeyPrefix + 'continent.af')
		: 'Africa'
	const lblFilterContOC: string = translationFunction
		? translationFunction(translationKeyPrefix + 'continent.oc')
		: 'Australia'

	const getCountryTranslation = (country: ICountry): string => {
		let name = country.properties.name
		if (translationFunction && translationFunction('country.' + country.id2.toLowerCase())) {
			name = translationFunction('country.' + country.id2.toLowerCase())
		}
		return name
	}

	const toggleCountry = (givenCountries: string | Array<string>): void => {
		if (typeof givenCountries == 'string') givenCountries = [givenCountries]

		let newCountries = [...countries]
		givenCountries.forEach((countryId) => {
			const selecting = !countries.includes(countryId)
			if (!selecting) {
				newCountries = newCountries.filter((c) => c != countryId)
			} else {
				newCountries = [...newCountries, countryId]
			}
		})
		setCountries(newCountries)
		if (onChange) onChange(newCountries)
	}

	const filterButtonClicked = (filter: IFilter): void => {
		setFilter(filter)
		setModalVisible(true)
	}

	useEffect(() => {
		setErrorMaximized(disallowSelectAll && countries.length === world.features.length - disabledCountries.length)
	}, [countries])

	useEffect(() => {
		setCountries(receivedCountries)
	}, [value])

	return (
		<div
			data-testid={`map-container-${testId}`}
			className={styles.countrySelectMap}
			style={{ width: width + 'px' }}
		>
			<CountrySelectMap
				selectedCountries={countries}
				disabledCountries={disabledCountries}
				width={width}
				readOnly={readOnly}
				toggleCountry={toggleCountry}
				countryTranslationFunction={getCountryTranslation}
				selectedRegion={
					(filter.continent
						? filter.continent.toLowerCase()
						: filter.region
						? filter.region.toLowerCase()
						: 'world') as RegionOptions
				}
				tooltipDataChanged={setTooltipData}
				tooltip={tooltip}
				color={color}
				testId={testId + '-cs-map'}
			/>
			{readOnly ? (
				''
			) : (
				<>
					<div data-testid={`map-buttons-container-${testId}`} className={styles.regionButtons}>
						<div data-testid={`map-continents-${testId}`} id="businessRegions">
							<Button
								text={lblFilterAllCountries}
								onClick={() => {
									filterButtonClicked({})
								}}
								short={true}
								type="secondary"
								iconType="search"
								iconPosition="right"
								iconColor={tokens.ColorBlueBrand}
								testId={testId + '-all-countries'}
							/>
							<Button
								text={lblFilterRegionAMER}
								onClick={() => {
									filterButtonClicked({ region: 'AMER' })
								}}
								short={true}
								type="secondary"
								testId={testId + '-AMER'}
							/>
							<Button
								text={lblFilterRegionAPAC}
								onClick={() => {
									filterButtonClicked({ region: 'APAC' })
								}}
								short={true}
								type="secondary"
								testId={testId + '-APAC'}
							/>
							<Button
								text={lblFilterRegionEMEA}
								onClick={() => {
									filterButtonClicked({ region: 'EMEA' })
								}}
								short={true}
								type="secondary"
								testId={testId + '-EMEA'}
							/>
						</div>
						<div data-testid={`map-regions-${testId}`} id="continentRegions">
							<Button
								text={lblFilterContNA}
								onClick={() => {
									filterButtonClicked({ continent: 'NA' })
								}}
								short={true}
								type="secondary"
								testId={testId + '-NA'}
							/>
							<Button
								text={lblFilterContSA}
								onClick={() => {
									filterButtonClicked({ continent: 'SA' })
								}}
								short={true}
								type="secondary"
								testId={testId + '-SA'}
							/>
							<Button
								text={lblFilterContEU}
								onClick={() => {
									filterButtonClicked({ continent: 'EU' })
								}}
								short={true}
								type="secondary"
								testId={testId + '-EU'}
							/>
							<Button
								text={lblFilterContAF}
								onClick={() => {
									filterButtonClicked({ continent: 'AF' })
								}}
								short={true}
								type="secondary"
								testId={testId + '-AF'}
							/>
							<Button
								text={lblFilterContAS}
								onClick={() => {
									filterButtonClicked({ continent: 'AS' })
								}}
								short={true}
								type="secondary"
								testId={testId + '-AS'}
							/>
							<Button
								text={lblFilterContOC}
								onClick={() => {
									filterButtonClicked({ continent: 'OC' })
								}}
								short={true}
								type="secondary"
								testId={testId + '-OC'}
							/>
						</div>
					</div>
					<div
						data-testid={`map-modal-${testId}`}
						className={styles.countrySelectModalContainer}
						style={{ height: width * 0.65 + 1 + 'px' }}
					>
						<CountrySelectModal
							visible={modalVisible}
							value={countries}
							disabledCountries={disabledCountries}
							toggleCountry={toggleCountry}
							filter={filter}
							changeVisible={setModalVisible}
							countryTranslationFunction={getCountryTranslation}
							labelTranslationFunction={translationFunction}
							width={width}
							disallowSelectAll={disallowSelectAll}
							onBackToWorldMapButtonClick={() => {
								setFilter({})
							}}
							testId={testId + '-cs-modal'}
						/>
					</div>
					{disallowSelectAll && countries.length === world.features.length - disabledCountries.length && (
						<div
							className={cx(styles.selectAllError, { [styles.minimized]: !errorMaximized })}
							style={{
								height: errorMaximized ? 'calc(' + (width * 0.65 + 1) + 'px - 1.6rem)' : null,
								top: errorMaximized ? null : 'calc(' + (width * 0.65 + 1) + 'px - 5.2rem)'
							}}
							data-testid={`map-errors-${testId}`}
						>
							<div
								className={styles.errorCloseIcon}
								tabIndex={0}
								onClick={() => {
									setErrorMaximized(!errorMaximized)
								}}
								data-testid={testId + '-error-toggle'}
							>
								{errorMaximized ? (
									'×'
								) : (
									<Icon
										testId={`map-alert-img-${testId}`}
										type="alert-big"
										size="small"
										color={'ColorOrangeBurnt'}
									/>
								)}
							</div>
							<div data-testid={`map-error-container-${testId}`} className={styles.errorBody}>
								<div data-testid={`map-icon-container-${testId}`} className={styles.iconContainer}>
									<Icon
										testId={`alert-${testId}`}
										type="alert-big"
										size="small"
										color={'ColorOrangeBurnt'}
									/>
								</div>
								{selectAllErrorMessage}
							</div>
						</div>
					)}
				</>
			)}
			<div
				data-testid={`map-tooltip-container-${testId}`}
				ref={tooltip}
				className={cx(styles.tooltip, { [styles.visible]: tooltipData.visible })}
			>
				<Flag
					testId={`map-flags-${testId}`}
					countryCode={tooltipData.countryId}
					alt={tooltipData.countryName}
				/>
				<span data-testid={`map-tooltip-${testId}`}>{tooltipData.countryName}</span>
			</div>
		</div>
	)
}
