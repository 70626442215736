import cx from 'classnames'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import world from '../assets/data/world.json'
import { Flag } from '../flags/flag'
import { Button, Divider, Input } from '../index'
import styles from './country-select-with-map.module.scss'

interface IFilter {
	region?: string
	continent?: string
}

interface ICountry {
	properties: {
		name: string
	}
	id2: string
	id: string
}

export interface ICountrySelectModalProps {
	visible: boolean
	value: Array<string>
	disabledCountries?: Array<string>
	width?: number
	toggleCountry(countryOrCountries: string | Array<string>): void
	filter: IFilter
	disallowSelectAll?: boolean
	changeVisible(isVisible: boolean): void
	countryTranslationFunction(country: ICountry): string
	labelTranslationFunction(i18nKey: string): string
	onBackToWorldMapButtonClick?(): void
	testId
}

export const CountrySelectModal: FC<ICountrySelectModalProps> = ({
	visible,
	value,
	disabledCountries = [],
	width = 700,
	toggleCountry,
	filter,
	disallowSelectAll = false,
	changeVisible,
	countryTranslationFunction,
	labelTranslationFunction,
	onBackToWorldMapButtonClick,
	testId
}: ICountrySelectModalProps) => {
	const [searchString, setSearchString] = useState<string>('')
	const searchInputRef = useRef<HTMLDivElement>(null)
	const countrySelectModalRef = useRef<HTMLDivElement>(null)
	const backToMapBtnRef = useRef<HTMLDivElement>(null)
	const [countryListHeight, setCountryListHeight] = useState<number>(0)
	const [backToMapBtnRightPosition, setBackToMapBtnRightPosition] = useState<number>(0)

	const translationKeyPrefix = 'countrySelectWithMap.modal.'
	const lblBackToWorldMap: string = labelTranslationFunction
		? labelTranslationFunction(translationKeyPrefix + 'back.to.world.map')
		: 'Back to world map'
	const lblSearch: string = labelTranslationFunction
		? labelTranslationFunction(translationKeyPrefix + 'search')
		: 'Type here to find countries'
	const lblSelectAll: string = labelTranslationFunction
		? labelTranslationFunction(translationKeyPrefix + 'select.all')
		: 'Select All'
	const lblNoMatchingCountries: string = labelTranslationFunction
		? labelTranslationFunction(translationKeyPrefix + 'no.matching.countries')
		: 'There are no countries matching your search and filter critera.'

	const visibleCountries = useMemo<Array<ICountry>>(() => {
		return world.features
			.filter((country) => {
				return (
					(!filter.region && !filter.continent) ||
					country.region == filter.region ||
					country.continent == filter.continent
				)
			})
			.filter((country) => {
				return (
					!searchString ||
					countryTranslationFunction(country).toLowerCase().includes(searchString.toLowerCase())
				)
			})
			.sort((a, b) => (countryTranslationFunction(a) > countryTranslationFunction(b) ? 1 : -1))
	}, [filter, searchString, value])

	const selectAllChecked = useMemo<boolean>(() => {
		return (
			visibleCountries.filter((c) => !disabledCountries.includes(c.id)).length ==
			visibleCountries.filter((c) => value.includes(c.id)).length
		)
	}, [visibleCountries, value])

	const selectDeselectAll = (): void => {
		if (selectAllChecked) {
			// deselect all visible
			toggleCountry(
				visibleCountries
					.filter((c) => !disabledCountries.includes(c.id) && value.includes(c.id))
					.map((selectedCountry) => selectedCountry.id)
			)
		} else {
			// select all visible
			toggleCountry(
				visibleCountries
					.filter((c) => !disabledCountries.includes(c.id) && !value.includes(c.id))
					.map((selectedCountry) => selectedCountry.id)
			)
		}
	}

	useEffect(() => {
		if (visible && !selectAllChecked && Object.keys(filter).length !== 0) {
			toggleCountry(
				visibleCountries
					.filter((c) => !disabledCountries.includes(c.id) && !value.includes(c.id))
					.map((selectedCountry) => selectedCountry.id)
			)
		}
	}, [visible, filter])

	useEffect(() => {
		if (searchInputRef.current) {
			setCountryListHeight(width * 0.65 + 1 - searchInputRef.current.clientHeight - 10) // 10 = size of the scroll bar in windows
		}
	}, [searchInputRef, width])

	useEffect(() => {
		if (countrySelectModalRef.current && backToMapBtnRef.current) {
			setBackToMapBtnRightPosition(
				(width - countrySelectModalRef.current.clientWidth - backToMapBtnRef.current.clientWidth) / 2
			)
		}
	}, [countrySelectModalRef, backToMapBtnRef, width])

	return (
		<>
			<div
				className={cx(styles.countrySelectModal, { [styles.hidden]: !visible })}
				style={{ height: width * 0.65 + 1 + 'px' }}
				ref={countrySelectModalRef}
				data-testid={`map-modal-container-${testId}`}
			>
				<div data-testid={`map-ref-${testId}`} ref={searchInputRef}>
					<Input
						id="search"
						hint={lblSearch}
						onChangeFunction={(v) => {
							setSearchString(v)
						}}
						value={searchString}
						label=""
						leadingIcon="search"
						testId={testId + '-search'}
					/>
				</div>
				<Divider />
				<div
					data-testid={`map-list-${testId}`}
					className={styles.list}
					style={{ height: countryListHeight + 'px' }}
				>
					{visibleCountries.length > 0 ? (
						(filter.region || filter.continent || !disallowSelectAll) && (
							<div data-testid={`map-all-${testId}`} className={styles.selectAll}>
								<input
									id="cb-selectall"
									checked={selectAllChecked}
									type="checkbox"
									onChange={selectDeselectAll}
									data-testid={testId + '-cb-select-all'}
								/>
								<label data-testid={`map-select-label-${testId}`} htmlFor={'cb-selectall'}>
									{lblSelectAll}
								</label>
							</div>
						)
					) : (
						<div data-testid={`map-no-coutries-${testId}`}>{lblNoMatchingCountries}</div>
					)}
					<div data-testid={`map-checkbox-${testId}`} className={styles.countryCheckboxes}>
						{visibleCountries?.map((country) => (
							<div
								className={cx(styles.countryCheckbox, {
									[styles.disabled]: disabledCountries.includes(country.id)
								})}
								key={country.id}
								data-testid={`map-country-checkbox-${testId}`}
							>
								<input
									id={'cb-' + country.id}
									checked={value.includes(country.id)}
									type="checkbox"
									onChange={() => {
										toggleCountry(country.id)
									}}
									disabled={disabledCountries.includes(country.id)}
									data-testid={testId + '-cb-' + country.id}
								/>
								<label data-testid={`map-label-country-${testId}`} htmlFor={'cb-' + country.id}>
									<Flag
										testId={`map-flags-translation-${testId}`}
										countryCode={country.id2}
										alt={countryTranslationFunction(country)}
									/>
									{countryTranslationFunction(country)}
								</label>
							</div>
						))}
					</div>
				</div>
			</div>
			<div
				className={cx(styles.backToWorldMapButton, { [styles.hidden]: !visible })}
				style={{ right: backToMapBtnRightPosition + 'px' }}
				ref={backToMapBtnRef}
				data-testid={`map-back-to-${testId}`}
			>
				<Button
					short={true}
					type="secondary"
					text={lblBackToWorldMap}
					onClick={() => {
						setSearchString('')
						changeVisible(!visible)
						onBackToWorldMapButtonClick()
					}}
					testId={testId + '-back-to-world-map'}
				/>
			</div>
		</>
	)
}
