import { ColorWhite } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { FocusEvent, ReactElement, Ref, useCallback, useEffect, useRef } from 'react'
import { Button, Icon, Input } from '../../local-core-ui'
import styles from './search-button-n-input.module.scss'

export enum sbniType {
	button,
	input
}

export interface ISearchButtonNInputProps {
	value: string
	onChangeFilter(value: string): void
	hint: string
	textButton?: string
	onClick?(): void
	type: sbniType
	showButtonAsIcon?: boolean
	testId: string
}

export const SearchButtonNInput = ({
	value,
	onChangeFilter,
	hint,
	textButton,
	onClick,
	type,
	showButtonAsIcon = false,
	testId
}: ISearchButtonNInputProps): ReactElement => {
	const inputRef: Ref<HTMLInputElement> = useRef(null)

	const focusInput = useCallback(() => {
		if (type === sbniType.input) {
			if (inputRef.current) {
				const inputElement = inputRef.current.nextElementSibling as HTMLInputElement
				inputElement.focus()
			}
		}
	}, [type])

	useEffect(() => {
		focusInput()
	}, [type, focusInput])

	const handleBlurInput = (event: FocusEvent<HTMLDivElement>) => {
		const selectedElement = event.relatedTarget as HTMLElement
		const elementStyles = selectedElement ? selectedElement.classList : undefined
		if (elementStyles && elementStyles[0] === 'button-clear-input') {
			focusInput()
		} else if (!value && onClick) {
			onClick()
		}
	}

	const changeToInput = () => {
		if (onClick) onClick()
	}

	const onChangeInput = (inputValue: string) => {
		onChangeFilter(inputValue)
	}

	return (
		<div className={styles.searchNButtonInput}>
			{type === sbniType.button ? (
				showButtonAsIcon ? (
					<div className={styles.buttonSearch}>
						<div
							className={styles.iconSearch}
							onClick={() => changeToInput()}
							data-testid={testId + '-icon'}
						>
							<Icon testId="search-button-and-input" type="search" color={ColorWhite} />
						</div>
					</div>
				) : (
					<div>
						<Button
							text={textButton ? textButton : ''}
							onClick={() => changeToInput()}
							type="secondary"
							size="medium"
							testId={testId + '-secondary'}
						/>
					</div>
				)
			) : (
				<div
					className={styles.searchInput}
					onBlur={handleBlurInput}
					onFocus={focusInput}
					data-testid={testId + '-search-container'}
				>
					<Input
						id="search-user"
						ref={inputRef}
						hint={hint}
						label={''}
						value={value}
						onChangeFunction={(inputValue) => onChangeInput(inputValue)}
						size="medium"
						leadingIcon="search"
						testId={testId + '-search'}
					/>
				</div>
			)}
		</div>
	)
}
