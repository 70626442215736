import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RecordClassification } from '../../components/steward-filter-tabs/steward-filter-tabs'
import { AssignmentDetails, Record } from '../../types'
import { SetRecordsPayload } from './SetRecordsPayload'
import { PageSize, TileTypes } from './types'

export interface StewardState {
	retrieving: boolean
	assignments?: Array<AssignmentDetails>
	records?: Array<Record>
	forTile?: TileTypes
	requestedClassification?: RecordClassification
	pageIndex: number
	pageSize: PageSize
	total: number
	selectedRecords: Array<Record>
	forAssignee?: string
	forAssignment?: string
}

export const initialState: StewardState = {
	retrieving: true,
	pageIndex: 1,
	pageSize: 20,
	total: 0,
	selectedRecords: [],
	forTile: TileTypes.Assigned,
	requestedClassification: RecordClassification.NeedsReview
}

const stewardSlice = createSlice({
	name: 'steward',
	initialState,
	reducers: {
		setAssignments: (state, action: PayloadAction<Array<AssignmentDetails>>) => {
			state.assignments = action.payload
		},
		setRecords: (state, action: PayloadAction<SetRecordsPayload>) => {
			state.records = action.payload.records
			state.forTile = action.payload.forTile
			state.pageIndex = action.payload.pageIndex
			state.pageSize = action.payload.pageSize
			state.total = action.payload.total
			if (action.payload.requestedClassification)
				state.requestedClassification = action.payload.requestedClassification
		},
		setRetrieving: (state, action: PayloadAction<boolean>) => {
			state.retrieving = action.payload
		},
		setSelectedRecords: (state, action: PayloadAction<Array<Record>>) => {
			state.selectedRecords = action.payload
		},
		setSelectedAssignee: (state, action: PayloadAction<string>) => {
			state.forAssignee = action.payload
		},
		setSelectedAssignment: (state, action: PayloadAction<string | undefined>) => {
			state.forAssignment = action.payload
		},
		setForTile: (state, action: PayloadAction<TileTypes>) => {
			state.forTile = action.payload
		},
		setRequestedClassification: (state, action: PayloadAction<RecordClassification>) => {
			state.requestedClassification = action.payload
		}
	}
})

// Extract the action creators object and the reducer
const { actions, reducer } = stewardSlice
// Extract and export each action creator by name
export const {
	setAssignments,
	setRecords,
	setRetrieving,
	setSelectedRecords,
	setSelectedAssignee,
	setSelectedAssignment,
	setForTile,
	setRequestedClassification
} = actions

export default reducer
