import { ElementDetail } from '../types'

export const filterDeprecatedElements = (
	dataBlockList: Array<ElementDetail>,
	selectedElements: Array<string>,
	enableEmailVerification: boolean
): Array<ElementDetail> => {
	if (!enableEmailVerification) {
		const indexNumber = dataBlockList.findIndex((dataBlock) => dataBlock.blockId === 'emailverification')
		if (indexNumber > -1) dataBlockList.splice(indexNumber, 1)
	}

	const filteredDataBlockList: Array<ElementDetail> = [...dataBlockList]
	return filteredDataBlockList.map((block) => {
		block.levels.forEach((level) => {
			level.elements = level.elements.filter((element) => {
				if (selectedElements.includes(element.elementId) || !element.deprecated) {
					return element
				}
			})
			return level
		})
		return block
	})
}
