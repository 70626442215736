import { ReactElement } from 'react'
import { useWizardContext } from '../../../../components/wizardComposer/hooks/useWizardContext'
import { MatchRecommendation } from './match-recommendation/match-recommendation'
import { MatchStepV2 } from './match-step-v2'

export function WizardComposerMatching(): ReactElement {
	const { wizardContext } = useWizardContext()
	return (
		<>
			{wizardContext.projectWizardState.currentProject.matchingApproach === 'start-from-scratch-mr' ? (
				<MatchRecommendation />
			) : (
				<MatchStepV2 />
			)}
		</>
	)
}
