import { AxiosInstance, AxiosResponse } from 'axios'
import { MatchRule } from '../../../types'

export const deletePreviousMatchRules = (apiClient: AxiosInstance, sourceId: string): void => {
	const url = `/pls/matchrules/sourceId/${sourceId}?includeArchived=false&includeInactive=false`
	apiClient
		.get(url)
		.then((response: AxiosResponse<Array<MatchRule>>) => {
			const previousMatchRules = response.data
			if (previousMatchRules.length > 1) {
				previousMatchRules.forEach((matchRule) => {
					if (matchRule && matchRule.ruleType === 'SPECIAL_RULE') {
						const matchRuleId = matchRule.matchRuleId
						const url = `/pls/matchrules/${matchRuleId}`
						apiClient
							.delete(url)
							.then()
							.catch((error) => {
								console.error(error)
							})
					}
				})
			}
		})
		.catch((error) => {
			console.error(error)
		})
}
