import { FC, PropsWithChildren } from 'react'

interface TabPanelProps {
	value: number
	index: number
	id: string
	/**
	 * Refers to the tab element that controls the panel (tab id).
	 *
	 * Provides an accessible name for the tab panel.
	 */
	'aria-labelledby': string
	testId?: string
}

export const TabPanel: FC<PropsWithChildren<TabPanelProps>> = ({
	value,
	index,
	children,
	id,
	'aria-labelledby': ariaLabelLedBy,
	testId
}: PropsWithChildren<TabPanelProps>) => {
	return value === index ? (
		<div data-testid={`tab-panel-${testId}`} role="tabpanel" id={id} aria-labelledby={ariaLabelLedBy}>
			{children}
		</div>
	) : null
}

export default TabPanel
