/**
 * @class Divider
 */

import cx from 'classnames'
import { FC } from 'react'
import { createTokenList, getValueToken } from '../design-tokens/utils'
import * as tokens from '../shared/token-colors.json'
import styles from './divider.module.scss'

interface IDividerProps {
	size?: 'small' | 'medium' | 'large'
	color?: string
}

export const Divider: FC<IDividerProps> = ({ size = 'small', color = 'ColorBorderDivider' }: IDividerProps) => {
	const colorsTokenList = createTokenList(tokens)
	return (
		<hr
			className={cx(styles.divider, styles[size])}
			style={{ borderColor: getValueToken(colorsTokenList, color) }}
		/>
	)
}
export default Divider
