import { MutableRefObject } from 'react'
import { TFunction } from 'react-i18next'
import assessmentDelinquencyScoreCommentaryPrefixes from '../assessment-delinquency-score-commentary-prefixes.json'
import { ElementUIFacade } from '../ElementUIFacade'

const buildChildrenTree = (
	listFlattenElementsAssessmentDelinquencyScoreCommentary: Array<ElementUIFacade>,
	root: ElementUIFacade,
	t: TFunction<'translation'>,
	assessmentDelinquencyScoreSelectorValueRef: MutableRefObject<number>
) => {
	const groupElements: Array<ElementUIFacade> = []
	assessmentDelinquencyScoreCommentaryPrefixes.forEach((prefix) => {
		const elementList = listFlattenElementsAssessmentDelinquencyScoreCommentary.filter((delinquencyScoreElement) =>
			delinquencyScoreElement.elementId.startsWith(prefix)
		)
		if (elementList && elementList.length > 0) {
			const groupParent: ElementUIFacade = {
				parent: root,
				elementId: `${prefix}Id`,
				displayName: t(`${prefix}.element`),
				description: elementList[0].description,
				level: elementList[0].level,
				dataType: elementList[0].dataType,
				example: elementList[0].example,
				showChildrenBlocks: false,
				considerChildrenForCount: false,
				maximumElementsInOutput: assessmentDelinquencyScoreSelectorValueRef
			}
			elementList.sort((a: ElementUIFacade, b: ElementUIFacade) => {
				if (a.elementId > b.elementId) {
					return 1
				} else if (a.elementId < b.elementId) {
					return -1
				} else {
					return 0
				}
			})
			groupParent.childs = elementList
			groupElements.push(groupParent)
		}
	})
	return groupElements
}

export const buildAssessmentDelinquencyScoreCommentaryTree = (
	listFlattenElementsAssessmentDelinquencyScoreCommentary: Array<ElementUIFacade>,
	t: TFunction<'translation'>,
	assessmentDelinquencyScoreSelectorValueRef: MutableRefObject<number>
): Array<ElementUIFacade> => {
	const tree: Array<ElementUIFacade> = []
	if (listFlattenElementsAssessmentDelinquencyScoreCommentary.length) {
		const root: ElementUIFacade = {
			blockId: t(listFlattenElementsAssessmentDelinquencyScoreCommentary[0].blockId || ''),
			elementId: 'assessmentDelinquencyScoreCommentaryId',
			displayName: t('assessmentDelinquencyScoreCommentary.element'),
			description: t('assessmentDelinquencyScoreCommentary.description'),
			level: '3',
			dataType: t('ARRAY'),
			showChildrenBlocks: true,
			containsChildrenSelector: true,
			considerChildrenForCount: true,
			maximumElementsInOutput: assessmentDelinquencyScoreSelectorValueRef,
			outputDropdownOptions: [
				{ label: '1', value: '1' },
				{ label: '2', value: '2' },
				{ label: '3', value: '3' },
				{ label: '4', value: '4' },
				{ label: '5', value: '5' },
				{ label: '6', value: '6' },
				{ label: '7', value: '7' },
				{ label: '8', value: '8' },
				{ label: '9', value: '9' },
				{ label: '10', value: '10' },
				{ label: '11', value: '11' },
				{ label: '12', value: '12' },
				{ label: '13', value: '13' },
				{ label: '14', value: '14' },
				{ label: '15', value: '15' }
			]
		}
		tree.push(root)

		root.childs = buildChildrenTree(
			listFlattenElementsAssessmentDelinquencyScoreCommentary,
			root,
			t,
			assessmentDelinquencyScoreSelectorValueRef
		)
	}
	return tree
}
