import { ColorScaleRed } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { DNBButton, DNBTooltip } from '@dnb-uux-design-system/react'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import lock from '../../assets/images/lock.svg'
import { Icon } from '../../local-core-ui'
import { sideBlocksStringReplace } from '../data-block-searcher/helpers/side-blocks-string-replace'
import styles from './data-block-row.module.scss'

interface DataBlockRowProps {
	name: string
	description?: string
	available: number
	selected?: number

	onEditionClicked?(): void

	editionLocked: boolean
	testId: string
	readOnly?: boolean
	haveElementsDeprecated?: boolean
}

export function DataBlockRow({
	name,
	description,
	available,
	selected,
	onEditionClicked,
	editionLocked,
	testId,
	readOnly,
	haveElementsDeprecated = false
}: DataBlockRowProps): ReactElement {
	const { t } = useTranslation()
	const [isDetailShow, setIsDetailShow] = useState(false)

	const gridTemplate = readOnly
		? editionLocked
			? styles.gridTemplateDetailsClosed
			: styles.gridTemplateReadOnly
		: selected
		? isDetailShow
			? styles.gridTemplateDetailsOpen
			: styles.gridTemplateDetailsClosed
		: isDetailShow
		? styles.gridTemplateDetailsOpen
		: styles.gridTemplateButtonCenter

	return (
		<div data-testid="data-block-details" className={`${styles.dataBlockRowContainer} ${gridTemplate}`}>
			<div data-testid="data-block-row-name-container" className={styles.dataBlockRowNameContainer}>
				<p data-testid="data-block-row-name" className={styles.dataBlockRowName} title={t(name)}>
					{sideBlocksStringReplace(name)}
				</p>
				<p data-testid="data-block-row-available" className={styles.dataBlockRowAvailable}>
					{t('data.block.row.available', { available })}
				</p>
			</div>
			{editionLocked ? (
				<div data-testid="data-block-row-view-elements-lock" className={styles.dataBlockRowViewElements}>
					<img width="12px" src={lock} alt="view elements lock" />
				</div>
			) : readOnly ? null : (
				<div data-testid="data-block-row-view-elements" className={styles.dataBlockRowViewElements}>
					{haveElementsDeprecated && (
						<div className={styles.containerIconDeprecated}>
							<DNBTooltip arrow content={'This data block contains deprecated elements.'} placement="top">
								<div className={styles.iconWarning}>
									<Icon
										testId="alert-big-warning-dashboard-compo"
										type="emptywarning"
										size={'mini'}
										color={ColorScaleRed}
									/>
								</div>
							</DNBTooltip>
						</div>
					)}
					<DNBButton
						size="small"
						variant="primary"
						onClick={() => {
							if (onEditionClicked) {
								onEditionClicked()
							}
						}}
						data-testid={testId + '-add-edit'}
					>
						{t(selected ? 'data.block.row.edit.elements' : 'data.block.row.add.elements')}
					</DNBButton>
				</div>
			)}
			{description && !readOnly && (
				<>
					{isDetailShow && (
						<p className={styles.dataBlockRowDescription} title={description}>
							{description}
						</p>
					)}
					<p
						className={styles.dataBlockRowToggleDescription}
						role="button"
						tabIndex={1}
						onKeyUp={(event) => {
							if (event.key === 'Enter') {
								setIsDetailShow(!isDetailShow)
							}
						}}
						onClick={() => setIsDetailShow(!isDetailShow)}
						data-testid="data-block-row-description"
					>
						{t(isDetailShow ? 'data.block.row.show.less' : 'data.block.row.learn.more')}
					</p>
				</>
			)}
			{selected !== undefined && selected > 0 && (
				<p data-testid="data-block-row-selected" className={styles.dataBlockRowSelected}>
					{t('data.block.row.selected', { selected })}
				</p>
			)}
		</div>
	)
}
