import { FC } from 'react'
import styles from './tooltip.module.scss'

export interface IMatchScoreProps {
	matchScore: number
	testId?: string
}

export const MatchScore: FC<IMatchScoreProps> = ({ matchScore, testId }: IMatchScoreProps) => {
	return (
		<div data-testid={`tooltip-number-container-${testId}`} className={styles.tooltipSpanNumberWrapper}>
			<span data-testid={`tooltip-match-score-${testId}`} className={styles.tooltipSpanNumber}>
				{matchScore}
			</span>
		</div>
	)
}

export default MatchScore
