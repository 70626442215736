import { ColorScaleRed, ColorWhite } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ChangeEvent, MouseEventHandler, ReactElement, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import stagingWordmarkLogo from '../assets/images/dnb-staging-wordmark-logo.svg'
import wordmarkLogo from '../assets/images/dnb-wordmark-logo.svg'
import { useApi } from '../hooks'
import { Button, Icon } from '../local-core-ui'
import styles from './ForgotPassword.module.scss'

export function ForgotPassword(): ReactElement {
	const { t } = useTranslation()
	const [username, setUsername] = useState('')
	const [fetching, setFetching] = useState(false)
	const [showPrivacyNotice, setShowPrivacyNotice] = useState(false)
	const [resetRequested, setResetRequested] = useState(false)
	const [validationError, setValidationError] = useState(false)
	const authContainerRefForgot = useRef<HTMLDivElement>(null)
	const dnbLinksRefForgot = useRef<HTMLDivElement>(null)
	const privacyModalRefForgot = useRef<HTMLDivElement>(null)

	const apiClient = useApi()

	const validateUsernameInput = () => {
		username === '' ? setValidationError(true) : resetPassword()
	}
	const resetPassword = () => {
		setFetching(true)
		const url = '/pls/forgotpassword'
		apiClient
			.put(url, { Username: username, Product: 'Data Cloud Portal' })
			.then(() => {
				setFetching(false)
				setResetRequested(true)
			})
			.catch(() => {
				setResetRequested(false)
			})
			.then(() => setFetching(false))
	}

	const onUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value
		setUsername(newValue)
		setValidationError(newValue === '')
	}

	const handleCloseClick: MouseEventHandler<HTMLDivElement> = (event) => {
		const eventTargetNode = event.target as Node
		const cliclkedOnAuthContainerRefForgot =
			authContainerRefForgot.current && authContainerRefForgot.current.contains(eventTargetNode)
		const cliclkedOnDnbLinksRefForgot =
			dnbLinksRefForgot.current && dnbLinksRefForgot.current.contains(eventTargetNode)
		const cliclkedOnPrivacyModalRefForgot =
			privacyModalRefForgot.current && privacyModalRefForgot.current.contains(eventTargetNode)

		if (!(cliclkedOnAuthContainerRefForgot || cliclkedOnDnbLinksRefForgot || cliclkedOnPrivacyModalRefForgot)) {
			event.preventDefault()
			setShowPrivacyNotice(false)
		}
	}

	return (
		<div className={styles.loginBackground} onClick={handleCloseClick}>
			<div className={styles.forgotPassContainer} ref={authContainerRefForgot}>
				<div className={styles.forgotPassHeader}>
					<img
						className={styles.forgotPassLogo}
						alt="dnb-stg logo logo"
						aria-label="dnb-stg logo logo"
						src={import.meta.env.VITE_LOGIN_IMAGE === 'staging' ? stagingWordmarkLogo : wordmarkLogo}
					/>
					{fetching && (
						<div className={styles.beaconContainer}>
							<>
								<div className={styles.beacon} />
								<div className={styles.circle} />
							</>
						</div>
					)}
				</div>
				{!resetRequested && (
					<>
						<div className={styles.forgotPassTitle}>{t('login.forgotPassword.title')}</div>
						<div className={styles.forgotPassDesc}>
							<p>
								{t('login.forgotPassword.description') + ' '}
								<i>{t('login.forgotPassword.description.email') + ' '}</i>
								{' ' + t('login.forgotPassword.description.complement') + ' '}
								<i>{t('login.forgotPassword.description.complement.continue') + ' '}</i>
								{' ' + t('login.forgotPassword.description.complement.continue.complement') + ' '}
								<strong>{t('login.forgotPassword.description.dnb') + ' '}</strong>
								{t('login.forgotPassword.description.at') + ' '}
								<strong>{t('login.forgotPassword.description.db.tel') + ' '}</strong>
								{t('login.forgotPassword.description.for') + ' '}
								<strong>{t('login.forgotPassword.description.customers.us') + ' '}</strong>
								{t('login.forgotPassword.description.for') + ' '}
								<strong>{t('login.forgotPassword.description.customers.canada') + ' '}</strong>
								{t('login.forgotPassword.description.visit') + ' '}
								<a href={import.meta.env.VITE_SUPPORT_URL}>
									{t('login.forgotPassword.description.link')}
								</a>
								{' ' + t('login.forgotPassword.description.if') + ' '}
								<strong>{t('login.forgotPassword.description.dnb.associate')}</strong>
								{t('login.forgotPassword.description.contact') + ' '}
								<strong>{t('login.forgotPassword.description.helpdesk')}</strong>
							</p>
						</div>
						{validationError ? (
							<div className={styles.errorCard}>
								<div className={styles.errorIcon}>
									<Icon testId="warning-error" type="warning" size="small" color={ColorWhite} />
								</div>
								<div className={styles.errorText}>
									<p>
										{t('login.forgotPassword.errorCard')}
										<br />
										{t('login.forgotPassword.errorCard.complement')}
									</p>
								</div>
							</div>
						) : undefined}
						<div className={styles.forgotPassContent}>
							<div className={styles.forgotPassContent}>
								<div className={styles.fieldSetContainer}>
									<div className={styles.formLabel}>
										<label htmlFor="usernameForgot" className={styles.label}>
											Username&nbsp;
										</label>
									</div>
									<span className={styles.formInputField}>
										<input
											type="text"
											placeholder=""
											name="usernameForgot"
											id="usernameForgot"
											value={username}
											aria-label=""
											autoComplete="usernameForgot"
											required={true}
											className={styles.inputField}
											onChange={onUsernameChange}
										/>
									</span>
								</div>
							</div>
							{validationError && (
								<div className={styles.errorContainer}>
									<Icon
										testId="warning-user-name"
										type="warning"
										size="small"
										color={ColorScaleRed}
									/>
									<p className={styles.errorValidation}>{t('login.forgotPassword.error.username')}</p>
								</div>
							)}

							<button
								className={styles.button}
								onClick={validateUsernameInput}
								disabled={fetching || validationError}
							>
								{t('continue')}
							</button>
						</div>
					</>
				)}
				{resetRequested && (
					<div className={styles.forgotPassSuccess}>
						<p>{t('login.forgotPassword.email.sended', { username: username })}</p>
						<Link to="/">
							<Button
								text={t('login.forgotPassword.backto.login')}
								onClick={() => false}
								size="large"
								testId='"btn-forgot-back"'
							/>
						</Link>
					</div>
				)}
			</div>
			<div className={styles.dnbLinks} ref={dnbLinksRefForgot}>
				<a onClick={() => setShowPrivacyNotice(true)}>Privacy</a>
				{!resetRequested && (
					<>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<Link to="/">{t('login.forgotPassword.backto.signin')}</Link>
					</>
				)}
			</div>
			{/*-- The Privacy Modal*/}
			{showPrivacyNotice && (
				<div role="dialog" className={styles.privacyNotice}>
					<div className={styles.privacyContent} ref={privacyModalRefForgot}>
						<div className={styles.authHeader}>
							<img
								className={styles.authLogo}
								alt="dnb-stg logo logo"
								aria-label="dnb-stg logo logo"
								src={
									import.meta.env.VITE_LOGIN_IMAGE === 'staging' ? stagingWordmarkLogo : wordmarkLogo
								}
							/>
						</div>
						<div
							id="priv-body"
							style={{
								fontSize: '14px',
								background: '#fff',
								color: '#000',
								padding: '20px 25px 25px 20px',
								height: '100%',
								fontFamily: "'Roboto', 'Helvetica Neue', 'Helvetica', 'Segoe UI', 'Arial', 'sans-serif'"
							}}
						>
							<span className="priv-title" style={{ fontSize: '20px' }}>
								Privacy
								<br />
								<br />
							</span>
							Access to this site is governed by the following D&B Policies
							<br />
							<br />
							<a
								href="https://www.dnb.co.uk/utility-pages/privacy-policy.html"
								target="_blank"
								rel="noreferrer"
							>
								European Union Privacy Policy
							</a>
							<br />
							<br />
							<a
								href="http://www.dnb.com/utility-pages/privacy-policy.html"
								target="_blank"
								rel="noreferrer"
							>
								Cookie and Privacy Policy for USA
							</a>
							<br />
							<br />
							<br />
							<button className={styles.closeButton} onClick={() => setShowPrivacyNotice(false)}>
								Close
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
