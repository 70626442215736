import { useTranslation } from 'react-i18next'
import { ReportTile } from '../../../../components/report-tile/report-tile'
import { BaseReportSection } from '../../types/BaseReportSection'
import { EmailCategory } from './email-category/email-category'
import { EmailQuality } from './email-quality/email-quality'
import { EmailType } from './email-type/email-type'
import styles from './email-verification.module.scss'

type EmailVerificationProps = BaseReportSection

const EmailVerification = ({ uploadReport }: EmailVerificationProps) => {
	const { t } = useTranslation()
	const emailCategoryReport = uploadReport?.emailCategoryReport ?? null
	const emailTypeReport = uploadReport?.emailTypeReport ?? null
	const emailQualityReport = uploadReport?.emailQualityReport ?? null

	return (
		<ReportTile
			className={styles.emailVerification}
			header={t('report.page.emailVerification.tile.header') as string}
		>
			<div className={styles.topTilesContainer}>
				<EmailCategory emailCategoryReport={emailCategoryReport} />
				<EmailType emailTypeReport={emailTypeReport} />
			</div>
			<EmailQuality emailQualityReport={emailQualityReport} />
		</ReportTile>
	)
}

export { EmailVerification }
