import { AxiosInstance } from 'axios'
import { RefreshRematchConfig } from '../../types'

export const setRefreshRematch = async (
	apiClient: AxiosInstance,
	sourceId: string,
	refreshRematch: RefreshRematchConfig = {
		refresh: false,
		rematch: false,
		enabled: false,
		nextUpdate: ''
	}
): Promise<void> => {
	const url = `/pls/sources/sourceId/${sourceId}/integration/refresh-rematch`

	return apiClient.put(url, refreshRematch)
}
