const DELTA = 0x9e3779b9
import { Buffer } from 'buffer'

export function encrypt(plainText: string, plainKey: string): string {
	const text = Buffer.from(plainText)
	const key = Buffer.from(plainKey)

	// Copy contents from key to key32
	const key32 = new Uint32Array(4)
	const key32View = new DataView(key32.buffer)

	for (let offset = 0; offset < key.length && offset < 16; ++offset) key32View.setUint8(offset, key[offset])

	// Copy the input text to a buffer of size multiple of 4
	const textLength = text.length
	let bufferLength = textLength

	if (!textLength) return ''

	if (textLength % 4) bufferLength += 4 - (textLength % 4)

	bufferLength = bufferLength / 4
	const text32 = new Uint32Array(bufferLength + 1)
	const text32View = new DataView(text32.buffer)

	for (let offset = 0; offset < textLength; ++offset) text32View.setUint8(offset, text[offset])

	// Encode
	let previous = 0

	for (let offset = 0; offset < bufferLength; ++offset) {
		let v = text32[offset]
		let sum = 0

		for (let i = 0; i < 32; ++i) {
			v += (((previous << 4) ^ (previous >>> 5)) + previous) ^ (sum + key32[sum & 3])
			sum += DELTA
			previous += (((v << 4) ^ (v >>> 5)) + v) ^ (sum + key32[(sum >>> 11) & 3])
		}

		text32[offset] = v
	}

	text32[bufferLength] = previous

	return Buffer.from(text32.buffer).toString('base64')
}

export function decrypt(encryptedData: string, plainKey: string): string {
	const data = Buffer.from(encryptedData, 'base64')
	const key = Buffer.from(plainKey)

	// Copy contents from key to key32
	const key32 = new Uint32Array(4)
	const key32View = new DataView(key32.buffer)

	for (let offset = 0; offset < key.length && offset < 16; ++offset) key32View.setUint8(offset, key[offset])

	// Copy the input data to data32
	const dataLength = data.length
	const dataBlocks = dataLength / 4
	const numPasses = dataBlocks - 1

	if (numPasses <= 0) return ''

	const data32 = new Uint32Array(dataBlocks)
	const data32View = new DataView(data32.buffer)

	for (let offset = 0; offset < dataLength; ++offset) data32View.setUint8(offset, data[offset])

	// Decode
	let previous = data32[numPasses]

	for (let offset = numPasses - 1; offset >= 0; --offset) {
		let v = data32[offset]
		let sum = 0xc6ef3720

		for (let i = 0; i < 32; ++i) {
			previous -= (((v << 4) ^ (v >>> 5)) + v) ^ (sum + key32[(sum >>> 11) & 3])
			sum -= DELTA
			v -= (((previous << 4) ^ (previous >>> 5)) + previous) ^ (sum + key32[sum & 3])
		}

		data32[offset] = v
	}

	// Remove trailing zero bytes
	let byteLength = data32.byteLength - 5
	for (; data32View.getUint8(data32.byteOffset + byteLength) === 0 && byteLength >= 0; byteLength--);

	return Buffer.from(data32.buffer, data32.byteOffset, byteLength + 1).toString('utf8')
}
