import { AxiosInstance, AxiosResponse } from 'axios'
import { CustomObjectUsage } from '../../types/integration/CustomObjectUsage'

export const getIntegrationCustomObjectUsage = async (
	apiClient: AxiosInstance,
	sourceId: string
): Promise<CustomObjectUsage> => {
	const url = `/pls/enrichment-layouts/integration/sourceId/${sourceId}/field-limits`
	return apiClient.get(url).then((response: AxiosResponse<CustomObjectUsage>) => response.data)
}
