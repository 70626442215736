import cx from 'classnames'
import { t } from 'i18next'
import { ChangeEvent, ReactElement, useRef, useState } from 'react'
import { CheckBox, Grid } from '../../local-core-ui'
import arrayPrefixes from '../data-block-searcher/array-prefixes.json'
import mandatoryElements from '../data-block-searcher/arrayMandatoryElesAndAlerts.json'
import { agingBucketObject, ElementUIFacade } from './ElementUIFacade'
import {
	getElementIdPrefix,
	getElementIdSuffix,
	isElementChecked,
	isElementIdSubstringExists
} from './helpers/element-tree-helpers'
import styles from './ranges-and-figures-children-tree.module.scss'

interface AgingBucketProps {
	element: ElementUIFacade
	selectedElementList: Array<ElementUIFacade>
	onDropSelectorChange?: (newSelectedElements: Array<ElementUIFacade>) => void
	mandatoryIdArray: Array<string>
	setMandatoryIdArray: (arr: Array<string>) => void
	errorBannerVisibility(mandatoryIdArray: Array<string>): void
}

export const AgingBucket = ({
	element,
	selectedElementList,
	onDropSelectorChange,
	mandatoryIdArray,
	setMandatoryIdArray,
	errorBannerVisibility
}: AgingBucketProps): ReactElement => {
	const [agingValues, setAgingValues] = useState(element.agingBucketOptions)
	const agingValuesRef = useRef<Array<agingBucketObject>>([])
	if (element.agingBucketOptions && element.agingBucketOptions?.length > 0) {
		agingValuesRef.current = element.agingBucketOptions
	}

	const isAgingCheckboxChecked = (
		selectedElementList: Array<ElementUIFacade>,
		checkboxElementId: string,
		value: string
	) => {
		const checkboxElementIdPrefix = value.split('_')[0]
		const selectedIndex = selectedElementList.findIndex((selectedElement) => {
			const elementSuffix = getElementIdSuffix(selectedElement.elementId)
			const elementPrefix = getElementIdPrefix(selectedElement.elementId)
			return elementSuffix === checkboxElementId && elementPrefix === checkboxElementIdPrefix
		})
		return selectedIndex > -1
	}

	const addChildrenByAgingSelector = (agingId: string): Array<ElementUIFacade> => {
		const newSelectedElements: Array<ElementUIFacade> = [...selectedElementList]
		const childrenSelectorValueRef = element.maximumElementsInOutput

		if (childrenSelectorValueRef) {
			const blockChildren = element.childs || []
			blockChildren.forEach((blockChild) => {
				const isChildChecked = isElementChecked(
					selectedElementList,
					blockChild.elementId,
					undefined,
					true,
					blockChild
				)
				if (isChildChecked || blockChild.isRequiredByDefault) {
					blockChild.childs?.forEach((elem) => {
						if (elem.elementId.includes(agingId)) {
							const selectedElementIndex = newSelectedElements.findIndex(
								(currentSelectedElement) => currentSelectedElement.elementId === elem.elementId
							)
							if (selectedElementIndex < 0) {
								newSelectedElements.push(elem)
							} else if (selectedElementIndex >= 0) {
								newSelectedElements.splice(selectedElementIndex, 1)
							}
						}
					})
				}
			})
		}
		return newSelectedElements
	}

	const agingChangeFunction = (event: ChangeEvent<HTMLInputElement>, agingId: string) => {
		agingValuesRef?.current?.forEach((val) => {
			if (val.value === event.target.value) val.isChecked = event.target.checked
		})
		setAgingValues(agingValuesRef.current)
		if (element.maximumElementsInOutput) {
			element.maximumElementsInOutput.current =
				agingValuesRef?.current?.filter((elem) => elem.isChecked === true).length || 0
			element.agingBucketOptions = agingValuesRef?.current
			if (onDropSelectorChange) {
				onDropSelectorChange(addChildrenByAgingSelector(agingId))
			}
		}
		const mandatoryValidation = agingValuesRef?.current?.some((elem) => {
			return elem.isChecked === true
		})
		if (mandatoryValidation) {
			checkMandatoryStatus(element)
		} else {
			removeMandatoryCheck(element.displayName)
		}
	}

	const checkMandatoryStatus = (option: ElementUIFacade) => {
		const newSelectedElements: Array<ElementUIFacade> = [...selectedElementList]
		const prefixes: Array<string> = mandatoryElements[option?.elementId as keyof typeof mandatoryElements]
		const totalPrefixes: Array<string> = arrayPrefixes[option?.elementId as keyof typeof arrayPrefixes]
		const mandatoryIdExists = totalPrefixes?.some((element) => prefixes?.includes(element))
		if (option?.elementId && mandatoryIdExists && prefixes) {
			const mandatoryError = newSelectedElements.some((element) =>
				isElementIdSubstringExists(prefixes, element.elementId)
			)
			if (!mandatoryError && mandatoryIdArray.indexOf(option.displayName) < 0) {
				setMandatoryIdArray([...mandatoryIdArray, option.displayName])
				errorBannerVisibility([...mandatoryIdArray, option.displayName])
			} else {
				if (mandatoryError && mandatoryIdArray.indexOf(option.displayName) >= 0) {
					const updatedMandatoryArray = mandatoryIdArray.filter((ele) => {
						return ele !== option?.displayName
					})
					setMandatoryIdArray(updatedMandatoryArray)
					errorBannerVisibility(updatedMandatoryArray)
				}
			}
		}
	}
	const removeMandatoryCheck = (name: string) => {
		if (mandatoryIdArray.indexOf(name) >= 0) {
			mandatoryIdArray.splice(mandatoryIdArray.indexOf(name), 1)
			errorBannerVisibility(mandatoryIdArray)
			setMandatoryIdArray(mandatoryIdArray)
		}
	}
	return (
		<div>
			<div className={cx(styles.agingBucketContainer)}>{t('aging.bucket')}</div>
			<Grid testId="container-aging-bucket" container>
				{agingValues?.map(({ id, value, label }) => {
					return (
						<Grid testId="aging-padding" sm={6} key={id}>
							<div className={styles.agingPaddingDiv}>
								<CheckBox
									id={id}
									checked={isAgingCheckboxChecked(selectedElementList, id, value)}
									value={value}
									label={label}
									testId={id}
									onChange={(event) => agingChangeFunction(event, id)}
								/>
							</div>
						</Grid>
					)
				})}
			</Grid>
		</div>
	)
}
