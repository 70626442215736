import { AxiosInstance, AxiosResponse } from 'axios'
import { connectData } from '../../components/connect-manage-modal/connect-manage-modal'
import { SnowflakeData } from '../../components/snowflake-form/snowflake-form'
import { SuccessResponse } from './updateConnection'

export interface SalesforceData {
	oAuthSettings: {
		oauth: {
			code: string
			redirectUrl: string
			isSandbox: boolean
		}
	}
	systemId?: string
	nameConnection?: string
}

export const createConnection = async (
	apiClient: AxiosInstance,
	systemType: string,
	connectionData: SnowflakeData | SalesforceData | connectData
): Promise<SuccessResponse> => {
	const url = `/pls/integration/${systemType}/organization/connection`

	return apiClient
		.post(url, connectionData)
		.then(async (response: AxiosResponse<SuccessResponse>) => response.data)
		.catch((error) => {
			return error
		})
}
