import { AxiosInstance, AxiosResponse } from 'axios'
import { Domain, EntitlementResponse, PurposeOfUse } from '../../types'
import { EntityType } from '../../types/sources/EntityType'

export const getDataBlocksEntitlements = async (
	apiClient: AxiosInstance,
	purposeOfUse?: PurposeOfUse,
	entityType?: EntityType,
	includeElements = false
): Promise<Array<Domain>> => {
	const purposeOfUseParams = purposeOfUse
		? `&domainName=${encodeURIComponent(purposeOfUse.domain)}&recordType=${encodeURIComponent(
				purposeOfUse.recordType
		  )}`
		: ''

	const entityTypeParams = entityType ? `&entityType=${entityType}` : ''
	const url = `/pls/data-blocks/entitlement?includeElements=${
		includeElements ? 'true' : 'false'
	}${entityTypeParams}${purposeOfUseParams}`
	return apiClient.get(url).then((response: AxiosResponse<EntitlementResponse>) => response.data.domains)
}
