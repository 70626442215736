import { ColorBlueBrand, ColorBluePrimary } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { stewardViews } from '../../dashboard/steward/dashboard-steward'
import { Divider, Icon, Tab, Tabs } from '../../local-core-ui'
import { RootState, useAppDispatch, useAppSelector } from '../../store'

import { setRequestedClassification } from '../../store/steward/stewardSlice'
import styles from './steward-filter-tabs.module.scss'

export enum RecordClassification {
	NeedsReview = 'NEEDS_REVIEW',
	Accepted = 'ACCEPTED',
	Rejected = 'REJECTED'
}

interface StewardFilterTabsProps {
	selectedView: stewardViews
	onChangeView(view: stewardViews): void
	isNeedsReviewAvailable: boolean
}

export const StewardFilterTabs = ({
	selectedView,
	onChangeView,
	isNeedsReviewAvailable
}: StewardFilterTabsProps): ReactElement => {
	const { t } = useTranslation()
	const isCardViewSelected = selectedView === 'cardView'
	const isTableViewSelected = selectedView === 'tableView'
	const classificationTabs: RecordClassification[] = isNeedsReviewAvailable
		? [RecordClassification.NeedsReview, RecordClassification.Accepted, RecordClassification.Rejected]
		: [RecordClassification.Accepted, RecordClassification.Rejected]
	const selectSteward = (state: RootState) => state.steward
	const steward = useAppSelector(selectSteward)

	const currentClassificationIndex = classificationTabs.findIndex(
		(classification) => classification === steward.requestedClassification
	)
	const dispatch = useAppDispatch()

	const onTabChange = (tabIndex: number) => {
		const selectedClassification = classificationTabs[tabIndex]
		dispatch(setRequestedClassification(selectedClassification))
	}

	return (
		<div className={styles.stewardFilterTabsContainer}>
			<div className={styles.stewardFilterTabsBarContainer}>
				<Tabs
					colors={{
						defaultColor: ColorBluePrimary,
						hoverColor: ColorBlueBrand
					}}
					testId="dashboard-steward-tab-control"
					onChange={onTabChange}
					value={currentClassificationIndex !== -1 ? currentClassificationIndex : 0}
				>
					{classificationTabs.map((classification) => {
						return (
							<Tab
								key={classification}
								label={t(`dashboard.tab.steward.${classification}`)}
								id={`tab${classification}`}
								aria-controls="assignmentsListContainer"
							/>
						)
					})}
				</Tabs>
				<div className={styles.stewardFilterTabsViewsContainer}>
					<button
						className={`${styles.stewardFilterViewBtnContainer} ${
							isTableViewSelected ? styles.dashboardStewardIconBtnSelected : ''
						}`}
						onClick={() => onChangeView('tableView')}
					>
						<Icon
							testId="table-view-steward-filter-tabs"
							type="table-view"
							color={isTableViewSelected ? ColorBlueBrand : ''}
						/>
					</button>
					<button
						className={`${styles.stewardFilterViewBtnContainer} ${
							isCardViewSelected ? styles.dashboardStewardIconBtnSelected : ''
						} ${styles.stewardFilterTabsSpacingLeft}`}
						onClick={() => onChangeView('cardView')}
					>
						<Icon
							testId="card-view-steward-filter-tabs"
							type="card-view"
							color={isCardViewSelected ? ColorBlueBrand : ''}
						/>
					</button>
				</div>
			</div>
			<Divider color={ColorBluePrimary} />
		</div>
	)
}
