import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { deleteMatchRule as deleteMatchRuleAction } from '../actions'

export const deleteMatchRule =
	(matchRuleIndex: number): AppThunk =>
	(dispatch: TDispatch, getState: TGetState): void => {
		const matchRuleId = getState().projectWizard.currentProject.matchRules[matchRuleIndex].matchRule.matchRuleId

		if (matchRuleId) {
			const url = `/pls/matchrules/${matchRuleId}`

			const apiClient = getApiClient4Thunks(dispatch)
			apiClient
				.delete(url)
				.then(() => {
					dispatch(deleteMatchRuleAction(matchRuleIndex))
				})
				.catch((e) => {
					console.error(e)
				})
		} else {
			dispatch(deleteMatchRuleAction(matchRuleIndex))
		}
	}
