import { NEW_PROJECT, ProjectWizardActionTypes } from '../types'

export function newProjectAction(isAPIEnabled: boolean, isC4S: boolean): ProjectWizardActionTypes {
	return {
		type: NEW_PROJECT,
		payload: {
			isAPIEnabled: isAPIEnabled,
			isC4S: isC4S
		}
	}
}
