import { Team, UpdateProjectRequest, UserDetail } from '../../../types'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { updateCurrentProjectAction, updateLoadingNextStep } from '../actions'
import { updateTeam } from './updateTeam'

export const updateProject =
	(): AppThunk<Promise<void>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<void> => {
		dispatch(updateLoadingNextStep(true))
		const currentProject = getState().projectWizard.currentProject
		const url = `/pls/projects/projectId/${currentProject.id}`
		const updateProjectRequest: UpdateProjectRequest = {
			displayName: currentProject.name,
			projectDescription: currentProject.projectDescription
		}
		const apiClient = getApiClient4Thunks(dispatch)
		return apiClient
			.put(url, updateProjectRequest)
			.then(async () => {
				let team: Team = {
					TeamId: currentProject.team.TeamId,
					TeamName: currentProject.team.TeamName,
					TeamMembers: []
				}
				if (team.TeamId !== undefined) {
					const teamMembers = getState().projectWizard.currentProject.team.TeamMembers.map(
						(teamMember: UserDetail) => teamMember.Email
					)
					team = await updateTeam(team.TeamId, teamMembers, apiClient)
					dispatch(updateCurrentProjectAction({ team: team }))
				}
				dispatch(updateLoadingNextStep(false))
			})
			.catch((error) => {
				dispatch(updateLoadingNextStep(false))
				throw error
			})
	}
