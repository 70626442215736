import { ChangeEvent, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import information from '../../../../assets/images/info.svg'
import { AmeToggle } from '../../../../components/ameToggle/ameToggle'
import { DescriptionTextArea } from '../../../../components/description-text-area/description-text-area'
import { StewardableToggle } from '../../../../components/stewardableToggle/stewardableToggle'
import { useFeatures, useModules } from '../../../../hooks/useEntitlements'
import { Grid, Input } from '../../../../local-core-ui'
import { RootState, useAppDispatch, useAppSelector } from '../../../../store'
import { updateCurrentProjectAction } from '../../../../store/projectWizard/actions'
import { getDisplayNameForCrmSource } from '../../../../store/projectWizard/thunks/getDisplayNameForCrmSource'
import styles from './createSource.module.scss'

export interface CreateSourceProps {
	title?: string
}

export const CreateSource: FC<CreateSourceProps> = ({ title }) => {
	const { t } = useTranslation()
	const isAMEEnabled = useFeatures(['EnableAmeMatch'])
	const isStewardshipEnabled = useModules(['Stewardship'])
	const [missingSource, setMissingSource] = useState(false)
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const [sourceName, setSourceName] = useState(projectWizardState.currentProject.source.name || '')
	const dispatch = useAppDispatch()
	const isCRMSource = projectWizardState.currentProject.thirdPartyIntegration
	const isCRMSourceName = projectWizardState.currentProject.thirdPartyIntegration?.crmSource
	const disabled = projectWizardState.currentProject.source.id !== undefined
	const onAMEChange = (enable: boolean) => {
		dispatch(
			updateCurrentProjectAction({
				source: {
					enableAme: enable
				}
			})
		)
	}

	const onStewardableChange = (enable: boolean) => {
		dispatch(
			updateCurrentProjectAction({
				source: {
					stewardable: enable
				}
			})
		)
	}

	const onDescriptionChange = (newDescription: string) => {
		dispatch(
			updateCurrentProjectAction({
				source: {
					sourceDescription: newDescription
				}
			})
		)
	}

	const onSourceChange = (newSourceName: string) =>
		dispatch(updateCurrentProjectAction({ source: { name: newSourceName } }))

	return (
		<div data-testid="upload-page-chooser-container" className={styles.uploadChooserPageContainer}>
			{title ? (
				<h1 data-testid="title-page-text" className={styles.selectFileText} title={title}>
					{title}
				</h1>
			) : (
				<h1 data-testid="chooser-data-heading">{t('upload.chooser.data.heading')}</h1>
			)}
			<div className={`${styles.helpLine} ${styles.firstHelpLine}`}>{t('upload.chooser.help.text.line1')}</div>
			<div className={styles.helpLine}>
				{t('upload.chooser.help.text.line2.normal')}
				<b> {t('upload.chooser.help.text.line2.bold')}</b>
			</div>
			<div className={styles.tipLine}>
				<img className={styles.informationImage} src={information} alt={'information'} />{' '}
				{t('upload.chooser.tip')}
			</div>
			<div className={styles.sourceName}>
				<Grid testId="container-create-source" container>
					<Grid testId="label-create-source" sm={12}>
						<div className={styles.label}>
							Source name:{!isCRMSource && <span className={styles.required}>*</span>}
						</div>
					</Grid>
					{isCRMSource &&
						!disabled &&
						!sourceName?.startsWith(getDisplayNameForCrmSource(isCRMSourceName || '')) && (
							<Grid testId="CRM-create-source" sm={1}>
								<div className={styles.crmPrefix}>
									{getDisplayNameForCrmSource(isCRMSourceName || '')}-
								</div>
							</Grid>
						)}
					<Grid
						sm={
							isCRMSource &&
							!disabled &&
							!sourceName?.startsWith(getDisplayNameForCrmSource(isCRMSourceName || ''))
								? 5
								: 6
						}
						testId="input-source-name-create-source"
					>
						<div className={styles.inputWrapper}>
							<Input
								id="source-name"
								label={''}
								hint={t('upload.chooser.source.name.hint')}
								value={sourceName}
								onChangeFunction={(newSource) => {
									if (onSourceChange) onSourceChange(newSource)
									if (newSource.trim() === '' && !isCRMSource) {
										setMissingSource(true)
										setSourceName('')
									} else {
										setMissingSource(false)
										setSourceName(newSource)
									}
								}}
								required={false}
								size="fluid"
								error={missingSource}
								errorMessage={missingSource ? t('missing.source.name') : ''}
								onBlurFunction={() => {
									setMissingSource(sourceName.trim() === '' && !isCRMSource)
								}}
								maxLength={30}
								disabled={disabled}
								testId={'CreateSource-source-name'}
							/>
						</div>
					</Grid>
					<Grid testId="description-create-source" sm={6}>
						<div className={styles.sourceNameDescription}>
							{t(
								`${
									isCRMSource
										? 'crm.source.name.description'
										: 'upload.chooser.source.name.description'
								}`
							)}
						</div>
					</Grid>
					{isAMEEnabled ? (
						<Grid testId="ame-toggle-create-source" sm={12}>
							<AmeToggle
								checked={projectWizardState.currentProject.source.enableAme || false}
								onChange={(event) => onAMEChange(event.target.checked)}
								disabled={!isAMEEnabled}
							/>
						</Grid>
					) : undefined}
					{isStewardshipEnabled ? (
						<Grid testId="steward-toggle-create-source" sm={12}>
							<StewardableToggle
								checked={projectWizardState.currentProject.source.stewardable || false}
								onChange={(event) => onStewardableChange(event.target.checked)}
							/>
						</Grid>
					) : undefined}
				</Grid>
			</div>
			<DescriptionTextArea
				placeholder={t('upload.chooser.description.placeholder')}
				value={projectWizardState.currentProject.source.sourceDescription}
				maxLength={500}
				onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onDescriptionChange(e.target.value)}
				testId={`upload-chooser-description-CreateSource`}
				disabled={disabled}
			/>
		</div>
	)
}
