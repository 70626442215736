import { ColorSlateLight } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { DNBButton } from '@dnb-uux-design-system/react'
import { AxiosResponse } from 'axios'
import { ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { sbniType, SearchButtonNInput } from '../../../components/search-button-n-input/search-button-n-input'
import { SearchNSort } from '../../../components/search-n-sort/search-n-sort'
import { Spinner } from '../../../components/spinner/spinner'
import { UserDetailModal } from '../../../components/user-detail-modal/user-detail-modal'
import { UserTile } from '../../../components/user-tile/user-tile'
import { useApi, useSession } from '../../../hooks'
import { Button, Divider, DropdownOptions, Modal, TileCollection } from '../../../local-core-ui'
import { useCreateUser } from '../../../queries/useCreateUser'
import { useDeleteUser } from '../../../queries/useDeleteUser'
import { useUpdateUser } from '../../../queries/useUpdateUser'
import { useUsers } from '../../../queries/useUsers'
import { RootState, useAppDispatch, useAppSelector } from '../../../store'
import { UserDetail } from '../../../types'
import styles from './dashboard-tab-users.module.scss'

export const DashboardTabUsers = (): ReactElement => {
	const { t } = useTranslation()
	const [sbniTypeShow, setSbniTypeShow] = useState(sbniType.button)
	const selectSession = (state: RootState) => state.session
	const session = useAppSelector(selectSession)
	const dispatch = useAppDispatch()
	const [roles, setRoles] = useState<DropdownOptions[]>([])
	const [currentUser, setCurrentUser] = useState<UserDetail>()
	const [showUserModal, setShowUserModal] = useState(false)
	const [addButtonDisable, setAddButtonDisable] = useState(false)
	const [showModalDifferentDomains, setShowModalDifferentDomains] = useState<boolean>(false)
	const [pendingUser, setPendingUser] = useState<UserDetail>()
	const [msgModalWarning, setMsgModalWarning] = useState<{ i18nKey: unknown; values?: object }>()
	const currentTenantArray = session.tenants?.find((tenant) => tenant.Identifier === session.tenant)
	const tenantName = currentTenantArray.CompanyName as string
	const userMutation = useUpdateUser()
	const deleteUserMutation = useDeleteUser()
	const createUserMutation = useCreateUser()

	interface rolePermissions {
		role: string
		allowedRoles: string[]
	}

	// These are the roles currently supported by the UI, we have the ROLE and what ROLES it can set when creating a new user
	const supportedRoles: rolePermissions[] = [
		{ role: 'BUSINESS_ANALYST', allowedRoles: ['BUSINESS_ANALYST'] },
		{ role: 'DATA_STEWARD', allowedRoles: ['DATA_STEWARD'] },
		{ role: 'EXTERNAL_ADMIN', allowedRoles: ['EXTERNAL_ADMIN', 'BUSINESS_ANALYST', 'DATA_STEWARD'] },
		{
			role: 'INTERNAL_ADMIN',
			allowedRoles: ['INTERNAL_ADMIN', 'EXTERNAL_ADMIN', 'BUSINESS_ANALYST', 'DATA_STEWARD']
		},
		{
			role: 'SUPER_ADMIN',
			allowedRoles: ['SUPER_ADMIN', 'INTERNAL_ADMIN', 'EXTERNAL_ADMIN', 'BUSINESS_ANALYST', 'DATA_STEWARD']
		}
	]
	const apiClient = useApi()

	// We get the list of BE supported roles and we cross check it with the UI list of supported roles and what they can set
	useEffect(() => {
		const url = '/pls/users/newuser/levels'
		const newRoles: DropdownOptions[] = []
		apiClient.get(url).then((response: AxiosResponse<string[]>) => {
			const allRoles: string[] = response.data
			if (allRoles) {
				const foundSupportedRole = supportedRoles.find(
					(supportedRole) => supportedRole.role === session.user?.AccessLevel
				)
				if (foundSupportedRole) {
					foundSupportedRole.allowedRoles.forEach((allowedRole) => {
						if (allRoles.includes(allowedRole)) {
							newRoles.push({ label: t('user.role.' + allowedRole), value: allowedRole })
						}
					})
					setRoles(newRoles)
				}
			}
		})
		/**
		 * We only want to run this effect once, which is why we are leaving the dependency array.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const [filterValue, setFilterValue] = useState('')
	const [showModalConfirmDeleteUser, setShowModalConfirmDeleteUser] = useState({
		show: false,
		email: '',
		username: '',
		actionText: 'prompt.delete.user.cancel',
		hideText: 'prompt.delete.user.confirm',
		onlyHideButton: false
	})

	const usersQuery = useUsers()

	const changeOrder = () => {
		// TODO: Check what to do
	}

	const handleFilterUser = (filterValue: string) => {
		setFilterValue(filterValue)
	}

	const addUserToStore = (user: UserDetail) => {
		setAddButtonDisable(true)
		if (!usersQuery.data?.map((u) => u.Email).includes(user.Email)) {
			createUserMutation.mutate(
				{ user: user, withSameDomain: true },
				{
					onSuccess: (data: { errorMsg: string; errorCode: string } | void) => {
						const txtErrorMsg = data?.errorMsg || ''
						const codeError = data?.errorCode
						const errorCodeNotPrimaryDomain = 'LEDP_18275'

						if (codeError && codeError === errorCodeNotPrimaryDomain) {
							setMsgModalWarning({
								i18nKey: 'modal.user.no.primary.domain.message'
							})
							setPendingUser(user)
							setShowModalDifferentDomains(true)
						} else {
							const emailDomains =
								txtErrorMsg !== ''
									? txtErrorMsg
											.match(/domain (\S+)/g)
											?.map((x) => x.match(/@(\S+)/g)[0].replace(/@/g, ''))
									: ''
							if (emailDomains !== undefined && emailDomains.length) {
								setMsgModalWarning({
									i18nKey: 'modal.user.diff.email.domains.message',
									values: {
										domainNewUser: emailDomains[0].toUpperCase(),
										domainContactUser: emailDomains[1].toUpperCase()
									}
								})
								setPendingUser(user)
								setShowModalDifferentDomains(true)
							}
						}
					}
				}
			)
		}
		setTimeout(() => {
			setShowUserModal(false)
			setAddButtonDisable(false)
		}, 0)
	}

	const deleteUserConfirm = (user: UserDetail) => {
		const username = user.FirstName + ' ' + user.LastName
		setShowModalConfirmDeleteUser({
			show: true,
			email: user.Email,
			username: username,
			actionText: 'prompt.delete.user.cancel',
			hideText: 'prompt.delete.user.confirm',
			onlyHideButton: false
		})
	}

	const deleteUserToStore = (userEmail: string) => {
		deleteUserMutation.mutate(userEmail)
	}

	const [logOutService] = useSession(dispatch)

	const doLogOut = () => {
		logOutService()
	}

	const updateUserStore = (user: UserDetail) => {
		userMutation.mutateAsync(user).finally(() => {
			setShowUserModal(false)
			if (user.Username === session.username) {
				doLogOut()
			}
		})
	}

	const onClickHandler = () => {
		setSbniTypeShow(sbniTypeShow === sbniType.input ? sbniType.button : sbniType.input)
	}

	return (
		<div className={styles.dashboardTabUsers}>
			<Modal open={showModalConfirmDeleteUser.show} isContainer={true} testId="DeleteUserModal">
				<p className="modal-content">
					{t('prompt.delete.user.message', { username: showModalConfirmDeleteUser.username })}
				</p>
				<div className={styles.buttonsContainer}>
					<Button
						size="large"
						type="primary"
						text={t(showModalConfirmDeleteUser.hideText)}
						onClick={() => {
							deleteUserToStore(showModalConfirmDeleteUser.email)
							setShowModalConfirmDeleteUser({ ...showModalConfirmDeleteUser, show: false })
							setShowUserModal(false)
						}}
						testId="delete-user-confirm-btn"
					/>
					<Button
						size="medium"
						type="secondary"
						text={t(showModalConfirmDeleteUser.actionText)}
						onClick={() => {
							setShowModalConfirmDeleteUser({ ...showModalConfirmDeleteUser, show: false })
						}}
						testId="delete-user-cancel-btn"
					/>
				</div>
			</Modal>
			<Modal
				open={showModalDifferentDomains}
				isContainer={true}
				testId={'modal-different-domains'}
				onClose={() => setShowModalDifferentDomains(false)}
			>
				<div className={styles.differentDomainsModalText}>
					<div className={styles.differentDomainsModalTitle}>{t('modal.user.diff.email.domains.title')}</div>
					<div className={styles.differentDomainsModalMessage}>
						<p>
							<Trans i18nKey={t(`${msgModalWarning?.i18nKey}`)} values={msgModalWarning?.values}></Trans>
						</p>
						<p>
							<Trans i18nKey={'modal.user.diff.email.domains.question'}>
								Are you sure you want to add
								<i style={{ fontWeight: 800 }}>{{ tenantName }}</i>
							</Trans>
						</p>
					</div>
				</div>
				<div className={styles.differentDomainsModalDivider}>
					<Divider color={ColorSlateLight} size="small" />
				</div>
				<div className={styles.differentDomainsModalButton}>
					<DNBButton
						size="default"
						variant="secondary"
						onClick={() => {
							setShowModalDifferentDomains(false)
						}}
					>
						{t('confirmation.modal.cancel')}
					</DNBButton>
					<DNBButton
						size="default"
						variant="primary"
						onClick={() => {
							if (pendingUser) {
								createUserMutation.mutateAsync({ user: pendingUser, withSameDomain: false })
							}
							setPendingUser(undefined)
							setShowModalDifferentDomains(false)
						}}
					>
						{t('user.tile.button.add')}
					</DNBButton>
				</div>
			</Modal>
			{!showModalConfirmDeleteUser.show && (
				<UserDetailModal
					user={currentUser}
					addUserFunction={addUserToStore}
					deleteUserFunction={deleteUserConfirm}
					updateUserFunction={updateUserStore}
					roles={roles}
					testId="user-detail-modal"
					open={showUserModal}
					onClose={() => setShowUserModal(false)}
					addButtonDisable={addButtonDisable}
				/>
			)}
			<div className={styles.rowUsers}>
				<Button
					size="medium"
					type="secondary"
					text={t('dashboard.button.add-user')}
					onClick={() => {
						setCurrentUser(undefined)
						setShowUserModal(true)
						//dispatch(showModal())
					}}
					testId="add-user-btn"
				/>
			</div>
			<div className={styles.sortSearchContainer}>
				<SearchNSort
					optionsDropdown={[
						{
							label: t('search.label.sortByName'),
							value: 'name'
						},
						{
							label: t('search.label.sortByEmail'),
							value: 'email'
						}
					]}
					hintDropdown={t('search.label.sortBy')}
					listToSort={usersQuery.data || []}
					onChangeSort={changeOrder}
					testId="user-search-and-sort"
				/>
				<SearchButtonNInput
					value={filterValue}
					onChangeFilter={handleFilterUser}
					hint={t('search.hint.team.members')}
					onClick={onClickHandler}
					type={sbniTypeShow}
					showButtonAsIcon={true}
					testId="user-search-input-btn"
				/>
			</div>
			{usersQuery.data?.length ? (
				<div className={styles.tileCollectionWrapper}>
					<TileCollection testId="users">
						{usersQuery.data
							.filter((user) => {
								const userName =
									user.FirstName === null || user.LastName === null
										? t('user.name.noneProvided').toLowerCase().trim()
										: `${user.FirstName} ${user.LastName}`.toLowerCase().trim()
								const userEmail = user.Email.toLowerCase().trim()
								return (
									userName.includes(filterValue.toLowerCase().trim()) ||
									userEmail.includes(filterValue.toLowerCase().trim())
								)
							})
							.map((user: UserDetail) => {
								return (
									<UserTile
										key={'user-tile-' + user.Username}
										userID={user.Username}
										name={
											user.FirstName === null || user.LastName === null
												? t('user.name.noneProvided')
												: user.FirstName + ' ' + user.LastName
										}
										isBusy={user.isBusy}
										role={t('user.role.' + user.AccessLevel, user.AccessLevel || '')}
										jobTitle={user.team || ''}
										emailAddress={user.Email}
										deleteUserButtonClick={() => deleteUserToStore(user.Username)}
										editUserButtonClick={() => {
											setCurrentUser(user)
											setShowUserModal(true)
											//dispatch(showModal(user))
										}}
										testId={'user-tile-' + user.Username}
									/>
								)
							})}
					</TileCollection>
				</div>
			) : usersQuery.isFetching ? (
				<div className={styles.loadingUsersSpinner}>
					<Spinner />
				</div>
			) : null}
		</div>
	)
}
