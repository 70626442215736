import { AxiosInstance, AxiosResponse } from 'axios'
import { RecordClassification } from '../../components/steward-filter-tabs/steward-filter-tabs'
import { PageSize, TileTypes } from '../../store/steward/types'
import { PublishedList } from '../../types'

export const getRecordsToSteward = async (
	apiClient: AxiosInstance,
	forTile: TileTypes | undefined,
	pageIndex: number,
	pageSize: PageSize,
	requestedClassification?: RecordClassification,
	forAssignee?: string,
	forAssignment?: string
): Promise<PublishedList> => {
	let url
	switch (forTile) {
		case TileTypes.Published:
			url = `/pls/steward/records/list/published?pageIndex=${pageIndex}&pageSize=${pageSize}${
				requestedClassification !== undefined ? '&classification=' + requestedClassification : ''
			}&sortField=COMPANY_NAME&sortOrder=ASC`
			break
		case TileTypes.Unassigned:
			url = `/pls/steward/records/list/unassigned?pageIndex=${pageIndex}&pageSize=${pageSize}`
			break
		case TileTypes.Assigned:
			break
		case TileTypes.AssignedToMe:
		default:
			if (forAssignment) {
				url = `/pls/steward/assignments/${forAssignment}/records/processing?pageIndex=${pageIndex}&pageSize=${pageSize}${
					requestedClassification !== undefined ? '&classification=' + requestedClassification : ''
				}&sortField=COMPANY_NAME&sortOrder=ASC`
			} else if (forAssignee) {
				url = `/pls/steward/records/list?assignee=${forAssignee}&pageIndex=${pageIndex}&pageSize=${pageSize}${
					requestedClassification !== undefined ? '&classification=' + requestedClassification : ''
				}`
			}
			break
	}
	if (url && forTile !== undefined) {
		return apiClient.get(url).then((response: AxiosResponse<PublishedList>) => response.data)
	} else return Promise.reject('No data to query')
}
