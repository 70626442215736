import { AxiosResponse } from 'axios'
import { CreateAssignmentRequest, CreateAssignmentResponse } from '../../../types'
import { ApiErrorState, setApiErrorAction } from '../../apiError'
import { AppThunk, getApiClient4Thunks, TDispatch } from '../../index'
import { setSelectedRecords } from '../stewardSlice'
import { getAssignments } from './getAssignments'

export const createAssignmentWithRecords =
	(newAssignment: CreateAssignmentRequest): AppThunk =>
	(dispatch: TDispatch): void => {
		const apiClient = getApiClient4Thunks(dispatch)
		apiClient
			.post<CreateAssignmentResponse>('/pls/steward/assignments/includerecords', newAssignment)
			.then((response: AxiosResponse<CreateAssignmentResponse>) => {
				if (response.status === 200) {
					dispatch(getAssignments(newAssignment.assignee))
					dispatch(setSelectedRecords([]))
				}
			})
			.catch((e) => {
				const errorMessage = JSON.parse(e.request.response).UIAction
				if (errorMessage) {
					const errorData: ApiErrorState = {
						errorCode: errorMessage.code,
						message: errorMessage.message
					}
					dispatch(setApiErrorAction(errorData))
				} else {
					window.history.pushState({}, 'stewardship', '/steward/review')
					const errorData: ApiErrorState = {
						message: e.response.data.Errors[0]
					}
					dispatch(setApiErrorAction(errorData))
				}
			})
	}
