import { KeyboardEvent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { ToolTip } from '../../local-core-ui'
import { Record } from '../../types'
import styles from './company-detail-tile.module.scss'
import { LegendTooltip } from './legend-tooltip'
import { Accepted, NeedsReview, Rejected } from './legends'

export type recordStatus = 'AUTO_ACCEPTED' | 'RECENTLY_ASSIGNED' | 'REMATCHED'

interface CompanyDetailTileProps {
	recordInformation: Record
	onSelect(): void
	showDetail: boolean
	isSelected: boolean
}

export const CompanyDetailTile = ({
	recordInformation,
	onSelect,
	showDetail,
	isSelected
}: CompanyDetailTileProps): ReactElement => {
	const { t } = useTranslation()
	const { recordId, inputFields, matchCandidate } = recordInformation
	const companyName = inputFields.companyName
	const matchCode = matchCandidate.matchInsight.ConfidenceCode
	const tradeStyle = inputFields.tradestyle
	const duns = inputFields.duns
	const industry = ''
	const status = ''
	const matchString = matchCandidate.matchInsight.MatchGrade.rawCode
	const hasMatch = matchCode > 0
	const missingField = '-'
	const isAutoAccepted = ['AUTO_ACCEPTED', 'ACCEPTED'].findIndex((status) => status === recordInformation.status) > -1

	const onTileClick = () => {
		onSelect()
	}

	const onKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			onTileClick()
		}
	}

	return (
		<div
			className={`${styles.companyDetailTileContainer} ${
				isSelected ? styles.companyDetailTileContainerSelected : ''
			} ${isAutoAccepted ? styles.companyDetailTileAutoAccepted : ''}`}
			onClick={onTileClick}
			onKeyPress={onKeyPress}
			tabIndex={0}
			aria-selected={isSelected}
			role="listitem"
		>
			<div className={styles.companyDetailTileHeader}>
				<div className={styles.companyDetailTileCompanyNameTradeStyleContainer}>
					<p className={styles.companyDetailTileCompanyName} title={companyName}>
						{companyName || missingField}
					</p>
				</div>
				{recordInformation.status !== undefined ? (
					<div className={styles.companyDetailTileClassificationIconsContainer}>
						<ToolTip
							key={`${recordId}-${recordInformation.status}`}
							position="right"
							customContent={<LegendTooltip />}
							testId={`${recordId}-${recordInformation.status}`}
						>
							{
								{
									AUTO_ACCEPTED: <Accepted committed={recordInformation.status === 'COMMITTED'} />,
									ACCEPTED: <Accepted committed={recordInformation.status === 'COMMITTED'} />,
									REJECTED: <Rejected committed={recordInformation.status === 'COMMITTED'} />,
									AUTO_REJECTED: <Rejected committed={recordInformation.status === 'COMMITTED'} />,
									NEEDS_REVIEW: <NeedsReview />
								}[recordInformation.classification]
							}
						</ToolTip>
					</div>
				) : undefined}
				<span
					className={`${styles.companyDetailTileMatchCode} ${
						hasMatch ? '' : styles.companyDetailTileNoMatchCode
					}`}
				>
					{matchCode}
				</span>
			</div>
			{showDetail && hasMatch && (
				<div className={styles.companyDetailTileDetailContainer}>
					<label className={styles.companyDetailTileTradeStyleContainer}>
						{t('company.detail.tile.trade.style')}
						<span className={styles.companyDetailTileTradeStyle}>{tradeStyle || missingField}</span>
					</label>
					<label className={styles.companyDetailTileDunsContainer}>
						{t('company.detail.tile.duns')}
						<span className={styles.companyDetailTileDuns}>{duns || missingField}</span>
					</label>
					<div className={styles.companyDetailTileIndustryStatusContainer}>
						<label className={styles.companyDetailTileIndustryContainer}>
							{t('company.detail.tile.industry')}
							<span className={styles.companyDetailTileIndustry}>{industry || missingField}</span>
						</label>
						<label className={styles.companyDetailTileStatusContainer}>
							{t('company.detail.tile.status')}
							<span className={styles.companyDetailTileStatus}>{status || missingField}</span>
						</label>
					</div>
					<label className={styles.companyDetailTileMatchStringContainer}>
						{t('company.detail.tile.match.string')}
						<span className={styles.companyDetailTileMatchString}>{matchString || missingField}</span>
					</label>
				</div>
			)}
		</div>
	)
}
