import { FC, MouseEvent, useEffect, useRef, useState } from 'react'
import { Button, LoadingState, Modal } from '../../local-core-ui'
import { useDropFolderAccess } from '../../queries/useDropFolderAccess'
import { useSource } from '../../queries/useSource'
import styles from './s3-credentials-modal.module.scss'

export interface IS3CredentialsModalProps {
	projectId: string
	open: boolean
	labels?: Record<string, unknown>
	tenant?: string
	generatedBy?: string
	onModalClose(event: MouseEvent<HTMLDivElement>): void
	sourceId: string
	testId: string
}

export const S3CredentialsModal: FC<IS3CredentialsModalProps> = ({
	projectId,
	open,
	labels,
	tenant,
	generatedBy,
	onModalClose,
	sourceId,
	testId
}: IS3CredentialsModalProps) => {
	const [isOpen, setIsOpen] = useState(open)
	const sourceQuery = useSource(sourceId)
	const dropFolderAccessQuery = useDropFolderAccess(projectId)

	const defaultLabels = {
		header: 'S3 Credentials',
		description: 'Existing credentials. Please save them in a secure location.',
		protocol: 'Protocol',
		amazonS3: 'Amazon S3',
		accessKey: 'Access Key',
		secret: 'Secret',
		AWSRegion: 'AWS Region',
		s3Pathway: 'S3 Drop Folder Path',
		dropFolder: 'DropFolder',
		download: 'Download',
		tenant: 'Tenant',
		generatedBy: 'Generated by',
		footer: "To learn more about the automating the file imports, visit <a target='_blank' href='http://help.lattice-engines.com'>http://help.lattice-engines.com</a>",
		filename: 'connect_credentials'
	}

	const useLabels = {
		...defaultLabels,
		...labels
	}

	const modalBody = useRef<HTMLDivElement>(null)

	const downloadFile = () => {
		const content = modalBody.current?.innerText || ''
		const filename = `${useLabels.filename}.txt`
		const element = document.createElement('a')
		element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content))
		element.setAttribute('download', filename)
		element.style.display = 'none'
		document.body.appendChild(element)
		element.click()
		document.body.removeChild(element)
	}

	useEffect(() => {
		setIsOpen(open)
	}, [open])

	return (
		<div className={styles.s3CredentialsModal}>
			<Modal
				open={isOpen}
				isContainer={true}
				onClose={(e) => {
					onModalClose(e)
				}}
				testId="S3CredentialsModal"
			>
				{(dropFolderAccessQuery.isFetching || sourceQuery.isFetching) && <LoadingState />}
				<h1 className={styles.header}>{useLabels.header}</h1>
				<div ref={modalBody} className={styles.s3CredentialsModalBody}>
					<p>{useLabels.description}</p>

					<div className="label">{useLabels.protocol}:</div>
					<p className="value">{useLabels.amazonS3}</p>

					<div className="label">{useLabels.accessKey}:</div>
					<p className="value">{dropFolderAccessQuery.data?.AccessKey}</p>

					<div className="label">{useLabels.secret}:</div>
					<p className="value">{dropFolderAccessQuery.data?.SecretKey}</p>

					<div className="label">{useLabels.AWSRegion}:</div>
					<p className="value">{dropFolderAccessQuery.data?.Region}</p>

					<div className="label">{useLabels.s3Pathway}:</div>
					<p className="value">{sourceQuery.data?.sourceFullPath}</p>

					<div className="label">{useLabels.dropFolder}:</div>
					<p className="value">{dropFolderAccessQuery.data?.DropBox}</p>

					{tenant && (
						<>
							<div className="label">{useLabels.tenant}:</div>
							<p className="value">{tenant}</p>
						</>
					)}

					{generatedBy && (
						<>
							<div className="label">{useLabels.generatedBy}:</div>
							<p className="value">{generatedBy}</p>
						</>
					)}

					{/*<p dangerouslySetInnerHTML={{ __html: useLabels.footer }} />*/}
				</div>
				<div className={styles.downloadButtonContainer}>
					<Button
						size="large"
						type="primary"
						text={useLabels.download}
						onClick={downloadFile}
						testId={testId + '-download'}
					/>
				</div>
			</Modal>
		</div>
	)
}
