import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { getFileSizeLimit } from './api/getFileSizeLimit'

export const useFileSizeLimit = (): UseQueryResult<number> => {
	const apiClient = useApi()

	const queryKey = ['getFileSizeLimit']
	const queryFunction = () => getFileSizeLimit(apiClient)

	return useQuery(queryKey, queryFunction)
}
