import cx from 'classnames'
import { FC } from 'react'
import world from '../assets/data/world.json'
import { IDistributionData } from './gdm-key'
import styles from './geographical-distribution-map.module.scss'

interface TableProps {
	data: IDistributionData[]
	updateAndShowTooltip: (newData: IDistributionData, elem?: HTMLDivElement) => void
	onChangeVisibleTooltip: (isTooltipVisible: boolean) => void
	color: string
	testId: string
	translationFunction(i18nKey: string): string
}
export const TableKeysWithNames: FC<TableProps> = ({
	data,
	updateAndShowTooltip,
	onChangeVisibleTooltip,
	color,
	testId,
	translationFunction
}: TableProps) => {
	const getCountryTranslation = (countryCode: string): string => {
		let name = world.features.filter((c) => c.id2 === countryCode)[0]?.properties.name || countryCode
		if (translationFunction && translationFunction('country.' + countryCode.toLowerCase())) {
			name = translationFunction('country.' + countryCode.toLowerCase())
		}
		return name
	}

	return (
		<div className={styles.containerBottom}>
			<div className={cx(styles.rowTable, styles.title)}>
				<div className={styles.countryContainer}>
					{translationFunction ? translationFunction('table.map.country') : 'Country'}
				</div>
				<div className={styles.percentageContainer}>
					{translationFunction ? translationFunction('table.map.percentage') : 'Percentage'}
				</div>
			</div>
			<div className={styles.tableData}>
				{data
					.sort((a, b) => (a.percent <= b.percent ? 1 : -1))
					.map((country: IDistributionData, index: number) => {
						return (
							<div
								key={country.countryCode + index}
								className={cx(styles.rowTable, { [styles.rowGray]: index % 2 === 0 })}
								tabIndex={0}
								data-testid={testId + '-bottom-' + country.countryCode}
								onMouseOver={(e) => updateAndShowTooltip(country, e.currentTarget)}
								onMouseOut={() => {
									onChangeVisibleTooltip(false)
								}}
								onFocus={(e) => {
									updateAndShowTooltip(country, e.currentTarget)
								}}
								onBlur={() => {
									onChangeVisibleTooltip(false)
								}}
							>
								<div
									data-testid={`gdm-flag-container-${testId}`}
									className={styles.countryContainer}
									style={{ color: color }}
								>
									{getCountryTranslation(country.countryCode)}
								</div>
								<div className={styles.percentageContainer}>
									{country.percent > 0.001 || country.percent === 0
										? Math.floor(country.percent * 1000) / 10
										: '<0.1'}
									%
								</div>
							</div>
						)
					})}
			</div>
		</div>
	)
}
