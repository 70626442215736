import { FC, useEffect, useState } from 'react'
import { Icon } from '..'
import styles from './avatar.module.scss'

export interface IAvatarData {
	label: string
	value: string
}

interface IAvatarProps {
	userData?: Array<IAvatarData>
	size?: 'small' | 'medium' | 'large'
	onClick?: () => void
	onHover?: () => void
	testId: string
}

export const Avatar: FC<IAvatarProps> = ({
	userData = undefined,
	size = 'large',
	onClick = undefined,
	onHover = undefined,
	testId
}: IAvatarProps) => {
	const [initials, setInitials] = useState('')

	useEffect(() => {
		if (userData) {
			const name = userData.find((data) => data.label.toLocaleLowerCase() === 'name')
			if (name) {
				const nameComponents = name.value.split(' ')
				const letterAvatar =
					nameComponents[0]?.charAt(0).toUpperCase() +
					(nameComponents[1] ? nameComponents[1].charAt(0).toUpperCase() : '')
				setInitials(letterAvatar)
			}
		}
	}, [userData])

	return (
		<div data-testid={`avatar-container-${testId}`} className={styles.avatarContainer}>
			{onHover === undefined && userData != undefined ? (
				<span className={styles.avatarWrapper} onClick={onClick} data-testid={`avatar-wrapper-${testId}`}>
					{userData.find((data) => data.label.toLocaleLowerCase() === 'name') ? (
						<span className={styles.letterAvatar} data-testid={`${testId}-initials`}>
							{initials}
						</span>
					) : (
						<Icon
							type="avatar"
							size={
								size === 'small'
									? 'mini'
									: size === 'medium'
									? 'small'
									: size === 'large'
									? 'medium'
									: null
							}
							testId="avatar-first"
						/>
					)}
				</span>
			) : (
				<span
					data-testid={`avatar-wrapper-next-${testId}`}
					className={styles.avatarWrapper}
					onMouseOver={onHover}
					onClick={onClick}
				>
					<Icon
						type="avatar"
						size={
							size === 'small' ? 'mini' : size === 'medium' ? 'small' : size === 'large' ? 'medium' : null
						}
						testId="avatar-second"
					/>
				</span>
			)}
		</div>
	)
}
