import { ReactElement, useEffect, useState } from 'react'
import { useUsers } from '../../queries/useUsers'
import { TileTypes } from '../../store/steward/types'
import { StewardAssigneeSummary } from '../../types'
import { AssignmentOverviewTile } from '../assignment-overview-tile/assignment-overview-tile'
import { AssignmentPublishedTile } from '../assignment-published-tile/assignment-published-tile'
import { ScrollableList } from '../scrollable-list/scrollable-list'
import { AssignmentsGroup } from './assignments-group'
import { AssignmentsManagerProps } from './assignments-manager'
import styles from './assignments-manager.module.scss'

export interface AssignedToWithName extends StewardAssigneeSummary {
	assigneeName: string
}

interface AssignmentsManagerListProps extends AssignmentsManagerProps {
	filterCriteria: string
	showDashboards: boolean
	onHideDashboard(): void
}

export const AssignmentsManagerList = ({
	publishedRecordsCounts,
	unassignedRecordsCount,
	assignedRecordsCount,
	assignedToMe,
	assignedToList,
	filterCriteria,
	showDashboards,
	onHideDashboard,
	selectedTile,
	onTileSelected,
	selectedAssignmentId,
	onAssignmentSelected,
	onAssign,
	onAssigneeSelected
}: AssignmentsManagerListProps): ReactElement => {
	const usersQuery = useUsers()

	const getUserName = (email: string): string => {
		const userInfo = usersQuery.data?.find((user) => {
			return user.Email === email
		})
		if (userInfo) {
			return userInfo.FirstName + ' ' + userInfo.LastName
		}
		return email
	}

	const mapAssignedToNames = (assignedTo: StewardAssigneeSummary): AssignedToWithName => {
		return {
			...assignedTo,
			assigneeName: getUserName(assignedTo.assignee)
		}
	}

	const sortAssignedTo = (a: AssignedToWithName, b: AssignedToWithName) => {
		const aUserName = a.assigneeName
		const bUserName = b.assigneeName
		if (aUserName.toLowerCase() > bUserName.toLowerCase()) {
			return 1
		} else if (aUserName.toLowerCase() < bUserName.toLowerCase()) {
			return -1
		} else {
			return 0
		}
	}

	const assignedToFullNames = assignedToList?.map(mapAssignedToNames).sort(sortAssignedTo) || []
	const assignedToMeFullNames = {
		...assignedToMe,
		assigneeName: getUserName(assignedToMe.assignee)
	}
	const isPublishedAvailable = publishedRecordsCounts && publishedRecordsCounts.total > 0
	const isUnassignedAvailable = unassignedRecordsCount !== undefined && unassignedRecordsCount > 0
	const isAssignedAvailable = true
	// assignedRecordsCount !== 0 TODO uncomment this once we wire up the assigned tile
	const isAssignedToMeAvailable = assignedToMe !== undefined && assignedToMe.totalAssignments > 0
	const assignedToFullList = isAssignedToMeAvailable
		? [assignedToMeFullNames, ...assignedToFullNames]
		: [...assignedToFullNames]

	//The purpose of this state is to trigger the re-render after a list of assignments is open/close and update the scroll-list scroll points.
	//This state is needed because without forcing the update, the scroll-list has some issues.
	//The assignment tiles are added inside the scroll list, but these tiles are not direct children of the scroll list,
	//so adding or removing the assignment tiles doesn't update the scroll-list causing some bugs.
	const [updateScrollPoints, setUpdateScrollPoints] = useState<boolean>(false)

	const handleDownloadRecords = (type: 'published' | 'unassigned' | 'assigned') => {
		console.log('download', type)
	}

	useEffect(() => {
		setUpdateScrollPoints(!updateScrollPoints)
		/**
		 * updateScrollPoints is not added as a dependency because it would create an infinite loop.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterCriteria])

	return (
		<ScrollableList testId="assignments-manager-assignments-list">
			{isPublishedAvailable && (
				<div
					className={`${styles.assignmentsManagerTileContainer} ${
						!showDashboards && styles.assignmentsManagerSummaryTileContainer
					}`}
				>
					<AssignmentPublishedTile
						onHideDashboard={onHideDashboard}
						onDownloadRecords={() => handleDownloadRecords('published')}
						showDetail={showDashboards}
						isSelected={selectedTile === TileTypes.Published}
						onFocus={() => {
							onTileSelected(TileTypes.Published)
						}}
						autoCount={publishedRecordsCounts?.autoAccepted || 0}
						manualCount={publishedRecordsCounts?.manualAccepted || 0}
						rejectedCount={publishedRecordsCounts?.rejected || 0}
					/>
				</div>
			)}
			{isUnassignedAvailable && (
				<div
					className={`${styles.assignmentsManagerTileContainer} ${
						!showDashboards && styles.assignmentsManagerSummaryTileContainer
					}`}
				>
					<AssignmentOverviewTile
						type="unassigned"
						onHideDashboard={onHideDashboard}
						onDownloadRecords={() => handleDownloadRecords('unassigned')}
						onAssign={onAssign}
						showDetail={showDashboards}
						isSelected={selectedTile === TileTypes.Unassigned}
						onFocus={() => {
							onTileSelected(TileTypes.Unassigned)
						}}
						totalCount={unassignedRecordsCount || 0}
					/>
				</div>
			)}
			{isAssignedAvailable && (
				<div
					className={`${styles.assignmentsManagerTileContainer} ${
						!showDashboards && styles.assignmentsManagerSummaryTileContainer
					}`}
				>
					<AssignmentOverviewTile
						type="assigned"
						onHideDashboard={onHideDashboard}
						onDownloadRecords={() => handleDownloadRecords('assigned')}
						showDetail={showDashboards}
						isSelected={selectedTile === TileTypes.Assigned}
						onFocus={() => {
							onTileSelected(TileTypes.Assigned)
						}}
						totalCount={assignedRecordsCount || 0}
					/>
				</div>
			)}
			{assignedToFullList.map((assignedTo, idx) => {
				return (
					<AssignmentsGroup
						key={`assignee-${assignedTo?.assignee}-assignments-group`}
						idx={idx}
						assignedTo={assignedTo}
						filterCriteria={filterCriteria}
						showDashboards={showDashboards}
						onHideDashboard={onHideDashboard}
						isSelected={selectedTile === idx}
						selectedAssignmentId={selectedAssignmentId}
						onFocus={(_, assignment) => {
							onTileSelected(idx)
							onAssigneeSelected(assignedTo)
							onAssignmentSelected(assignment)
						}}
						onAssignmentsGroupChange={() => setUpdateScrollPoints(!updateScrollPoints)}
					/>
				)
			})}
		</ScrollableList>
	)
}
