export class WizardCache {
	private static instance: WizardCache
	private cache: Map<string, unknown>

	/**
	 * The Singleton's constructor should always be private to prevent direct
	 * construction calls with the `new` operator.
	 */
	private constructor() {
		this.cache = new Map<string, unknown>()
	}

	/**
	 * The static method that controls the access to the singleton instance.
	 *
	 * This implementation let you subclass the Singleton class while keeping
	 * just one instance of each subclass around.
	 */
	public static getInstance(): WizardCache {
		if (!WizardCache.instance) {
			WizardCache.instance = new WizardCache()
		}

		return WizardCache.instance
	}

	/**
	 * Finally, any singleton should define some business logic, which can be
	 * executed on its instance.
	 */
	public async query(key: string, queryFunction: () => Promise<unknown>) {
		if (!this.cache.has(key)) {
			this.cache.set(key, queryFunction())
		}
		return await this.cache.get(key)
	}
}
