import cx from 'classnames'
import { t } from 'i18next'
import { ReportProgressBar } from '../../../components/report-progress-bar/report-progress-bar'
import { ReportSnackbar } from '../../../components/report-snackbar/report-snackbar'
import { CellDef, ColDef, ReportTable, RowDef } from '../../../components/report-table/report-table'
import { ReportTile } from '../../../components/report-tile/report-tile'
import styles from '../report.module.scss'
import { BaseReportSection } from '../types/BaseReportSection'
import { getOrderingMap } from './utils/getOrderingMap'

interface InputPresenceProps extends BaseReportSection {
	fileName?: string
}

type PresenceList = Array<{
	field: string
	count: number
	rate: number
}>

const inputPresenceOrdering = [
	'CompanyName',
	'RegistrationNumber',
	'DUNS',
	'Country',
	'Address_Street_1',
	'Address_Street_2',
	'Address_Street_3',
	'Address_Street_4',
	'City',
	'State',
	'PostalCode',
	'PhoneNumber',
	'Email',
	'Website',
	'FirstName',
	'LastName',
	'ContactId',
	'GlobalContactKey'
]

const additionalInputListOrdering = [
	'Alternate_CompanyName',
	'Alternate_Address_Street_1',
	'Alternate_Address_Street_2',
	'Alternate_Address_Street_3',
	'Alternate_Address_Street_4',
	'Alternate_City',
	'Alternate_State',
	'Alternate_PostalCode',
	'Alternate_Country',
	'Alternate_RegistrationNumber'
]

const getTableSort = <T,>(orderingList: string[], key: keyof T) => {
	const orderingMap = getOrderingMap(orderingList)

	const tableSort = (a: T, b: T) => {
		const indexA = orderingMap[a[key] as unknown as string] ?? -1
		const indexB = orderingMap[b[key] as unknown as string] ?? -1
		return indexA - indexB
	}

	return tableSort
}

const InputPresence = ({ uploadReport }: InputPresenceProps) => {
	const basicStats = uploadReport?.basicStats
	const inputPresenceReport = uploadReport?.inputPresenceReport
	const inputPresenceList = inputPresenceReport?.inputPresenceList
	const ADDITIONAL_INPUTS_PREFIX = 'Alternate'
	let filteredInputPresenceList: typeof inputPresenceList = []
	let additionalInputList: typeof inputPresenceList = []

	if (inputPresenceList) {
		filteredInputPresenceList = [...inputPresenceList]
			.filter((element) => !element.field.startsWith(ADDITIONAL_INPUTS_PREFIX))
			.sort(getTableSort(inputPresenceOrdering, 'field'))

		additionalInputList = [...inputPresenceList]
			.filter((element) => element.field.startsWith(ADDITIONAL_INPUTS_PREFIX))
			.sort(getTableSort(additionalInputListOrdering, 'field'))
	}

	const rowHeight = '2rem'
	const inputPresenceColumns: ColDef[] = [
		{
			label: t('report.page.inputPresence.table.header.field') as string,
			field: 'field',
			width: 355
		},
		{
			label: t('report.page.inputPresence.table.header.count') as string,
			field: 'count'
		},
		{
			label: t('report.page.inputPresence.table.header.rate') as string,
			field: 'rate'
		},
		{
			field: 'progress-bar',
			width: 232
		}
	]

	const getRows = (dataList: PresenceList | undefined): RowDef[] => {
		const rows: RowDef[] = []

		if (dataList) {
			dataList.forEach(({ field, count, rate }) => {
				const row: CellDef[] = [
					t(`report.page.inputPresence.table.field.${field}`) ?? '',
					new Intl.NumberFormat().format(count),
					`${rate}%`,
					<ReportProgressBar
						key={field}
						id={field}
						value={count}
						total={uploadReport?.basicStats.totalSubmitted ?? 0}
					/>
				]

				rows.push(row)
			})
		}

		return rows
	}

	return (
		<ReportTile
			title={t('report.page.inputPresence.title') as string}
			subtitle={t('report.page.inputPresence.subtitle') as string}
		>
			<div className={cx(styles.inputPresence)}>
				{inputPresenceList ? (
					<>
						<div className={styles.statsContainer}>
							<span className={styles.stat}>
								{t('input.presence.total.submitted')}{' '}
								<span className={styles.value}>
									{new Intl.NumberFormat().format(basicStats?.totalSubmitted || 0)}
								</span>
							</span>

							<span className={styles.stat}>
								{t('input.presence.successfully.absorbed')}{' '}
								<span className={styles.value}>
									{new Intl.NumberFormat().format(basicStats?.successCnt || 0)}
								</span>
							</span>
						</div>
						<ReportTable
							hideBorders
							columns={inputPresenceColumns}
							rowHeight={rowHeight}
							rows={getRows(filteredInputPresenceList)}
						/>
						{additionalInputList && additionalInputList.length > 0 && (
							<div className={styles.additionalInputTable}>
								<span className={styles.tableTitle}>
									{t('report.page.inputPresence.table.additionalInput.title')}
								</span>
								<ReportTable
									hideBorders
									columns={inputPresenceColumns}
									rowHeight={rowHeight}
									rows={getRows(additionalInputList)}
								/>
							</div>
						)}
					</>
				) : (
					<ReportSnackbar />
				)}
			</div>
		</ReportTile>
	)
}

export { InputPresence }
