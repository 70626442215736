import { ColorOrangeBurnt } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import shareIcon from '../../assets/images/lightbulb.svg'
import { Button, Icon, Modal } from '../../local-core-ui'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import { ApiErrorState, clearApiErrorAction } from '../../store/apiError'
import { DevSiteData } from './dev-site-data'
import { DevSiteDataVersion } from './dev-site-data-version'
import styles from './error-modal.module.scss'
import { OAuthSection } from './oauth-section'

export const ErrorModal = (): ReactElement => {
	const { t } = useTranslation()
	const selectApiError = (state: RootState) => state.apiError
	const apiErrorState: ApiErrorState = useAppSelector(selectApiError)
	const dispatch = useAppDispatch()
	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		if (
			apiErrorState.message ||
			apiErrorState.errorCode ||
			apiErrorState.requestURL ||
			apiErrorState.oAuthC4SError
		) {
			setIsOpen(true)
		}
	}, [apiErrorState])

	const closeModal = () => {
		setIsOpen(false)
		dispatch(clearApiErrorAction())
		window.history.back()
	}

	const closeDialog = () => {
		setIsOpen(false)
		dispatch(clearApiErrorAction())
		window.history.back()
	}

	return (
		<div className={styles.errorMessageModalContainer}>
			<Modal open={isOpen} showButtonClose={true} testId="ErrorModal" onClose={closeModal}>
				<div data-testid="error-message-icon-container" className={styles.errorMessageModalAlertIconContainer}>
					{apiErrorState.additionalInfo === '' ? (
						<Icon testId="alert-big-error-modal" type="alert-big" size="medium" color={ColorOrangeBurnt} />
					) : null}
				</div>
				{apiErrorState.errorCode === 'LEDP_90003' ? (
					<DevSiteDataVersion apiError={apiErrorState} />
				) : (
					<>{apiErrorState.additionalInfo === '' ? <DevSiteData apiError={apiErrorState} /> : null}</>
				)}
				{apiErrorState?.message && (
					<p data-testid="error-message-modal-error-message" className={styles.errorMessageModalErrorMessage}>
						{t(apiErrorState.message)}
					</p>
				)}

				{apiErrorState?.errorCode &&
					apiErrorState.additionalInfo === '' &&
					apiErrorState.errorCode !== 'LEDP_90003' && (
						<p data-testid="error-message-modal-error-code" className={styles.errorMessageModalErrorCode}>
							{t('error.message.modal.error.code', { code: apiErrorState.errorCode })}
						</p>
					)}

				{apiErrorState?.additionalInfo && apiErrorState.additionalInfo !== apiErrorState.errorCode && (
					<p
						data-testid="error-message-modal-additional-info"
						className={styles.errorMessageModalAdditionalInfo}
					>
						{t(apiErrorState.additionalInfo)}
					</p>
				)}

				{apiErrorState.additionalInfo === '' ? (
					<div
						data-testid="error-message-modal-share-container"
						className={styles.errorMessageModalShareContainer}
					>
						<img
							data-testid="error-message-modal-share-icon"
							className={styles.errorMessageModalShareIcon}
							alt={'share-icon'}
							src={shareIcon}
						/>
						<p
							data-testid="error-message-modal-share-message"
							className={styles.errorMessageModalShareMessage}
						>
							{t('error.message.modal.share.message')}
						</p>
					</div>
				) : null}

				{apiErrorState.additionalInfo === '' ? (
					<Button
						type="primary"
						size="large"
						text={t('error.message.modal.contact.support')}
						onClick={() => window.open(import.meta.env.VITE_SUPPORT_URL, '_blank')}
						testId="error-modal-support"
					/>
				) : null}
				{apiErrorState?.oAuthC4SError && <OAuthSection apiError={apiErrorState} onClose={closeModal} />}
			</Modal>
		</div>
	)
}
