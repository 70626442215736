import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { RecordBlock } from './record-block'
import styles from './record-compare-card.module.scss'
import { RecordHeader } from './record-header'

export interface StandardDnBFields {
	businessName?: string
	tradeStyle?: string
	address?: string
	duns?: string
	uniqueId?: string
	phoneNumber?: string
	website?: string
	confidenceCode?: number
	matchGrade?: string
	matchDataProfile?: string
	country?: string
	isPublic?: boolean
	isMarketable?: boolean
	industry?: string
	status?: string
}

interface RecordCompareCardProps {
	standardFields: StandardDnBFields
	comparisonFields: StandardDnBFields
	isDnbMatch?: boolean
}

export const RecordCompareCard = ({
	standardFields,
	comparisonFields,
	isDnbMatch = false
}: RecordCompareCardProps): ReactElement => {
	const { t } = useTranslation()
	const {
		businessName,
		tradeStyle,
		address,
		duns,
		uniqueId,
		phoneNumber,
		website,
		confidenceCode,
		matchGrade,
		matchDataProfile,
		country,
		isPublic,
		isMarketable,
		industry,
		status
	} = standardFields
	const {
		businessName: comparisonBusinessName,
		tradeStyle: comparisonTradeStyle,
		address: comparisonAddress,
		duns: comparisonDuns,
		uniqueId: comparisonUniqueId,
		phoneNumber: comparisonPhoneNumber,
		website: comparisonWebsite,
		confidenceCode: comparisonConfidenceCode,
		matchGrade: comparisonMatchGrade,
		matchDataProfile: comparisonMatchDataProfile,
		country: comparisonCountry,
		isPublic: comparisonIsPublic,
		isMarketable: comparisonIsMarketable,
		industry: comparisonIndustry,
		status: comparisonStatus
	} = comparisonFields
	const confidenceCodeClassName = confidenceCode ? styles.bulletStyle : ''
	const publicPrivateIndicator = isPublic
		? t('card.compare.record.public')
		: isPublic !== undefined
		? t('card.compare.record.private')
		: undefined
	const marketabilityIndicator = isMarketable
		? t('card.compare.record.yes')
		: isMarketable !== undefined
		? t('card.compare.record.no')
		: undefined
	const comparisonPublicPrivateIndicator = comparisonIsPublic
		? t('card.compare.record.public')
		: comparisonIsPublic !== undefined
		? t('card.compare.record.private')
		: undefined
	const comparisonMarketabilityIndicator = comparisonIsMarketable
		? t('card.compare.record.yes')
		: comparisonIsMarketable !== undefined
		? t('card.compare.record.no')
		: undefined
	const hasConfidenceCode = confidenceCode !== undefined && confidenceCode > 0
	const hasDuns = duns !== undefined && duns !== ''
	const hasMatch = hasConfidenceCode && hasDuns

	return (
		<div className={`${styles.recordCompareCardContainer} ${isDnbMatch ? '' : styles.recordCompareCardToneDown}`}>
			<RecordHeader
				businessName={businessName}
				tradeStyle={tradeStyle}
				comparisonBusinessName={comparisonBusinessName}
				comparisonTradeStyle={comparisonTradeStyle}
				showIndicator={isDnbMatch}
				showIcon={isDnbMatch}
				iconLabel="stewardship.company.name"
			/>
			<RecordBlock
				iconType="location"
				details={[address]}
				detailClassName={{ 0: `${styles.wrapText} ${styles.wordSpacing}` }}
				comparisonDetails={[comparisonAddress]}
				showIndicator={isDnbMatch}
				showIcon={isDnbMatch}
				iconLabel="address"
			/>
			<RecordBlock
				iconType="globe"
				details={[country]}
				detailClassName={{ 0: `${styles.wordSpacing}` }}
				comparisonDetails={[comparisonCountry]}
				showIndicator={isDnbMatch}
				showIcon={isDnbMatch}
				iconLabel="country"
				noTopMargin
			/>
			<RecordBlock
				iconType="fingerprint"
				labels={[t('card.compare.record.duns'), t('card.compare.record.registration.id')]}
				details={[duns, uniqueId]}
				comparisonDetails={[comparisonDuns, comparisonUniqueId]}
				showIndicator={isDnbMatch}
				showIcon={isDnbMatch}
				iconLabel="company.id"
			/>
			<RecordBlock
				iconType="phone"
				details={[phoneNumber]}
				comparisonDetails={[comparisonPhoneNumber]}
				showIndicator={isDnbMatch}
				showIcon={isDnbMatch}
				iconLabel="phone.number"
			/>
			<RecordBlock
				iconType="www"
				details={[website]}
				comparisonDetails={[comparisonWebsite]}
				showIndicator={isDnbMatch}
				showIcon={isDnbMatch}
				iconLabel="website"
			/>
			<RecordBlock
				iconType="account_balance"
				labels={[t('card.compare.record.indicator')]}
				details={[publicPrivateIndicator]}
				comparisonDetails={[comparisonPublicPrivateIndicator]}
				showIndicator={isDnbMatch}
				showIcon={isDnbMatch}
				iconLabel="indicator"
			/>
			<RecordBlock
				iconType="send"
				labels={[t('card.compare.record.marketability')]}
				details={[marketabilityIndicator]}
				comparisonDetails={[comparisonMarketabilityIndicator]}
				showIndicator={isDnbMatch}
				showIcon={isDnbMatch}
				iconLabel="marketability"
			/>
			{hasMatch && isDnbMatch && (
				<RecordBlock
					iconType="match"
					title={t('card.compare.record.match.insights')}
					labels={[
						t('card.compare.record.duns'),
						t('card.compare.record.confidence.code'),
						t('card.compare.record.match.grade'),
						t('card.compare.record.match.data.profile'),
						t('card.compare.record.industry'),
						t('card.compare.record.status'),
						t('card.compare.record.marketability')
					]}
					details={[
						duns,
						confidenceCode?.toString(),
						matchGrade,
						matchDataProfile,
						industry,
						status,
						marketabilityIndicator
					]}
					detailClassName={{
						1: confidenceCodeClassName,
						2: styles.wordSpacing,
						3: styles.wordSpacing,
						4: styles.wordSpacing,
						5: styles.wordSpacing
					}}
					comparisonDetails={[
						comparisonDuns,
						comparisonConfidenceCode?.toString(),
						comparisonMatchGrade,
						comparisonMatchDataProfile,
						comparisonIndustry,
						comparisonStatus,
						comparisonMarketabilityIndicator
					]}
					showIcon
					iconLabel="match.insights"
					/* temporarily suppress helper sheet
					helperDetail={{
						3: (
							<button
								className={styles.downloadHelpSheet}
								onClick={handleMdpCodeHelpSheet}
								data-testid="mdp-code-help-sheet-btn"
							>
								{t('card.compare.record.download.help.sheet')}
							</button>
						)
					}}*/
				/>
			)}
		</div>
	)
}
