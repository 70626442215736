import cx from 'classnames'
import { ChangeEvent, FC } from 'react'
import styles from './checkbox.module.scss'

export interface ICheckBoxProps {
	id: string
	label?: string
	value?: string
	disabled?: boolean
	checked: boolean
	title?: string
	onChange?(event: ChangeEvent<HTMLInputElement>): void
	type?: 'default' | 'tab'
	testId: string
}

export const CheckBox: FC<ICheckBoxProps> = ({
	id,
	value,
	label,
	disabled = false,
	checked,
	title,
	onChange,
	type = 'default',
	testId
}: ICheckBoxProps) => {
	return (
		<div
			className={
				type !== 'tab'
					? styles.checkboxContainer
					: cx(styles.checkboxTabContainer, { [styles.disabledCheckboxContainer]: disabled })
			}
			data-testid={`checkbox-container-${testId}`}
		>
			<input
				id={id}
				type="checkbox"
				className={styles.checkbox}
				disabled={disabled}
				checked={checked}
				value={value}
				onChange={onChange}
				data-testid={testId}
				hidden={type === 'tab'}
			/>
			{type === 'tab' ? (
				<div
					className={styles.tabType}
					onClick={() => {
						document.getElementById(id).click()
					}}
					tabIndex={disabled ? -1 : 0}
					onKeyPress={(event) => {
						if (event.key === 'Enter') {
							document.getElementById(id).click()
						}
					}}
					aria-selected={checked}
					aria-disabled={disabled}
					data-testid={`checkbox-tab-type-${testId}`}
				>
					<div data-testid={`checkbox-label-container-${testId}`}>
						{label && (
							<span data-testid={`checkbox-label-tab-${testId}`} className={styles.checkboxText}>
								{label}
							</span>
						)}
					</div>
				</div>
			) : (
				<>
					<span
						className={cx(styles.checkboxCustom, { [styles.disabledCheckboxCustom]: disabled && checked })}
						data-testid={`checkbox-disabled-checked-${testId}`}
					/>
					{label && (
						<label
							data-testid={`checkbox-label-normal-${testId}`}
							htmlFor={id}
							className={cx({ [styles.disabled]: disabled })}
							title={title}
						>
							{label}
						</label>
					)}
				</>
			)}
		</div>
	)
}

export default CheckBox
