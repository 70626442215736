import { ColorGrayDark, ColorWhite } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { FocusEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContextMenu, ContextMenuOption, Icon } from '../../local-core-ui'
import { AssignmentTileContainer } from '../assignment-tile-container/assignment-tile-container'
import { AssignmentPublishedDetail } from './assignment-published-detail'
import styles from './assignment-published-tile.module.scss'

interface AssignmentPublishedTileProps {
	onHideDashboard(): void
	onDownloadRecords(): void
	showDetail: boolean
	autoCount: number
	manualCount: number
	rejectedCount: number
	isSelected: boolean
	onFocus(event: FocusEvent<HTMLDivElement>): void
}

export const AssignmentPublishedTile = ({
	onHideDashboard,
	onDownloadRecords,
	showDetail,
	autoCount,
	manualCount,
	rejectedCount,
	isSelected,
	onFocus
}: AssignmentPublishedTileProps): ReactElement => {
	const { t } = useTranslation()
	const contextMenuOptions: ContextMenuOption[] = [
		{
			label: t(`assignments.to.tile.${showDetail ? 'hide' : 'show'}.dashboard`),
			action: 'hideDashboard'
		},
		{
			label: t('assignments.to.tile.download.records'),
			action: 'downloadRecords'
		}
	]
	const [contextMenuColor, setContextMenuColor] = useState<string>(ColorGrayDark)

	const handleContextMenuClicked = (option: string) => {
		switch (option) {
			case 'hideDashboard':
				onHideDashboard()
				break
			case 'downloadRecords':
				onDownloadRecords()
				break
		}
	}

	useEffect(() => {
		setContextMenuColor(isSelected ? ColorWhite : ColorGrayDark)
	}, [isSelected])

	return (
		<AssignmentTileContainer
			isSelected={isSelected}
			showDetail={showDetail}
			testId="published-overview-tile"
			onFocus={onFocus}
		>
			<div
				className={`${styles.assignmentPublishedTileContainer} ${
					showDetail ? '' : styles.assignmentPublishedTileSummary
				}`}
			>
				<div className={styles.assignmentPublishedTileHeader}>
					<h4 className={styles.assignmentPublishedTileTitle}>{t('assignment.published.tile.title')}</h4>
					<button className={styles.assignmentPublishedTileContextMenuContainer}>
						<ContextMenu
							options={contextMenuOptions}
							onMenuClicked={handleContextMenuClicked}
							testId="published-aTile-kebab"
						>
							<Icon testId="three-dots-published-tile" type="three-dots" color={contextMenuColor} />
						</ContextMenu>
					</button>
				</div>
				{showDetail && (
					<AssignmentPublishedDetail
						isSelected={isSelected}
						onDownloadRecords={onDownloadRecords}
						autoCount={autoCount}
						manualCount={manualCount}
						rejectedCount={rejectedCount}
					/>
				)}
			</div>
		</AssignmentTileContainer>
	)
}
