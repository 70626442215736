import { DNBDialog, DNBRadio } from '@dnb-uux-design-system/react'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Element } from '../../types'
import styles from '../template-deprecated-field-modal/template-deprecated-field-modal.module.scss'

interface TemplateDeprecatedFieldModalProps {
	testId: string
	isOpen: boolean
	onClose: () => void
	onUseDeprecated: (element: string) => void
	onUseSuggested: (element: string) => void
	onNextElement: () => void
	deprecatedElements: Array<Element>
	replacingElements: Array<Element>
	evaluatedIndex?: number
}

export function TemplateDeprecatedFieldModal({
	testId,
	isOpen,
	onClose,
	onUseDeprecated,
	onUseSuggested,
	onNextElement,
	deprecatedElements,
	replacingElements,
	evaluatedIndex = 0
}: TemplateDeprecatedFieldModalProps): ReactElement {
	const { t } = useTranslation()
	const [recommendedCheck, setRecommendedCheck] = useState(true)
	const evaluatedElement: Element = deprecatedElements[evaluatedIndex]
	const deprecatedName = evaluatedElement?.displayName ? evaluatedElement.displayName : ''
	const suggestedFields: Array<string> = replacingElements
		?.filter((element: Element) => evaluatedElement?.replacements?.includes(element.elementId))
		.map((element: Element) => element.displayName)

	return (
		<div
			className={styles.containerTemplateDeprecatedFieldModal}
			data-testid="template-deprecated-field-modal-container"
		>
			<DNBDialog
				open={isOpen}
				onClose={onClose}
				data-testid={'TemplateDeprecatedFieldModal'}
				size={'default'}
				primaryCTA={() => {
					recommendedCheck ? onUseSuggested(deprecatedName) : onUseDeprecated(deprecatedName)
					onNextElement()
					setRecommendedCheck(true)
				}}
				primaryCTALabel={t('text.apply') ?? ''}
				hasCloseButton
			>
				<div className={styles.header}>
					<h1 data-testid="template-deprecated-field-modal-title" className={styles.title}>
						{t('template.deprecated.field.modal.title')}
					</h1>
				</div>
				<div className={styles.body}>
					<div className={styles.textWrapper}>
						<label className={styles.textLabel}>{t('template.deprecated.field.modal.text')}</label>
					</div>
					<div className={styles.radiosContainer}>
						<div className={styles.radioWrapper}>
							<DNBRadio
								size="small"
								value={'recommended'}
								id={'recommended'}
								checked={recommendedCheck}
								onChange={() => setRecommendedCheck(true)}
								data-testid={testId + '-radio-recommended'}
								label={''}
							/>
							<div className={styles.radioLabel}>
								<span className={styles.fontWeight}>
									{t('template.deprecated.field.modal.suggested')}&nbsp;
								</span>
								<span>
									{suggestedFields.length > 0
										? suggestedFields.map((field) => (
												<p className={styles.recommendedText} key={'suggested' + field}>
													{field}
												</p>
										  ))
										: null}
								</span>
							</div>
						</div>

						<div className={styles.radioWrapper}>
							<DNBRadio
								label={''}
								size="small"
								value={'notrecommended'}
								id={'notrecommended'}
								checked={!recommendedCheck}
								onChange={() => setRecommendedCheck(false)}
								data-testid={testId + '-radio-notrecommended'}
							/>
							<span>
								{t('template.deprecated.field.modal.deprecated')} {deprecatedName}
							</span>
						</div>
					</div>
				</div>
			</DNBDialog>
		</div>
	)
}
