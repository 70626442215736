import { AxiosInstance } from 'axios'
import { DeleteUserRequest } from '../../types'

export const deleteUser = async (apiClient: AxiosInstance, userEmail: string): Promise<void> => {
	const url = `/pls/users`

	const deleteUser: DeleteUserRequest = {
		targetUsername: userEmail
	}
	return apiClient.delete(url, { data: deleteUser })
}
