import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Grid } from '../../local-core-ui'
import { GridSystemColumnRange } from '../../local-core-ui/grid/grid'
import { EmailVerification } from '../dashboard-component/dashboard-component'
import { PieChart as PieChartCustom } from '../pie-chart/pie-chart'
import styles from './email-verification-overview.module.scss'
import { ProgressChartsEV } from './progressChartsEV/progressChartsEV'

export interface emailCategoryEV {
	emailCategoryList: Array<EmailVerification>
	total: number
}

interface EmailVerificationOverviewProps {
	colors: Array<string>
	emailCategoryData?: emailCategoryEV
	emailQualityList?: Array<EmailVerification>
	emailTypeList?: Array<EmailVerification>
	showStateZero?: boolean
	testId: string
}

export const EmailVerificationOverview: FC<EmailVerificationOverviewProps> = ({
	colors,
	emailCategoryData,
	emailQualityList,
	emailTypeList,
	showStateZero,
	testId
}: EmailVerificationOverviewProps) => {
	const { t } = useTranslation()
	const totalColumns = 12
	const haveDataCategory = (emailCategoryData && emailCategoryData.emailCategoryList?.length > 0) || false
	const haveDataQuality = (emailQualityList && emailQualityList?.length > 0) || false
	const haveDataType = (emailTypeList && emailTypeList?.length > 0) || false
	const counterGraphs = () => {
		let acc = 0
		if (haveDataQuality) acc++
		if (haveDataType) acc++
		if (haveDataCategory) acc++
		return acc
	}
	const sizeColumns = (showStateZero ? 4 : totalColumns / counterGraphs()) as GridSystemColumnRange

	const mockCategoryReport = {
		emailCategoryList: [
			{ count: 21850, description: 'Role-based', rate: 25 },
			{ count: 21850, description: 'FreeMail', rate: 25 },
			{ count: 21850, description: 'Disposable', rate: 25 }
		],
		total: 87400
	}
	const mockQualityReport: Array<EmailVerification> = [
		{ count: 8, description: 'Excellent', rate: 8, total: 8 },
		{ count: 10, description: 'Good', rate: 10, total: 10 },
		{ count: 8, description: 'Fair', rate: 8, total: 8 },
		{ count: 26, description: 'Poor', rate: 26, total: 26 },
		{ count: 21, description: 'VeryPoor', rate: 21, total: 21 },
		{ count: 19, description: 'EmailThreatRisk', rate: 19, total: 19 },
		{ count: 8, description: 'Indeterminate(unknown)', rate: 8, total: 8 }
	]

	const mockTypeReport: Array<EmailVerification> = [
		{ count: 51, description: 'Business', rate: 51, total: 51 },
		{ count: 26, description: 'Consumer', rate: 26, total: 26 },
		{ count: 23, description: 'Unknown', rate: 23, total: 23 }
	]
	const getData = (list: Array<EmailVerification>) =>
		list.map((category: EmailVerification) => {
			const label =
				category.description !== undefined ? t('label.graph.' + category.description.replaceAll(' ', '')) : ''
			return {
				label: label,
				value: category.count,
				rate: category.rate
			}
		})

	const getChartColors = (list: Array<EmailVerification>) =>
		list.reduce((data, verification, currentIndex) => {
			const label =
				verification.description !== undefined
					? t('label.graph.' + verification.description.replaceAll(' ', ''))
					: ''
			return {
				...data,
				[label]: colors[currentIndex]
			}
		}, {})

	return (
		<div className={styles.emailVerificationOverview} data-testid={testId + 'ev-overview'}>
			<h1 data-testid="title-section-ev">{t('dashboard.emailVerificationOverview.title')}</h1>
			<div className={styles.divisorTop}></div>
			{showStateZero && (
				<p>
					<Trans i18nKey="text.instruction.EV">
						Click
						<a
							rel={'noreferrer'}
							className={styles.linkText}
							href={'https://view.highspot.com/viewer/64492d9508166b2a5f1314e0'}
							download
							data-testid={testId + '-sample-link'}
							target={'_blank'}
						/>
					</Trans>
				</p>
			)}
			<div className={showStateZero ? styles.containerGraphsZeroState : ''}>
				<Grid testId="container-email-verification-overview" container>
					{(haveDataCategory || showStateZero) && (
						<Grid testId="title-subtitle-email-category" md={4} sm={4}>
							<div className={styles.header}>
								<div className={styles.title}>
									{t('dashboard.emailVerificationOverview.emailCategoryGraph')}
								</div>
							</div>
						</Grid>
					)}
					{(haveDataQuality || showStateZero) && (
						<Grid testId="title-subtitle-email-deliverability" md={sizeColumns} sm={sizeColumns}>
							<div className={styles.header}>
								<div className={styles.title}>
									{t('dashboard.emailVerificationOverview.emailDeliverabilityGraph')}
								</div>
							</div>
						</Grid>
					)}
					{(haveDataType || showStateZero) && (
						<Grid testId="title-subtitle-email-type" md={sizeColumns} sm={sizeColumns}>
							<div className={styles.header}>
								<div className={styles.title}>
									{t('dashboard.emailVerificationOverview.emailTypeGraph')}
								</div>
							</div>
						</Grid>
					)}
					{((!showStateZero && haveDataCategory) || showStateZero) && (
						<Grid testId="progress-chart-email-category" md={sizeColumns} sm={sizeColumns}>
							<div className={`${styles.withMarginToProgressMeter}`}>
								<ProgressChartsEV
									data={
										showStateZero
											? mockCategoryReport
											: emailCategoryData || {
													emailCategoryList: [],
													total: 0
											  }
									}
									containerBarColor={colors[0]}
									barColor={'ColorGrayLighter'}
									textTotal={t('dashboard.total')}
									testId={testId + '-progressChartsEV'}
								/>
							</div>
						</Grid>
					)}
					{((!showStateZero && haveDataQuality) || showStateZero) && (
						<Grid testId="pie-chart-email-deliverability" md={sizeColumns} sm={sizeColumns}>
							<div className={`${styles.withMargin}`}>
								<PieChartCustom
									chartColors={getChartColors(
										showStateZero ? mockQualityReport : emailQualityList || []
									)}
									data={getData(showStateZero ? mockQualityReport : emailQualityList || [])}
									testId={testId + 'email-deliverability'}
								/>
							</div>
						</Grid>
					)}
					{((!showStateZero && haveDataType) || showStateZero) && (
						<Grid testId="pie-chart-email-type" md={sizeColumns} sm={sizeColumns}>
							<div className={`${styles.withMargin}`}>
								<PieChartCustom
									chartColors={getChartColors(showStateZero ? mockTypeReport : emailTypeList || [])}
									data={getData(showStateZero ? mockTypeReport : emailTypeList || [])}
									testId={testId + 'email-type'}
								/>
							</div>
						</Grid>
					)}
				</Grid>
			</div>
		</div>
	)
}
