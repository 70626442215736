import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './match-summary.module.scss'

interface IMatchSummaryInfoRuleProps {
	nameRule: string
	confidenceCode: number
	geographicalDistribution: string
	match: { matchType: string; matching: string }
	inclusionCriteria: string
	color: string
	excludeRules?: Array<{ nameRule: string; color: string }>
	testId: string
}

export function MatchSummaryInfoRule({
	nameRule,
	confidenceCode,
	geographicalDistribution,
	match,
	inclusionCriteria,
	color,
	excludeRules,
	testId
}: IMatchSummaryInfoRuleProps): ReactElement {
	const { t } = useTranslation()

	return (
		<div
			data-testid="match-summary-info-rule"
			className={styles.matchSummaryInfoRule}
			id={`match-summary-${testId}`}
		>
			<div data-testid="name-rule-summary-general" className={styles.nameRule} style={{ backgroundColor: color }}>
				{nameRule}
			</div>
			<div data-testid="container-data-rule" className={styles.containerDataRule}>
				<div data-testid="data-rule-single" className={styles.dataRule}>
					<span data-testid="label-data-cc" className={`${styles.labelData} ${styles.labelRegular}`}>
						{t('card.compare.record.confidence.code')}
					</span>
					<div data-testid="confidence-number" className={`${styles.circleNumber} ${styles.labelBold}`}>
						{confidenceCode}
					</div>
				</div>
				<div data-testid="data-rule-space-top" className={`${styles.dataRule} ${styles.spaceTop}`}>
					<span data-testid="label-data-countries" className={`${styles.labelData} ${styles.labelRegular}`}>
						{t('dashboard.geographical.distribution')}
					</span>
					<div data-testid="geographical-container" className={styles.geographicalContainer}>
						<span
							data-testid="label-bold-geo-distribution"
							className={`${styles.labelBold} ${excludeRules === undefined ? styles.overflow : ''}`}
						>
							{geographicalDistribution}
						</span>
						{excludeRules !== undefined ? (
							<>
								{excludeRules.length > 0 && (
									<span data-testid="label-summary-match-text" className={styles.labelItalic}>
										&nbsp;{t('matching.step.summary.text.minus')}&nbsp;
									</span>
								)}
								{excludeRules?.map((rule, idx) => {
									return (
										<div
											data-testid="exclude-rule-container"
											key={`${testId}-exclude-rule-container-${idx}`}
										>
											<span
												className={styles.labelBold}
												style={{ color: rule.color }}
												key={`${testId}-exclude-rule-${idx}`}
												data-testid="rule-container-summary"
											>
												{rule.nameRule}
											</span>
											{idx + 1 < excludeRules?.length ? ',' : ''}
											&nbsp;
										</div>
									)
								})}
							</>
						) : null}
					</div>
				</div>
				{match.matching !== '' && (
					<div data-testid="data-rule-match-strings" className={`${styles.dataRule} ${styles.spaceTop}`}>
						<div data-testid="label-regular-matching" className={`${styles.labelRegular} ${styles.match}`}>
							<span
								data-testid="matching-label-italic"
								className={`${styles.labelItalic} ${styles.matchType}`}
							>
								{match.matchType}
							</span>
							<span>&nbsp;{t('matching.step.tab.matching')}</span>
						</div>
						<span
							data-testid="matching-strings-overflow-value"
							className={`${styles.labelBold} ${styles.overflow}`}
						>
							{match.matching}
						</span>
					</div>
				)}
				<div data-testid="data-rule-inclusion" className={`${styles.dataRule} ${styles.spaceTop}`}>
					<span data-testid="label-inclusion" className={`${styles.labelData} ${styles.labelRegular}`}>
						{t('inclusion.criteria')}
					</span>
					<span data-testid="inclusion-value" className={`${styles.labelBold} ${styles.overflow}`}>
						{inclusionCriteria !== '' ? inclusionCriteria : t('match.rule.summary.tile.inclusion.empty')}
					</span>
				</div>
			</div>
		</div>
	)
}
