import { ReactElement } from 'react'
import styles from './elements-per-page.module.scss'

interface ElementProps {
	value: number
	onSelected(value: number): void
	isSelected?: boolean
	isDisabled?: boolean
	testId: string
}

export const ElementsPerPageOption = ({
	value,
	onSelected,
	isSelected,
	isDisabled,
	testId
}: ElementProps): ReactElement => {
	return (
		<span
			tabIndex={0}
			role="option"
			className={`${styles.elementPerPage} ${isDisabled ? styles.disabled : isSelected ? styles.selected : ''}`}
			onClick={() => {
				if (!isDisabled) {
					onSelected(value)
				}
			}}
			onKeyDown={(e) => {
				if (e.key === 'Enter' && !isDisabled) {
					onSelected(value)
				}
			}}
			data-testid={testId}
		>
			{value}
		</span>
	)
}
