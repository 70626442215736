import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { saveAs } from 'file-saver'
import { GetUploadTokenResponse, UploadDetails } from '../../types'

type setIsDownloading = (state: boolean) => void
type setErrorMessage = (error: string) => void

type startDownloadProcess = (
	dateTile: number | undefined,
	isApiBased: boolean,
	sourceId: string,
	files: string,
	setIsDownloading: setIsDownloading,
	uploadId: string,
	apiClient: AxiosInstance,
	genericErrorMessage: string,
	setErrorMessage: setErrorMessage
) => void

export const startDownloadProcess: startDownloadProcess = (
	dateTile,
	isApiBased,
	sourceId,
	files,
	setIsDownloading,
	uploadId,
	apiClient,
	genericErrorMessage,
	setErrorMessage
) => {
	setIsDownloading(true)
	if (isApiBased)
		getError(apiClient, setIsDownloading, uploadId, sourceId, files, dateTile, genericErrorMessage, setErrorMessage)
	else getTokenResponse(apiClient, setIsDownloading, uploadId, files, genericErrorMessage, setErrorMessage)
}

type getFiles = (
	tokenResponse: AxiosResponse<GetUploadTokenResponse>,
	setIsDownloading: setIsDownloading,
	uploadId: string,
	apiClient: AxiosInstance,
	genericErrorMessage: string,
	setErrorMessage: setErrorMessage
) => void

const getFiles: getFiles = (
	tokenResponse,
	setIsDownloading,
	uploadId,
	apiClient,
	genericErrorMessage,
	setErrorMessage
) => {
	const url = `/pls/filedownloads/${tokenResponse.data}`
	apiClient({ method: 'get', url, responseType: 'blob' })
		.then((response: AxiosResponse<BlobPart>) => {
			download(response.data, uploadId)
			setIsDownloading(false)
		})
		.catch((err: AxiosError) => {
			const error = err.message || genericErrorMessage
			setIsDownloading(false)
			setErrorMessage(error)
		})
}

const getTokenResponse = (
	apiClient: AxiosInstance,
	setIsDownloading: setIsDownloading,
	uploadId: string,
	files: string,
	genericErrorMessage: string,
	setErrorMessage: setErrorMessage
) => {
	const url = `/pls/uploads/uploadId/${uploadId}/token?files=${files}`
	apiClient
		.get(url)
		.then((tokenResponse: AxiosResponse<GetUploadTokenResponse>) => {
			getFiles(tokenResponse, setIsDownloading, uploadId, apiClient, genericErrorMessage, setErrorMessage)
		})
		.catch((err: AxiosError) => {
			const error = err.message || genericErrorMessage
			setIsDownloading(false)
			setErrorMessage(error)
		})
}

const replaceNameFile = (files: string): string => {
	const replacements: { [key: string]: string } = {
		IMPORT_ERRORS: 'INGESTION',
		PUBLISH_ERRORS: 'PUBLISHING',
		INGESTION: 'TXN_API_INGEST_ERRORS',
		PUBLISHING: 'TXN_API_PUBLISH_ERRORS'
	}

	const regex = new RegExp(Object.keys(replacements).join('|'), 'g')

	const result = files.replace(regex, (match) => replacements[match])
	return result
}

const getError = (
	apiClient: AxiosInstance,
	setIsDownloading: setIsDownloading,
	uploadId: string,
	sourceId: string,
	files: string,
	dateTile: number | undefined,
	genericErrorMessage: string,
	setErrorMessage: setErrorMessage
) => {
	let rangeDate = ''

	if (dateTile !== undefined) {
		const dates = new Date(dateTile)

		const year = dates.getUTCFullYear()
		const month = (dates.getUTCMonth() + 1).toString().padStart(2, '0')
		const day = '01'

		const firstDayOfMonth = `${year}${month}${day}`

		const nextMonth = month === '12' ? '01' : (parseInt(month) + 1).toString().padStart(2, '0')
		const nextYear = month === '12' ? (year + 1).toString() : year.toString()
		const firstDayOfNextMonth = `${nextYear}${nextMonth}${day}`

		rangeDate = `&start=${firstDayOfMonth}&end=${firstDayOfNextMonth}`
	}

	const fileMatchError = replaceNameFile(files)
	const url = `/pls/transactional-match/matchErrors/${sourceId}?types=${fileMatchError}${rangeDate}&uploadId=${uploadId}`
	apiClient({ method: 'get', url })
		.then((response: AxiosResponse<UploadDetails>) => {
			getTokenResponse(
				apiClient,
				setIsDownloading,
				uploadId,
				replaceNameFile(fileMatchError),
				genericErrorMessage,
				setErrorMessage
			)
		})
		.catch((err: AxiosError) => {
			const error = err.message || genericErrorMessage
			setIsDownloading(false)
			setErrorMessage(error)
		})
}

const download = (response: BlobPart, id: string) => {
	const blobObject = new Blob([response])

	saveAs(blobObject, `${id}.zip`)
}
