import { FC, useEffect, useState } from 'react'
import { Button, Input, Modal } from '../../local-core-ui/'
import styles from './renaming-modal.module.scss'

interface IRenamingModalProps {
	open?: boolean
	label: string
	hint: string
	value?: string
	actionLabel: string
	onRename(newNameValue: string): void
	testId: string
}

export const RenamingModal: FC<IRenamingModalProps> = ({
	open,
	label,
	hint,
	value,
	actionLabel,
	onRename,
	testId
}: IRenamingModalProps) => {
	const [newName, setNewName] = useState<string>(value ? value : '')

	useEffect(() => {
		if (open === false) {
			setNewName('')
		}
	}, [open])

	return (
		<div className={styles.renamingModal} data-testid="renaming-modal-container">
			<Modal open={open} isContainer={true} onClose={() => onRename('')} testId="RenamingModal">
				<div className={styles.container}>
					<div className={styles.inputWrapper}>
						<Input
							id={'renaming-modal-id'}
							label={label}
							hint={hint}
							size={'medium'}
							onChangeFunction={(inputValue) => setNewName(inputValue)}
							value={newName}
							maxLength={30}
							testId={testId + '-name'}
						/>
					</div>
					<div className={styles.buttonWrapper}>
						<Button
							size={'medium'}
							text={actionLabel}
							onClick={() => {
								onRename(newName)
							}}
							testId={testId + '-action'}
						/>
					</div>
				</div>
			</Modal>
		</div>
	)
}
