import { MatchRuleInfo } from '../../../../store/projectWizard/types'

export const getInclusionCriteria = (rule: MatchRuleInfo) => {
	const inclusionCriteriaKeys = ['NonHeadQuarters', 'OutOfBusiness', 'Unreachable', 'NonMarketable', 'Undeliverable']
	return arrayDifference(inclusionCriteriaKeys, rule.matchRule.exclusionCriterion)?.map((includeKey) => {
		return `match.rule.summary.tile.${includeKey}`
	})
}

const arrayDifference = (array1: string[], array2?: string[]) => {
	let difference: string[] = array1
	if (array2) {
		difference = array1.filter((key: string) => !array2.includes(key))
	}
	return difference
}
