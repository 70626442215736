import { DNBThemeProvider } from '@dnb-uux-design-system/react'
import { StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { App } from './App'
import './assets/styles/main.scss'
import './i18n'
import { LoadingState } from './local-core-ui'
import * as serviceWorker from './serviceWorker'
import './setupGainsight'
import './setupLogRocket'
import { store } from './store'

const persistor = persistStore(store)
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
			staleTime: 30 * 1000
		}
	}
})

const container = document.getElementById('root')
/**
 * `createRoot` always needs to receive a non-null parameter
 */
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(
	<Suspense fallback={<LoadingState />}>
		<StrictMode>
			<DNBThemeProvider>
				<QueryClientProvider client={queryClient}>
					<Provider store={store}>
						<PersistGate loading={null} persistor={persistor}>
							<App />
						</PersistGate>
					</Provider>
				</QueryClientProvider>
			</DNBThemeProvider>
		</StrictMode>
	</Suspense>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
