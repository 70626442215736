import { ColorGrayDarker } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ChangeEvent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { numberDelimeters } from '../../helpers'
import { CheckBox, Dropdown, DropdownOptions, Icon, ToggleSwitch } from '../../local-core-ui'
import { RootState, useAppSelector } from '../../store'
import { PillButton } from '../pill-button/pill-button'
import styles from './company-list-manager.module.scss'

export type recordSortBy = 'nameAsc' | 'nameDesc' | 'confidenceCodeHigh' | 'confidenceCodeLow'

interface CompanyListToolBarProps {
	isMultiSelectActive: boolean
	onToggleMultiSelect(): void
	currentSorting?: recordSortBy
	onSortChange(sortBy: recordSortBy): void
	isInsightsOn: boolean
	onInsightsChange(event: ChangeEvent<HTMLInputElement>): void
	isSelectAllChecked: boolean
	onSelectAllChange(event: ChangeEvent<HTMLInputElement>): void
}

export const CompanyListToolBar = ({
	isMultiSelectActive,
	onToggleMultiSelect,
	currentSorting = 'nameAsc',
	onSortChange,
	isInsightsOn,
	onInsightsChange,
	isSelectAllChecked,
	onSelectAllChange
}: CompanyListToolBarProps): ReactElement => {
	const { t } = useTranslation()
	const selectSteward = (state: RootState) => state.steward
	const steward = useAppSelector(selectSteward)

	const sortingOptions: DropdownOptions[] = [
		{ label: t('company.list.manager.sort.name.asc'), value: 'nameAsc' },
		{ label: t('company.list.manager.sort.name.desc'), value: 'nameDesc' },
		{ label: t('company.list.manager.sort.confidence.code.high'), value: 'confidenceCodeHigh' },
		{ label: t('company.list.manager.sort.confidence.code.low'), value: 'confidenceCodeLow' }
	]

	return (
		<div className={styles.companyListManagerToolBar}>
			<div className={styles.companyListManagerRecordsCount}>
				<p className={styles.companyListManagerNumberOfRecords}>
					<span className={styles.companyListManagerFilteredRecords}>{numberDelimeters(steward.total)}</span>
					{t('company.list.manager.number.of.records')}
				</p>
				<div className={styles.companyListManagerSortingContainer}>
					<Dropdown
						id="company-list-manager-dropdown"
						label=""
						hint=""
						onChangeFunction={onSortChange}
						options={sortingOptions}
						selected={currentSorting}
						dropdownContainerClassName={styles.companyListManagerSortingDropdown}
						dropdownListClassName={styles.companyListManagerSortingDropdownList}
						testId="company-list-manager-dropdown"
						dropdownOverflow="show"
					/>
				</div>
			</div>
			<div className={styles.companyListManagerToolsContainer}>
				{isMultiSelectActive ? (
					<div className={styles.multiSelectActive}>
						<CheckBox
							id="company-list-manager-select-all"
							label={t('company.list.manager.select.all')}
							checked={isSelectAllChecked}
							testId="company-list-manager-select-all"
							onChange={onSelectAllChange}
						/>
						<button
							className={styles.footerCancelBtn}
							onClick={() => onToggleMultiSelect()}
							data-testid="footer-btn-cancel"
							disabled={isMultiSelectActive === undefined}
						>
							<Icon testId="X-company-list-bar" type="x" size="medium" color={ColorGrayDarker} />
							{t('stewardship.exit.multiselect')}
						</button>
					</div>
				) : (
					<PillButton
						text={t('company.list.manager.multi.select')}
						onClick={() => onToggleMultiSelect()}
						testId="company-list-manager-multi-select"
						short
						type="secondary"
					/>
				)}
				<div className={styles.companyListManagerToolContainer}>
					<ToggleSwitch
						id="company-list-manager-toggle-details"
						checked={isInsightsOn}
						onChange={onInsightsChange}
						label={t('company.list.manager.details')}
						testId="company-list-manager-toggle-details"
					/>
				</div>
			</div>
		</div>
	)
}
