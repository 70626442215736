import * as d3 from 'd3'
import { ColorWhite } from '../shared/token-colors.json'
import { position } from './progress-bar'
import styles from './progress-bar.module.scss'

interface ProgressBarSvgBuilder {
	(
		progressBarSVG: d3.Selection<SVGElement>,
		id: string,
		total: number,
		value: number,
		percentage: number,
		borderRadius: number,
		svgWidth: number,
		svgHeight: number,
		position: position,
		displayPercentage: boolean,
		progressBarColor: string,
		progressColor: string,
		showLineProgress: boolean
	): void
}

export const progressBarSVGBuilder: ProgressBarSvgBuilder = (
	progressBarSVG,
	id,
	total,
	value,
	percentage,
	borderRadius,
	svgWidth,
	svgHeight,
	position,
	displayPercentage,
	progressBarColor,
	progressColor,
	showLineProgress
): void => {
	const data = [
		{ id: 'Total', value: total },
		{ id: 'Progress', value: percentage }
	]

	progressBarSVG.selectAll('g').remove()

	progressBarSVG
		.append('g')
		.attr('transform', position === 'vertical' ? `translate(0, ${svgHeight})` : '')
		.selectAll('path')
		.data(data)
		.enter()
		.append('path')
		.attr('d', (el) => {
			const widthBar: number =
				el.id === 'Total' ? svgWidth : position === 'horizontal' ? svgWidth * (percentage / 100) : svgWidth
			const heightBar: number =
				el.id === 'Total' ? svgHeight : position === 'vertical' ? svgHeight * (percentage / 100) : svgHeight
			return barBuilder(widthBar, heightBar, borderRadius, position)
		})
		.attr('id', (el) => {
			return el.id === 'Total' ? `progress-bar-${id}` : `progress-${id}`
		})
		.attr('class', (el) => {
			return el.id === 'Total' ? styles.progressBar : styles.progress
		})
		.style('fill', (el) => {
			return el.id === 'Total' ? progressBarColor : progressColor
		})

	const { translateText } = percentageTextBuilder(
		percentage,
		position === 'horizontal' ? svgWidth : svgHeight,
		position,
		progressColor
	)
	const textTransform =
		position === 'horizontal' ? `translate(${translateText}, 0)` : `translate(0, ${translateText})`
	const formatNumber = (number: number): string => {
		return number === 0 ? '0' : new Intl.NumberFormat().format(number)
	}

	if (showLineProgress) {
		progressBarSVG
			.append('g')
			.append('line')
			.style('stroke', progressColor !== undefined ? progressColor : '#73af55')
			.style('stroke-width', 1)
			.attr('x1', svgWidth * (percentage / 100) - 0.5)
			.attr('y1', 0)
			.attr('x2', svgWidth * (percentage / 100) - 0.5)
			.attr('y2', 5)
			.attr('transform', position === 'vertical' ? `translate(0, ${svgHeight})` : `translate(0, ${svgHeight})`)

		progressBarSVG
			.append('g')
			.attr(
				'transform',
				position === 'vertical' ? `translate(0, ${svgHeight})` : `translate(0, ${svgHeight + 15})`
			)
			.append('text')
			.text(formatNumber(value))
			.attr('class', styles.percentageMeter)
			.attr('fill', '#5c5c5c')
			.attr('transform', textTransform)
	} else {
		progressBarSVG
			.select('g')
			.append('text')
			.text(percentage + '%')
			.attr('class', styles.percentage)
			.attr('id', `progress-bar-text-${id}`)
			.attr('x', position === 'horizontal' ? 0 : Math.round(svgWidth / 2))
			.attr('y', position === 'horizontal' ? Math.round(svgHeight / 2) : 0)
			.attr('dy', position === 'horizontal' ? '.35em' : '')
			.attr('text-anchor', position === 'horizontal' ? 'right' : 'middle')
			.attr('transform', textTransform)
			.style('display', displayPercentage ? '' : 'none')
	}
}

export const barBuilder = (width: number, height: number, radius: number, position: position): string => {
	if (position === 'horizontal') {
		const topLine = `L${width - radius},0`
		const topRightArc = `Q${width},0 ${width},${radius}`
		const rightLine = `L${width},${height - radius}`
		const bottomRightArc = `Q${width},${height} ${width - radius},${height}`
		const bottomLine = `L0,${height}`
		const leftLine = 'L0,0'
		return `M0, 0 ${topLine} ${topRightArc} ${rightLine} ${bottomRightArc} ${bottomLine} ${leftLine}`
	}
	if (position === 'vertical') {
		height = height * -1
		const leftLine = `L0,0`
		const bottomLine = `L${width},0`
		const rightLine = `L${width},${height + radius}`
		const topRightArc = `Q${width},${height} ${width - radius},${height}`
		const topLine = `L${radius},${height}`
		const topLeftArc = `Q0,${height} 0,${height + radius}`
		return `M0, 0 ${leftLine} ${bottomLine} ${rightLine} ${topRightArc} ${topLine} ${topLeftArc}`
	}
}

export const percentageTextBuilder = (
	percentage: number,
	sizeContainer: number,
	position: position,
	color?: string
): { percentageColor: string; translateText: number } => {
	const textWidth = 25
	const textHeight = 17
	const marginRight = 16
	const marginLeft = 5
	const marginBottom = 5
	const marginTop = 5
	let percentageColor = color ? color : ''
	let translateText: number =
		position === 'horizontal'
			? sizeContainer * (percentage / 100) + marginLeft
			: -(sizeContainer * (percentage / 100) + marginBottom)

	const progressSize: number = sizeContainer * (percentage / 100)
	if (
		(position === 'horizontal' && progressSize > textWidth + marginLeft) ||
		(position === 'vertical' && progressSize > textHeight + marginBottom)
	) {
		percentageColor = ColorWhite
		const textSize: number = position === 'horizontal' ? textWidth : textHeight
		const margin: number = position === 'horizontal' ? marginRight : marginTop
		const translate: number =
			sizeContainer * (percentage / 100) - textSize - margin > 3
				? sizeContainer * (percentage / 100) - textSize - margin
				: 3
		translateText = position === 'horizontal' ? translate : -translate
	}
	return { percentageColor, translateText }
}
