import { MapFieldDefinition } from '../../../types'
import { EntityType } from '../../../types/sources/EntityType'
import { AppThunk, TDispatch, TGetState } from '../../index'
import { updateCurrentProjectAction } from '../actions'

export const validateAdditionalCountry =
	(enableAlternateFieldsRecomm: boolean, typeSource: EntityType): AppThunk<Promise<unknown>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<unknown> => {
		if (enableAlternateFieldsRecomm && typeSource === EntityType.ACCOUNTS) {
			const companyInfo =
				getState().projectWizard.currentProject.mappingInfo.mapping.currentFieldDefinitionsRecord
					.fieldDefinitionsRecordsMap['companyInformation']
			const alternateAddressInfo = companyInfo
				.filter((row: MapFieldDefinition) => {
					return row.fieldName?.toLowerCase().includes('alternate') && row.columnName !== undefined
				})
				.filter((row) => {
					return !row.fieldName?.toLowerCase().includes('company')
				})
			const alternateCountryInfo = companyInfo
				.filter((row) => {
					return row.fieldName?.toLowerCase().includes('alternate') && row.columnName !== undefined
				})
				.filter((row) => {
					return row.fieldName?.toLowerCase().includes('country')
				})
			const requireAlternateCountry = alternateCountryInfo.length > 0 ? false : alternateAddressInfo.length > 0

			if (
				(requireAlternateCountry &&
					getState().projectWizard.currentProject.mappingInfo.continueWithoutAddCountry) ||
				!requireAlternateCountry
			) {
				dispatch(
					updateCurrentProjectAction({
						mappingInfo: {
							...getState().projectWizard.currentProject.mappingInfo,
							requireAdditionalCountry: requireAlternateCountry
						}
					})
				)
				return new Promise<void>((resolve) => resolve())
			} else {
				dispatch(
					updateCurrentProjectAction({
						mappingInfo: {
							...getState().projectWizard.currentProject.mappingInfo,
							requireAdditionalCountry: true
						}
					})
				)
				return new Promise<void>((resolve, reject) => reject())
			}
		} else {
			return new Promise<void>((resolve) => resolve())
		}
	}
