import { get } from 'lodash-es'
import { tokenObj } from './design-tokens'

export const convertToToken = (tokenString: string): string => {
	const toKabab = tokenString
		.replace(/([a-z0-9])([A-Z0-9])/g, '$1-$2')
		.replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
		.toLowerCase()
	return `$${toKabab}`
}

export const createTokenList = (tokens: unknown): Array<tokenObj> => {
	return Object.entries(get(tokens, 'default')).map((token) => {
		return {
			name: convertToToken(token[0]),
			token: token[0],
			sassVariable: convertToToken(token[0]),
			value: typeof token[1] === 'object' ? get(token[1], 'value') : token[1],
			alias: typeof token[1] === 'object' ? convertToToken(get(token[1], 'name')) : ''
		}
	})
}

export const getValueToken = (tokenList: Array<tokenObj>, key: string): string => {
	const foundColor = tokenList.find((colorToken) => {
		return colorToken.sassVariable === key || colorToken.token === key
	})?.value

	return foundColor || key
}
