import { Children, FC, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Icon } from '../../local-core-ui'
import styles from './scrollable-list.module.scss'

interface ScrollableListProps {
	testId: string
	setToStartOnChildrenChange?: boolean
}

export const ScrollableList: FC<PropsWithChildren<ScrollableListProps>> = ({
	children,
	testId,
	setToStartOnChildrenChange = false
}: PropsWithChildren<ScrollableListProps>) => {
	const body = useRef<HTMLDivElement>(null)
	const firstListElement = useRef<HTMLDivElement>(null)
	const childrenArray = Children.toArray(children)
	const [scrollPosition, setScrollPosition] = useState(0)
	const [scrollIndex, setScrollIndex] = useState(0)
	const [leftEnabled, setLeftEnabled] = useState(false)
	const [rightEnabled, setRightEnabled] = useState(true)

	const [scrollPoints, setScrollPoints] = useState<Array<number>>([0])
	useEffect(() => {
		const visibleWidth = body.current?.clientWidth || 0
		const fullWidth = body.current?.scrollWidth || 0
		const maxScrollPosition = fullWidth - visibleWidth + 34
		const arr: Array<number> = [0]
		body.current?.childNodes.forEach((child, index) => {
			const nextPosition = arr[index] + (child as HTMLElement).clientWidth + 34
			if (nextPosition <= maxScrollPosition) arr.push(nextPosition)
			else if (!arr.includes(maxScrollPosition)) arr.push(maxScrollPosition)
		})
		if (setToStartOnChildrenChange) setScrollIndex(0)
		setScrollPoints(arr)
		/**
		 * We only want to run this effect when the children changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [children])

	const scrollLeft = () => {
		const elementXPosition = (firstListElement.current?.getBoundingClientRect().x || 0) * -1
		const currentPosition = scrollPoints.find((point) => point > elementXPosition) || 0
		const visibleWidth = body.current?.clientWidth || 0
		const targetPoint = currentPosition - visibleWidth
		let indexToScrollTo = -1
		scrollPoints.forEach((point, index) => {
			if (point >= targetPoint && indexToScrollTo === -1) indexToScrollTo = index
		})
		if (indexToScrollTo === scrollIndex) indexToScrollTo--
		setScrollIndex(Math.max(0, indexToScrollTo))
	}

	const scrollRight = () => {
		const elementXPosition = (firstListElement.current?.getBoundingClientRect().x || 0) * -1
		const currentPosition = scrollPoints.find((point) => point > elementXPosition) || 0
		const visibleWidth = body.current?.clientWidth || 0
		const endPoint = currentPosition + visibleWidth
		const fullWidth = body.current?.scrollWidth || 0
		const maxScrollPosition = fullWidth - visibleWidth + 34
		let indexToScrollTo = 0
		scrollPoints.forEach((point, index) => {
			if (point <= endPoint) indexToScrollTo = index
		})
		if (indexToScrollTo === scrollIndex) indexToScrollTo++
		if (scrollPoints[indexToScrollTo] <= maxScrollPosition) setScrollIndex(indexToScrollTo)
	}

	useEffect(() => {
		setScrollPosition(scrollPoints[scrollIndex])
	}, [scrollIndex, scrollPoints])

	useEffect(() => {
		const visibleWidth = body.current?.clientWidth || 0
		const fullWidth = body.current?.scrollWidth || 0
		const maxScrollPosition = fullWidth - visibleWidth + 34
		setLeftEnabled(scrollPosition !== 0)
		setRightEnabled(scrollPosition !== maxScrollPosition)
		const scrollToOptions: ScrollToOptions = {
			left: scrollPosition,
			behavior: 'smooth'
		}
		if (body.current) body.current.scrollTo(scrollToOptions)
	}, [scrollPosition])

	body.current?.addEventListener(
		'wheel',
		function (e) {
			e.preventDefault()
			return false
		},
		{ passive: false }
	)

	return (
		<div className={styles.scrollableList}>
			<div
				className={`${styles.arrow} ${styles.leftArrow} ${leftEnabled ? '' : styles.disabled}`}
				tabIndex={0}
				onClick={scrollLeft}
				onKeyPress={(e) => {
					if (e.key === 'Enter' || e.key === ' ') scrollLeft()
				}}
				data-testid={testId + '-prev-button'}
			>
				<div className={styles.arrowCircle}>
					<Icon testId="chevron-left-scrollable-list" type="chevron-left" size="mini" />
				</div>
			</div>
			<div className={styles.slBody} ref={body}>
				<div ref={firstListElement} className={styles.firstListElement} />
				{childrenArray.map((child) => child)}
			</div>
			<div
				className={`${styles.arrow} ${styles.rightArrow} ${rightEnabled ? '' : styles.disabled}`}
				tabIndex={0}
				onClick={scrollRight}
				onKeyPress={(e) => {
					if (e.key === 'Enter' || e.key === ' ') scrollRight()
				}}
				data-testid={testId + '-next-button'}
			>
				<div className={styles.arrowCircle}>
					<Icon testId="chevron-right-scrollable-list" type="chevron-right" size="mini" />
				</div>
			</div>
		</div>
	)
}
