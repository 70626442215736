import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dependencies, version } from '../../package.json'
import { ExpandedSearchButtonNInput } from '../components/expanded-search-button-n-input/expanded-search-button-n-input'
import { useApi } from '../hooks'
import { useFeatures } from '../hooks/useEntitlements'
import { Button, Divider, Grid, Panel } from '../local-core-ui'
import { RootState, useAppSelector } from '../store'
import { Tenant } from '../types'
import styles from './about.module.scss'
import { usedFeatureFlags } from './usedFeatureFlags'

export interface AboutStackInfo {
	ArtifactVersion?: string
	CurrentStack?: string
	ClusterName?: string
	Branch?: string
	Commit?: string
}

export interface FeatureFlags {
	[key: string]: string | boolean
}

const FeatureFlagLabel = ({ name }: { name: string }): ReactElement => {
	const valueFlag = useFeatures([name])

	return (
		<li key={'flag-' + name}>
			<p>{`${name}:  ${valueFlag}`}</p>
		</li>
	)
}

const getSubscriberNumber = (tenantId: string, tenantList: Tenant[]): string | undefined => {
	const currentTenant = tenantList.find((tenant) => tenant.Identifier === tenantId)

	return currentTenant?.SubscriberNumber
}

export function About(): ReactElement {
	const selectSession = (state: RootState) => state.session
	const session = useAppSelector(selectSession)

	const { t } = useTranslation()
	const [showLibraries, setShowLibraries] = useState(false)
	const [stackInfoObj, setStackInfoObj] = useState<AboutStackInfo>({})
	const [usedFeatureFlagsList, setUsedFeatureFlagsList] = useState<FeatureFlags>({})
	const [unUsedFeatureFlagsList, setunUsedFeatureFlagsList] = useState<FeatureFlags>({})
	const [searchInputValue, setSearchInputValue] = useState('')
	const searchTermlength = 3
	const libraries = Object.keys(dependencies)
	const apiClient = useApi()

	const moduleFlags = session.moduleFlags

	const subscriberNumber = getSubscriberNumber(session.tenant || '', session.tenants)

	const goToDCPGit = () => {
		import.meta.env.VITE_GIT_COMMIT
			? window.open('https://github.com/dnb-main/lat-dcp-ui/commit/' + import.meta.env.VITE_GIT_COMMIT, '_blank')
			: window.open('https://github.com/dnb-main/lat-dcp-ui/commits/', '_blank')
	}

	const goToCommonGit = () => {
		window.open('https://github.com/dnb-main/lat-dnb-ui-common', '_blank')
	}

	const showLibrariesList = () => {
		setShowLibraries(!showLibraries)
	}

	const onChangeFilter = (filterValue: string) => {
		setSearchInputValue(filterValue)
	}

	const renderFeatureFlags = (key: string, index: number, flagValue: string, unUsed: boolean) => {
		const unUsedFlagIndicator = unUsed ? styles.unUsedFlag : ''
		if (
			searchInputValue.length < searchTermlength ||
			(searchInputValue.length >= searchTermlength && key.toLowerCase().includes(searchInputValue.toLowerCase()))
		) {
			return (
				<ul key={index} className={unUsedFlagIndicator}>
					<li key={key}>
						<p>
							<span className={unUsedFlagIndicator}>
								{key}: {flagValue}
							</span>
						</p>
					</li>
				</ul>
			)
		}
	}

	useEffect(() => {
		const stackInfoUrl = '/pls/health/about'
		apiClient
			.get(stackInfoUrl)
			.then((stackInfoResponse) => {
				setStackInfoObj(stackInfoResponse?.data)
			})
			.catch((e) => console.error(e))

		const tenantconfigUrl = `/pls/tenantconfig`
		apiClient
			.get(tenantconfigUrl)
			.then((tenantConfig) => {
				if (tenantConfig?.data?.FeatureFlags) {
					let featuresFlags = Object.fromEntries(
						Object.entries(tenantConfig?.data?.FeatureFlags)
							.filter(([flag]) => usedFeatureFlags.indexOf(flag) !== -1)
							.sort()
					)
					setUsedFeatureFlagsList(featuresFlags)
					featuresFlags = Object.fromEntries(
						Object.entries(tenantConfig?.data?.FeatureFlags)
							.filter(([flag]) => usedFeatureFlags.indexOf(flag) === -1)
							.sort()
					)
					setunUsedFeatureFlagsList(featuresFlags)
				}
			})
			.catch((e) => console.error(e))
	}, [])

	return (
		<div className={styles.aboutContainer}>
			<h1>{t('about.title')}</h1>
			<Grid testId="container-about" container gutter={2}>
				<Grid testId="about-info-dcp" md={6}>
					<Panel>
						<p>{t('about.ui.title')}</p>
						<Divider />
						<ul>
							<li>
								<p>
									{t('about.commit.hash')} &nbsp; <span>{import.meta.env.VITE_GIT_COMMIT}</span>
								</p>
							</li>
							<li>
								<p>
									{t('about.build.version')} &nbsp; <span>{version}</span>
								</p>
							</li>
							<li>
								<p>
									{t('about.node.version')} &nbsp; <span>{import.meta.env.VITE_NODE_VERSION}</span>
								</p>
							</li>
						</ul>
						<Button
							text={t('about.button.github')}
							onClick={goToDCPGit}
							type={'secondary'}
							testId="about-btn-dcp-github"
						/>
						<div className={styles.buttonMargin}>
							<Button
								text={t('about.button.libraries')}
								onClick={showLibrariesList}
								type={'secondary'}
								testId="about-btn-libraries"
							/>
						</div>
					</Panel>
				</Grid>
				{stackInfoObj && stackInfoObj?.CurrentStack ? (
					<Grid testId="stackinfo-about" md={12}>
						<Panel>
							<p>{t('about.stackInfo.title')}</p>
							<Divider />
							{Object.keys(stackInfoObj).map((key, index) => {
								return (
									<ul key={index}>
										<li key={key}>
											<p>
												{key}: {stackInfoObj[key as keyof typeof stackInfoObj]}
											</p>
										</li>
									</ul>
								)
							})}
						</Panel>
					</Grid>
				) : null}
				{subscriberNumber ? (
					<Grid testId="subscriber-number-about" md={12}>
						<Panel>
							<p>{t('about.subscriberNumber.title')}</p>
							<Divider />
							<ul>
								<li key="subscriber-number">
									<p>{subscriberNumber}</p>
								</li>
							</ul>
						</Panel>
					</Grid>
				) : null}
				<Grid testId="feature-flags-about" md={6}>
					<Panel>
						<p>{t('about.featureFlags.title')}</p>
						<Divider />
						<div className={styles.searchSection}>
							<ExpandedSearchButtonNInput
								value={searchInputValue}
								onChangeFilter={onChangeFilter}
								hint={t('about.featureFlag.searcher.keyword')}
								testId={'about-feature-flags-search'}
							/>
						</div>
						{Object.keys(usedFeatureFlagsList).map((key, index) => {
							return renderFeatureFlags(
								key,
								index,
								usedFeatureFlagsList[key as keyof typeof usedFeatureFlagsList].toString(),
								false
							)
						})}
						{Object.keys(unUsedFeatureFlagsList).map((key, index) => {
							return renderFeatureFlags(
								key,
								index,
								unUsedFeatureFlagsList[key as keyof typeof unUsedFeatureFlagsList].toString(),
								true
							)
						})}
						<p>{t('about.featureFlagUnused.note')}</p>
					</Panel>
				</Grid>
				<Grid testId="module-flags-about" md={6}>
					<Panel>
						<p>{t('about.moduleFlags.title')}</p>
						<Divider />
						<ul>
							{moduleFlags?.map((module) => (
								<li key={`module-${module}`}>
									<p>{`${module}`}</p>
								</li>
							))}
						</ul>
					</Panel>
				</Grid>
				{showLibraries && (
					<Grid testId="libraries-about" lg={6}>
						<Panel>
							<p>{t('about.libraries.title')}</p>
							<Divider />
							<ul>
								{libraries?.map((library, index) => (
									<li key={index}>
										<p>{library}</p>
									</li>
								))}
							</ul>
						</Panel>
					</Grid>
				)}
			</Grid>
		</div>
	)
}
