/**
 * @class Panel
 */

import { FC, PropsWithChildren } from 'react'
import styles from './panel.module.scss'

export const Panel: FC<PropsWithChildren<unknown>> = (props: PropsWithChildren<unknown>) => {
	return (
		<div data-testid="panel-container" className={styles.panel}>
			{props?.children}
		</div>
	)
}

export default Panel
