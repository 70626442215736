import { ReactElement, useEffect, useState } from 'react'
import { ElementsPerPageOption } from './elements-per-page-option'
import styles from './elements-per-page.module.scss'

interface ElementsPerPageProps {
	paginationOptions: number[]
	selectedElement: number
	onElementsPerPageChanged(selectedElement: number): void
	totalElementsCount?: number
	textLeft?: string
	textRight?: string
}

export const ElementsPerPage = ({
	paginationOptions,
	selectedElement,
	onElementsPerPageChanged,
	totalElementsCount,
	textLeft,
	textRight
}: ElementsPerPageProps): ReactElement => {
	const [selectedElementValue, setSelectedElementValue] = useState<number>(selectedElement)
	const elementsPerPageOptions = [...paginationOptions].sort((a, b) => a - b)

	useEffect(() => {
		if (totalElementsCount && totalElementsCount < selectedElement) {
			const nextAvailableElement = [...elementsPerPageOptions]
				.reverse()
				.find((element) => totalElementsCount > element)
			if (nextAvailableElement) setSelectedElementValue(nextAvailableElement)
		}
		/**
		 * We only want to run this effect when totalElementsCount prop changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totalElementsCount])

	useEffect(() => {
		onElementsPerPageChanged(selectedElementValue)
		/**
		 * We only want to run this effect when selectedElementValue state changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedElementValue])

	const isOptionDisabled = (element: number): boolean => {
		if (totalElementsCount !== undefined) {
			if (totalElementsCount > 0) {
				return totalElementsCount < element
			} else return true
		} else return false
	}

	return (
		<div className={styles.elementsPerPageContainer}>
			{textLeft && <span className={styles.text}>{textLeft}</span>}
			<div className={styles.paginationContainer}>
				{elementsPerPageOptions.map((element, index) => (
					<div key={element + '-elements-key'}>
						<ElementsPerPageOption
							value={element}
							onSelected={(value) => setSelectedElementValue(value)}
							isSelected={selectedElementValue === element}
							isDisabled={isOptionDisabled(element)}
							testId={`element-per-page-${element}`}
						/>
						{index !== paginationOptions.length - 1 && <span className={styles.separator}>&#124;</span>}
					</div>
				))}
			</div>
			{textRight && <span className={styles.text}>{textRight}</span>}
		</div>
	)
}
