import { DNBCheckbox } from '@dnb-uux-design-system/react'
import { t } from 'i18next'
import { ChangeEvent, ReactElement } from 'react'
import { AccordionPanel } from '../../local-core-ui'
import { DataBlockElementSelector } from '../data-block-element-selector/data-block-element-selector'
import { ElementUIFacade } from './ElementUIFacade'
import { isElementChecked, isElementDisabled } from './helpers/element-tree-helpers'
import { RangesAndFiguresChildrenTree } from './ranges-and-figures-children-tree'
import styles from './ranges-and-figures-children-tree.module.scss'

interface RangesandFiguresCheckboxSubelementsProps {
	indexChild: number
	blockChild: ElementUIFacade
	index: number
	element: ElementUIFacade
	testId: string
	selectedElementList: Array<ElementUIFacade>
	onChange: (e: ChangeEvent<HTMLInputElement>, option: ElementUIFacade) => void
	defaultElements?: Array<ElementUIFacade>
	showBlockInformation?: boolean
	mandatoryIdArray: Array<string>
	setMandatoryIdArray: (arr: Array<string>) => void
	errorBannerVisibility(mandatoryIdArray: Array<string>): void
}

export const getChildName = (blockChild: ElementUIFacade) => {
	let label = blockChild.displayName
	if (blockChild.dataTable) {
		label += `(${blockChild.dataTable?.length} ${t('text.columns')})`
	}
	return label
}

export const RangesandFiguresCheckboxSubelements = ({
	indexChild,
	blockChild,
	index,
	element,
	testId,
	selectedElementList,
	onChange,
	defaultElements,
	showBlockInformation,
	mandatoryIdArray,
	setMandatoryIdArray,
	errorBannerVisibility
}: RangesandFiguresCheckboxSubelementsProps): ReactElement => {
	const blockChildren = element.childs || []
	return (
		<div
			className={`${indexChild !== 0 && !blockChild.childs ? styles.containerItemSmall : styles.containerItem} ${
				indexChild === blockChildren.length - 1 && !blockChild.childs ? styles.lastChildContainerItem : ''
			}`}
			key={blockChild.elementId + 'subpanel-' + index}
			data-testid="container-item-tree"
		>
			<DNBCheckbox
				size="small"
				id={t(blockChild.elementId)}
				label={getChildName(blockChild)}
				checked={
					blockChild.isRequiredByDefault ||
					(blockChild.childs
						? isElementChecked(selectedElementList, blockChild.elementId, defaultElements, true, blockChild)
						: isElementChecked(selectedElementList, blockChild.elementId, defaultElements))
				}
				disabled={blockChild.isRequiredByDefault || isElementDisabled(blockChild.elementId)}
				data-testid={testId + '-dbes-cb-child' + index}
				onChange={(e) => onChange(e, blockChild)}
				error={
					mandatoryIdArray && mandatoryIdArray.length > 0
						? mandatoryIdArray.find((ele) => ele === blockChild?.parent?.displayName)
							? 'Selection missing'
							: ''
						: ''
				}
			/>

			{blockChild.hideElementDetail ? undefined : (
				<DataBlockElementSelector
					element={blockChild}
					dataBlockName={blockChild.blockId || ''}
					level={!blockChild.childs || !blockChild.showChildrenBlocks ? blockChild.level : undefined}
					dataTable={blockChild.dataTable}
					showBlockInformation={showBlockInformation && !!blockChild.dataTable}
				/>
			)}
			{blockChild.childs && blockChild.showChildrenBlocks ? (
				<AccordionPanel id={testId + '-accordion-panel' + index} isOpen={true} existControlElement={true}>
					<RangesAndFiguresChildrenTree
						index={indexChild}
						element={blockChild}
						testId={testId}
						selectedElementList={selectedElementList}
						onChange={onChange}
						defaultElements={defaultElements}
						showBlockInformation={showBlockInformation}
						mandatoryIdArray={mandatoryIdArray}
						setMandatoryIdArray={setMandatoryIdArray}
						errorBannerVisibility={errorBannerVisibility}
					/>
				</AccordionPanel>
			) : (
				<></>
			)}
		</div>
	)
}
