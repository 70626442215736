import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { useApi } from '../hooks'
import { UserDetail } from '../types'
import { updateUser } from './api/updateUser'

export const useUpdateUser = (): UseMutationResult<void, unknown, UserDetail, unknown> => {
	const apiClient = useApi()
	const queryClient = useQueryClient()

	const mutationFn = (user: UserDetail) => updateUser(apiClient, user)

	return useMutation(mutationFn, {
		onSuccess: () => {
			queryClient.invalidateQueries('getUsers')
		}
	})
}
