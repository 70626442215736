import { AppThunk, TDispatch, TGetState } from '../../index'
import { PageSize } from '../types'
import { getRecords } from './getRecords'

export const paginateOverRecords =
	(pageIndex: number, pageSize?: PageSize): AppThunk =>
	async (dispatch: TDispatch, getState: TGetState): Promise<void> => {
		const state = getState()
		const desiredPageSize = pageSize ? pageSize : state.steward.pageSize
		dispatch(
			getRecords(
				state.steward.forTile,
				pageIndex,
				desiredPageSize,
				state.steward.requestedClassification,
				state.steward.forAssignee,
				state.steward.forAssignment
			)
		)
	}
