import { Element, ElementDetail, ElementsToUpdate } from '../types'

export const mapReplacements = (
	dataBlockList: Array<ElementDetail>,
	updatableElems: Record<string, ElementsToUpdate>
) => {
	Object.entries(updatableElems).map((entrie) => {
		const blockData = dataBlockList.filter((datablock) => datablock.blockId === entrie[0])[0]
		let elems: any[] = []
		blockData.levels.forEach((level) => {
			elems = elems.concat(level.elements)
		})
		entrie[1].elements.forEach((elemSelect: { original: Element; replacements: Array<Element> }) => {
			elemSelect.original.replacements?.forEach((elemId: string) => {
				const info = elems.filter((elem) => elem.elementId === elemId)
				if (info.length) {
					elemSelect.replacements.push(info[0])
				}
			})
		})
	})
}
