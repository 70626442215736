import { RowDef } from '../../../../components/report-table/report-table'
import { GeoDistributionList } from '../../types/GeoDistributionList'
import { getCountryTranslation } from './getCountryTranslation'
import { getGeoDataWithPercents } from './getGeoDataWithPercents'

const getGeoTableData = (geoDistributionList: GeoDistributionList): RowDef[] => {
	const geoData = getGeoDataWithPercents(geoDistributionList)

	return geoData
		.sort((a, b) => b.percent - a.percent)
		.map(({ countryCode, matched, percent }) => [
			getCountryTranslation(countryCode),
			new Intl.NumberFormat().format(matched),
			percent > 0.001 || percent === 0 ? (Math.floor(percent * 1000) / 10).toLocaleString() + '%' : '<0.1'
		])
}

export { getGeoTableData }
