import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { useApi } from '../hooks'
import { RefreshRematchConfig } from '../types'
import { setRefreshRematch } from './api/setRefreshRematch'
const refreshRematchDefault: RefreshRematchConfig = {
	refresh: false,
	rematch: false,
	enabled: false,
	nextUpdate: ''
}

export const useSetSourceRefreshRematch = (
	sourceId: string
): UseMutationResult<void, unknown, RefreshRematchConfig | undefined, unknown> => {
	const apiClient = useApi()
	const queryClient = useQueryClient()

	const mutationFn = (refreshRematch: RefreshRematchConfig = refreshRematchDefault) =>
		setRefreshRematch(apiClient, sourceId, refreshRematch)

	return useMutation(mutationFn, {
		onSuccess: () => {
			queryClient.invalidateQueries(['getSources', { sourceId, inflateMetadata: true }])
		}
	})
}
