import { ColorGrayDark, ColorJetBlack, ColorWhite } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { FocusEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import spinnerGif from '../../assets/images/spinner.gif'
import { numberDelimeters } from '../../helpers'
import { ContextMenu, ContextMenuOption, Icon } from '../../local-core-ui'
import { RootState, useAppSelector } from '../../store'
import { AssignmentTileContainer } from '../assignment-tile-container/assignment-tile-container'
import { AssignedToWithName } from '../assignments-manager/assignments-manager-list'
import { AssignmentsToDetail } from './assignments-to-detail'
import styles from './assignments-to-tile.module.scss'

interface AssignmentsToTileProps {
	idx: number
	assigneeSummary: AssignedToWithName
	onHideDashboardClick(): void
	onReassignClick(idx: number): void
	onDownloadRecordsClick(idx: number): void
	onThreeCardsClick(idx: number): void
	showDetail: boolean
	isSelected: boolean
	onFocus(event: FocusEvent<HTMLDivElement>): void
	isGettingAssignments: boolean
}

export const AssignmentsToTile = ({
	idx,
	assigneeSummary,
	onHideDashboardClick,
	onReassignClick,
	onDownloadRecordsClick,
	onThreeCardsClick,
	showDetail,
	isSelected,
	onFocus,
	isGettingAssignments
}: AssignmentsToTileProps): ReactElement => {
	const { t } = useTranslation()
	const { assignee, assigneeName, totalAssignments } = assigneeSummary

	const selectSession = (state: RootState) => state.session
	const sessionState = useAppSelector(selectSession)
	const currentUser = sessionState.user?.EmailAddress

	const isCurrentUser = currentUser === assignee

	const me = t('assignments.to.tile.me')
	const user = isCurrentUser ? me : assigneeName
	const owner = isSelected ? t('assignments.to.tile.header', { assignedTo: user }) : user
	const contextMenuOptions: ContextMenuOption[] = [
		{
			label: t(`assignments.to.tile.${showDetail ? 'hide' : 'show'}.dashboard`),
			action: 'hideDashboard'
		},
		{
			label: t('assignments.to.tile.reassign'),
			action: 'reassign'
		},
		{
			label: t('assignments.to.tile.download.records'),
			action: 'downloadRecords'
		}
	]
	const [multiCardsColor, setMultiCardsColor] = useState<string>(ColorJetBlack)
	const [contextMenuColor, setContextMenuColor] = useState<string>(ColorGrayDark)

	const handleContextMenuClicked = (option: string) => {
		switch (option) {
			case 'hideDashboard':
				onHideDashboardClick()
				break
			case 'reassign':
				onReassignClick(idx)
				break
			case 'downloadRecords':
				onDownloadRecordsClick(idx)
				break
		}
	}

	useEffect(() => {
		setMultiCardsColor(isSelected ? ColorWhite : ColorJetBlack)
		setContextMenuColor(isSelected ? ColorWhite : ColorGrayDark)
	}, [isSelected])

	return (
		<AssignmentTileContainer
			isSelected={isSelected}
			showDetail={showDetail}
			testId={`assigned-to-${assignee}-tile`}
			onFocus={onFocus}
		>
			<div
				className={`${styles.assignmentsToTileContainer} ${showDetail ? '' : styles.assignmentsToTileSummary}`}
			>
				<div className={styles.assignmentsToTileHeader}>
					<h4 className={styles.assignmentsToTileTitle} title={owner}>
						{owner}
					</h4>
					<div className={styles.assignmentsToTileIconsContainer}>
						{isGettingAssignments ? (
							<div className={styles.assignmentsToTileLoaderContainer}>
								<img src={spinnerGif} alt="loading" />
							</div>
						) : (
							<button
								data-testid={`assigned-to-${assignee}-multi-cards`}
								className={styles.assignmentsToTileIconContainer}
								onClick={() => onThreeCardsClick(idx)}
							>
								<Icon
									testId="multi-solid-assignments-to-tile"
									type="multi-solid"
									color={multiCardsColor}
								/>
								<span
									className={`${styles.assignmentsToTileAssignmentsCount} ${
										totalAssignments > 99
											? styles.assignmentsToTileThreeChars
											: totalAssignments > 9
											? styles.assignmentsToTileTwoDigits
											: ''
									} ${isSelected ? styles.assignmentToTileSelected : ''}`}
									title={numberDelimeters(totalAssignments)}
								>
									{totalAssignments > 99 ? `99+` : totalAssignments}
								</span>
							</button>
						)}
						<button className={styles.assignmentsToTileContextMenuContainer}>
							<ContextMenu
								options={contextMenuOptions}
								onMenuClicked={handleContextMenuClicked}
								testId={`assigned-to-${assignee}-aTile-kebab`}
							>
								<Icon
									testId="three-dots-assignments-to-tile"
									type="three-dots"
									color={contextMenuColor}
								/>
							</ContextMenu>
						</button>
					</div>
				</div>
				{showDetail && <AssignmentsToDetail isSelected={isSelected} assigneeSummary={assigneeSummary} />}
			</div>
		</AssignmentTileContainer>
	)
}
