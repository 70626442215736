import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { Domain } from '../types'
import { getDataBlocksEntitlements } from './api/getDataBlocksEntitlements'

export const useDataBlocksEntitlements = (includeElements = false): UseQueryResult<Array<Domain>, unknown> => {
	const apiClient = useApi()

	const queryKey = ['getDataBlocksEntitlements', { includeElements }]
	const queryFunction = () => getDataBlocksEntitlements(apiClient)
	return useQuery(queryKey, queryFunction)
}
