import { AxiosInstance, AxiosResponse } from 'axios'

export interface VersionResponse {
	version: string
}

export const getC4SSourcePackageVersion = async (
	apiClient: AxiosInstance,
	sourceId?: string,
	versionSF?: string
): Promise<VersionResponse> => {
	if (versionSF !== undefined) {
		return { version: versionSF }
	} else {
		const url = `/pls/integration/sourceId/${sourceId}/getPackageVersion`
		return apiClient.get(url).then((response: AxiosResponse<VersionResponse>) => response.data)
	}
}
