import { FC, MouseEvent, useState } from 'react'
import { Icon } from '../../local-core-ui'
import styles from './big-button.module.scss'

interface IButtonProps {
	text: string
	onClick(event: MouseEvent<HTMLButtonElement>): void
	onMouseDown?(event: MouseEvent<HTMLButtonElement>): void
	onMouseUp?(event: MouseEvent<HTMLButtonElement>): void
	onMouseLeave?(event: MouseEvent<HTMLButtonElement>): void
	isDisabled?: boolean
	testId: string
}

export const BigButton: FC<IButtonProps> = ({
	text,
	onClick,
	onMouseDown,
	onMouseUp,
	onMouseLeave,
	isDisabled = false,
	testId
}: IButtonProps) => {
	const [focused, setFocused] = useState<boolean>(false)
	return (
		<button
			data-testid={testId}
			onClick={(e) => {
				onClick(e)
				setFocused(false)
			}}
			onFocus={() => {
				setFocused(true)
			}}
			onMouseLeave={(e) => {
				if (onMouseLeave) {
					onMouseLeave(e)
				}
			}}
			onMouseDown={onMouseDown}
			onMouseUp={onMouseUp}
			className={`${styles.bigButton} ${focused ? styles.focused : ''}`}
			disabled={isDisabled}
		>
			<span className={styles.content}>
				<span className={styles.iconContainer}>
					<Icon testId="plus-big-button" type="plus" size="small" />
				</span>
				{text}
			</span>
		</button>
	)
}
