import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { CreateQueryStatus } from './api/getC4SActiveConnection'
import { Connection, getConnections } from './api/getConnections'

export const useConnections = (
	enabled: boolean,
	createConnectionStatus: CreateQueryStatus
): UseQueryResult<Connection[]> => {
	const apiClient = useApi()
	const queryKey = ['getConnections']
	const queryFunction = () => getConnections(apiClient)

	return useQuery(queryKey, queryFunction, {
		enabled: createConnectionStatus != CreateQueryStatus.Loading && enabled
	})
}
