import { ColorOrange, ColorOrangeLighter } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { FC } from 'react'
import { EntityType } from '../../types/sources/EntityType'
import { DashboardComponent } from '../dashboard-component/dashboard-component'

interface UploadSummaryProps {
	uploadId: string
	title?: string
	status: string
	entityType?: EntityType
	sourceWithEV: boolean
	testId: string
}

export const UploadSummary: FC<UploadSummaryProps> = ({
	uploadId,
	title,
	status,
	testId,
	entityType,
	sourceWithEV
}: UploadSummaryProps) => {
	const primaryColor = ColorOrange
	const secondaryColor = ColorOrangeLighter
	const primaryScssColor = '$color-orange'
	const tertiaryScssColor = '$color-orange-burnt-powder'
	const colorToken = 'ColorOrange'

	const uploadSummaryHeader = () => {
		if (title) {
			return title.includes('.') ? title.slice(0, title.lastIndexOf('.')) : title
		} else return uploadId
	}

	return (
		<>
			<h3>{uploadSummaryHeader()}</h3>
			<DashboardComponent
				mode="panels"
				level="Upload"
				ownerId={uploadId}
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				primaryScssColor={primaryScssColor}
				tertiaryScssColor={tertiaryScssColor}
				colorToken={colorToken}
				hasUploads={true}
				hasErrors={status === 'ERROR'}
				testId={testId + '-dbc-upload'}
				showContactGraphs={entityType === EntityType.CONTACTS}
				EVforCurrentSrc={sourceWithEV}
			/>
		</>
	)
}
