import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { useApi } from '../hooks'
import { deleteSource } from './api/deleteSource'

export const useDeleteSource = (
	projectId: string,
	sourceId: string
): UseMutationResult<void, unknown, void, unknown> => {
	const apiClient = useApi()
	const queryClient = useQueryClient()

	const mutationFn = () => deleteSource(apiClient, sourceId)

	return useMutation(mutationFn, {
		onSuccess: () => {
			queryClient.invalidateQueries(['getProjects', projectId])
			queryClient.invalidateQueries(['getSources', { sourceId, inflateMetadata: true }])
			queryClient.invalidateQueries(['getSources', { sourceId, inflateMetadata: false }])
		}
	})
}
