/**
 * BaseDonutChart
 * Receive data : {key1 : value, key2 : value}
 * keys type string
 * values type number
 * **/

import * as d3 from 'd3'
import { FC, useEffect, useRef, useState } from 'react'
import { tokenObj } from '../design-tokens/design-tokens'
import { createTokenList, getValueToken } from '../design-tokens/utils'
import { drawKeys } from '../helpers/base-keys-for-chart'
import chartColors from '../shared/chart-colors.json'
import * as tokens from '../shared/token-colors.json'
import styles from './base-donut-chart.module.scss'

interface IDataValue {
	label: string
	value: number
	netTotal?: string
	description?: string
}

interface IDonutChartProps {
	data: IDataValue[]
	size?: 'mini' | 'small' | 'medium' | 'large' | number
	colors?: string[]
	heavy?: boolean
	showDividers?: boolean
	showTooltip?: boolean
	testId?: string
	showKeys?: boolean
	typeKeys?: 'basic' | 'extended'
	orderedColors?: boolean
}

const sizing = { mini: 48, small: 128, medium: 200, large: 255 }

function createTableExtended(
	rootG: any,
	idSVG: string,
	data: IDataValue[],
	total: number,
	getColor: (d: { [key: string]: unknown }) => unknown,
	showTooltip: boolean,
	colorsTokenList: Array<any>,
	containerGraph?: any,
	placeholder?: d3.Selection<SVGElement>,
	testId?: string
): void {
	const numRowsByTable = 10
	const numTables = Math.ceil(data.length / numRowsByTable)
	const widthBytable = 260
	d3.select('#' + idSVG)
		.select('svg')
		.attr('width', 250 + numTables * widthBytable)

	for (let i = 0; i < numTables; i++) {
		const table = rootG.append('g').attr('transform', 'translate(' + i * widthBytable + ',0)')
		const dataPart = data.slice(i * numRowsByTable, (i + 1) * numRowsByTable)
		const posXGraphContainer =
			containerGraph.node().getBBox().x * -1 + containerGraph.node().getBBox().width / 2 + 20
		createIndividualTable(
			table,
			dataPart,
			total,
			getColor,
			posXGraphContainer,
			showTooltip,
			idSVG,
			colorsTokenList,
			testId,
			placeholder
		)
	}
}

function animateSegmentInGraph(id: string, placeholder?: d3.Selection<SVGElement>): void {
	placeholder.select('#' + id).attr('opacity', 0.65)
}

function removeAnimationSegment(id: string, placeholder?: d3.Selection<SVGElement>): void {
	placeholder.select('#' + id).attr('opacity', '1')
}

function animateSegmentTable(id: string, placeholder: d3.Selection<SVGElement>, color: string): void {
	placeholder.select('#' + id).style('fill', color)
}

function removeAnimationRow(id: string, placeholder: d3.Selection<SVGElement>, color: string): void {
	placeholder.select('#' + id).style('fill', color)
}

function createIndividualTable(
	table: any,
	data: IDataValue[],
	total: number,
	getColor: (d: { [key: string]: unknown }) => unknown,
	posXGraphContainer: number,
	showTooltip: boolean,
	idSVG: string,
	colorsTokenList: Array<tokenObj>,
	testId?: string,
	placeholder?: d3.Selection<SVGElement>
): void {
	const colorWhite = getValueToken(colorsTokenList, 'ColorWhite')
	const colorSlate = getValueToken(colorsTokenList, 'ColorSlateLighter')
	const colorHover = getValueToken(colorsTokenList, 'ColorGray')

	const rows = table
		.selectAll('g')
		.data(data)
		.enter()
		.append('g')
		.attr('id', function (d) {
			return 'row-' + testId + '-' + d.label.replaceAll(/[\s+]/g, '')
		})
		.attr('transform', function (d, i) {
			return 'translate (' + posXGraphContainer + ',' + i * 25 + ')'
		})
		.attr('data-index', function (d, j) {
			return j
		})
		.style('fill', function (d, j) {
			return j % 2 === 0 ? colorSlate : colorWhite // color gray for even rows, white for odd rows
		})

	rows.on('mouseover', function (event, d) {
		d3.select(this).selectAll('rect').attr('fill', colorHover)
		animateSegmentInGraph('segment-' + testId + '-' + d.label.replaceAll(/[\s+]/g, ''), placeholder)
		const id = 'containerTooltips' + testId + '-' + d.label.replaceAll(/[\s+]/g, '')
		if (showTooltip && d.netTotal && !document.getElementById(id)) {
			d3.select(this).transition().duration(150).attr('opacity', '.85')
			const containerTooltip = d3
				.select('#' + idSVG)
				.append('div')
				.attr('id', id)
				.style('position', 'absolute')
				.style('opacity', 0)
			containerTooltip.transition().duration(50).style('opacity', 1)
			const containerDescTooltip = containerTooltip.append('div').classed(styles.containerTooltipDesc, true)
			containerDescTooltip
				.append('div')
				.classed(styles.textTooltipDesc, true)
				.text(d.description || '')
			containerDescTooltip
				.append('div')
				.classed(styles.textTooltip, true)
				.text(
					'Percentage of total: ' +
						((d.value * 100) / total).toFixed(2).replace(/\.00$/, '').toLocaleString() +
						'%'
				)
			containerDescTooltip
				.append('div')
				.classed(styles.textTooltip, true)
				.text('Count: ' + d.value.toLocaleString())
			containerTooltip.style('left', event.offsetX + 5 + 'px').style('top', event.offsetY - 15 + 'px')
		}
	}).on('mouseout', function (event, d) {
		const index = d3.select(this).attr('data-index')
		removeAnimationSegment('segment-' + testId + '-' + d.label.replaceAll(/[\s+]/g, ''), placeholder)
		d3.select(this)
			.selectAll('rect')
			.attr('fill', index % 2 === 0 ? colorSlate : colorWhite)
		if (showTooltip) {
			const containerTooltip = d3
				.select('#' + idSVG)
				.select('#containerTooltips' + testId + '-' + d.label.replaceAll(/[\s+]/g, ''))
			d3.select(this).transition().duration('50').attr('opacity', '1')
			containerTooltip.transition().duration('50').style('opacity', 0)
			containerTooltip.remove()
		}
	})

	const cells = rows
		.selectAll('g')
		.data(function (d) {
			const tempArrayData = Object.values(d)
			tempArrayData.pop()
			return tempArrayData
		})
		.enter()
		.append('g')
		.attr('transform', function (d, i) {
			return 'translate(' + i * 100 + ',0)'
		})

	cells
		.append('rect')
		.attr('width', function (d, i) {
			return i === 2 ? 30 : 100
		})
		.attr('height', 30)

	cells
		.filter(function (d, i) {
			return i === 0
		})
		.append('circle')
		.attr('cx', 15)
		.attr('cy', 15)
		.attr('r', 5)
		.attr('fill', function (d) {
			return getColor(d.toLowerCase().replace(/[\s+]/g, ''))
		})

	cells
		.append('text')
		.attr('x', function (d, j) {
			return j === 0 || j == 2 ? 25 : 80
		})
		.attr('y', 15)
		.attr('text-anchor', function (d, j) {
			return j === 0 ? 'start' : j === 2 ? 'end' : 'end'
		})
		.attr('alignment-baseline', 'middle')
		.attr('class', styles.keylabel)
		.text(function (d: number, j) {
			if (j === 1) return d.toLocaleString()
			else if (j === 2) return d + '%'
			return d
		})
}

function buildPieSVG(
	placeholder: d3.Selection<SVGElement>,
	data: IDataValue[],
	size: number,
	colors: string[],
	heavy: boolean,
	showDividers: boolean,
	showTooltip: boolean,
	myId,
	testId?: string,
	showKeys?: boolean,
	typeKeys?: string,
	colorsTokenList: Array<any>,
	orderedColors: boolean
): void {
	const svg = placeholder
	const rootG = svg.append('g')
	const radius = size / 2
	const innerRadius = heavy ? size / 4.2 : size / 3
	// Extract values from the data and create ColorFunction the color scale,
	// The range array will repeat if it’s shorter than the domain array.

	const defineColor = d3.scaleOrdinal()

	if (orderedColors) {
		const colorDomain = data.map((element) => element.label)
		defineColor.domain(colorDomain)
	} else {
		defineColor.domain([0, data.length - 1])
	}

	defineColor.range(colors)

	const definePieSlices = d3
		.pie()
		.value(function (d) {
			return d.value
		})
		.sort(null)

	const pieSlices = definePieSlices(data)
	let total = data.reduce((accumulator, current) => accumulator + current.value, 0)
	if (total == 0) total = 1 // to avoid division by 0 on percentage calculation (percentage funct)
	const arc = d3.arc().innerRadius(innerRadius).outerRadius(radius)

	// In case there is a previous instance of the Pie and we need to update it, proceed to clean previous chart
	rootG.selectAll('g').remove()
	// append the svg object
	rootG
		.append('g')
		.attr('id', testId + '-graph')
		.attr('transform', 'translate(' + size / 2 + ',' + size / 2 + ')')
		.selectAll('path')
		.data(pieSlices)
		.enter()
		.append('path')
		.attr('d', arc)
		.attr('fill', function (element: { data: { label: string } }) {
			let scaleArg = element.data.label.toLowerCase().replace(/[\s+]/g, '')

			if (orderedColors) {
				scaleArg = element.data.label
			}

			return defineColor(scaleArg)
		})
		.attr('id', function (d) {
			return 'segment-' + testId + '-' + d.data.label.replaceAll(/[\s+]/g, '')
		})
		.on('mouseover', function (d, i) {
			const id = 'containerTooltips' + testId + '-' + i.data.label.replaceAll(/[\s+]/g, '')
			if (showTooltip && i.data.netTotal && !document.getElementById(id)) {
				d3.select(this).transition().duration(50).attr('opacity', '.85')
				const containerTooltip = d3
					.select('#wrapSVG' + myId)
					.append('div')
					.attr('id', id)
					.style('position', 'absolute')
					.style('opacity', 0)
				if (typeKeys === 'basic') {
					const labelTooltip = i.data.label + ': ' + i.data.netTotal
					containerTooltip.transition().duration(50).style('opacity', 1)
					containerTooltip
						.append('div')
						.classed(styles.containerTooltip, true)
						.append('div')
						.classed(styles.textTooltip, true)
						.text(labelTooltip)
					containerTooltip.style('left', d.offsetX + 3 + 'px').style('top', d.offsetY - 5 + 'px')
				} else {
					containerTooltip.transition().duration(50).style('opacity', 1)
					const containerDescTooltip = containerTooltip
						.append('div')
						.classed(styles.containerTooltipDesc, true)
					containerDescTooltip
						.append('div')
						.classed(styles.textTooltipDesc, true)
						.text(i.data.description || '')
					containerDescTooltip
						.append('div')
						.classed(styles.textTooltip, true)
						.text('Percentage of total: ' + ((i.value * 100) / total).toFixed(2).replace(/\.00$/, '') + '%')
					containerDescTooltip
						.append('div')
						.classed(styles.textTooltip, true)
						.text('Count: ' + i.value.toLocaleString())
					containerTooltip.style('left', d.offsetX + 3 + 'px').style('top', d.offsetY - 5 + 'px')
				}
			}
			if (typeKeys === 'extended') {
				const colorHover = getValueToken(colorsTokenList, 'ColorGray')
				const idRow = 'row-' + testId + '-' + i.data.label.replaceAll(/[\s+]/g, '')
				animateSegmentTable(idRow, placeholder, colorHover)
			}
		})
		.on('mouseout', function (d, i) {
			if (showTooltip) {
				const containerTooltip = d3
					.select('#wrapSVG' + myId)
					.select('#containerTooltips' + testId + '-' + i.data.label.replaceAll(/[\s+]/g, ''))
				d3.select(this).transition().duration('50').attr('opacity', '1')
				containerTooltip.transition().duration(50).style('opacity', 0)
				if (typeKeys === 'extended') {
					const colorWhite = getValueToken(colorsTokenList, 'ColorWhite')
					const colorSlate = getValueToken(colorsTokenList, 'ColorSlateLighter')
					const idRow = 'row-' + testId + '-' + i.data.label.replaceAll(/[\s+]/g, '')
					const originalColor = i.index % 2 === 0 ? colorSlate : colorWhite
					removeAnimationRow(idRow, placeholder, originalColor)
					containerTooltip.remove()
				}
				containerTooltip.remove()
			}
		})
	if (showKeys) {
		if (typeKeys === 'basic') {
			rootG.append('g').attr('class', 'keys')
			const key = (d) => d.data.label
			const percentage = (d, total) => (d.value * 100) / total
			const percentageLabel = (d, total) => percentage(d, total).toFixed(0).toLocaleString() + '%'
			const textKey = (d) => {
				return percentageLabel(d, total) + ' ' + key(d)
			}
			const spacingSmall = 16 // spacing-small=1.6rem but ie does not understand rems
			const iconRadius = 10
			const dy = radius + iconRadius + spacingSmall
			const iconPadding = 28

			drawKeys({
				svg: placeholder,
				selector: '.keys',
				data: definePieSlices(data),
				textForKey: textKey,
				showKeys,
				color: defineColor,
				dx: 0,
				dy,
				iconPadding,
				iconRadius,
				keyLabel: styles.keylabel,
				containerTooltips: '#wrapSVG' + myId
			})

			const heightContainerKeys = rootG.select('.keys').node().getBBox().height
			const posX = -(size / 2 + heightContainerKeys / 2 - 90)
			rootG.select('.keys').attr('transform', 'translate(' + (size + 40) + ',' + posX + ')')
		} else if (typeKeys === 'extended') {
			createTableExtended(
				rootG,
				'wrapSVG' + myId,
				data,
				total,
				defineColor,
				showTooltip,
				colorsTokenList,
				placeholder.select('#' + testId + '-graph'),
				placeholder,
				testId
			)
		}
	}
	if (showDividers) {
		rootG.selectAll('path').attr('stroke', tokens.ColorWhite)
	}
}

const getChartSize = (size: Required<IDonutChartProps>['size']): number => {
	if (typeof size === 'number') {
		return size
	} else {
		return sizing[size]
	}
}

export const BaseDonutChart: FC<IDonutChartProps> = ({
	data,
	size = 'small',
	colors,
	heavy = false,
	showDividers = false,
	showTooltip = false,
	testId = '',
	showKeys = false,
	typeKeys = 'basic',
	orderedColors = false
}: IDonutChartProps) => {
	const d3Container = useRef(null)
	const sizeInPx = getChartSize(size)
	const colorsTokenList = createTokenList(tokens)
	const [myId] = useState(Math.floor(Math.random() * 1000 + 1).toString())

	useEffect(() => {
		if (data && d3Container.current) {
			const placeholder = d3.select(d3Container.current)
			placeholder.selectAll('g').remove()
			if (colors == null) {
				colors = Object.entries(chartColors.color.chart).map((x) => x[1]['value'])
			} else {
				colors = colors.map((customColor) => {
					return getValueToken(colorsTokenList, customColor)
				})
			}
			buildPieSVG(
				placeholder,
				data,
				sizeInPx,
				colors,
				heavy,
				showDividers,
				showTooltip,
				myId,
				testId,
				showKeys,
				typeKeys,
				colorsTokenList,
				orderedColors
			)
		}
	}, [data, colors, size])

	return (
		<div style={{ position: 'relative' }} id={'wrapSVG' + myId}>
			<svg width={showKeys ? 500 : sizeInPx} height={sizeInPx} ref={d3Container} data-testid={testId} />
		</div>
	)
}

export default BaseDonutChart
export type { IDonutChartProps }
