import { AxiosInstance, AxiosResponse } from 'axios'

export interface ComplianceCodes {
	codeTables: CodeTable[]
}

export interface CodeTable {
	codeLists: CodeList[]
}

export interface CodeList {
	code: string
	description: string
	language: Language
}

export interface Language {
	description: string
	dnbCode: number
}

export const getComplianceCodes = async (apiClient: AxiosInstance): Promise<ComplianceCodes> => {
	const url = `/pls/referenceData/complianceInsights`
	return apiClient.get(url).then((response: AxiosResponse<ComplianceCodes>) => response.data)
}
