import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon, IconType, ProgressBar } from '../../local-core-ui'
import styles from './input-presence.module.scss'

interface inputInfo {
	field: string
	count: number
	rate: number
}

interface inputPresence {
	fieldName: string
	typeIcon: IconType
	keyLabel: string
}
export interface InputPresenceProps {
	submitted: number
	absorbed: number
	inputPresenceList: Array<inputInfo>
	regularInputs: Array<inputPresence>
	additionalInputs: Array<inputPresence>
	primaryScssColor?: string
	tertiaryScssColor?: string
	textToZero?: string
	showZero?: boolean
	testId: string
}

export const InputPresence: FC<InputPresenceProps> = ({
	submitted,
	absorbed,
	inputPresenceList,
	primaryScssColor = '$color-mulberry',
	tertiaryScssColor = '$color-mulberry-lightest',
	regularInputs,
	additionalInputs,
	textToZero = '',
	showZero = true,
	testId
}: InputPresenceProps) => {
	const { t } = useTranslation()
	const barWidth = 161
	const barHeight = 24
	const precision = 1

	const getCount = useCallback(
		(field: string): number => {
			if (!inputPresenceList) {
				return 0
			}
			const fieldData = inputPresenceList.filter((item) => item.field === field)
			return fieldData && fieldData.length > 0 ? fieldData[0].count : 0
		},
		[inputPresenceList]
	)

	return (
		<div className={styles.inputPresenceTile}>
			<div data-testid="input-presence" className={styles.inputPresence}>
				<div className={styles.header}>
					<span data-testid="title-input-presence" className={styles.title}>
						{t('input.presence')}
					</span>
					<span data-testid="submitted-records-data" className={styles.submitted}>
						{t('input.presence.total.submitted')}{' '}
						<span data-testid="value-submitted" className={styles.value}>
							{new Intl.NumberFormat().format(submitted)}
						</span>
					</span>

					<span data-testid="absorbed-records-data" className={styles.absorbed}>
						{t('input.presence.successfully.absorbed')}
						<span data-testid="value-absorbed" className={styles.value}>
							{new Intl.NumberFormat().format(absorbed)}
						</span>
					</span>
					<div className={styles.subtitle}>{t('input.presence.subtitle')}</div>
				</div>
				<div data-testid="progress-bar-grid-container" className={styles.progressBarGridContainer}>
					{regularInputs.map((inputInfo: inputPresence, idx) => {
						return (
							<span
								data-testid={`bar-${inputInfo.fieldName.toLowerCase()}`}
								className={styles.bar}
								key={`${testId}-${inputInfo.fieldName}-${idx}`}
							>
								<div>
									<Icon
										testId={`${inputInfo.fieldName}-input-presence`}
										type={inputInfo.typeIcon}
										size={'mini'}
										color={primaryScssColor}
									/>
								</div>
								<ProgressBar
									id={`${inputInfo.fieldName}-progress-bar`}
									type="secondary"
									label={t(`${inputInfo.keyLabel}`) as string}
									value={getCount(inputInfo.fieldName)}
									total={submitted}
									svgWidth={barWidth}
									svgHeight={barHeight}
									precision={precision}
									rounded="oneSide"
									numberToShow="value"
									colors={{
										barColor: tertiaryScssColor,
										containerColor: primaryScssColor
									}}
									displayPercentage={true}
									tooltipInfo={{
										effect: 'float',
										position: 'top',
										values: [
											{
												label: t('dashboard.total.records'),
												value: new Intl.NumberFormat().format(submitted)
											},
											{
												label: t(`${inputInfo.keyLabel}`),
												value: new Intl.NumberFormat().format(getCount(inputInfo.fieldName))
											}
										],
										testId: `${testId}-tt-${inputInfo.fieldName}`
									}}
									textToZero={textToZero}
									showZero={showZero}
									testId={`progress-bar-${inputInfo.fieldName.toLowerCase()}`}
								/>
							</span>
						)
					})}
					{additionalInputs.length > 0 && (
						<>
							<div className={styles.subtitle}>{t('input.presence.additional.inputs')}</div>
							{additionalInputs.map((inputInfo: inputPresence, idx) => {
								return (
									<span
										data-testid={`bar-${inputInfo.fieldName.toLowerCase()}`}
										className={styles.bar}
										key={`${testId}-${inputInfo.fieldName}-${idx}`}
									>
										<div>
											<Icon
												testId={`${inputInfo.fieldName}-input-presence`}
												type={inputInfo.typeIcon}
												size={'mini'}
												color={primaryScssColor}
											/>
										</div>
										<ProgressBar
											id={`${inputInfo.fieldName}-progress-bar`}
											type="secondary"
											label={t(`${inputInfo.keyLabel}`) as string}
											value={getCount(inputInfo.fieldName)}
											total={submitted}
											svgWidth={barWidth}
											svgHeight={barHeight}
											precision={precision}
											rounded="oneSide"
											numberToShow="value"
											colors={{
												barColor: tertiaryScssColor,
												containerColor: primaryScssColor
											}}
											displayPercentage={true}
											tooltipInfo={{
												effect: 'float',
												position: 'top',
												values: [
													{
														label: t('dashboard.total.records'),
														value: new Intl.NumberFormat().format(submitted)
													},
													{
														label: t(`${inputInfo.keyLabel}`),
														value: new Intl.NumberFormat().format(
															getCount(inputInfo.fieldName)
														)
													}
												],
												testId: `${testId}-tt-${inputInfo.fieldName}`
											}}
											textToZero={textToZero}
											showZero={showZero}
											testId={`progress-bar-${inputInfo.fieldName.toLowerCase()}`}
										/>
									</span>
								)
							})}
						</>
					)}
				</div>
			</div>
		</div>
	)
}
