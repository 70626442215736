import cx from 'classnames'
import { t } from 'i18next'
import { ChangeEvent, ReactElement } from 'react'
import mandatoryElements from './arrayMandatoryElesAndAlerts.json'
import { CheckboxSubelements } from './checkbox-subelements'
import styles from './children-tree.module.scss'
import { ElementUIFacade } from './ElementUIFacade'
import { countSelectedElementChildren } from './helpers/element-tree-helpers'

interface ChildrenTreeProps {
	index: number
	element: ElementUIFacade
	testId: string
	selectedElementList: Array<ElementUIFacade>
	onChange: (e: ChangeEvent<HTMLInputElement>, option: ElementUIFacade) => void
	defaultElements?: Array<ElementUIFacade>
	showBlockInformation?: boolean
	mandatoryIdArray: Array<string>
	counterUpdated?: (selectedInBlock: number) => void
}

export const ChildrenTree = ({
	index,
	element,
	testId,
	selectedElementList,
	onChange,
	defaultElements,
	showBlockInformation,
	mandatoryIdArray,
	counterUpdated
}: ChildrenTreeProps): ReactElement => {
	if (element.showChildrenBlocks) {
		let selectedChildrenCount = 0
		const blockChildren = element.childs || []
		if (element.containsChildrenSelector || element.multipleChildrenSelector) {
			selectedChildrenCount = countSelectedElementChildren(blockChildren, selectedElementList, defaultElements)
		}
		const checkMandatorySubelements = blockChildren.some((child) =>
			mandatoryElements.mandatoryIds.includes(child.elementId)
		)
		const checkOverallSubelements = blockChildren.every((child) =>
			mandatoryElements.mandatoryIds.includes(child.elementId)
		)
		if (counterUpdated) counterUpdated(selectedChildrenCount)
		return (
			<div
				className={cx({
					[styles.childrenSelectorLeftMargin]:
						element.containsChildrenSelector || element.multipleChildrenSelector
				})}
			>
				{element.maximumElementsInOutput?.current === 0
					? undefined
					: checkMandatorySubelements && (
							<div
								data-testid="subelements-mandatory-title"
								className={cx(styles.wantToKnowQuestionContainer)}
							>
								{t('subelements.mandatory.title')}
							</div>
					  )}
				{blockChildren.map(
					(blockChild, indexChild) =>
						mandatoryElements.mandatoryIds.indexOf(blockChild.elementId) > -1 && (
							<CheckboxSubelements
								key={blockChild.elementId + 'parentsubpanel-' + index}
								blockChild={blockChild}
								indexChild={indexChild}
								index={index}
								element={element}
								testId={testId}
								selectedElementList={selectedElementList}
								onChange={onChange}
								defaultElements={defaultElements}
								showBlockInformation={showBlockInformation}
								mandatoryIdArray={mandatoryIdArray}
							/>
						)
				)}
				{element.maximumElementsInOutput?.current === 0
					? undefined
					: !checkOverallSubelements && (
							<div
								data-testid="subelements-overall-title"
								className={cx(styles.wantToKnowQuestionContainer)}
							>
								{t('subelements.overall.title')}
							</div>
					  )}
				{blockChildren.map(
					(blockChild, indexChild) =>
						mandatoryElements.mandatoryIds.indexOf(blockChild.elementId) === -1 && (
							<CheckboxSubelements
								key={blockChild.elementId + 'parentsubpanel-' + index}
								blockChild={blockChild}
								indexChild={indexChild}
								index={index}
								element={element}
								testId={testId}
								selectedElementList={selectedElementList}
								onChange={onChange}
								defaultElements={defaultElements}
								showBlockInformation={showBlockInformation}
								mandatoryIdArray={[]}
							/>
						)
				)}
				{(element.containsChildrenSelector || element.multipleChildrenSelector) &&
					selectedChildrenCount > 0 && (
						<div className={styles.childrenSelectorMessage}>
							<div data-testid="columns-result-message-tree" className={styles.columnsResultMessage}>
								{t('columns.number.result', { columns: selectedChildrenCount })}
							</div>
							<div data-testid="available-data-message-tree" className={styles.availableDataMessage}>
								{t('available.data')}
							</div>
						</div>
					)}
				{/* {element.containsChildrenSelector &&
					element.maximumElementsInOutput &&
					element.maximumElementsInOutput.current > 0 &&
					selectedChildrenCount === 0 && (
						<div className={cx(styles.childrenSelectorMessage, styles.selectOneElementContainer)}>
							<div className={styles.selectOneElement}>{t('select.one.element')}</div>
						</div>
					)} */}
			</div>
		)
	} else return <></>
}
