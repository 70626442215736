import { ReactElement } from 'react'
import { useWizardRouteMatch } from '../../../../components/wizardComposer/hooks/useWizardRouteMatch'
import { useFeatures, useModules, usePlatform } from '../../../../hooks/useEntitlements'
import { RootState, useAppSelector } from '../../../../store'
import { ChooseMatch } from './choose-match'
import { RecommendationMatch } from './recommendation-match'

export function WizardComposerChooseMatch(): ReactElement {
	const enableMatchRecommendation = useFeatures(['EnableMatchRecommendation'])
	const { next } = useWizardRouteMatch()
	const enableMatchRecommendationC4S = useFeatures(['EnableMatchRecommendationC4S'])
	const salesforcePlatform = usePlatform('salesforce')
	const isC4S = useModules(['C4S']) && salesforcePlatform
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const isFile = projectWizardState.currentProject.source.isFile
	const isCRMSource = projectWizardState.currentProject.thirdPartyIntegration !== undefined
	const showRecommendation = isCRMSource ? enableMatchRecommendationC4S && isC4S : enableMatchRecommendation && !isC4S

	return showRecommendation && isFile ? (
		<RecommendationMatch onSelect={() => next()} />
	) : (
		<ChooseMatch onSelect={() => next()} />
	)
}
