import { MouseEvent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useSession } from '../../hooks'
import '../../i18n'
import { useAppDispatch } from '../../store'
import styles from './logout-link.module.scss'

export interface LogoutLinkProps {
	text: string
}

export function LogoutLink(props: LogoutLinkProps): ReactElement {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const [logOutService] = useSession(dispatch)

	const logOut = (e: MouseEvent) => {
		e.preventDefault()
		logOutService()
	}

	return (
		<Link to="/login" className={styles.logoutLink} onClick={logOut}>
			<span data-testid="logout-link">{t(props.text ? props.text : 'logout')}</span>
		</Link>
	)
}
