import designTokens from '@dnb-uux-design-system/design-tokens/build/index'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { ProgressBar } from '../../local-core-ui'
import styles from './report-progress-bar.module.scss'

interface ReportProgressBarProps {
	id: string
	value: number
	total: number
	variant?: 'meter' | 'simple'
	width?: number
}

const ReportProgressBar = ({
	value,
	total,
	id,
	variant = 'simple',
	width = 200
}: ReportProgressBarProps): ReactElement => {
	const { t } = useTranslation()
	const { TokenChartColors, TokenColors } = designTokens

	return (
		<div className={styles.progressBarWrapper}>
			<ProgressBar
				id={id}
				value={value}
				total={total}
				key={id}
				svgHeight={variant === 'simple' ? 16 : 20}
				svgWidth={width}
				colors={{
					containerColor: TokenChartColors.ColorChartCategory1,
					barColor: TokenColors.ColorGrayPrimaryAlpha1
				}}
				precision={1}
				numberToShow="none"
				type={variant === 'meter' ? 'meter' : 'primary'}
				textTotal={t('dashboard.total') as string}
			/>
		</div>
	)
}

export { ReportProgressBar }
