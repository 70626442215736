import { AxiosResponse } from 'axios'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useApi } from '../../../../hooks'
import { useFeatures } from '../../../../hooks/useEntitlements'
import { Grid } from '../../../../local-core-ui'
import { useMatchedRecordSummary } from '../../../../queries/useMatchedRecordSummary'
import { useProject } from '../../../../queries/useProject'
import { useRUMRules } from '../../../../queries/useRUMRules'
import { useSetSourceRefreshRematch } from '../../../../queries/useSetSourceRefreshRematch'
import { useSetTransactionalMatch } from '../../../../queries/useSetTransactionalMatch'
import { useSource } from '../../../../queries/useSource'
import { useStartRefresh } from '../../../../queries/useStartRefresh'
import { useStartRematch } from '../../../../queries/useStartRematch'
import { useUploads } from '../../../../queries/useUploads'
import { RefreshRematchConfig } from '../../../../types'
import { EntityType } from '../../../../types/sources/EntityType'
import { ActionConfigurationCard } from '../../../action-configuration-card/action-configuration-card'
import { ActionConfiguration } from '../../../action-configuration/action-configuration'
import { ConfirmationModalSourceToogleOff } from '../../../confirmation-modal-source-toggle-off/confirmation-modal-source-toggle-off'
import { RefreshErrorModal } from '../../../refresh-error-modal/RefreshErrorModal'
import { MatchVerificationMessageModal } from '../../../refresh-rematch-now-confirmation-modal/components/MatchVerificationMessageModal/matchVerificationMessageModal'
import { RefreshMatchNowConfirmationModal } from '../../../refresh-rematch-now-confirmation-modal/components/RefreshMatchNowConfirmationModal/refreshMatchNowConfirmationModal'
import { ConfirmationModalStartProcess } from '../../../refresh-rematch-now-confirmation-modal/components/StartProcessConfirmationModal/startProcessConfirmationModal'
import { IRUMLimits, RumLimitsModal } from '../../../rum-limits-modal/rum-limits-modal'
import { timestampFormatter } from '../../../source-settings/timestampFormatter'
import styles from '../../source-settings-on-demand.module.scss'

interface DataUpdatePanelProps {
	sourceId: string
	projectId: string
}

const refreshRematchDefault = {
	refresh: false,
	rematch: false,
	enabled: true,
	nextUpdate: '',
	refreshStatus: '',
	matchStatus: ''
}

export const DataUpdatePanel: FC<DataUpdatePanelProps> = ({ sourceId, projectId }: DataUpdatePanelProps) => {
	const setRefreshRematchMutation = useSetSourceRefreshRematch(sourceId)
	const setTransactionalMatchMutation = useSetTransactionalMatch(sourceId)
	const sourceQuery = useSource(sourceId, true)
	const projectQuery = useProject(projectId)
	const { t } = useTranslation()
	const [refreshRematchConfig, setRefreshRematchConfig] = useState<RefreshRematchConfig>(refreshRematchDefault)
	const [refreshOrRematchActive, setRefreshOrRematchActive] = useState<boolean>(false)
	const [transactionalMatch, setTransactionalMatch] = useState<boolean>(false)
	const [assignmentRule, setAssignmentRule] = useState<boolean>(false)
	const [nextUpdate, setNextUpdate] = useState<string>('')
	const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
	const [showConfirmationModalRefreshNow, setShowConfirmationModalRefreshNow] = useState<boolean>(false)
	const [showConfirmationModalStartRefresh, setShowConfirmationModalStartRefresh] = useState<boolean>(false)
	const [showConfirmationModalStartRematch, setShowConfirmationModalStartRematch] = useState<boolean>(false)
	const [showConfirmationModalMatchNow, setShowConfirmationModalMatchNow] = useState<boolean>(false)
	const [refreshRunning, setRefreshRunning] = useState<boolean>(false)
	const [rematchRunning, setRematchRunning] = useState<boolean>(false)
	const [showRefreshError, setShowRefreshError] = useState<boolean>(false)
	const uploadsQuery = useUploads(sourceId)
	const [showModalRUMFiles, setShowModalRUMFiles] = useState<boolean>(false)
	const [rumLimits, setRUMLimits] = useState<IRUMLimits>({ addOns: [], regions: [] })
	const startRefreshMutation = useStartRefresh(sourceId)
	const startRematchMutation = useStartRematch(sourceId)
	const apiClient = useApi()
	const enableUIOverageAlert = useFeatures(['EnableUIOverageAlert'])
	const C4SDisableRefreshFF = useFeatures(['C4SDisableRefresh'])
	const { data: rumRulesData, status } = useRUMRules(sourceId, true)
	const isExcludeFlagConfigured = rumRulesData?.isExcludeFlagConfigured || false
	const recordsToProcess = rumRulesData?.recordsToProcess || 0
	const recordsToExclude = rumRulesData?.recordsToExclude || 0
	const rumStatus = status
	const { data: hasMatchedRecordsData } = useMatchedRecordSummary(sourceId, true)
	const hasMatchedRecords = hasMatchedRecordsData?.hasMatchedRecords || false

	const [entityType, setEntityType] = useState<string>('')
	const [documentType, setDocumentType] = useState<string>('')

	const handleChangeRefresh = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const config = { ...refreshRematchConfig, refresh: event.target.checked }
			setRefreshRematchConfig(config)
			setRefreshRematchMutation.mutate(config)
		} else {
			checkRefreshRunning()
		}
	}

	const handleChangeRematch = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const config = { ...refreshRematchConfig, rematch: event.target.checked }
			setRefreshRematchConfig(config)
			setRefreshRematchMutation.mutate(config)
		} else {
			checkRematchRunning()
		}
	}

	const handleSwitchTransactionalMatch = (event: ChangeEvent<HTMLInputElement>) => {
		const ruleValue = !event.target.checked ? event.target.checked : assignmentRule
		setTransactionalMatchMutation.mutate({
			enabled: event.target.checked,
			triggerAssignmentRules: ruleValue
		})
		setAssignmentRule(ruleValue)
		setTransactionalMatch(event.target.checked)
	}

	const handleSwitchAssignmentRule = (event: ChangeEvent<HTMLInputElement>) => {
		setTransactionalMatchMutation.mutate({ triggerAssignmentRules: event.target.checked })
		setAssignmentRule(event.target.checked)
	}

	function checkRefreshRunning(): void {
		if (sourceQuery.data?.integrationInfo?.refreshInfo) {
			const refreshStatusResponse: string[] = ['Completed', 'Failed']
			const refreshStatus: string = sourceQuery.data.integrationInfo?.refreshInfo?.refreshStatus
			if (refreshStatusResponse.includes(refreshStatus)) {
				disableToggleRefresh()
			} else {
				setRefreshRunning(true)
				setShowConfirmationModal(true)
			}
		} else {
			disableToggleRefresh()
		}
	}

	function checkRefreshOnDemandRunning(): void {
		if (C4SDisableRefreshFF) {
			setShowRefreshError(true)
			onConfirmStartRefresh()
		} else {
			if (sourceQuery.data?.integrationInfo?.refreshRematchConfig) {
				const refreshStatus = sourceQuery.data.integrationInfo?.refreshRematchConfig?.refreshStatus
				if (refreshStatus && refreshStatus === 'Importing') {
					setShowConfirmationModalRefreshNow(true)
				} else {
					setShowConfirmationModalStartRefresh(true)
				}
			} else {
				setShowConfirmationModalRefreshNow(true)
			}
		}
	}

	function checkRematchOnDemandRunning(): void {
		if (sourceQuery.data?.integrationInfo?.refreshRematchConfig) {
			const matchStatus = sourceQuery.data.integrationInfo?.refreshRematchConfig?.matchStatus
			if (matchStatus && matchStatus === 'Importing') {
				setShowConfirmationModalMatchNow(true)
			} else {
				setShowConfirmationModalStartRematch(true)
			}
		} else {
			setShowConfirmationModalStartRematch(true)
		}
	}

	function checkRematchRunning(): void {
		const problematicFilesStatuses: string[] = [
			'NEW',
			'IMPORT_STARTED',
			'IMPORT_FINISHED',
			'MATCH_STARTED',
			'MATCH_FINISHED'
		]
		if (uploadsQuery.data) {
			const response: string[] = uploadsQuery.data.map((element) => element.status)
			const found: boolean = problematicFilesStatuses.some((r) => response.includes(r))
			if (found) {
				setRematchRunning(true)
				setShowConfirmationModal(true)
			} else {
				disableToggleRematch()
			}
		} else {
			disableToggleRematch()
		}
	}

	const disableToggleRefresh = () => {
		const config = { ...refreshRematchConfig, refresh: false }
		setRefreshRematchConfig(config)
		setRefreshRematchMutation.mutate(config)
	}

	const disableToggleRematch = () => {
		const config = { ...refreshRematchConfig, rematch: false }
		setRefreshRematchConfig(config)
		setRefreshRematchMutation.mutate(config)
	}

	const disableRefreshRematchFromModal = () => {
		let config = { ...refreshRematchConfig }
		if (refreshRunning) config = { ...refreshRematchConfig, refresh: false }
		if (rematchRunning) config = { ...refreshRematchConfig, rematch: false }
		if (refreshRunning) config = { ...refreshRematchConfig, refresh: false }
		if (rematchRunning) config = { ...refreshRematchConfig, rematch: false }
		setRefreshRematchConfig(config)
		setRefreshRematchMutation.mutate(config)
		setShowConfirmationModal(false)
	}

	const rumLimitsResponse = () => {
		const checkRUMurl = `/pls/usage/alert/${projectQuery.data?.purposeOfUse?.domain}/${projectQuery.data?.purposeOfUse?.recordType}?record_count=${recordsToProcess}`

		apiClient
			.get(checkRUMurl)
			.then((response: AxiosResponse<IRUMLimits>) => {
				setRUMLimits(response.data)
				setShowModalRUMFiles(true)
			})
			.catch((error) => {
				console.error(error)
			})
	}

	const onConfirmStartRefresh = () => {
		startRefreshMutation.mutate()
		setShowConfirmationModalStartRefresh(false)
	}

	const onConfirmStartRematch = () => {
		if (enableUIOverageAlert) {
			rumLimitsResponse()
		} else {
			startRematchMutation.mutate()
		}
		setShowConfirmationModalStartRematch(false)
	}

	const getEntityType = () =>
		entityType === EntityType.CONTACTS
			? t('create.project.form.label.contact')
			: t('create.project.form.label.company')

	useEffect(() => {
		if (sourceQuery.isSuccess && !sourceQuery.isFetching) {
			const integrationInfo = sourceQuery.data.integrationInfo
			const refreshRematch: RefreshRematchConfig = integrationInfo?.refreshRematchConfig || refreshRematchDefault
			setRefreshRematchConfig({ ...refreshRematch, enabled: true })
			setRefreshOrRematchActive(refreshRematch.refresh || refreshRematch.rematch || false)
			if (integrationInfo?.refreshRematchConfig.nextUpdate) {
				setNextUpdate(timestampFormatter(integrationInfo?.refreshRematchConfig.nextUpdate))
			}
			setTransactionalMatch(integrationInfo?.isTransactional || false)
			setAssignmentRule(integrationInfo?.refreshRematchConfig.triggerAssignmentRules || false)
			setEntityType(sourceQuery.data?.entityType ?? '')
			setDocumentType(sourceQuery.data?.integrationInfo?.documentType ?? '')
		}
		/**
		 * We only want to run this effect when the property isFetching
		 * from sourceQuery changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceQuery.isFetching])

	return (
		<div>
			<div className={styles.updateOptionsContainer}>
				<div className={styles.updateOptionsTitle}>{t('source.data.update.options')}</div>
				<div className={styles.matchTypeContainer}>
					<div className={styles.updateOptionsSubtitle}>{t('source.entity.type')}</div>
					<div className={styles.updateOptionsEntityType}>&nbsp; {getEntityType()}</div>
					<div className={styles.updateOptionsBody} />
				</div>
				<div className={styles.columnsToggles}>
					<div className={styles.toggleContainer}>
						<Grid testId="first-container-data-update-panel" container>
							<Grid testId="first-action-cards-panel" lg={12}>
								<ActionConfigurationCard
									active={refreshRematchConfig.refresh}
									isLoading={
										sourceQuery.isFetching ||
										setRefreshRematchMutation.status === 'loading' ||
										setTransactionalMatchMutation.status === 'loading'
									}
									title={t('source.refresh.match.records')}
									description={t('source.refresh.match.records.description') ?? ''}
									toggleLabel={t('source.update14switch.onDemand') ?? ''}
									toggleDescription={
										t('source.update.schedule.onDemand', { nextUpdate: nextUpdate }) ?? ''
									}
									buttonLabel={t('source.refresh.match.records.now') ?? ''}
									buttonDescription={t('source.refresh.match.records.now.description') ?? ''}
									onChange={handleChangeRefresh}
									onClick={checkRefreshOnDemandRunning}
								/>
								<RefreshMatchNowConfirmationModal
									open={showConfirmationModalRefreshNow}
									onClose={() => setShowConfirmationModalRefreshNow(false)}
									showButtonClose={false}
									testId={'confirmation-modal-source-refreshNow-off'}
									refresh={true}
								/>
								<RefreshErrorModal
									open={showRefreshError}
									onClose={() => setShowRefreshError(false)}
									testId={'refresh-error-modal'}
								/>
							</Grid>
						</Grid>
					</div>
					<p className={styles.dividerVerticalLine} />
					<div className={styles.toggleContainer}>
						<Grid testId="second-container-data-update-panel" container>
							<Grid testId="second-action-cards-panel" lg={12}>
								{entityType === EntityType.CONTACTS && (
									<ActionConfigurationCard
										isLoading={
											sourceQuery.isFetching ||
											setRefreshRematchMutation.status === 'loading' ||
											setTransactionalMatchMutation.status === 'loading'
										}
										title={t('source.rematch.unmatch.records')}
										description={t('source.rematch.unmatch.records.disabled') ?? ''}
										contactMatchMode
										onClick={checkRematchOnDemandRunning}
										buttonDescription={t('source.rematch.unmatch.records.now.description') ?? ''}
										buttonLabel={t('source.rematch.unmatch.records.now') ?? ''}
									/>
								)}
								{entityType !== EntityType.CONTACTS && (
									<ActionConfigurationCard
										active={refreshRematchConfig.rematch}
										isLoading={
											sourceQuery.isFetching ||
											setRefreshRematchMutation.status === 'loading' ||
											setTransactionalMatchMutation.status === 'loading'
										}
										title={t('source.rematch.unmatch.records')}
										description={t('source.rematch.unmatch.records.description') ?? ''}
										toggleLabel={t('source.update14switch.onDemand') ?? ''}
										toggleDescription={
											t('source.update.schedule.onDemand', { nextUpdate: nextUpdate }) ?? ''
										}
										buttonLabel={t('source.rematch.unmatch.records.now') ?? ''}
										buttonDescription={t('source.rematch.unmatch.records.now.description') ?? ''}
										onChange={handleChangeRematch}
										onClick={checkRematchOnDemandRunning}
									/>
								)}
								<RefreshMatchNowConfirmationModal
									open={showConfirmationModalMatchNow}
									onClose={() => setShowConfirmationModalMatchNow(false)}
									showButtonClose={false}
									testId={'confirmation-modal-source-matchNow-off'}
									refresh={false}
								/>
							</Grid>
						</Grid>
					</div>
					<p className={styles.dividerVerticalLine} />
					<div className={styles.toggleContainer}>
						<Grid testId="third-container-data-update-panel" container>
							<Grid testId="third-action-cards-panel" lg={12}>
								<ActionConfigurationCard
									active={transactionalMatch}
									isLoading={
										sourceQuery.isFetching ||
										setTransactionalMatchMutation.status === 'loading' ||
										setRefreshRematchMutation.status === 'loading'
									}
									title={t('source.transactional.match.onDemand')}
									description={t('match.type.card.accounts.description') ?? ''}
									toggleLabel={t('source.always.on.title') ?? ''}
									onChange={handleSwitchTransactionalMatch}
								/>
								{(entityType !== EntityType.CONTACTS || documentType === 'Lead') && (
									<div className={styles.toggleAssignment}>
										<ActionConfiguration
											isDisabled={!transactionalMatch}
											active={!transactionalMatch ? false : assignmentRule}
											isLoading={
												sourceQuery.isFetching ||
												setTransactionalMatchMutation.status === 'loading' ||
												setRefreshRematchMutation.status === 'loading'
											}
											toggleLabel={
												t('source.update.assignment.rules', {
													source: sourceQuery.data?.integrationInfo?.documentType
												}) ?? ''
											}
											onChange={handleSwitchAssignmentRule}
										/>
									</div>
								)}
							</Grid>
						</Grid>
					</div>
				</div>
				<MatchVerificationMessageModal
					open={showConfirmationModalStartRefresh && !hasMatchedRecords}
					onClose={() => setShowConfirmationModalStartRefresh(false)}
					showButtonClose={false}
					testId={'confirmation-modal-source-match-verification'}
				/>
				<ConfirmationModalStartProcess
					open={showConfirmationModalStartRefresh && hasMatchedRecords}
					onClose={() => setShowConfirmationModalStartRefresh(false)}
					onConfirmClick={() => {
						onConfirmStartRefresh()
					}}
					showButtonClose={false}
					testId={'confirmation-modal-source-start-process'}
				/>
				<ConfirmationModalStartProcess
					open={showConfirmationModalStartRematch}
					onClose={() => setShowConfirmationModalStartRematch(false)}
					onConfirmClick={() => {
						onConfirmStartRematch()
					}}
					showButtonClose={false}
					testId={'confirmation-modal-source-start-process'}
				/>
				<RumLimitsModal
					open={showModalRUMFiles}
					addOns={rumLimits?.addOns}
					regions={rumLimits?.regions}
					processFile={() => {
						startRematchMutation.mutate()
						setShowModalRUMFiles(false)
					}}
					saveNoProcessFile={() => {
						setShowModalRUMFiles(false)
					}}
					records={0}
					crmSource={sourceQuery.data?.integrationInfo?.documentType}
					rumSummary={{
						isExcludeFlagConfigured: isExcludeFlagConfigured,
						recordsToProcess: recordsToProcess,
						recordsToExclude: recordsToExclude,
						status: rumStatus
					}}
					isSalesforce={true}
				/>
				{refreshOrRematchActive && (
					<div>
						<div>
							<ConfirmationModalSourceToogleOff
								open={showConfirmationModal}
								onClose={() => setShowConfirmationModal(false)}
								onConfirmClick={() => {
									disableRefreshRematchFromModal()
								}}
								showButtonClose={false}
								testId={'confirmation-modal-source-toggle-off'}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
