import { ColorWhite } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { KeyboardEvent, MouseEvent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import dnbAmpLogo from '../assets/images/dnb-amp-logo.svg'
import dnbWordMarkLogo from '../assets/images/dnb-wordmark-logo.svg'
import { useSession } from '../hooks'
import { Icon } from '../local-core-ui'
import { SignOutReason, useAppDispatch } from '../store'
import styles from './unauthorized-login.module.scss'

export interface UnauthorizedProps {
	isC4S?: boolean
}

export const UnauthorizedC4SLogin = () => <UnauthorizedLogin isC4S={true} />

export function UnauthorizedLogin({ isC4S = false }: UnauthorizedProps): ReactElement {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const [signOut] = useSession(dispatch)

	const goToSupport = async (e: MouseEvent | KeyboardEvent) => {
		if (e) e.preventDefault()
		isC4S
			? window.open(import.meta.env.VITE_C4S_SUPPORT_URL, '_blank')
			: window.open(import.meta.env.VITE_DISCOVER_SUPPORT_URL, '_blank')
	}

	const goToSupportForOthers = async (e: MouseEvent | KeyboardEvent) => {
		if (e) e.preventDefault()
		window.open(import.meta.env.VITE_OTHERS_SUPPORT_URL, '_blank')
	}

	const onLogOut = () => {
		signOut(SignOutReason.userrequested)
	}
	return (
		<div className={`${styles.unauthorizedContainer} ${styles.loginBackground}`}>
			<div className={styles.mask}>
				<div className={styles.cardContainer}>
					<div className={styles.logoContainer}>
						<img alt="D&B logo" src={dnbAmpLogo} className={styles.ampersand} />
						<img alt="dun & bradstreet" src={dnbWordMarkLogo} className={styles.dnbWordmarkLogo} />
					</div>
					<main className={styles.contentContainer}>
						<p className={styles.noAccess}>{t('no.access')}</p>
						<h1 className={styles.productName}>
							{isC4S ? t('unauthorized.c4s.product.name') : t('product.name')}
						</h1>
						<div className={styles.supportSection}>
							<p className={styles.noAccess}>{t('customer.support.us')}</p>
							<u>
								<a onClick={goToSupport}>{t('us.link.text')}</a>
							</u>
							<p>
								<span className={styles.talkTextStyles}>{t('talk.to.us')}</span>
								<span className={styles.fontWeight}>800-486-8666</span>
							</p>
						</div>
						<div className={styles.supportSection}>
							<p className={styles.noAccess}>{t('customer.support.canada')}</p>
							<u>
								<a onClick={goToSupport}>{t('canada.link.text')}</a>
							</u>
							<p>
								<span className={styles.talkTextStyles}>{t('talk.to.us')}</span>
								<span className={styles.fontWeight}>800-486-8666</span>
							</p>
						</div>
						<div className={styles.supportSection}>
							<p className={`${styles.noAccess} ${styles.marginBottomClass}`}>
								{t('customer.support.uk/i')}
							</p>
							<p className={styles.marginBottomClass}>
								<span className={styles.talkTextStyles}>{t('uk/i.email.text')}</span>
								<span className={styles.fontWeight}>ukcs@dnb.com</span>
							</p>
							<p>
								<span className={styles.talkTextStyles}>{t('talk.to.us.uk')}</span>
								<span className={styles.fontWeight}>+44 (0)845 145 1700</span>
							</p>
						</div>
						<div className={styles.supportSection}>
							<p className={`${styles.noAccess} ${styles.marginBottomClass}`}>
								{t('customer.support.others')}
							</p>
							<p className={styles.othersdesc}>{t('customer.support.others.desc')}</p>
							<u>
								<a onClick={goToSupportForOthers}>{t('others.link.text')}</a>
							</u>
						</div>
						{isC4S ? (
							<div className={styles.signoutContainer}>
								<div data-test="signout-btn" className={styles.signoutButton} onClick={onLogOut}>
									<Icon
										testId="back-unauthorized"
										type="arrow-back"
										size="mini"
										color={ColorWhite}
										title="Try again"
									/>
									{t('unauthorized.c4s.try.again')}
								</div>
							</div>
						) : undefined}
					</main>
				</div>
			</div>
		</div>
	)
}
