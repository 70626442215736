import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { getReadyToSteward } from './api/getReadyToSteward'

export const useReadyToSteward = (): UseQueryResult<boolean, unknown> => {
	const apiClient = useApi()

	const queryKey = ['getReadyToSteward']
	const queryFunction = () => getReadyToSteward(apiClient)
	return useQuery(queryKey, queryFunction)
}
