import { ColorGrayDark, ColorWhite } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { FocusEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContextMenu, ContextMenuOption, Icon } from '../../local-core-ui'
import { AssignmentTileContainer } from '../assignment-tile-container/assignment-tile-container'
import { AssignmentOverviewDetail } from './assignment-overview-detail'
import styles from './assignment-overview-tile.module.scss'
import { assignContextMenuOptions, unAssignContextMenuOptions } from './context-menu-options'

interface AssignmentOverviewTileProps {
	type: 'assigned' | 'unassigned'
	onHideDashboard(): void
	onDownloadRecords(): void
	showDetail: boolean
	onAssign?(): void
	totalCount: number
	isSelected: boolean
	onFocus(event: FocusEvent<HTMLDivElement>): void
}

export const AssignmentOverviewTile = ({
	type,
	onHideDashboard,
	onDownloadRecords,
	showDetail,
	onAssign,
	totalCount,
	isSelected,
	onFocus
}: AssignmentOverviewTileProps): ReactElement => {
	const { t } = useTranslation()
	const title = t(`assignment.overview.tile.${type}`)
	const contextMenuOptions: ContextMenuOption[] =
		type === 'assigned' ? assignContextMenuOptions(t, showDetail) : unAssignContextMenuOptions(t, showDetail)
	const [contextMenuColor, setContextMenuColor] = useState<string>(ColorGrayDark)

	const handleContextMenuClicked = (option: string) => {
		switch (option) {
			case 'hideDashboard':
				onHideDashboard()
				break
			case 'assignRecords':
				if (onAssign) onAssign()
				break
			case 'downloadRecords':
				onDownloadRecords()
				break
		}
	}

	useEffect(() => {
		setContextMenuColor(isSelected ? ColorWhite : ColorGrayDark)
	}, [isSelected])

	return (
		<AssignmentTileContainer
			isSelected={isSelected}
			showDetail={showDetail}
			testId={`${type}-overview-tile`}
			onFocus={onFocus}
		>
			<div
				className={`${styles.assignmentOverviewTileContainer} ${
					showDetail ? '' : styles.assignmentOverviewTileSummary
				}`}
			>
				<div className={styles.assignmentOverviewTileHeader}>
					<h4 className={styles.assignmentOverviewTileTitle}>{title}</h4>
					<button className={styles.assignmentOverviewTileContextMenuContainer}>
						<ContextMenu
							options={contextMenuOptions}
							onMenuClicked={handleContextMenuClicked}
							testId="overview-aTile-kebab"
						>
							<Icon testId="three-dots-overview-tile" type="three-dots" color={contextMenuColor} />
						</ContextMenu>
					</button>
				</div>
				{showDetail && (
					<AssignmentOverviewDetail
						isSelected={isSelected}
						type={type}
						onDownloadRecords={onDownloadRecords}
						totalCount={totalCount}
						onAssign={onAssign}
					/>
				)}
			</div>
		</AssignmentTileContainer>
	)
}
