import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { numberDelimeters } from '../../helpers'
import styles from './assignment-published-tile.module.scss'

interface AssignmentPublishedDetailProps {
	isSelected: boolean
	onDownloadRecords(): void
	autoCount: number
	manualCount: number
	rejectedCount: number
}

export const AssignmentPublishedDetail = ({
	isSelected,
	onDownloadRecords,
	autoCount,
	manualCount,
	rejectedCount
}: AssignmentPublishedDetailProps): ReactElement => {
	const { t } = useTranslation()

	return (
		<>
			<div className={styles.assignmentPublishedTileBody}>
				<div className={styles.assignmentPublishedTileDataContainer}>
					<span
						className={`${styles.assignmentPublishedTileData} ${
							isSelected ? styles.assignmentPublishedTileSelectedTxt : ''
						}`}
					>
						{t('assignment.published.tile.auto.accepted')}
					</span>
					<span
						className={`${styles.assignmentPublishedTileData} ${styles.assignmentPublishedTileCount} ${
							isSelected ? styles.assignmentPublishedTileSelectedTxt : ''
						}`}
					>
						{numberDelimeters(autoCount)}
					</span>
				</div>
				<div className={styles.assignmentPublishedTileDataContainer}>
					<span
						className={`${styles.assignmentPublishedTileData} ${
							isSelected ? styles.assignmentPublishedTileSelectedTxt : ''
						}`}
					>
						{t('assignment.published.tile.manually.accepted')}
					</span>
					<span
						className={`${styles.assignmentPublishedTileData} ${styles.assignmentPublishedTileCount} ${
							isSelected ? styles.assignmentPublishedTileSelectedTxt : ''
						}`}
					>
						{numberDelimeters(manualCount)}
					</span>
				</div>
				<div className={styles.assignmentPublishedTileDataContainer}>
					<span
						className={`${styles.assignmentPublishedTileData} ${
							isSelected ? styles.assignmentPublishedTileSelectedTxt : ''
						}`}
					>
						{t('assignment.published.tile.rejected')}
					</span>
					<span
						className={`${styles.assignmentPublishedTileData} ${styles.assignmentPublishedTileCount} ${
							isSelected ? styles.assignmentPublishedTileSelectedTxt : ''
						}`}
					>
						{numberDelimeters(rejectedCount)}
					</span>
				</div>
			</div>
			<div className={styles.assignmentPublishedTileBtnContainer}>
				<button
					className={`${styles.assignmentPublishedTileBtn} ${
						isSelected ? styles.assignmentPublishedTileBtnSelected : ''
					}`}
					onClick={onDownloadRecords}
					data-testid="published-overview-tile-download-btn"
				>
					{t('assignments.to.tile.download.records')}
				</button>
			</div>
		</>
	)
}
