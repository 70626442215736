import { AxiosInstance } from 'axios'
import { FinalizeUploadRequest } from '../../types/steward/FinalizeUploadRequest'

export const finalizeRecordsForUpload = async (apiClient: AxiosInstance, uploadId: string): Promise<void> => {
	const url = `/pls/steward/records/finalize`

	const finalizeRequest: FinalizeUploadRequest = {
		uploadId
	}

	return apiClient.post<unknown>(url, finalizeRequest).then()
}
