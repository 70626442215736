import { ColorGrayDark } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { Fragment, ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon, IconType, ToolTip } from '../../local-core-ui'
import { StringComparisonView } from '../string-comparision-view/StringComparisonView'
import styles from './record-compare-card.module.scss'

interface RecordBlockProps {
	iconType?: IconType
	title?: string
	labels?: string[]
	details?: string[]
	comparisonDetails?: string[]
	labelClassName?: { [key: number]: string }
	detailClassName?: { [key: number]: string }
	showIndicator: boolean
	showIcon: boolean
	iconLabel: string
	noTopMargin: boolean
	helperDetail?: { [key: number]: JSX.Element }
}

const RecordBlock = ({
	iconType,
	title,
	labels,
	details,
	comparisonDetails,
	labelClassName,
	detailClassName,
	showIndicator,
	showIcon,
	iconLabel,
	noTopMargin,
	helperDetail
}: RecordBlockProps): ReactElement => {
	const regExp = /[\s, -.]+/
	const { t } = useTranslation()

	const wordExists = (sentence: string, word: string): boolean => {
		const wordsInSentence = sentence?.toLowerCase().split(regExp) || []
		return wordsInSentence.includes(word)
	}

	const compareStrings = (detail: string, comparisonDetail: string): string[] => {
		const detailWords = detail.toLowerCase().split(regExp)
		return detailWords.reduce((differences, word) => {
			const nonExistingWords: string[] = []
			if (!wordExists(comparisonDetail, word)) {
				nonExistingWords.push(word)
			}
			return [...differences, ...nonExistingWords]
		}, [] as string[])
	}

	const getIfIsExtraDetail = (detail?: string, comparisonDetail?: string): boolean => {
		return (
			(comparisonDetail === undefined || comparisonDetail === null || comparisonDetail?.length === 0) &&
			detail !== undefined &&
			detail !== null &&
			detail?.length !== 0
		)
	}

	return (
		<div className={`${styles.recordBlockContainer} ${noTopMargin ? styles.recordBlockNoMargin : ''}`}>
			{iconType && showIcon && (
				<div className={styles.recordBlockIconContainer}>
					<Icon testId="icon-record-block" type={iconType} color={ColorGrayDark} title={t(iconLabel)} />
				</div>
			)}
			<div
				className={`${styles.recordDetails} ${iconType ? '' : styles.noIcon} ${
					showIcon && !showIndicator ? styles.noIndicator : ''
				}`}
			>
				{title && (
					<p className={`${styles.recordTitle} ${showIndicator ? '' : styles.spacingMedium}`}>{title}</p>
				)}
				<div className={styles.recordInfoContainer}>
					{labels ? (
						<div className={styles.recordLabelsContainer}>
							{labels.map((label, idx) => {
								const labelClass = labelClassName && labelClassName[idx] ? labelClassName[idx] : ''
								const detail = details ? details[idx] : ''
								const comparisonDetail = comparisonDetails ? comparisonDetails[idx] : ''
								const detailClass = detailClassName && detailClassName[idx] ? detailClassName[idx] : ''
								const isExtraDetail = getIfIsExtraDetail(detail, comparisonDetail)
								const diffWords =
									detail && comparisonDetail ? compareStrings(detail, comparisonDetail) : []
								const areDiffs = diffWords?.length !== 0

								return (
									<Fragment key={label}>
										<div className={styles.recordContainer}>
											{showIndicator && (
												<Indicator areDiffs={areDiffs} isExtraDetail={isExtraDetail} />
											)}
											<p className={`${styles.recordLabel} ${labelClass}`}>{label}</p>
											<Detail
												detail={detail}
												comparisonDetail={comparisonDetail}
												detailClass={detailClass}
												diffWords={diffWords}
												showIndicator={showIndicator}
												isExtraDetail={isExtraDetail}
											/>
										</div>
										{helperDetail && helperDetail[idx]}
									</Fragment>
								)
							})}
						</div>
					) : (
						details?.map((detail, idx) => {
							const detailClass = detailClassName && detailClassName[idx] ? detailClassName[idx] : ''
							const comparisonDetail = comparisonDetails ? comparisonDetails[idx] : ''
							const isExtraDetail = getIfIsExtraDetail(detail, comparisonDetail)
							const diffWords = detail && comparisonDetail ? compareStrings(detail, comparisonDetail) : []
							const areDiffs = diffWords?.length !== 0

							return (
								<Fragment key={`${detail}-idx-${idx}`}>
									<div className={styles.recordContainer}>
										{showIndicator && (
											<Indicator areDiffs={areDiffs} isExtraDetail={isExtraDetail} />
										)}
										<Detail
											detail={detail}
											comparisonDetail={comparisonDetail}
											detailClass={detailClass}
											diffWords={diffWords}
											showIndicator={showIndicator}
											isExtraDetail={isExtraDetail}
										/>
									</div>
									{helperDetail && helperDetail[idx]}
								</Fragment>
							)
						})
					)}
				</div>
			</div>
		</div>
	)
}

interface DetailProps {
	detail: string
	comparisonDetail: string
	detailClass: string
	diffWords: string[]
	showIndicator: boolean
	isExtraDetail: boolean
}

const Detail = ({
	detail,
	comparisonDetail,
	detailClass,
	diffWords,
	showIndicator,
	isExtraDetail
}: DetailProps): ReactElement => {
	const sentenceEl = useRef<HTMLParagraphElement>(null)
	const [needsTooltip, setNeedsTooltip] = useState<boolean>(false)
	const diffContainerStyling = diffWords?.length > 0 ? styles.diffContainerStyling : ''

	useEffect(() => {
		if (sentenceEl.current) {
			const hasXScroll = sentenceEl.current.scrollWidth > sentenceEl.current.offsetWidth
			const hasYScroll = sentenceEl.current.scrollHeight > sentenceEl.current.offsetHeight
			if (hasXScroll || hasYScroll) {
				setNeedsTooltip(true)
			} else if (needsTooltip) {
				setNeedsTooltip(false)
			}

			const comparisonAddress = getComputedStyle(sentenceEl.current, 'after')
			const comparisonAddressHeight = parseInt(comparisonAddress.height)
			sentenceEl.current.style.height = ''
			const hasSmallerHeight = sentenceEl.current.offsetHeight < comparisonAddressHeight
			if (comparisonAddress && comparisonAddressHeight && hasSmallerHeight) {
				sentenceEl.current.style.height = comparisonAddressHeight + 'px'
			}
		}
		/**
		 * 'needsTooltip' is not added as a dependency because it would create an infinite loop.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sentenceEl, detail, comparisonDetail])

	return (
		<ToolTip
			key={`${detail}`}
			effect="float"
			position="right"
			testId={detail + 'record-block'}
			customContent={
				detail && needsTooltip ? (
					<p className={`${styles.recordDetailTooltipContainer} ${detailClass} ${diffContainerStyling}`}>
						{showIndicator ? <StringComparisonView original={comparisonDetail} compare={detail} /> : detail}
					</p>
				) : undefined
			}
		>
			<p
				ref={sentenceEl}
				className={`${styles.recordDetail} ${detailClass} ${diffContainerStyling} ${
					isExtraDetail && showIndicator ? styles.greenTxt : ''
				}`}
				data-value={comparisonDetail}
			>
				{detail ? (
					showIndicator ? (
						<StringComparisonView original={comparisonDetail} compare={detail} />
					) : (
						detail
					)
				) : (
					<span className={styles.emptyField}>&#9866;&#9866;&#9866;</span>
				)}
			</p>
		</ToolTip>
	)
}

interface IndicatorProps {
	areDiffs: boolean
	isExtraDetail: boolean
}

const Indicator = ({ areDiffs, isExtraDetail }: IndicatorProps): ReactElement => {
	return (
		<div className={styles.recordBlockIndicatorIconContainer}>
			{(areDiffs || isExtraDetail) && (
				<Icon
					testId="arrows-X-record-block"
					type={areDiffs ? 'arrows-reverse' : isExtraDetail ? 'plus' : 'x'}
					color={ColorGrayDark}
					size="mini"
				/>
			)}
		</div>
	)
}

export { RecordBlock, Indicator }
