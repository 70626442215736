import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Input, Modal } from '../../local-core-ui'
import styles from './template-saving-modal.module.scss'

interface TemplateSavingModalProps {
	isOpen: boolean
	onClose: () => void
	templateExist: boolean
	onSave: (templateName: string) => void
	onUpdate?: () => void
	testId: string
	templateName?: string
	title: string
	inputLabel: string
	inputHint: string
	btnText: string
	updateDescription: string
}

export const TemplateSavingModal = ({
	isOpen,
	onClose,
	templateExist,
	onSave,
	onUpdate,
	testId,
	templateName = '',
	title,
	inputLabel,
	inputHint,
	btnText,
	updateDescription
}: TemplateSavingModalProps): ReactElement => {
	const { t } = useTranslation()
	const [isDisabled, setIsDisabled] = useState<boolean>(true)
	const [name, setName] = useState<string>(templateName)

	const onNameChange = (nameInput: string) => {
		if (nameInput !== ' ') {
			setName(nameInput)
		}
	}

	useEffect(() => {
		name.length > 0 ? setIsDisabled(false) : setIsDisabled(true)
	}, [name])

	return (
		<div className={styles.containerTemplateSavingModal} data-testid="template-saving-modal-container">
			<Modal open={isOpen} onClose={onClose} testId="TemplateSavingModal">
				<div className={styles.header}>
					<h1 data-testid="title-header-save-template" className={styles.title}>
						{title}
					</h1>
				</div>
				<div className={styles.body}>
					{templateExist && onUpdate && (
						<div className={styles.updateWrapper}>
							<div>
								<label className={styles.updateQuestionLabel}>
									{t('template.saving.modal.update.question')}
								</label>
							</div>
							<div className={styles.updateSection}>
								<div
									className={styles.updateButtonContainer}
									onClick={() => {
										onUpdate()
										onClose()
									}}
									tabIndex={0}
									onKeyDown={(event) => {
										if (event.key === 'Enter') {
											onUpdate()
											onClose()
										}
									}}
								>
									<label className={styles.updateLabel}>
										{t('template.saving.modal.update.link')}
									</label>
								</div>
								<label>{updateDescription}</label>
							</div>
						</div>
					)}
					<div className={styles.inputWrapper}>
						<Input
							id={testId + 'templateNameInput'}
							hint={inputHint}
							onChangeFunction={onNameChange}
							value={name}
							label={inputLabel}
							size="medium"
							required
							testId={testId + '-templateName'}
						/>
						<span className={styles.inputSpan}>{t('template.saving.modal.input.span')}</span>
					</div>
				</div>
				<div className={styles.footer}>
					<Button
						text={btnText}
						isDisabled={isDisabled}
						size="medium"
						onClick={() => onSave(name)}
						testId={testId + '-modal-save'}
					/>
				</div>
			</Modal>
		</div>
	)
}
