import { About } from './about/about'
import { MatchInclusion } from './components/match-inclusion/match-inclusion'
import { IRouteProps } from './components/switch-routes/switch-routes'
import { StepComposer } from './components/wizardComposer/stepComposer'
import { WizardComposer } from './components/wizardComposer/wizardComposer'
import { Dashboard } from './dashboard/dashboard'
import { DashboardOverview } from './dashboard/overview/dashboard-overview'
import { Report } from './dashboard/report/report'
import { LoggedOut } from './logout/logged-out'
import { PreLogin } from './pre-login/pre-login'
import { SetupProject } from './project/steps/setup-project/setup-project'
import { SessionInfo } from './session/sessionInfo'
import { TemporalErrorModal } from './temporal/temporalErrorModal'
import { UnauthorizedC4SLogin } from './unauthorized-login/unauthorized-login'

export const AppRoutes: IRouteProps[] = [
	{ path: '/preLogin', component: PreLogin },
	{ path: '/c4sAccess', component: UnauthorizedC4SLogin },
	{ path: '/dashboard', component: Dashboard },
	{ path: '/dashboard-overview', component: DashboardOverview },
	{ path: '/session', component: SessionInfo },
	{ path: '/steps/:wizKey', component: WizardComposer },
	{ path: '/step/:wizKey', component: StepComposer },
	{ path: '/project', component: SetupProject },
	{ path: '/about', component: About },
	{ path: '/report', component: Report },
	{ path: '/inclusion', component: MatchInclusion },
	{ path: '/errorModal', component: TemporalErrorModal },
	{ path: '/logged-out', component: LoggedOut },
	{ path: '/', component: Dashboard }
]

export default AppRoutes
