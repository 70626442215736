import { forwardRef, useState } from 'react'
import { Icon } from '../../local-core-ui'
import styles from './expanded-input.module.scss'

interface ExpandedInputProps {
	id: string
	hint: string
	value: string
	testId: string
	searchIcon: boolean
	iconColor?: string
	onChangeFunction(value: string): void
	onBlurFunction?(value: string): void
}

export const ExpandedInput = forwardRef<HTMLInputElement, ExpandedInputProps>(
	(
		{ id, hint, value, testId, searchIcon, iconColor, onChangeFunction, onBlurFunction }: ExpandedInputProps,
		ref?
	) => {
		const [showClearIcon, setShowClearIcon] = useState(value.length !== 0)

		const onChangeValue = (newValue: string) => {
			if (newValue.length === 0) {
				setShowClearIcon(false)
			} else if (!showClearIcon) {
				setShowClearIcon(true)
			}
			onChangeFunction(newValue)
		}

		const onBlurInput = (newValue: string) => {
			if (onBlurFunction) {
				onBlurFunction(newValue)
			}
		}

		const clearInput = () => {
			onChangeFunction('')
			setShowClearIcon(false)
		}

		return (
			<div id={id} className={styles.expandedInputContainer}>
				{searchIcon && (
					<div className={styles.searchIconContainer}>
						<Icon
							testId="search-expanded-input"
							type="search"
							size="mini"
							color={iconColor ? iconColor : ''}
						/>
					</div>
				)}
				<div className={styles.containerInput}>
					<input
						id={id + 'input'}
						className={styles.expandedInput}
						ref={ref}
						placeholder={hint}
						value={value}
						data-testid={testId + '-input'}
						onChange={(event) => onChangeValue(event.target.value)}
						onBlur={(event) => onBlurInput(event.target.value)}
						type="text"
					/>
				</div>
				{showClearIcon && (
					<button className={styles.xIconContainer} onClick={clearInput} data-testid={testId + '-clear'}>
						<Icon testId="X-expanded-input" type="x" />
					</button>
				)}
			</div>
		)
	}
)

ExpandedInput.displayName = 'ExpandedInput'
