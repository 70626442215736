import { FC } from 'react'
import { Icon } from '../../local-core-ui'
import { StringComparisonView } from '../string-comparision-view/StringComparisonView'
import styles from './table-compare.module.scss'

interface DiffCompareProps {
	original?: string
	compare?: string
	showDiffForEmpties?: boolean
	showTag?: boolean
}

export const DiffCompare: FC<DiffCompareProps> = ({
	original = '',
	compare = '',
	showDiffForEmpties = true,
	showTag = true
}: DiffCompareProps) => {
	const hasDiffs = () => {
		if (showDiffForEmpties) {
			return original != compare
		} else {
			return original && compare && original != compare
		}
	}
	const isEmptyDiff = original?.length === 0 && compare?.length > 0

	return (
		<div>
			{hasDiffs() && showTag ? (
				<span className={styles.diffChevron}>
					<Icon
						testId="plus-arrows-diff-compare"
						type={isEmptyDiff ? 'plus' : 'arrows-reverse'}
						color="$color-gray-dark"
						size="mini"
					/>
				</span>
			) : (
				<></>
			)}
			<StringComparisonView original={original} compare={compare} />
		</div>
	)
}
