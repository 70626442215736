import cx from 'classnames'
import { FC, PropsWithChildren } from 'react'
import Divider from '../divider/divider'
import { Button } from '../index'
import styles from './accordion.module.scss'

interface IadditionalTexts {
	textCollapsed: string
	textExpanded: string
}
export interface AccordionHeaderProps {
	title: string
	additionalText?: string | IadditionalTexts
	stickyAdditionalText?: boolean
	isOpen?: boolean
	extraDataTitle?: string
	controls: string
	isCollapsable?: boolean
	type?: 'normal' | 'button'
}

export interface withAccordionParentProps extends AccordionHeaderProps {
	onChange?(idx: number): void
	index?: number
	testId?: string
}

export const AccordionHeader: FC<PropsWithChildren<withAccordionParentProps>> = (
	props: PropsWithChildren<withAccordionParentProps>
) => {
	const {
		children,
		testId,
		title,
		additionalText,
		stickyAdditionalText = false,
		isOpen = false,
		onChange,
		index,
		extraDataTitle = '',
		controls,
		isCollapsable = true,
		type = 'normal'
	} = props as PropsWithChildren<withAccordionParentProps>
	const onToggleItem = () => {
		if (onChange && isCollapsable) onChange(index)
	}

	const showAccordion = () => {
		onChange(index)
	}

	return (
		<div className={styles.accordionHead} data-testid={testId + '-head-' + index}>
			{type === 'button' && !isOpen ? (
				<Button
					text={title}
					type={'secondary'}
					size={'large'}
					onClick={() => showAccordion()}
					colors={{
						normalState: {
							textColor: 'ColorGrayDarker',
							backgroundColor: 'ColorWhite',
							borderColor: 'ColorGray'
						},
						hoverState: {
							textColor: 'ColorGrayDarker',
							backgroundColor: 'ColorWhite',
							borderColor: 'ColorGrayDark'
						},
						focusState: {
							textColor: 'ColorGrayDarker',
							backgroundColor: 'ColorWhite',
							borderColor: 'ColorBlueBrand'
						},
						disabledState: {
							textColor: 'ColorGrayDarker',
							backgroundColor: 'ColorWhite',
							borderColor: 'ColorGray'
						}
					}}
					testId={`button-${testId}-${index}`}
				/>
			) : (
				<>
					{children && (
						<div data-testid={`accordion-children-${testId}`} className={styles.accordionControl}>
							{children}
						</div>
					)}
					<div
						data-testid={`accordion-container-header-${testId}`}
						className={cx(styles.containerHeader, { [styles.bottomHeader]: !isOpen })}
					>
						<Divider size="large" color={'ColorSlateLighter'} />
						<div
							className={styles.titleSection}
							onClick={() => onToggleItem()}
							aria-controls={controls}
							aria-hidden={!isCollapsable}
							role="button"
							data-testid={`accordion-title-section-${testId}-${index}`}
						>
							<div data-testid={`accordion-container-text-${testId}`} className={styles.containerText}>
								<div
									data-testid={'title-text-' + testId}
									className={cx(
										styles.titleText,
										{ [styles.paddingLeftZero]: children },
										children
											? children['props']?.checked
												? styles.textStateSelected
												: styles.textStateInitial
											: ''
									)}
								>
									{title}
								</div>
								{extraDataTitle !== '' && (
									<div
										data-testid={`accordion-extra-title-${testId}`}
										className={cx(styles.extraDataTitle, { [styles.textStateInitial]: children })}
									>
										{extraDataTitle}
									</div>
								)}
							</div>
							<div
								data-testid={`accordion-container-right-section-${testId}`}
								className={styles.containerRightSection}
							>
								<div data-testid={`accordion-right-section-${testId}`} className={styles.rightSection}>
									{additionalText !== undefined &&
										(typeof additionalText === 'string' ? (
											(stickyAdditionalText || !isOpen) && (
												<div data-testid="additional-text" className={styles.rightText}>
													{additionalText}
												</div>
											)
										) : isOpen ? (
											<div data-testid="text-expanded" className={styles.rightText}>
												{additionalText.textExpanded}
											</div>
										) : (
											<div data-testid="text-collapsed" className={styles.rightText}>
												{additionalText.textCollapsed}
											</div>
										))}
									{isCollapsable && (
										<div
											className={isOpen ? styles.bottomArrow : styles.rightArrow}
											data-testid={`${testId}-arrow`}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	)
}
export default AccordionHeader
