import { FC, useEffect, useState } from 'react'
import { useSource } from '../../queries/useSource'
import { DataUpdatePanel } from './components/SourceRefreshRematchOnDemand/data-update-panel'
import styles from './source-settings-on-demand.module.scss'

interface SourceSettingsProps {
	sourceId: string
	projectId: string
}

export const SourceSettingsOnDemand: FC<SourceSettingsProps> = ({ sourceId, projectId }: SourceSettingsProps) => {
	const sourceQuery = useSource(sourceId, true)
	const [isSalesforceSource, setSalesforceSource] = useState<boolean>(false)

	useEffect(() => {
		if (sourceQuery.isSuccess && sourceQuery.isFetched) {
			setSalesforceSource(sourceQuery.data?.integrationInfo?.integrationType === 'Salesforce')
		}
		/**
		 * We only want to run this effect when the flag isFetching from sourceQuery changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceQuery.isFetching])

	return isSalesforceSource ? (
		<div className={styles.sourceSettingsOnDemand}>
			<DataUpdatePanel sourceId={sourceId} projectId={projectId} />
		</div>
	) : null
}
