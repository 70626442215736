import { ColorBlueBrand } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { recordSortBy } from '../../../components/company-list-manager/company-list-manager'
import { CompanyListToolBar } from '../../../components/company-list-manager/company-list-tool-bar'
import { FilterSummary } from '../../../components/filter-summary/filter-summary'
import { FooterButtons } from '../../../components/footer-buttons/footer-buttons'
import { PaginationButtons } from '../../../components/pagination-buttons/pagination-buttons'
import { TableCompare } from '../../../components/table-compare/table-compare'
import { Icon, LoadingState } from '../../../local-core-ui'
import { RootState, useAppDispatch, useAppSelector } from '../../../store'
import { setSelectedRecords } from '../../../store/steward/stewardSlice'
import { paginateOverRecords } from '../../../store/steward/thunks'
import { PageSize, TileTypes } from '../../../store/steward/types'
import styles from '../dashboard-steward.module.scss'
import { getIsAssignAvailable, getPrimaryBtn } from '../footer-buttons-conditions'

interface StewardTableViewProps {
	onCardView(): void
	isStewardFilterTabsAvailable: boolean
	recordListSortBy?: recordSortBy
	onRecordListSortByChange(sortBy: recordSortBy): void
	onAssign(): void
	selectedTile: TileTypes
	onMultiSelectToggle(): void
	isMultiSelectActive: boolean
}

export function StewardTableView({
	onCardView,
	isStewardFilterTabsAvailable,
	recordListSortBy,
	onRecordListSortByChange,
	onAssign,
	selectedTile,
	onMultiSelectToggle,
	isMultiSelectActive
}: StewardTableViewProps): ReactElement {
	const dispatch = useAppDispatch()
	const selectSteward = (state: RootState) => state.steward
	const stewardState = useAppSelector(selectSteward)
	const [isAssignAvailable, setIsAssignAvailable] = useState<boolean>(false)
	const [isInsightsOn, setIsInsightsOn] = useState<boolean>(false)
	const [selectAll, setSelectAll] = useState<boolean>(false)
	const tableViewContainer = useRef<HTMLDivElement>(null)
	const requestedClassification = stewardState.requestedClassification

	const handleCancel = () => {
		if (isMultiSelectActive) onMultiSelectToggle()
		dispatch(setSelectedRecords([]))
	}

	const scrollToTop = () => {
		window.scrollTo({ top: tableViewContainer.current?.offsetTop || 0, behavior: 'smooth' })
	}

	const handleNext = () => {
		dispatch(paginateOverRecords(stewardState.pageIndex + 1))
		scrollToTop()
		if (!isMultiSelectActive) {
			dispatch(setSelectedRecords([]))
		}
	}

	const handlePrevious = () => {
		dispatch(paginateOverRecords(stewardState.pageIndex - 1))
		scrollToTop()
		if (!isMultiSelectActive) {
			dispatch(setSelectedRecords([]))
		}
	}

	const handlePageSizeChange = (newPageSize: PageSize) => {
		dispatch(paginateOverRecords(1, newPageSize))
	}

	const handleRecordCheck = (recordId: string, checked: boolean) => {
		const myRecord = stewardState.records?.find((r) => r.recordId === recordId)
		if (isMultiSelectActive) {
			if (myRecord && checked) {
				const newRecords = [...(stewardState.selectedRecords || [])]
				newRecords.push(myRecord)
				dispatch(setSelectedRecords(newRecords))
			} else if (myRecord) {
				const newRecords = [...(stewardState.selectedRecords || [])]
				const recordIdx = newRecords.findIndex((r) => r.recordId === recordId)
				if (recordIdx !== -1) {
					newRecords.splice(recordIdx, 1)
					dispatch(setSelectedRecords(newRecords))
				}
			}
		} else {
			if (myRecord && checked) {
				dispatch(setSelectedRecords([myRecord]))
			} else {
				dispatch(setSelectedRecords([]))
			}
		}
	}

	useEffect(() => {
		if (stewardState.selectedRecords && stewardState.selectedRecords.length > 0 && !isAssignAvailable) {
			setIsAssignAvailable(true)
		} else if ((!stewardState.selectedRecords || stewardState.selectedRecords.length === 0) && isAssignAvailable) {
			setIsAssignAvailable(false)
		}
	}, [stewardState.selectedRecords, isAssignAvailable])

	useEffect(() => {
		dispatch(setSelectedRecords([]))
		if (isMultiSelectActive) onMultiSelectToggle()
		/**
		 * We only want to run this effect when forTile, forAssignment or requestedClassification properties from stewardState changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stewardState.forTile, stewardState.forAssignment, stewardState.requestedClassification])

	if (stewardState.records)
		return (
			<div ref={tableViewContainer} className={styles.dashboardStewardTableView}>
				<div className={styles.dashboardStewardHeaderBar}>
					<FilterSummary
						filters={[]}
						onAddFilterClicked={() => console.log('add')}
						onMoreClicked={() => 'remove'}
						onRemovedFilter={() => console.log('remove')}
						testId="stewardship-table-filters"
					/>
					{!isStewardFilterTabsAvailable && (
						<div className={styles.dashboardStewardIconsViewContainer}>
							<div className={styles.dashboardStewardIconContainer}>
								<Icon testId="table-view-table-compo" type="table-view" color={ColorBlueBrand} />
							</div>
							<button className={styles.dashboardStewardIconBtnContainer} onClick={onCardView}>
								<Icon testId="card-view-table-compo" type="card-view" />
							</button>
						</div>
					)}
				</div>
				<div className={styles.dashboardStewardTableViewToolBar}>
					<CompanyListToolBar
						isMultiSelectActive={isMultiSelectActive}
						onToggleMultiSelect={onMultiSelectToggle}
						currentSorting={recordListSortBy}
						onSortChange={onRecordListSortByChange}
						isInsightsOn={isInsightsOn}
						onInsightsChange={(event) => setIsInsightsOn(event.target.checked)}
						isSelectAllChecked={selectAll}
						onSelectAllChange={(event) => setSelectAll(event.target.checked)}
					/>
				</div>
				<TableCompare
					data={stewardState.records}
					isMultiSelectActive={isMultiSelectActive}
					onRecordCheck={handleRecordCheck}
					selectedRecords={stewardState.selectedRecords.map((r) => r.recordId)}
				/>
				<div className={styles.dashboardStewardPaginationContainer}>
					<PaginationButtons
						onNext={handleNext}
						onPrevious={handlePrevious}
						pageSize={stewardState.pageSize}
						onPageSizeChange={handlePageSizeChange}
						listLength={stewardState.total}
						showBtnTxt
						currentPage={stewardState.pageIndex}
					/>
				</div>
				<FooterButtons
					primaryBtn={getPrimaryBtn(selectedTile, requestedClassification)}
					justifyContent="flex-end"
					onAssign={getIsAssignAvailable(stewardState.selectedRecords, selectedTile, onAssign)}
					onCancel={isMultiSelectActive ? handleCancel : undefined}
				/>
			</div>
		)
	else
		return (
			<div className={styles.dashboardStewardTableViewLoader}>
				<LoadingState />
			</div>
		)
}
