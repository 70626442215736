import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { ConnectionStatus as StatusType } from '../../queries/api/getC4SActiveConnection'
import { Spinner } from '../spinner/spinner'
import styles from './connection-status.module.scss'

interface ConnectionStatusProps {
	connectionStatus: StatusType
	isStatusLoading: boolean
}

export const ConnectionStatus = ({ connectionStatus, isStatusLoading }: ConnectionStatusProps): ReactElement => {
	const { t } = useTranslation()

	const getStatusContainer = (status: StatusType): string => {
		if (isStatusLoading) return styles.loading
		switch (status) {
			case StatusType.Ready:
				return styles.connected
			case StatusType.Unknown:
				return styles.unhealthy
			case StatusType.Failed:
				return styles.disconnected
			default:
				return styles.disconnected
		}
	}

	const getStatusText = (status: StatusType): ReactElement => {
		switch (status) {
			case StatusType.Ready:
				return <div className={styles.statusConnected}>{t('connection.status.connected')}</div>
			case StatusType.Unknown:
				return <div className={styles.statusUnhealthy}>{t('connection.status.unhealthy')}</div>
			case StatusType.Failed:
				return <div className={styles.statusDisconnected}>{t('connection.status.no.connection')}</div>
			default:
				return <div className={styles.statusDisconnected}>{t('connection.status.no.connection')}</div>
		}
	}

	const getLoadingText = (): ReactElement => {
		return (
			<div className={styles.container}>
				<Spinner smallSized={true} />
				<div className={styles.statusLoading}>{t('connection.status.loading')}</div>
			</div>
		)
	}

	return (
		<div className={`${styles.container} ${getStatusContainer(connectionStatus)}`} data-testid="connection-status">
			{isStatusLoading ? getLoadingText() : getStatusText(connectionStatus)}
		</div>
	)
}
