import { DNBTextField } from '@dnb-uux-design-system/react'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './manual-match-string-input.module.scss'

interface ManualMatchStringInputI {
	value: string
	onChange(matchString: string): void
	error?: boolean
	testId: string
}

export const ManualMatchStringInput = ({
	value,
	onChange,
	error = false,
	testId
}: ManualMatchStringInputI): ReactElement => {
	const matchGrade = RegExp(/^(|[ABFZ.]{0,11})$/)
	const { t } = useTranslation()

	const onInputChange = (newValue: string) => {
		const newMatchString = newValue.toUpperCase()
		if (matchGrade.test(newMatchString)) {
			onChange(newMatchString)
		}
	}

	return (
		<div className={styles.manualMatchStringInput}>
			<DNBTextField
				data-testid={testId + '-match-string'}
				error={error}
				fullWidth
				helperText={t('manual.match.string.input.helper')}
				id="manual-match-string-input"
				label={t('manual.match.string.input.label')}
				onChange={(event) => onInputChange(event.target.value)}
				placeholder={t('manual.match.string.input.hint') ?? undefined}
				value={value}
				size="compact"
			/>
		</div>
	)
}
