import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useApi } from '../../../hooks'
import { useFeatures, useModules, usePlatform } from '../../../hooks/useEntitlements'
import { getDataBlocksEntitlements } from '../../../queries/api/getDataBlocksEntitlements'
import { RootState, useAppDispatch, useAppSelector } from '../../../store'
import { PurposeOfUse } from '../../../types'
import { EntityType } from '../../../types/sources/EntityType'
import { DynamicWizardContext } from '../helpers/DynamicWizardContext'
import { WizardCache } from '../helpers/WizardCache'
import { WizardContext } from '../helpers/WizardContext'
import { setDynamicWizardContext } from '../store/wizardSlice'

export const useWizardContext = (): {
	wizardContext: WizardContext
	dynamicWizardContext: DynamicWizardContext
} => {
	const apiClient = useApi()
	const context = new WizardContext(
		useModules,
		useFeatures,
		usePlatform,
		useAppSelector,
		useAppDispatch,
		useQueryClient,
		useTranslation,
		apiClient
	)
	// the use of Redux will trigger a refresh on set (due to the async nature of the mechanism to get these values)
	const selectWizard = (state: RootState) => state.wizard
	const wizardState = useAppSelector(selectWizard)
	const dispatch = useAppDispatch()

	useEffect(() => {
		const isEntitledToContactMatch = async (purposeOfUse: PurposeOfUse) =>
			getDataBlocksEntitlements(apiClient, purposeOfUse, EntityType.CONTACTS)
				.then((domains) => domains.find((domain) => domain.domain === purposeOfUse.domain))
				.then((domain) => {
					const entitledBlocks = domain?.recordTypes[purposeOfUse.recordType]
					return (
						entitledBlocks != undefined &&
						entitledBlocks.findIndex((entitledBlock) => entitledBlock.blockId === 'contacts') > -1
					)
				})

		// ContactMatch Validation
		const isNotHomePage = !window.location.href.includes('dashboard/overview')
		if (context.enableContactMatch && isNotHomePage) {
			const purposeOfUse = context.projectWizardState.currentProject.purposeOfUse
			if (purposeOfUse && purposeOfUse.domain && purposeOfUse.recordType) {
				WizardCache.getInstance()
					.query(`isEntitledToContactOn-${purposeOfUse.domain}-${purposeOfUse.recordType}`, () =>
						isEntitledToContactMatch(purposeOfUse)
					)
					.then((isEntitled) => {
						dispatch(setDynamicWizardContext(new DynamicWizardContext(isEntitled as boolean)))
					})
			} else {
				dispatch(setDynamicWizardContext(new DynamicWizardContext(false)))
			}
		} else {
			dispatch(setDynamicWizardContext(new DynamicWizardContext(false)))
		}
		/**
		 * We only want to run this effect when the domain or the recordType from purposeOfUse in the current project changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		context.projectWizardState.currentProject.purposeOfUse.domain,
		context.projectWizardState.currentProject.purposeOfUse.recordType
	])

	return { wizardContext: context, dynamicWizardContext: wizardState.dynamicWizardContext }
}
