import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { ToolTip } from '../../local-core-ui'
import { UserDetail } from '../../types'
import { TemplateTileContainer } from '../template-tile-container/template-tile-container'
import styles from './enrichment-template-tile.module.scss'

interface EnrichmentTemplateTileProps {
	name: string
	purposeOfUse: { domain: string; recordType: string }
	createdBy: UserDetail
	date: number
	numberOfElements: number
	numberOfDataBlocks: number
	disabled?: boolean
	isOwnerTemplate?: boolean
	functionApplyTemplate(templateId: string): void
	functionPreviewTemplate(templateId: string): void
	functionRemoveTemplate?(templateId: string, name: string): void
	testId: string
	templateId: string
	flipped?: boolean
	dataBlockDetails?: string[]
}

export function EnrichmentTemplateTile({
	name,
	purposeOfUse,
	createdBy,
	date,
	numberOfElements,
	numberOfDataBlocks,
	disabled,
	testId,
	isOwnerTemplate = false,
	functionApplyTemplate,
	functionPreviewTemplate,
	functionRemoveTemplate,
	templateId,
	flipped,
	dataBlockDetails
}: EnrichmentTemplateTileProps): ReactElement {
	const { t } = useTranslation()
	const dnbOwnedId = 'Dun & Bradstreet'
	const dnbOwned = createdBy.id === dnbOwnedId

	const shortenDataBlockDetails = (dataBlockDetails: string[]): string[] => {
		//TODO (aramirez): rework this to be done with CSS only, wasn't able to make 'text-overflow: ellipsis' work for some reason
		const shortenedDataBlocks = [...dataBlockDetails]
		if (shortenedDataBlocks.length > 3) {
			shortenedDataBlocks[2] += '...'
			return shortenedDataBlocks.slice(0, 3)
		}
		return shortenedDataBlocks
	}

	return (
		<TemplateTileContainer
			name={name}
			createdBy={createdBy}
			date={date}
			canRemove={isOwnerTemplate}
			testId={testId}
			templateId={templateId}
			functionApplyTemplate={functionApplyTemplate}
			functionPreviewTemplate={functionPreviewTemplate}
			functionRemoveTemplate={functionRemoveTemplate}
			disabled={disabled}
			flipped={flipped}
			dnbOwned={dnbOwned}
		>
			<div className={styles.enrichmentTemplateTileContainer}>
				<div className={styles.enrichmentTemplateTileDetail}>
					<div className={`${styles.commonSection} ${styles.purposeOfUseSection}`}>
						<div className={`${styles.commonInfo} ${styles.commonInfoFirstLine}`}>
							{t('purpose.of.use')}
						</div>
						{purposeOfUse.domain && (
							<>
								<div className={`${styles.commonInfo} ${styles.commonInfoSecondLine}`}>
									{purposeOfUse.domain}
								</div>
								<div className={`${styles.commonInfo} ${styles.commonInfoThirdLine}`}>
									{purposeOfUse.recordType}
								</div>
							</>
						)}
						{!purposeOfUse.domain && (
							<div className={`${styles.commonInfo} ${styles.commonInfoSecondLine}`}>
								{t('purpose.of.use.multiple')}
							</div>
						)}
					</div>
					{!purposeOfUse?.domain && (
						<>
							<div className={`${styles.commonSection} ${styles.blockDetailSection}`}>
								<div className={`${styles.commonInfo} ${styles.commonInfoFirstLine}`}>
									{t('includes.elements.from')}
								</div>
								<ToolTip
									testId={`common-info-data-block-tooltip-${testId}`}
									keepTooltipOnMouseOver={true}
									effect="float"
									position="right"
									customContent={
										<div className={styles.blockDetailTooltip}>
											<div className={styles.blockDetailTooltipTitle}>
												{t('includes.elements.from')}
											</div>
											{dataBlockDetails &&
												dataBlockDetails.map((blockDetail, key) => {
													return <div key={key}>{blockDetail}</div>
												})}
										</div>
									}
								>
									<div className={`${styles.commonInfo} ${styles.blockDetailDescription}`}>
										{dataBlockDetails &&
											shortenDataBlockDetails(dataBlockDetails).map((blockDetail, key) => {
												return <div key={key}>{blockDetail}</div>
											})}
									</div>
								</ToolTip>
							</div>
						</>
					)}
					<div className={styles.commonSection}>
						<div className={styles.blockElementSection}>
							<div className={styles.leftNumber}>
								<div className={styles.bigNumber}>{numberOfElements}</div>
								<div className={styles.legend}>{t('elements')}</div>
							</div>
							<div className={styles.lineSeparator} />
							<div className={styles.rightNumber}>
								<div className={styles.bigNumber}>{numberOfDataBlocks}</div>
								<div className={styles.legend}>{t('data.blocks')}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</TemplateTileContainer>
	)
}
