import { AxiosInstance, AxiosResponse } from 'axios'
import { Connection } from './getConnections'

export const getConnectionsStatus = async (
	apiClient: AxiosInstance,
	connectionNames: string[]
): Promise<Connection[]> => {
	const url = `/pls/integration/connection/status`
	return apiClient
		.post(url, { connectionNames: connectionNames })
		.then((response: AxiosResponse<Connection[]>) => response.data)
}
