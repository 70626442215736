import { FC, ReactNode } from 'react'

interface CenterPanelProps {
	children?: ReactNode
}

export const CenterPanel: FC<CenterPanelProps> = ({ children }: CenterPanelProps) => {
	return (
		<div
			style={{
				height: '100vh',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			{children}
		</div>
	)
}
