import { AxiosInstance, AxiosResponse } from 'axios'
import { SourceUIFacade } from '../../dashboard/SourceUIFacade'
import { GetSourceResponse } from '../../types'

export const getSource = async (
	apiClient: AxiosInstance,
	sourceId: string,
	inflateMetadata = false
): Promise<SourceUIFacade> => {
	const url = `/pls/sources/sourceId/${sourceId}?inflateMetadata=${inflateMetadata}`
	return apiClient.get(url).then((sourceResponse: AxiosResponse<GetSourceResponse>) => {
		return {
			...sourceResponse.data
		} as SourceUIFacade
	})
}
