import { ColorWhite } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement, Ref, useCallback, useEffect, useRef } from 'react'
import { Icon } from '../../local-core-ui'
import { ExpandedInput } from '../expanded-input/expanded-input'
import styles from './expanded-search-button-n-input.module.scss'

export interface ISearchButtonNInputProps {
	value: string
	hint: string
	onClick?(): void
	onChangeFilter: (value: string) => void
	onBlurFunction?: (value: string) => void
	collapsed?: boolean
	buttonPosition?: 'Left' | 'Right'
	showButtonAsIcon?: boolean
	testId: string
}

export const ExpandedSearchButtonNInput = ({
	value,
	hint,
	onClick,
	onChangeFilter,
	onBlurFunction,
	collapsed,
	buttonPosition = 'Left',
	showButtonAsIcon = false,
	testId
}: ISearchButtonNInputProps): ReactElement => {
	const inputRef: Ref<HTMLInputElement> = useRef(null)

	const onChangeInput = (newValue: string) => {
		if (!newValue) {
			focusInput()
		}
		onChangeFilter(newValue)
	}

	const handleIconClick = () => {
		if (onClick) onClick()
	}

	const focusInput = useCallback(() => {
		if (!collapsed) {
			if (inputRef.current) {
				const inputElement = inputRef.current
				inputElement.focus()
			}
		}
	}, [collapsed])

	useEffect(() => {
		focusInput()
	}, [collapsed, focusInput])

	return (
		<div className={styles.expandedSearchNButtonInput}>
			{collapsed ? (
				<div className={`${styles.buttonSearch} ${styles[`buttonPosition${buttonPosition}`]}`}>
					<div className={styles.iconSearch} onClick={() => handleIconClick()} data-testid={testId + '-icon'}>
						<Icon testId="search-expanded-search-button-collapsed" type="search" color={ColorWhite} />
					</div>
				</div>
			) : (
				<div className={styles.inputNButtonContainer}>
					<div className={styles.searchInput}>
						<ExpandedInput
							id="search-user"
							ref={inputRef}
							hint={hint}
							value={value}
							onChangeFunction={(changedValue: string) => onChangeInput(changedValue)}
							testId={testId}
							searchIcon={showButtonAsIcon}
							onBlurFunction={(newValue: string) => {
								if (onBlurFunction) {
									onBlurFunction(newValue)
								}
								if (!newValue && onClick) {
									onClick()
								}
							}}
						/>
					</div>
					{!showButtonAsIcon && (
						<div className={`${styles.buttonSearch} ${styles.extended}}`}>
							<div className={styles.iconSearch} onClick={() => handleIconClick()}>
								<Icon testId="search-expanded-search-button" type="search" color={ColorWhite} />
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	)
}
