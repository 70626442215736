import { DNBBanner, DNBCheckbox, DNBIconButton } from '@dnb-uux-design-system/react'
import { toNumber } from 'lodash-es'
import { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../../local-core-ui'
import { agingBucketObject, ElementUIFacade } from '../ElementUIFacade'
import styles from './checkbox-classifications.module.scss'

interface checkboxClassificationsProps {
	element: ElementUIFacade
	selectedElementList: Array<ElementUIFacade>
	onDropSelectorChange?: (newSelectedElements: Array<ElementUIFacade>) => void
	counterColumns: number
}

export const CheckboxClassifications = ({
	element,
	onDropSelectorChange,
	selectedElementList,
	counterColumns
}: checkboxClassificationsProps): ReactElement => {
	const { t } = useTranslation()
	const [openWarning, setOpenWarning] = useState(false)
	const classifValuesRef = useRef<Array<agingBucketObject>>([])
	const classifSelected2TriggerAlert = 20

	const isClassificationSelected = (classif: agingBucketObject) => {
		return (
			selectedElementList.find((selectedElement) => selectedElement.elementId.startsWith(classif.value)) !=
				undefined || false
		)
	}

	if (element.classifications && element.classifications?.length > 0) {
		classifValuesRef.current = element.classifications
		classifValuesRef.current.forEach((classif) => {
			classif.isChecked = isClassificationSelected(classif)
		})
	}

	useEffect(() => {
		const counterClassifSelected = classifValuesRef.current.filter((classifValue) => classifValue.isChecked).length
		if (counterClassifSelected === classifSelected2TriggerAlert) {
			setOpenWarning(true)
		} else {
			setOpenWarning(false)
		}
	}, [selectedElementList])

	const updateChildrenByClassification = (classifId: string): Array<ElementUIFacade> => {
		const newSelectedElements: Array<ElementUIFacade> = [...selectedElementList]
		const childrenSelectorValueRef = element.maximumElementsInOutput
		if (childrenSelectorValueRef) {
			const blockChildren = element.childs || []
			blockChildren.forEach((blockChild) => {
				blockChild.childs?.forEach((elem) => {
					if (elem.elementId.startsWith(classifId)) {
						const isCheckedClassif = classifValuesRef.current.find(
							(classifValue: agingBucketObject) => classifValue.id === classifId
						)?.isChecked
						const splitElement = elem.elementId.split('_')
						const providerElem = toNumber(splitElement[splitElement.length - 1])
						//Search number of provider
						if (providerElem <= toNumber(childrenSelectorValueRef.current)) {
							const selectedElementIndex = newSelectedElements.findIndex(
								(currentSelectedElement) => currentSelectedElement.elementId === elem.elementId
							)
							if (isCheckedClassif) {
								const subElement = elem.elementId
									.slice(0, elem.elementId.lastIndexOf('_'))
									.split(classifId)[1]
								const isSubelementSelected = newSelectedElements.some((currentSelectedElem) =>
									currentSelectedElem.elementId.includes('_' + subElement + '_1')
								)
								if (
									selectedElementIndex < 0 &&
									(isSubelementSelected ||
										classifValuesRef.current.filter((classif) => classif.isChecked).length === 1)
								) {
									newSelectedElements.push(elem)
								}
							} else {
								if (selectedElementIndex >= 0) {
									newSelectedElements.splice(selectedElementIndex, 1)
								}
							}
						}
					} else {
						const classifValue = classifValuesRef.current.find((classifValue: agingBucketObject) =>
							elem.elementId.startsWith(classifValue.id)
						)
						const selectedElementIndex = newSelectedElements.findIndex(
							(currentSelectedElement) => currentSelectedElement.elementId === elem.elementId
						)
						if (classifValue?.isChecked) {
							//Search number of provider
							const splitElemId = elem.elementId.split('_')
							const providerElem = toNumber(splitElemId[splitElemId.length - 1])
							const subElement = elem.elementId
								.slice(0, elem.elementId.lastIndexOf('_'))
								.split(classifId)[1]
							const isSubelementSelected = newSelectedElements.some((currentSelectedElem) =>
								currentSelectedElem.elementId.includes('_' + subElement + '_1')
							)
							const haveSelectedElementDiffProviderIndex = isSubelementSelected
								? newSelectedElements.findIndex((currentSelectedElement) => {
										const elementIDwithoutProvider = ''.concat(
											...splitElemId.map((idPart, idx) => {
												if (idx < 2) {
													return idPart + '_'
												} else return ''
											})
										)
										return (
											currentSelectedElement.elementId !== elem.elementId &&
											currentSelectedElement.elementId.startsWith(elementIDwithoutProvider)
										)
								  })
								: -1
							//It is checked if any sub-element already exists and they are added/removed with respect to the current number of suppliers
							if (selectedElementIndex < 0 && haveSelectedElementDiffProviderIndex >= 0) {
								if (providerElem <= toNumber(childrenSelectorValueRef.current)) {
									newSelectedElements.push(elem)
								}
							} else if (
								selectedElementIndex >= 0 &&
								providerElem > toNumber(childrenSelectorValueRef.current)
							) {
								newSelectedElements.splice(selectedElementIndex, 1)
							}
						} else {
							if (selectedElementIndex >= 0 && classifValue)
								newSelectedElements.splice(selectedElementIndex, 1)
						}
					}
				})
			})
		}
		return newSelectedElements
	}
	const onChangeClassification = (event: ChangeEvent<HTMLInputElement>, classifId: string) => {
		let someClassifSelected = false
		classifValuesRef?.current?.forEach((val) => {
			if (val.value === event.target.id) {
				val.isChecked = event.target.checked
			}
			someClassifSelected = someClassifSelected || val.isChecked
		})
		if (element.maximumElementsInOutput) {
			if (element.maximumElementsInOutput.current === 0) {
				element.maximumElementsInOutput.current = 1
			}
			element.classifications = classifValuesRef?.current
			if (onDropSelectorChange) {
				onDropSelectorChange(updateChildrenByClassification(classifId))
			}
			if (!someClassifSelected) {
				element.maximumElementsInOutput.current = 0
			}
		}
	}

	return (
		<div className={styles.containerGralClassif}>
			<div className={styles.containerHeaderInfo}>
				<div className={styles.titleSection}>{t('title.classifications')}</div>
				<span className={styles.descriptionClassif}>{t('description.classifications')}</span>
			</div>
			{openWarning && (
				<div className={styles.warningContainer}>
					<DNBBanner
						severity={'warning'}
						style={{ height: '57px' }}
						action={
							<DNBIconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => {
									setOpenWarning(false)
								}}
							>
								<div>
									<Icon testId="close-warning-classications" type={'x'} />
								</div>
							</DNBIconButton>
						}
					>
						<p>{t('datablocks.searcher.alert.SECDetailsId', { counterColumns: counterColumns })}</p>
					</DNBBanner>
				</div>
			)}
			<div className={styles.containerCheckboxList}>
				{element.classifications?.map((classif: agingBucketObject, index) => {
					return (
						<DNBCheckbox
							id={classif.id}
							checked={
								classifValuesRef.current.find(
									(classifValue: agingBucketObject) => classifValue.id === classif.id
								)?.isChecked
							}
							label={t(classif.label)}
							indeterminate={false}
							data-testid={classif.id}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								onChangeClassification(e, classif.id)
							}}
							key={`${index}_${classif}`}
							size="small"
						/>
					)
				})}
			</div>
		</div>
	)
}
