import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { GetMatchSummaryCodes } from '../types/match-recommendation/GetMatchRecommendationProfile'
import { getMatchSummaryCodes } from './api/getMatchSummaryCodes'

export const useMatchSummaryCodes = (enableMatchRecommendation?: boolean): UseQueryResult<GetMatchSummaryCodes> => {
	const apiClient = useApi()
	const queryKey = ['getMatchSummaryCodes']
	const queryFunction = () => getMatchSummaryCodes(apiClient, enableMatchRecommendation)
	return useQuery(queryKey, queryFunction)
}
