import { ReactElement, useEffect, useState } from 'react'
import { Avatar, Icon } from '../../local-core-ui'
import styles from './selectable-user-tile.module.scss'

export interface SelectableUserTileProps {
	userId: string
	name: string
	role: string
	onClick(): void
	selected: boolean
	testId: string
}

export const SelectableUserTile = ({
	userId,
	name,
	role,
	onClick,
	selected,
	testId
}: SelectableUserTileProps): ReactElement => {
	const [isSelected, setIsSelected] = useState(selected)
	useEffect(() => {
		setIsSelected(selected)
	}, [selected])

	return (
		<div
			className={`${styles.selectableUserTileContainer} ${isSelected ? styles.selected : ''}`}
			tabIndex={0}
			onClick={() => {
				setIsSelected(!isSelected)
				onClick()
			}}
			data-testid={testId + '-container-' + userId}
			onKeyPress={(e) => {
				if (e.key === 'Enter' || e.key === ' ') {
					setIsSelected(!isSelected)
					onClick()
				}
			}}
		>
			<div className={styles.avatarContainer}>
				{isSelected ? (
					<Icon testId="check-circle-selectable-user-tile" type="check-circle" size="medium" />
				) : (
					<Avatar userData={[{ label: 'Name', value: name || '' }]} testId={testId + '-sut-avatar'} />
				)}
			</div>
			<div className={styles.dataContainer}>
				<div className={styles.name} title={name}>
					{name}
				</div>
				<div className={styles.info}>{role}</div>
			</div>
		</div>
	)
}
