import { Fragment, ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ApiErrorState } from '../../store/apiError'
import styles from './error-modal.module.scss'

interface DevSiteDataVersionProps {
	apiError: ApiErrorState
}

export const DevSiteDataVersion = ({ apiError }: DevSiteDataVersionProps): ReactElement => {
	const { t } = useTranslation()
	return (
		<Fragment>
			{<p className={styles.errorMessageModalErrorMessage}>{t('error.message.modal.version.title')}</p>}

			<div className={styles.errorMessageModalPayloadResponseVersionContainer}>
				<p className={styles.errorMessageModalErrorMessageVersion}>
					<p>{t('error.message.modal.code.version')}</p>
					<p>{t('error.message.modal.version.description.prt1')}</p>
					<p>
						<strong>{t('error.message.modal.version.description.prt2')}</strong>
						<Trans i18nKey="error.message.modal.version.description.prt3">
							Click
							<a
								rel={'noreferrer'}
								className={styles.linkText}
								href={'https://view.highspot.com/viewer/65f99d4d9d681c22a03f089a'}
								download
								data-testid={'sample-link'}
								target={'_blank'}
							/>
						</Trans>
					</p>
				</p>
			</div>
		</Fragment>
	)
}
