import { FC } from 'react'
import languageIcon from './ic_language.png'
import styles from './language-pill.module.scss'

export interface LanguagePillProps {
	language: string
}
export const LanguagePill: FC<LanguagePillProps> = ({ language }) => {
	return (
		<span className={styles.languagePill}>
			<img src={languageIcon} data-testid="language-img" alt="Language selector" />
			<span className={styles.languageElement} data-testid="language-short-text">
				{language}
			</span>
		</span>
	)
}
