import ApartmentIcon from '@mui/icons-material/Apartment'
import ContactsIcon from '@mui/icons-material/Contacts'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { EntityType } from '../../types/sources/EntityType'
import styles from './match-type-card.module.scss'

export interface MatchTypeCardProps {
	entityType: EntityType
	partialMatch?: boolean
}

export const MatchTypeCard: FC<MatchTypeCardProps> = ({ entityType, partialMatch }) => {
	const { t } = useTranslation()

	const contactPieceOrEmpty = entityType === EntityType.CONTACTS ? (partialMatch ? '.enabled' : '.disabled') : ''

	const descriptionKey = entityType
		? `match.type.card.${entityType.toLowerCase()}${contactPieceOrEmpty}.description`
		: ''

	return (
		<div className={styles.card}>
			<h2 className={styles.matchType}>
				{t(`match.type.card.${entityType.toLowerCase()}`)}{' '}
				<span className={styles.icon}>
					{entityType === EntityType.ACCOUNTS ? (
						<ApartmentIcon sx={{ fontSize: 24 }} />
					) : (
						<ContactsIcon sx={{ fontSize: 24 }} />
					)}
				</span>
			</h2>

			{entityType === EntityType.CONTACTS ? (
				<div className={styles.contactSection}>
					<Trans
						i18nKey="match.type.card.company.only"
						values={{ state: t(`match.type.card.is.${partialMatch ? 'enabled' : 'disabled'}`) }}
					/>
				</div>
			) : undefined}
			<span>{t(descriptionKey)}</span>
		</div>
	)
}
