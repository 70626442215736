import { ElementDetail } from '../types'

export const removeFamily = (dataBlockList: ElementDetail[], blockId: string, familyId: string): ElementDetail[] => {
	return dataBlockList.filter((block) => {
		if (block.blockId === blockId) {
			block.levels = block.levels.filter((level) => level.level.levelId !== familyId)
		}
		return true
	})
}
