import { ColorBlueBrandDark } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import cx from 'classnames'
import { FC, useEffect, useRef, useState } from 'react'
import Icon from '../icon/icon'
import colorTokens from '../shared/token-colors.json'
import { GDMTooltip } from './gdm-tooltip'
import styles from './geographical-distribution-map.module.scss'
import { TableKeyBasic } from './table-key-basic'
import { TableKeysWithNames } from './table-keys-with-names'

export interface IDistributionData {
	countryCode: string
	total: number
	percent: number
}

export interface IGDMKeyProps {
	data?: Array<IDistributionData>
	color: string
	translationFunction(i18nKey: string): string
	testId: string
	orientation?: 'horizontal' | 'vertical'
	width?: number
}

export const GDMKey: FC<IGDMKeyProps> = ({
	data = [],
	color,
	translationFunction,
	testId,
	orientation = 'horizontal',
	width = 330
}: IGDMKeyProps) => {
	const body = useRef<HTMLDivElement>(null)
	const orientationHorizontal = orientation === 'horizontal'

	const scrollDistance = 2
	const scrollUp = () => {
		body.current.scrollTop -= scrollDistance
	}
	const scrollDown = () => {
		body.current.scrollTop += scrollDistance
	}

	const [scrollPositionTop, setScrollPositionTop] = useState(true)
	const [scrollPositionBottom, setScrollPositionBottom] = useState(true)
	const [tooltipData, setTooltipData] = useState<IDistributionData>({
		countryCode: '',
		total: 0,
		percent: 0
	})
	const [tooltipVisible, setTooltipVisible] = useState(false)
	const [isMouseDown, setMouseDown] = useState<boolean>(false)
	const [isUpButtonDisabled, setUpButtonDisabled] = useState<boolean>(true)
	const [isDownButtonDisabled, setDownButtonDisabled] = useState<boolean>(false)
	const [scrollDirection, setScrollDirection] = useState('')
	const [positionTopTooltip, setPositionTopTooltip] = useState<number>(0)

	const updateAndShowTooltip = (newData: IDistributionData, elem?: HTMLDivElement) => {
		if (elem) {
			const positionRow = elem.getBoundingClientRect()
			const pos = positionRow.top - body.current?.getBoundingClientRect().top + body.current?.scrollTop
			setPositionTopTooltip(pos)
		}
		setTooltipData(newData)
		setTooltipVisible(true)
	}

	const updateScrollPosition = () => {
		setScrollPositionTop(body.current?.scrollTop === 0)
		setScrollPositionBottom(body.current?.scrollTop + 1 >= body.current?.scrollHeight - body.current?.clientHeight)
	}

	useEffect(() => {
		const scroll = () => {
			if (scrollDirection === 'up') {
				scrollUp()
			} else if (scrollDirection === 'down') {
				scrollDown()
			}
		}

		if (isMouseDown) {
			const interval = setInterval(scroll, 8)
			return () => clearInterval(interval)
		}
	}, [isMouseDown])

	useEffect(() => {
		updateScrollPosition()
	}, [body])

	useEffect(() => {
		setDownButtonDisabled(scrollPositionBottom)
		setUpButtonDisabled(scrollPositionTop)
		if ((scrollPositionBottom && scrollDirection === 'down') || (scrollPositionTop && scrollDirection === 'up')) {
			setMouseDown(false)
		}
	}, [scrollDirection, scrollPositionTop, scrollPositionBottom])

	return (
		<div
			data-testid={`gdm-key-container-${testId}`}
			className={cx(styles.gdmKey, { [styles.centerKey]: !orientationHorizontal })}
			style={{
				height: !orientationHorizontal ? 'calc(100% - ' + ((width - 78) * 0.65 + 75) + 'px)' : 'auto'
			}}
		>
			{orientationHorizontal && <div data-testid={`gdm-key-flexed-${testId}`} className={styles.flexed} />}
			{!(scrollPositionBottom && scrollPositionTop) && orientationHorizontal ? (
				<button
					className={styles.arrowButtonContainer}
					onClick={() => scrollUp()}
					onMouseDown={() => {
						setScrollDirection('up')
						setMouseDown(true)
					}}
					onMouseUp={() => {
						setScrollDirection('')
						setMouseDown(false)
					}}
					onMouseLeave={() => {
						setScrollDirection('')
						setMouseDown(false)
					}}
					disabled={isUpButtonDisabled}
					data-testid={testId + '-up'}
				>
					<span data-testid={`gdm-icon-wrapper-${testId}`} className={styles.iconWrapper}>
						<Icon
							type="arrow-up"
							size={'mini'}
							color={`${isUpButtonDisabled ? colorTokens.ColorGray : ''} ${
								isMouseDown && scrollDirection === 'up' ? colorTokens.ColorBlueBrand : ''
							}`}
							testId={`gdm-arrow-up-${testId}`}
						/>
					</span>
				</button>
			) : (
				''
			)}
			<div
				data-testid={`gdm-table-scroll-${testId}`}
				className={cx(styles.keyBody, {
					[styles.containerTableBottom]: !orientationHorizontal,
					[styles.noData]: data?.length === 0 && !orientationHorizontal
				})}
				style={{ width: `${!orientationHorizontal ? width + 'px' : '100%'}` }}
				ref={body}
				onScroll={updateScrollPosition}
			>
				{orientationHorizontal ? (
					<>
						{data
							.sort((a, b) => (a.percent <= b.percent ? 1 : -1))
							.map((country: IDistributionData) => {
								return (
									<>
										<TableKeyBasic
											country={country}
											updateAndShowTooltip={updateAndShowTooltip}
											onChangeVisibleTooltip={(isTooltipVisible: boolean) =>
												setTooltipVisible(isTooltipVisible)
											}
											color={color}
											testId={testId + '-' + country.countryCode}
										/>
									</>
								)
							})}
					</>
				) : data?.length > 0 ? (
					<TableKeysWithNames
						color={color}
						data={data}
						updateAndShowTooltip={updateAndShowTooltip}
						onChangeVisibleTooltip={(isTooltipVisible: boolean) => setTooltipVisible(isTooltipVisible)}
						testId={testId + '-bottomKeys'}
						translationFunction={translationFunction}
					/>
				) : (
					<div className={styles.informationNoData}>
						<div className={styles.containerIcon}>
							<Icon type="notification" size="mini" color={ColorBlueBrandDark} />
						</div>
						{translationFunction
							? translationFunction('table.keys.no.countries')
							: 'No information available'}
					</div>
				)}
			</div>
			{!(scrollPositionBottom && scrollPositionTop) && orientationHorizontal ? (
				<button
					className={styles.arrowButtonContainer}
					onClick={scrollDown}
					onMouseDown={() => {
						setScrollDirection('down')
						setMouseDown(true)
					}}
					onMouseUp={() => {
						setScrollDirection('')
						setMouseDown(false)
					}}
					onMouseLeave={() => {
						setScrollDirection('')
						setMouseDown(false)
					}}
					disabled={isDownButtonDisabled}
					data-testid={testId + '-down'}
				>
					<span data-testid={`gdm-icon-wrapper-${testId}`} className={styles.iconWrapper}>
						<Icon
							type="arrow-down"
							size={'mini'}
							color={`${isDownButtonDisabled ? colorTokens.ColorGray : ''} ${
								isMouseDown && scrollDirection === 'down' ? colorTokens.ColorBlueBrand : ''
							}`}
							testId={`gdm-key-arrow-down-${testId}`}
						/>
					</span>
				</button>
			) : (
				''
			)}
			{orientationHorizontal && <div data-testid={`gdm-key-flexed-second-${testId}`} className={styles.flexed} />}
			<GDMTooltip
				testId={`gdm-tooltip-${testId}`}
				data={tooltipData}
				visible={tooltipVisible}
				translationFunction={translationFunction}
				positionTop={orientationHorizontal ? '50%' : positionTopTooltip + 'px'}
			/>
		</div>
	)
}
