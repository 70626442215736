import { ElementUIFacade } from '../../components/data-block-searcher/ElementUIFacade'
import { EnrichingBlock } from '../../store/projectWizard/types'
import { Element, ElementDetail } from '../../types'
import { FieldUsage } from '../../types/enrichment-layouts/FieldUsage'
import { CustomObjectUsage } from '../../types/integration/CustomObjectUsage'

export const calculateUsage = (
	apiLimits: CustomObjectUsage,
	enrichmentLayout: Array<EnrichingBlock>,
	flattenedSelectedElements?: Array<ElementUIFacade>,
	thisBlock?: ElementDetail,
	removedElements?: Array<Element>
): FieldUsage => {
	//removing CER and Contact fields from the custom field count
	const filteredEnrichmentLayout = enrichmentLayout.filter(
		(field) => field.blockId !== 'entityresolution' && field.blockId !== 'contacts'
	)
	const currentlySelectedFields = filteredEnrichmentLayout.reduce((result, block) => {
		//If viewing a block, don't consider those fields as they should come from flattenedSelectedElements instead
		if (thisBlock?.blockId !== block.blockId)
			result.push(
				...block.elements.reduce((elements, element) => {
					elements.push(element.elementId)
					return elements
				}, [] as Array<string>)
			)
		return result
	}, [] as Array<string>)

	//Fields in the current view of the data block searcher are stored in flattenedSelectedElements (Enrichment Layout is only updated after clicking Done)
	if (
		flattenedSelectedElements &&
		flattenedSelectedElements.length > 0 &&
		thisBlock?.blockId !== 'contacts' &&
		thisBlock?.blockId !== 'entityresolution'
	)
		currentlySelectedFields.push(
			...flattenedSelectedElements
				.filter(
					(field) =>
						!currentlySelectedFields.includes(field.elementId) &&
						field.blockId !== 'entityresolution' &&
						field.blockId !== 'contacts'
				)
				.map((field) => field.elementId)
		)

	const fieldLimit = apiLimits.customObjectLimits.maxFields
	const currentObjectAvailability = fieldLimit - apiLimits.customObjectLimits.availableFields
	const existingFields = apiLimits.companyProfileFields

	const newFields = currentlySelectedFields.filter((currentField) => {
		return (
			!existingFields.includes(currentField) &&
			(!removedElements || !removedElements.some((removed) => removed.elementId === currentField))
		)
	})

	const fieldCountDifference = fieldLimit - existingFields.length - newFields.length

	const remainingFieldCount = fieldCountDifference < 0 ? 0 : fieldCountDifference

	return {
		fieldLimit: fieldLimit,
		existingFields: currentObjectAvailability,
		newFields: newFields.length,
		remainingFields: remainingFieldCount
	}
}
