import { ReactElement, useEffect, useRef } from 'react'
import { numberDelimeters } from '../../helpers'
import {
	ChangeRecordsToStewardClassificationParams,
	useChangeRecordsToStewardClassification
} from '../../queries/useChangeRecordsToStewardClassification'
import {
	ChangeRecordsToStewardStatusParams,
	useChangeRecordsToStewardStatus
} from '../../queries/useChangeRecordsToStewardStatus'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import { setSelectedRecords } from '../../store/steward/stewardSlice'
import { RecordClassification } from '../../types'
import { ConditionalBtn } from './conditionalBtn'
import styles from './footer-buttons.module.scss'

export type btnOptions =
	| 'showRecords'
	| 'assign'
	| 'download'
	| 'reject'
	| 'accept'
	| 'cancel'
	| 'moveToReview'
	| 'commit'
	| 'uncommit'

interface FooterButtonsProps {
	onShowRecords?(): void
	onAssign?(): void
	onDownload?(): void
	onCancel?(): void
	recordsCount?: number
	primaryBtn?: btnOptions
	justifyContent?: 'flex-start' | 'center' | 'flex-end'
}

export const FooterButtons = ({
	onShowRecords,
	onAssign,
	onDownload,
	onCancel,
	recordsCount = 0,
	primaryBtn = 'assign',
	justifyContent = 'flex-end'
}: FooterButtonsProps): ReactElement | null => {
	const selectSteward = (state: RootState) => state.steward
	const steward = useAppSelector(selectSteward)

	const selectCurrentUser = (state: RootState) => state.session.user?.EmailAddress
	const currentUser = useAppSelector(selectCurrentUser)
	const canUserChangeAssignment = steward.forAssignment && steward.forAssignee === currentUser
	const dispatch = useAppDispatch()

	const btnsContainer = useRef<HTMLDivElement>(null)
	const hasActiveBtn =
		onShowRecords !== undefined ||
		onAssign !== undefined ||
		onDownload !== undefined ||
		canUserChangeAssignment ||
		onCancel !== undefined ||
		steward.forAssignee === currentUser

	const initialBodyHeight = useRef<number>(0)
	const initialYScroll = useRef<number>(0)
	const initialTop = useRef<number>(0)
	const changeRecordsToStewardClassification = useChangeRecordsToStewardClassification()
	const changeRecordsToStewardStatus = useChangeRecordsToStewardStatus()

	const onChangeClassification = (newClassification: RecordClassification) => {
		if (steward.forAssignment) {
			const params: ChangeRecordsToStewardClassificationParams = {
				assignmentId: steward.forAssignment,
				newClassification: newClassification,
				records: steward.selectedRecords
			}
			changeRecordsToStewardClassification.mutate(params)
			dispatch(setSelectedRecords([]))
		}
	}

	const onChangeCommitStatus = (newCommitStatus: boolean) => {
		if (steward.forAssignment) {
			const params: ChangeRecordsToStewardStatusParams = {
				assignmentId: steward.forAssignment,
				newCommitStatus: newCommitStatus,
				records: steward.selectedRecords
			}
			changeRecordsToStewardStatus.mutate(params)
			dispatch(setSelectedRecords([]))
		}
	}

	const onAccept = () => onChangeClassification('ACCEPTED')
	const onReject = () => onChangeClassification('REJECTED')
	const onNeedsReview = () => onChangeClassification('NEEDS_REVIEW')
	const onCommit = () => onChangeCommitStatus(true)
	const onUncommit = () => onChangeCommitStatus(false)

	const setInitialTopPositionOnBody = (footerParent: HTMLElement, body: HTMLElement, footerBtns: HTMLDivElement) => {
		const parentCoords = footerParent.getBoundingClientRect()
		const windowHeight = window.innerHeight
		const hasXBodyScroll = body.offsetWidth < body.scrollWidth
		const hasXContainerScroll = footerParent.offsetWidth !== footerParent.scrollWidth
		const scrollBar = 17
		const initialTopPosition =
			(parentCoords.bottom - windowHeight) * -1 -
			(hasXBodyScroll ? scrollBar : 0) +
			(hasXContainerScroll ? scrollBar : 0)
		footerBtns.style.top = `${initialTopPosition}px`

		initialYScroll.current = window.scrollY
		initialTop.current = initialTopPosition
		initialBodyHeight.current = body.scrollHeight
	}

	const repositionFooterOnBody = (footerParent: HTMLElement, footerBtns: HTMLDivElement) => {
		const scrollTop = window.scrollY - initialYScroll.current
		const bodyHeight = document.body.scrollHeight
		const bodyHeightDiff = bodyHeight - initialBodyHeight.current
		const maxTop = -footerParent.offsetHeight + footerBtns.offsetHeight
		const newTopPosition = Math.max(Math.min(initialTop.current - bodyHeightDiff + scrollTop, 0), maxTop)
		footerBtns.style.top = `${newTopPosition}px`
	}

	useEffect(() => {
		const footerBtns = btnsContainer.current
		if (footerBtns) {
			const footerParent = footerBtns.parentElement
			if (footerParent) {
				const parentHeight = footerParent.clientHeight
				const parentScroll = footerParent.scrollHeight
				const hasScrollOnParent = parentHeight !== parentScroll

				const body = document.body
				const hasScrollOnBody = window.innerHeight < body.scrollHeight

				footerParent.style.position = 'relative'

				if (hasScrollOnParent) {
					const scrollDiff = parentScroll - parentHeight
					const initialPosition = hasScrollOnParent ? scrollDiff * -1 : 0
					footerBtns.style.top = `${initialPosition}px`
					const setNewTopPosition = () => {
						footerBtns.style.top = `${initialPosition + footerParent.scrollTop}px`
					}
					footerParent.addEventListener('scroll', setNewTopPosition)
					return () => {
						footerParent.removeEventListener('scroll', setNewTopPosition)
					}
				} else if (hasScrollOnBody) {
					setInitialTopPositionOnBody(footerParent, body, footerBtns)
					const resizeObserver = new ResizeObserver(() => repositionFooterOnBody(footerParent, footerBtns))
					resizeObserver.observe(document.body)
					window.addEventListener('scroll', () => repositionFooterOnBody(footerParent, footerBtns))
					return () => {
						resizeObserver.unobserve(document.body)
						window.removeEventListener('scroll', () => repositionFooterOnBody(footerParent, footerBtns))
					}
				}
			}
		}
	}, [btnsContainer, hasActiveBtn])

	const canIChangeClassification = () => steward.forAssignee === currentUser && steward.forAssignment !== undefined

	const isRecordChangeable = () =>
		canIChangeClassification() &&
		steward.selectedRecords.some(
			(record) => record.classification !== 'NEEDS_REVIEW' && record.status === 'PROCESSING'
		)
	return hasActiveBtn ? (
		<div ref={btnsContainer} className={styles.footerButtonsContainer} style={{ justifyContent }}>
			<ConditionalBtn
				onClick={onShowRecords}
				isPrimary={primaryBtn === 'showRecords'}
				type="showRecords"
				tValue={numberDelimeters(recordsCount)}
			/>
			<ConditionalBtn onClick={onAssign} isPrimary={primaryBtn === 'assign'} type="assign" />
			<ConditionalBtn
				onClick={onDownload}
				iconType="download"
				isPrimary={primaryBtn === 'download'}
				type="download"
			/>
			<ConditionalBtn
				onClick={onReject}
				iconType="denied"
				isPrimary={primaryBtn === 'reject'}
				type="reject"
				condition={() =>
					canIChangeClassification() &&
					steward.selectedRecords.some(
						(record) => record.classification !== 'REJECTED' && record.status === 'PROCESSING'
					)
				}
			/>
			<ConditionalBtn
				onClick={onAccept}
				iconType="accepted"
				isPrimary={primaryBtn === 'accept'}
				type="accept"
				condition={() =>
					canIChangeClassification() &&
					steward.selectedRecords.some(
						(record) => record.classification !== 'ACCEPTED' && record.status === 'PROCESSING'
					)
				}
			/>
			<ConditionalBtn
				onClick={onNeedsReview}
				iconType="eye"
				isPrimary={primaryBtn === 'moveToReview'}
				type="moveToReview"
				condition={isRecordChangeable}
			/>

			<ConditionalBtn
				onClick={onCommit}
				iconType="download"
				isPrimary={primaryBtn === 'commit'}
				type="commit"
				condition={isRecordChangeable}
			/>
			<ConditionalBtn
				onClick={onUncommit}
				iconType="external-link"
				isPrimary={primaryBtn === 'uncommit'}
				type="uncommit"
				condition={() =>
					canIChangeClassification() &&
					steward.selectedRecords.some(
						(record) => record.classification !== 'NEEDS_REVIEW' && record.status === 'COMMITTED'
					)
				}
			/>
		</div>
	) : null
}
