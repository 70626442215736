import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { CustomObjectUsage } from '../types/integration/CustomObjectUsage'
import { getIntegrationCustomObjectUsage } from './api/getIntegrationCustomObjectUsage'

export const useIntegrationCustomObjectUsage = (
	sourceId: string,
	active: boolean
): UseQueryResult<CustomObjectUsage, unknown> => {
	const apiClient = useApi()

	const queryKey = ['getCustomObjectUsage', sourceId]
	const queryFunction = () => getIntegrationCustomObjectUsage(apiClient, sourceId)
	return useQuery(queryKey, queryFunction, { enabled: !!sourceId && active })
}
