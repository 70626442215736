import { ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ColorGray } from '../../../../design-tokens/build/shared/token-colors.json'
import { usePlatform } from '../../hooks/useEntitlements'
import { useOrgId } from '../../hooks/useOrgId'
import { Button, Divider, Dropdown, DropdownOptions, Modal } from '../../local-core-ui/'
import styles from './salesforce-modal.module.scss'

export enum SalesforceEnvironment {
	Production = 'Production',
	Sandbox = 'Sandbox'
}

interface NewC4SConnectionModalProps {
	open: boolean
	onCloseModal(): void
	onContinue(environment: SalesforceEnvironment): void
	testId: string
}

export const NewC4SConnectionModal = ({
	open,
	onCloseModal,
	onContinue,
	testId
}: NewC4SConnectionModalProps): ReactElement => {
	const { t } = useTranslation()
	const orgId = useOrgId()
	const isC4SPlatform = usePlatform('salesforce')
	const [environment, setEnvironment] = useState(SalesforceEnvironment.Production)
	const environments: Array<DropdownOptions> = [
		{
			label: SalesforceEnvironment.Production,
			value: SalesforceEnvironment.Production
		},
		{
			label: SalesforceEnvironment.Sandbox,
			value: SalesforceEnvironment.Sandbox
		}
	]

	useEffect(() => {
		if (environment === SalesforceEnvironment.Sandbox && open) setEnvironment(SalesforceEnvironment.Production)
		/**
		 * We want to run this effect to keep Production env by default
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open])

	return (
		<Modal open={open} onClose={() => onCloseModal()} testId={testId} showButtonClose={false} fixWidth={false}>
			<div className={styles.mainContainer}>
				<div className={`${styles.titleContainer} ${styles.title}`}>{t('create.salesforce.title')}</div>
				<div className={styles.description}>
					{orgId && isC4SPlatform ? (
						<Trans i18nKey="create.salesforce.line1" tOptions={{ orgId: orgId }} />
					) : (
						<div>
							<div className={styles.descriptionBold}>{t('create.salesforce.line1.1')}</div>
							<div className={styles.description}>{t('create.salesforce.environment')}</div>
							<Dropdown
								id={'region'}
								hint={t('create.snowflake.region.hint')}
								onChangeFunction={(value: SalesforceEnvironment) => {
									setEnvironment(value)
								}}
								label={''}
								options={environments}
								size={'small'}
								selected={environment}
								testId="salesforce-environment"
							/>
						</div>
					)}
				</div>
				<div className={styles.description}>
					<Trans i18nKey="create.salesforce.line2" />
				</div>
			</div>
			<div className={styles.buttonContainer}>
				<Divider color={ColorGray} />
				<div className={styles.buttons}>
					<Button
						size={'small'}
						type={'secondary'}
						text={t('confirmation.modal.cancel')}
						onClick={() => onCloseModal()}
						testId={testId + '-cancel'}
					/>
					<div className={styles.continueButton}>
						<Button
							size={'small'}
							text={t('continue')}
							onClick={() => onContinue(environment)}
							testId={testId + '-continue'}
						/>
					</div>
				</div>
			</div>
		</Modal>
	)
}
