import { isEmpty } from 'lodash-es'
import { PersistedState } from 'redux-persist/es/types'
import { EntityType } from '../../types/sources/EntityType'
import { InitialProjectWizardState as projectWizardInitialState, ProjectWizardState } from './types'

export const projectWizardMigrations = {
	0: (state: PersistedState): PersistedState => {
		if (state) {
			return { ...projectWizardInitialState, _persist: state._persist }
		}
	},
	1: (state: PersistedState): PersistedState => {
		if (state) {
			return { ...projectWizardInitialState, _persist: state._persist }
		}
	},
	2: (state: PersistedState): PersistedState => {
		if (state) {
			const projectWizardState: ProjectWizardState = { ...state } as unknown as ProjectWizardState
			if (projectWizardState.projects && !isEmpty(projectWizardState.projects)) {
				Object.keys(projectWizardState.projects).forEach((projectId) => {
					projectWizardState.projects[projectId] = {
						...projectWizardState.projects[projectId],
						purposeOfUse: projectWizardState.projects[projectId].purposeOfUse
							? projectWizardState.projects[projectId].purposeOfUse
							: { domain: '', recordType: '' },
						enrichingLayout: []
					}
				})
			}
			return { ...state, ...projectWizardState }
		}
	},
	3: (state: PersistedState): PersistedState => {
		if (state) {
			const projectWizardState: ProjectWizardState = { ...state } as unknown as ProjectWizardState
			if (projectWizardState.projects && !isEmpty(projectWizardState.projects)) {
				Object.keys(projectWizardState.projects).forEach((projectId) => {
					const matchingApproach = projectWizardState.projects[projectId].matchingApproach
					projectWizardState.projects[projectId] = {
						...projectWizardState.projects[projectId],
						matchingApproach:
							matchingApproach &&
							((matchingApproach as string) === 'simple-match' ||
								(matchingApproach as string) === 'advanced-match')
								? 'start-from-scratch'
								: projectWizardState.projects[projectId].matchingApproach
					}
				})
			}
			return { ...state, ...projectWizardState }
		}
	},
	4: (state: PersistedState): PersistedState => {
		if (state) {
			const projectWizardState: ProjectWizardState = { ...state } as unknown as ProjectWizardState
			if (projectWizardState.projects && !isEmpty(projectWizardState.projects)) {
				Object.keys(projectWizardState.projects).forEach((projectId) => {
					const matchRules = projectWizardState.projects[projectId].matchRules
					projectWizardState.projects[projectId] = {
						...projectWizardState.projects[projectId],
						matchRulesValidated: true,
						initialMatchRules: matchRules
					}
				})
			}
			return { ...state, ...projectWizardState }
		}
	},
	5: (state: PersistedState): PersistedState => {
		if (state) {
			const projectWizardState: ProjectWizardState = { ...state } as unknown as ProjectWizardState
			if (projectWizardState.projects && !isEmpty(projectWizardState.projects)) {
				Object.keys(projectWizardState.projects).forEach((projectId) => {
					projectWizardState.projects[projectId].source = {
						...projectWizardState.projects[projectId].source,
						entityType: EntityType.ACCOUNTS
					}
				})
			}
			return { ...state, ...projectWizardState }
		}
	}
}
