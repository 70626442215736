import { FC, useEffect, useRef, useState } from 'react'
import i18n from '../../i18n'
import { findLocale } from './findLocale'
import { LanguageList } from './language-list'
import { LanguagePill } from './language-pill'
import styles from './language-selector.module.scss'

declare global {
	interface Window {
		// The structure of the aptrinsic plugin in is external to us and we don't know the type
		// so disabling next line no-explicit-any
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		aptrinsic: any
	}
}
window.aptrinsic = window.aptrinsic || undefined

export const LanguageSelector: FC = () => {
	const [showLanguageList, setShowLanguageList] = useState(false)
	const [selectedLocale, setSelectedLocale] = useState(findLocale(i18n.language))
	const languageListRef = useRef(null)

	const onMouseDown = (e: MouseEvent) => {
		if (
			languageListRef.current &&
			!e.composedPath().find((pathElement) => pathElement === languageListRef.current)
		) {
			setShowLanguageList(false)
			document.removeEventListener('mousedown', onMouseDown)
		}
	}

	useEffect(() => {
		return () => {
			document.removeEventListener('mousedown', onMouseDown)
		}
		/**
		 * We only want to run this effect once, so we use the empty array as the dependency
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<span
				onClick={() => {
					setShowLanguageList(!showLanguageList)
					document.addEventListener('mousedown', onMouseDown)
				}}
				className={styles.languagePillContainer}
				data-testid="language-pill"
			>
				<LanguagePill language={selectedLocale.short} />
			</span>
			{showLanguageList ? (
				<div className={styles.languageSelectorContainer}>
					<LanguageList
						ref={languageListRef}
						selectedLocale={selectedLocale}
						onNewSelection={(newLocale) => {
							const aptrinsic = window.aptrinsic
							if (aptrinsic) {
								aptrinsic('set', 'user', { language: newLocale.key })
							}
							i18n.changeLanguage(newLocale.key).then(() => {
								setSelectedLocale(newLocale)
								setShowLanguageList(false)
							})
						}}
					/>
				</div>
			) : undefined}
		</>
	)
}
