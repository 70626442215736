import { ColorBlueBrand, ColorSlateLighter } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Icon } from '../../local-core-ui'
import { Source } from '../../types'
import { copyToClipboard } from '../upload-status-details/upload-status-utilities'
import styles from './APISourceDetails.module.scss'

export interface APISourceDetailsProps {
	source: Source
}

export const APISourceDetails: FC<APISourceDetailsProps> = ({ source }) => {
	const { t } = useTranslation()
	const sourceURI = `${import.meta.env.VITE_PLUS_DNB}/v1/connect/matchEnrich/v2/${source.sourceId}`
	return (
		<div className={styles.container}>
			<div className={styles.sourceAPIUriContainer} data-testid="api-container">
				<span className={styles.label}>{t('source.api.uri')}&nbsp;</span>
				<div className={styles.wrapper}>
					{sourceURI}
					<span
						className={styles.copyIcon}
						onClick={() => copyToClipboard(sourceURI)}
						data-testid={'buttonCopy-APIURI'}
					>
						<Icon
							testId="copy-mini-API-source-details"
							key={'mini-copy'}
							type="copy"
							color={ColorBlueBrand}
						/>
					</span>
				</div>
			</div>
			<div className={styles.apiNKey}>{t('source.api.key.n.secret')}</div>
			<div className={styles.documentationHeader}>{t('source.api.details')}</div>
			<div className={styles.hr}>
				<Divider size={'medium'} color={ColorSlateLighter} />
			</div>
			<div className={styles.documentationLine}>
				<a
					className={`${styles.documentationLink}`}
					href={'https://directplus.documentation.dnb.com/html/guides/DNBConnect/DNBConnect.html'}
					target="_blank"
					rel="noopener noreferrer"
					data-testid="link-apiSpec"
				>
					<Icon testId="external-API-source-details" type={'external-link'} title="external-link" />
					&nbsp;
					{t('source.api.get.documentation')}
				</a>
			</div>
		</div>
	)
}
