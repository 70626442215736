import { MutableRefObject } from 'react'
import { TFunction } from 'react-i18next'
import { ElementUIFacade } from '../ElementUIFacade'
import splitPayMannerPastDueSummaryPrefixes from '../split-pay-manner-past-due-summary-element-prefixes.json'

const buildChildrenTree = (
	listFlattenElementsSplitPayMannerPastDueSummary: Array<ElementUIFacade>,
	root: ElementUIFacade,
	t: TFunction<'translation'>,
	splitPayMannerPastDueSummarySelectorValueRef: MutableRefObject<number>
) => {
	const groupElements: Array<ElementUIFacade> = []
	splitPayMannerPastDueSummaryPrefixes.forEach((prefix) => {
		const elementList = listFlattenElementsSplitPayMannerPastDueSummary.filter((ele) =>
			ele.elementId.startsWith(prefix)
		)
		if (elementList && elementList.length > 0) {
			const groupParent: ElementUIFacade = {
				parent: root,
				elementId: `${prefix}Id`,
				displayName: t(`${prefix}.element`),
				description: elementList[0].description,
				level: elementList[0].level,
				dataType: elementList[0].dataType,
				example: elementList[0].example,
				showChildrenBlocks: false,
				considerChildrenForCount: false,
				isRangesAndFiguresPattern: true,
				maximumElementsInOutput: splitPayMannerPastDueSummarySelectorValueRef,
				isRequiredByDefault:
					prefix === 'spltPyMnnrPstDueSum_daysHighQty_' || prefix === 'spltPyMnnrPstDueSum_daysLowQty_'
			}
			elementList.sort((a: ElementUIFacade, b: ElementUIFacade) => {
				if (a.elementId > b.elementId) {
					return 1
				} else if (a.elementId < b.elementId) {
					return -1
				} else {
					return 0
				}
			})
			groupParent.childs = elementList
			groupElements.push(groupParent)
		}
	})
	return groupElements
}

export const buildSplitPayMannerPastDueSummaryTree = (
	listFlattenElementsSplitPayMannerPastDueSummary: Array<ElementUIFacade>,
	t: TFunction<'translation'>,
	splitPayMannerPastDueSummarySelectorValueRef: MutableRefObject<number>
): Array<ElementUIFacade> => {
	const tree: Array<ElementUIFacade> = []
	if (listFlattenElementsSplitPayMannerPastDueSummary.length) {
		const root: ElementUIFacade = {
			blockId: t(listFlattenElementsSplitPayMannerPastDueSummary[0].blockId || ''),
			elementId: 'splitPayMannerPastDueSummaryId',
			displayName: t('splitPayMannerPastDueSummary.element'),
			description: t('splitPayMannerPastDueSummary.description'),
			level: '4',
			dataType: t('ARRAY'),
			showChildrenBlocks: true,
			containsChildrenSelector: false,
			considerChildrenForCount: true,
			isRangesAndFiguresPattern: true,
			maximumElementsInOutput: splitPayMannerPastDueSummarySelectorValueRef,
			agingBucketOptions: [
				{ value: 'spltPyMnnrPstDueSum_1', id: '000_030', isChecked: false, label: t('0_30_days') },
				{ value: 'spltPyMnnrPstDueSum_2', id: '091_120', isChecked: false, label: t('91_120_days') },
				{ value: 'spltPyMnnrPstDueSum_3', id: '031_060', isChecked: false, label: t('31_60_days') },
				{ value: 'spltPyMnnrPstDueSum_4', id: '121_180', isChecked: false, label: t('121_180_days') },
				{ value: 'spltPyMnnrPstDueSum_5', id: '061_090', isChecked: false, label: t('61_90_days') },
				{ value: 'spltPyMnnrPstDueSum_6', id: '181_999', isChecked: false, label: t('180+_days') }
			]
		}
		tree.push(root)

		root.childs = buildChildrenTree(
			listFlattenElementsSplitPayMannerPastDueSummary,
			root,
			t,
			splitPayMannerPastDueSummarySelectorValueRef
		)
	}
	return tree
}
