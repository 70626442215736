import cx from 'classnames'
import { Children, cloneElement, FC, PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react'
import { Icon } from '..'
import { createTokenList, getValueToken } from '../design-tokens/utils'
import Dots from '../dots/dots'
import * as tokens from '../shared/token-colors.json'
import { TabProps, withTabsParentProps } from './tab'
import styles from './tabs.module.scss'

export interface TabsColor {
	defaultColor?: string
	hoverColor?: string
}

interface TabsProps {
	span?: boolean
	minTabWidth?: number
	maxVisibleTabs?: number
	type?: 'default' | 'button' | 'pill' | 'link'
	wrap?: boolean
	onEditTab?(idx: number): void
	onDeleteTab?(idx: number): void
	value: number
	colors?: TabsColor
	testId: string
	showDots?: boolean
	onChange(idx: number): void
	/**
	 * Provides a label that describes the purpose of the set of tabs.
	 */
	'aria-label'?: string
}

export interface TabColorState {
	defaultState: string
	hoverState: string
}

export const Tabs: FC<PropsWithChildren<TabsProps>> = ({
	children,
	span = false,
	minTabWidth = null,
	maxVisibleTabs = null,
	type = 'default',
	wrap = false,
	onEditTab,
	onDeleteTab,
	value,
	colors,
	testId,
	showDots = false,
	onChange,
	'aria-label': ariaLabel
}: PropsWithChildren<TabsProps>) => {
	const getChildrenProps = (tabIndex) => {
		if (children.hasOwnProperty('length') && children[tabIndex] && children[tabIndex].props) {
			return children[tabIndex].props
		} else if (children['props']) {
			return children['props']
		}
	}

	const [tabWidth, setTabWidth] = useState(undefined)
	const [arrowNextDisabled, setArrowNextDisabled] = useState(true)
	const [arrowBackDisabled, setArrowBackDisabled] = useState(true)
	const [activeTab, setActiveTab] = useState(value || 0)
	const [sizeToScroll, setSizeToScroll] = useState(undefined)
	const [childrenTabsWidth, setChildrenTabsWidth] = useState(0)
	const [headerWidth, setHeaderWidth] = useState(0)
	const tabContainerRef = useRef(null)
	const headerRef = useRef(null)
	const childrenArray = Children.toArray(children)
	const tabsLength = childrenArray.length
	const colorsTokenList = createTokenList(tokens)
	const defaultColor = getValueToken(colorsTokenList, colors?.defaultColor)
	const hoverColor = getValueToken(colorsTokenList, colors?.hoverColor)
	const [activeDot, setActiveDot] = useState(undefined)
	const [numberOfDots, setNumberOfDots] = useState(undefined)

	const getNumberOfDots = (width) => {
		if (showDots) {
			if (width < headerRef.current.offsetWidth) {
				setNumberOfDots(0)
			} else if (width > 0) {
				const dots =
					width % tabContainerRef.current.offsetWidth === 0
						? (width / tabContainerRef.current.offsetWidth) | 0
						: ((width / tabContainerRef.current.offsetWidth) | 0) + 1
				setNumberOfDots(dots)
				setActiveDot(0)
			}
		}
	}

	useEffect(() => {
		function recalculateDots() {
			getNumberOfDots(childrenTabsWidth)
		}
		if (showDots) {
			window.addEventListener('resize', recalculateDots)
			return () => {
				window.removeEventListener('resize', recalculateDots)
			}
		}
	})

	useEffect(() => {
		const newTabContainerWidth = tabContainerRef.current ? tabContainerRef.current.offsetWidth : 0
		if (maxVisibleTabs !== null) {
			if (span) {
				if (tabsLength > maxVisibleTabs) {
					const width = newTabContainerWidth / maxVisibleTabs + 0.1
					setTabWidth(width)
					showDots ? setSizeToScroll(width * maxVisibleTabs) : setSizeToScroll(width)
				} else {
					const width = newTabContainerWidth / tabsLength
					setTabWidth(width)
				}
			} else {
				const childrenTabs = tabContainerRef.current.children
				let tabsWidth = 0
				setHeaderWidth(headerRef.current ? headerRef.current.offsetWidth : 0)
				childrenArray.forEach((tab, index) => (tabsWidth += childrenTabs[index]?.offsetWidth))
				setChildrenTabsWidth(tabsWidth)
				getNumberOfDots(tabsWidth)
				setSizeToScroll(childrenTabs[0]?.offsetWidth)
			}
		} else {
			if (minTabWidth !== null) {
				const visibleTabs = Math.floor(newTabContainerWidth / minTabWidth)
				if (tabsLength > visibleTabs) {
					setTabWidth(minTabWidth)
				} else {
					if (span) {
						const width = newTabContainerWidth / tabsLength + 0.1
						setTabWidth(width)
					}
				}
			}
		}
	}, [tabContainerRef.current, tabsLength])

	useEffect(() => {
		function handleResize() {
			const newTabContainerWidth = tabContainerRef.current ? tabContainerRef.current.offsetWidth : 0
			if (span) {
				const visibleTabs = maxVisibleTabs === null ? childrenArray.length : maxVisibleTabs
				setTabWidth(newTabContainerWidth / visibleTabs + 0.1)
			}
		}
		if (maxVisibleTabs !== null && (tabsLength > maxVisibleTabs || childrenTabsWidth > headerWidth)) {
			window.addEventListener('resize', handleResize)
			return () => {
				window.removeEventListener('resize', handleResize)
			}
		}
	})

	useEffect(() => {
		function handleKeyUp(event) {
			if (event.key === 'Tab') {
				scrollHandler(tabContainerRef.current)
				scrollPaginator(tabContainerRef.current)
			}
		}

		window.addEventListener('keyup', handleKeyUp)
		return () => {
			window.removeEventListener('keyup', handleKeyUp)
		}
	})

	const changeTab = (index) => {
		setActiveTab(index)
		onChange(index)
	}

	useEffect(() => {
		const childrenProps = getChildrenProps(activeTab)
		if (!childrenProps) {
			setActiveTab(childrenArray.length - 1)
		}
	}, [getChildrenProps(activeTab)])

	useEffect(() => {
		if (!children.hasOwnProperty('length')) {
			setActiveTab(0)
		}
	}, [children])

	useEffect(() => {
		if (tabContainerRef.current?.scrollWidth > tabContainerRef.current?.offsetWidth) setArrowNextDisabled(false)
	}, [tabContainerRef.current?.scrollWidth])

	const scrollHandler = (e) => {
		if (childrenTabsWidth > headerRef.current.offsetWidth || span) {
			if (e.scrollLeft < 1) {
				setArrowNextDisabled(false)
				setArrowBackDisabled(true)
			} else if (isScrollEnd(e.scrollLeft)) {
				setArrowNextDisabled(true)
				setArrowBackDisabled(false)
			} else {
				setArrowNextDisabled(false)
				setArrowBackDisabled(false)
			}
		}
	}

	const isScrollEnd = (scrollLeft) => {
		return tabContainerRef.current.scrollWidth - scrollLeft - tabContainerRef.current.offsetWidth < 5
	}

	useEffect(() => {
		if (value >= 0) {
			setActiveTab(value)
		}
	}, [value])

	const onChangeDot = (index) => {
		const scroll = (tabContainerRef.current.offsetWidth / maxVisibleTabs + 0.1) * maxVisibleTabs
		setActiveDot(index)
		tabContainerRef.current.scrollLeft = index * scroll
	}

	const scrollPaginator = (e) => {
		if (e.scrollLeft < 1) {
			if (showDots) setActiveDot(0)
		} else if (isScrollEnd(e.scrollLeft)) {
			if (showDots) setActiveDot(numberOfDots - 1)
		} else {
			const activeDot = Math.floor((e.scrollLeft + e.offsetWidth) / (e.scrollWidth / numberOfDots))
			if (showDots) setActiveDot(activeDot - 1)
		}
	}

	const doScroll = (e) => {
		scrollHandler(e)
		if (showDots) {
			scrollPaginator(e)
		}
	}

	const generateTab = (
		child: ReactElement,
		index: number,
		scroll: 'scroll' | 'scrollHidden'
	): ReactElement<withTabsParentProps> => {
		const spacingClass =
			index != tabsLength - 1 || scroll != 'scrollHidden'
				? type === 'button'
					? 'itemSpacingButton'
					: type === 'pill'
					? 'itemSpacingPill'
					: 'itemSpacingDefault'
				: ''
		const tabsColor: TabColorState = {
			defaultState: defaultColor,
			hoverState: hoverColor
		}

		return cloneElement(child, {
			testId,
			isSelected: index === activeTab,
			changeTab,
			index,
			scroll,
			spacingClass,
			type,
			onEditTab,
			onDeleteTab,
			tabsColor
		})
	}

	const renderTabs = () => {
		const scroll =
			maxVisibleTabs !== null && (tabsLength > maxVisibleTabs || childrenTabsWidth > headerWidth)
				? 'scroll'
				: 'scrollHidden'
		const spanClass = span ? 'span' : ''
		return (
			<div
				data-testid={`tabs-header-${testId}`}
				className={cx(styles.headerTabs, styles.headerSpacing, styles[scroll])}
				ref={headerRef}
			>
				{maxVisibleTabs !== null && (tabsLength > maxVisibleTabs || childrenTabsWidth > headerWidth) && !wrap
					? showLeftArrow()
					: null}
				<div
					data-testid={`tabs-container-baseline-${testId}`}
					className={cx({ [styles.containerBaseline]: !wrap }, styles[spanClass])}
				>
					<div
						className={cx(styles.tabsContainer, wrap ? styles.wrapTabs : styles[scroll], styles[spanClass])}
						ref={tabContainerRef}
						onScroll={(e) => doScroll(e.target)}
						aria-label={ariaLabel}
						role="tablist"
						data-testid={`tabs-container-${testId}`}
					>
						{childrenArray.map((child: ReactElement, index) => {
							const childProps: TabProps = child.props
							return (
								<div
									key={`${childProps.label || childProps['aria-label']}-${index}`}
									className={cx(
										styles.tabButtonContainer,
										styles[scroll],
										styles[spanClass],
										childProps.disabled ? styles.disabled : ''
									)}
									style={{ width: tabWidth, minWidth: minTabWidth, maxWidth: tabWidth }}
									data-testid={`tabs-child-${testId}`}
								>
									{generateTab(child, index, scroll)}
								</div>
							)
						})}
					</div>
					{type !== 'button' && type !== 'link' && (
						<hr data-testid={`line-tabs-${testId}`} className={cx(styles.baseline, styles[scroll])} />
					)}
					{showDots ? (
						<div
							data-testid={`tabs-dots-${testId}`}
							className={`${numberOfDots <= 1 ? styles.notVisible : styles.visible}`}
						>
							<Dots
								numberDots={numberOfDots}
								onChangeDot={onChangeDot}
								selectedRadio={activeDot}
								testId={testId + '-dots'}
							/>
						</div>
					) : undefined}
				</div>
				{maxVisibleTabs !== null && (tabsLength > maxVisibleTabs || childrenTabsWidth > headerWidth) && !wrap
					? showRightArrow()
					: null}
			</div>
		)
	}

	const nextTab = () => {
		tabContainerRef.current.scrollLeft += sizeToScroll
	}

	const backTab = () => {
		tabContainerRef.current.scrollLeft -= sizeToScroll
	}

	const showLeftArrow = () => {
		const arrowBackColor = arrowBackDisabled ? 'ColorGrayLighter' : 'ColorGrayDarker'
		return (
			<div data-testid={`tabs-back-arrow-container-${testId}`} className={styles.arrowContainer}>
				<div
					className={cx(styles.arrowBack, arrowBackDisabled ? styles.disabled : '')}
					onClick={backTab}
					data-testid={testId + '-prev'}
				>
					<Icon
						testId={`tabs-arrow-back-${testId}`}
						type="chevron-left"
						size="small"
						title="arrow-back"
						color={arrowBackColor}
					/>
				</div>
			</div>
		)
	}

	const showRightArrow = () => {
		const arrowNextColor = arrowNextDisabled ? 'ColorGrayLighter' : 'ColorGrayDarker'
		return (
			<div data-testid={`tabs-next-arrow-container-${testId}`} className={styles.arrowContainer}>
				<div
					className={cx(styles.arrowNext, arrowNextDisabled ? styles.disabled : '')}
					onClick={nextTab}
					data-testid={testId + '-next'}
				>
					<Icon
						testId={`tabs-arrow-next-${testId}`}
						type="chevron-right"
						size="small"
						title="arrow-next"
						color={arrowNextColor}
					/>
				</div>
			</div>
		)
	}

	return (
		<div data-testid={`tabs-general-container-${testId}`} className={styles.tabControlContainer}>
			{renderTabs()}
		</div>
	)
}

export default Tabs
