import { AxiosInstance, AxiosResponse } from 'axios'
import { AttachTenantResponse, Domain, Tenant, User } from '../../../types'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { newProjectAction } from '../../projectWizard/actions'
import { inboundTransform } from '../../projectWizard/transformer'
import { attachFeatureFlagAction, attachTenantAction } from '../actions'
import { SessionState, SessionStatus } from '../types'

declare global {
	interface Window {
		// The structure of the aptrinsic plugin in is external to us and we don't know the type
		// so disabling next line no-explicit-any
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		aptrinsic: any
	}
}
window.aptrinsic = window.aptrinsic || undefined

const entitledDatablocks = (apiClient: AxiosInstance, selectedEntitlements: Array<string>) => {
	return apiClient.get('/pls/data-blocks/entitlement').then((response: AxiosResponse) => {
		const domains: Array<Domain> = response.data.domains
		const filteredBlocks = selectedEntitlements.filter((entitlement) =>
			domains.find((domain) =>
				Object.values(domain.recordTypes)
					.flat()
					.find((block) => block.blockId === entitlement)
			)
		)
		return filteredBlocks
	})
}
const setGainsightData = (
	tenant: Tenant,
	session: SessionState,
	user: User,
	lng: string,
	entitlements: Array<string>
) => {
	const aptrinsic = window.aptrinsic
	const sessionFeatures: any = session.featureFlags
	const entitledFeatures = Object.keys(sessionFeatures)
		.filter((feature) => sessionFeatures[feature] === true)
		.join(', ')
	if (aptrinsic) {
		if (session.platform === 'salesforce') {
			aptrinsic('set', 'globalContext', { name: 'Salesforce', type: 'Iframe' })
		} else {
			aptrinsic('set', 'globalContext', { name: 'Standalone', type: 'Web Application' })
		}
		const userAttributes = {
			accountId: tenant.SubscriberNumber,
			id: `${session.username}_${import.meta.env.VITE_GAINSIGHT_ENV}`,
			email: session.username,
			firstName: session.firstName,
			lastName: session.lastName,
			duns: tenant.DUNS,
			role: user.AccessLevel,
			screenWidth: screen.width,
			screenHeight: screen.height,
			viewportHeight: window.innerHeight,
			viewportWidth: window.innerWidth,
			userAgent: navigator.userAgent,
			language: lng
		}
		const tenantAttributes = {
			id: tenant.SubscriberNumber,
			tenantId: tenant.Pid,
			name: tenant.CompanyName,
			duns: tenant.DUNS,
			subscriptionId: tenant.SubscriberNumber,
			isInternal: tenant.SubscriptionType === 'Internal',
			countryName: tenant.Country,
			contractStartDate: tenant.ContractStartDate,
			contractEndDate: tenant.ContractEndDate,
			modules: session?.moduleFlags?.join(', '),
			features: entitledFeatures,
			dataBlockEntitlements: entitlements,
			orgId: session?.orgid
		}

		aptrinsic('identify', userAttributes, tenantAttributes)
	}
}

export const attachToTenant =
	(token: string, tenant: Tenant, lng: string): AppThunk<Promise<void>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<void> => {
		const apiClient = getApiClient4Thunks(dispatch)
		return apiClient.post('/pls/attach', tenant).then((response: AxiosResponse<AttachTenantResponse>) => {
			return apiClient
				.get('/pls/tenantconfig')
				.then((response: AxiosResponse) => {
					const envFlag = JSON.parse(import.meta.env.VITE_FEATURE_FLAGS || '{}')
					const featureFlags = {
						...response.data.FeatureFlags,
						...envFlag
					}
					const envModuleFlags = JSON.parse(import.meta.env.VITE_MODULE_FLAGS || '[]')
					const moduleFlags = [...response.data.Modules, ...envModuleFlags]
					const session = getState().session
					if (session.status !== SessionStatus.signedout)
						dispatch(
							attachFeatureFlagAction({
								featureFlags: featureFlags,
								moduleFlags: moduleFlags
							})
						)
					dispatch(newProjectAction(false, false))
				})
				.finally(() => {
					const session = getState().session
					if (session.status !== SessionStatus.signedout) {
						const encryptCurrent = inboundTransform(tenant, 'local')
						window.localStorage.setItem('currentTenant', JSON.stringify(encryptCurrent))
						dispatch(
							attachTenantAction({
								tenant: tenant.Identifier,
								user: response.data.Result.User
							})
						)
						try {
							const session = getState().session
							const user = response.data.Result.User
							entitledDatablocks(apiClient, ['emailverification']).then((entitlements: Array<string>) => {
								setGainsightData(tenant, session, user, lng, entitlements)
							})
						} finally {
							// Do nothing, we don't want gainsight to disrupt the session mechanism
						}
					}
				})
		})
	}
