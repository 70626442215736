import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { SummaryResponse } from '../types'
import { getPublishedSummary } from './api/getPublishedSummary'

export const usePublishedSummary = (): UseQueryResult<SummaryResponse, unknown> => {
	const apiClient = useApi()

	const queryKey = ['getPublishedSummary']
	const queryFunction = () => getPublishedSummary(apiClient)
	return useQuery(queryKey, queryFunction)
}
