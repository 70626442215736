import { MouseEvent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import ampLogo from '../assets/images/dnb-amp-logo.svg'
import wordmarkLogo from '../assets/images/dnb-wordmark-logo.svg'
import { Button } from '../local-core-ui'
import styles from './pre-login.module.scss'

export function PreLogin(): ReactElement {
	const { t } = useTranslation()

	const handleLoginClick = (event: MouseEvent) => {
		event.preventDefault()
		console.log("'Log In' was clicked")
	}

	const handleContactClick = (event: MouseEvent) => {
		event.preventDefault()
		console.log("'Contact Sales' was clicked")
	}

	return (
		<div className={styles.preLogin}>
			<div className={styles.mask}>
				<div className={styles.preLoginPanel}>
					<div className={styles.ampLogo}>
						<img alt={t('logo.alt.text')} src={ampLogo} />
					</div>
					<div className={styles.wordmarkLogo}>
						<img alt={t('company.name')} src={wordmarkLogo} />
					</div>
					<div className={styles.jumbo}>{t('product.name')}</div>
					<p>{t('product.description')}</p>
					<div className={styles.buttonSection}>
						<Button
							size="medium"
							text={t('continue')}
							onClick={handleLoginClick}
							testId="pre-login-continue-btn"
						/>
						{/* switch to Button */}
						<a
							href="/sales-link"
							id="contact-sales-link"
							className={styles.contactSalesLink}
							onClick={handleContactClick}
							data-testid="pre-login-contact-sales"
						>
							{t('contact.sales')}
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}
