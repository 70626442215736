import { ColorBlueBrand } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { FC, MouseEvent, useState } from 'react'
import { Button, ContextMenu, ContextMenuOption } from '../../../local-core-ui'
import { ProjectSummary } from '../../project-summary/project-summary'
import { ShowHideSectionIcon } from '../../show-hide-section-icon/show-hide-section-icon'
import { Spinner } from '../../spinner/spinner'
import styles from './project-detail.module.scss'

interface ProjectDetailContextMenu {
	options: ContextMenuOption[]

	onClick(option: string, idx: number): void
}

interface ActionButton {
	text: string

	onClick(event: MouseEvent<HTMLButtonElement>): void
}

interface ProjectDetailProps {
	projectId: string
	projectName: string
	contextMenu?: ProjectDetailContextMenu
	actionButton?: ActionButton
	testId: string

	onClick(): void

	isLoading?: boolean
}

export const ProjectDetail: FC<ProjectDetailProps> = ({
	projectId,
	projectName,
	contextMenu,
	actionButton,
	testId,
	onClick,
	isLoading
}: ProjectDetailProps) => {
	const [displayProjectSection, setDisplayProjectSection] = useState(true)
	return (
		<div className={styles.projectDetail}>
			<div data-testid="project-detail-header" className={styles.projectDetailHeader}>
				<p
					data-testid="project-detail-name"
					className={styles.projectDetailName}
					title={projectName}
					onClick={onClick}
				>
					{projectName}
				</p>
				<div className={styles.projectDetailUtilitiesButtons}>
					{isLoading && (
						<div className={styles.projectSourceFileBarSpinnerContainer}>
							<Spinner />
						</div>
					)}
					<div className="project-detail-display-section-button">
						<ShowHideSectionIcon
							iconColor={ColorBlueBrand}
							displayedSection={displayProjectSection}
							onClickSectionIcon={() => setDisplayProjectSection(!displayProjectSection)}
							testId={testId + '-icon'}
						/>
					</div>
					{actionButton ? (
						<Button
							text={actionButton.text}
							onClick={actionButton.onClick}
							testId={testId + '-action-button'}
						/>
					) : undefined}
					{contextMenu ? (
						<div className={styles.contextMenuWrapper}>
							<ContextMenu
								options={contextMenu.options}
								onMenuClicked={contextMenu.onClick}
								alignment="left"
								testId={testId + '-menu'}
							/>
						</div>
					) : undefined}
				</div>
			</div>
			<ProjectSummary
				projectId={projectId}
				displayProjectSection={displayProjectSection}
				testId={testId + '-project-summary'}
			/>
		</div>
	)
}
