import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from '../../local-core-ui'
import { AlphabeticalFilter } from '../alphabetical-filter/alphabetical-filter'
import { sbniType, SearchButtonNInput } from '../search-button-n-input/search-button-n-input'
import { SelectableUserTile } from '../selectable-user-tile/selectable-user-tile'
import styles from './person-picker.module.scss'

interface PersonPickerProps {
	onSelectionChange(personIds: Array<string>): void
	selectedPersons?: Array<string>
	persons: Array<{
		id: string
		name: string
		role: string
	}>
	multiselect?: boolean
	testId: string
}

export const PersonPicker: FC<PersonPickerProps> = ({
	onSelectionChange,
	selectedPersons = [],
	persons,
	multiselect = false,
	testId
}: PersonPickerProps) => {
	const { t } = useTranslation()
	const [searchString, setSearchString] = useState('')
	const [selectedLetter, setSelectedLetter] = useState('All')
	const [sortedBy, setSortedBy] = useState('FA')
	const [selectedUsers, setSelectedUsers] = useState<Array<string>>(selectedPersons)
	const toggleUser = (userId: string) => {
		let newSelectedUsers = [...selectedUsers]
		if (newSelectedUsers.includes(userId)) {
			newSelectedUsers = newSelectedUsers.filter((u) => u != userId)
		} else if (multiselect) {
			newSelectedUsers.push(userId)
		} else {
			newSelectedUsers = [userId]
		}
		setSelectedUsers(newSelectedUsers)
		onSelectionChange(newSelectedUsers)
	}

	const filteredPersons = useMemo(() => {
		return persons
			.filter((person) => person.name.toLowerCase().includes(searchString.toLowerCase()))
			.filter(
				(person) =>
					selectedLetter === 'All' ||
					(selectedLetter === 'Selected' && selectedUsers.includes(person.id)) ||
					person.name
						.split(' ')
						.map((n) => n.charAt(0).toUpperCase() === selectedLetter)
						.some((e) => e)
			)
			.sort((a, b) => {
				const criteria = sortedBy.charAt(0)
				const direction = sortedBy.charAt(1) === 'A' ? 1 : -1
				const compA =
					criteria === 'F'
						? a.name.toLowerCase()
						: criteria === 'L'
						? a.name.split(' ').reverse().join(' ').toLowerCase()
						: a.role
				const compB =
					criteria === 'F'
						? b.name.toLowerCase()
						: criteria === 'L'
						? b.name.split(' ').reverse().join(' ').toLowerCase()
						: b.role

				if (compA < compB) return -direction
				else if (compA > compB) return direction
				else return 0
			})
		/**
		 * We only want to uptdate this effect when selectedLetter, searchString, sortedBy or persons changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedLetter, searchString, sortedBy, persons])

	return (
		<div className={styles.personPicker}>
			<div className={styles.headerContainer}>
				<div className={styles.searchContainer}>
					<SearchButtonNInput
						hint={t('person.picker.search.hint')}
						onChangeFilter={(f) => {
							if (f !== '') setSelectedLetter('All')
							setSearchString(f)
						}}
						testId={`${testId}-search`}
						type={sbniType.input}
						value={searchString}
					/>
				</div>
				<div className={styles.sortContainer}>
					{t('person.picker.sorted.by')}
					<Dropdown
						id="sort-a-ma-bob"
						onChangeFunction={(s) => {
							setSortedBy(s)
						}}
						options={[
							{ label: t('person.picker.fn.a.z'), value: 'FA' },
							{ label: t('person.picker.fn.z.a'), value: 'FZ' },
							{ label: t('person.picker.ln.a.z'), value: 'LA' },
							{ label: t('person.picker.ln.z.a'), value: 'LZ' },
							{ label: t('person.picker.role.a.z'), value: 'RA' },
							{ label: t('person.picker.role.z.a'), value: 'RZ' }
						]}
						selected={sortedBy}
						labelPosition="left"
						testId={`${testId}-sort-dropdown`}
						hint=""
						label=""
					/>
				</div>
			</div>
			<div className={styles.alphaFilterContainer}>
				<AlphabeticalFilter
					onSelection={(selected) => {
						setSelectedLetter(selected)
					}}
					itemsToFilter={searchString === '' ? persons.map((p) => p.name.split(' ')).flat() : []}
					showAll={true}
					selectedLetter={selectedLetter}
					customCategories={[
						{
							label: t('person.picker.selected.count', { count: selectedUsers.length }),
							key: 'Selected',
							visible: selectedUsers.length > 0,
							enabled: true
						}
					]}
					testId={`${testId}-alpha-filter`}
				/>
			</div>
			<div className={styles.personsContainer}>
				{filteredPersons.length > 0 ? (
					filteredPersons.map((person) => {
						return (
							<SelectableUserTile
								userId={person.id}
								name={person.name}
								role={person.role}
								onClick={() => {
									toggleUser(person.id)
								}}
								testId={`${testId}-person-${person.id}`}
								selected={selectedUsers.includes(person.id)}
								key={person.id}
							/>
						)
					})
				) : (
					<div className="no-results">No Results</div>
				)}
			</div>
		</div>
	)
}
