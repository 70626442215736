import { AxiosResponse } from 'axios'
import { StartImportRequest } from '../../../types'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { removeProjectFromWizardAction, updateLoadingNextStep, updateStatusStartImport } from '../actions'

export const startImport =
	(): AppThunk<Promise<void>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<void> => {
		const projectId = getState().projectWizard.currentProject?.id
		const fileImportId = getState().projectWizard.currentProject.fileInfo?.uploadInfo?.file_import_id
			? getState().projectWizard.currentProject.fileInfo?.uploadInfo?.file_import_id
			: ''
		const sourceId = getState().projectWizard.currentProject.source?.id
		const projectShouldSync = getState().projectWizard.currentProject.shouldSync
		if (projectId && sourceId && fileImportId && projectShouldSync) {
			dispatch(updateLoadingNextStep(true))
			const apiClient = getApiClient4Thunks(dispatch)
			const url = `/pls/uploads/startimport`
			const createImportRequest: StartImportRequest = {
				project_id: projectId,
				source_id: sourceId,
				file_import_id: fileImportId ? fileImportId : '',
				enrichOnly: false
			}
			return apiClient
				.post(url, createImportRequest)
				.then((response: AxiosResponse) => {
					if (response.status === 200) {
						dispatch(updateStatusStartImport(true))
						dispatch(removeProjectFromWizardAction(projectId))
					}
					dispatch(updateLoadingNextStep(false))
				})
				.catch((e) => {
					console.error(e)
					dispatch(updateLoadingNextStep(false))
					throw e
				})
		} else if (projectId && sourceId) {
			dispatch(updateLoadingNextStep(true))
			dispatch(updateStatusStartImport(true))
			dispatch(removeProjectFromWizardAction(projectId))
			return Promise.resolve()
		} else {
			return Promise.reject()
		}
	}
