import { ReactElement, useEffect } from 'react'
import { recordSortBy } from '../../../components/company-list-manager/company-list-manager'
import { useAppDispatch } from '../../../store'
import { setSelectedRecords } from '../../../store/steward/stewardSlice'
import { TileTypes } from '../../../store/steward/types'
import styles from '../dashboard-steward.module.scss'
import { CardLeftSide } from './card-left-side'
import { CardRightSide } from './card-right-side'

interface StewardCardViewProps {
	onTableView(): void
	assignmentName: string
	isStewardFilterTabsAvailable: boolean
	selectedTile: TileTypes
	recordListSortBy?: recordSortBy
	onRecordListSortByChange(sortBy: recordSortBy): void
	onAssign(): void
	onMultiSelectToggle(): void
	isMultiSelectActive: boolean
}

export function StewardCardView({
	onTableView,
	assignmentName,
	isStewardFilterTabsAvailable,
	selectedTile,
	recordListSortBy,
	onRecordListSortByChange,
	onAssign,
	onMultiSelectToggle,
	isMultiSelectActive
}: StewardCardViewProps): ReactElement {
	const dispatch = useAppDispatch()

	const handlePageChange = () => {
		if (!isMultiSelectActive) {
			dispatch(setSelectedRecords([]))
		}
	}
	const toggleMultiSelect = () => {
		if (isMultiSelectActive) {
			dispatch(setSelectedRecords([]))
		}
		onMultiSelectToggle()
	}
	const hideMultiSelect = () => {
		dispatch(setSelectedRecords([]))
		if (isMultiSelectActive) onMultiSelectToggle()
	}

	useEffect(() => {
		dispatch(setSelectedRecords([]))
		if (isMultiSelectActive) onMultiSelectToggle()
		/**
		 * We only want to run this effect selectedTile, assignmentName or the dispatch promise changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTile, assignmentName, dispatch])

	return (
		<div className={styles.dashboardStewardCardView}>
			<CardLeftSide
				isMultiSelectActive={isMultiSelectActive}
				onToggleMultiSelect={toggleMultiSelect}
				recordListSortBy={recordListSortBy}
				onRecordListSortByChange={onRecordListSortByChange}
				onPageChange={handlePageChange}
			/>
			<CardRightSide
				isMultiSelectActive={isMultiSelectActive}
				onMultiSelect={hideMultiSelect}
				onTableView={onTableView}
				assignmentName={assignmentName}
				isStewardFilterTabsAvailable={isStewardFilterTabsAvailable}
				selectedTile={selectedTile}
				onAssign={onAssign}
			/>
		</div>
	)
}
