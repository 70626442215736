import { FC } from 'react'
import Flag from '../flags/flag'
import { IDistributionData } from './gdm-key'
import styles from './geographical-distribution-map.module.scss'

interface TableProps {
	country: IDistributionData
	updateAndShowTooltip: (newData: IDistributionData) => void
	onChangeVisibleTooltip: (isTooltipVisible: boolean) => void
	color: string
	testId: string
}
export const TableKeyBasic: FC<TableProps> = ({
	country,
	updateAndShowTooltip,
	onChangeVisibleTooltip,
	color,
	testId
}: TableProps) => {
	return (
		<div
			key={country.countryCode}
			className={styles.countryData}
			onMouseOver={() => {
				updateAndShowTooltip(country)
			}}
			onMouseOut={() => {
				onChangeVisibleTooltip(false)
			}}
			onFocus={() => {
				updateAndShowTooltip(country)
			}}
			onBlur={() => {
				onChangeVisibleTooltip(false)
			}}
			tabIndex={0}
			style={{ color: color }}
			data-testid={testId + '-' + country.countryCode}
		>
			<div data-testid={`gdm-flag-container-${testId}`} className={styles.flagContainer}>
				<Flag testId={`gdm-key-country-${testId}`} countryCode={country.countryCode} />
			</div>
			{country.percent > 0.001 || country.percent === 0 ? Math.floor(country.percent * 1000) / 10 : '<0.1'}%
		</div>
	)
}
