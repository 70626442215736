import { AxiosResponse } from 'axios'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { removeTeamMemberAction } from '../actions'

export const removeTeamMember =
	(emailUser: string): AppThunk =>
	(dispatch: TDispatch, getState: TGetState): void => {
		const createdProject = getState().projectWizard.currentProject
		const teamId = createdProject.team.TeamId
		const teamName = createdProject.team.TeamName
		const teamMembers = createdProject.team.TeamMembers.map((teamMember) => teamMember.Email)
		const apiClient = getApiClient4Thunks(dispatch)
		const url = `/pls/teams/teamId/${teamId}`
		if (createdProject.id) {
			if (teamId && teamId !== '') {
				const newTeamMembers = teamMembers.filter((currentEmailUser) => currentEmailUser !== emailUser)
				apiClient
					.put(url, {
						teamName: teamName,
						teamMembers: newTeamMembers
					})
					.then((response: AxiosResponse) => {
						if (response.status === 200) {
							dispatch(removeTeamMemberAction(emailUser))
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}
		} else {
			dispatch(removeTeamMemberAction(emailUser))
		}
	}
