import { FC } from 'react'
import styles from './pill.module.scss'

export interface PillProps {
	text: string
}
export const Pill: FC<PillProps> = ({ text }) => {
	return (
		<span className={styles.pill}>
			<span className={styles.pillText}>{text}</span>
		</span>
	)
}
