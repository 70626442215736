import { FocusEvent, PropsWithChildren, ReactElement } from 'react'
import styles from './assignment-tile-container.module.scss'

interface AssignmentTileContainerProps {
	isSelected: boolean
	showDetail: boolean
	testId: string
	onFocus(event: FocusEvent<HTMLDivElement>): void
	isArchivedTile?: boolean
}

export const AssignmentTileContainer = ({
	children,
	isSelected,
	showDetail,
	testId,
	onFocus,
	isArchivedTile
}: PropsWithChildren<AssignmentTileContainerProps>): ReactElement => {
	return (
		<div
			data-testid={testId}
			className={`${styles.assignmentTileContainer}  ${
				isSelected ? styles.assignmentTileContainerFlip : styles.assignmentTileContainerUnFlip
			} ${showDetail ? styles.assignmentTileContainerDetail : styles.assignmentTileContainerSummary} ${
				isArchivedTile ? styles.assignmentTileArchived : ''
			}`}
			tabIndex={0}
			aria-selected={isSelected}
			onFocus={(event) => {
				if (event.target.nodeName !== 'BUTTON') {
					onFocus(event)
				}
			}}
		>
			{isSelected ? (
				<div
					className={`${styles.assignmentTileContainerSelected} ${
						showDetail ? '' : styles.assignmentTileContainerSummary
					}`}
				>
					{children}
				</div>
			) : (
				<div className={styles.assignmentTileContainerUnSelected}>{children}</div>
			)}
		</div>
	)
}
