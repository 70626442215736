import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { DropFolderAccess } from '../types'
import { getDropFolderAccess } from './api/getDropFolderAccess'

export const useDropFolderAccess = (projectId: string): UseQueryResult<DropFolderAccess> => {
	const apiClient = useApi()

	const queryKey = ['getDropFolderAccess', projectId]
	const queryFunction = () => getDropFolderAccess(apiClient, projectId)
	return useQuery(queryKey, queryFunction)
}
