import { t } from 'i18next'
import { world } from '../../../../local-core-ui/geographical-distribution-map/gdm-map'

const getCountryTranslation = (countryCode: string): string => {
	let name = world.features.filter((country) => country.id2 === countryCode)[0]?.properties.name || countryCode

	if (t('country.' + countryCode.toLowerCase())) {
		name = t('country.' + countryCode.toLowerCase())
	}

	return name
}

export { getCountryTranslation }
