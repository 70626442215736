import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from '../../../../local-core-ui'
import styles from '../../refresh-rematch-now-confirmation-modal.module.scss'

interface MatchVerificationMessageProps {
	open: boolean
	onClose(): void
	testId: string
	showButtonClose?: boolean
}

const MatchVerificationMessageModal = ({
	open,
	onClose,
	testId,
	showButtonClose = true
}: MatchVerificationMessageProps): ReactElement => {
	const { t } = useTranslation()
	return (
		<div className={styles.confirmationModalSourceContainer} data-testid="confirmation-modal-source-container">
			<Modal
				open={open}
				isContainer={false}
				onClose={onClose}
				showButtonClose={showButtonClose}
				testId="confirmation-modal-source"
			>
				<div>
					<p className={styles.confirmationModalSourceDescription}>
						{t('source.refresh.match.verification.message.description')}
					</p>
				</div>

				<div className={styles.confirmationModalSourceButtonsContainer}>
					<Button
						type="secondary"
						text={t('source.refreshNow.disable.btn.disable')}
						onClick={onClose}
						testId={testId + '-cancel'}
					/>
				</div>
			</Modal>
		</div>
	)
}

export type { MatchVerificationMessageProps }
export { MatchVerificationMessageModal }
