import { TDispatch } from '../../../../store'
import { updateCurrentProjectAction } from '../../../../store/projectWizard/actions'
import { EnrichingBlock } from '../../../../store/projectWizard/types'
import { Element } from '../../../../types'

export const addElementToEnrichingLayout = (
	dispatch: TDispatch,
	newEnrichingLayout: Array<EnrichingBlock>,
	newSelectedElement: Element,
	blockId?: string
): void => {
	const blockIdx = newEnrichingLayout.findIndex((block) => block.blockId === blockId)

	if (blockIdx !== -1) {
		if (!newEnrichingLayout[blockIdx].elements.find((e) => e.elementId === newSelectedElement.elementId))
			newEnrichingLayout[blockIdx].elements.push(newSelectedElement)
		else return
	} else {
		const newBlock = {
			blockId: blockId || '',
			elements: [newSelectedElement]
		}
		newEnrichingLayout.push(newBlock)
	}

	dispatch(
		updateCurrentProjectAction({
			enrichingLayout: newEnrichingLayout
		})
	)
}
