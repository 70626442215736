import { AxiosInstance } from 'axios'
import { Record, RecordClassification } from '../../types'

export const changeRecordsToStewardClassification = async (
	apiClient: AxiosInstance,
	assignmentId: string,
	newClassification: RecordClassification,
	records: Array<Record>
): Promise<void> => {
	const url = `/pls/steward/assignments/${assignmentId}/records/classification/${newClassification}`

	const payload = records.map((record) => record.recordId)
	return apiClient.put(url, payload)
}
