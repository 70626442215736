import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { ToggleSwitch } from '../../../../local-core-ui'
import styles from './full-family-tree-selector.module.scss'

interface FullFamilyTreeSelectorProps {
	testId: string
	enableFullFamilyTree: boolean
	onChangeFullFamilyTree({ currentTarget }: ChangeEvent<HTMLInputElement>): void
}

export const FullFamilyTreeSelector = ({
	testId,
	enableFullFamilyTree,
	onChangeFullFamilyTree
}: FullFamilyTreeSelectorProps) => {
	const { t } = useTranslation()

	return (
		<div className={styles.enableFullFamilyTreeContainerContainer} data-testid={''}>
			<ToggleSwitch
				label={t('title.fullFamilyTree')}
				checked={enableFullFamilyTree}
				onChange={onChangeFullFamilyTree}
				id={testId + '-switch-enable-full-family-tree'}
				testId={testId + '-switch-enable-full-family-tree'}
			/>
		</div>
	)
}
