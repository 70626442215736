/**
 * @class PieChart
 */

import * as d3 from 'd3'
import { FC, useEffect, useRef, useState } from 'react'

import cx from 'classnames'
import { createTokenList, getValueToken } from '../design-tokens/utils'
import { drawKeys } from '../helpers/base-keys-for-chart'
import * as tokens from '../shared/token-colors.json'
import ToolTip, { ToolTipInfo } from '../tooltip/tooltip'
import styles from './pie-chart.module.scss'

interface TooltipInfoPieChart extends ToolTipInfo {
	type?: 'bySlice' | 'general'
}
export interface PieChartProps {
	data: Record<string, unknown>
	colors: Record<string, string>
	diameter: number
	showLines?: boolean
	showStrokes?: boolean
	showKeys?: boolean
	describeWith?: 'label' | 'percentage' | 'value' | 'none'
	tooltipInfo?: TooltipInfoPieChart
	zeroStateColor?: string
	testId?: string
	positionKeys?: 'bottom' | 'right'
}

const PieChartGenerator = (SVGRef: SVGSVGElement) => {
	const svg = d3.select(SVGRef)
	const rootG = svg.append('g')

	rootG.append('g').attr('class', 'slices')
	rootG.append('g').attr('class', 'labels')
	rootG.append('g').attr('class', 'lines')
	rootG.append('g').attr('class', 'keys')

	const change = (
		data,
		colors,
		diameter: number,
		showLines = false,
		showStrokes = false,
		showKeys = true,
		describeWith = 'label',
		zeroStateColor: string,
		tooltipInfo: TooltipInfoPieChart,
		myId: string,
		testId: string,
		positionKeys = 'bottom',
		typeTooltip = 'general'
	) => {
		const xFactor = 2.5
		const yFactor = 2.5
		const radius = diameter / 2
		const spacingSmall = 16 // spacing-small=1.6rem but ie does not understand rems
		const halfFontSizeLarge = 16 / 2 // font-size-large=1.6rem but ie does not understand rems

		const allZeroes = data.reduce((previous, currentValue) => previous && currentValue.value === 0, true)

		if (allZeroes && data.length > 1) data[1].value = 100 // UX asked for this stupid logic to display an arc when all values are 0

		const pie = d3.pie().value((d) => d.value)

		const arc = d3.arc().outerRadius(radius).innerRadius(0)

		const outerArc = d3
			.arc()
			.innerRadius(radius + spacingSmall)
			.outerRadius(radius + spacingSmall)

		let total = data.reduce((accumulator, current) => accumulator + current.value, 0)
		if (total == 0) total = 1 // to avoid division by 0 on percentage calculation (percentage funct)

		const hidePercentagesSmallerThan =
			data.filter((value) => percentage(value, total) < 6).reduce((accumulator) => accumulator + 1, 0) > 1 ? 5 : 1

		const describe = (d) => {
			// Don't show a text if the percentage is 0
			// if we have more than 2 data elements and there are more than 1 small percentage (< 6%)
			// hide them
			if (d.value === 0 || (data.length > 2 && percentage(d, total) < hidePercentagesSmallerThan)) return ''
			switch (describeWith) {
				case 'label':
					return key(d)
				case 'value':
					return allZeroes ? 0 : value(d)
				case 'percentage':
					return allZeroes ? '0 %' : percentageLabel(d, total)
				case 'none':
					return ''
			}
		}

		const bottomKey = (d) => {
			if (describeWith === 'label') return percentageLabel(d, total)
			else return key(d)
		}
		const rightKey = (d) => {
			return percentageLabel(d, total) + ' ' + key(d)
		}
		//.range(allZeroes ? Array(colors.length).fill(colors.length > 1 ? colors[1] : colors[0]) : colors)
		const colorArray = colors.map((d) => d.value)
		const color = d3
			.scaleOrdinal()
			.domain(colors.map((d) => d.label))
			.range(allZeroes ? Array(colorArray.length).fill(zeroStateColor) : colorArray)
		const textColor = d3
			.scaleOrdinal()
			.domain(colors.map((d) => d.label))
			.range(colorArray)

		svg.attr('width', diameter * xFactor).attr('height', radius * yFactor + 70)
		rootG.attr(
			'transform',
			'translate(' +
				((diameter * xFactor) / 2 - (positionKeys !== 'bottom' ? diameter / 2 : 0)) +
				',' +
				radius * 1.5 +
				')'
		)

		/* ------- PIE SLICES -------*/
		const slice = rootG.select('.slices').selectAll('path.slice').data(pie(data), key)

		slice
			.enter()
			.insert('path')
			.merge(slice)
			.on('mouseover', function (d, i) {
				if (tooltipInfo && tooltipInfo.type === 'bySlice') {
					const id = 'containerTooltipsPie' + testId + '-' + i.data.label.replaceAll(/[\s+]/g, '')
					const infoSlice = tooltipInfo.values.find((value) => {
						const label = value.label.toLowerCase().replace(/[\s+]/g, '')
						return label === i.data.label.toLowerCase().replaceAll(/[\s+]/g, '')
					})
					if (infoSlice.value && !document.getElementById(id)) {
						d3.select(this).transition().duration('50').attr('opacity', '.85')
						const containerTooltipPie = d3
							.select('#wrapSVGPie' + myId)
							.append('div')
							.attr('id', id)
							.style('position', 'absolute')
							.style('opacity', 0)
						containerTooltipPie.transition().duration(50).style('opacity', 1)
						const containerTextTooltip = containerTooltipPie
							.append('div')
							.classed(styles.containerTooltipPie, true)
						const val = infoSlice?.value || 0
						containerTextTooltip
							.append('div')
							.classed(styles.textTooltipDesc, true)
							.text(infoSlice?.label + ': ' + val.toLocaleString())
						containerTooltipPie.style('left', d.offsetX + 7 + 'px').style('top', d.offsetY - 15 + 'px')
					}
				}
			})
			.on('mouseout', function (d, i) {
				if (tooltipInfo && tooltipInfo.type === 'bySlice') {
					const containerTooltipPie = d3
						.select('#wrapSVGPie' + myId)
						.select('#containerTooltipsPie' + testId + '-' + i.data.label.replaceAll(/[\s+]/g, ''))
					d3.select(this).transition().duration('50').attr('opacity', '1')
					containerTooltipPie.transition().duration('50').style('opacity', 0)
					containerTooltipPie.remove()
				}
			})
			.attr('class', () => {
				return cx(styles.slice, { [styles.withStroke]: showStrokes })
			})
			.transition()
			.duration(1000)
			.attrTween('d', function (d) {
				this._current = this._current || d
				const interpolate = d3.interpolate(this._current, d)
				this._current = interpolate(0)
				return function (t) {
					return arc(interpolate(t))
				}
			})
			.styleTween('fill', function (d) {
				this._current = this._current || d
				const interpolate = d3.interpolate(this._current, d)
				this._current = interpolate(0)
				return function (t) {
					const d2 = interpolate(t)
					return color(d2.data.label)
				}
			})

		slice.exit().remove()

		/* ------- TEXT LABELS -------*/
		const text = rootG.select('.labels').selectAll('text').data(pie(data), describe)

		if (describeWith !== 'none') {
			text.enter()
				.append('text')
				.attr('class', styles.label)
				.attr('text-anchor', 'start')
				//				.attr('dy', '3.5px') // sorry rems, but freaking IE does not understand you
				.merge(text)
				.text(describe)
				.transition()
				.duration(1000)
				.attrTween('transform', function (d) {
					this._current = this._current || d
					const interpolate = d3.interpolate(this._current, d)
					this._current = interpolate(0)
					return function (t) {
						const d2 = interpolate(t)
						const pos = outerArc.centroid(d2)

						// so some trig lesson, I'm making a break at 45 degrees (or Pi/4 rads)
						// the tan(Pi/4) = 1 and both sin(pi/4) and cos(pi/4) equals sqrt(2) / 2
						// which is close to 0.7071
						const tan = pos[0] / pos[1]
						if (pos[0] >= -0.000001 && tan <= 1) {
							pos[0] = (radius + spacingSmall) * 0.7071
							pos[1] = (radius + spacingSmall) * 0.7071
						}

						return 'translate(' + pos + ')'
					}
				})
				.styleTween('dominant-baseline', function (d) {
					this._current = this._current || d
					const interpolate = d3.interpolate(this._current, d)
					this._current = interpolate(0)
					return function (t) {
						const d2 = interpolate(t)
						const pos = outerArc.centroid(d2)
						return Math.sign(pos[1]) >= 0 ? 'hanging' : 'auto'
					}
				})
				.styleTween('text-anchor', function (d) {
					this._current = this._current || d
					const interpolate = d3.interpolate(this._current, d)
					this._current = interpolate(0)
					return function (t) {
						const d2 = interpolate(t)
						const pos = outerArc.centroid(d2)

						// See comment above
						const tan = pos[0] / pos[1]
						if (pos[0] >= -0.000001 && tan <= 1) {
							return 'start'
						} else return midAngle(d2) < Math.PI ? 'start' : 'end'
					}
				})
				.styleTween('fill', function (d) {
					this._current = this._current || d
					const interpolate = d3.interpolate(this._current, d)
					this._current = interpolate(0)
					return function (t) {
						const d2 = interpolate(t)
						return textColor(d2.data.label)
					}
				})

			text.exit().remove()
		} else {
			text.remove()
		}

		/* ------- SLICE TO TEXT POLYLINES -------*/
		const polyline = rootG.select('.lines').selectAll('polyline').data(pie(data), key)

		if (showLines && describeWith !== 'none') {
			polyline
				.enter()
				.append('polyline')
				.merge(polyline)
				.attr('class', (d) => (percentage(d, total) > 5 ? 'shown' : 'hidden'))
				.transition()
				.duration(1000)
				.attrTween('points', function (d) {
					this._current = this._current || d
					const interpolate = d3.interpolate(this._current, d)
					this._current = interpolate(0)
					return function (t) {
						const d2 = interpolate(t)
						const pos = outerArc.centroid(d2)

						// see comments above on the text
						const tan = pos[0] / pos[1]
						if (pos[0] > 0 && tan < 1) {
							pos[0] = (radius + spacingSmall) * 0.7071
							pos[1] = (radius + spacingSmall) * 0.7071
						}

						pos[1] = Math.sign(pos[1]) >= 0 ? pos[1] + halfFontSizeLarge : pos[1] - halfFontSizeLarge / 2

						return [arc.centroid(d2), pos]
					}
				})

			polyline.exit().remove()
		} else {
			polyline.remove()
		}

		const iconRadius = 10
		const dy = radius + iconRadius + spacingSmall
		const iconPadding = 28
		drawKeys({
			svg: rootG,
			selector: '.keys',
			data: pie(data),
			textForKey: positionKeys === 'bottom' ? bottomKey : rightKey,
			showKeys,
			color,
			dx: 0,
			dy,
			iconPadding,
			iconRadius,
			keyLabel: styles.keylabel,
			containerTooltips: typeTooltip === 'bySlice' ? '#wrapSVGPie' + myId : undefined
		})
		if (positionKeys === 'right') {
			const heightContainerKeys = rootG.select('.keys').node().getBBox().height
			const heigthContainerChart = 400
			const posX = -(heigthContainerChart / 2 + heightContainerKeys / 2 - 90)
			rootG
				.select('.keys')
				.attr('transform', 'translate(' + ((diameter * xFactor) / 2 - diameter / 2) + ',' + posX + ')')
		}
	}

	const midAngle = (d) => {
		return d.startAngle + (d.endAngle - d.startAngle) / 2
	}

	const key = (d) => d.data.label
	const value = (d) => d.data.value.toLocaleString()
	const percentage = (d, total) => (d.value * 100) / total
	const percentageLabel = (d, total) => percentage(d, total).toFixed(0).toLocaleString() + '%'

	return change
}

export const PieChart: FC<PieChartProps> = ({
	data,
	colors,
	diameter,
	showLines,
	showStrokes,
	showKeys,
	describeWith,
	tooltipInfo,
	zeroStateColor = '#F9EEF5',
	testId,
	positionKeys = 'bottom'
}: PieChartProps) => {
	const [myId] = useState(Math.floor(Math.random() * 1000 + 1).toString())
	const typeTooltip = tooltipInfo !== undefined && tooltipInfo.type ? tooltipInfo.type : 'general'
	const canvasRef = useRef(null)
	const pieChartGenerator = useRef(null)
	const colorsTokenList = createTokenList(tokens)

	function objectToOrderArray(arrayToOrder) {
		const OrderArray = []
		Object.entries(arrayToOrder).forEach(([currentKey, value]) => {
			const valueToken = typeof value === 'string' ? getValueToken(colorsTokenList, value) : undefined
			const realValue = valueToken !== undefined ? valueToken : value
			OrderArray.push({ label: currentKey, value: realValue })
		})
		return OrderArray
	}

	useEffect(() => {
		if (pieChartGenerator.current === null) {
			pieChartGenerator.current = PieChartGenerator(canvasRef.current)
		}
	}, [])

	useEffect(() => {
		const dataArray = objectToOrderArray(data)
		const colorArray = objectToOrderArray(colors)
		pieChartGenerator.current(
			dataArray,
			colorArray,
			diameter,
			showLines,
			showStrokes,
			showKeys,
			describeWith,
			zeroStateColor,
			tooltipInfo,
			myId,
			testId,
			positionKeys,
			typeTooltip
		)
	}, [
		data,
		colors,
		diameter,
		showLines,
		showStrokes,
		showKeys,
		describeWith,
		zeroStateColor,
		positionKeys,
		typeTooltip
	])

	return (
		<>
			{typeTooltip === 'bySlice' ? (
				<div style={{ position: 'relative' }} id={'wrapSVGPie' + myId}>
					<svg
						data-testid={`svg-pie-${testId}`}
						className={styles.pieChart}
						ref={canvasRef}
						width={400}
						height={400}
					/>
				</div>
			) : (
				<ToolTip testId={`tooltip-info-pie-${testId}`} {...tooltipInfo}>
					<svg
						data-testid={`svg-pie-${testId}`}
						className={styles.pieChart}
						ref={canvasRef}
						width={400}
						height={400}
					/>
				</ToolTip>
			)}
		</>
	)
}

export default PieChart
