import { ColorBlueBrandPowder, ColorWhite } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ProjectUIFacade from '../../../dashboard/ProjectUIFacade'
import { useFeatures, usePlatform } from '../../../hooks/useEntitlements'
import { Button, Modal } from '../../../local-core-ui'
import { useDeleteProject } from '../../../queries/useDeleteProject'
import { RootState, useAppDispatch, useAppSelector } from '../../../store'
import { toggleProjectExpanded } from '../../../store/dashboard/dashboardSlice'
import { selectProjectAction, updateCurrentProjectAction } from '../../../store/projectWizard/actions'
import { readProjectIntoStore } from '../../../store/projectWizard/thunks'
import { UserDetail } from '../../../types'
import { ProjectOverview } from '../../project-overview/project-overview'
import { ProjectSourceFileBar } from '../../project-source-file-bar/project-source-file-bar'
import styles from './project-bar.module.scss'
import { ProjectDetail } from './project-detail'

type expandCallback = (projectId: string) => void

interface ProjectBarProps {
	project: ProjectUIFacade
	user?: UserDetail
	expanded?: boolean
	onExpand: expandCallback
	inProgress?: boolean
	testId: string
}

export const ProjectBar: FC<ProjectBarProps> = ({
	project,
	user,
	expanded = false,
	onExpand,
	inProgress = false,
	testId
}: ProjectBarProps) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const history = useHistory()
	const deleteProjectMutation = useDeleteProject()
	const selectDashboard = (state: RootState) => state.dashboard
	const dashboardState = useAppSelector(selectDashboard)
	const selectProjectsFromWizard = (state: RootState) => state.projectWizard.projects
	const inProgressProjects = useAppSelector(selectProjectsFromWizard)
	const isAPIEnabled = useFeatures(['EnableAPI'])
	const isSalesforce = usePlatform('salesforce')

	const [showProjectOverviewModal, setShowProjectOverviewModal] = useState(false)
	const [isReadingProjectToStore, setIsReadingProjectToStore] = useState(false)
	const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false)
	const progressState = inProgress ? inProgressProjects[project.projectId] : undefined
	const onFinishSetup = () => {
		dispatch(selectProjectAction(project.projectId))
		history.push('/steps/project-wizard/lastStep')
	}
	const menuOptions = [
		{ label: t('view.project.details'), action: 'viewDetails' },
		{ label: t('manage.project.members'), action: 'manageMembers' },
		{ label: t('add.source'), action: 'addSource' },
		{ label: t('archive.project'), action: 'archive' }
	]

	const getBasicStats = () => {
		if (!dashboardState.expandedProjects.includes(project.projectId)) {
			if (project.basicStats) {
				return project.basicStats
			} else if (inProgress) {
				return undefined
			} else {
				return {
					matchedCnt: undefined,
					successCnt: undefined,
					unmatchedCnt: undefined
				}
			}
		} else return undefined
	}

	const contextMenu =
		(inProgress && !progressState?.deleteOnCancel) || !inProgress
			? {
					options: menuOptions,
					onClick: (option: string) => contextMenuAction(option)
			  }
			: undefined

	const actionButton = inProgress
		? {
				text: t('finish.setup'),
				onClick: () => onFinishSetup()
		  }
		: undefined

	const handleName = () => {
		if (user) {
			return user.FirstName.concat(' ', user.LastName)
		} else return project.createdBy
	}

	const contextMenuAction = async (action: string) => {
		switch (action) {
			case 'viewDetails':
				setShowProjectOverviewModal(true)
				break
			case 'manageMembers':
				history.push(`/steps/project-wizard/project?projectId=${project.projectId}&sourceId=first`)
				// there is no need to set isReadingProjectToStore to false, since a rerender will occur with the push
				break
			case 'addSource':
				setIsReadingProjectToStore(true)
				await dispatch(readProjectIntoStore(project.projectId, isAPIEnabled, isSalesforce, false))
				await dispatch(updateCurrentProjectAction({ shouldSync: true, deleteOnCancel: false }))

				history.push(`/steps/project-wizard/select-data?projectId=${project.projectId}`)
				// there is no need to set isReadingProjectToStore to false, since a rerender will occur with the push
				break
			case 'archive':
				setShowArchiveConfirmation(true)
				break
		}
	}

	return (
		<>
			<Modal
				isContainer={true}
				open={showArchiveConfirmation}
				onClose={() => {
					setShowArchiveConfirmation(false)
				}}
				testId="ProjectBarModal"
			>
				<div className={styles.archiveModal}>
					<div className={styles.archiveModalText}>{t('archive.project.warning')}</div>
					<div className={styles.archiveModalButton}>
						<Button
							text={t('archive.project.button')}
							type="primary"
							size="small"
							onClick={() => {
								deleteProjectMutation.mutate(project.projectId)
								if (dashboardState.expandedProjects.includes(project.projectId))
									dispatch(toggleProjectExpanded(project.projectId))
								setShowArchiveConfirmation(false)
							}}
							testId={testId + '-archive'}
						/>
					</div>
				</div>
			</Modal>
			<div className={styles.projectBar} data-testid="project-bar-container">
				<Modal
					open={showProjectOverviewModal}
					isContainer={true}
					onClose={() => {
						setShowProjectOverviewModal(false)
					}}
					testId="ProjectBarOverviewModal"
				>
					<ProjectOverview
						readOnly={true}
						projectId={project.projectId}
						haveSources={!!project.basicStats}
						testId={testId + '-project-overview'}
					/>
				</Modal>
				{expanded ? (
					<ProjectDetail
						projectId={project.projectId}
						projectName={project.projectDisplayName}
						testId={'project-detail-' + testId}
						contextMenu={contextMenu}
						actionButton={actionButton}
						onClick={() => onExpand(project.projectId)}
						isLoading={isReadingProjectToStore}
					/>
				) : (
					<ProjectSourceFileBar
						name={project.projectDisplayName}
						createDate={project.createdOn}
						createdBy={handleName()}
						isArchived={project.archived}
						color={
							dashboardState.expandedProjects.includes(project.projectId)
								? ColorWhite
								: ColorBlueBrandPowder
						}
						onClick={() => onExpand(project.projectId)}
						testId={'skeleton-project-bar-' + testId}
						basicStats={getBasicStats()}
						contextMenu={contextMenu}
						actionButton={actionButton}
						isLoading={isReadingProjectToStore}
					/>
				)}
			</div>
		</>
	)
}
