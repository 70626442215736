import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { ConnectionDetail } from './api/getConnection'
import { getSourceConnection } from './api/getSourceConnection'

export const useSourceConnection = (
	sourceId: string | undefined,
	enable: boolean
): UseQueryResult<ConnectionDetail> => {
	const apiClient = useApi()
	const queryKey = ['getSources', { sourceId }]
	const queryFunction = () => getSourceConnection(apiClient, sourceId || '')
	return useQuery(queryKey, queryFunction, {
		enabled: sourceId !== undefined && sourceId !== '' && enable
	})
}
