import { AxiosResponse } from 'axios'
import { AssignmentDetails } from '../../../components/assignment-picker/assignment-picker'
import { ApiErrorState, setApiErrorAction } from '../../apiError'
import { AppThunk, getApiClient4Thunks, TDispatch } from '../../index'
import { setAssignments, setRetrieving } from '../stewardSlice'

export const getAssignments =
	(userId: string): AppThunk =>
	async (dispatch: TDispatch): Promise<void> => {
		dispatch(setRetrieving(true))
		const apiClient = getApiClient4Thunks(dispatch)
		apiClient
			.get(`/pls/steward/assignments?assignee=${userId}&state=ACTIVE`)
			.then((assignmentsResponse: AxiosResponse<Array<AssignmentDetails>>) => {
				if (assignmentsResponse.data) {
					dispatch(setAssignments(assignmentsResponse.data))
					dispatch(setRetrieving(false))
				}
			})
			.catch((e) => {
				const errorMessage = JSON.parse(e.request.response).UIAction
				if (errorMessage) {
					const errorData: ApiErrorState = {
						errorCode: errorMessage.code,
						message: errorMessage.message
					}
					dispatch(setApiErrorAction(errorData))
				} else {
					const errorData: ApiErrorState = {
						message: e.response.data.Errors[0]
					}
					dispatch(setApiErrorAction(errorData))
				}
				dispatch(setRetrieving(false))
			})
	}
