import { ColorGray } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { TabLinks } from '../../components/tab-links/tab-links'
import { Icon } from '../../local-core-ui'
import DashboardRoutes from '../dashboard-routes'
import styles from './tabsDashboard.module.scss'

export function TabsDashboard(): ReactElement {
	const tabsContainerRef = useRef<HTMLDivElement>(null)
	const tabsRef = useRef<HTMLDivElement>(null)
	const [sizesTabsArray, setSizesTabsArray] = useState<Array<number>>([])
	const [showArrowLeft, setShowArrowLeft] = useState<boolean>(false)
	const [showArrowRight, setShowArrowRight] = useState<boolean>(false)
	const [outerTabs, setOuterTabs] = useState({ initial: 0, final: -1 })

	useEffect(() => {
		let mounted = true
		if (tabsRef.current) {
			getSizesTab(tabsRef.current.children[0], mounted)
			calculateOuterTabs(mounted)
		}
		return () => {
			mounted = false
		}
		/**
		 * We only want to run this effect when the current ref from tabsRef changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabsRef.current])

	function handleResize() {
		const offsetWidthFullContainer = tabsContainerRef.current ? tabsContainerRef.current.offsetWidth : 0
		const scrollWidthTabsRef = tabsRef.current ? tabsRef.current.scrollWidth : 0
		if (offsetWidthFullContainer > scrollWidthTabsRef) {
			setShowArrowLeft(false)
			setShowArrowRight(false)
		}
		calculateOuterTabs()
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	})

	const getSizesTab = (tabs: Element, mounted = true) => {
		const childrenArray = Array.from(tabs.children)
		const sizesArray: Array<number> = []
		childrenArray.forEach((children) => {
			sizesArray.push(children.scrollWidth)
		})
		if (mounted) setSizesTabsArray(sizesArray)
	}

	const showNextTab = () => {
		if (tabsRef.current && tabsContainerRef.current) {
			let idxTab = 0
			let sumTabs = 0
			const newOuterFinalTab = outerTabs.final + 1
			const isLastTab = newOuterFinalTab === sizesTabsArray.length
			while (idxTab < newOuterFinalTab) {
				sumTabs += sizesTabsArray[idxTab] + 12
				idxTab++
			}
			tabsRef.current.scrollLeft =
				Math.abs(tabsContainerRef.current.offsetWidth - sumTabs) + 32 + (isLastTab ? 0 : 32)
			setOuterTabs({ initial: outerTabs.initial + 1, final: newOuterFinalTab })
			setShowArrowLeft(true)
			if (isLastTab) {
				setShowArrowRight(false)
			}
		}
	}

	const showPreviousTab = () => {
		if (tabsRef.current && tabsContainerRef.current) {
			let idxTab = 0
			let sumTabs = 0
			const newOuteInitialTab = outerTabs.initial - 1
			const isFirsTab = newOuteInitialTab === 0
			while (idxTab < newOuteInitialTab) {
				sumTabs += sizesTabsArray[idxTab] + 12
				idxTab++
			}
			tabsRef.current.scrollLeft = sumTabs
			setOuterTabs({ initial: newOuteInitialTab, final: outerTabs.final - 1 })
			setShowArrowRight(true)
			if (isFirsTab) {
				setShowArrowLeft(false)
			}
		}
	}

	const calculateOuterTabs = (mounted = true) => {
		const offsetWidthFullContainer = tabsContainerRef.current ? tabsContainerRef.current.offsetWidth : 0
		const scrollWidthTabsRef = tabsRef.current ? tabsRef.current.scrollWidth : 0
		if (offsetWidthFullContainer < scrollWidthTabsRef && sizesTabsArray.length) {
			let sumTab = 0
			let idxTab = -1
			sizesTabsArray.forEach((tabSize) => {
				if (sumTab + tabSize <= offsetWidthFullContainer) {
					sumTab += tabSize
					idxTab++
				}
			})
			if (mounted) setOuterTabs({ initial: 0, final: idxTab })
			if (idxTab < sizesTabsArray.length && mounted) {
				setShowArrowRight(true)
			}
		}
	}

	return (
		<div className={styles.tabsDashboard} ref={tabsContainerRef}>
			<div className={styles.tabs} ref={tabsRef}>
				<TabLinks routes={DashboardRoutes} />
			</div>
			{showArrowLeft && (
				<div className={`${styles.arrow} ${styles.left}`} onClick={() => showPreviousTab()}>
					<Icon testId="show-previous-tab" type="chevron-left" color={ColorGray} size="mini" />
				</div>
			)}
			{showArrowRight && (
				<div className={`${styles.arrow} ${styles.right}`} onClick={() => showNextTab()}>
					<Icon testId="show-next-tab" type="chevron-right" color={ColorGray} size="mini" />
				</div>
			)}
		</div>
	)
}
